import React, { useState, useEffect } from "react";
import { Container, Box, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import AvarageScores from "../charts.js/AvarageScores";
import PerformanceTrends from "../charts.js/PerformanceTrends";
import Heatmap from "../charts.js/Heatmap";
import BubbleChart from "../charts.js/BubbleChart";
import TopicsLineChart from "../charts.js/TopicsLineChart";
import { useParams } from "react-router";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Post } from "../../utils/axiosUtils";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Page from "../Page";
import { useHistory } from "react-router";
import UpcomingMeetingsTable from "../CRM/UpcomingMeetingsTable";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import PastMeetingsTable from "../CRM/PastMeetingsTable";
import { getOrgainzation } from "../../firebase/firestore";

function MemberAnalytics(props) {
  const history = useHistory();
  const { fireStoreuserObj } = useAuth();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [loading, setLoading] = useState(true);
  const [bubleChartType, setBubbleChartType] = useState({
    value: "topicSentiments",
    label: "Topics & Sentiments",
  });
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  const checkSentimentPermission = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );
  };

  useEffect(checkSentimentPermission, []);

  const getUpcomingMeetings = async (userEmail) => {
    if (!userEmail) {
      return;
    }
    let jwt = await firebase.auth().currentUser.getIdToken();
    let upcomingMeetingsResponse = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/calendar/upcomingEvents?perPage=20&page=1`,
      jwt
    );

    if (upcomingMeetingsResponse.status == "success") {
      let meetingWithLead = upcomingMeetingsResponse.data.filter((meeting) => {
        if (userEmail) {
          return meeting.participants.some(
            (participant) => participant.email == userEmail
          );
        }
      });
      setUpcomingMeetings(meetingWithLead);
    }
  };

  const fetchData = () => {
    const postBody = {
      start: new Date().getTime() - dateRange.value * 2 * 24 * 60 * 60 * 1000,
      end: new Date().getTime(),
      organizationId: fireStoreuserObj.organizationId, //"a8a69ba0-1362-448c-9d57-07371257aa2e"
    };
    Post("analytics/getOrganizationUsersData", postBody)
      .then(({ data: { data } }) => {
        const findUser = data.find((item) => item?.uid === id);
        if (findUser) {
          getUpcomingMeetings(findUser?.email);
          findUser.meetingData.sort((a, b) => b.startTime - a.startTime);
          setData(findUser);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(fetchData, [dateRange]);

  const dateRangeHandler = (newDateRange) => {
    if (newDateRange.value > dateRange.value) {
      setDateRange(newDateRange);
      return true;
    }
    return false;
  };
  return (
    <Page>
      <Container maxWidth={"xl"}>
        <Box>
          {loading ? (
            <Stack spacing={1} animation="wave">
              <Skeleton
                variant="rectangular"
                width={150}
                height={25}
                sx={{ bgcolor: "#cCc" }}
              />
            </Stack>
          ) : (
            <Box display="flex" alignItems="center">
              {history?.location?.state?.from === "teams" ? (
                <Link to={"/teams"} className={"breadcrumbLink"}>
                  Teams
                </Link>
              ) : (
                <Link to={"/team-management"} className={"breadcrumbLink"}>
                  Team management
                </Link>
              )}
              <NavigateNextIcon sx={{ color: "#676767", margin: "0 .5em" }} />
              <span className="breadcrumbText">{data?.displayName}</span>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: "1em" }}>
          {loading ? (
            <Stack spacing={1} animation="wave">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={55}
              >
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ bgcolor: "#cCc" }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height={55}
                  sx={{ ml: 1 }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={210}
                    height={25}
                    sx={{ bgcolor: "#cCc" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={210}
                    height={18}
                    sx={{ bgcolor: "#cCc" }}
                  />
                </Box>
              </Box>
            </Stack>
          ) : (
            <>
              <Box>
                <Avatar
                  src={data?.photoURL}
                  sx={{ height: 40, width: 40, border: "1px solid #7DA8FB" }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <h3 className="team-member-name">{data?.displayName}</h3>
                <span className="team-member-role">
                  {data?.role?.charAt(0).toUpperCase() +
                    data?.role?.slice(1).toLowerCase()}
                </span>
              </Box>
            </>
          )}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <AvarageScores
              data={data?.meetingData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
              chartType={"member"}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <PerformanceTrends
              data={data?.meetingData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
              type={"member"}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Heatmap
              fetchLoading={loading}
              meetingsData={data?.meetingData}
              dateRangeController={dateRangeHandler}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "2em" }}>
          <Grid item xs={12} lg={6}>
            <BubbleChart
              type={bubleChartType}
              setType={setBubbleChartType}
              data={data?.meetingData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
              isTeamAnalytic={true}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopicsLineChart
              fetchData={data?.meetingData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "2em" }}>
          <Grid item xs={12} lg={6}>
            <PastMeetingsTable
              data={data?.meetingData}
              from={"memberAnalytics"}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>
          <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
            <UpcomingMeetingsTable
              data={upcomingMeetings}
              meetingTitle={
                data?.displayName && `Meeting with ${data.displayName}`
              }
              email={data?.email}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default MemberAnalytics;
