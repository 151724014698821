import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContex";
import { store } from "./redux/store";
import { postData } from "./utils";
import useAuth from "./hooks/useAuth";

import "./assets/css/main.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo, this.props.user);
    errorInfo.user_id = this.props.user ? this.props.user.id : null;

    if (process.env.REACT_APP_LOGGING == "true") {
      postData(
        `${process.env.REACT_APP_BASE_API_URL}/logging/addLog`,
        "no need",
        { data: { error: error.toString(), errorInfo } }
      );
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const { user } = useAuth();
    return <Component {...props} user={user} />;
  };
}

const ErrorBoundaryWithHook = withMyHook(ErrorBoundary);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AuthProvider>
        <ErrorBoundaryWithHook>
          <App />
        </ErrorBoundaryWithHook>
      </AuthProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
