import React from "react";
import { Grid } from "@material-ui/core";
import MiniCharts from "./MiniCharts";

function AvarageScores({ data, fetchLoading, dateRangeHandler, chartType, isSentimentAvailable }) {
  return (
    <div className="chart avarage-scores">
      <div className="chart-header">
        <h6>Average Scores</h6>
      </div>
      <Grid container spacing={1} sx={{ marginTop: ".5em" }}>
        <Grid item xs={12}>
          <MiniCharts
            type={"All meetings"}
            data={data}
            fetchLoading={fetchLoading}
            dateRangeHandler={dateRangeHandler}
            chartType={chartType}
          />
        </Grid>
        <Grid item xs={isSentimentAvailable ? 6 : 12}>
          <MiniCharts
            type={"Avg. duration"}
            data={data}
            fetchLoading={fetchLoading}
            dateRangeHandler={dateRangeHandler}
            chartType={chartType}
          />
        </Grid>
        {
          isSentimentAvailable &&
          <Grid item xs={6}>
            <MiniCharts
                type={"Avg. sentiment"}
                data={data}
                fetchLoading={fetchLoading}
                dateRangeHandler={dateRangeHandler}
                chartType={chartType}
            />
          </Grid>
        }
        <Grid item xs={6}>
          <MiniCharts
            type={"Avg. engagement"}
            data={data}
            fetchLoading={fetchLoading}
            dateRangeHandler={dateRangeHandler}
            chartType={chartType}
          />
        </Grid>
        <Grid item xs={6}>
          <MiniCharts
              type={"Positive meeting rate"}
              data={data}
              fetchLoading={fetchLoading}
              dateRangeHandler={dateRangeHandler}
              chartType={chartType}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default AvarageScores;
