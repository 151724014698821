import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Avatar,
  CircularProgress,
  Container,
  Drawer,
  Grid,
} from "@material-ui/core";
import Page from "../../Page";
import {
  getAccount,
  getAccountContacts,
  getContactById,
  GetDeals,
  getOrgainzation,
  getUserByUserId,
} from "../../../firebase/firestore";
import AccountDetailProfile from "./AccountDetailProfile";
import Attachments from "../Attachments";
import Notes from "../Notes";
import Tasks from "../Tasks";
import Deals from "../Deals";
import AccountDetailContactTable from "./AccountDetailContactTable";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import NewsDrawer from "./NewsDrawer";
import { Post } from "../../../utils/axiosUtils";
import EngagementScore from "../../charts.js/EngagementScore";
import SentimentScore from "../../charts.js/SentimentScore";
import BubbleChart from "../../charts.js/BubbleChart";
import useAuth from "../../../hooks/useAuth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountInsightContainer from "./AccountInsightContainer";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: ".3em !important",
  },
}));

export default function AccountDetails() {
  const { fireStoreuserObj } = useAuth();
  let { accountId } = useParams();
  const history = useHistory();
  const [accountDetails, setAccountDetails] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newsDrawerStatus, setNewsDrawerStatus] = useState(false);
  const [analyticData, setAnalyticData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const sentimentAvailableChecker = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );
  };

  useEffect(sentimentAvailableChecker, []);

  const getAccountAnalytics = () => {
    Post("meeting/getAccountInteractionResult", { accountId })
      .then(({ data }) => {
        let allData = [];
        if (data?.data?.length) {
          data?.data?.map((item) => (allData = [...allData, ...item.meetings]));
        }
        setChartData(allData);
        setAnalyticData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(getAccountAnalytics, []);

  useEffect(() => {
    (async () => {
      let c = await getAccountContacts(accountId);
      console.log(c, "C account contacts");
      let allContacts = [];
      for await (const item of c) {
        let contactId = item.contactId ? item.contactId : item.id;
        if (contactId) {
          let contact = await getContactById(contactId);
          if (contact) {
            contact.contactId = contactId;
            allContacts.push(contact);
          }
        }
      }
      setContacts(allContacts);
    })();
  }, []);

  const fetchDeals = () => {
    GetDeals()
      .then((res) => {
        let filteredDeals = res?.filter(
          (deal) =>
            deal?.relatedTo?.type === "account" &&
            deal.relatedTo.accountId === accountId
        );
        if (filteredDeals?.length > 0) {
          setDeals(filteredDeals);
        }
      })
      .catch((err) => {
        console.log(err, "error happen when fetching deals");
      });
  };

  useEffect(fetchDeals, []);

  const getAccountDetails = async () => {
    let res = await getAccount(accountId);
    if (res.success) {
      setInputs({
        name: res?.account?.name,
        website: res?.account?.website,
        noOfEmployee: res?.account?.noOfEmployee,
        annualRevenue: res?.account?.annualRevenue,
        phone: res?.account?.phone,
        billingAddress: res?.account?.billingAddress,
        billingNumber: res?.account?.billingNumber,
        // assignee: res?.contact?.assignee ? res.contact.assignee : [],
      });
      let createdByUser = await getUserByUserId(res.account.createdBy);
      res.account.createdByUser = createdByUser.displayName;
      if (res.account.modifiedBy) {
        let updatedByUser = await getUserByUserId(res.account.modifiedBy);
        res.account.updatedByUser = updatedByUser.displayName;
      }
      setAccountDetails(res.account);
      setLoading(false);
    }
  };

  useEffect(getAccountDetails, []);

  const handleInputChange = (e) => {
    let newInputs = Object.assign({}, inputs);
    newInputs[e.target.name] = e.target.value;
    setInputs(newInputs);
  };

  const handleNewsDrawerOpen = () => {
    setNewsDrawerStatus(true);
  };

  const doc = document.getElementById("analyticChartsContainer");
  const handleScroll = (rotate) => {
    doc?.scrollTo({
      top: 0,
      left: rotate === "right" ? doc.scrollWidth : 0,
      behavior: "smooth",
    });
    setScrollPosition(rotate === "right" ? 1020 : 0);
  };

  const filterChartsByContacts = (contact) => {
    if (!analyticData) {
      return;
    }
    let contactName = `${contact.firstName} ${
      contact?.lastName ? contact.lastName : ""
    }`;
    let checkContactAlreadySelected = selectedContacts?.find(
      (item) => item === contactName
    );
    let newSelectedContacts;
    if (checkContactAlreadySelected) {
      newSelectedContacts = selectedContacts.filter(
        (item) => item !== contactName
      );
    } else {
      newSelectedContacts = [...selectedContacts, contactName];
    }
    let newChartData = [];
    if (newSelectedContacts?.length) {
      newSelectedContacts?.map((contactName) => {
        let contactData = analyticData?.find(
          (item) => item.contact === contactName
        );
        if (contactData) {
          newChartData = [...newChartData, ...contactData.meetings];
        }
      });
      setChartData(newChartData);
    } else {
      let allData = [];
      if (analyticData.length) {
        analyticData.map((item) => (allData = [...allData, ...item.meetings]));
      }
      setChartData(allData);
    }
    setSelectedContacts(newSelectedContacts);
  };

  if (loading) {
    return (
      <Box justifyContent="space-around" display="flex" padding={2}>
        <CircularProgress style={{ color: "blue" }} size="20px" />
      </Box>
    );
  }

  return (
    <Page>
      <Container maxWidth={"xl"}>
        {newsDrawerStatus && (
          <NewsDrawer
            closeDrawer={() => setNewsDrawerStatus(false)}
            searchKey={accountDetails?.name}
          />
        )}
        <Box paddingX={3} display="flex" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            width={150}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: `/crm/accounts`,
                state: { from: "accountDetails", accountId: accountId },
              });
            }}
          >
            <ArrowBackIcon />
            <span style={{ marginLeft: "10px" }}>Back to the list</span>
          </Box>

          <Box display="flex" alignItems="center">
            <Box style={{ marginRight: "10px", color: "#707070" }}>
              <p style={{ fontSize: "11px" }}>
                Created by <b>{accountDetails.createdByUser}</b> at{" "}
                <b>{moment(accountDetails.createdAt.toDate()).format("lll")}</b>
              </p>
              {accountDetails.updatedByUser ? (
                <p style={{ fontSize: "11px" }}>
                  Updated by <b>{accountDetails.updatedByUser}</b> at{" "}
                  <b>
                    {moment(accountDetails.updatedAt.toDate()).format("lll")}
                  </b>
                </p>
              ) : (
                <p style={{ fontSize: "11px" }}>Not updated yet!</p>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer", marginRight: "1em" }}
              onClick={handleNewsDrawerOpen}
              mr={1}
            >
              <ArticleIcon
                sx={{
                  color: "#212B36",
                  fontSize: "16px",
                  cursor: "pointer",
                  marginRight: ".5em",
                }}
              />
              Latest News
            </Box>

            <button
              onClick={() => history.push(`/crm/account/edit/${accountId}`)}
              className={"modal-button blue d-flex-center"}
            >
              <EditIcon
                sx={{ fontSize: "16px", color: "#fff", marginRight: ".5em" }}
              />
              Edit
            </button>
          </Box>
        </Box>
        <Box
          marginTop={2}
          sx={{ padding: ".5em 0" }}
          className="arrow-scroll-container"
        >
          <Box sx={{ display: "flex" }}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              mr={3}
            >
              <AccountDetailProfile
                data={accountDetails}
                accountId={accountId}
                handleInputChange={handleInputChange}
                deals={deals}
                inputs={inputs}
                setDetails={setAccountDetails}
              />
            </Box>
            <AccountDetailContactTable
              contacts={contacts}
              analyticData={analyticData}
            />
          </Box>
        </Box>

        {process.env.REACT_APP_APP_ENV === "dev" && (
          <Grid container spacing={3} marginTop={1}>
            <Grid item lg={12} sx={{ paddingTop: "8px !important" }}>
              <AccountInsightContainer header={"AI Overview"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <AccountInsightContainer header={"AI Business Opportunities"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <AccountInsightContainer header={"AI Business Risks"} />
            </Grid>
          </Grid>
        )}

        <div className="account-filter-members">
          {contacts?.map((contact, index) => (
            <LightTooltip
              title={`${contact.firstName} ${
                contact?.lastName ? contact.lastName : ""
              }`}
              key={index}
            >
              <Avatar
                alt={`${contact.firstName} ${
                  contact?.lastName ? contact.lastName : ""
                }`}
                src={contact?.avatar}
                sx={{
                  width: 35,
                  height: 35,
                  border: selectedContacts?.find(
                    (item) =>
                      item ===
                      `${contact.firstName} ${
                        contact?.lastName ? contact.lastName : ""
                      }`
                  )
                    ? "3px solid #2c73ff"
                    : "",
                }}
                onClick={() => filterChartsByContacts(contact)}
              />
            </LightTooltip>
          ))}
        </div>

        <Box sx={{ padding: ".5em 0" }} className="arrow-scroll-container">
          {scrollPosition > 0 && (
            <div
              className="arrow left lead"
              onClick={() => handleScroll("left")}
            >
              <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
            </div>
          )}

          <Box sx={{ display: "flex" }} id={"analyticChartsContainer"}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <EngagementScore data={chartData} />
            </Box>

            {isSentimentAvailable && (
              <Box
                display="flex"
                alignItems="center"
                marginLeft={3}
                flexDirection="column"
              >
                <SentimentScore data={chartData} />
              </Box>
            )}

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "matchedCompetitors" }}
                data={chartData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "matchedProducts" }}
                data={chartData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "topicSentiments" }}
                data={chartData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>
          </Box>

          {scrollPosition === 0 && (
            <div
              className="arrow right lead"
              onClick={() => handleScroll("right")}
            >
              <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
            </div>
          )}
        </Box>

        <Grid container spacing={3} sx={{ height: "100%" }}>
          <Grid item xs={12} lg={6}>
            <Notes
              typeId={accountId}
              type={"accounts"}
              height={"calc(100% - 35px)"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Tasks
              type={"account"}
              accountId={accountId}
              lastName={accountDetails.name}
              mt={0}
            />
          </Grid>
          <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
            <Attachments type={"accounts"} typeId={accountId} hMax />
          </Grid>
          <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
            <Deals
              accountId={accountId}
              type={"account"}
              lastName={accountDetails.name}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
