import React from "react";
import EventIcon from "@mui/icons-material/Event";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Avatar from "@material-ui/core/Avatar";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: "5px",
    minWidth: 160,
    padding: 0,
  },
}));

function HoverUserInfo({
  avatar,
  name,
  email,
  buttonAction,
  buttonTitle,
  disableEventIcon,
  eventIconAction,
  children,
  button,
}) {
  return (
    <HtmlTooltip
      title={
        <div className="mention-hover-container">
          <div className="profile-informations">
            {avatar && (
              <Avatar
                alt={name}
                src={avatar}
                style={{
                  border: "1px solid #2C73FF",
                  marginRight: 8,
                }}
              />
            )}
            <div className="profile-contact">
              {name}
              <span className="email">{email}</span>
            </div>
          </div>
          <div className="mention-hr" />
          <div className="icons">
            {button && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  buttonAction();
                }}
              >
                {buttonTitle}
              </button>
            )}
            {!disableEventIcon && (
              <EventIcon
                sx={{ fontSize: 24, color: "#2c73ff" }}
                onClick={(e) => {
                  e.stopPropagation();
                  eventIconAction();
                }}
              />
            )}
          </div>
        </div>
      }
    >
      {children}
    </HtmlTooltip>
  );
}

export default HoverUserInfo;
