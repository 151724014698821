import axios from "axios";

const elasticHost = process.env.REACT_APP_ELASTIC_HOST
  ? process.env.REACT_APP_ELASTIC_HOST
  : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

export const elasticSearchEmailSuggestions = async (searchText, apiKey) => {
  if (!apiKey || searchText?.length < 2) {
    return;
  }
  const types = ["leads", "contacts"];
  const results = [];
  for await (const type of types) {
    let query = {
      bool: {
        must: [
          {
            query_string: {
              query: `*${searchText}*`,
              fields: ["fullName", "email.keyword"],
            },
          },
          {
            query_string: {
              query: "?*",
              fields: ["email"],
            },
          },
        ],
        must_not: [
          {
            match: {
              leadStatus: "converted",
            },
          },
        ],
      },
    };
    const searchQuery = await axios.post(
      `${elasticHost}/${type}/_search`,
      {
        query,
        size: 50,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `ApiKey ${apiKey}`,
        },
      }
    );

    searchQuery?.data?.hits?.hits?.map((h) => {
      let searchInResults = results.find(
        (item) => item.email === h._source?.email
      );
      if (!searchInResults) {
        results.push({
          id: h._id,
          fullName: h._source?.fullName,
          type,
          avatar: h._source?.avatar,
          email: h._source?.email,
        });
      }
    });
  }
  return results;
};

export const elasticSearchCRMSuggestions = async (
  searchText,
  types,
  containsMode,
  apiKey
) => {
  if (!searchText || !apiKey || !types?.length) {
    return false;
  }
  let queryString;
  if (typeof searchText === "string") {
    queryString = containsMode ? `*${searchText}*` : searchText;
  } else if (Array.isArray(searchText)) {
    queryString = containsMode
      ? searchText.map((item) => `*${item}*`).join(" OR ")
      : searchText.join(" OR ");
  } else {
    return false;
  }

  try {
    const results = [];

    for await (const type of types) {
      let query = {
        bool: {
          must: [
            {
              query_string: {
                query: queryString,
                fields: [
                  type === "contacts" || type === "leads"
                    ? "fullName"
                    : type === "accounts"
                    ? "name"
                    : null,
                ],
              },
            },
          ],
        },
      };
      const searchQuery = await axios.post(
        `${elasticHost}/${type}/_search`,
        {
          query,
          size: 50,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${apiKey}`,
          },
        }
      );
      searchQuery?.data?.hits?.hits?.map((h) => {
        results.push({
          id: h._id,
          fullName: h._source?.fullName,
          name: h._source?.name,
          type:
            type === "leads"
              ? "lead"
              : type === "contacts"
              ? "contact"
              : type === "accounts"
              ? "account"
              : null,
        });
      });
    }
    return results;
  } catch {
    return false;
  }
};
