/* eslint-disable no-script-url */

import React, { useState, useEffect, useCallback } from "react";
import { Icon } from "@iconify/react";

import { Box, Collapse, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";

import { getTranscriptByMeetingId } from "../../../firebase/firestore";

import { getMeetingByMeetingId } from "../../../firebase/firestore";

import googleMeetAvatar from "../../../assets/images/meet.png";
import recordingMeeting from "../../../assets/images/recording_meeting.png";
import zoomAvatar from "../../../assets/images/zoom-icon.svg";
import msTeamAvatar from "../../../assets/images/ms-teams-icon.svg";

import ParticipantsAvatars from "./ParticipantsAvatars";

import Highlighter from "react-highlight-words";

import moment from "moment";

function Transcript({ transcript, query, setShowSuggestions, searchInput }) {
  const [open, setOpenFn] = useState(false);
  const [transcripts, setTranscripts] = useState(false);
  const [transcriptMeeting, setTranscriptMeeting] = useState(false);

  const myRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    let loadMeeting = async () => {
      let tMeeting = await getMeetingByMeetingId(transcript.meetingId);
      setTranscriptMeeting(tMeeting);
    };
    loadMeeting();
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    searchInput.current.querySelector("input").onfocus = () => {
      setShowSuggestions(true);
    };

    setTranscripts(false);

    if (!transcripts) {
      let d = await getTranscriptByMeetingId(transcript.meetingId);
      // let t = [];
      // d.forEach((doc) => {
      //   t.push({
      //     text: doc.data().text,
      //     id: doc.id,
      //     speaker: doc.data().speaker,
      //     timestamp: doc.data().timestamp,
      //   });
      // });
      if (d && d.length) {
        setTranscripts(d);
      }
    }

    setOpenFn(!open);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        background: "rgb(241, 241, 241)",
        margin: "5px 0",
        borderRadius: " 10px",
      }}
    >
      <div style={{ padding: "10px" }}>
        {transcriptMeeting && (
          <Grid
            width={520}
            display="flex"
            alignItems="center"
            gap="15px"
            style={{
              paddingBottom: "10px",
            }}
          >
            <Box
              item
              xs={6}
              sm={6}
              display="flex"
              fontSize="14px"
              alignItems="center"
              gap="5px"
              style={{ textDecoration: "underline" }}
            >
              {transcriptMeeting.channelType === "ZOOM" ? (
                <Avatar
                  src={zoomAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : transcriptMeeting.channelType === "GOOGLE_MEET" ? (
                <Avatar
                  src={googleMeetAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : transcriptMeeting.channelType === "RECORDING" ? (
                <Avatar
                  src={recordingMeeting}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <Avatar
                  src={msTeamAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
              <p>{transcriptMeeting.title ? transcriptMeeting.title : "-"}</p>
            </Box>
            <Box item xs={2} sm={2} fontSize="12px" color="#707070">
              {transcriptMeeting.createdAt.seconds
                ? moment(transcriptMeeting.createdAt.seconds * 1000).format(
                    "lll"
                  )
                : "--"}
            </Box>
            <Box item xs={2} sm={2}>
              {transcriptMeeting.participants?.length && (
                <ParticipantsAvatars row={transcriptMeeting.participants} />
              )}
            </Box>
            <Box
              item
              xs={3}
              sm={3}
              display="flex"
              alignItems="center"
              gap="5px"
            >
              <span>
                {transcriptMeeting.meetingOutcome === "POSITIVE" ? (
                  <Icon
                    width="25"
                    height="25"
                    icon="gg:smile"
                    color="#82abfb"
                  />
                ) : transcriptMeeting.meetingOutcome === "NEGATIVE" ? (
                  <Icon
                    width="25"
                    height="25"
                    icon="gg:smile-sad"
                    color="#82abfb"
                  />
                ) : transcriptMeeting.meetingOutcome === "UNCERTAIN" ? (
                  <Icon
                    width="25"
                    height="25"
                    icon="gg:smile-neutral"
                    color="#82abfb"
                  />
                ) : (
                  ""
                )}
              </span>
            </Box>
            <Box
              item
              xs={3}
              sm={3}
              display="flex"
              alignItems="center"
              gap="10px"
            >
              <div
                style={{
                  borderRight: " 1px solid #e6e6ff",
                  padding: "0 8px",
                  color: "#707070",
                }}
              >
                {transcriptMeeting.attendeesType || ""}
              </div>
              <div className="meeting-topics-section">
                {transcriptMeeting.topics?.length
                  ? transcriptMeeting.topics.map((topic, key) => {
                      return (
                        <div key={key} className="topic">
                          {topic}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </Box>
          </Grid>
        )}

        <Grid container spacing={1}>
          {/* <Grid item xs={0.5} sm={0.5}>
              <img src="/images/trans-icon.png"></img>
            </Grid> */}
          <Grid item xs={3.5} sm={3.5} fontWeight="bold">
            {!open ? (
              <p>{transcript.speaker}</p>
            ) : (
              <a
                // eslint-disable-next-line no-script-url
                href="Javascript:;"
                onClick={() =>
                  (window.location.href = `/meeting/${transcript.meetingId}`)
                }
              >
                View Meeting
              </a>
            )}
          </Grid>
          {!open && (
            <Grid item xs={8} sm={8}>
              <div style={{ lineHeight: "1.3" }}>
                {/* <components.Snippet hit={transcript} attribute="transcript">
                  <components.Highlight
                    hit={transcript}
                    attribute="transcript"
                  />
                </components.Snippet> */}
                <Highlighter
                  searchWords={[query]}
                  autoEscape={true}
                  textToHighlight={transcript.transcript}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            borderRadius="10px"
          >
            <Box
              height={200}
              style={{
                borderRadius: "10px",
                margin: "5px 0 20px 0",
                overflow: "auto",
              }}
            >
              <Grid container spacing={1}>
                {open && (
                  <Grid item xs={8} sm={8}>
                    <div style={{ lineHeight: "1.3" }}>
                      {/* <components.Snippet
                        hit={transcript}
                        attribute="transcript"
                      >
                        <components.Highlight
                          hit={transcript}
                          attribute="transcript"
                        />
                      </components.Snippet> */}
                      <Highlighter
                        searchWords={[query]}
                        autoEscape={true}
                        textToHighlight={transcript.transcript}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <div
                style={{
                  borderRadius: "10px",
                  margin: "10px 0 20px 0",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  <Box margin={1}>
                    <a
                      href="Javascript:;"
                      onClick={() =>
                        (window.location.href = `/meeting/${transcript.meetingId}`)
                      }
                    >
                      Transcript details:
                    </a>
                  </Box>
                  {transcripts &&
                    transcripts.map((doc, key) => {
                      if (doc.id === transcript.objectID) {
                        return (
                          <Box
                            key={key}
                            padding={1}
                            style={{ lineHeight: "1.3" }}
                            display="flex"
                            justifyContent="flex-start"
                            gap="10px"
                            ref={myRef}
                          >
                            <Grid item xs={3} sm={3}>
                              <Grid item color="#707070">
                                {doc.timestamp.seconds
                                  ? moment(doc.timestamp.seconds * 1000).format(
                                      "hh:mm:ss"
                                    )
                                  : "--"}
                              </Grid>
                              <Grid item fontWeight="bold" fontSize="14px">
                                <p>{doc.speaker}</p>
                              </Grid>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                              <Grid item>
                                {/* <components.Highlight
                                  hit={transcript}
                                  attribute="transcript"
                                /> */}
                                <Highlighter
                                  searchWords={[query]}
                                  autoEscape={true}
                                  textToHighlight={transcript.transcript}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      } else {
                        return (
                          <Box
                            key={key}
                            padding={1}
                            style={{ lineHeight: "1.3" }}
                            display="flex"
                            justifyContent="flex-start"
                            gap="10px"
                          >
                            <Grid item xs={3} sm={3}>
                              <Grid item color="#707070">
                                {doc.timestamp.seconds
                                  ? moment(doc.timestamp.seconds * 1000).format(
                                      "hh:mm:ss"
                                    )
                                  : "--"}
                              </Grid>
                              <Grid item fontWeight="bold" fontSize="14px">
                                <p>{doc.speaker}</p>
                              </Grid>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                              <Grid item>
                                <p>{doc.text}</p>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      }
                    })}
                </div>
              </div>
            </Box>
          </Box>
        </Collapse>
      </div>
    </div>
  );
}

export default function TranscriptsByMeeting({
  transcript,
  //   components,
  //   insights,
  query,
  setShowSuggestions,
  searchInput,
  //   page,
}) {
  useEffect(() => {
    // document
    //   .getElementById("global-search-input")
    //   .addEventListener("blur", function (e) {
    //     setShowSuggestions(false);
    //   });
    // document
    //   .getElementById("global-search-input")
    //   .addEventListener("focus", function (e) {
    //     setShowSuggestions(true);
    //   });
  }, []);
  return (
    <div style={{ borderRadius: "10px" }}>
      {transcript.map((t, i) => {
        return (
          <Transcript
            key={i}
            // insights={insights}
            // components={components}
            transcript={t}
            query={query}
            setShowSuggestions={setShowSuggestions}
            searchInput={searchInput}
          />
        );
      })}
    </div>
  );
}
