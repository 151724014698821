import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { blue } from "@mui/material/colors";

export default function OrganizationList(props) {
  const { organizations, selectOrganization, orgid } = props;
  const getFavicon = async (domain) => {
    let url = "https://" + domain + "/favicon.ico";
    let img = new Image();
    img.src = url;
    return await new Promise((resolve) => {
      img.onerror = () => {
        resolve(false);
      };
      img.onload = () => {
        resolve(true);
      };
    }).then((res) => res);
  };

  return (
    <>
      {orgid ? (
        <List
          sx={{
            mx: "auto",
            width: "100%",
            maxWidth: 360,
            mt: 2,
          }}
        >
          {organizations
            .filter((org) => orgid === org.id)
            .map((organization, i) => (
              <ListItem
                key={"organization_" + i}
                alignItems="center"
                sx={{
                  border: 1,
                  borderColor: "grey.300",
                  boxShadow: "0px 0px 10px #e5e6ff",
                  borderRadius: 3,
                  mt: 1,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <ListItemAvatar>
                  {getFavicon(organization.domain) === true ? (
                    <img
                      src={"https://" + organization.domain + "/favicon.ico"}
                      width={28}
                      height={28}
                    />
                  ) : (
                    <Avatar>{organization.name.charAt(0).toUpperCase()}</Avatar>
                  )}
                </ListItemAvatar>

                <ListItemText
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "block" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {organization.name}
                      </Typography>
                      {`${organization.access.length} ${
                        organization.access.length > 1
                          ? "teammates"
                          : "teammate"
                      } in this organization`}
                    </>
                  }
                />
                <IconButton
                  aria-label="change"
                  size="small"
                  onClick={() => selectOrganization(null)}
                >
                  <EditOutlinedIcon
                    fontSize="inherit"
                    sx={{ color: blue[800], cursor: "pointer" }}
                  />
                </IconButton>
              </ListItem>
            ))}
        </List>
      ) : (
        <>
          <Typography
            align="center"
            sx={{ display: "block" }}
            component="span"
            variant="h6"
            color="grey.700"
          >
            Your teammates are already using bluecap™ Meeting Assistant
          </Typography>
          <Typography
            sx={{ display: "block" }}
            align="center"
            variant="body2"
            color={"grey.600"}
          >
            Join an existing organization.
          </Typography>
          <List
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: 360,
              mt: 2,
            }}
          >
            {organizations.map((organization, i) => (
              <ListItem
                key={"organization_" + i}
                alignItems="center"
                sx={{
                  border: 1,
                  borderColor: "grey.300",
                  boxShadow: "0px 0px 10px #e5e6ff",
                  borderRadius: 3,
                  mt: 1,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                onClick={() => selectOrganization(organization.id)}
                data-organization-name={organization.name}
                className="organization-list-item"
              >
                <ListItemAvatar
                    data-organization-name={organization.name}
                    className="organization-list-item-avatar"
                >
                  {getFavicon(organization.domain) === true ? (
                    <img
                      src={"https://" + organization.domain + "/favicon.ico"}
                      width={28}
                      height={28}
                    />
                  ) : (
                    <Avatar>{organization.name.charAt(0).toUpperCase()}</Avatar>
                  )}
                </ListItemAvatar>

                <ListItemText
                  data-organization-name={organization.name}
                  className="organization-list-item-text"
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "block" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {organization.name}
                      </Typography>
                      {`${organization.access.length} ${
                        organization.access.length > 1
                          ? "teammates"
                          : "teammate"
                      } in this organization`}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Typography
            sx={{ display: "block", mt: 3 }}
            align="center"
            variant="body3"
            color={"grey.600"}
          >
            Want to create a new organization?
          </Typography>
          <Button
            id="create-organization-button"
            variant="text"
            sx={{ display: "block", mx: "auto", textTransform: "none" }}
            onClick={() => props.showCreateOrganization()}
          >
            Create a new organization
          </Button>
        </>
      )}
    </>
  );
}
