import React from "react";

import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AttachmentIcon from "@mui/icons-material/Attachment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import UpdateIcon from "@mui/icons-material/Update";
import Chip from "@mui/material/Chip";
import moment from "moment";

const getFormattedClosingDate = (date) => {
  let formattedDate = moment(date).fromNow();

  formattedDate = formattedDate.replace("minutes", "mins");
  formattedDate = formattedDate.replace("minute", "min");

  formattedDate = formattedDate.replace("seconds", "secs");
  formattedDate = formattedDate.replace("second", "sec");

  return formattedDate;
};

const getFormattedClosingDateTooltip = (deal) => {
  return `Expected closing at ${moment(
    deal.expectedClosingDate.toDate()
  ).format("lll")}`;
};

const DealComponent = ({
  deal,
  handleOpenDealClick,
  handleDeleteDealClick,
}) => (
  <div
    className="task"
    style={{ cursor: "pointer" }}
    onClick={() => {
      handleOpenDealClick(deal.id);
    }}
  >
    <div className="title-container">
      <h6 className="title" style={{ marginLeft: 0 }}>
        {deal.title}
      </h6>
    </div>
    <div className="drag-icon">
      <DragIndicatorIcon sx={{ color: "#707070", fontSize: 14 }} />
    </div>

    <div className="icons">
      <div>
        <ChatBubbleOutlineIcon sx={{ color: "#2C73FF", fontSize: 18 }} />
        <span>{deal.commentsCount ? deal.commentsCount : 0}</span>
      </div>
      <div>
        <AttachmentIcon
          sx={{ color: "#2C73FF", transform: "rotate(-45deg)", fontSize: 20 }}
        />
        <span>{deal.attachmentsCount ? deal.attachmentsCount : 0}</span>
      </div>
      {/* <div>
        <VisibilityOutlinedIcon sx={{ color: "#2C73FF", fontSize: 18 }} />
        <span>0</span>
      </div> */}

      <div>
        <Tooltip title={getFormattedClosingDateTooltip(deal)}>
          <Chip
            size="small"
            icon={<UpdateIcon />}
            color="primary"
            style={{ height: "20px" }}
            label={
              deal.expectedClosingDate
                ? getFormattedClosingDate(deal.expectedClosingDate.toDate())
                : ""
            }
          />
        </Tooltip>
      </div>

      <div>
        <DeleteOutlineIcon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteDealClick(deal.id);
          }}
          sx={{ color: "#2C73FF", fontSize: 18 }}
        />
      </div>
    </div>
  </div>
);

export default function DealColumn({
  droppableId,
  deals,
  title,
  addDeal,
  handleOpenDealClick,
  handleDeleteDealClick,
}) {
  return (
    <div className="task-container">
      <div className="task-header">
        <h6>{title}</h6>
        <span>{deals.length}</span>
      </div>
      <div className="add" onClick={() => addDeal(title)}>
        <AddIcon sx={{ fontSize: 14, color: "#2C73FF", mr: 1 }} />
        Add Deal
      </div>
      <Droppable droppableId={droppableId} type="DEAL">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`task-background`}
          >
            {deals.map((deal, i) => {
              return (
                <Draggable key={`${deal.id}`} draggableId={deal.id} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DealComponent
                        deal={deal}
                        handleOpenDealClick={handleOpenDealClick}
                        handleDeleteDealClick={handleDeleteDealClick}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            <div
              className={`${
                snapshot.isDraggingOver && title === "Completed"
                  ? "dragging completed"
                  : snapshot.isDraggingOver
                  ? "dragging"
                  : ""
              }`}
            />
          </div>
        )}
      </Droppable>
    </div>
  );
}
