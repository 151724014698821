import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { deleteContacts } from "./../../../firebase/firestore.js";
import { Box, Button, CircularProgress, Container } from "@material-ui/core";
import Page from "../../Page";
import ListTabular from "../ListTabular";
import MergeLeadContactDialog from "../MergeLeadContactDialog";

export default function ContactsList() {
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [deleteContactDialogStatus, setDeleteContactDialogStatus] =
    useState(false);
  const [contactDeleting, setContactDeleting] = useState(false);
  const [mergeDialogStatus, setMergeDialogStatus] = useState(false);

  const removeContactsFromList = (removeContacts) => {
    setContacts(contacts.filter((item) => !removeContacts.includes(item.id)));
  };

  return (
    <Page>
      <Dialog
        fullWidth
        open={deleteContactDialogStatus}
        onClose={() => setDeleteContactDialogStatus(false)}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={3}
        >
          <p>
            Are you sure you want to delete <b>{selectedContacts.length}</b>{" "}
            contact
            {selectedContacts.length > 1 ? "s" : ""}?
          </p>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setDeleteContactDialogStatus(false);
              }}
            >
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setContactDeleting(true);
                let res = await deleteContacts(selectedContacts);
                setContactDeleting(false);
                if (res.success == true) {
                  removeContactsFromList(selectedContacts);
                  setDeleteContactDialogStatus(false);
                  setSelectedContacts([]);
                }
              }}
            >
              Delete{" "}
            </Button>
            {contactDeleting && (
              <Box>
                <CircularProgress style={{ color: "blue" }} size="15px" />
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
      {mergeDialogStatus && (
        <MergeLeadContactDialog
          onClose={() => setMergeDialogStatus(false)}
          onSuccessClose={() => {
            setSelectedContacts([]);
            removeContactsFromList(selectedContacts.slice(1));
            setMergeDialogStatus(false);
          }}
          selecteds={selectedContacts}
          type={"contacts"}
        />
      )}
      <Container maxWidth={"xl"}>
        <ListTabular
          stateData={contacts}
          setStateData={setContacts}
          setDeleteLeadDialogStatus={setDeleteContactDialogStatus}
          setMergeDialogStatus={setMergeDialogStatus}
          selectedRows={selectedContacts}
          setSelectedRows={setSelectedContacts}
          type={"contacts"}
        />
      </Container>
    </Page>
  );
}
