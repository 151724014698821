import React, { useState } from "react";

import { Grid, Popover } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default function ParticipantsAvatars(props) {
  const { row } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  if (row && row.length > 0) {
    return (
      <Grid container>
        <Grid item xs={12} lg={8}>
          <AvatarGroup className="search-avatars-block" max={4}>
            {row.map((p, index) => {
              const { name, avatar } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              return (
                <Avatar
                  key={index}
                  alt={name}
                  src={avatar}
                  className="bulk-avatar"
                  style={{ width: "20px", height: "20px" }}
                >
                  {fChar}
                </Avatar>
              );
            })}
          </AvatarGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <a
            aria-describedby={row.id}
            onClick={handleClick}
            className="expand-arrow-icon"
            href="Javascript:;"
          >
            {open ? (
              <ExpandLessIcon className="arrow" />
            ) : (
              <ExpandMoreIcon className="arrow" />
            )}
          </a>
          <Popover
            id={row.id}
            open={open}
            style={{ zIndex: "1111111111" }}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { minWidth: "147px", paddingRight: "20px" },
            }}
          >
            {row.map((p, index) => {
              const { name, avatar } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              let txtToDisplay = name;
              return (
                <Grid key={index} container className="past-popup-participants">
                  <Grid item xs={3} lg={3}>
                    <Avatar alt={name} src={avatar} className="pop-avatar">
                      {fChar}
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} lg={9}>
                    {txtToDisplay}
                  </Grid>
                </Grid>
              );
            })}
          </Popover>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}
