import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AttachmentIcon from "@mui/icons-material/Attachment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getUserByUserId } from "../../firebase/firestore";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TextEditor from "../TextEditor";
import { EditorState, convertFromRaw } from "draft-js";
import Chip from "@mui/material/Chip";
import UpdateIcon from "@mui/icons-material/Update";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: ".3em !important",
  },
}));

const getFormattedDueDate = (date) => {
  let formattedDate = moment(date).fromNow();

  formattedDate = formattedDate.replace("minutes", "mins");
  formattedDate = formattedDate.replace("minute", "min");
  formattedDate = formattedDate.replace("ago", "");
  formattedDate = formattedDate.replace("seconds", "secs");
  formattedDate = formattedDate.replace("second", "sec");

  return formattedDate;
};

const getFormattedPriorityTooltip = (task) => {
  let priorityTitles = {
    low: "Low",
    medium: "Medium",
    high: "High",
    urgent: "Urgent",
  };
  if (task?.dueDate?.seconds) {
    return `Priority ${priorityTitles[task.priority]}, due at ${moment(
      new Date(task.dueDate.seconds * 1000)
    ).format("lll")}`;
  } else {
    return `Priority ${priorityTitles[task.priority]}`;
  }
};

const TaskComponent = ({
  task,
  handleOpenTaskClick,
  handleDeleteTaskClick,
}) => {
  const theme = useTheme();

  const [assignedUser, setAssignedUser] = useState({
    name: "",
    photoURL: "",
  });
  const [photoLoading, setPhotoLoading] = useState(true);
  const getUserPhoto = async () => {
    let userData = await getUserByUserId(task.assignedTo);
    setAssignedUser({
      name: userData?.displayName ? userData.displayName : "",
      photoURL: userData?.photoURL,
    });
    setPhotoLoading(false);
  };
  useEffect(getUserPhoto, []);

  const getEditorState = (content) => {
    try {
      const rawData = convertFromRaw(
        typeof content === "string" ? JSON.parse(content) : content
      );
      return EditorState.createWithContent(rawData);
    } catch (e) {
      return EditorState.createEmpty();
    }
  };

  let priorityColors = {
    low: "info",
    medium: "warning",
    high: "error",
    urgent: "error",
  };
  return (
    <div
      className="task"
      style={{ cursor: "pointer" }}
      onClick={() => {
        handleOpenTaskClick(task.id);
      }}
    >
      <div className="title-container">
        {photoLoading ? (
          <Skeleton variant="circular" width={30} height={30} />
        ) : (
          <LightTooltip title={assignedUser.name}>
            <Avatar
              src={assignedUser?.photoURL}
              style={{ height: 30, width: 30, border: "1px solid #2c73ff" }}
            />
          </LightTooltip>
        )}
        <h6 className="title">{task.title}</h6>
      </div>
      <div className="drag-icon">
        <DragIndicatorIcon sx={{ color: "#707070", fontSize: 14 }} />
      </div>
      <span className="description">
        <TextEditor
          readOnly={true}
          toolbarHidden
          editorState={getEditorState(task.description)}
          onEditorStateChange={() => {}}
          handlePastedFiles={() => {}}
        />
      </span>
      <div className="icons">
        <div>
          <ChatBubbleOutlineIcon sx={{ color: "#2C73FF", fontSize: 18 }} />
          <span>{task.commentsCount ? task.commentsCount : 0}</span>
        </div>
        <div>
          <AttachmentIcon
            sx={{ color: "#2C73FF", transform: "rotate(-45deg)", fontSize: 20 }}
          />
          <span>{task.attachmentsCount ? task.attachmentsCount : 0}</span>
        </div>
        {/* <div>
          <VisibilityOutlinedIcon sx={{ color: "#2C73FF", fontSize: 18 }} />
          <span>0</span>
        </div> */}
        <Tooltip title={getFormattedPriorityTooltip(task)}>
          <div
            style={{
              border: `1px solid ${
                task.priority === "high"
                  ? theme.palette[priorityColors[task.priority]].light
                  : theme.palette[priorityColors[task.priority]].main
              }`,
              padding: "0 .3em",
              borderRadius: ".3em",
            }}
          >
            <UpdateIcon
              sx={{
                color: `${
                  task.priority === "high"
                    ? theme.palette[priorityColors[task.priority]].light
                    : theme.palette[priorityColors[task.priority]].main
                }`,
                fontSize: 18,
              }}
            />
            <span
              style={{
                color: `${
                  task.priority === "high"
                    ? theme.palette[priorityColors[task.priority]].light
                    : theme.palette[priorityColors[task.priority]].main
                }`,
              }}
            >
              {getFormattedDueDate(
                task?.dueDate?.toDate ? task.dueDate.toDate() : new Date()
              )}
            </span>
            {/*
              <Chip
              size="small"
              icon={<UpdateIcon />}
              style={
                task.priority == "high"
                  ? {
                      height: "20px",
                      backgroundColor:
                        theme.palette[priorityColors[task.priority]].light,
                    }
                  : {
                      height: "20px",
                      backgroundColor:
                        theme.palette[priorityColors[task.priority]].main,
                    }
              }
              label={
                task?.dueDate?.toDate ? getFormattedDueDate(task.dueDate.toDate()) : ""
              }
            />
               */}
          </div>
        </Tooltip>
        <div>
          <DeleteOutlineIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteTaskClick(task.id);
            }}
            sx={{ color: "#2C73FF", fontSize: 18 }}
          />
        </div>
      </div>
    </div>
  );
};
export default function TaskColumn({
  droppableId,
  tasks,
  title,
  addTask,
  handleOpenTaskClick,
  handleDeleteTaskClick,
}) {
  return (
    <div className="task-container">
      <div className="task-header">
        <h6>{title}</h6>
        <span>{tasks.length}</span>
      </div>
      <div className="add" onClick={() => addTask(title)}>
        <AddIcon sx={{ fontSize: 14, color: "#2C73FF", mr: 1 }} />
        Add task
      </div>
      <Droppable droppableId={droppableId} type="TASK">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`task-background`}
          >
            {tasks.map((task, i) => {
              return (
                <Draggable key={`${task.id}`} draggableId={task.id} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TaskComponent
                        task={task}
                        handleOpenTaskClick={handleOpenTaskClick}
                        handleDeleteTaskClick={handleDeleteTaskClick}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            <div
              className={`${
                snapshot.isDraggingOver && title === "Completed"
                  ? "dragging completed"
                  : snapshot.isDraggingOver
                  ? "dragging"
                  : ""
              }`}
            />
          </div>
        )}
      </Droppable>
    </div>
  );
}
