import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getLead, getUserByUserId } from "./../../../firebase/firestore.js";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getData } from "./../../../utils";
import firebase from "./../../../firebase/firebase.js";
import { Grid, Container } from "@material-ui/core";
import moment from "moment";

import LeadTimeline from "./LeadTimeline";
import EngagementScore from "../../charts.js/EngagementScore";
import LeadDetailProfile from "./LeadDetailProfile";
import LeadStatus from "./LeadStatus";
import SentimentScore from "../../charts.js/SentimentScore";
import BubbleChart from "../../charts.js/BubbleChart";
import UpcomingMeetingsTable from "../UpcomingMeetingsTable";
import PastMeetingsTable from "../PastMeetingsTable";
import Attachments from "../Attachments";
import Notes from "../Notes";
import Tasks from "../Tasks";
import { Post } from "../../../utils/axiosUtils";
import Page from "../../Page";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAuth from "../../../hooks/useAuth";
import { getOrgainzation } from "./../../../firebase/firestore";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Avatar from "@material-ui/core/Avatar";
import ProfileNameRole from "../ProfileNameRole";
import LeadContactInsights from "../LeadContactInsights";

const leadSelectOptions = [
  {
    value: 1,
    label: "Prospecting",
  },
  {
    value: "email",
    label: "Contact confirmed",
  },
  {
    value: "accountName",
    label: "Company confirmed",
  },
  {
    value: 4,
    label: "Junk/Not qualified",
  },
];

export default function LeadDetails() {
  const { fireStoreuserObj } = useAuth();
  const history = useHistory();
  const { leadId } = useParams();
  const [leadDetails, setLeadDetails] = useState(null);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [fetchData, setFetchData] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [convertButtonDisabled, setConvertButtonDisabled] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  const sentimentAvailableChecker = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "MEMBER" &&
        sentimentSettings?.users === "all") ||
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );
  };

  useEffect(sentimentAvailableChecker, []);

  const fetchDataFromServer = () => {
    Post("meeting/getLeadInteractionResult", { leadId })
      .then(({ data: { data } }) => {
        setFetchData(data);
      })
      .catch((err) => {
        console.log(err, "fetch data errr");
      });
  };

  useEffect(() => {
    fetchDataFromServer();
    let getLeadDetails = async () => {
      let res = await getLead(leadId);
      if (res.success) {
        console.log(res.lead, "LEAD");
        setInputs({
          email: res?.lead?.email,
          role: res?.lead?.role,
          account: res?.lead?.account,
          accountName: res?.lead?.accountName,
          noOfEmployee: res?.lead?.noOfEmployee,
          website: res?.lead?.website,
          gender: res?.lead?.gender,
          telephoneNumber: res?.lead?.telephoneNumber,
          assignee: res?.lead?.assignee ? res.lead.assignee : [],
          industry: res?.lead?.industry,
          leadStatus: res?.lead?.leadStatus
            ? res.lead.leadStatus
            : leadSelectOptions[0].label,
        });
        let createdByUser = await getUserByUserId(res.lead.createdBy);
        res.lead.createdByUser = createdByUser.displayName;

        if (res.lead.modifiedBy) {
          let updatedByUser = await getUserByUserId(res.lead.modifiedBy);
          res.lead.updatedByUser = updatedByUser.displayName;
        }
        setLeadDetails(res.lead);
        getUpcomingMeetings(res.lead);
      }
    };

    const getUpcomingMeetings = async (tmpLeadDetails) => {
      let jwt = await firebase.auth().currentUser.getIdToken();
      let upcomingMeetingsResponse = await getData(
        `${process.env.REACT_APP_BASE_API_URL}/calendar/upcomingEvents?perPage=20&page=1`,
        jwt
      );

      if (upcomingMeetingsResponse.status == "success") {
        let meetingWithLead = upcomingMeetingsResponse.data.filter(
          (meeting) => {
            if (tmpLeadDetails.email) {
              return meeting.participants.some(
                (participant) => participant.email == tmpLeadDetails.email
              );
            }
          }
        );
        setUpcomingMeetings(meetingWithLead);
      }
    };

    getLeadDetails();
  }, []);

  const initialLeadStatus = () => {
    if (!inputs) {
      return;
    }
    if (leadDetails?.email !== "") {
      if (leadDetails?.accountName && leadDetails?.accountName !== "") {
        setConvertButtonDisabled(false);
      } else {
        setConvertButtonDisabled(true);
      }
    } else {
      setConvertButtonDisabled(true);
    }
  };

  useEffect(initialLeadStatus, [leadDetails]);

  const handleInputChange = (e) => {
    let newInputs = Object.assign({}, inputs);
    newInputs[e.target.name] = e.target.value;
    setInputs(newInputs);
  };

  const assigneeChange = (assigness) => {
    let allIds = [];
    assigness?.map((item) => allIds.push(item.value));
    handleInputChange({ target: { name: "assignee", value: allIds } });
  };

  const doc = document.getElementById("analyticChartsContainer");
  const handleScroll = (rotate) => {
    doc?.scrollTo({
      top: 0,
      left: rotate === "right" ? doc.scrollWidth : 0,
      behavior: "smooth",
    });
    setScrollPosition(rotate === "right" ? 1020 : 0);
  };

  if (!leadDetails) {
    return <p>Lead details: {leadId}</p>;
  } else {
    return (
      <Page>
        <Container maxWidth={"xl"}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {!leadDetails ? (
                <Stack spacing={1} animation="wave">
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={25}
                    sx={{ bgcolor: "#cCc" }}
                  />
                </Stack>
              ) : (
                <Box display="flex" alignItems="center">
                  <Link to={"/crm/leads"} className={"breadcrumbLink"}>
                    Leads
                  </Link>
                  <NavigateNextIcon
                    sx={{ color: "#676767", margin: "0 .5em" }}
                  />
                  <span className="breadcrumbText">{`${
                    leadDetails?.firstName
                  } ${
                    leadDetails?.lastName ? leadDetails.lastName : ""
                  }`}</span>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box style={{ marginRight: "10px", color: "#707070" }}>
                <p style={{ fontSize: "11px" }}>
                  Created by <b>{leadDetails.createdByUser}</b> at{" "}
                  <b>{moment(leadDetails.createdAt.toDate()).format("lll")}</b>
                </p>
                {leadDetails.updatedByUser ? (
                  <p style={{ fontSize: "11px" }}>
                    Updated by <b>{leadDetails.updatedByUser}</b> at{" "}
                    <b>
                      {moment(leadDetails.updatedAt.toDate()).format("lll")}
                    </b>
                  </p>
                ) : (
                  <p style={{ fontSize: "11px" }}>Not updated yet!</p>
                )}
              </Box>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() =>
                  history.push({
                    pathname: `/crm/lead/edit/${leadId}`, // state: { from: "editLead", leadId },
                  })
                }
                variant="outlined"
                color="secondary"
              >
                Edit
              </Button>
              <Button
                onClick={() =>
                  history.push({
                    pathname: `/crm/lead/${leadId}/convert`,
                  })
                }
                disabled={convertButtonDisabled}
                variant="contained"
                color="secondary"
              >
                Convert
              </Button>
            </Box>
          </Box>
          <ProfileNameRole
            profileDetails={leadDetails}
            handleInputChange={handleInputChange}
            leadId={leadId}
            inputs={inputs}
            setInputs={setInputs}
          />
          <Box
            sx={{ padding: ".5em 0", mt: 1, display: "flex" }}
            className="lead-contact-profile-detail-container"
          >
            <Box display="flex" alignItems="center" flexDirection="column">
              <LeadDetailProfile
                data={leadDetails}
                leadId={leadId}
                handleInputChange={handleInputChange}
                inputs={inputs}
                setDetails={setLeadDetails}
                assigneeChange={assigneeChange}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <LeadStatus
                data={leadDetails}
                setLeadDetails={setLeadDetails}
                leadId={leadId}
                inputs={inputs}
                leadStatusSelectOptions={leadSelectOptions}
                setInputs={setInputs}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
              width={"100%"}
            >
              <LeadContactInsights leadId={leadId} />
            </Box>
          </Box>
          <Box sx={{ padding: ".5em 0" }} className="arrow-scroll-container">
            {scrollPosition > 0 && (
              <div
                className="arrow left lead"
                onClick={() => handleScroll("left")}
              >
                <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
              </div>
            )}

            <Box sx={{ display: "flex", mt: 1 }} id={"analyticChartsContainer"}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <EngagementScore data={fetchData} />
              </Box>

              {isSentimentAvailable && (
                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft={3}
                  flexDirection="column"
                >
                  <SentimentScore data={fetchData} />
                </Box>
              )}

              <Box
                display="flex"
                alignItems="center"
                marginLeft={3}
                flexDirection="column"
              >
                <BubbleChart
                  type={{ value: "matchedCompetitors" }}
                  data={fetchData}
                  dateRangeHandler={() => false}
                  isSentimentAvailable={isSentimentAvailable}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                marginLeft={3}
                flexDirection="column"
              >
                <BubbleChart
                  type={{ value: "matchedProducts" }}
                  data={fetchData}
                  dateRangeHandler={() => false}
                  isSentimentAvailable={isSentimentAvailable}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                marginLeft={3}
                flexDirection="column"
              >
                <BubbleChart
                  type={{ value: "topicSentiments" }}
                  data={fetchData}
                  dateRangeHandler={() => false}
                  isSentimentAvailable={isSentimentAvailable}
                />
              </Box>
            </Box>
            {scrollPosition === 0 && (
              <div
                className="arrow right lead"
                onClick={() => handleScroll("right")}
              >
                <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
              </div>
            )}
          </Box>

          {process.env.REACT_APP_APP_ENV === "dev" && (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7} sx={{ paddingTop: "8px !important" }}>
                <LeadTimeline />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ paddingTop: "8px !important" }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Notes typeId={leadId} type={"leads"} />
                  {/* <Tasks
                    leadId={leadId}
                    lastName={leadDetails.lastName}
                    leadDetails={leadDetails}
                    mt={2}
                  /> */}
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3} sx={{ height: "100%", marginTop: "1em" }}>
            <Grid item xs={12} lg={6}>
              <Notes
                typeId={leadId}
                type={"leads"}
                height={"calc(100% - 35px)"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Tasks
                leadId={leadId}
                lastName={leadDetails.lastName}
                type={"lead"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PastMeetingsTable
                data={fetchData}
                isSentimentAvailable={isSentimentAvailable}
                from={"crm"}
              />
            </Grid>

            <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
              <UpcomingMeetingsTable
                data={upcomingMeetings}
                meetingTitle={`Meeting with ${
                  leadDetails?.firstName ? leadDetails.firstName : ""
                } ${leadDetails?.lastName ? leadDetails.lastName : ""}`}
                email={inputs?.email}
              />
              <Attachments type={"leads"} typeId={leadId} />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
