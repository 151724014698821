import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { Get, Post, Delete } from "../../utils/axiosUtils";
import { CircularProgress } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";

function Attachments({ type, typeId, hMax }) {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState({ status: false, uploadType: "" });
  const [attachments, setAttachments] = useState([]);
  const [attachmentLoading, setAttachmentLoading] = useState({
    status: false,
    attachmentId: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [uploadUrlText, setUploadUrlText] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAttachments = () => {
    Get(`attachments/${type}/${typeId}`)
      .then(({ data }) => {
        setAttachments(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error happen when fetching attachments");
      });
  };
  useEffect(getAttachments, []);
  const fileInputOpener = () => {
    let doc = document.getElementById("attachment-input");
    if (doc) {
      doc?.click();
    }
  };
  const fileUpload = (e, uploadType = "disk") => {
    setUploading({ status: true, uploadType });
    let formData = new FormData();
    if (uploadType === "disk") {
      formData.append("attachment", e.target.files[0]);
    } else {
      formData.append("file_url", uploadUrlText);
    }
    formData.append("type", type);
    formData.append("typeid", typeId);
    Post("attachments/create", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(({ data }) => {
        setAttachments([data.data, ...attachments]);
        setUploading({ status: false, uploadType: "" });
        setAnchorEl(false);
        setUploadUrlText("");
      })
      .catch((err) => {
        console.log(err);
        setUploading({ status: false, uploadType: "" });
      });
  };

  const attachmentOpen = (attachmentId) => {
    setAttachmentLoading({ status: true, attachmentId });
    Get(`attachments/${attachmentId}`)
      .then(({ data }) => {
        window.open(data.data.url, "_blank");
        setAttachmentLoading({ status: false, attachmentId: "" });
      })
      .catch((err) => {
        console.log(err, "error happen when opening attachment");
        setAttachmentLoading({ status: false, attachmentId: "" });
      });
  };

  const deleteAttachment = (e, attachmentId) => {
    e.stopPropagation();
    setAttachmentLoading({ status: true, attachmentId });
    Delete(`attachments/${attachmentId}`)
      .then(() => {
        let filterAttachments = attachments.filter(
          (item) => item.id !== attachmentId
        );
        setAttachments(filterAttachments);
        setAttachmentLoading({ status: false, attachmentId: "" });
      })
      .catch((err) => {
        console.log(err, "error happen when deleting attachment");
      });
  };

  return (
    <Box mt={2} sx={hMax ? { height: "100%" } : {}}>
      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
        <h6 className="agenda">Attachments</h6>
        <input
          type="file"
          style={{ display: "none" }}
          id={"attachment-input"}
          onChange={fileUpload}
        />
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          onClick={handleClick}
        />
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 200,
              maxWidth: "40ch",
            },
          }}
        >
          <Box p={2}>
            <Box display="flex" alignItems="center">
              <TextField
                width="fullwidth"
                label="Upload via url"
                variant="outlined"
                value={uploadUrlText}
                onChange={(e) => setUploadUrlText(e.target.value)}
                className={"tasks-title-input"}
                style={{ marginRight: "1em" }}
              />
              <button
                className="modal-button blue"
                onClick={(e) => fileUpload(e, "url")}
                disabled={uploading?.status}
                style={{ height: "43px", width: "90px" }}
              >
                {uploading?.status && uploading?.uploadType === "url" ? (
                  <CircularProgress size="15px" style={{ color: "#fff" }} />
                ) : (
                  "Upload"
                )}
              </button>
            </Box>
            <Box width="100%">
              <button
                className="modal-button white"
                onClick={fileInputOpener}
                disabled={uploading?.status}
                style={{ marginTop: "1em", width: "100%" }}
              >
                {uploading?.status && uploading?.uploadType === "disk" ? (
                  <CircularProgress size={"15px"} style={{ color: "2c73ff" }} />
                ) : (
                  "Upload from disk"
                )}
              </button>
            </Box>
          </Box>
        </Menu>
      </Box>
      <div
        className="details-box"
        style={{ height: hMax ? "calc(100% - 35px)" : "150px" }}
      >
        {loading ? (
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            height={"100%"}
          >
            <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
          </Box>
        ) : (
          <div className="attachments">
            {attachments?.map((attachment, index) => (
              <div
                className="attachment"
                key={index}
                onClick={() => attachmentOpen(attachment.id)}
              >
                <span className="title">{attachment.fileName}</span>
                {attachmentLoading?.status &&
                attachmentLoading?.attachmentId === attachment.id ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <CircularProgress
                      style={{ color: "#303030" }}
                      size="12px"
                    />
                  </Box>
                ) : (
                  <ClearIcon
                    fontSize={"18px"}
                    color={"#303030"}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => deleteAttachment(e, attachment.id)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
}

export default Attachments;
