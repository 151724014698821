import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LeadDetailProfile from "../Leads/LeadDetailProfile";
import EngagementScore from "../../charts.js/EngagementScore";
import SentimentScore from "../../charts.js/SentimentScore";
import BubbleChart from "../../charts.js/BubbleChart";
import {
  getContact,
  getOrgainzation,
  getUserByUserId,
} from "../../../firebase/firestore";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import UpcomingMeetingsTable from "../UpcomingMeetingsTable";
import PastMeetingsTable from "../PastMeetingsTable";
import firebase from "../../../firebase/firebase";
import { getData } from "../../../utils";
import { Post } from "../../../utils/axiosUtils";
import LeadTimeline from "../Leads/LeadTimeline";
import Notes from "../Notes";
import Tasks from "../Tasks";
import Deals from "../Deals";
import Attachments from "../Attachments";
import Page from "../../Page";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAuth from "../../../hooks/useAuth";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProfileNameRole from "../ProfileNameRole";
import LeadContactInsights from "../LeadContactInsights";
import moment from "moment";

export default function ContactDetails() {
  const { fireStoreuserObj } = useAuth();
  const history = useHistory();
  const location = useLocation();
  let { contactId } = useParams();
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leadId, setLeadId] = useState(null);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [fetchData, setFetchData] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  const sentimentAvailableChecker = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "MEMBER" &&
        sentimentSettings?.users === "all") ||
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );
  };

  useEffect(sentimentAvailableChecker, []);

  const handleInputChange = (e) => {
    let newInputs = Object.assign({}, inputs);
    newInputs[e.target.name] = e.target.value;
    setInputs(newInputs);
  };

  const assigneeChange = (assigness) => {
    let allIds = [];
    assigness?.map((item) => allIds.push(item.value));
    handleInputChange({ target: { name: "assignee", value: allIds } });
  };

  const fetchDataFromServer = () => {
    Post("meeting/getContactsInteractionResult", { contactIds: [contactId] })
      .then(({ data: { data } }) => {
        setFetchData(data[0]?.meetings);
      })
      .catch((err) => {
        console.log(err, "fetch data errr");
      });
  };

  let getUpcomingMeetings = async (tmpLeadDetails) => {
    let jwt = await firebase.auth().currentUser.getIdToken();
    let upcomingMeetingsResponse = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/calendar/upcomingEvents?perPage=20&page=1`,
      jwt
    );

    if (upcomingMeetingsResponse.status == "success") {
      let meetingWithLead = upcomingMeetingsResponse.data.filter((meeting) => {
        if (tmpLeadDetails.email) {
          return meeting.participants.some(
            (participant) => participant.email == tmpLeadDetails.email
          );
        }
      });
      setUpcomingMeetings(meetingWithLead);
    }
  };

  const getContactDetails = async () => {
    let res = await getContact(contactId);
    if (res.success) {
      setInputs({
        email: res?.contact?.email,
        role: res?.contact?.role,
        accountName: res?.contact?.accountName,
        noOfEmployee: res?.contact?.noOfEmployee,
        website: res?.contact?.website,
        gender: res?.contact?.gender,
        telephoneNumber: res?.contact?.telephoneNumber,
        assignee: res?.contact?.assignee ? res.contact.assignee : [],
      });

      let createdByUser = await getUserByUserId(res.contact.createdBy);
      res.contact.createdByUser = createdByUser.displayName;

      if (res.contact.modifiedBy) {
        let updatedByUser = await getUserByUserId(res.contact.modifiedBy);
        res.contact.updatedByUser = updatedByUser.displayName;
      }
      setContactDetails(res.contact);
      setLeadId(res?.contact?.leadId);
      setLoading(false);
      getUpcomingMeetings(res.contact);
    }
  };

  useEffect(() => {
    getContactDetails();
    fetchDataFromServer();
  }, []);

  const refreshUpcomingMeetings = () => {
    getContactDetails();
  };

  if (loading) {
    return (
      <Box justifyContent="space-around" display="flex" padding={2}>
        <CircularProgress style={{ color: "blue" }} size="20px" />
      </Box>
    );
  }

  const doc = document.getElementById("analyticChartsContainer");
  const handleScroll = (rotate) => {
    doc?.scrollTo({
      top: 0,
      left: rotate === "right" ? doc.scrollWidth : 0,
      behavior: "smooth",
    });
    setScrollPosition(rotate === "right" ? 1020 : 0);
  };

  return (
    <Page>
      <Container maxWidth={"xl"}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {!contactDetails ? (
              <Stack spacing={1} animation="wave">
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={25}
                  sx={{ bgcolor: "#cCc" }}
                />
              </Stack>
            ) : (
              <Box display="flex" alignItems="center">
                <Link to={"/crm/contacts"} className={"breadcrumbLink"}>
                  Contacts
                </Link>
                <NavigateNextIcon sx={{ color: "#676767", margin: "0 .5em" }} />
                <span className="breadcrumbText">{`${
                  contactDetails?.firstName
                } ${
                  contactDetails?.lastName ? contactDetails.lastName : ""
                }`}</span>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box style={{ marginRight: "10px", color: "#707070" }}>
              <p style={{ fontSize: "11px" }}>
                Created by <b>{contactDetails.createdByUser}</b> at{" "}
                <b>{moment(contactDetails.createdAt.toDate()).format("lll")}</b>
              </p>
              {contactDetails.updatedByUser ? (
                <p style={{ fontSize: "11px" }}>
                  Updated by <b>{contactDetails.updatedByUser}</b> at{" "}
                  <b>
                    {moment(contactDetails.updatedAt.toDate()).format("lll")}
                  </b>
                </p>
              ) : (
                <p style={{ fontSize: "11px" }}>Not updated yet!</p>
              )}
            </Box>

            <Button
              onClick={() => history.push(`/crm/contact/edit/${contactId}`)}
              variant="contained"
              color="secondary"
            >
              Edit
            </Button>
          </Box>
        </Box>
        <ProfileNameRole
          profileDetails={contactDetails}
          handleInputChange={handleInputChange}
          contactId={contactId}
          inputs={inputs}
          setInputs={setInputs}
        />
        <Box
          sx={{ padding: ".5em 0", mt: 1, display: "flex" }}
          className="lead-contact-profile-detail-container"
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <LeadDetailProfile
              data={contactDetails}
              contactId={contactId}
              handleInputChange={handleInputChange}
              assigneeChange={assigneeChange}
              inputs={inputs}
              setDetails={setContactDetails}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginLeft={3}
            width={"100%"}
          >
            <LeadContactInsights contactId={contactId} />
          </Box>
        </Box>
        <Box
          marginTop={2}
          sx={{ padding: ".5em 0" }}
          className="arrow-scroll-container"
        >
          {scrollPosition > 0 && (
            <div
              className="arrow left lead"
              onClick={() => handleScroll("left")}
            >
              <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
            </div>
          )}

          <Box sx={{ display: "flex" }} id={"analyticChartsContainer"}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <EngagementScore data={fetchData} />
            </Box>

            {isSentimentAvailable && (
              <Box
                display="flex"
                alignItems="center"
                marginLeft={3}
                flexDirection="column"
              >
                <SentimentScore data={fetchData} />
              </Box>
            )}

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "matchedCompetitors" }}
                data={fetchData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "matchedProducts" }}
                data={fetchData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginLeft={3}
              flexDirection="column"
            >
              <BubbleChart
                type={{ value: "topicSentiments" }}
                data={fetchData}
                dateRangeHandler={() => false}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Box>
          </Box>

          {scrollPosition === 0 && (
            <div
              className="arrow right lead"
              onClick={() => handleScroll("right")}
            >
              <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
            </div>
          )}
        </Box>
        {process.env.REACT_APP_APP_ENV === "dev" && (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} sx={{ paddingTop: "8px !important" }}>
              <LeadTimeline />
            </Grid>
            <Grid item xs={12} lg={5} sx={{ paddingTop: "8px !important" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Notes typeId={contactId} type={"contacts"} />
                <Tasks
                  contactId={contactId}
                  leadId={leadId}
                  type={"contact"}
                  lastName={contactDetails.lastName}
                  mt={2}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3} sx={{ height: "100%", marginTop: "1em" }}>
          <Grid item xs={12} lg={6}>
            <Notes
              typeId={contactId}
              type={"contacts"}
              height={"calc(100% - 35px)"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Tasks
              contactId={contactId}
              leadId={leadId}
              type={"contact"}
              lastName={contactDetails.lastName}
              mt={0}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PastMeetingsTable
              data={fetchData}
              isSentimentAvailable={isSentimentAvailable}
              from={"crm"}
              isContactDetail
            />
          </Grid>

          <Grid item xs={12} lg={6} display={"flex"} flexDirection={"column"}>
            <UpcomingMeetingsTable
              email={contactDetails.email}
              data={upcomingMeetings}
              refreshUpcomingMeetings={refreshUpcomingMeetings}
              meetingTitle={`Meeting with ${
                contactDetails?.firstName ? contactDetails?.firstName : ""
              } ${contactDetails?.lastName ? contactDetails.lastName : ""}`}
            />
            <Deals
              contactId={contactId}
              lastName={contactDetails.lastName}
              type={"contact"}
            />
            <Attachments type={"contacts"} typeId={contactId} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
