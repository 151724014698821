import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import * as crmActions from "./../../../redux/crm/actions";
import Chip from "@mui/material/Chip";
import { Box, Button, CircularProgress } from "@mui/material";

import {
  getLead,
  getOrgainzationAccounts,
  saveAccount,
  addContact,
  updateLead,
} from "./../../../firebase/firestore.js";

import * as actions from "./../../../redux/actions";
import { connect } from "react-redux";

const ConvertLead = (props) => {
  let { leadId } = useParams();
  let history = useHistory();

  let [isAccountExist, setIsAccountExist] = useState(false);
  let [converting, setConverting] = useState(false);
  let [account, setAccount] = useState("");
  let [leadName, setLeadName] = useState("");
  let [leadObject, setLeadObject] = useState({});

  const crm = useSelector((state) => state.crm);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let leadResponse = await getLead(leadId);

      if (leadResponse.success) {
        setLeadObject(leadResponse.lead);
        let accountsResponse = await getOrgainzationAccounts(
          leadResponse.lead.accountName
        );
        setAccount(leadResponse.lead.accountName);

        let name =
          leadResponse.lead.firstName && leadResponse.lead.firstName != ""
            ? leadResponse.lead.firstName + " " + leadResponse.lead.lastName
            : leadResponse.lead.lastName;
        setLeadName(name);
        if (accountsResponse.length)
          setIsAccountExist({
            account: accountsResponse[0].id,
            accountName: accountsResponse[0].name,
          });
      }
    })();
  }, []);

  const convertLeadFn = async () => {
    setConverting(true);
    let contactDetails = {};

    if (!isAccountExist) {
      let accountPayload = { name: account };

      if (leadObject.annualRevenue) {
        accountPayload.annualRevenue = leadObject.annualRevenue;
      }

      if (leadObject.noOfEmployee) {
        accountPayload.noOfEmployee = leadObject.noOfEmployee;
      }

      let newAccount = await saveAccount(accountPayload);

      contactDetails["accountName"] = account;
      contactDetails["account"] = newAccount.accountId;
    } else {
      contactDetails["accountName"] = isAccountExist.accountName;
      contactDetails["account"] = isAccountExist.account;
    }

    if (leadObject?.lastName) {
      contactDetails.lastName = leadObject.lastName;
    }
    if (leadObject?.email) {
      contactDetails.email = leadObject.email;
    }
    if (leadObject?.gender) {
      contactDetails.gender = leadObject.gender;
    }
    if (leadObject?.telephoneNumber) {
      contactDetails.telephoneNumber = leadObject.telephoneNumber;
    }
    if (leadObject?.website) {
      contactDetails.website = leadObject.website;
    }
    if (leadObject?.noOfEmployee) {
      contactDetails.noOfEmployee = leadObject.noOfEmployee;
    }

    if (leadObject.firstName) {
      contactDetails["firstName"] = leadObject.firstName;
    }
    if (leadObject.avatar) {
      contactDetails["avatar"] = leadObject.avatar;
    }

    contactDetails.leadId = leadId;

    let res = await addContact(contactDetails);

    if (res.success) {
      leadObject.leadStatus = "converted";
      await updateLead(leadId, leadObject);

      dispatch(crmActions.removeLeadsAction({ leads: [leadId] }));
      setConverting(false);
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Contact created successfully.",
      });

      history.push({
        pathname: `/crm/contact/${res.id}`,
        state: { from: "convertLead" },
      });
    } else {
      setConverting(false);
      props.showSnackbar({
        show: true,
        severity: "error",
        message: res.message,
      });
    }
  };

  return (
    <Box p={5}>
      <Box display="flex" justifyContent="space-between" width={270}>
        <p>{isAccountExist ? "Use existing account" : "Create new account"}:</p>
        {account && (
          <Chip
            size="small"
            label={`${account}`}
            style={{
              fontSize: "14px",
              color: "#707070",
            }}
            color="primary"
            variant="outlined"
          />
        )}
      </Box>

      <Box display="flex" py={5} justifyContent="space-between" width={270}>
        <p>Create new contact:</p>
        {leadName && (
          <Chip
            size="small"
            label={`${leadName}`}
            style={{
              fontSize: "14px",
              color: "#707070",
            }}
            color="primary"
            variant="outlined"
          />
        )}
      </Box>

      <Box display="flex" py={5} justifyContent="space-between" width={250}>
        <Box display="flex" alignItems="center">
          <Button onClick={convertLeadFn} variant="contained" color="secondary">
            Convert
          </Button>
          {converting && (
            <Box style={{ paddingLeft: "5px" }}>
              <CircularProgress style={{ color: "blue" }} size="20px" />
            </Box>
          )}
        </Box>

        <Button
          onClick={() => {
            history.push({
              pathname: `/crm/lead/${leadId}`,
            });
          }}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConvertLead);
