import { Box, Button, TextareaAutosize, Container } from "@material-ui/core";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import firebase from "./../../../firebase/firebase.js";
import {
  updateContact,
  getOrganization,
  getOrgainzationMembers,
  getContact,
  saveAccount,
  getUserByUserId,
  getElasticToken,
} from "./../../../firebase/firestore.js";
import * as actions from "./../../../redux/actions";
import * as crmActions from "./../../../redux/crm/actions";
import { connect } from "react-redux";
import { camelCase } from "lodash";

import ElasticAccountAutocomplete from "../../Tasks/ElasticAccountAutocomplete";

const EditContact = (props) => {
  const history = useHistory();
  const { contactId } = useParams();
  const dispatch = useDispatch();

  const [fieldsArray, setFieldsArray] = useState([]);
  const [fieldsStateObject, setFieldsStateObject] = useState({});
  const [contactAccount, setContactAccount] = useState(null);
  const [elasticToken, setElasticToken] = useState(null);

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };
    loadElasticToken();
  }, []);

  useEffect(() => {
    (async () => {
      let organization = await getOrganization();
      let organizationMembers = await getOrgainzationMembers();
      let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

      setFieldsArray(organization.contactFieldsSetting);
      let currentContactDetails = await getContact(contactId);
      let tempStateObject = {};
      organization.contactFieldsSetting.map((f) => {
        let defaultVal = f.defaultValue ? f.defaultValue : "";

        if (f.type === "select") {
          if (f.title === "Contact Owner") {
            if (organizationMembers) {
              f.options = [];
              organizationMembers.members.map((member) => {
                let owner = {
                  title: member.name,
                  value: member.id,
                  isDefault:
                    member.id ===
                    currentContactDetails.contact[camelCase(f.title)]
                      ? true
                      : false,
                };
                f.options.push(owner);
                return true;
              });
            } else {
              f.options = [
                {
                  title: userDetails.displayName,
                  value: firebase.auth().currentUser.uid,
                  isDefault: true,
                },
              ];
            }

            // f.options = [
            //   {
            //     title: userDetails.displayName,
            //     value: firebase.auth().currentUser.uid,
            //     isDefault: true,
            //   },
            // ];
          }

          let defaultOpt = f.options.find((opt) => opt.isDefault);
          if (defaultOpt) {
            defaultVal = defaultOpt.value ? defaultOpt.value : defaultOpt.title;
          }
        }

        if (currentContactDetails.contact[camelCase(f.title)]) {
          defaultVal = currentContactDetails.contact[camelCase(f.title)];
        }
        tempStateObject[f.title] = {
          value: defaultVal,
          error: false,
          errorMsg: "",
          ...f,
        };
      });
      setFieldsStateObject(tempStateObject);

      if (
        currentContactDetails.contact["accountName"] &&
        currentContactDetails.contact["account"]
      ) {
        setContactAccount({
          name: currentContactDetails.contact["accountName"],
          accountId: currentContactDetails.contact["account"],
        });
      }
    })();
  }, []);

  const handleChangeField = (fieldTitle, value) => {
    let tempStateObject = { ...fieldsStateObject };
    tempStateObject[fieldTitle].value = value;
    if (tempStateObject[fieldTitle].defaultValue) {
      tempStateObject[fieldTitle].defaultValue = "";
    }
    setFieldsStateObject(tempStateObject);
  };

  const handleSubmit = async () => {
    let validationError = false;

    let tempStateObject = { ...fieldsStateObject };

    for (let key in tempStateObject) {
      if (tempStateObject[key].error) {
        tempStateObject[key].error = false;
        tempStateObject[key].errorMsg = "";
      }

      if (tempStateObject[key].isRequired && tempStateObject[key].value == "") {
        tempStateObject[key].error = true;
        tempStateObject[key].errorMsg = "This field is required";
        validationError = true;
      }
    }

    setFieldsStateObject(tempStateObject);

    if (validationError) {
      return false;
    } else {
      let contactDetails = {};
      for (let k in tempStateObject) {
        contactDetails[camelCase(k)] = tempStateObject[k].value;
      }

      if (contactAccount) {
        if (contactAccount.isNew) {
          let newAccount = await saveAccount({ name: contactAccount.name });
          console.log("newAccount", newAccount);
          contactDetails["accountName"] = contactAccount.name;
          contactDetails["account"] = newAccount.accountId;
        } else {
          contactDetails["accountName"] = contactAccount.name;
          contactDetails["account"] = contactAccount.accountId;
        }
      }

      let res = await updateContact(contactId, contactDetails);

      if (res.success) {
        props.showSnackbar({
          show: true,
          severity: "success",
          message: "Contact updated successfully.",
        });

        dispatch(crmActions.updateContactAction({ contactId, contactDetails }));
        history.push({
          pathname: `/crm/contacts`,
          state: { from: "editContact", contactId },
        });
      } else {
        props.showSnackbar({
          show: true,
          severity: "error",
          message: res.message,
        });
      }
    }
  };

  const getDefaultOptionFromList = (options) => {
    let defaultVal = "";
    options.map((opt) => {
      if (opt.isDefault) {
        defaultVal = opt.value ? opt.value : opt.title;
      }
    });
    console.log("defaultVal", defaultVal, options);
    return defaultVal;
  };

  return (
    <Container maxWidth={"xl"}>
      <Box
        padding={2}
        flexDirection="column"
        justifyContent="space-between"
        display="flex"
        minHeight={700}
        //   height={1}
      >
        <Box paddingBottom={2}>
          <h2>Edit Contact</h2>
        </Box>

        <Box
          width={2 / 3}
          flexWrap="wrap"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          margin="auto"
        >
          {fieldsArray.map((field) => {
            return (
              <Box width={1 / 2} padding={5}>
                {field.type == "text" && fieldsStateObject[field.title] && (
                  <Box>
                    <TextField
                      error={fieldsStateObject[field.title].error}
                      required={field.isRequired}
                      label={field.title}
                      value={
                        fieldsStateObject[field.title].value
                          ? fieldsStateObject[field.title].value
                          : fieldsStateObject[field.title].defaultValue
                          ? fieldsStateObject[field.title].defaultValue
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeField(field.title, e.target.value)
                      }
                      variant="outlined"
                      helperText={
                        fieldsStateObject[field.title].error
                          ? fieldsStateObject[field.title].errorMsg
                          : ""
                      }
                    />
                  </Box>
                )}

                {field.type == "select" &&
                  field.title != "Account Name" &&
                  fieldsStateObject[field.title] && (
                    <Box>
                      <FormControl
                        fullWidth
                        error={fieldsStateObject[field.title].error}
                        required={field.isRequired}
                      >
                        <InputLabel id={`${field.title}-select`}>
                          {field.title}
                        </InputLabel>
                        <Select
                          labelId={`${field.title}-select`}
                          label={field.title}
                          value={
                            fieldsStateObject[field.title].value
                              ? fieldsStateObject[field.title].value
                              : getDefaultOptionFromList(
                                  fieldsStateObject[field.title].options
                                )
                          }
                          onChange={(e) => {
                            handleChangeField(field.title, e.target.value);
                          }}
                        >
                          {field.options.map((option) => {
                            return (
                              <MenuItem
                                value={
                                  option.value ? option.value : option.title
                                }
                              >
                                {option.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {fieldsStateObject[field.title].error && (
                          <FormHelperText>
                            {fieldsStateObject[field.title].errorMsg}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  )}

                {field.type === "select" &&
                  field.title === "Account Name" &&
                  elasticToken && (
                    <ElasticAccountAutocomplete
                      apiKey={elasticToken}
                      setRelatedTo={setContactAccount}
                      defaultValue={contactAccount?.name}
                    />
                  )}

                {field.type == "multi-line-text" &&
                  fieldsStateObject[field.title] && (
                    <Box>
                      <TextareaAutosize
                        style={{ width: "83%" }}
                        maxRows={10}
                        minRows={4}
                        placeholder={field.title}
                        onChange={(e) =>
                          handleChangeField(field.title, e.target.value)
                        }
                        value={fieldsStateObject[field.title].value}
                      ></TextareaAutosize>
                    </Box>
                  )}

                {field.type == "checkbox" && fieldsStateObject[field.title] && (
                  <Box>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              fieldsStateObject[field.title].value
                                ? fieldsStateObject[field.title].value
                                : fieldsStateObject[field.title].defaultValue
                                ? fieldsStateObject[field.title].defaultValue
                                : false
                            }
                            onChange={(e) => {
                              handleChangeField(field.title, e.target.checked);
                            }}
                          />
                        }
                        label={field.title}
                      />
                    </FormControl>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>

        <Box
          justifyContent="space-around"
          // flexDirection="flex-end"
          width={1}
          display="flex"
        >
          <Box width={1 / 3}></Box>
          <Box width={1 / 3}>
            <Box width={1 / 2} display="flex" justifyContent="space-around">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
              >
                Update
              </Button>

              <Button
                color="secondary"
                onClick={() =>
                  history.push({
                    pathname: `/crm/contacts`,
                    state: { from: "editContact", contactId },
                  })
                }
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
