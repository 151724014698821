import { render } from "react-dom";
import React, { createElement, Fragment, useEffect, useState } from "react";

import algoliasearch from "algoliasearch";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { createTagsPlugin } from "@algolia/autocomplete-plugin-tags";
// import "@algolia/autocomplete-plugin-tags/dist/theme.css";
// import "@algolia/autocomplete-theme-classic";
import "../App.css";
import "../AlgoliaTheme.css";
import "../AlgoliaTags.css";
import { Box } from "@material-ui/core";
import { filter } from "lodash";
import defaultAvatar from "../assets/images/default-avatar.png";
import OrganizationList from "./../components/landing/OrganizationList";

const Account = ({
  account,
  setIsOpen,
  setQuery,
  setContactAccount,
  rowId,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={"10px"}
      height={1}
      width={1}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(account.name);
        setIsOpen(false);
        setContactAccount({
          isNew: false,
          name: account.name,
          email: account.email,
          avatar: account.avatar,
          avatarURL: account.avatarURL,
          accountId: account.objectID,
          rowId: rowId,
        });
      }}
    >
      <img
        src={account.avatar ? account.avatar : account?.avatarURL || ""}
        alt="img"
        width={30}
        height={30}
        style={{ borderRadius: "50%" }}
      />
      <Box>
        <p style={{ marginBottom: "10px", fontSize: "16px" }}>{account.name}</p>
        <p style={{ fontSize: "12px" }}>{account.email}</p>
      </Box>
    </Box>
  );
};

const Users = ({
  users,
  setIsOpen,
  setQuery,
  setUsers,
  rowId,
  organizationId,
  owners,
  handleUserSelection,
  tagsPlugin,
}) => {
  const isContactSelected = tagsPlugin.data.tags.filter(
    (i) => i.facet === users.objectID
  );
  let ownersId = [];
  let ownerList = owners.includes(users.objectID);
  if (ownerList) {
    ownersId.push(users.objectID);
  }

  return !isContactSelected.length &&
    !ownerList &&
    (users.organizationId === organizationId || organizationId === "") ? (
    <Box
      display="flex"
      alignItems="center"
      gap={"10px"}
      height={1}
      width={1}
      style={{
        background: isContactSelected.length
          ? "#70707061"
          : "rgba(145, 158, 171, 0.08)",
        padding: ".3em",
      }}
      onClick={(event) => {
        event.stopPropagation();
        // setQuery(users.displayName);
        setQuery("");
        setIsOpen(false);
        tagsPlugin.data.addTags([
          { label: users.displayName, facet: users.objectID },
        ]);
        setUsers({
          name: users.displayName,
          email: users.email,
          avatar: users.avatar,
          avatarURL: users.avatarURL,
          usersId: users.objectID,
          rowId: rowId,
          selected: true,
        });
      }}
    >
      <img
        src={
          users.photoURL
            ? users.photoURL
            : "https://lh3.googleusercontent.com/a-/AOh14Gg3TRVy2ceBxlcwQXzsgiB7XTPE9sl48ioSbjN32_Nz9RoNF3wQx1O7GsobH5d4FngDvY42jJSHBy27caIH07Ozrvs6jWP9dw0S2TziW9VfqPWrpciRY3LJP8ZuOww7pMXqQB5c4OGH1Q7PUCcUTlSqG33H1DCHrJS53jxKJ6ctDx0IZ_f4cfmR9kecqW31flj7mhnuUf6ujnnpsHo7qId7r2Jeq5vuLKXYbjEBvgatiP7HIgJwyoioifK7eFiycpJwDIuurrBsslebEQqYzkhcWWR2PLwGnBqIA6jJIZeaip--hGlk4x8KTMoYIvcwHT8DheCk5u5Chaf14-yQd51Ei-Px7Zeofu7FXdpal-tujZOCAd6JH1K2iZI1EMr2Zz5QAFegIOvo2QYOFxf_O98b2UzhM7wpOnS6Pxc_d3S_pJLkhNM3b82DNx-njfg5btguSvZmQu-xx2IoPxVGXlb0kxkErMxziLSdnngDR9Su9VCpMAET8eOAYb-Z9McEOhIqWr2S_WPCC8R57dzEfBvun5hK00w9rtjDNaENcLrqauz_x8hGngbsP8jmULgb7xT8ekgNspLknV1Suqj-2YEBw3vWey6Eku-yNnnaSQm4WZtZUYyH6bCKeaZVSSpjwlVcfNh0fU9mXyleRTz_PgdFN9BztEiJCyLcbBNTo94dQ7B4zWDAPQ-WqFDN7FGa1St7zkSS6I2BlPsNvsDbG8bM-a3-8TydQ8nTJEqM2Zn7EP0vc6fNYxVpXKhSotOIRF0N1A=s96-c"
        }
        alt="img"
        width={30}
        height={30}
        style={{ borderRadius: "50%" }}
      />
      <Box>
        <p style={{ fontSize: "16px" }}>{users.displayName}</p>
        <p style={{ fontSize: "12px", color: "#7c7c7c" }}>{users.email}</p>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

const CreateAccount = ({
  query,
  setIsOpen,
  setQuery,
  setContactAccount,
  indexName,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      width={1}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        // setQuery(query);
        setQuery("");
        setIsOpen(false);
        setContactAccount({ isNew: true, name: query, id: null });
      }}
    >
      <p>
        {" "}
        {indexName == `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_users`
          ? "Users"
          : "Contacts"}{" "}
        not found:{" "}
      </p>
      <b style={{ marginLeft: "5px" }}> {query}</b>
    </Box>
  );
};

export default function AlgoliaAccountAutocomplete(
  apiKey,
  setContactAccount,
  idx,
  rowId,
  sourceId,
  indexName,
  organizationId,
  owners,
  handleUserSelection
) {
  const tagsPlugin = createTagsPlugin({
    onChange: function (d) {
      handleUserSelection(d.tags);
    },
  });

  const appId = process.env.REACT_APP_ALGOLIA_APP_ID
    ? process.env.REACT_APP_ALGOLIA_APP_ID
    : "TWE65SAYW0";
  const searchClient = algoliasearch(appId, apiKey);
  const autocompleteInstance = autocomplete({
    container: `#algolia-account-autocomplete${idx}`,
    placeholder: sourceId,
    // debug: true,
    renderer: { createElement, Fragment },
    render({ children }, root) {
      render(children, root);
    },
    openOnFocus: true,
    plugins: [tagsPlugin],

    getSources({ setQuery, refresh, setIsOpen, query }) {
      return [
        {
          sourceId: sourceId,
          getItems({ query, state }) {
            let tmpQuery = {
              indexName: indexName,
              query,
              params: {
                hitsPerPage: 10,
              },
            };
            return getAlgoliaResults({
              searchClient,
              queries: [tmpQuery],
            });
          },
          templates: {
            item({ state, item, components }) {
              if (
                indexName ===
                `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_contacts`
              ) {
                return (
                  <Account
                    account={item}
                    setQuery={setQuery}
                    setIsOpen={setIsOpen}
                    setContactAccount={setContactAccount}
                    rowId={rowId}
                  />
                );
              } else if (
                indexName ===
                `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_users`
              ) {
                return (
                  <Users
                    users={item}
                    setQuery={setQuery}
                    setIsOpen={setIsOpen}
                    setUsers={setContactAccount}
                    rowId={rowId}
                    organizationId={organizationId}
                    owners={owners}
                    // handleUserSelection={handleUserSelection}
                    tagsPlugin={tagsPlugin}
                  />
                );
              }
            },
            noResults() {
              return (
                <CreateAccount
                  query={query}
                  setQuery={setQuery}
                  setIsOpen={setIsOpen}
                  setContactAccount={setContactAccount}
                  indexName={indexName}
                />
              );
            },
          },
        },
      ];
    },
  });

  return autocompleteInstance;
}
