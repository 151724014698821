import { v4 as uuidv4 } from "uuid";

export  const CrmData = [
  {
    id: uuidv4(),
    firstSelectResult: 'Lead Name',
    secondSelectResult: 'Contains',
    secondSelectList: ['Contains', 'Does not contain'],
    crmSentimentResult: 'Very positive',
    selectedSpecificPeoples: [],
    selects: [
      {
        id: 1,
        dimension: "Lead Name",
        options: [
          'Contains', 'Does not contain'
        ]
      },
      {
        id: 2,
        dimension: "Leads owned by",
        options: [
          'Me', 'Not me', 'Specific people'
        ]
      },
      {
        id: 3,
        dimension: "Lead Stage",
        options: ['Any', 'Include', 'Exclude']
      },
      {
        id: 4,
        dimension: "Lead sentiment",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 5,
        dimension: "Lead Engagement",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 6,
        dimension: "Contact Name",
        options: ['Contains', 'Does not contain']  
      },
      {
        id: 7,
        dimension: "Contacts owned be",
        options: ['Me', 'Not me', 'Specific people']
      },
      {
        id: 8,
        dimension: "Contact sentiment",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 9,
        dimension: "Contact Engagement",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 10,
        dimension: "Contact Address",
        options: ['Contains', 'Does not contain']  
      },
      {
        id: 11,
        dimension: "Topics interested",
        options: ['Include', 'Exclude']
      },
      {
        id: 12,
        dimension: "Topics sentiment",
        options: ['Include', 'Exclude']
      },
      {
        id: 13,
        dimension: "Product Mentions",
        options: ['Include', 'Exclude']
      },
      {
        id: 14,
        dimension: "Product sentiment",
        options: ['Include', 'Exclude']
      },
      {
        id: 15,
        dimension: "Account Name",
        options: ['Contains', 'Does not contain']
      },
      {
        id: 16,
        dimension: "Accounts owned by",
        options: ['Me', 'Not me', 'Specific people']
      },
      {
        id: 17,
        dimension: "Account address",
        options: ['Contains', 'Does not contain']
      },
      {
        id: 18,
        dimension: "Deal Name",
        options: ['Contains', 'Does not contain']
      },
      {
        id: 19,
        dimension: "Deals owned by",
        options: ['Me', 'Not me', 'Specific people']
      },
      {
        id: 20,
        dimension: "Deal Value",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 21,
        dimension: "Deal stage",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 22,
        dimension: "Task Title",
        options: ['Contains', 'Does not contain']
      },
      {
        id: 23,
        dimension: "Task Status",
        options: ['Equals to', 'More than', 'Less than']
      },
      {
        id: 24,
        dimension: "Tasks assigned to",
        options: ['Me', 'Not me', 'Specific people']
      },
      {
        id: 25,
        dimension: "Notes",
        options: ['Contains', 'Does not contain']
      },
      {
        id: 26,
        dimension: "Notes created by",
        options: ['Me', 'Not me', 'Specific people']
      },
    ]
  }
];