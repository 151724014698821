import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useState } from "react";
import { _log, getData } from "../utils";
import { useHistory } from "react-router-dom";
import firebase from "./../firebase/firebase.js";
import { getOrgainzation } from "./../firebase/firestore.js";
import firebaseAnalytics from "./../firebase/firebaseAnalytics";

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ["demo@minimals.cc"];
const db = firebase.firestore();
const DBusers = db.collection("users");

//Store user details

export const setUserDetailsByUserId = async (uid, payload) => {
  try {
    return await DBusers.doc(uid)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let userData = doc.data();

          let updateUserFields = {
            email: payload.email,
            photoURL: payload.photoURL,
            timeZone: new Date().getTimezoneOffset(),
            updatedAt: new Date(),
          };

          if (!userData.displayName || userData.displayName == "") {
            updateUserFields.displayName = payload.displayName;
          }

          if (!userData.createdAt) {
            updateUserFields.createdAt = new Date();
          }

          DBusers.doc(uid).update(updateUserFields);
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        return false;
      });
  } catch (error) {}
};
export const getUserByUserId = async (uid) => {
  return await DBusers.doc(uid)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        //_log(doc.data())
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  fireStoreuserObj: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, user, fireStoreuserObj } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      fireStoreuserObj,
      user,
    };
  }
  if (action.type === "SET_ORG_ID") {
    const { orgId, isOrgOwner } = action.payload;
    return {
      ...state,
      fireStoreuserObj: {
        ...state.fireStoreuserObj,
        organizationId: orgId,
        isOrganizationOwner: isOrgOwner,
      },
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: "firebase",
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithOutlook: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setOrganizationId: () => Promise.resolve(),
  getIsOrganizationOwner: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [authtoken, setAuthToken] = useState(null);
  const [authOutlook, setAuthOutlook] = useState(false);

  let history = useHistory();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          user.getIdToken().then(async (jwtToken) => {
            if (
              firebase.auth().currentUser.providerData[0].providerId ===
              "microsoft.com"
            ) {
              if (!authOutlook) {
                let currentUser = firebase.auth().currentUser;
                let fireStoreuserObj = await getUserByUserId(currentUser.uid);
                fireStoreuserObj.uid = currentUser.uid;
                dispatch({
                  type: "INITIALISE",
                  payload: {
                    isAuthenticated: true,
                    user,
                    fireStoreuserObj,
                  },
                });
              }
            } else {
              let fireStoreUserStatus = await getData(
                `${process.env.REACT_APP_BASE_API_URL}/user/auth/google/verify`,
                jwtToken
              );

              if (fireStoreUserStatus.tokensValid) {
                setUserDetailsByUserId(firebase.auth().currentUser.uid, {
                  displayName: firebase.auth().currentUser.displayName,
                  email: firebase.auth().currentUser.email,
                  photoURL: firebase.auth().currentUser.photoURL,
                });

                let fireStoreuserObj = await getUserByUserId(
                  firebase.auth().currentUser.uid
                );
                fireStoreuserObj.uid = firebase.auth().currentUser.uid;
                fireStoreuserObj.email = firebase.auth().currentUser.email;
                dispatch({
                  type: "INITIALISE",
                  payload: { isAuthenticated: true, user, fireStoreuserObj },
                });
              } else {
                window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=${user.uid}_${process.env.REACT_APP_APP_ENV}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&login_hint=${user.email}`;
              }
            }
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(async function (jwtToken) {
            setAuthToken(jwtToken);
            let fireStoreUserStatus = await getData(
              `${process.env.REACT_APP_BASE_API_URL}/user/auth/google/verify`,
              jwtToken
            );
            if (fireStoreUserStatus.tokensValid) {
              _log(firebase.auth().currentUser);
              setUserDetailsByUserId(firebase.auth().currentUser.uid, {
                displayName: firebase.auth().currentUser.displayName,
                email: firebase.auth().currentUser.email,
                photoURL: firebase.auth().currentUser.photoURL,
              });
            }
          });
      })
      .catch((error) => {
        firebaseAnalytics.logEvent("login", {
          success: false,
          error_code: error.toJSON().code,
          error_msg: error.toJSON().message,
        });
      });
  };

  const loginWithOutlook = async () => {
    setAuthOutlook(true);
    var provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.addScope("user.read");
    provider.addScope("calendars.readwrite");
    provider.addScope("offline_access");
    provider.addScope("OnlineMeetings.ReadWrite");

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let user = result.user;
        let credential = result.credential;
        let fireData = {
          // displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          microsoft: {
            id_token: credential.idToken,
          },
          timeZone: new Date().getTimezoneOffset(),
          updatedAt: new Date(),
        };

        if (fireData.microsoft.id_token) {
          await DBusers.doc(user.uid).set(fireData, { merge: true });

          let firestoreUserObject = await getUserByUserId(user.uid);

          if (
            !firestoreUserObject.displayName ||
            firestoreUserObject.displayName == ""
          ) {
            fireData.displayName = user.displayName;
          }

          if (!firestoreUserObject.createdAt) {
            fireData.createdAt = new Date();
          }

          await DBusers.doc(user.uid).set(fireData, { merge: true });

          let jwtToken = await user.getIdToken();
          let result = await getData(
            process.env.REACT_APP_BASE_API_URL + "/outlook/auth/callback",
            jwtToken
          );
          if (result.status === "success") {
            setAuthOutlook(false);
          } else {
            dispatch({
              type: "INITIALISE",
              payload: { isAuthenticated: false, user: null },
            });
          }
          return true;
        } else {
          dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: false, user: null },
          });
        }
      })
      .catch((error) => {
        console.log("msauth_error", error);
        firebaseAnalytics.logEvent("login", {
          success: false,
          error_code: error.toJSON().code,
          error_msg: error.toJSON().message,
        });
      });
  };

  const register = (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection("users")
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          });
      });

  const logout = async () => {
    localStorage.removeItem("weatherdata");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("selectedCurrency");
    localStorage.removeItem("userBillingStatusData");
    localStorage.removeItem("elasticSearchKeyData");

    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const setOrganizationId = async (orgId) => {
    let orgData = await getOrgainzation(orgId);
    let isOrgOwner = false;
    if (firebase.auth().currentUser.uid == orgData.createdBy) {
      isOrgOwner = true;
    }
    dispatch({
      type: "SET_ORG_ID",
      payload: { orgId, isOrgOwner },
    });

    return isOrgOwner;
  };

  const getIsOrganizationOwner = async (orgId) => {
    let orgData = await getOrgainzation(orgId);
    let isOrgOwner = false;
    if (firebase.auth().currentUser.uid == orgData.createdBy) {
      isOrgOwner = true;
    }
    return isOrgOwner;
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(auth.email) ? "admin" : "user",
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || "",
          country: profile?.country || "",
          address: profile?.address || "",
          state: profile?.state || "",
          city: profile?.city || "",
          zipCode: profile?.zipCode || "",
          about: profile?.about || "",
          isPublic: profile?.isPublic || false,
        },
        login,
        register,
        loginWithGoogle,
        loginWithOutlook,
        logout,
        resetPassword,
        setOrganizationId,
        getIsOrganizationOwner,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
