import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";

import { Box, Collapse, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";

import { getSummary } from "../../../firebase/firestore";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import googleMeetAvatar from "../../../assets/images/meet.png";
import recordingMeeting from "../../../assets/images/recording_meeting.png";
import zoomAvatar from "../../../assets/images/zoom-icon.svg";
import msTeamAvatar from "../../../assets/images/ms-teams-icon.svg";

import Highlighter from "react-highlight-words";

import moment from "moment";

import ParticipantsAvatars from "./ParticipantsAvatars";

export default function Meeting({
  meeting,
  query,
  setShowSuggestions,
  searchInput,
}) {
  const [open, setOpenFn] = useState(false);
  const [meetingSummary, setMeetingSummary] = useState(false);

  // For test
  // useEffect(() => {
  //   (async () => {
  //     let d = await getSummary(meeting.objectID);
  //     setMeetingSummary(d);

  //     // document
  //     //   .getElementById("global-search-input")
  //     //   .addEventListener("blur", function (e) {
  //     //     setShowSuggestions(false);
  //     //   });
  //     // document
  //     //   .getElementById("global-search-input")
  //     //   .addEventListener("focus", function (e) {
  //     //     setShowSuggestions(true);
  //     //   });
  //   })();
  // }, []);

  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    searchInput.current.querySelector("input").onfocus = () => {
      setShowSuggestions(true);
    };
    // searchInput.current.querySelector("input").onblur = () => {
    //   console.log("fffcs");
    //   setShowSuggestions(false);
    // };

    let d = await getSummary(meeting.objectID);
    if (meetingSummary) {
      setOpenFn(false);
      setMeetingSummary(false);
    } else {
      setOpenFn(!open);
      setMeetingSummary(d);
    }
  };

  return (
    <div onClick={(event) => handleClick(event)}>
      <Grid
        display="flex"
        gap="10px"
        p={1}
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <Grid
          width="100%"
          display="flex"
          alignItems="center"
          gap="15px"
          style={{}}
        >
          <Box
            item
            xs={6}
            sm={6}
            display="flex"
            fontSize="14px"
            alignItems="center"
            gap="5px"
            style={{ textDecoration: "underline" }}
            onClick={() =>
              (window.location.href = `/meeting/${meeting.objectID}`)
            }
          >
            {meeting.channelType === "ZOOM" ? (
              <Avatar
                src={zoomAvatar}
                alt="meet"
                style={{ width: "20px", height: "20px" }}
              />
            ) : meeting.channelType === "GOOGLE_MEET" ? (
              <Avatar
                src={googleMeetAvatar}
                alt="meet"
                style={{ width: "20px", height: "20px" }}
              />
            ) : meeting.channelType === "RECORDING" ? (
              <Avatar
                src={recordingMeeting}
                alt="meet"
                style={{ width: "20px", height: "20px" }}
              />
            ) : (
              <Avatar
                src={msTeamAvatar}
                alt="meet"
                style={{ width: "20px", height: "20px" }}
              />
            )}
            <p>
              {meeting.title ? (
                // <components.Highlight hit={meeting} attribute="title" />

                <Highlighter
                  searchWords={[query]}
                  autoEscape={true}
                  textToHighlight={meeting.title}
                />
              ) : (
                "-"
              )}
            </p>
          </Box>
          <Box item xs={2} sm={2} fontSize="12px" color="#707070">
            {meeting.createdAt._seconds
              ? moment(meeting.createdAt._seconds * 1000).format("lll")
              : "--"}
          </Box>
          {!open && (
            <>
              <Box item xs={2} sm={2}>
                {meeting.participants?.length && (
                  <ParticipantsAvatars row={meeting.participants} />
                )}
              </Box>
              <Box
                item
                xs={3}
                sm={3}
                display="flex"
                alignItems="center"
                gap="5px"
              >
                <span>
                  {meeting.meetingOutcome === "POSITIVE" ? (
                    <Icon
                      width="25"
                      height="25"
                      icon="gg:smile"
                      color="#82abfb"
                    />
                  ) : meeting.meetingOutcome === "NEGATIVE" ? (
                    <Icon
                      width="25"
                      height="25"
                      icon="gg:smile-sad"
                      color="#82abfb"
                    />
                  ) : meeting.meetingOutcome === "UNCERTAIN" ? (
                    <Icon
                      width="25"
                      height="25"
                      icon="gg:smile-neutral"
                      color="#82abfb"
                    />
                  ) : (
                    ""
                  )}
                </span>
              </Box>
            </>
          )}
        </Grid>
        <Box>
          {open ? (
            <ExpandLessIcon className="arrow" />
          ) : (
            <ExpandMoreIcon className="arrow" />
          )}
        </Box>
      </Grid>
      <Collapse
        in={open || meetingSummary.summary?.includes(query)}
        timeout="auto"
        unmountOnExit
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRadius="10px"
        >
          <Box
            style={{
              borderRadius: "10px",
            }}
          >
            {open && (
              <Grid
                width="100%"
                display="flex"
                alignItems="center"
                gap="15px"
                style={{
                  padding: "10px",
                  paddingRight: "20px",
                }}
              >
                <Box item xs={2} sm={2}>
                  {meeting.participants?.length && (
                    <ParticipantsAvatars row={meeting.participants} />
                  )}
                </Box>
                <Box
                  item
                  xs={3}
                  sm={3}
                  display="flex"
                  alignItems="center"
                  gap="10px"
                >
                  <div
                    style={{
                      borderRight: " 1px solid #e6e6ff",
                      borderLeft: " 1px solid #e6e6ff",
                      padding: "0 8px",
                    }}
                  >
                    {" "}
                    {meeting.meetingOutcome === "POSITIVE" ? (
                      <Icon
                        width="25"
                        height="25"
                        icon="gg:smile"
                        color="#82abfb"
                      />
                    ) : meeting.meetingOutcome === "NEGATIVE" ? (
                      <Icon
                        width="25"
                        height="25"
                        icon="gg:smile-sad"
                        color="#82abfb"
                      />
                    ) : meeting.meetingOutcome === "UNCERTAIN" ? (
                      <Icon
                        width="25"
                        height="25"
                        icon="gg:smile-neutral"
                        color="#82abfb"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      borderRight: " 1px solid #e6e6ff",
                      padding: "0 8px",
                      color: "#707070",
                    }}
                  >
                    {meeting.attendeesType || ""}
                  </div>
                  <div className="meeting-topics-section">
                    {meeting.topics?.length
                      ? meeting.topics.map((topic, key) => {
                          return (
                            <div key={key} className="topic">
                              {topic}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </Box>
              </Grid>
            )}
            <div
              style={{
                height: "100%",
                overflow: "hidden",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              <p>Meeting Summary:</p>
              {meetingSummary && (
                <Box
                  padding={1}
                  style={{ lineHeight: "1.3" }}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <p>
                    {/* <components.Highlight
                      hit={meetingSummary}
                      attribute="summary"
                    /> */}
                    <Highlighter
                      searchWords={[query]}
                      autoEscape={true}
                      textToHighlight={meetingSummary.summary}
                    />
                  </p>
                </Box>
              )}
            </div>
          </Box>
        </Box>
      </Collapse>
    </div>
  );
}
