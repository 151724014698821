import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AddTask from "../Tasks/AddTask";
import CreateEvent from "./../dashboard/CreateEvent";
import CreateReminder from "./../common/CreateReminder";

import { GetTasks } from "./../../firebase/firestore.js";

const ITEM_HEIGHT = 48;

const KeyTakeawayActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let [addTaskDialogStatus, setAddTaskDialogStatus] = useState(false);
  let [addReminderDialogStatus, setAddReminderDialogStatus] = useState(false);
  let [tasks, setTasks] = useState(false);

  const [showCreateEvent, setShowCreateEvent] = useState(false);

  const closeCreateEventPopup = () => {
    setShowCreateEvent(false);
  };

  useEffect(() => {
    const loadTasks = async () => {
      let tasks = await GetTasks();
      setTasks(tasks);
    };
    loadTasks();
    // DeleteTasks();
  }, []);
  const handleCloseAddTaskDialog = () => {
    setAddTaskDialogStatus(false);
  };

  const handleCloseAddReminderDialog = () => {
    setAddReminderDialogStatus(false);
  };

  const addTaskInList = (task) => {
    let tempTasks = [...tasks];
    tempTasks.push(task);
    setTasks(tempTasks);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    props.deleteAction(props.keyTakeaway);
    setAnchorEl(null);
  };

  const handleAddTask = () => {
    setAddTaskDialogStatus(true);
    setAnchorEl(null);
  };

  const handleAddReminder = () => {
    setAddReminderDialogStatus(true);
    setAnchorEl(null);
  };

  const handleAddMeeting = () => {
    setShowCreateEvent(true);
    setAnchorEl(null);
  };

  return (
    <div>
      {addTaskDialogStatus && (
        <AddTask
          addTaskDialogStatus={addTaskDialogStatus}
          handleCloseAddTaskDialog={handleCloseAddTaskDialog}
          addTaskInList={addTaskInList}
          tasks={tasks}
          title={`Follow up task from ${props.meetingTitle}`}
          description={props.keyTakeaway}
        />
      )}
      {addReminderDialogStatus && (
        <CreateReminder
          addReminderDialogStatus={addReminderDialogStatus}
          handleCloseAddReminderDialog={handleCloseAddReminderDialog}
          title={props.keyTakeaway}
        />
      )}
      {showCreateEvent && (
        <CreateEvent
          onClose={closeCreateEventPopup}
          title={props.keyTakeaway}
          open={showCreateEvent}
        />
      )}

      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleAddMeeting}>Create Meeting</MenuItem>
        <MenuItem onClick={handleAddTask}>Create Task</MenuItem>
        {process.env.REACT_APP_APP_ENV == "dev" && (
          <MenuItem onClick={handleAddReminder}>Add Reminder</MenuItem>
        )}
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>
    </div>
  );
};

export default KeyTakeawayActions;
