import React, { useRef, useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Box } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/styles";
import CreateEvent from "../dashboard/CreateEvent";
import AddTask from "../Tasks/AddTask";
import CreateReminder from "../common/CreateReminder";
import AddDeal from "../Deals/AddDeal";
import { useHistory } from "react-router-dom";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CircularProgress from "@material-ui/core/CircularProgress";
import { elasticSearchCRMSuggestions } from "../../utils/elasticSearch";
import newTabIcon from "../../assets/images/newtabIcon.png";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

const GetSuggestionsFromElasticSearch = ({ name, types, elasticToken }) => {
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    elasticSearchCRMSuggestions(name, types, true, elasticToken)
      .then((data) => {
        if (data?.length) {
          setSuggestions(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error happen when fetching suggestions");
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <MenuItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress size={20} sx={{ color: "#2c73ff" }} />
          </Box>
        </MenuItem>
      ) : suggestions?.length ? (
        <div>
          <span className="entity-suggestions">Suggestions</span>
          {suggestions.map((item, i) => (
            <MenuItem
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {item.type === "lead" || item.type === "contact"
                ? item?.fullName
                : item.name}
              <img
                src={newTabIcon}
                alt="new-tab"
                style={{ height: 16, width: 16, marginLeft: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/crm/${item.type}/${item.id}`);
                }}
              />
            </MenuItem>
          ))}
        </div>
      ) : null}
    </>
  );
};

function Entity({
  entityName,
  emotionKey,
  entityCount,
  sentimentColor,
  timestamp,
  entityToMarks,
  isSentimentAvailable,
  setActiveEntity,
  type,
  expandedEntity,
  handleExpand,
  elasticToken,
}) {
  const history = useHistory();
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [addTaskDialogStatus, setAddTaskDialogStatus] = useState(false);
  const [addReminderDialogStatus, setAddReminderDialogStatus] = useState(false);
  const [addDealDialogStatus, setAddDealDialogStatus] = useState({
    status: false,
    dealValue: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [subItemAnchorEl, setSubItemAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const subItemOpen = Boolean(subItemAnchorEl);
  const classes = useStyles();
  const popoverAnchor = useRef(null);
  const parentAnchor = useRef(null);

  const emotionNameToLabel = () => {
    if (!isSentimentAvailable) {
      return "Feature disabled";
    }
    if (!emotionKey) {
      return "";
    }
    if (emotionKey === "NEGATIVE_2") {
      return "Very Negative";
    } else if (emotionKey === "NEGATIVE_1") {
      return "Negative";
    } else if (
      emotionKey === "NEUTRAL" ||
      emotionKey === "mixed" ||
      emotionKey === "MIXED"
    ) {
      return "Neutral";
    } else if (emotionKey === "POSITIVE_1") {
      return "Positive";
    } else if (emotionKey === "POSITIVE_2") {
      return "Very Positive";
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenSubMenu = (e) => {
    setSubItemAnchorEl(e?.currentTarget);
  };
  const handleCloseSubMenu = (e) => {
    e.stopPropagation();
    setSubItemAnchorEl(null);
  };

  const handleAddMeeting = (e) => {
    e.stopPropagation();
    setShowCreateEvent(true);
    setAnchorEl(null);
  };

  const handleAddTask = (e) => {
    e.stopPropagation();
    setAddTaskDialogStatus(true);
    setAnchorEl(null);
  };

  const handleAddReminder = (e) => {
    e.stopPropagation();
    setAddReminderDialogStatus(true);
    setAnchorEl(null);
  };

  const handleAddDeal = (e, dealValue) => {
    e.stopPropagation();
    setAddDealDialogStatus({
      status: true,
      dealValue: dealValue?.replace(/[$,]+/g, ""),
    });
    setAnchorEl(null);
  };

  const handleAddContact = (e, contactName) => {
    e.stopPropagation();
    history.push("/crm/contact/create", {
      contactName,
    });
    setAnchorEl(null);
  };

  const handleAddAccount = (e, accountName) => {
    e.stopPropagation();
    history.push("/crm/accounts/create", {
      accountName,
    });
    setAnchorEl(null);
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
        }}
        ref={parentAnchor}
      >
        {showCreateEvent && (
          <CreateEvent
            onClose={() => setShowCreateEvent(false)}
            open={showCreateEvent}
          />
        )}
        {addTaskDialogStatus && (
          <AddTask
            addTaskDialogStatus={addTaskDialogStatus}
            handleCloseAddTaskDialog={() => setAddTaskDialogStatus(false)}
          />
        )}
        {addReminderDialogStatus && (
          <CreateReminder
            addReminderDialogStatus={addReminderDialogStatus}
            handleCloseAddReminderDialog={() =>
              setAddReminderDialogStatus(false)
            }
          />
        )}
        {addDealDialogStatus?.status && (
          <AddDeal
            addDealDialogStatus={addDealDialogStatus.status}
            handleCloseAddDealDialog={() =>
              setAddDealDialogStatus({ status: false, dealValue: "" })
            }
            dealValue={addDealDialogStatus.dealValue}
          />
        )}
        <div
          className="entity-box"
          style={{
            border: `1px solid ${sentimentColor ? sentimentColor : "#fff"}`,
          }}
          onClick={(e) => {
            if (entityCount > 1 && expandedEntity !== entityName) {
              handleExpand(entityName);
            }
            if (timestamp?.length) {
              entityToMarks(
                timestamp,
                entityName,
                sentimentColor,
                emotionNameToLabel(emotionKey),
                type === "topics"
              );
              setActiveEntity({ entity: entityName, color: sentimentColor });
            }
          }}
          onMouseEnter={handleOpenMenu}
          onMouseLeave={handleCloseMenu}
          ref={popoverAnchor}
        >
          <Popover
            disableRestoreFocus={true}
            container={parentAnchor.current}
            id="mouse-over-popover"
            sx={{
              pointerEvents: "auto",
            }}
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            open={open}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              onMouseEnter: handleOpenMenu,
              onMouseLeave: handleCloseMenu,
            }}
          >
            <Box p={2}>
              {emotionNameToLabel(emotionKey) &&
                emotionNameToLabel(emotionKey) !== "" && (
                  <MenuItem onClick={(e) => e.stopPropagation()}>
                    Sentiment : {emotionNameToLabel(emotionKey)}
                  </MenuItem>
                )}
              {type === "event" ? (
                <>
                  <MenuItem onClick={(e) => handleAddMeeting(e)}>
                    Create Meeting
                  </MenuItem>
                  <MenuItem onClick={(e) => handleAddTask(e)}>
                    Create Task
                  </MenuItem>
                  {process.env.REACT_APP_APP_ENV == "dev" && (
                    <MenuItem onClick={(e) => handleAddReminder(e)}>
                      Add Reminder
                    </MenuItem>
                  )}
                </>
              ) : type === "contact" ? (
                <>
                  <MenuItem onClick={(e) => handleAddContact(e, entityName)}>
                    Create Contact
                  </MenuItem>
                  <GetSuggestionsFromElasticSearch
                    name={entityName}
                    types={["leads", "contacts"]}
                    elasticToken={elasticToken}
                  />
                </>
              ) : type === "organization" ? (
                <>
                  <MenuItem onClick={(e) => handleAddAccount(e, entityName)}>
                    Create Account
                  </MenuItem>
                  <GetSuggestionsFromElasticSearch
                    name={entityName}
                    types={["accounts"]}
                    elasticToken={elasticToken}
                  />
                </>
              ) : type === "price" ? (
                <>
                  <MenuItem onClick={(e) => handleAddDeal(e, entityName)}>
                    Create Deal
                  </MenuItem>
                </>
              ) : type === "date" ? (
                <>
                  <MenuItem onClick={(e) => handleAddMeeting(e)}>
                    Create Meeting
                  </MenuItem>
                  {process.env.REACT_APP_APP_ENV == "dev" && (
                    <MenuItem onClick={(e) => handleAddReminder(e)}>
                      Add Reminder
                    </MenuItem>
                  )}
                </>
              ) : null}
            </Box>
          </Popover>
          {timestamp?.length ? (
            <WatchLaterIcon
              sx={{
                fontSize: 14,
                color: sentimentColor ? sentimentColor : "#000",
                position: "absolute",
                top: 2,
                left: 2,
              }}
            />
          ) : null}
          <span
            className="entity-name"
            style={{
              color: `${sentimentColor ? sentimentColor : "#000"}`,
            }}
          >
            {entityName}
          </span>
          {entityCount > 1 && expandedEntity !== entityName && (
            <span
              className="entity-count"
              style={{
                backgroundColor: sentimentColor,
                opacity: 0.8,
              }}
            >
              x {entityCount}
            </span>
          )}
        </div>
        {entityCount > 1 && expandedEntity !== entityName && (
          <div
            className="entity-box entity-multiple"
            style={{
              borderRight: `1px solid ${sentimentColor}`,
              height: "35px",
              background: "none",
            }}
          />
        )}
      </div>
      {expandedEntity === entityName &&
        new Array(entityCount - 1).fill(0).map((item, index) => (
          <div
            key={index}
            className="entity-box entity-expand"
            style={{
              border: `1px solid ${sentimentColor}`,
            }}
            id={`${entityName}-${index}`}
            onMouseEnter={handleOpenSubMenu}
            onMouseLeave={handleCloseSubMenu}
          >
            <span
              className="entity-name"
              style={{
                color: `${sentimentColor}`,
              }}
            >
              {entityName}
            </span>
          </div>
        ))}
      <Popover
        container={parentAnchor.current}
        disableRestoreFocus={true}
        id="mouse-over-popover"
        sx={{
          pointerEvents: "auto",
        }}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={subItemOpen}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          onMouseEnter: handleOpenSubMenu,
          onMouseLeave: handleCloseSubMenu,
        }}
      >
        <Box p={2}>
          {emotionNameToLabel(emotionKey) &&
            emotionNameToLabel(emotionKey) !== "" && (
              <MenuItem onClick={(e) => e.stopPropagation()}>
                Sentiment : {emotionNameToLabel(emotionKey)}
              </MenuItem>
            )}
          {type === "event" ? (
            <>
              <MenuItem onClick={(e) => handleAddMeeting(e)}>
                Create Meeting
              </MenuItem>
              <MenuItem onClick={(e) => handleAddTask(e)}>Create Task</MenuItem>
              {process.env.REACT_APP_APP_ENV == "dev" && (
                <MenuItem onClick={(e) => handleAddReminder(e)}>
                  Add Reminder
                </MenuItem>
              )}
            </>
          ) : type === "contact" ? (
            <>
              <MenuItem onClick={(e) => handleAddContact(e, entityName)}>
                Create Contact
              </MenuItem>
              <GetSuggestionsFromElasticSearch
                name={entityName}
                types={["leads", "contacts"]}
                elasticToken={elasticToken}
              />
            </>
          ) : type === "organization" ? (
            <>
              <MenuItem onClick={(e) => handleAddAccount(e, entityName)}>
                Create Account
              </MenuItem>
              <GetSuggestionsFromElasticSearch
                name={entityName}
                types={["accounts"]}
                elasticToken={elasticToken}
              />
            </>
          ) : type === "price" ? (
            <>
              <MenuItem onClick={(e) => handleAddDeal(e, entityName)}>
                Create Deal
              </MenuItem>
            </>
          ) : type === "date" ? (
            <>
              <MenuItem onClick={(e) => handleAddMeeting(e)}>
                Create Meeting
              </MenuItem>
              {process.env.REACT_APP_APP_ENV == "dev" && (
                <MenuItem onClick={(e) => handleAddReminder(e)}>
                  Add Reminder
                </MenuItem>
              )}
            </>
          ) : null}
        </Box>
      </Popover>
    </>
  );
}

export default Entity;
