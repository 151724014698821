import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Grid, Popover } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateEvent from "./../dashboard/CreateEvent";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import EventIcon from "@mui/icons-material/Event";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CreateReminder from "../common/CreateReminder";
import MenuItem from "@mui/material/MenuItem";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundImage: "none",
    color: "rgba(125, 168, 251, 1)",
    fontSize: 10,
    fontWeight: "normal",
  },
}));
const useStyles = makeStyles({
  fontStyleClass: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "100%",
  },
  bulkAvatar: {
    height: "20px",
    width: "20px",
    fontSize: "14px",
  },
  participantsBlock: {
    "& .MuiAvatar-colorDefault": {
      height: "20px",
      width: "20px",
    },
  },
});

function ParticipantsAvatars(props) {
  const { row } = props;
  const classes = useStyles();
  let organizerName = "";
  if (props.organizerName !== undefined) {
    organizerName = props.organizerName;
  }

  // console.log('organizerName',organizerName)
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { participants } = row;
  if (participants !== undefined && organizerName !== undefined) {
    participants.organizerName = organizerName;
  }

  // sort by name
  // participants.sort(function (a, b) {
  //   console.log("a", a);
  //   console.log("b", b);
  //   var nameA = a.name; // ignore upper and lowercase
  //   var nameB = b.name; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // console.log('participants', participants)

  if (participants && participants.length > 0) {
    return (
      <Grid
        container
        className={classes.participantsBlock}
        sx={{ justifyContent: "flex-end" }}
      >
        <AvatarGroup max={5}>
          {participants.map((p, index) => {
            const { name, avatar, email } = p;
            let fChar = "";
            if (avatar === undefined || avatar === "") {
              if (name !== undefined && name !== "") {
                fChar = name.charAt(0);
              }
              if (fChar === "" && email !== undefined && email !== "") {
                fChar = email.charAt(0);
              }
            }
            if (fChar !== "") {
              fChar = fChar.toUpperCase();
            }
            if (
              participants.organizerName &&
              name == participants.organizerName
            ) {
              return (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                  title="Organiser"
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                  }
                >
                  <Avatar
                    className={classes.bulkAvatar}
                    alt={name}
                    src={avatar}
                    key={index}
                    title={name}
                    style={{
                      border: "1px solid #2C73FF",
                    }}
                  />
                </StyledBadge>
                // <Badge
                //   overlap="circular"
                //   title="Organiser"
                //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
                //   badgeContent={
                //     <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                //   }
                // >
                //   <Avatar
                //     className="pop-avatar persons"
                //     alt={participants.name}
                //     src={participants.avatar}
                //     key={index}
                //   />
                // </Badge>
              );
            } else {
              return (
                <Avatar
                  key={index}
                  alt={name}
                  src={avatar}
                  className={classes.bulkAvatar}
                  title={name}
                  // style={{
                  //   border: "1px solid #7DA8FB",
                  // }}
                >
                  {fChar}
                </Avatar>
              );
            }
            // return (
            //   <>
            //     {
            //       participants.organizerName && name == participants.organizerName ? (
            //         <StyledBadge
            //           overlap="circular"
            //           anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            //           variant="dot"
            //           title="Organiser"
            //         >
            //           <Avatar
            //             className="bulk-avatar"
            //             alt={name}
            //             src={avatar}
            //             key={index}
            //           />
            //         </StyledBadge>
            //       ) : (
            //         <Avatar
            //           key={index}
            //           alt={name}
            //           src={avatar}
            //           className="bulk-avatar"
            //         >
            //           {fChar}
            //         </Avatar>
            //       )
            //     }

            //   </>
            // );
          })}
        </AvatarGroup>
        <a
          // href="/"
          aria-describedby={row.id}
          onClick={handleClick}
          className="expand-arrow-icon"
          style={{ cursor: "pointer" }}
        >
          {participants.length - 4 > 0 ? `+${participants.length - 4}` : null}
        </a>
        <Popover
          id={row.id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: { minWidth: "147px", paddingRight: "20px" },
          }}
        >
          {participants.map((p, index) => {
            // console.log('ppp',p)
            const { name, avatar, email } = p;
            let fChar = "";
            if (avatar === undefined || avatar === "") {
              if (name !== undefined && name !== "") {
                fChar = name.charAt(0);
              }
              if (fChar === "" && email !== undefined && email !== "") {
                fChar = email.charAt(0);
              }
            }
            if (fChar !== "") {
              fChar = fChar.toUpperCase();
            }
            let txtToDisplay = name;
            if (name === undefined || name === "") {
              txtToDisplay = email;
            }
            return (
              <Grid key={index} container className="past-popup-participants">
                <Grid item xs={3} lg={3}>
                  {participants.organizerName &&
                  name == participants.organizerName ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      variant="dot"
                      title="Organiser"
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                      }
                    >
                      <Avatar
                        alt={name}
                        src={avatar}
                        className="pop-avatar"
                        style={{
                          border: "1px solid #2C73FF",
                        }}
                      />
                    </StyledBadge>
                  ) : (
                    <Avatar
                      alt={name}
                      src={avatar}
                      className="pop-avatar"
                      title={name}
                      // style={{
                      //   border: "1px solid #7DA8FB",
                      // }}
                    >
                      {fChar}
                    </Avatar>
                  )}
                </Grid>
                <Grid item xs={9} lg={9}>
                  {txtToDisplay}
                </Grid>
              </Grid>
            );
          })}
        </Popover>
      </Grid>
    );
  } else {
    return null;
  }
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // background: "#B345D2",
    backgroundImage: `url(/images/host.png)`,
    // color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      // animation: "ripple 1.2s infinite ease-in-out",
      // border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  // animation: 'ripple 1.2s infinite ease-in-out',
  width: 10,
  height: 12,
  // border: `1px solid ${theme.palette.background.paper}`,
}));

function UpcomingMeetingsTable({
  data,
  email,
  refreshUpcomingMeetings,
  meetingTitle,
}) {
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showCreateReminder, setShowCreateReminder] = useState(false);
  const [activeTab, setActiveTab] = useState("meetings");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const closeCreateEventPopup = () => {
    // setTimeout(() => {
    //   refreshUpcomingMeetings();
    // }, 3000);
    setShowCreateEvent(false);
  };

  const classes = useStyles();
  return (
    <div style={{ height: "100%", minHeight: 300 }}>
      {showCreateEvent && (
        <CreateEvent
          onClose={closeCreateEventPopup}
          email={email}
          open={showCreateEvent}
          title={meetingTitle}
        />
      )}
      {showCreateReminder && (
        <CreateReminder
          addReminderDialogStatus={showCreateReminder}
          handleCloseAddReminderDialog={() => setShowCreateReminder(false)}
        />
      )}
      <Box display="flex" alignItems="center">
        <div
          className={`tab-menu ${
            activeTab === "meetings" && process.env.REACT_APP_APP_ENV === "dev"
              ? "active"
              : ""
          }`}
          onClick={() => setActiveTab("meetings")}
        >
          <h5 className="agenda">Upcoming Meetings</h5>
        </div>
        {process.env.REACT_APP_APP_ENV === "dev" && (
          <div
            className={`tab-menu ${activeTab === "reminders" ? "active" : ""}`}
            onClick={() => setActiveTab("reminders")}
          >
            <h5 className="agenda">Reminders</h5>
          </div>
        )}
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          onClick={handleOpenMenu}
        />
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: 200,
              maxWidth: "40ch",
            },
          }}
        >
          <Box p={2} display="flex" flexDirection="column">
            <button
              className="modal-button blue d-flex-center"
              onClick={() => {
                handleCloseMenu();
                setShowCreateEvent(true);
              }}
            >
              <EventIcon sx={{ fontSize: 16, color: "#fff", mr: 1 }} />
              Create meeting
            </button>
            {process.env.REACT_APP_APP_ENV === "dev" && (
              <button
                className="modal-button blue d-flex-center"
                style={{ marginTop: 8 }}
                onClick={() => {
                  handleCloseMenu();
                  setShowCreateReminder(true);
                }}
              >
                <NotificationsActiveIcon
                  sx={{ fontSize: 16, color: "#fff", mr: 1 }}
                />
                Create reminder
              </button>
            )}
          </Box>
        </Menu>
      </Box>
      <div className="shadow-container h-calc">
        <TableContainer
          style={{
            height: "100%",
            overflow: "scroll",
            borderRadius: "0 !important",
            boxShadow: "0 16px 32px -4px rgba(145, 158, 171, .24)",
            maxHeight: 600,
          }}
          component={Paper}
          className={`meeting-table ${
            activeTab === "meetings" && process.env.REACT_APP_APP_ENV === "dev"
              ? "border-radius-zero"
              : ""
          }`}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell align="left">Date/Time</StyledTableCell>
                <StyledTableCell align="right">Participants</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeTab === "meetings" &&
                data.map((meeting, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      className={classes.fontStyleClass}
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#404040",
                        textAlign: "start",
                        cursor: "pointer",
                      }}
                      component="th"
                      scope="row"
                      align={"left"}
                      onClick={() => window.open(meeting.htmlLink, "_blank")}
                    >
                      {meeting.title}
                    </TableCell>
                    <TableCell
                      className={classes.fontStyleClass}
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#707070",
                        paddingLeft: "0px",
                        textAlign: "start",
                      }}
                      align="left"
                    >
                      {moment(meeting.startTime).format("lll")}
                    </TableCell>
                    <TableCell align="right">
                      <ParticipantsAvatars
                        row={meeting}
                        organizerName={meeting.organizer.displayName}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="shadow" />
      </div>
    </div>
  );
}

export default UpcomingMeetingsTable;
