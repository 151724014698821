import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import { Box, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { getElasticToken } from "./../../firebase/firestore";
import ElasticLeadAutocomplete from "./../Tasks/ElasticLeadAutocomplete.js";
import ElasticContactAutocomplete from "./../Tasks/ElasticContactAutocomplete.js";
import ElasticAccountAutocomplete from "../Tasks/ElasticAccountAutocomplete";
import { elasticSearchCRM } from "../meetingTable/tabularDataConstants";
import moment from "moment";
import TabularFilterMenu from "./TabularFilterMenu";
import TabularColumnsMenu from "./TabularColumnsMenu";

const isSelected = (rowId, selectedRows) => {
  let checkRow = selectedRows?.find((item) => item === rowId);
  if (checkRow) {
    return true;
  }
  return false;
};

const onRowSelect = (e, selectedRowId, selectedRows, setSelectedRows) => {
  e.stopPropagation();
  let find = selectedRows.find((item) => item === selectedRowId);
  if (find) {
    // deselect
    setSelectedRows(selectedRows.filter((item) => item !== selectedRowId));
  } else {
    setSelectedRows([selectedRowId, ...selectedRows]);
  }
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  let splittedName = name?.split(" ");
  if (splittedName?.length > 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }
};

const TableNameColumn = ({
  cellValues,
  selectedRows,
  setSelectedRows,
  isAccount,
}) => {
  return (
    <div className="lead-list-name">
      <div
        className={`lead-list-avatar ${
          isSelected(cellValues.row.id, selectedRows) ? "selected" : ""
        } ${isAccount ? "account" : ""}`}
        onClick={(e) => {
          onRowSelect(e, cellValues.row.id, selectedRows, setSelectedRows);
        }}
      >
        {isAccount ? (
          cellValues?.row?.avatar ? (
            <div className="account-logo" style={{ marginRight: "0" }}>
              <img
                style={{
                  marginRight: "1em",
                  border: "1px solid #7DA8FB",
                  height: "50px",
                  minWidth: "50px",
                  maxWidth: "50px",
                  borderRadius: ".2em",
                }}
                src={cellValues.row.avatar}
              />
            </div>
          ) : (
            <div
              style={{
                background: stringAvatar(cellValues.row.name).sx.bgcolor,
                marginRight: "0",
              }}
              className="account-logo"
            >
              {stringAvatar(cellValues.row.name).children}
            </div>
          )
        ) : (
          <Avatar
            src={cellValues.row.avatar}
            style={{ height: 40, width: 40 }}
          />
        )}
        <div className="overlay">
          <div className={`icon ${isAccount ? "account" : ""}`}>
            <CheckIcon
              fontSize={"medium"}
              sx={
                isSelected(cellValues.row.id, selectedRows)
                  ? { color: "#2c73ff" }
                  : { color: "#fff" }
              }
            />
          </div>
        </div>
      </div>
      <span>{cellValues.row.name}</span>
    </div>
  );
};

const CustomToolbar = (props) => {
  const [elasticToken, setElasticToken] = useState(null);
  let {
    selectedRows,
    handleSelectAll,
    setDeleteLeadDialogStatus,
    setMergeDialogStatus,
    history,
    type,
    setActiveFilters,
    pageNumber,
    handlePageChange,
    rowCount,
    activeColumns,
    setActiveColumns,
    tableColumns,
  } = props;

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  const setRelatedTo = (data) => {
    if (type == "contacts") {
      history.push(`/crm/contact/${data.contactId}`);
    }
    if (type == "leads") {
      history.push(`/crm/lead/${data.leadId}`);
    }
    if (type == "accounts") {
      history.push(`/crm/account/${data.accountId}`);
    }
  };

  return (
    <GridToolbarContainer
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" alignItems="center">
        {selectedRows?.length ? (
          <div className="filter-icon-container">
            <div
              className="lead-tabular-toolbar-icon filter-icon"
              onClick={handleSelectAll}
            >
              {selectedRows?.length === rowCount ? (
                <IndeterminateCheckBoxIcon
                  sx={{ fontSize: 18, color: "#2c73ff" }}
                  onClick={handleSelectAll}
                />
              ) : (
                <CheckBoxIcon sx={{ fontSize: 18, color: "#2c73ff" }} />
              )}
            </div>
          </div>
        ) : null}
        <TabularColumnsMenu
          columns={tableColumns}
          activeColumns={activeColumns}
          setActiveColumns={setActiveColumns}
        />
        <TabularFilterMenu type={type} setFilters={setActiveFilters} />
        <AddIcon
          sx={{ marginRight: "12px", cursor: "pointer" }}
          className="lead-tabular-toolbar-icon"
          onClick={() =>
            history.push(
              type === "leads"
                ? "/crm/leads/create"
                : type === "contacts"
                ? "/crm/contact/create"
                : type === "accounts"
                ? "/crm/accounts/create"
                : "/"
            )
          }
          title={
            type === "leads"
              ? "Add lead"
              : type === "contacts"
              ? "Add contact"
              : type === "accounts"
              ? "Add account"
              : ""
          }
        />
        <DeleteIcon
          sx={{ marginRight: "12px", cursor: "pointer" }}
          className={`lead-tabular-toolbar-icon ${
            selectedRows?.length > 0 ? "" : "disabled"
          }`}
          onClick={() => {
            if (selectedRows?.length > 0) setDeleteLeadDialogStatus(true);
          }}
        />
        {(type === "leads" || type === "contacts") && (
          <CallMergeIcon
            sx={{ marginRight: "12px", cursor: "pointer" }}
            className={`lead-tabular-toolbar-icon ${
              selectedRows?.length > 1 ? "" : "disabled"
            }`}
            onClick={() => {
              if (selectedRows?.length > 1) setMergeDialogStatus(true);
            }}
          />
        )}
        {elasticToken && (
          <Box style={{ marginLeft: "5px", width: 300 }}>
            {type === "contacts" ? (
              <ElasticContactAutocomplete
                setRelatedTo={setRelatedTo}
                apiKey={elasticToken}
                className={"elastic-input-tabular"}
              />
            ) : type === "accounts" ? (
              <ElasticAccountAutocomplete
                setRelatedTo={setRelatedTo}
                apiKey={elasticToken}
                className={"elastic-input-tabular"}
              />
            ) : type === "leads" ? (
              <ElasticLeadAutocomplete
                setRelatedTo={setRelatedTo}
                apiKey={elasticToken}
                className={"elastic-input-tabular"}
              />
            ) : null}
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {`${rowCount > 0 ? pageNumber * 50 + 1 : 0}-${
          (pageNumber + 1) * 50 < rowCount ? (pageNumber + 1) * 50 : rowCount
        } of ${rowCount}`}
        <Box display="flex" alignItems="center" justifyContent="center">
          <ChevronLeftIcon
            className={`tabular-data-pagination-icon ${
              pageNumber - 1 < 0 ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(pageNumber - 1)}
          />
          <ChevronRightIcon
            className={`tabular-data-pagination-icon ${
              pageNumber + 1 > Math.floor(rowCount / 50) ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(pageNumber + 1)}
          />
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

function ListTabular({
  setDeleteLeadDialogStatus,
  selectedRows,
  setSelectedRows,
  type,
  setMergeDialogStatus,
  stateData,
  setStateData,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [activeFilters, setActiveFilters] = useState({ items: [] });
  const [activeSort, setActiveSort] = useState([]);
  const [elasticToken, setElasticToken] = useState(null);
  const [activeColumns, setActiveColumns] = useState(
    type === "leads"
      ? [
          "name",
          "role",
          "company",
          "email",
          "leadStatus",
          "rating",
          "leadSource",
          "createdAt",
        ]
      : type === "contacts"
      ? ["name", "role", "email", "createdAt"]
      : type === "accounts"
      ? ["name", "accountType", "annualRevenue", "createdAt"]
      : []
  );

  useEffect(() => {
    setLoading(true);
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };
    loadElasticToken();
  }, []);

  const handlePageChange = (newPageNumber) => {
    if (newPageNumber > Math.floor(rowCount / 50) || newPageNumber < 0) {
      return;
    }
    if (
      newPageNumber > pageNumber &&
      newPageNumber * 50 >= stateData?.length &&
      stateData?.length < rowCount
    ) {
      elasticSearchCRM(
        activeFilters,
        activeSort[0]?.field,
        activeSort[0]?.sort,
        newPageNumber,
        type,
        elasticToken
      )
        .then((data) => {
          if (data?.results?.length > -1) {
            setStateData([...stateData, ...data.results]);
          }
        })
        .catch((err) => {
          console.log(err, "new elastic search response");
        });
    }
    setPageNumber(newPageNumber);
  };

  const getFilteredRows = () => {
    if (!elasticToken) {
      return;
    }
    setPageNumber(0);
    elasticSearchCRM(
      activeFilters,
      activeSort[0]?.field,
      activeSort[0]?.sort,
      0,
      type,
      elasticToken
    )
      .then((data) => {
        if (data?.results?.length > -1) {
          setStateData(data.results);
          setRowCount(data.total);
        }
      })
      .catch((err) => {
        console.log(err, "new elastic search response");
      });
  };

  useMemo(getFilteredRows, [activeFilters, activeSort, elasticToken]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 250,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <TableNameColumn
            cellValues={cellValues}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyLead: true,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues) => {
        return <span>{cellValues?.formattedValue}</span>;
      },
    },
    {
      field: "leadStatus",
      headerName: "Lead Status",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      flex: 1,
      isOnlyLead: true,
    },
    {
      field: "rating",
      headerName: "Rating",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyLead: true,
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyLead: true,
    },
    {
      field: "leadOwner",
      headerName: "Lead Owner",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyLead: true,
    },
    {
      field: "contactOwner",
      headerName: "Contact Owner",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "asstPhone",
      headerName: "Assistant",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "annualRevenue",
      headerName: "Annual Revenue",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "assistantPhone",
      headerName: "Assistant Phone",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      isOnlyContact: true,
    },
    {
      field: "salutation",
      headerName: "Salutation",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "secondaryEmail",
      headerName: "Secondary Email",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "skypeId",
      headerName: "Skype Id",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "telephoneNumber",
      headerName: "Telephone Number",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "fax",
      headerName: "Fax",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "street",
      headerName: "Street",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "state",
      headerName: "State",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "website",
      headerName: "Website",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "noOfEmployee",
      headerName: "No. Of Employee",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "emailOptOut",
      headerName: "Email Opt-out",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "industry",
      headerName: "Industry",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      renderCell: (cellValues) => (
        <span>{cellValues.row.createdAt?.label}</span>
      ),
    },
  ];
  const accountColumns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 250,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <TableNameColumn
            cellValues={cellValues}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isAccount
          />
        );
      },
    },
    {
      field: "accountType",
      headerName: "Account type",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "annualRevenue",
      headerName: "Annual revenue",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 180,
      renderCell: (cellValues) => (
        <span>{cellValues.row.createdAt?.label}</span>
      ),
    },
  ];
  const generateRows = () => {
    const rows = [];
    if (type === "accounts") {
      stateData?.map((row) => {
        rows.push({
          id: row.id,
          name: row.name,
          accountType: row.accountType,
          annualRevenue: row.annualRevenue,
          createdAt: {
            label: row?.createdAt?.seconds
              ? moment(row?.createdAt?.seconds * 1000).format("MMM DD, h:mm A")
              : "",
            value: row?.createdAt?.seconds ? row?.createdAt?.seconds : 0,
          },
        });
      });
      let findActiveColumns = accountColumns.filter((item) =>
        activeColumns.includes(item.field)
      );
      setTableColumns(findActiveColumns);
    } else {
      stateData?.map((row) => {
        rows.push({
          id: row.id,
          avatar: row?.avatar,
          name: `${row?.firstName ? row.firstName : ""} ${
            row?.lastName ? row.lastName : ""
          }`,
          role: row?.role ? row.role : "",
          company: row?.accountName ? row.accountName : "",
          email: row?.email ? row.email : "",
          leadStatus: row?.leadStatus ? row.leadStatus : "",
          rating: row?.rating ? row.rating : "",
          leadSource: row?.leadSource ? row.leadSource : "",
          leadOwner: row?.leadOwner ? row.leadOwner : "",
          salutation: row?.salutation ? row.salutation : "",
          title: row?.title ? row.title : "",
          secondaryEmail: row?.secondaryEmail ? row.secondaryEmail : "",
          skypeId: row?.skypeId ? row.skypeId : "",
          mobileNumber: row?.mobileNumber ? row.mobileNumber : "",
          telephoneNumber: row?.telephoneNumber ? row.telephoneNumber : "",
          fax: row?.fax ? row.fax : "",
          street: row?.street ? row.street : "",
          city: row?.city ? row.city : "",
          state: row?.state ? row.state : "",
          zipCode: row?.zipCode ? row.zipCode : "",
          country: row?.country ? row.country : "",
          website: row?.website ? row.website : "",
          noOfEmployee: row?.noOfEmployee ? row.noOfEmployee : "",
          emailOptOut: row?.emailOptOut ? row.emailOptOut : "",
          industry: row?.industry ? row.industry : "",
          contactOwner: row?.contactOwner ? row.contactOwner : "",
          accountName: row?.accountName ? row.accountName : "",
          asstPhone: row?.asstPhone ? row.asstPhone : "",
          gender: row?.gender ? row.gender : "",
          annualRevenue: row?.annualRevenue ? row.annualRevenue : "",
          description: row?.description ? row.description : "",
          assistantPhone: row?.assistantPhone ? row.assistantPhone : "",
          createdAt: {
            label: row?.createdAt?.seconds
              ? moment(row?.createdAt?.seconds * 1000).format("MMM DD, h:mm A")
              : "",
            value: row?.createdAt?.seconds ? row?.createdAt?.seconds : 0,
          },
        });
      });
      setTableColumns(
        columns.filter((item) => {
          if (type === "leads") {
            return !item?.isOnlyContact && activeColumns.includes(item.field);
          } else if (type === "contacts") {
            return !item?.isOnlyLead && activeColumns.includes(item.field);
          } else {
            return [];
          }
        })
      );
    }
    setTableRows(rows);
    setLoading(false);
  };

  useMemo(generateRows, [stateData, selectedRows, activeColumns]);

  const handleSortModelChange = (sortModel) => {
    setActiveSort(sortModel);
  };

  const handleSelectAll = () => {
    if (selectedRows.length === tableRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableRows.map((row) => row.id));
    }
  };

  return (
    <div
      style={{
        height: "80vh",
        background: "#fff",
        marginBottom: "3em",
        borderRadius: "1em",
      }}
    >
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
          minHeight={500}
          sx={{ background: "#fff", borderRadius: ".5rem" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <DataGrid
          rows={tableRows}
          columns={tableColumns}
          sortingMode={"server"}
          onSortModelChange={handleSortModelChange}
          pageSize={50}
          rowsPerPageOptions={[50]}
          disableSelectionOnClick
          disableColumnMenu
          getRowClassName={(row) =>
            `data-table-row ${
              isSelected(row.id, selectedRows) ? "selected" : ""
            }`
          }
          className={"lead-list-tabular"}
          page={pageNumber}
          components={{
            Toolbar: CustomToolbar,
            Pagination: null,
          }}
          componentsProps={{
            toolbar: {
              selectedRows,
              handleSelectAll,
              setDeleteLeadDialogStatus,
              setMergeDialogStatus,
              history,
              type,
              setActiveFilters,
              pageNumber,
              handlePageChange,
              rowCount,
              tableColumns:
                type === "accounts"
                  ? accountColumns
                  : type === "contacts"
                  ? columns.filter((item) => !item?.isOnlyLead)
                  : type === "leads"
                  ? columns.filter((item) => !item?.isOnlyContact)
                  : [],
              activeColumns,
              setActiveColumns,
            },
          }}
          onRowClick={(lead) =>
            history.push(
              `/crm/${
                type === "leads"
                  ? "lead"
                  : type === "accounts"
                  ? "account"
                  : "contact"
              }/${lead.id}`
            )
          }
        />
      )}
    </div>
  );
}

export default ListTabular;
