import React, { useState, useEffect } from "react";
import { Post } from "../../utils/axiosUtils";
import { Box, Card, CardHeader, Avatar } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

function ConsiderReview(props) {
  const [considerData, setConsiderData] = useState([]);

  const handlerowClick = (rowData) => {
    props.history.push(`/contact/${rowData.contactId}`);
  };

  const fetchReviews = () => {
    Post("analytics/getLosingTouch", {
      start: new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
      end: new Date().getTime(),
      comparisonStartTime: new Date().getTime() - 60 * 24 * 60 * 60 * 1000,
      comparisonEndTime: new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
    })
      .then(({ data: { data } }) => {
        data.sort((a, b) => b.difference - a.difference);
        setConsiderData(data);
      })
      .catch((err) => {
        console.log(err, "getLosingTouch");
      });
  };

  useEffect(fetchReviews, []);

  return (
    <Card
      id="block-past-meetings"
      sx={{
        mt: 3,
        borderRadius: ".5rem !important",
        height: "100%",
        boxShadow: "none",
      }}
    >
      <CardHeader title="Insights" sx={{ mb: 0, fontSize: 16 }} />
      <Typography sx={{ mb: 1.5, padding: "0 24px" }} color="text.secondary">
        Consider reviewing the following contacts:
      </Typography>

      <Box
        sx={{
          display: "flex",
          padding: "0 24px 24px 24px",
          justifyContent: "flex-start",
        }}
      >
        {considerData.slice(0, 6).map((data, index) => (
          <Tooltip title={data.name} key={index}>
            <Avatar
              src={data.avatar}
              alt="user-avatar"
              sx={{ cursor: "pointer", marginRight: ".5em" }}
            />
          </Tooltip>
        ))}
      </Box>
    </Card>
  );
}

export default ConsiderReview;
