import React, {useEffect, useState} from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, CircularProgress, Tooltip} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import {dateDifference} from "../../utils/dateDifference";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextEditor from "../TextEditor";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import {styled} from "@material-ui/core/styles";
import {tooltipClasses} from "@mui/material/Tooltip";
import {Delete, Get, Patch} from "../../utils/axiosUtils";
import useAuth from "../../hooks/useAuth";
import NoteTextEditor from "./NoteTextEditor";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        marginTop: "0 !important",
    },
}));

function NotesAccordion({note,expandedNote,setExpandedNote,notes,setNotes,mentionSuggestions}) {
    const { fireStoreuserObj } = useAuth();
    const [attachmentsLoading,setAttachmentsLoading] = useState(false);
    const [deleteLoading,setDeleteLoading] = useState(false);
    const [attachments,setAttachments] = useState([]);
    const [attachmentOpenLoading,setAttachmentOpenLoading] = useState(false);
    const [editNote,setEditNote] = useState(false);
    const [updateLoading,setUpdateLoading] = useState(false);
    const [editNoteEditorState,setEditNoteEditorState] = useState(EditorState.createEmpty());
    const [editNoteTitle,setEditNoteTitle] = useState(note.title);
    const [noteState,setNoteState] = useState(EditorState.createEmpty());
    const [loading,setLoading] = useState(true);

    const prepareEditorStates = () => {
        try {
            setEditNoteEditorState(
                EditorState.createWithContent(convertFromRaw(note.content))
            );
            setNoteState(EditorState.createWithContent(convertFromRaw(note.content)));
        } catch (e) {
            setEditNoteEditorState(EditorState.createEmpty());
            setNoteState(EditorState.createEmpty());
        }
        setLoading(false);
    };

    useEffect(prepareEditorStates,[]);

    const getNotesAttachments = (noteId) => {
        setAttachmentsLoading(true);
        Get(`attachments/notes/${noteId}`)
            .then(({ data }) => {
                setAttachments(data?.data);
                setAttachmentsLoading(false);
            })
            .catch((err) => {
                console.log(err, "error happen when fetching note attachments");
                setAttachmentsLoading(false);
            });
    };



    const handleDeleteNote = (e, noteId) => {
        e.stopPropagation();
        setDeleteLoading(true);
        Delete(`notes/${noteId}`)
            .then(() => {
                let newNotes = notes.filter((item) => item.id !== noteId);
                setNotes(newNotes);
                setDeleteLoading(false);
            })
            .catch((err) => {
                console.log(err, "error happen when deleting note");
                setDeleteLoading(false);
            });
    };

    const attachmentOpen = (attachmentId) => {
        setAttachmentOpenLoading(true);
        Get(`attachments/${attachmentId}`)
            .then(({ data }) => {
                window.open(data.data.url, "_blank");
                setAttachmentOpenLoading(false);
            })
            .catch((err) => {
                console.log(err, "error happen when opening attachment");
                setAttachmentOpenLoading(false);
            });
    };

    const deleteAttachment = (attachmentId) => {
        setAttachmentOpenLoading(false);
        Delete(`attachments/${attachmentId}`)
            .then(() => {
                let newAttachments = attachments.filter(
                    (item) => item.id !== attachmentId
                );
                setAttachments(newAttachments);
                setAttachmentOpenLoading(false);
            })
            .catch((err) => {
                console.log(err, "error happen when deleting attachment");
                setAttachmentOpenLoading(false);
            });
    };

    const updateNote = (e, noteId) => {
        e.preventDefault();
        setUpdateLoading(true);
        let postBody = {
            content: convertToRaw(editNoteEditorState.getCurrentContent()),
            title: editNoteTitle,
        };
        Patch(`notes/update/${noteId}`, postBody)
            .then(({ data }) => {
                let Index = notes.findIndex((item) => item.id === noteId);
                let newNotes = [...notes];
                newNotes[Index].content = data.data.content;
                newNotes[Index].title = data.data.title;
                setNotes(newNotes);
                setUpdateLoading(false);
                setEditNote(false);
                setNoteState(editNoteEditorState);
            })
            .catch((err) => {
                console.log(err, "error happen when updating note");
                setUpdateLoading(false);
            });
    };


    const getEditorText = (content) => {
        try {
            const rawData = convertFromRaw(content);
            return EditorState.createWithContent(rawData)
                .getCurrentContent()
                .getPlainText("\u0001");
        } catch (e) {
            return "";
        }
    };


    return (
        <Accordion
            sx={{ boxShadow: "none",padding:'0 .3em'}}
            expanded={expandedNote === note.id}
            onChange={(e, expanded) => {
                if (expanded) {
                    getNotesAttachments(note.id);
                    setExpandedNote(note.id);
                } else {
                    setAttachments([]);
                    setEditNote(false);
                    setExpandedNote(null);
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 0,
                }}
                className="note-accordion"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                    maxWidth={"150px"}
                    minWidth={"120px"}
                >
                    <Avatar
                        src={note?.user?.photo}
                        sx={{
                            width: 25,
                            height: 25,
                            border: "1px solid #7DA8FB",
                        }}
                    />
                    <Box display="flex" flexDirection="column" sx={{ ml: 1 }}>
                        <h6 className="note-profile-name">{note.user?.name}</h6>
                        <LightTooltip
                            title={moment(note.createdAt).format("lll")}
                            componentsProps={{
                                popper: { sx: { backgroundColor: "#FFFFE6" } },
                            }}
                            placement={"bottom"}
                        >
                          <span className="note-date">
                            {note?.createdAt
                                ? dateDifference(note.createdAt)
                                : ""}
                          </span>
                        </LightTooltip>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "57%" }}
                >
                    <h6 className="note-profile-name">{note?.title}</h6>
                    {note?.content && note?.content !== "" && (
                        <span className="note-description">
                          {getEditorText(note.content)}
                        </span>
                    )}
                </Box>
                {note?.user?.email === fireStoreuserObj.email && (
                    <Box className="note-icons">
                        <EditIcon
                            fontSize={"small"}
                            sx={{ color: "#404040" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditNote(true);
                                let getIndex = notes.findIndex(
                                    (item) => item.id === note.id
                                );
                                if (expandedNote !== getIndex) {
                                    setExpandedNote(note.id);
                                }
                            }}
                        />
                        {deleteLoading ? (
                            <CircularProgress
                                size={"14px"}
                                sx={{ ml: 1, color: "#404040" }}
                            />
                        ) : (
                            <DeleteIcon
                                onClick={(e) => handleDeleteNote(e, note.id)}
                                fontSize={"small"}
                                sx={{ ml: 1, color: "#404040" }}
                            />
                        )}
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                {!loading &&
                    editNote ? (
                    <NoteTextEditor
                        isUpdate
                        mentionSuggestions={mentionSuggestions}
                        editorState={editNoteEditorState}
                        setEditorState={setEditNoteEditorState}
                        title={editNoteTitle}
                        setTitle={setEditNoteTitle}
                        buttonLoading={updateLoading}
                        onSubmit={(e) => updateNote(e, note.id)}
                        onCancel={() => {
                            setEditNote(false);
                            setEditNoteEditorState(noteState);
                        }}
                    />
                ) : !loading && !editNote && (
                    <TextEditor
                        readOnly
                        toolbarHidden
                        editorState={noteState}
                        onEditorStateChange={setNoteState}
                        handlePastedFiles={() => {}}
                        mentions={mentionSuggestions}
                    />
                )}
                {attachmentsLoading ? (
                    <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CircularProgress
                            size={"14px"}
                            style={{
                                color: "#2C73FF",
                            }}
                        />
                    </Box>
                ) : (
                    attachments?.length > 0 && (
                        <div className="attachments">
                            {attachments?.map((item, index) => (
                                <div
                                    className="attachment"
                                    onClick={() => attachmentOpen(item.id)}
                                    key={index}
                                >
                                    <span className="title">{item?.fileName}</span>
                                    {attachmentOpenLoading ? (
                                        <CircularProgress
                                            size={"14px"}
                                            style={{
                                                cursor: "pointer",
                                                color: "#303030",
                                            }}
                                        />
                                    ) : (
                                        editNote && (
                                            <ClearIcon
                                                fontSize={"14px"}
                                                sx={{ color: "#303030", cursor: "pointer" }}
                                                onClick={() => deleteAttachment(item.id)}
                                            />
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                    )
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default NotesAccordion;
