import React, { useState, useEffect, useMemo } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { styled } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MeetingOutcome from "./MeetingOutcome";
import AddMeetingTopics from "./AddMeetingTopics";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  updateAttendeesType,
  updateMeetingTitle,
} from "../../firebase/firestore";

const StyledBadge = styled(Badge)(({ theme }) => ({
  background: "white",
  "& .MuiBadge-badge": {
    backgroundImage: `url(/images/host.png)`,
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    left: 0,
    width: "5px",
    backgroundSize: "cover",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  // animation: 'ripple 1.2s infinite ease-in-out',
  width: 12,
  height: 12,
  // border: `1px solid ${theme.palette.background.paper}`,
}));

const attendeeColors = [
  { type: "EXTERNAL", color: "#fbbc04" },
  { type: "INTERNAL", color: "#abffab" },
  { type: "NONE", color: "#E7E7E7" },
];

const Loading = () => (
  <Stack spacing={1} animation="wave" width={"100%"}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={55}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={55}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
    </Box>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
    </Box>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
    </Box>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
      <Skeleton
        variant="rectangular"
        width={"45%"}
        height={25}
        sx={{ bgcolor: "#cCc", borderRadius: ".5rem" }}
      />
    </Box>
  </Stack>
);

function MeetingDetailSubHeader({
  fulldate,
  timeZone,
  meetingData,
  meetingId,
  meetingDuration,
  showSnackbar,
}) {
  const [meetingTitleInput, setMeetingTitleInput] = useState("");
  const [channelImgUri, setChannelImageUri] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [meetingDurationText, setMeetingDurationText] = useState(null);
  const [attendeeMenu, setAttendeeMenu] = React.useState(null);
  const [attendeesType, setAttendeesType] = useState(null);
  const [loading, setLoading] = useState(true);

  const openAttendee = Boolean(attendeeMenu);
  const handleClickAttendee = (event) => {
    setAttendeeMenu(event.currentTarget);
  };
  const handleAttendeesType = async (attendeesType) => {
    setAttendeeMenu(null);

    let res = await updateAttendeesType({
      meetingId,
      attendeesType,
    });
    if (res) {
      showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
      setAttendeesType({
        type: attendeesType,
        color: attendeeColors.find((item) => item.type === attendeesType)
          ?.color,
      });
    } else {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const handleSaveMeetingTitle = async (val) => {
    let res = await updateMeetingTitle(val, meetingId);
    if (res) {
      showSnackbar({
        show: true,
        severity: "success",
        message: "Meeting title updated successfully.",
      });
    } else {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  useEffect(() => {
    //meeting duration to text
    if (meetingDuration) {
      let hours = Math.floor(meetingDuration / 60 / 60);
      let mins = Math.floor(meetingDuration / 60) - hours * 60;
      setMeetingDurationText(
        `${hours > 0 ? hours + "h" : ""} ${mins > 0 ? mins + "m" : ""}`
      );
    }
  }, [meetingDuration]);

  useMemo(() => {
    if (!meetingData) {
      return;
    }
    setLoading(true);
    //Channel Type
    if (
      meetingData.channelType === "GOOGLE_MEET" ||
      meetingData.channelType === "HYBRID"
    ) {
      setChannelImageUri("/images/google-meet-logo.svg");
    } else if (meetingData.channelType === "ZOOM") {
      setChannelImageUri("/images/zoom-logo.png");
    } else if (meetingData.channelType === "MS_TEAMS") {
      setChannelImageUri("/images/ms-teams-logo.png");
    } else if (meetingData.channelType === "RECORDING") {
      setChannelImageUri("/images/recording_meeting.png");
    }

    //Attendee
    setAttendeesType(
      attendeeColors.find((item) => item.type === meetingData?.attendeesType)
        ? attendeeColors.find(
            (item) => item.type === meetingData?.attendeesType
          )
        : attendeeColors.find((item) => item.type === "NONE")
    );

    //Title
    setMeetingTitleInput(meetingData?.title ? meetingData.title : "");
    setLoading(false);
  }, [meetingData]);
  return (
    <div className="meeting-detail-submenu-header">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="meeting-detail-submenu-container">
            <div
              className="profile-details"
              style={{ height: "unset", flexDirection: "column" }}
            >
              <div
                className="profile-first"
                style={{ minWidth: "unset", height: "auto" }}
              >
                <div
                  className="profile-header-container"
                  style={{ padding: "0 .5em 0 0", width: "max-content" }}
                >
                  <DateRangeIcon
                    sx={{
                      color: "#2C73FF",
                      fontSize: "16px",
                      marginRight: ".5em",
                    }}
                  />
                  {fulldate} {timeZone && `(${timeZone})`}
                </div>
              </div>
              {meetingDurationText && (
                <div
                  className="profile-first"
                  style={{ minWidth: "unset", height: "auto" }}
                >
                  <div
                    className="profile-header-container"
                    style={{ width: "max-content" }}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        color: "#2C73FF",
                        fontSize: "16px",
                        marginRight: ".5em",
                      }}
                    />
                    {meetingDurationText}
                  </div>
                </div>
              )}
              <div
                className="profile-first"
                style={{ minWidth: "unset", height: "auto" }}
              >
                <div
                  className="profile-header-container"
                  style={{ padding: "0 1em", width: "max-content" }}
                >
                  <AvatarGroup
                    sx={{ "& > * ": { borderWidth: "1px !important" } }}
                  >
                    {meetingData?.participants?.map((p, index) => {
                      const { name, avatar, email } = p;
                      let fChar = "";
                      if (avatar === undefined || avatar === "") {
                        if (name !== undefined && name !== "") {
                          fChar = name.charAt(0);
                        }
                        if (
                          fChar === "" &&
                          email !== undefined &&
                          email !== ""
                        ) {
                          fChar = email.charAt(0);
                        }
                      }
                      if (fChar !== "") {
                        fChar = fChar.toUpperCase();
                      }
                      return (
                        <Avatar
                          key={index}
                          alt={name}
                          src={avatar}
                          className="bulk-avatar"
                          title={name}
                          style={
                            name === meetingData?.organizer?.displayName
                              ? {
                                  border: "1px solid #2C73FF",
                                  height: 20,
                                  width: 20,
                                }
                              : {
                                  border: "1px solid #fff",
                                  height: 20,
                                  width: 20,
                                }
                          }
                        >
                          {fChar}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                </div>
              </div>
            </div>
            <Box display="flex" alignItems="center">
              <div
                className="meeting-detail-submenu-item"
                id="meeting-detail-title-container"
              >
                <div>
                  {channelImgUri !== "" && (
                    <img
                      src={channelImgUri}
                      alt="meeting-channel"
                      width={16}
                      height={16}
                      style={{ marginRight: ".5em" }}
                    />
                  )}
                  {!editTitle ? (
                    <span
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                      {meetingTitleInput}
                    </span>
                  ) : (
                    <TextField
                      variant="standard"
                      id="standard-basic"
                      value={meetingTitleInput}
                      onChange={(e) => setMeetingTitleInput(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  )}
                  {!editTitle ? (
                    <div style={{ width: "16px", height: "16px" }}>
                      <EditIcon
                        onClick={() => setEditTitle(true)}
                        className="meeting-detail-title-edit-icon"
                      />
                    </div>
                  ) : (
                    <>
                      <CheckIcon
                        fontSize={"18px"}
                        sx={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => {
                          handleSaveMeetingTitle(meetingTitleInput);
                          setEditTitle(false);
                        }}
                      />
                      <ClearIcon
                        fontSize={"18px"}
                        sx={{
                          color: "#707070",
                          cursor: "pointer",
                          marginLeft: ".5em",
                        }}
                        onClick={() => {
                          setMeetingTitleInput(
                            meetingData?.title ? meetingData.title : ""
                          );
                          setEditTitle(false);
                        }}
                      />
                    </>
                  )}
                </div>

                <Button
                  id="basic-button"
                  size="small"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={openAttendee ? "true" : undefined}
                  onClick={handleClickAttendee}
                  style={{
                    fontSize: "12px",
                    fontFamily: "poppins",
                    textDecoration: "none",
                    color: "#000",
                    backgroundColor: `${attendeesType.color}`,
                    borderRadius: "20px",
                    marginLeft: "1em",
                  }}
                >
                  {attendeesType.type}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={attendeeMenu}
                  open={openAttendee}
                  onClose={() => setAttendeeMenu(false)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => handleAttendeesType("NONE")}>
                    None
                  </MenuItem>
                  <MenuItem onClick={() => handleAttendeesType("INTERNAL")}>
                    Internal
                  </MenuItem>
                  <MenuItem onClick={() => handleAttendeesType("EXTERNAL")}>
                    External
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </div>
          <div className="meeting-detail-submenu-right">
            <div className="vr" />
            <AddMeetingTopics meetingId={meetingId} />
            <MeetingOutcome
              meetingId={meetingId}
              meetingOutcome={meetingData?.meetingOutcome}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MeetingDetailSubHeader;
