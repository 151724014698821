import React, { useMemo, useState } from "react";
import { Avatar, Box, CircularProgress } from "@material-ui/core";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { sentimentIcons } from "../../utils/sentimentIcons";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useHistory } from "react-router";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundImage: "none",
    color: "rgba(125, 168, 251, 1)",
    fontSize: 10,
    fontWeight: "normal",
  },
}));

const useStyles = makeStyles({
  fontStyleClass: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "100%",
    padding: ".5em 0",
  },
  bulkAvatar: {
    height: "20px",
    width: "20px",
    fontSize: "14px",
  },
  participantsBlock: {
    "& .MuiAvatar-colorDefault": {
      height: "20px",
      width: "20px",
    },
  },
});

function MeetingDetailAnalytics({
  isPaidUser,
  participants,
  meetingDataLoading,
}) {
  console.log(participants, "participants");
  const { fireStoreuserObj } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [organizationSettings, setOrganizationSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useMemo(() => {
    if (meetingDataLoading) {
      return;
    }
    if (participants?.length) {
      getOrgainzation(fireStoreuserObj.organizationId)
        .then((data) => {
          setOrganizationSettings(data);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [participants, meetingDataLoading]);

  const checkSentimentPermission = (participant) => {
    if (!organizationSettings) {
      return;
    }
    let sentimentSettings = organizationSettings?.sentimentSettings;
    if (
      fireStoreuserObj.role === "MEMBER" &&
      sentimentSettings?.users === "userOnly"
    ) {
      if (participant.name === fireStoreuserObj.displayName) {
        return sentimentIcons[participant?.sentiment];
      } else {
        return <DoNotDisturbAltIcon />;
      }
    } else if (
      fireStoreuserObj.role === "TEAM_MANAGER" &&
      sentimentSettings?.managers === "off"
    ) {
      return <DoNotDisturbAltIcon />;
    } else if (
      fireStoreuserObj.role === "ADMIN" &&
      sentimentSettings?.admins === "off"
    ) {
      return <DoNotDisturbAltIcon />;
    } else {
      return sentimentIcons[participant?.sentiment];
    }
  };

  const handleOpenContactLead = (participantName) => {
    let findUser = participants.find((item) => item.name === participantName);
    if (findUser?.id && findUser?.type) {
      history.push(`/crm/${findUser.type}/${findUser.id}`);
    }
  };

  return (
    <Box height="max-content">
      <h5 className="agenda">Analytics</h5>
      <TableContainer
        style={{
          height: "350px",
          borderRadius: "10px",
          marginTop: "10px",
          boxShadow: "none",
        }}
        component={Paper}
        className={"meeting-table"}
      >
        {meetingDataLoading || loading || !participants?.length ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            {meetingDataLoading || loading ? (
              <CircularProgress size="24px" style={{ color: "#2c73ff" }} />
            ) : !participants?.length ? (
              "No data found"
            ) : null}
          </Box>
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Participant</StyledTableCell>
                <StyledTableCell align="left">Sentiment</StyledTableCell>
                <StyledTableCell align="left">Participation %</StyledTableCell>
                <StyledTableCell align="left">Time late</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants?.map((participant, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className={classes.fontStyleClass}
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#212B36",
                      textAlign: "start",
                      display: "flex",
                      alignItems: "center",
                    }}
                    component="th"
                    scope="row"
                    align={"left"}
                  >
                    <Avatar
                      src={participant?.avatar}
                      sx={{
                        width: 40,
                        height: 40,
                        border: "1px solid #2c73ff",
                        marginRight: ".5em",
                      }}
                    />
                    <span
                      className={
                        participants.find(
                          (item) => item.name === participant?.name
                        )?.id
                          ? "link-text"
                          : ""
                      }
                      onClick={() => handleOpenContactLead(participant?.name)}
                    >
                      {participant?.name}
                    </span>
                  </TableCell>
                  <TableCell
                    className={classes.fontStyleClass}
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#212B36",
                      textAlign: "start",
                      paddingLeft: "16px",
                      cursor: !isPaidUser ? "pointer" : "default",
                    }}
                    align="left"
                    onClick={() => {
                      if (!isPaidUser) {
                        history.push("/payment");
                      }
                    }}
                  >
                    {isPaidUser ? (
                      checkSentimentPermission(participant)
                    ) : (
                      <QuestionMarkIcon sx={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.fontStyleClass}
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#212B36",
                      textAlign: "start",
                      paddingLeft: "16px",
                      cursor: !isPaidUser ? "pointer" : "default",
                    }}
                    align="left"
                    onClick={() => {
                      if (!isPaidUser) {
                        history.push("/payment");
                      }
                    }}
                  >
                    {isPaidUser ? (
                      participant?.participation ? (
                        participant.participation
                      ) : (
                        ""
                      )
                    ) : (
                      <QuestionMarkIcon sx={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.fontStyleClass}
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#212B36",
                      textAlign: "start",
                      paddingLeft: "16px",
                      cursor: !isPaidUser ? "pointer" : "default",
                    }}
                    align="left"
                    onClick={() => {
                      if (!isPaidUser) {
                        history.push("/payment");
                      }
                    }}
                  >
                    {isPaidUser ? (
                      !participant?.participation ? (
                        ""
                      ) : participant?.participation?.noShow ? (
                        "no show"
                      ) : (
                        participant?.lateMinutes +
                        ` ${participant?.lateMinutes > 1 ? "mins" : "min"}`
                      )
                    ) : (
                      <QuestionMarkIcon sx={{ color: "red" }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}

export default MeetingDetailAnalytics;
