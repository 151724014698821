import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function EngagementScore({ data }) {
  const [loading, setLoading] = useState(true);
  const [yMax, setYmax] = useState(7);
  const [xPositions, setXPositions] = useState({ min: 0, max: 0 });
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [chartData, setChartData] = useState([]);
  const [previousData, setPreviousData] = useState({
    allMeetings: 0,
    missedMeetings: 0,
    engagementScores: [],
    avarageEngagement: 0,
  });
  const [allCounts, setAllCounts] = useState({
    allMeetings: 0,
    missedMeetings: 0,
    engagementScore: 0,
  });

  const calculateAvarageSentiment = (sentimentArray) => {
    const sum = (previous, current) => {
      if (!current) {
        return previous;
      }
      return previous + current;
    };
    if (sentimentArray.length === 0 || !sentimentArray) {
      return {
        label: 0,
        value: 0,
      };
    }

    return {
      label: Math.round(sentimentArray.reduce(sum) / sentimentArray.length),
      value:
        ((sentimentArray.reduce(sum) / sentimentArray.length) * yMax) / 100,
    };
  };

  const calculateDiffirence = (current, previous) => {
    let diffirence = Math.ceil(((current - previous) / previous) * 100);
    if (previous === 0) {
      diffirence = 100;
    }
    if (previous === 0 && current === 0) {
      diffirence = 0;
    }
    return (
      <span
        className={diffirence > 0 ? "green" : diffirence === 0 ? "gray" : "red"}
      >{`${
        diffirence > 0 ? " ⬆ " : diffirence === 0 ? " ➡ " : " ⬇ "
      }${diffirence}%`}</span>
    );
  };

  const dateFormatter = (date) => {
    //This function return ISO dates to mm/dd/yyyy format.
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const calculateTotalsAndStatics = () => {
    if (chartData === []) {
      return;
    }
    const currentData = chartData.slice(xPositions.min, xPositions.max);
    const previousData = chartData.slice(
      xPositions.min - dateRange.value,
      xPositions.max - dateRange.value
    );
    let highestValue = 0;
    let allMeetingsCount = 0;
    let missedMeetingsCount = 0;
    let engagementScoresCount = [];
    let newPreviousData = {
      allMeetings: 0,
      missedMeetings: 0,
      engagementScores: [],
      avarageEngagement: 0,
    };

    currentData.map((current) => {
      if (current.allMeetings > highestValue) {
        highestValue = current.allMeetings;
      }
      allMeetingsCount += current.allMeetings;
      missedMeetingsCount += current.missedMeetings;
      engagementScoresCount.push(...current.engagementScores);
    });
    previousData.map((previous) => {
      newPreviousData.allMeetings += previous?.allMeetings;
      newPreviousData.missedMeetings += previous?.missedMeetings;
      newPreviousData.engagementScores = [
        ...newPreviousData.engagementScores,
        ...previous?.engagementScores,
      ];
    });

    let avarageEngagement = calculateAvarageSentiment(
      engagementScoresCount
    ).label;
    newPreviousData.avarageEngagement = calculateAvarageSentiment(
      newPreviousData.engagementScores
    ).label;
    setAllCounts({
      allMeetings: allMeetingsCount,
      missedMeetings: missedMeetingsCount,
      engagementScore: avarageEngagement,
    });
    setYmax(highestValue * 1.3 > 5 ? highestValue * 1.3 : 5);
    setPreviousData(newPreviousData);
    setLoading(false);
  };

  useEffect(calculateTotalsAndStatics, [chartData, xPositions]);

  const transformData = () => {
    const dates = [];
    for (let i = 0; i < 180; i++) {
      const today = new Date();
      let newDate = dateFormatter(new Date(today.setDate(today.getDate() - i)));
      dates.unshift({
        label: newDate,
        allMeetings: 0,
        missedMeetings: 0,
        engagementScores: [],
      });
    }
    data?.map((meeting) => {
      const formattedDate = dateFormatter(new Date(meeting.createdAt));
      let dayData = dates.find((item) => item.label === formattedDate);
      if (dayData) {
        // incremenet all meetings count for current date range
        dayData.allMeetings += 1;
        if (meeting?.participation?.noShow) {
          dayData.missedMeetings += 1;
          dayData.engagementScores.push(0);
        } else if (meeting?.participation?.participation) {
          dayData.engagementScores.push(
            Number(meeting?.participation?.participation)
          );
        }
      }
    });
    let filterInteractiveDays = dates?.filter((item) => item.allMeetings);
    let lastInteractiveDayIndex = dates.findIndex(
      (item) =>
        item?.label ===
        filterInteractiveDays[filterInteractiveDays.length - 1]?.label
    );
    setChartData(
      lastInteractiveDayIndex > -1
        ? dates?.slice(0, lastInteractiveDayIndex + 1)
        : dates
    );
    setXPositions({
      min:
        lastInteractiveDayIndex > -1
          ? lastInteractiveDayIndex + 1 - dateRange.value
          : dates.length - dateRange.value,
      max:
        lastInteractiveDayIndex > -1
          ? lastInteractiveDayIndex + 1
          : dates.length,
    });
  };

  useEffect(transformData, [dateRange, data]);

  const chartJSData = {
    labels: chartData.map((item) => item.label),
    datasets: [
      {
        id: 1,
        label: "All Meetings",
        data: chartData.map((item, index) =>
          item.allMeetings > 0
            ? item.allMeetings
            : chartData[index - 1]?.allMeetings > 0
            ? item.allMeetings
            : chartData[index + 1]?.allMeetings > 0
            ? item.allMeetings
            : undefined
        ),
        tension: 0.4,
        borderColor: "#2C73FF",
        borderWith: 1,
      },
      {
        id: 2,
        label: "Missed Meetings",
        data: chartData.map((item, index) =>
          item.missedMeetings > 0
            ? item.missedMeetings
            : chartData[index - 1]?.missedMeetings > 0
            ? item.missedMeetings
            : chartData[index + 1]?.missedMeetings > 0
            ? item.missedMeetings
            : undefined
        ),
        tension: 0.4,
        borderColor: "#E4A400",
        borderWith: 1,
      },
      {
        id: 3,
        label: "Engagement Score",
        data: chartData.map((item, index) =>
          item.allMeetings > 0
            ? calculateAvarageSentiment(item.engagementScores).value
            : chartData[index - 1]?.allMeetings > 0
            ? calculateAvarageSentiment(item.engagementScores).value
            : chartData[index + 1]?.allMeetings > 0
            ? calculateAvarageSentiment(item.engagementScores).value
            : undefined
        ),
        tension: 0.4,
        borderColor: "#ef5da8",
        borderWith: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    fill: false,
    maintainAspectRatio: false,
    bezierCurve: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.dataset.id === 3) {
              //dataIndex
              label +=
                calculateAvarageSentiment(
                  chartData[context.dataIndex].engagementScores
                ).label + "%";
              return label;
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
        position: "average",
        backgroundColor: "rgba(200, 200, 20, 0.4)",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    scales: {
      y: {
        max: yMax,
        beginAtZero: true,
        stacked: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        max: xPositions.max,
        min: xPositions.min,
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
        radius: 2,
      },
    },
  };

  const handleScrollChart = (direction) => {
    if (direction === "toLeft") {
      if (xPositions.min - 1 < 0) {
        return;
      }
      setXPositions({
        min:
          xPositions.min - dateRange.value < 0
            ? 0
            : xPositions.min - dateRange.value,
        max: xPositions.min - 1,
      });
    }
    if (direction === "toRight") {
      if (xPositions.max + 1 > chartData.length) {
        return;
      }
      setXPositions({
        min: xPositions.max + 1,
        max:
          xPositions.max + dateRange.value > chartData.length
            ? chartData.length
            : xPositions.max + dateRange.value,
      });
    }
  };
  return (
    <div className="analytics-chart lead chart">
      <div className="chart-header">
        <h6>Engagement Score</h6>
        <DateRangeDropdown selected={dateRange} setDateRange={setDateRange} />
      </div>
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          <div className="chart-canvas">
            {xPositions.min - 1 > 0 && (
              <div
                className="chart-arrow-icon"
                onClick={() => handleScrollChart("toLeft")}
              >
                <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 16 }} />
              </div>
            )}
            <Line data={chartJSData} options={options} />
            {chartData?.length > xPositions.max + 1 && (
              <div
                className="chart-arrow-icon"
                style={{ right: 0 }}
                onClick={() => handleScrollChart("toRight")}
              >
                <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 16 }} />
              </div>
            )}
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div className="compare-chart-info">
              <div className="dot blue" />
              All
              <div className="avarage-container">
                (<span className="blue">{allCounts.allMeetings}</span>
                {calculateDiffirence(
                  allCounts.allMeetings,
                  previousData.allMeetings
                )}
                )
              </div>
            </div>
            <div className="compare-chart-info">
              <div className="dot orange" />
              Missed
              <div className="avarage-container">
                (<span className="orange">{allCounts.missedMeetings}</span>
                {calculateDiffirence(
                  allCounts.missedMeetings,
                  previousData.missedMeetings
                )}
                )
              </div>
            </div>
            <div className="compare-chart-info">
              <div className="dot pink" />
              Eng.
              <div className="avarage-container">
                (
                <span className="pink">
                  Avg. {Number(allCounts.engagementScore).toFixed(2)}
                </span>
                {calculateDiffirence(
                  allCounts.engagementScore,
                  previousData.avarageEngagement
                )}
                )
              </div>
            </div>
          </Box>
        </>
      )}
    </div>
  );
}

export default EngagementScore;
