import moment from "moment";

export const dateDifference = (date) => {
    if(!date) {
        return ""
    }
    let createDate = new Date(date).getTime();
    let now = new Date().getTime();
    let diffMs = now - createDate;
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMins < 2 && diffHrs === 0 && diffDays === 0) {
        return "Now";
    } else if (diffMins > 1 && diffHrs === 0 && diffDays === 0) {
        return `${diffMins} mins ago`;
    } else if (diffMins > 1 && diffHrs > 0 && diffDays === 0) {
        return `${diffHrs} h ${diffMins} m ago`;
    } else if (diffDays < 10 && diffDays > 0) {
        return `${diffDays} days ago`;
    } else if (diffDays >= 10) {
        return moment(date).format("lll");
    } else {
        return "";
    }
}
