import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress } from "@material-ui/core";
import {
  EditorState,
  convertToRaw,
  ContentState,
  AtomicBlockUtils,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useAuth from "../../hooks/useAuth";
import { Delete, Get, Patch, Post } from "../../utils/axiosUtils";
import { dateDifference } from "../../utils/dateDifference";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import { updateDeal } from "../../firebase/firestore";

const DealComments = ({ dealId }) => {
  let [addCommentMode, setAddcommentMode] = useState(false);
  const [focused, setFocused] = useState("");
  const [pendingAttachments, setPendingAttachments] = useState([]);

  const [commentEditorState, setCommentEditorState] = useState(null);
  const [commentCreateLoading, setCommentCreateLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [editComment, setEditComment] = useState({
    status: false,
    commentId: "",
  });
  const [editCommentEditorState, setEditCommentEditorState] = useState(null);
  const [updateCommentLoading, setUpdateCommentLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState({
    status: false,
    commentId: "",
  });
  const [mentionSuggestions, setMentionSuggestions] = useState([]);

  const { fireStoreuserObj } = useAuth();

  useEffect(() => {
    Get(`comments/deals/${dealId}`)
      .then(({ data }) => {
        console.log(data.data, "comments data", fireStoreuserObj);
        setComments(data.data);
        setCommentsLoading(false);
      })
      .catch((err) => {
        console.log(err, "error happen when fetching comments");
      });
    //Get attachments
    Get(`attachments/deals/${dealId}`)
      .then(({ data }) => {
        setAttachments(data.data);
      })
      .catch((err) => {
        console.log(err, "err happen when fetching attachments");
      });

    //fetch team members
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
          jwtToken
        );

        console.log("result", result);
        if (result.status === "success") {
          let suggestionData = result.data.members?.filter(
            (member) => member.id !== fireStoreuserObj.uid
          );
          let suggestionToEditor = [];
          suggestionData?.map((member) => {
            suggestionToEditor.push({
              text: member?.name,
              value: member?.name,
              url: "",
              avatar: member?.avatar,
              id: member?.id,
            });
          });
          setMentionSuggestions(suggestionToEditor);
        }
      });
  }, [dealId]);

  const updateDealCommentsAndAttachmentsCount = () => {
    return Get(`comments/deals/${dealId}`)
      .then(({ data }) => {
        let commentsCount = data.data.length;
        let attachmentsCount = 0;

        data.data?.map((comment) => {
          if (comment?.attachments && comment?.attachments?.length > 0) {
            attachmentsCount = attachmentsCount + comment.attachments.length;
          }
        });
        return updateDeal(dealId, { commentsCount, attachmentsCount });
      })
      .catch((err) => {
        console.log(err, "error happen when fetching comments");
      });
  };
  const createComment = () => {
    setCommentCreateLoading(true);
    Post("comments/create", {
      type: "deals",
      typeid: dealId,
      content: draftToHtml(
        convertToRaw(commentEditorState.getCurrentContent())
      ),
    })
      .then(({ data }) => {
        setComments([data.data, ...comments]);
        setCommentEditorState("");
        setFocused("");
        setAddcommentMode(false);
        setCommentCreateLoading(false);
        setAttachments(pendingAttachments);
        setPendingAttachments([]);
      })
      .then(() => updateDealCommentsAndAttachmentsCount())
      .catch((err) => {
        setCommentCreateLoading(false);
        console.log(err);
      });
  };

  const handleFileUpload = (files, editorState, setEditorState) => {
    const formData = new FormData();
    formData.append("attachment", files[0]);
    formData.append("type", "comments");
    formData.append("typeid", dealId);
    Post("attachments/create", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(({ data }) => {
        Get(`attachments/${data.data.id}`).then((urlRes) => {
          setPendingAttachments([
            { url: urlRes.data.data.url, ...data.data },
            ...pendingAttachments,
          ]);
          setEditorState(insertImage(urlRes.data.data.url, editorState));
        });
      })
      .catch((err) => {
        console.log("err happen when uploading attachment", err);
      });
  };

  const insertImage = (url, editorState) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const getEditorState = (content) => {
    const contentBlock = htmlToDraft(content);
    if (contentBlock) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      );
    }
    return EditorState.createEmpty();
  };

  const handleEditComment = (comment) => {
    if (!comment?.content) {
      return;
    }
    const contentBlock = htmlToDraft(comment.content);
    if (contentBlock) {
      setEditCommentEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks)
        )
      );
    }
    setEditComment({ status: true, commentId: comment.id });
  };

  const handleUpdateComment = () => {
    setUpdateCommentLoading(true);
    Patch(`comments/update/${editComment.commentId}`, {
      content: draftToHtml(
        convertToRaw(editCommentEditorState.getCurrentContent())
      ),
      title: "task_comments",
    })
      .then(({ data }) => {
        let Index = comments.findIndex(
          (item) => item.id === editComment.commentId
        );
        let newComments = [...comments];
        newComments[Index].content = data.data.content;
        setComments(newComments);
        setUpdateCommentLoading(false);
        setEditCommentEditorState("");
        setEditComment({ status: false, commentId: "" });
        setAttachments(pendingAttachments);
        setPendingAttachments([]);
      })
      .then(() => updateDealCommentsAndAttachmentsCount())
      .catch((err) => {
        setUpdateCommentLoading(false);
        console.log(err);
      });
  };

  const handleDeleteComment = (commentId) => {
    setDeleteCommentLoading({ status: true, commentId });
    Delete(`comments/${commentId}`)
      .then(() => {
        let newComments = comments.filter((item) => item.id !== commentId);
        setComments(newComments);
        setDeleteCommentLoading({ status: false, commentId: "" });
      })
      .then(() => updateDealCommentsAndAttachmentsCount())
      .catch((err) => {
        console.log(err, "error happen when deleting comment");
        setDeleteCommentLoading({ status: false, commentId: "" });
      });
  };

  return (
    <Box
      sx={{ width: "100%", height: "auto", marginTop: "1.5em 0" }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      onClick={() => {
        if (!addCommentMode) {
          setAddcommentMode(true);
        }
      }}
    >
      <b style={{ paddingBottom: "1em", color: "#404040" }}>Comments</b>
      <Box display="flex" alignItems="flex-start" width="100%">
        <Box
          sx={{
            height: 43,
            minWidth: 43,
            maxWidth: 43,
            mr: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            src={fireStoreuserObj?.photoURL}
            sx={{
              height: 35,
              width: 35,
              border: "1px solid #2C73FF",
            }}
          />
        </Box>
        <Box width="100%">
          <Editor
            wrapperClassName={`task-view-editor-wrapper comments ${
              focused === "comment" ? "focused" : ""
            }`}
            editorClassName={`task-view-editor comments ${
              focused === "comment" ? "focused" : ""
            }`}
            wrapperStyle={
              focused === "comment" ? { border: "1px solid #2c73ff" } : {}
            }
            onFocus={() => setFocused("comment")}
            placeholder={"Add a comment"}
            readOnly={!addCommentMode}
            toolbarHidden={!addCommentMode}
            handlePastedFiles={(files) =>
              handleFileUpload(files, commentEditorState, setCommentEditorState)
            }
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "list",
                "textAlign",
                "history",
              ],
            }}
            editorState={commentEditorState}
            onEditorStateChange={(commentEditorState) =>
              setCommentEditorState(commentEditorState)
            }
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: mentionSuggestions,
            }}
          />
          {focused === "comment" && (
            <Box sx={{ mt: 1 }}>
              <button className="modal-button blue" onClick={createComment}>
                {commentCreateLoading ? (
                  <CircularProgress size="15px" style={{ color: "#fff" }} />
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="modal-button white ml"
                onClick={() => {
                  setCommentEditorState("");
                  setFocused("");
                  setAddcommentMode(false);
                }}
              >
                Cancel
              </button>
            </Box>
          )}
        </Box>
      </Box>

      <Box width={4 / 6} height={"auto"} paddingTop={2} paddingRight={2}>
        {commentsLoading ? (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={"18px"} sx={{ color: "#2C73FF" }} />
          </Box>
        ) : (
          comments?.map((comment, index) => (
            <Box
              display="flex"
              alignItems="flex-start"
              width="100%"
              marginBottom={2}
              key={index}
            >
              <Box
                sx={{
                  height: 43,
                  minWidth: 43,
                  maxWidth: 43,
                  mr: 2,
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  src={comment?.user?.photo}
                  sx={{
                    height: 35,
                    width: 35,
                    border: "1px solid #2C73FF",
                  }}
                />
              </Box>
              <div>
                <Box display="flex" alignItems="flex-end">
                  <span className="comment-username">
                    {comment?.user?.name}
                  </span>
                  <span className="comment-date">
                    {dateDifference(comment?.createdAt)}
                  </span>
                </Box>
                <Editor
                  wrapperClassName={`${
                    editComment?.status && editComment?.commentId === comment.id
                      ? "task-view-editor-wrapper comments focused"
                      : ""
                  }`}
                  editorClassName={`${
                    editComment?.status && editComment?.commentId === comment.id
                      ? "task-view-editor comments comment focused"
                      : "tasks-comment-editor"
                  }`}
                  wrapperStyle={
                    editComment?.status && editComment?.commentId === comment.id
                      ? { border: "1px solid #2c73ff" }
                      : {}
                  }
                  readOnly={
                    !(
                      editComment?.status &&
                      editComment?.commentId === comment.id
                    )
                  }
                  toolbarHidden={
                    !(
                      editComment?.status &&
                      editComment?.commentId === comment.id
                    )
                  }
                  editorState={
                    editComment?.status && editComment?.commentId === comment.id
                      ? editCommentEditorState
                      : getEditorState(comment?.content)
                  }
                  onEditorStateChange={(editorState) =>
                    setEditCommentEditorState(editorState)
                  }
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: mentionSuggestions,
                  }}
                />
                {editComment?.status &&
                editComment?.commentId === comment.id ? (
                  <Box sx={{ mt: 1 }}>
                    <button
                      className="modal-button blue"
                      onClick={handleUpdateComment}
                    >
                      {updateCommentLoading ? (
                        <CircularProgress
                          size="15px"
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className="modal-button white ml"
                      onClick={(e) => {
                        setEditComment({ status: false, commentId: "" });
                      }}
                    >
                      Cancel
                    </button>
                  </Box>
                ) : (
                  comment?.user?.email === fireStoreuserObj.email && (
                    <Box display="flex">
                      <button
                        className="task-comment-edit"
                        onClick={() => handleEditComment(comment)}
                      >
                        Edit
                      </button>
                      <button
                        className="task-comment-edit ml"
                        onClick={() => handleDeleteComment(comment.id)}
                      >
                        {deleteCommentLoading?.status &&
                        deleteCommentLoading?.commentId === comment.id ? (
                          <CircularProgress
                            size="15px"
                            style={{ color: "#404040" }}
                          />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </Box>
                  )
                )}
              </div>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default DealComments;
