import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import moment from "moment";
import { Box, CircularProgress, Grid, Popover } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import { styled } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@material-ui/styles";
import { getSummary } from "../../firebase/firestore";
import { useHistory } from "react-router-dom";
import { sentimentIcons } from "../../utils/sentimentIcons";
import newTabIcon from "../../assets/images/newtabIcon.png";
import recordingMeeting from "../../assets/images/recording_meeting.png";

const googleMeet = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76562 6.00008L7.90111 7.29799L9.4281 8.27369L9.69372 6.00827L9.4281 3.79395L7.87185 4.65107L6.76562 6.00008Z"
      fill="#00832D"
    />
    <path
      d="M0.175781 8.06283V9.99329C0.175781 10.4341 0.533575 10.792 0.974447 10.792H2.90491L3.30465 9.33335L2.90491 8.06283L1.58047 7.66309L0.175827 8.06283H0.175781Z"
      fill="#0066DA"
    />
    <path
      d="M2.90491 1.20801L0.175781 3.93714L1.58057 4.33592L2.90491 3.93714L3.29737 2.68391L2.90491 1.20801Z"
      fill="#E94235"
    />
    <path
      d="M0.175781 8.06404H2.90491V3.9375H0.175827L0.175781 8.06404Z"
      fill="#2684FC"
    />
    <path
      d="M11.1692 2.36404L9.42578 3.79431V8.27401L11.1764 9.70982C11.4385 9.91511 11.8218 9.72802 11.8218 9.39489V2.67261C11.8218 2.33579 11.4293 2.14961 11.1692 2.36409"
      fill="#00AC47"
    />
    <path
      d="M6.76618 6V8.06277H2.90625V10.7919H8.63003C9.0709 10.7919 9.42865 10.434 9.42865 9.99324V8.27361L6.76618 6Z"
      fill="#00AC47"
    />
    <path
      d="M8.63003 1.20801H2.90625V3.93714H6.76618V5.99991L9.4287 3.79373V2.00672C9.4287 1.56585 9.0709 1.20805 8.63003 1.20805"
      fill="#FFBA00"
    />
  </svg>
);

const zoom = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0L8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4V8C12 9.06087 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06087 12 8 12H4C2.93913 12 1.92172 11.5786 1.17157 10.8284C0.421427 10.0783 0 9.06087 0 8V4Z"
      fill="#3984FD"
    />
    <path
      d="M2.5 4.5C2.5 4.36739 2.55268 4.24021 2.64645 4.14645C2.74021 4.05268 2.86739 4 3 4H6C6.39782 4 6.77936 4.15804 7.06066 4.43934C7.34196 4.72064 7.5 5.10218 7.5 5.5V7.5C7.5 7.63261 7.44732 7.75979 7.35355 7.85355C7.25979 7.94732 7.13261 8 7 8H4C3.60218 8 3.22064 7.84196 2.93934 7.56066C2.65804 7.27936 2.5 6.89782 2.5 6.5V4.5Z"
      fill="white"
    />
    <path
      d="M7.75 5.87555C7.7499 5.63314 7.83785 5.39895 7.9975 5.21655L8.8425 4.25055C9.071 3.99055 9.5 4.15155 9.5 4.49755V7.50155C9.5 7.84805 9.071 8.00905 8.843 7.74855L7.9975 6.78255C7.83797 6.60027 7.75002 6.36628 7.75 6.12405V5.87505V5.87555Z"
      fill="white"
    />
  </svg>
);

const msIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51278 0.687588C9.45645 0.631256 9.38004 0.599609 9.30038 0.599609C9.22071 0.599609 9.14431 0.631256 9.08798 0.687588C9.03165 0.74392 9 0.820323 9 0.899988C9 0.979654 9.03165 1.05606 9.08798 1.11239C9.76958 1.79339 10.1254 2.83439 10.1254 3.89999C10.1254 4.96559 9.76958 6.00659 9.08798 6.68759C9.03165 6.74392 9 6.82032 9 6.89999C9 6.97965 9.03165 7.05606 9.08798 7.11239C9.14431 7.16872 9.22071 7.20037 9.30038 7.20037C9.38004 7.20037 9.45645 7.16872 9.51278 7.11239C10.3312 6.29339 10.7254 5.08439 10.7254 3.89999C10.7254 2.71559 10.3312 1.50659 9.51278 0.687588Z"
      fill="#2C73FF"
    />
    <path
      d="M8.31161 1.88778C8.28371 1.85989 8.2506 1.83777 8.21416 1.82267C8.17771 1.80757 8.13865 1.7998 8.09921 1.7998C8.05976 1.7998 8.0207 1.80757 7.98426 1.82267C7.94781 1.83777 7.9147 1.85989 7.88681 1.88778C7.85891 1.91568 7.83679 1.94879 7.82169 1.98523C7.8066 2.02168 7.79883 2.06074 7.79883 2.10018C7.79883 2.13963 7.8066 2.17869 7.82169 2.21513C7.83679 2.25158 7.85891 2.28469 7.88681 2.31258C8.26781 2.69358 8.47421 3.28458 8.47421 3.90018C8.47421 4.51578 8.26781 5.10678 7.88681 5.48778C7.83047 5.54412 7.79883 5.62052 7.79883 5.70018C7.79883 5.77985 7.83047 5.85625 7.88681 5.91258C7.94314 5.96892 8.01954 6.00056 8.09921 6.00056C8.17887 6.00056 8.25528 5.96892 8.31161 5.91258C8.83061 5.39358 9.07421 4.63458 9.07421 3.90018C9.07421 3.16578 8.83061 2.40678 8.31161 1.88778Z"
      fill="#2C73FF"
    />
    <path
      d="M2.39844 4.1998C2.39844 3.56329 2.65129 2.95284 3.10138 2.50275C3.55147 2.05266 4.16192 1.7998 4.79844 1.7998C5.43496 1.7998 6.04541 2.05266 6.49549 2.50275C6.94558 2.95284 7.19844 3.56329 7.19844 4.1998C7.19844 4.83632 6.94558 5.44677 6.49549 5.89686C6.04541 6.34695 5.43496 6.5998 4.79844 6.5998C4.16192 6.5998 3.55147 6.34695 3.10138 5.89686C2.65129 5.44677 2.39844 4.83632 2.39844 4.1998Z"
      fill="#2C73FF"
    />
    <path
      d="M0.599609 8.4002C0.599609 7.7324 1.14141 7.2002 1.80501 7.2002H7.79961C8.11787 7.2002 8.42309 7.32662 8.64814 7.55167C8.87318 7.77671 8.99961 8.08194 8.99961 8.4002C8.99961 9.41479 8.49981 10.1798 7.71861 10.6784C6.94941 11.1686 5.91261 11.4002 4.79961 11.4002C3.68661 11.4002 2.64981 11.1686 1.88061 10.6784C1.09941 10.1804 0.599609 9.41419 0.599609 8.4002Z"
      fill="#2C73FF"
    />
  </svg>
);

const useStyles = makeStyles({
  fontStyleClass: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "100%",
  },
  bulkAvatar: {
    height: "20px",
    width: "20px",
    fontSize: "14px",
  },
  participantsBlock: {
    "& .MuiAvatar-colorDefault": {
      height: "20px",
      width: "20px",
    },
  },
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  // animation: 'ripple 1.2s infinite ease-in-out',
  width: 10,
  height: 12,
  // border: `1px solid ${theme.palette.background.paper}`,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // background: "#B345D2",
    backgroundImage: `url(/images/host.png)`,
    // color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      // animation: "ripple 1.2s infinite ease-in-out",
      // border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function ParticipantsAvatars(props) {
  const { row } = props;
  const classes = useStyles();
  let organizerName = "";
  if (props.organizerName !== undefined) {
    organizerName = props.organizerName;
  }

  // console.log('organizerName',organizerName)
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { participants } = row;
  if (participants !== undefined && organizerName !== undefined) {
    participants.organizerName = organizerName;
  }

  // sort by name
  // participants.sort(function (a, b) {
  //   console.log("a", a);
  //   console.log("b", b);
  //   var nameA = a.name; // ignore upper and lowercase
  //   var nameB = b.name; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // console.log('participants', participants)

  if (participants && participants.length > 0) {
    return (
      <Grid
        container
        className={classes.participantsBlock}
        sx={{ justifyContent: "flex-end" }}
      >
        <AvatarGroup max={3}>
          {participants.map((p, index) => {
            const { name, avatar, email } = p;
            let fChar = "";
            if (avatar === undefined || avatar === "") {
              if (name !== undefined && name !== "") {
                fChar = name.charAt(0);
              }
              if (fChar === "" && email !== undefined && email !== "") {
                fChar = email.charAt(0);
              }
            }
            if (fChar !== "") {
              fChar = fChar.toUpperCase();
            }
            if (
              participants.organizerName &&
              name == participants.organizerName
            ) {
              return (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                  title="Organiser"
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                  }
                >
                  <Avatar
                    className={classes.bulkAvatar}
                    alt={name}
                    src={avatar}
                    key={index}
                    title={name}
                    style={{
                      border: "1px solid #2C73FF",
                    }}
                  />
                </StyledBadge>
                // <Badge
                //   overlap="circular"
                //   title="Organiser"
                //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
                //   badgeContent={
                //     <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                //   }
                // >
                //   <Avatar
                //     className="pop-avatar persons"
                //     alt={participants.name}
                //     src={participants.avatar}
                //     key={index}
                //   />
                // </Badge>
              );
            } else {
              return (
                <Avatar
                  key={index}
                  alt={name}
                  src={avatar}
                  className={classes.bulkAvatar}
                  title={name}
                  // style={{
                  //   border: "1px solid #7DA8FB",
                  // }}
                >
                  {fChar}
                </Avatar>
              );
            }
            // return (
            //   <>
            //     {
            //       participants.organizerName && name == participants.organizerName ? (
            //         <StyledBadge
            //           overlap="circular"
            //           anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            //           variant="dot"
            //           title="Organiser"
            //         >
            //           <Avatar
            //             className="bulk-avatar"
            //             alt={name}
            //             src={avatar}
            //             key={index}
            //           />
            //         </StyledBadge>
            //       ) : (
            //         <Avatar
            //           key={index}
            //           alt={name}
            //           src={avatar}
            //           className="bulk-avatar"
            //         >
            //           {fChar}
            //         </Avatar>
            //       )
            //     }

            //   </>
            // );
          })}
        </AvatarGroup>
        <a
          // href="/"
          aria-describedby={row.id}
          onClick={handleClick}
          className="expand-arrow-icon"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: 12,
            margin: "auto .3em",
          }}
        >
          {participants.length - 4 > 0 ? `+${participants.length - 4}` : null}
        </a>
        <Popover
          id={row.id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: { minWidth: "147px", paddingRight: "20px" },
          }}
        >
          {participants.map((p, index) => {
            // console.log('ppp',p)
            const { name, avatar, email } = p;
            let fChar = "";
            if (avatar === undefined || avatar === "") {
              if (name !== undefined && name !== "") {
                fChar = name.charAt(0);
              }
              if (fChar === "" && email !== undefined && email !== "") {
                fChar = email.charAt(0);
              }
            }
            if (fChar !== "") {
              fChar = fChar.toUpperCase();
            }
            let txtToDisplay = name;
            if (name === undefined || name === "") {
              txtToDisplay = email;
            }
            return (
              <Grid key={index} container className="past-popup-participants">
                <Grid item xs={3} lg={3}>
                  {participants.organizerName &&
                  name == participants.organizerName ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      variant="dot"
                      title="Organiser"
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                      }
                    >
                      <Avatar
                        alt={name}
                        src={avatar}
                        className="pop-avatar"
                        style={{
                          border: "1px solid #2C73FF",
                        }}
                      />
                    </StyledBadge>
                  ) : (
                    <Avatar
                      alt={name}
                      src={avatar}
                      className="pop-avatar"
                      title={name}
                      // style={{
                      //   border: "1px solid #7DA8FB",
                      // }}
                    >
                      {fChar}
                    </Avatar>
                  )}
                </Grid>
                <Grid item xs={9} lg={9}>
                  {txtToDisplay}
                </Grid>
              </Grid>
            );
          })}
        </Popover>
      </Grid>
    );
  } else {
    return null;
  }
}

function PastMeetingsTable({
  data,
  from,
  isSentimentAvailable,
  isContactDetail,
}) {
  const history = useHistory();
  const [collapseId, setCollapseId] = useState(null);
  const [summaryData, setSummaryData] = useState("");
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [actionPlans, setActionPlans] = useState("");
  const [renderData, setRenderData] = useState([]);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);

  useEffect(() => {
    if (data && data?.length > 0) {
      setRenderData(
        data?.sort((a, b) => b.startTime - a.startTime)?.slice(0, 8)
      );
    }
  }, [data]);

  const getMeetingSummary = async () => {
    if (collapseId === null || !data[collapseId]) {
      return;
    }
    setSummaryLoading(true);
    if (data[collapseId]) {
      let summaryData = await getSummary(
        from === "memberAnalytics"
          ? data[collapseId]?.meetingId
          : data[collapseId]?.id
      );
      setSummaryData(summaryData?.summary || "");
      setActionPlans(summaryData?.actionPlans || "");
      setSummaryLoading(false);
    }
  };

  useEffect(getMeetingSummary, [collapseId]);

  const redirectMeetingDetail = (e, id) => {
    e.stopPropagation();
    history.push(`/meeting/${id}`);
  };

  const handleScrollbar = () => {
    let doc = document.getElementById("pastmeetingsScroolContainer");
    if (doc) {
      doc?.classList?.add("on-scroll");
    }
    setTimeout(() => {
      if (doc && doc?.classList?.contains("on-scroll")) {
        doc.classList.remove("on-scroll");
      }
    }, 1000);
  };

  const loadAll = () => {
    setIsAllLoading(true);
    setRenderData(data);
    setIsAllLoaded(true);
    setTimeout(() => {
      setIsAllLoading(false);
      handleScrollbar();
    }, 700);
  };

  return (
    <div style={{ height: "100%" }}>
      <Box sx={{ mb: 1 }}>
        <h5 className="agenda">Past Meetings</h5>
      </Box>
      <div
        className={`past-meetings-container lead-contact-details`}
        style={isContactDetail ? { maxHeight: "unset" } : {}}
      >
        <div
          className="past-meetings-data-container"
          id="pastmeetingsScroolContainer"
          onScroll={handleScrollbar}
        >
          {renderData?.map((data, index) => (
            <div className="past-meeting" key={index}>
              <div
                className="past-meeting-row"
                key={index}
                onClick={() => {
                  collapseId === index
                    ? setCollapseId(null)
                    : setCollapseId(index);
                }}
              >
                <div className="meeting-details">
                  <img
                    src={newTabIcon}
                    alt="new-tab"
                    width={16}
                    height={16}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let meetingId = data.id ? data.id : data.meetingId;
                      redirectMeetingDetail(e, meetingId);
                    }}
                  />
                  <div className="title">
                    {data?.channelType === "GOOGLE_MEET"
                      ? googleMeet
                      : data?.channelType === "ZOOM"
                      ? zoom
                      : data?.channgelType === "MS_TEAMS"
                      ? msIcon
                      : data?.channgelType === "RECORDING"
                      ? recordingMeeting
                      : null}
                    <span>{data.title}</span>
                  </div>
                  <span className="date">
                    {moment(data?.startTime * 1000).format("lll")}
                    <div className="vr" />
                  </span>

                  {data?.participation?.participation && (
                    <div className="meeting-dropdown-item">
                      <div className="vr" />
                      <span className="engagement-score">
                        {data?.participation?.participation &&
                        !data?.participation?.noShow
                          ? Math.round(
                              Number(data.participation.participation)
                            ) + "%"
                          : "0%"}
                      </span>
                    </div>
                  )}
                  {isSentimentAvailable && data?.sentiment?.emotion && (
                    <div className="meeting-dropdown-item">
                      <div className="vr" />
                      {data?.sentiment?.emotion
                        ? data?.sentiment?.emotion === "MIXED"
                          ? sentimentIcons.NEUTRAL
                          : sentimentIcons[data.sentiment.emotion]
                        : null}
                    </div>
                  )}
                  {collapseId !== index && data?.participants && (
                    <div className="avatars">
                      <div className="vr" />
                      <ParticipantsAvatars
                        row={data}
                        organizerName={data?.organizer?.displayName}
                      />
                    </div>
                  )}
                </div>
                {collapseId === index ? (
                  <ExpandLessIcon fontSize={"18px"} sx={{ color: "#2C73FF" }} />
                ) : (
                  <ExpandMoreIcon fontSize={"18px"} sx={{ color: "#000" }} />
                )}
              </div>
              {collapseId === index && (
                <div className="meeting-menu">
                  <div className="header-items">
                    <div className="avatars">
                      <ParticipantsAvatars
                        row={data}
                        organizerName={data?.organizer?.displayName}
                      />
                    </div>

                    {data?.topics && data?.topics?.length > 0 && (
                      <div className="meeting-dropdown-item">
                        <div className="vr" />
                        {data.topics.map((topic, index) => (
                          <div className="topic" key={index}>
                            {topic}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {summaryLoading ? (
                    <Box
                      justifyContent="space-around"
                      display="flex"
                      padding={2}
                    >
                      <CircularProgress style={{ color: "blue" }} size="20px" />
                    </Box>
                  ) : (
                    <div className="meeting-summary">
                      {summaryData !== "" && <h6>Meeting Summary:</h6>}
                      <span>{summaryData}</span>
                      {actionPlans !== "" && (
                        <h6 style={{ marginTop: "1em" }}>Key Takeaways</h6>
                      )}
                      <span className="key-takeaways">{actionPlans}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {isAllLoading ? (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
          >
            <CircularProgress style={{ color: "#2c73ff" }} size="16px" />
          </Box>
        ) : (
          data?.length > 8 &&
          !isAllLoaded && (
            <div className="footer-see-all">
              <a onClick={loadAll} style={{ cursor: "pointer" }}>
                Load more
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default PastMeetingsTable;
