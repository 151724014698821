import React, { useEffect, useState, useRef } from "react";
import { Avatar, Box, CircularProgress } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Chip from "@mui/material/Chip";
import {
  EditorState,
  convertToRaw,
  AtomicBlockUtils,
  convertFromRaw,
} from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ElasticLeadAutocomplete from "./ElasticLeadAutocomplete.js";
import ElasticContactAutocomplete from "./ElasticContactAutocomplete.js";

import {
  GetTask,
  updateTask,
  getUserByUserId,
  getElasticToken,
} from "./../../firebase/firestore.js";
import EditIcon from "@mui/icons-material/Edit";
import useAuth from "../../hooks/useAuth";
import { Delete, Get, Post } from "../../utils/axiosUtils";
import { dateDifference } from "../../utils/dateDifference";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import TextEditor from "../TextEditor";
import Comments from "./Comments";
import { isImage } from "../../utils/isImage";
import ClearIcon from "@mui/icons-material/Clear";
import ElasticDealsAutocomplete from "./ElasticDealsAutocomplete";
import ElasticAccountAutocomplete from "./ElasticAccountAutocomplete";
import MemberAutocomplete from "./MemberAutocomplete";

export default function ViewTask({
  taskId,
  handleCloseViewTaskDialog,
  viewTaskDialogStatus,
}) {
  const { fireStoreuserObj } = useAuth();

  let [loader, setLoader] = useState(false);

  let [editTitle, setEditTitle] = useState(false);
  let [editedTitle, setEditedTitle] = useState("");

  let [editDescription, setEditDescription] = useState(false);
  let [addCommentMode, setAddcommentMode] = useState(false);
  let [commentsLoading, setCommentsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(true);

  const [descriptionEditorState, setDescriptionEditorState] = useState(null);
  const [changedDescriptionEditorState, setChangedDescriptionEditorState] =
    useState(null);
  const [isDescriptionUnsaved, setIsDescriptionUnsaved] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [pendingAttachments, setPendingAttachments] = useState([]);
  const [attachmentLoading, setAttachmentLoading] = useState({
    status: false,
    attachmentId: "",
  });
  const [commentEditorState, setCommentEditorState] = useState(
    EditorState.createEmpty()
  );
  const [commentCreateLoading, setCommentCreateLoading] = useState(false);
  const [comments, setComments] = useState([]);
  let [taskDetails, setTaskDetails] = useState(null);
  let [tmpDueDate, settmpDueDate] = useState(new Date());
  let [taskUser, setTaskUser] = useState(null);
  let [editTaskUser, setEditTaskUser] = useState(false);
  let [relatedToEntity, setRelatedToEntity] = useState(null);
  let [taskMembers, setTaskMembers] = useState(null);
  const taskMembersRef = useRef();

  taskMembersRef.current = taskMembers;

  const [elasticToken, setElasticToken] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  useEffect(() => {
    setLoader(true);
    setCommentsLoading(true);
    const loadTask = async () => {
      let getTaskResponse = await GetTask(taskId);
      const allAttachments = [];
      if (getTaskResponse.success) {
        setTaskDetails(getTaskResponse.task);
        setLoader(false);

        // Merging attachments
        if (
          getTaskResponse?.task?.descriptionAttachments &&
          getTaskResponse?.task?.descriptionAttachments?.length > 0
        ) {
          getTaskResponse.task.descriptionAttachments.map((attachment) => {
            allAttachments.push({
              attachmentField: "description",
              ...attachment,
            });
          });
        }

        let desc;
        if (
          getTaskResponse?.task?.description &&
          typeof getTaskResponse.task.description === "string"
        ) {
          desc = JSON.parse(getTaskResponse?.task?.description);
        } else {
          desc = getTaskResponse?.task?.description;
        }
        setDescriptionEditorState(
          EditorState.createWithContent(convertFromRaw(desc))
        );
        setChangedDescriptionEditorState(
          EditorState.createWithContent(convertFromRaw(desc))
        );
        setIsDescriptionUnsaved(false);

        let user = await getUserByUserId(getTaskResponse.task.assignedTo);
        if (user) {
          setTaskUser({
            userId: getTaskResponse.task.assignedTo,
            displayName: user.displayName,
          });
        }

        settmpDueDate(getTaskResponse.task.dueDate.toDate());
        if (getTaskResponse.task.relatedTo) {
          setRelatedToEntity(getTaskResponse.task.relatedTo.type);
          if (getTaskResponse?.task.relatedTo?.lastName) {
            setDefaultValue(getTaskResponse?.task.relatedTo?.lastName);
          } else if (getTaskResponse.task.relatedTo?.title) {
            setDefaultValue(getTaskResponse.task.relatedTo?.title);
          } else if (getTaskResponse.task.relatedTo?.accountName) {
            setDefaultValue(getTaskResponse.task.relatedTo?.accountName);
          }
        }

        if (getTaskResponse.task.taskMembers) {
          let tmpTaskMembers = await Promise.all(
            getTaskResponse.task.taskMembers.map((userId) => {
              return { userId };
            })
          );
          setTaskMembers(tmpTaskMembers);
        }
      }

      //get comments
      Get(`comments/tasks/${taskId}`)
        .then(({ data }) => {
          setComments(data.data);
          //get comment attachments

          data.data?.map((comment) => {
            if (comment?.attachments && comment?.attachments?.length > 0) {
              comment.attachments.map((attachment) => {
                allAttachments.push({
                  commentId: comment.typeid,
                  attachmentField: "comment",
                  ...attachment,
                });
              });
            }
          });
          setAttachments(allAttachments);
          setCommentsLoading(false);
        })
        .catch((err) => {
          console.log(err, "error happen when fetching comments");
        });

      //fetch team members
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (jwtToken) => {
          let result = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
            jwtToken
          );
          if (result.status === "success") {
            let suggestionData = result.data.members?.filter(
              (member) => member.id !== fireStoreuserObj.uid
            );
            let suggestionToEditor = [];
            suggestionData?.map((member) => {
              suggestionToEditor.push({
                name: member?.name,
                email: member?.email,
                avatar: member?.avatar,
                id: member?.id,
              });
            });
            setSuggestions(suggestionToEditor);
            setSuggestionsLoading(false);
          }
        });
    };
    if (taskId) {
      loadTask();
    }
  }, [taskId]);

  const updateTaskCommentsAndAttachmentsCount = () => {
    return Get(`comments/tasks/${taskId}`)
      .then(({ data }) => {
        let commentsCount = data.data.length;
        let attachmentsCount = 0;

        data.data?.map((comment) => {
          if (comment?.attachments && comment?.attachments?.length > 0) {
            attachmentsCount = attachmentsCount + comment.attachments.length;
          }
        });
        return updateTask(taskId, { commentsCount, attachmentsCount });
      })
      .catch((err) => {
        console.log(err, "error happen when fetching comments");
      });
  };

  const addTaskMember = (newMember) => {
    let tmpTaskMembers = getSelectedMemberIds();
    tmpTaskMembers.push(newMember.userId);
    saveTaskMembers(tmpTaskMembers);

    setTaskMembers((CurrentSelectedMembers) => [
      ...CurrentSelectedMembers,
      newMember,
    ]);
  };

  const removeTaskMember = (member) => {
    setTaskMembers((CurrentSelectedMembers) =>
      CurrentSelectedMembers.filter((m) => m.userId != member)
    );

    let afterRemove = getSelectedMemberIds().filter(
      (selected) => selected != member
    );

    saveTaskMembers(afterRemove);
  };

  const getSelectedMemberIds = () => {
    if (!taskMembersRef.current) {
      return [];
    }
    return taskMembersRef.current.map((m) => m.userId);
  };

  const saveTaskUser = async (selectedUser) => {
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, assignedTo: selectedUser.userId };
    });

    setTaskUser(selectedUser);
    await updateTask(taskId, { assignedTo: selectedUser.userId });
    setEditTaskUser(false);
  };

  const saveTitle = async () => {
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, title: editedTitle };
    });
    await updateTask(taskId, { title: editedTitle });
  };

  const saveTaskMembers = async (newtaskMembers) => {
    // setTaskDetails((taskDetails) => {
    //   return { ...taskDetails, taskMembers:newtaskMembers};
    // });
    await updateTask(taskId, { taskMembers: newtaskMembers });
  };

  const saveStatus = async (status) => {
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, status };
    });
    await updateTask(taskId, { status });
  };

  const savePriority = async (priority) => {
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, priority };
    });
    await updateTask(taskId, { priority });
  };
  const saveDueDate = async (dueDate) => {
    settmpDueDate(dueDate);
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, dueDate };
    });
    await updateTask(taskId, { dueDate });
  };

  const saveRelatedTo = async (relatedTo) => {
    if (relatedTo?.lastName) {
      setDefaultValue(relatedTo.lastName);
    } else if (relatedTo?.title) {
      setDefaultValue(relatedTo.title);
    } else if (relatedTo?.accountName) {
      setDefaultValue(relatedTo.accountName);
    }
    console.log({ relatedTo }, "related to in save related to");
    await updateTask(taskId, { relatedTo });
    setTaskDetails((taskDetails) => {
      return { ...taskDetails, relatedTo };
    });
  };

  const saveDescription = async () => {
    setTaskDetails((taskDetails) => {
      return {
        ...taskDetails,
        description: convertToRaw(
          changedDescriptionEditorState.getCurrentContent()
        ),
      };
    });
    await updateTask(taskId, {
      description: JSON.stringify(
        convertToRaw(changedDescriptionEditorState.getCurrentContent())
      ),
    });
    setIsDescriptionUnsaved(false);
    setDescriptionEditorState(changedDescriptionEditorState);
    let allEntities = Object.values(
      convertToRaw(changedDescriptionEditorState.getCurrentContent()).entityMap
    );
    let imageEntities = allEntities
      .filter((item) => item.type === "IMAGE")
      .map((item) => item.data.id);

    let imageAttachments = [];
    [...attachments, ...pendingAttachments].map((attachment) => {
      if (isImage(attachment.fileName)) {
        imageAttachments.push(attachment);
      }
    });
    if (imageAttachments.length !== imageEntities.length) {
      let willDeleteAttachments = imageAttachments
        .filter((item) => !imageEntities.includes(item.id))
        .map((item) => item.id);
      let newAttachments = [...attachments, ...pendingAttachments];
      newAttachments = newAttachments.filter(
        (item) => !willDeleteAttachments.includes(item.id)
      );
      let newDescriptionAttachments = newAttachments.filter(
        (item) => item.attachmentField === "description"
      );
      updateTask(taskId, { descriptionAttachments: newDescriptionAttachments })
        .then(() => {
          setAttachments(newAttachments);
        })
        .catch((err) => {
          console.log(err, "error happen when removing description attachment");
        });
    } else {
      setAttachments([...attachments, ...pendingAttachments]);
    }
    setPendingAttachments([]);
  };

  const createComment = () => {
    setCommentCreateLoading(true);
    Post("comments/create", {
      type: "tasks",
      typeid: taskId,
      content: convertToRaw(commentEditorState.getCurrentContent()),
      attachment_ids:
        (attachments?.length > 0 || pendingAttachments?.length > 0) &&
        [...attachments, ...pendingAttachments].map((item) => item.id),
    })
      .then(({ data }) => {
        setComments([data.data, ...comments]);
        setCommentEditorState(EditorState.createEmpty());
        setAddcommentMode(false);
        setCommentCreateLoading(false);
        let pendingAttachmentsWithId = pendingAttachments?.map((item) => ({
          ...item,
          commentId: data.data.id,
          attachmentField: "comment",
        }));
        setAttachments([...attachments, ...pendingAttachmentsWithId]);
        setPendingAttachments([]);
      })
      .then(() => updateTaskCommentsAndAttachmentsCount())
      .catch((err) => {
        setCommentCreateLoading(false);
        console.log(err);
      });
  };

  const handleFileUpload = (files, editorState, setEditorState) => {
    const formData = new FormData();
    formData.append("attachment", files[0]);
    formData.append("type", "comments");
    formData.append("typeid", "");
    Post("attachments/create", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(({ data }) => {
        Get(`attachments/${data.data.id}`).then((urlRes) => {
          setPendingAttachments([
            { url: urlRes.data.data.url, ...data.data },
            ...pendingAttachments,
          ]);
          if (isImage(data.data.fileName)) {
            // Check file type
            setEditorState(insertImage(urlRes.data.data.url, editorState));
          }
        });
      })
      .catch((err) => {
        console.log("err happen when uploading attachment", err);
      });
  };

  const insertImage = (url, editorState) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const attachmentOpen = (attachmentId) => {
    setAttachmentLoading({ status: true, attachmentId });
    Get(`attachments/${attachmentId}`)
      .then(({ data }) => {
        window.open(data.data.url, "_blank");
        setAttachmentLoading({ status: false, attachmentId: "" });
      })
      .catch((err) => {
        console.log(err, "error happen when opening attachment");
        setAttachmentLoading({ status: false, attachmentId: "" });
      });
  };

  const handleDeleteAttachment = (e, attachmentId, attachmentField) => {
    e.stopPropagation();
    if (!attachmentId) {
      return;
    }
    if (attachmentField === "pending") {
      let newPendingAttachments = [...pendingAttachments];
      newPendingAttachments = newPendingAttachments.filter(
        (item) => item.id !== attachmentId
      );
      setPendingAttachments(newPendingAttachments);
    } else if (attachmentField === "description") {
      let descriptionAttachments = attachments.filter(
        (item) => item.attachmentField === "description"
      );
      let newDescriptionAttachments = descriptionAttachments.filter(
        (item) => item.id !== attachmentId
      );
      //Update task here
      updateTask(taskId, { descriptionAttachments: newDescriptionAttachments })
        .then(() => {
          let newAttachments = attachments.filter(
            (item) => item.id !== attachmentId
          );
          setAttachments(newAttachments);
        })
        .catch((err) => {
          console.log(err, "error happen when removing description attachment");
        });
    } else if (attachmentField === "comment") {
      Delete(`attachments/${attachmentId}`)
        .then(() => {
          let newAttachments = attachments.filter(
            (item) => item.id !== attachmentId
          );
          setAttachments(newAttachments);
        })
        .catch((err) => {
          console.log(err, "error happen when removing comment attachment");
        });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleCloseViewTaskDialog}
      open={viewTaskDialogStatus}
    >
      {viewTaskDialogStatus && (
        <Box p={3} height={"90vh"} width={1} className="dialog-scrolbar">
          {loader && (
            <Box
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress size={"30px"} sx={{ color: "#2C73FF" }} />
            </Box>
          )}
          {taskDetails && !loader && (
            <Box display="flex" flexDirection="column">
              <Box width={1}>
                {!editTitle ? (
                  <div
                    onClick={() => {
                      setEditTitle(true);
                      setEditedTitle(taskDetails.title);
                    }}
                    className="tasks-assignedTo title"
                  >
                    {taskDetails?.title && taskDetails?.title?.length > 0 ? (
                      <h3>{taskDetails.title}</h3>
                    ) : (
                      <h3 style={{ color: "#707070" }}>Add a title</h3>
                    )}
                    <EditIcon fontSize={"18px"} className="tasks-icon" />
                  </div>
                ) : (
                  <TextField
                    autoFocus
                    size="small"
                    style={{ width: "100%" }}
                    className={"tasks-title-input"}
                    label=""
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    onBlur={() => {
                      saveTitle();
                      setEditTitle(false);
                    }}
                  />
                )}
              </Box>

              <Box display="flex" width={1} height={"auto"}>
                <Box
                  width={4 / 6}
                  height={"auto"}
                  paddingTop={2}
                  paddingRight={2}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Box
                    sx={{ height: "100%", width: "100%" }}
                    onClick={() => {
                      if (!editDescription) {
                        setEditDescription(true);
                        setAddcommentMode(false);
                      }
                    }}
                  >
                    {suggestionsLoading ? (
                      <Box width={"100%"} textAlign={"center"}>
                        <CircularProgress
                          size={"18px"}
                          style={{ color: "#2c73ff" }}
                        />
                      </Box>
                    ) : (
                      <TextEditor
                        editorState={changedDescriptionEditorState}
                        onEditorStateChange={setChangedDescriptionEditorState}
                        onKeyPress={() => setIsDescriptionUnsaved(true)}
                        handlePastedFiles={(files) =>
                          handleFileUpload(
                            files,
                            changedDescriptionEditorState,
                            setChangedDescriptionEditorState
                          )
                        }
                        mentions={suggestions}
                        readOnly={!editDescription}
                        toolbarHidden={!editDescription}
                        isBorder
                        isButtons
                        buttonTitle={"Save"}
                        cancelButtonTitle={"Cancel"}
                        handleButton={() => {
                          setEditDescription(false);
                          saveDescription();
                        }}
                        handleCancelButton={() => {
                          setChangedDescriptionEditorState(
                            descriptionEditorState
                          );
                          setIsDescriptionUnsaved(false);
                          setEditDescription(false);
                        }}
                        buttonLoading={false}
                        className={"task-editor"}
                        placeholder={"Add description"}
                      />
                    )}
                    {isDescriptionUnsaved && (
                      <span className="comment-date">Unsaved changes!</span>
                    )}
                  </Box>

                  {attachments?.length > 0 && (
                    <Box
                      sx={{ margin: "1.5em 0", width: "100%", height: "auto" }}
                    >
                      <b style={{ paddingBottom: "1em", color: "#404040" }}>
                        Attachments ({attachments.length})
                      </b>
                      <div className="attachments">
                        {attachments.map((attachment, index) => (
                          <div
                            className="attachment"
                            onClick={() =>
                              attachment?.url
                                ? window.open(attachment.url, "_blank")
                                : attachmentOpen(attachment?.id)
                            }
                            key={index}
                          >
                            <span className="title">{attachment.fileName}</span>
                            {attachmentLoading?.status &&
                            attachmentLoading?.attachmentId ===
                              attachment.id ? (
                              <CircularProgress
                                size={"18px"}
                                sx={{ color: "#303030" }}
                              />
                            ) : (
                              !isImage(attachment.fileName) && (
                                <ClearIcon
                                  onClick={(e) =>
                                    handleDeleteAttachment(
                                      e,
                                      attachment?.id,
                                      attachment?.attachmentField
                                    )
                                  }
                                  style={{ fontSize: "16px", color: "#303030" }}
                                />
                              )
                            )}
                          </div>
                        ))}
                        {pendingAttachments.map((attachment, index) => (
                          <div
                            className="attachment"
                            onClick={() =>
                              attachment?.url
                                ? window.open(attachment.url, "_blank")
                                : attachmentOpen(attachment?.id)
                            }
                            key={index}
                          >
                            <span className="title">{attachment.fileName}</span>
                            {attachmentLoading?.status &&
                            attachmentLoading?.attachmentId ===
                              attachment.id ? (
                              <CircularProgress
                                size={"18px"}
                                sx={{ color: "#303030" }}
                              />
                            ) : (
                              !isImage(attachment.fileName) && (
                                <ClearIcon
                                  onClick={(e) =>
                                    handleDeleteAttachment(
                                      e,
                                      attachment?.id,
                                      "pending"
                                    )
                                  }
                                  style={{ fontSize: "16px", color: "#303030" }}
                                />
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}

                  <Box
                    sx={{ width: "100%", height: "auto", marginTop: "1.5em" }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    onClick={() => {
                      if (!addCommentMode) {
                        setEditDescription(false);
                        setAddcommentMode(true);
                      }
                    }}
                  >
                    <b style={{ paddingBottom: "1em", color: "#404040" }}>
                      Comments
                    </b>
                    <Box display="flex" alignItems="flex-start" width="100%">
                      <Box
                        sx={{
                          height: 43,
                          minWidth: 43,
                          maxWidth: 43,
                          mr: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          src={fireStoreuserObj?.photoURL}
                          sx={{
                            height: 35,
                            width: 35,
                            border: "1px solid #2C73FF",
                          }}
                        />
                      </Box>
                      <Box width="100%">
                        {suggestionsLoading ? (
                          <Box
                            width={"100%"}
                            height={44}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <CircularProgress
                              size={"18px"}
                              style={{ color: "#2c73ff" }}
                            />
                          </Box>
                        ) : (
                          <TextEditor
                            editorState={commentEditorState}
                            onEditorStateChange={setCommentEditorState}
                            handlePastedFiles={(files) =>
                              handleFileUpload(
                                files,
                                commentEditorState,
                                setCommentEditorState
                              )
                            }
                            mentions={suggestions}
                            readOnly={!addCommentMode}
                            toolbarHidden={!addCommentMode}
                            isBorder
                            isButtons
                            handleButton={createComment}
                            buttonLoading={commentCreateLoading}
                            buttonTitle={"Save"}
                            handleCancelButton={() => {
                              setCommentEditorState(EditorState.createEmpty());
                              setPendingAttachments([]);
                              setAddcommentMode(false);
                            }}
                            cancelButtonTitle={"Cancel"}
                            className={"task-editor comment"}
                            placeholder={"Add a comment"}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box paddingTop={2} width={2 / 6}>
                  <Box display="flex" flexDirection="column">
                    <Box paddingBottom={4} display="flex" alignItems="center">
                      <b style={{ paddingRight: "5px", color: "#404040" }}>
                        Assigned To:{" "}
                      </b>{" "}
                      {taskUser && !editTaskUser && (
                        <div
                          onClick={() => {
                            setEditTaskUser(true);
                          }}
                          className="tasks-assignedTo"
                        >
                          <span>{taskUser.displayName}</span>
                          <EditIcon fontSize={"18px"} className="tasks-icon" />
                        </div>
                      )}
                      {editTaskUser && (
                        <MemberAutocomplete
                          suggestions={suggestions}
                          setTaskUser={saveTaskUser}
                          placeholder={"Assign to"}
                          defaultValue={taskUser?.displayName}
                          className={"elastic-add-task-input"}
                        />
                      )}
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel
                          id="task-status"
                          className="task-select-label"
                        >
                          Status
                        </InputLabel>
                        <Select
                          labelId="task-status"
                          id="task-status"
                          value={taskDetails.status}
                          label="Status"
                          onChange={(e) => saveStatus(e.target.value)}
                          className="task-select"
                        >
                          <MenuItem
                            value={"not_started"}
                            className="task-select-item"
                          >
                            Not Started
                          </MenuItem>
                          <MenuItem
                            value={"deferred"}
                            className="task-select-item"
                          >
                            Deferred
                          </MenuItem>
                          <MenuItem
                            value={"inprogress"}
                            className="task-select-item"
                          >
                            In Progress
                          </MenuItem>
                          <MenuItem
                            value={"completed"}
                            className="task-select-item"
                          >
                            completed
                          </MenuItem>
                          <MenuItem
                            value={"waiting_for_input"}
                            className="task-select-item"
                          >
                            Waiting for input
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box marginTop={2}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="priority-select-label"
                          className="task-select-label"
                        >
                          Priority
                        </InputLabel>
                        <Select
                          labelId="priority-select-label"
                          id="priority-select"
                          value={taskDetails.priority}
                          label="Entity"
                          onChange={(e) => {
                            savePriority(e.target.value);
                          }}
                          className="task-select"
                        >
                          <MenuItem value="low" className="task-select-item">
                            Low
                          </MenuItem>
                          <MenuItem value="medium" className="task-select-item">
                            Medium
                          </MenuItem>
                          <MenuItem value="high" className="task-select-item">
                            High
                          </MenuItem>
                          <MenuItem value="urgent" className="task-select-item">
                            Urgent
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box marginTop={2}>
                      <DesktopDatePicker
                        label="Due date"
                        inputFormat="MMM,dd yyyy"
                        value={tmpDueDate}
                        onChange={(date) => saveDueDate(date)}
                        renderInput={(params) => (
                          <TextField className="tasks-datepicker" {...params} />
                        )}
                      />
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Box marginTop={2}>
                        <p>Related to: </p>
                      </Box>
                      <Box
                        marginTop={2}
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="space-between"
                      >
                        <FormControl style={{ minWidth: "100px" }}>
                          <InputLabel
                            id="entity-select-label"
                            className="task-select-label"
                          >
                            Entity
                          </InputLabel>
                          <Select
                            labelId="entity-select-label"
                            id="entity-select"
                            value={relatedToEntity ? relatedToEntity : ""}
                            label="Entity"
                            onChange={(e) => {
                              setDefaultValue(null);
                              saveRelatedTo(null);
                              setRelatedToEntity(e.target.value);
                            }}
                            className="task-select"
                          >
                            <MenuItem value="lead" className="task-select-item">
                              Lead
                            </MenuItem>
                            <MenuItem
                              value="contact"
                              className="task-select-item"
                            >
                              Contact
                            </MenuItem>
                            <MenuItem
                              value="account"
                              className="task-select-item"
                            >
                              Account
                            </MenuItem>
                            <MenuItem value="deal" className="task-select-item">
                              Deal
                            </MenuItem>
                          </Select>
                        </FormControl>

                        {elasticToken && (
                          <Box sx={{ width: "100%", marginLeft: "5px" }}>
                            {relatedToEntity === "lead" ? (
                              <ElasticLeadAutocomplete
                                setRelatedTo={saveRelatedTo}
                                apiKey={elasticToken}
                                defaultValue={defaultValue}
                                className={"elastic-add-task-input"}
                              />
                            ) : relatedToEntity === "deal" ? (
                              <ElasticDealsAutocomplete
                                setRelatedTo={saveRelatedTo}
                                apiKey={elasticToken}
                                defaultValue={defaultValue}
                                className={"elastic-add-task-input"}
                              />
                            ) : relatedToEntity === "contact" ? (
                              <ElasticContactAutocomplete
                                setRelatedTo={saveRelatedTo}
                                apiKey={elasticToken}
                                defaultValue={defaultValue}
                                className={"elastic-add-task-input"}
                              />
                            ) : relatedToEntity === "account" ? (
                              <ElasticAccountAutocomplete
                                setRelatedTo={saveRelatedTo}
                                apiKey={elasticToken}
                                defaultValue={defaultValue}
                                className={"elastic-add-task-input"}
                              />
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box marginTop={2} sx={{ position: "relative" }}>
                      <Box my={1}>
                        {taskMembers &&
                          taskMembers.map((member, index) => {
                            let currentMember = suggestions.filter(
                              (s) => s.id === member.userId
                            );
                            if (currentMember.length && currentMember.name) {
                              member.displayName = currentMember.name;
                            } else if (member.userId === fireStoreuserObj.uid) {
                              member.displayName = fireStoreuserObj.displayName;
                            } else {
                              member.displayName = member.userId;
                            }
                            return (
                              <Chip
                                label={member.displayName}
                                onDelete={() => removeTaskMember(member.userId)}
                                key={index}
                              />
                            );
                          })}
                      </Box>
                      <MemberAutocomplete
                        setTaskUser={addTaskMember}
                        suggestions={suggestions}
                        clearInputAfterSet
                        placeholder={"Add member"}
                        className={"elastic-add-task-input"}
                      />
                      <Box mt={1} sx={{ position: "absolute" }}>
                        <div className="comment-date">
                          Created at{" "}
                          {dateDifference(
                            new Date(taskDetails?.createdAt?.seconds * 1000)
                          )}
                        </div>
                        <div className="comment-date">
                          Updated at{" "}
                          {dateDifference(
                            new Date(taskDetails?.updatedAt?.seconds * 1000)
                          )}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Comments
                comments={comments}
                setComments={setComments}
                attachments={attachments}
                setAttachments={setAttachments}
                commentsLoading={commentsLoading}
                suggestions={suggestions}
                updateTaskCommentsAndAttachmentsCount={
                  updateTaskCommentsAndAttachmentsCount
                }
              />
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
}
