import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import { saveAccount } from "../../../firebase/firestore";
import axios from "axios";
import { useHistory } from "react-router-dom";

const elasticHost = process.env.REACT_APP_ELASTIC_HOST
  ? process.env.REACT_APP_ELASTIC_HOST
  : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

function AccountEditChange({
  handleAccountSave,
  accountId,
  accountName,
  editMode,
  setEditMode,
  elasticToken,
}) {
  const history = useHistory();
  const [saveLoading, setSaveLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isCreateAccountAvailable, setCreateAccountAvailable] = useState(false);

  const search = () => {
    console.log(inputValue?.length);
    if (!inputValue && inputValue.length < 2) {
      console.log("returned");
      return;
    }
    axios
      .post(
        `${elasticHost}/accounts/_search`,
        {
          query: {
            multi_match: {
              query: inputValue,
              type: "bool_prefix",
              fields: ["name", "name._2gram", "name._3gram"],
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${elasticToken}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.hits.hits.map((h) => h._source);
        setSuggestions(results);
        let findExactName = results.find((r) => r.name === inputValue);
        if (findExactName) {
          setCreateAccountAvailable(false);
        } else {
          setCreateAccountAvailable(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeHandler = async (item, isNew = false) => {
    setSaveLoading(true);
    if (!isNew) {
      await handleAccountSave(item?.objectID, item?.name);
    } else {
      let newAccount = await saveAccount({ name: item });
      await handleAccountSave(newAccount?.accountId, item);
    }
    setSaveLoading(false);
    setEditMode({ status: false, type: "" });
  };

  useEffect(search, [inputValue]);

  return (
    <div className="profile-contact">
      <div>
        <BusinessOutlinedIcon sx={{ fontSize: 16, color: "#2C73FF" }} />
        {editMode ? (
          <div style={{ position: "relative", width: "100%" }}>
            <input
              className="edit-input"
              style={{ width: "100%" }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {inputValue?.length > 2 && (
              <div className="account-autocomplete-menu">
                <ul>
                  {suggestions.length > 0 ? (
                    <>
                      {suggestions.map((item) => (
                        <li
                          key={item.objectID}
                          className="account-autocomplete-menu-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeHandler(item).catch((err) =>
                              console.log("on change error", err)
                            );
                          }}
                        >
                          {item.name}
                        </li>
                      ))}
                    </>
                  ) : null}
                  {isCreateAccountAvailable && (
                    <li
                      className="account-autocomplete-menu-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeHandler(inputValue, true).catch((err) =>
                          console.log("on change error", err)
                        );
                      }}
                    >
                      <span>Create an account for </span>
                      <b>{inputValue}</b>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <span
            className={`${accountId ? "link-text" : ""}`}
            onClick={() => {
              if (accountId) {
                history.push(`/crm/account/${accountId}`);
              }
            }}
          >
            {accountName && accountName !== "" ? accountName : "Company name"}
          </span>
        )}
      </div>
      {!editMode ? (
        <EditIcon
          fontSize={"18px"}
          className="icon"
          onClick={() => setEditMode({ status: true, type: "accountName" })}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "auto !important",
          }}
        >
          {saveLoading ? (
            <CircularProgress size={20} sx={{ color: "#707070" }} />
          ) : (
            <ClearIcon
              fontSize={"18px"}
              sx={{ color: "#707070", cursor: "pointer", marginLeft: ".5em" }}
              onClick={() => {
                setEditMode({ status: false, type: "" });
              }}
            />
          )}
        </Box>
      )}
    </div>
  );
}

export default AccountEditChange;
