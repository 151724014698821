import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@material-ui/core/Avatar";

import {
  GetTask,
  GetTasks,
  updateTask,
  DeleteTasks,
  deleteTask,
  getUserByUserId,
} from "./../../firebase/firestore.js";

import AddTask from "../Tasks/AddTask";
import ViewTask from "../Tasks/ViewTask";
import Container from "@mui/material/Container";

function Tasks({ contactId, leadId, accountId, lastName, mt, type }) {
  const [tasks, setTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [addTaskDialogStatus, setAddTaskDialogStatus] = useState(false);
  const [viewTaskDialogStatus, setViewTaskDialogStatus] = useState({
    status: false,
    taskId: "",
  });

  const [assignedToUsers, setAssignedToUsers] = useState([]);
  const [assignedToUsersDetails, setAssignedToUsersDetails] = useState({});

  useEffect(() => {
    const loadTasks = async () => {
      let allTasks = await GetTasks();

      let filteredTasks = allTasks.filter((task) => {
        if (type && type === "lead") {
          return (
            task.relatedTo &&
            task.relatedTo.type === "lead" &&
            task.relatedTo.leadId === leadId
          );
        } else if (type === "contact") {
          return (
            task?.relatedTo?.contactId === contactId ||
            task?.relatedTo?.leadId === leadId
          );
        } else if (type === "account") {
          return (
            task.relatedTo &&
            task.relatedTo.type === "account" &&
            task.relatedTo.accountId === accountId
          );
        }
      });

      let tmpAssignedToDetails = await getUserDetails(filteredTasks);

      setAssignedToUsersDetails(tmpAssignedToDetails);
      setTasks(filteredTasks);
      setAllTasks(allTasks);
    };
    loadTasks();
    // DeleteTasks();
  }, []);

  const getUserDetails = async (contactTasks) => {
    let formattedObj = {};
    let fetchedUsers = [];
    let tmpAssignedToDetails = contactTasks.map(async (contactTask) => {
      if (fetchedUsers.includes(contactTask.assignedTo)) {
        return false;
      }
      fetchedUsers.push(contactTask.assignedTo);

      let userDetails = await getUserByUserId(contactTask.assignedTo);

      return {
        displayName: userDetails.displayName,
        photoURL: userDetails.photoURL,
        uid: contactTask.assignedTo,
      };
    });

    let res = await Promise.all(tmpAssignedToDetails);
    res.map((user) => {
      if (!user) {
        return;
      }
      if (!formattedObj[user.uid]) {
        formattedObj[user.uid] = user;
      }
    });
    return formattedObj;
  };
  const handleCloseAddTaskDialog = () => {
    setAddTaskDialogStatus(false);
  };

  const openAddTaskDialog = () => {
    setAddTaskDialogStatus(true);
  };

  const dateFormatter = (date) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "," +
      date.getFullYear()
    );
  };

  const addTaskInList = async (task) => {
    let newTask = await GetTask(task.id);
    let tmpTasks = [...allTasks];
    tmpTasks.push(newTask.task);
    tmpTasks.sort((t1, t2) => t1.order - t2.order);

    if (assignedToUsersDetails[newTask.assignedTo]) {
      let userDetails = await getUserByUserId(newTask.assignedTo);
      setAssignedToUsersDetails((tmpAssignedToUsersDetails) => {
        tmpAssignedToUsersDetails[newTask.assignedTo] = {
          displayName: userDetails.displayName,
          photoURL: userDetails.photoURL,
        };

        return tmpAssignedToUsersDetails;
      });
      setAllTasks(tmpTasks);
    } else {
      setAllTasks(tmpTasks);
    }
  };

  return (
    <Box sx={mt ? { mt } : { mt: 0 }}>
      {addTaskDialogStatus && (
        <AddTask
          addTaskDialogStatus={addTaskDialogStatus}
          handleCloseAddTaskDialog={handleCloseAddTaskDialog}
          addTaskInList={addTaskInList}
          tasks={tasks}
          relatedToEntity={
            type && type === "lead"
              ? "lead"
              : type === "contact"
              ? "contact"
              : "account"
          }
          relatedTo={
            type && type === "lead"
              ? {
                  lastName,
                  leadId,
                  type: "lead",
                }
              : type === "contact"
              ? {
                  lastName,
                  contactId,
                  type: "contact",
                }
              : {
                  lastName,
                  accountId,
                  type: "account",
                }
          }
        />
      )}
      {viewTaskDialogStatus?.status && viewTaskDialogStatus?.taskId && (
        <ViewTask
          viewTaskDialogStatus={viewTaskDialogStatus?.status}
          handleCloseViewTaskDialog={() =>
            setViewTaskDialogStatus({ status: false, taskId: "" })
          }
          taskId={viewTaskDialogStatus?.taskId}
        />
      )}
      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
        <h5 className="agenda">Tasks</h5>
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          onClick={openAddTaskDialog}
        />
      </Box>
      <div className="shadow-container">
        <div className="details-box tasks notes">
          <table className="tasks-table">
            <thead>
              <tr>
                <th style={{ width: "35%" }}>Title</th>
                <th>Date/Time</th>
                <th style={{ width: "35%" }}>Assigned to</th>
              </tr>
            </thead>
            <tbody>
              {allTasks
                .filter((task) => {
                  if (type && type === "lead") {
                    return (
                      task.relatedTo &&
                      task.relatedTo.type === "lead" &&
                      task.relatedTo.leadId === leadId
                    );
                  } else if (type === "contact") {
                    return (
                      task?.relatedTo?.contactId === contactId ||
                      task?.relatedTo?.leadId === leadId
                    );
                  } else {
                    return (
                      task.relatedTo &&
                      task.relatedTo.type === "account" &&
                      task.relatedTo.accountId === accountId
                    );
                  }
                })
                .map((task, index) => (
                  <tr key={index}>
                    <td
                      onClick={() =>
                        setViewTaskDialogStatus({
                          status: true,
                          taskId: task?.id,
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <h6 className="note-profile-name">{task.title}</h6>
                    </td>
                    <td>
                      <span className="note-date">
                        {dateFormatter(task.createdAt.toDate())}
                      </span>
                    </td>
                    <td>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={
                            assignedToUsersDetails[task.assignedTo] &&
                            assignedToUsersDetails[task.assignedTo].photoURL
                              ? assignedToUsersDetails[task.assignedTo].photoURL
                              : ""
                          }
                          sx={{
                            width: 25,
                            height: 25,
                            border: "1px solid #7DA8FB",
                          }}
                        />
                        <Box sx={{ ml: 1 }}>
                          <h6 className="note-profile-name">
                            {assignedToUsersDetails[task.assignedTo]
                              ? assignedToUsersDetails[task.assignedTo]
                                  .displayName
                              : task.assignedTo}
                          </h6>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="shadow" />
      </div>
    </Box>
  );
}

export default Tasks;
