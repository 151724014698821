import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

export default function BillingRequired() {
  return (
    <Container>
      <Box display="flex" alignItems="center" height={200}>
        <Typography variant="h6">
          Your account trial period is completed, Please contact your organiser
          to add billing details. Thanks!
        </Typography>
      </Box>
    </Container>
  );
}
