import React, { useState, useMemo } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Box, CircularProgress } from "@material-ui/core";
import { Get } from "../../utils/axiosUtils";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import Avatar from "@material-ui/core/Avatar";
import PolygonChartScoreTable from "./PolygonChartScoreTable";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function PolygonChart({
  meetingsLoading,
  meetingsData,
  dateRangeHandler,
  isSentimentAvailable,
}) {
  const { fireStoreuserObj } = useAuth();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [cultureDetails, setCultureDetails] = useState({});
  const [cultureChartData, setCultureChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [tab, setTab] = useState("overview");
  let currentStart = new Date(
    new Date(new Date().getTime() - (dateRange.value - 1) * 24 * 60 * 60 * 1000)
  ).toISOString();
  let previousEnd = new Date(
    new Date(new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000)
  ).toISOString();
  let previousStart = new Date(
    new Date().getTime() - (dateRange.value * 2 - 1) * 24 * 60 * 60 * 1000
  ).toISOString();

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "average",
        backgroundColor: "#edf3ff",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    scales: {
      r: {
        max: 5,
        min: 0,
        ticks: {
          display: false, // Hides the labels in the middle (numbers)
        },
        pointLabels: {
          font: {
            size: 12,
          },
        },
      },
    },
    interaction: {
      mode: "index",
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const fetchData = async () => {
    if (meetingsLoading) {
      return;
    }
    setLoading(true);
    let currentRangeData = await Get(
      `meeting/getAllScores/${
        fireStoreuserObj.uid
      }?fromDate=${currentStart}&toDate=${new Date().toISOString()}`
    );
    let previousRangeData = await Get(
      `meeting/getAllScores/${fireStoreuserObj.uid}?fromDate=${previousStart}&toDate=${previousEnd}`
    );

    const calculateMeetingsSentiment = (meetings) => {
      if (!meetings?.length) {
        return;
      }
      let allSentimentScores = [];
      meetings.map((meeting) => {
        if (meeting?.sentiment?.emotion) {
          if (meeting.sentiment.emotion === "NEGATIVE_2") {
            allSentimentScores.push(1);
          } else if (meeting.sentiment.emotion === "NEGATIVE_1") {
            allSentimentScores.push(2);
          } else if (
            meeting.sentiment.emotion === "NEUTRAL" ||
            meeting.sentiment.emotion === "MIXED"
          ) {
            allSentimentScores.push(3);
          } else if (meeting.sentiment.emotion === "POSITIVE_1") {
            allSentimentScores.push(4);
          } else if (meeting.sentiment.emotion === "POSITIVE_2") {
            allSentimentScores.push(5);
          }
        }
      });
      return allSentimentScores?.length
        ? (
            allSentimentScores.reduce((a, b) => a + b, 0) /
            allSentimentScores?.length
          ).toFixed(2)
        : 0;
    };

    if (currentRangeData?.status === 200 && previousRangeData?.status === 200) {
      currentRangeData = currentRangeData?.data?.data;
      previousRangeData = previousRangeData?.data?.data;

      //Culture scores
      setCultureDetails({
        cultureScore: currentRangeData.cultureScore.cultureScore,
        cultureDetails: currentRangeData?.cultureScore?.details || [],
        leaderScore: currentRangeData.leaderScore,
        teamworkScore: currentRangeData.teamworkScore,
      });

      let newCultureChartData = {
        labels: [
          "Assertive",
          "Curious",
          "Formality",
          "Friendly",
          "Optimistic",
          "Worried",
        ],
        datasets: [
          {
            label: "Previous",
            data: [
              previousRangeData?.toneScore?.graphValues?.assertive,
              previousRangeData?.toneScore?.graphValues?.curious,
              previousRangeData?.toneScore?.graphValues?.formality,
              previousRangeData?.toneScore?.graphValues?.friendly,
              previousRangeData?.toneScore?.graphValues?.optimistic,
              previousRangeData?.toneScore?.graphValues?.worried,
            ],
            backgroundColor: "rgba(239, 93, 168, 0.2)",
            borderColor: "rgb(239,93,168)",
            borderWidth: 1,
            pointRadius: 0.3,
            tension: 0.3,
          },
          {
            label: "Current",
            data: [
              currentRangeData?.toneScore?.graphValues?.assertive,
              currentRangeData?.toneScore?.graphValues?.curious,
              currentRangeData?.toneScore?.graphValues?.formality,
              currentRangeData?.toneScore?.graphValues?.friendly,
              currentRangeData?.toneScore?.graphValues?.optimistic,
              currentRangeData?.toneScore?.graphValues?.worried,
            ],
            backgroundColor: "rgba(44,115,255,0.2)",
            borderColor: "rgba(44,115,255,1)",
            borderWidth: 1,
            pointRadius: 0.3,
            tension: 0.3,
          },
        ],
      };

      let newChartData = {
        labels: [
          "Tone",
          "Attendance",
          "Engagement",
          "Inclusivity",
          "Sentiment",
        ],
        datasets: [
          {
            label: "Previous",
            data: [],
            backgroundColor: "rgba(239, 93, 168, 0.2)",
            borderColor: "rgb(239,93,168)",
            borderWidth: 1,
            pointRadius: 0.3,
            tension: 0.3,
          },
          {
            label: "Current",
            data: [],
            backgroundColor: "rgba(44,115,255,0.2)",
            borderColor: "rgba(44,115,255,1)",
            borderWidth: 1,
            pointRadius: 0.3,
            tension: 0.3,
          },
        ],
      };
      newChartData.datasets[1].data.push(currentRangeData.toneScore?.toneScore);
      newChartData.datasets[1].data.push(
        currentRangeData.attendanceScore?.attendanceScore
      );
      newChartData.datasets[1].data.push(
        currentRangeData.engagementScore?.engagementScore
      );
      newChartData.datasets[1].data.push(
        currentRangeData.cultureScore?.cultureScore
      );
      if (isSentimentAvailable) {
        newChartData.datasets[1].data.push(
          calculateMeetingsSentiment(
            meetingsData?.meetingAsAll?.filter(
              (item) =>
                item.startTime >= new Date(currentStart).getTime() / 1000
            )
          )
        );
      } else {
        newChartData.datasets[1].data.push(0);
      }
      newChartData.datasets[0].data.push(
        previousRangeData.toneScore?.toneScore
      );
      newChartData.datasets[0].data.push(
        previousRangeData.attendanceScore?.attendanceScore
      );
      newChartData.datasets[0].data.push(
        previousRangeData.engagementScore?.engagementScore
      );
      newChartData.datasets[0].data.push(
        previousRangeData.cultureScore?.cultureScore
      );
      if (isSentimentAvailable) {
        newChartData.datasets[0].data.push(
          calculateMeetingsSentiment(
            meetingsData?.meetingAsAll?.filter(
              (item) =>
                item.startTime >= new Date(previousStart).getTime() / 1000 &&
                item.startTime <= new Date(previousEnd).getTime() / 1000
            )
          )
        );
      } else {
        newChartData.datasets[0].data.push(0);
      }
      setChartData(newChartData);
      setCultureChartData(newCultureChartData);
    }
    setLoading(false);
  };

  useMemo(fetchData, [dateRange, meetingsData, meetingsLoading]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
      }}
    >
      <Box
        className="insight-header"
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h6 className="insight-title">Your Speech Analytics</h6>
        <DateRangeDropdown
          selected={dateRange}
          setDateRange={(item) => {
            setDateRange(item);
            dateRangeHandler(item);
          }}
        />
      </Box>
      {loading || meetingsLoading ? (
        <Box
          height="100%"
          maxHeight={"495px"}
          minHeight={"495px"}
          width="100%"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="36px" />
        </Box>
      ) : (
        <>
          <div className="polygon-tabs">
            <div
              className={`tab ${tab === "overview" ? "active" : ""}`}
              onClick={() => setTab("overview")}
            >
              Overview
            </div>
            <div
              className={`tab ${tab === "culture" ? "active" : ""}`}
              onClick={() => setTab("culture")}
            >
              Culture
            </div>
            <div className="tab passive">Attendance & Engagement</div>
          </div>
          {tab === "culture" && (
            <PolygonChartScoreTable
              cultureScore={cultureDetails?.cultureScore}
              cultureDetails={cultureDetails?.cultureDetails}
              teamworkScore={cultureDetails?.teamworkScore}
              leaderScore={cultureDetails?.leaderScore}
            />
          )}
          <Box
            height="100%"
            maxHeight={"450px"}
            minHeight={"450px"}
            width="100%"
          >
            <Radar
              data={tab === "overview" ? chartData : cultureChartData}
              height={"380px"}
              options={options}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            mt={2}
          >
            <div className="compare-chart-info">
              <div className="dot pink" />
              {`${moment(previousStart).format("MMM D")} - ${moment(
                previousEnd
              ).format("MMM D")}`}
            </div>
            <div className="compare-chart-info">
              <div className="dot blue" />
              {`${moment(currentStart).format("MMM D")} - ${moment().format(
                "MMM D"
              )}`}
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PolygonChart;
