import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, CircularProgress } from "@material-ui/core";
import AnalyticsChartDropdown from "../dropdowns/AnalyticsChartDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Post } from "../../utils/axiosUtils";
import ModalMeetingsTable from "../modalMeetingsTable";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Analytics = ({ type, data, dateRangeController, fetchLoading }) => {
  const [chartData, setChartData] = useState([]);
  const [days, setDays] = useState([]);
  const [yAxisHeight, setYAxisHeight] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [previousMeetingPercentage, setPreviousMeetingPercentage] =
    useState("");
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [currentMeetingIds, setCurrentMeetingIds] = useState([]);
  const [meetingModal, setMeetingModal] = useState(false);

  const dateFormatter = (date) => {
    //This function return ISO dates to mm/dd/yyyy format.
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const transformData = () => {
    setLoading(true);
    if (data && data.length > 0) {
      const meetingDates = [];
      const meetingsIds = [];
      const previousMeetingDates = [];
      for (let i = 0; i < data.length; i++) {
        //Date with miliseconds
        let date = new Date(Number(data[i].startTime * 1000));
        if (
          date >
          new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000
        ) {
          //If date inside of dateRange
          meetingDates.push(dateFormatter(date));
        } else if (
          date >
          new Date().getTime() - dateRange.value * 2 * 24 * 60 * 60 * 1000
        ) {
          //If date for previousMeeting
          previousMeetingDates.push(dateFormatter(date));
        }
        // Adding meeting ids for modal
        if (
          date >
          new Date().getTime() - (dateRange.value - 1) * 24 * 60 * 60 * 1000
        ) {
          meetingsIds.push(data[i].meetingId);
        }
      }
      if (meetingDates.length === 0) {
        // If there is not any meeting in rage bypassing returning data
        setChartData([]);
        setDays([]);
        setPreviousMeetingPercentage(null);
        setTotalMeetings(0);
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return;
      }

      const meetingCounts = [];
      const dates = [];
      for (let i = 0; i < dateRange.value; i++) {
        const today = new Date();
        let newDate = dateFormatter(
          new Date(today.setDate(today.getDate() - i))
        );
        dates.unshift(newDate);
        //Find how much meetings happen on newDay day variable
        const calculateMeetings = meetingDates.filter(
          (item) => item === newDate
        );
        meetingCounts.unshift(calculateMeetings.length);
      }
      setDays(dates);
      setChartData(meetingCounts);

      //Find highest value then set chart height with multiple it 1.5
      const highestValue = [...meetingCounts].sort((a, b) => b - a)[0];
      if (highestValue > 0) {
        setYAxisHeight(highestValue * 1.5);
      }

      //Sum function
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      const totalMeetingsCount = meetingCounts.reduce(reducer);
      setTotalMeetings(totalMeetingsCount);
      let calculatedPercentage = Math.ceil(
        ((totalMeetingsCount - previousMeetingDates.length) /
          previousMeetingDates.length) *
          100
      );
      if (previousMeetingDates.length === 0) {
        calculatedPercentage = 100;
      }
      setPreviousMeetingPercentage(`${calculatedPercentage}%`);
      setCurrentMeetingIds(meetingsIds);
    } else {
      setChartData([0]);
      setPreviousMeetingPercentage(null);
      setTotalMeetings(0);
    }
    setLoading(false);
    setIsParentDataShouldChange(false);
  };

  useEffect(transformData, [isParentDataShouldChange, data]);

  const chartJSData = {
    labels: days,
    datasets: [
      {
        id: 1,
        data: chartData,
        tension: 0.4,
      },
    ],
  };

  const getGradient = (ctx) => {
    let gradient = ctx.createLinearGradient(0, 0, 0, 60);
    gradient.addColorStop(0, "rgba(44, 115, 255, 0.4)");
    gradient.addColorStop(0.5, "rgba(44, 115, 255, 0.3)");
    gradient.addColorStop(1, "rgba(44, 115, 255, 0)");
    return gradient;
  };

  const options = {
    responsive: true,
    fill: true,
    borderColor: "#1DA1F2",
    backgroundColor: function (context) {
      const chart = context.chart;
      const { ctx, chartArea } = chart;

      if (!chartArea) {
        // This case happens on initial chart load
        return null;
      }
      return getGradient(ctx, chartArea);
    },
    borderWidth: 0.6,
    maintainAspectRatio: false,
    bezierCurve: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        //intersect: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
        position: "average",
        backgroundColor: "rgba(200, 200, 20, 0.4)",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    scales: {
      y: {
        max: yAxisHeight,
        stacked: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
        radius: 2,
      },
    },
  };

  const handleModalOpen = () => {
    setMeetingModal(true);
  };

  return (
    <div className="analytics-chart">
      {meetingModal && (
        <ModalMeetingsTable
          open={meetingModal}
          onClose={() => setMeetingModal(false)}
          meetingIds={currentMeetingIds}
        />
      )}
      <h5>
        {type === "meetingAsAll"
          ? "All Meetings"
          : type === "meetingAsHost"
          ? "Meetings Hosted"
          : type === "meetingAsParticipant"
          ? "Meetings joined"
          : type === "absentMeetings"
          ? "Meetings I missed"
          : type === "meetingAttendedLate"
          ? "Meetings I attended late"
          : null}
      </h5>
      {loading || fetchLoading ? (
        <Box justifyContent="center" alignItems="center" display="flex">
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          <div
            className="chart-counter"
            onClick={handleModalOpen}
            style={{
              cursor: "pointer",
            }}
          >
            {totalMeetings}
            <div className="increase">{previousMeetingPercentage}</div>
          </div>
          <div className="chart-canvas">
            <Line className="canvas" data={chartJSData} options={options} />
          </div>
          <AnalyticsChartDropdown
            selected={dateRange}
            setDateRange={(item) => {
              setDateRange(item);
              if (dateRangeController(item)) {
                setLoading(true);
              } else {
                setIsParentDataShouldChange(true);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default Analytics;
