import React, { useState, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
import Badge from "@mui/material/Badge";
import "./ElasticAutocomplete.css";

const User = ({ user, setUserSelected, setQuery, setRelatedTo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={"30px"}
      justifyContent="space-between"
      width={1}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(user.displayName);
        // setIsOpen(false);
        setUserSelected(true);
        setRelatedTo({
          displayName: user.displayName,
          id: user.objectID,
          type: "user",
          email: user.email,
        });
      }}
    >
      <Box display={"flex"}>
        <Avatar
          src={user?.avatar}
          style={{
            height: 20,
            width: 20,
            border: "1px solid #2c73ff",
            marginRight: "5px",
          }}
        />
        <p>{user.displayName}</p>
      </Box>
      <Badge
        overlap="circular"
        title="Organiser"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent="Member"
        style={{ marginRight: "20px" }}
      />
    </Box>
  );
};

const Contact = ({ contact, setContactSelected, setQuery, setRelatedTo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      height={"30px"}
      width={1}
      onClick={(event) => {
        let fullName = contact.firstName
          ? contact.firstName + " " + contact.lastName
          : contact.lastName;
        event.stopPropagation();
        setQuery("");
        // setIsOpen(false);
        setContactSelected(true);
        setRelatedTo({
          lastName: fullName,
          id: contact.objectID,
          type: "contact",
          email: contact.email,
        });
      }}
    >
      <Box display={"flex"}>
        <Avatar
          src={contact?.avatar}
          style={{
            height: 20,
            width: 20,
            border: "1px solid #2c73ff",
            marginRight: "5px",
          }}
        />
        <p>
          {contact.firstName
            ? contact.firstName + " " + contact.lastName
            : contact.lastName}
        </p>
      </Box>
      <Badge
        overlap="circular"
        title="Organiser"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent="Contact"
        style={{ marginRight: "20px" }}
      />
    </Box>
  );
};

const Lead = ({ lead, setLeadSelected, setQuery, setRelatedTo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      height={"30px"}
      width={1}
      onClick={(event) => {
        let fullName = lead.firstName
          ? lead.firstName + " " + lead.lastName
          : lead.lastName;
        event.stopPropagation();
        setQuery("");
        // setIsOpen(false);
        setLeadSelected(true);
        setRelatedTo({
          lastName: fullName,
          id: lead.objectID,
          type: "lead",
          email: lead.email,
        });
      }}
    >
      <Box display={"flex"}>
        <Avatar
          src={lead?.avatar}
          style={{
            height: 20,
            width: 20,
            border: "1px solid #2c73ff",
            marginRight: "5px",
          }}
        />
        <p>
          {lead.firstName
            ? lead.firstName + " " + lead.lastName
            : lead.lastName}
        </p>
      </Box>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent="Lead"
        style={{ marginRight: "20px" }}
      />
    </Box>
  );
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const AnonymousUser = ({ query, setQuery, setUserSelected, setRelatedTo }) => {
  if (!validateEmail(query)) {
    return <p>No match found. Type full email address to Invite by email.</p>;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      width={1}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        setQuery("");
        setRelatedTo({
          //   anonymousUser: true,
          type: "anonymousUser",
          lastName: query,
          id: null,
          email: query,
        });
        setUserSelected(true);
      }}
    >
      <p>Invite by email:</p>
      <b style={{ marginLeft: "5px" }}> {query}</b>
    </Box>
  );
};
export default function ElasticMultiAutocomplete(props) {
  const { apiKey, setRelatedTo, placeholder, defaultValue } = props;

  const elasticHost = process.env.REACT_APP_ELASTIC_HOST
    ? process.env.REACT_APP_ELASTIC_HOST
    : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

  let [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  let [suggestions, setSuggestions] = useState([]);
  let [userSelected, setUserSelected] = useState(false);

  const onChange = (event, { newValue }) => {
    if (newValue) {
      setValue(newValue);
      if (userSelected) setUserSelected(false);
    }

    if (!newValue) {
      setValue("");
    }

    if (event.key === "Enter" && validateEmail(value)) {
      setRelatedTo({
        type: "anonymousUser",
        lastName: value,
        id: null,
        email: value,
      });
      setValue("");
      event.preventDefault();
    }
  };

  const renderSuggestion = (suggestion) => {
    console.log(suggestion);

    if (suggestion.noResults) {
      return (
        <AnonymousUser
          query={value}
          setQuery={setValue}
          setUserSelected={setUserSelected}
          setRelatedTo={setRelatedTo}
        />
      );
    }

    if (suggestion._index === "users") {
      return (
        <User
          user={suggestion}
          setQuery={setValue}
          setUserSelected={setUserSelected}
          setRelatedTo={setRelatedTo}
        />
      );
    }

    if (suggestion._index === "contacts") {
      return (
        <Contact
          contact={suggestion}
          setQuery={setValue}
          setContactSelected={setUserSelected}
          setRelatedTo={setRelatedTo}
        />
      );
    }

    if (suggestion._index === "leads") {
      return (
        <Lead
          lead={suggestion}
          setQuery={setValue}
          setLeadSelected={setUserSelected}
          setRelatedTo={setRelatedTo}
        />
      );
    }
  };

  const shouldRenderSuggestions = (value, reason) => {
    return !userSelected && value.length > 1;
  };

  let onSuggestionsFetchRequested = ({ value }) => {
    axios
      .post(
        `${elasticHost}/users,leads,contacts/_search`,

        {
          query: {
            multi_match: {
              query: value,
              type: "bool_prefix",
              fields: [
                "displayName",
                "displayName._2gram",
                "displayName._3gram",
                "email",
                "fullName",
                "fullName._2gram",
                "fullName._3gram",
              ],
            },
          },
          // sort: ["_score", { createdDate: "desc" }]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${apiKey}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.hits.hits
          .map((h) => {
            let item = h._source;
            item._index = h._index;
            return item;
          })
          .filter((item) => item.email);

        console.log("results", results);

        if (results.length) {
          if (validateEmail(value)) {
            results.unshift({ noResults: true });
          }
          setSuggestions(results);
        } else {
          setSuggestions([{ noResults: true }]);
        }
      });
  };
  let onSuggestionsFetchRequestedDebounce = useRef(
    debounce(500, onSuggestionsFetchRequested)
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: placeholder ? placeholder : "Select user",
    value,
    onChange,
    className: `react-autosuggest__input ${
      props?.className ? props?.className : ""
    }`,
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      {/* <div>custom stuff</div> */}
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        {/* <div>
          Press Enter to search <strong>{query}</strong>
        </div> */}
      </div>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounce.current}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.fullName}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
    />
  );
}
