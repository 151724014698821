import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Autosuggest from "react-autosuggest";
import Avatar from "@mui/material/Avatar";

const Member = ({
  member,
  setTaskUser,
  setQuery,
  setMemberSelected,
  clearInputAfterSet,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={"30px"}
      width={1}
      onClick={(event) => {
        event.stopPropagation();
        if (clearInputAfterSet) {
          setQuery("");
        } else {
          setQuery(member.name);
        }
        setMemberSelected(true);
        setTaskUser({
          displayName: member.name,
          userId: member.id,
        });
      }}
    >
      <Avatar
        src={member?.avatar}
        style={{
          height: 20,
          width: 20,
          border: "1px solid #2c73ff",
          marginRight: "5px",
        }}
      />
      <p>{member?.name}</p>
    </Box>
  );
};

function getSuggestions(value, suggestions) {
  return suggestions.filter((item) =>
    item.name.toLowerCase().includes(value.toLowerCase())
  );
}

function MemberAutocomplete({
  suggestions,
  defaultValue,
  placeholder,
  setTaskUser,
  clearInputAfterSet,
  className,
}) {
  const [teamMembers, setTeamMembers] = useState(suggestions);
  const [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    } else {
      return "";
    }
  });
  const [memberSelected, setMemberSelected] = useState(false);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (memberSelected) setMemberSelected(false);
  };
  const inputProps = {
    placeholder: placeholder ? placeholder : "Select member",
    value,
    onChange,
    className: `react-autosuggest__input ${className ? className : ""}`,
  };
  const shouldRenderSuggestions = (value, reason) => {
    return !memberSelected && value.length > 1;
  };
  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return <div {...containerProps}>{children}</div>;
  };
  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setTeamMembers(getSuggestions(value, suggestions));
  };

  const onSuggestionsClearRequested = () => {
    setTeamMembers([]);
  };
  return (
    <Autosuggest
      suggestions={teamMembers}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.fullName}
      renderSuggestion={(suggestion) => (
        <Member
          member={suggestion}
          setTaskUser={setTaskUser}
          setMemberSelected={setMemberSelected}
          setQuery={setValue}
          clearInputAfterSet={clearInputAfterSet}
        />
      )}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
    />
  );
}

export default MemberAutocomplete;
