import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { sentimentIcons } from "../../utils/sentimentIcons";
import { Box, CircularProgress } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import ModalMeetingsTable from "./index";

let columns = [
  {
    field: "contactName",
    headerName: "Contact name",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "numberOfMeetings",
    headerName: "Number of meetings",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "sentimentScore",
    headerName: "Average sentiment",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 64,
    filterable: false,
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return sentimentIcons[params.row.sentimentScore];
    },
  },
];

function SentimentChartModal({ contactNameByMeetingId, open, onClose }) {
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meetingsDetailModal, setMeetingsDetailModal] = useState({
    status: false,
    meetingIds: [],
    contactName: "",
  });

  const transformData = () => {
    setLoading(true);
    if (!contactNameByMeetingId || contactNameByMeetingId?.length === 0) {
      setLoading(false);
      return;
    }
    const rows = [];
    contactNameByMeetingId.map((contact, index) => {
      rows.push({
        id: index,
        contactName: contact?.contactName,
        numberOfMeetings: contact?.meetingIds?.length
          ? contact.meetingIds.length
          : 0,
        sentimentScore: contact?.sentiment ? contact.sentiment : "",
        meetingIds: contact.meetingIds,
      });
    });
    setTableRows(rows);
    setLoading(false);
  };

  useEffect(transformData, [contactNameByMeetingId]);
  const handleRowClick = (meetingData) => {
    setMeetingsDetailModal({
      status: true,
      meetingIds: meetingData.meetingIds,
      contactName: meetingData.contactName,
    });
  };
  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={open}>
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
          minHeight={500}
          sx={{ background: "#fff", borderRadius: ".5rem" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : meetingsDetailModal?.status ? (
        <ModalMeetingsTable
          open={meetingsDetailModal?.status}
          onClose={() =>
            setMeetingsDetailModal({
              status: false,
              meetingIds: [],
              contactName: "",
            })
          }
          meetingIds={meetingsDetailModal?.meetingIds}
          isFromSentiment
          contactName={meetingsDetailModal?.contactName}
        />
      ) : (
        <div style={{ height: "80vh" }}>
          <DataGrid
            rows={tableRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowCount={contactNameByMeetingId?.length}
            checkboxSelection={false}
            disableSelectionOnClick
            enableCellSelect={false}
            getRowClassName={() => `data-table-row`}
            onRowClick={(meeting) => handleRowClick(meeting.row)}
            className={`data-table`}
          />
        </div>
      )}
    </Dialog>
  );
}

export default SentimentChartModal;
