import React from "react";
import Select from "react-select";
import {DateRangeOptions} from "./DateRangeOptions";

const AnalyticsChartDropdown = ({ selected, setDateRange, type }) => {
  const handleChange = (item) => {
    setDateRange(item);
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: type === "mini" ? "10px" : "12px",
      boxShadow: "none",
      border: "none",
      alignItems: "center",
      justifyContent: "center"
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#2C73FF" // Custom colour
    }),
    valueContainer: (base) => ({
      ...base,
      maxWidth: "fit-content"
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "#707070",
      fontSize: "12px",
      border: "none",
      boxShadow: "none"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#f3f3f3",
      color: state.isFocused && "#000"
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1 })
  };

  return (
    <Select
      value={selected}
      options={DateRangeOptions}
      onChange={(item) => handleChange(item)}
      styles={styles}
      components={{
        IndicatorSeparator: () => null
      }}
      isSearchable={false}
      menuPortalTarget={document.body}
    />
  );
};

export default AnalyticsChartDropdown;
