import React, { useEffect, useState } from "react";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Box, CircularProgress } from "@material-ui/core";
import Chart from "react-apexcharts";
import Select from "react-select";
import BubbleChartModal from "../modalMeetingsTable/BubbleChartModal";
import { sentimentIcons as tooltipIcons } from "../../utils/sentimentIcons";

const sentimentIcons = [
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99967 14.6668C4.31767 14.6668 1.33301 11.6822 1.33301 8.00016C1.33301 4.31816 4.31767 1.3335 7.99967 1.3335C11.6817 1.3335 14.6663 4.31816 14.6663 8.00016C14.6663 11.6822 11.6817 14.6668 7.99967 14.6668ZM7.99967 13.3335C9.41416 13.3335 10.7707 12.7716 11.7709 11.7714C12.7711 10.7712 13.333 9.41465 13.333 8.00016C13.333 6.58567 12.7711 5.22912 11.7709 4.22893C10.7707 3.22873 9.41416 2.66683 7.99967 2.66683C6.58519 2.66683 5.22863 3.22873 4.22844 4.22893C3.22824 5.22912 2.66634 6.58567 2.66634 8.00016C2.66634 9.41465 3.22824 10.7712 4.22844 11.7714C5.22863 12.7716 6.58519 13.3335 7.99967 13.3335ZM5.33301 8.66683H10.6663C10.6663 9.37407 10.3854 10.0524 9.88529 10.5524C9.3852 11.0525 8.70692 11.3335 7.99967 11.3335C7.29243 11.3335 6.61415 11.0525 6.11406 10.5524C5.61396 10.0524 5.33301 9.37407 5.33301 8.66683ZM5.33301 7.3335C5.06779 7.3335 4.81344 7.22814 4.6259 7.0406C4.43836 6.85307 4.33301 6.59871 4.33301 6.3335C4.33301 6.06828 4.43836 5.81393 4.6259 5.62639C4.81344 5.43885 5.06779 5.3335 5.33301 5.3335C5.59822 5.3335 5.85258 5.43885 6.04011 5.62639C6.22765 5.81393 6.33301 6.06828 6.33301 6.3335C6.33301 6.59871 6.22765 6.85307 6.04011 7.0406C5.85258 7.22814 5.59822 7.3335 5.33301 7.3335ZM10.6663 7.3335C10.4011 7.3335 10.1468 7.22814 9.95923 7.0406C9.7717 6.85307 9.66634 6.59871 9.66634 6.3335C9.66634 6.06828 9.7717 5.81393 9.95923 5.62639C10.1468 5.43885 10.4011 5.3335 10.6663 5.3335C10.9316 5.3335 11.1859 5.43885 11.3734 5.62639C11.561 5.81393 11.6663 6.06828 11.6663 6.3335C11.6663 6.59871 11.561 6.85307 11.3734 7.0406C11.1859 7.22814 10.9316 7.3335 10.6663 7.3335Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7207 6.17589C10.7207 5.84125 10.4494 5.56998 10.1148 5.56998C9.78015 5.56998 9.50887 5.84125 9.50887 6.17589V6.47884C9.50887 6.81347 9.78015 7.08475 10.1148 7.08475C10.4494 7.08475 10.7207 6.81347 10.7207 6.47884V6.17589Z"
      fill="#7DA8FB"
    />
    <path
      d="M5.87342 5.56998C6.20805 5.56998 6.47933 5.84125 6.47933 6.17589V6.47884C6.47933 6.81347 6.20805 7.08475 5.87342 7.08475C5.53879 7.08475 5.26751 6.81347 5.26751 6.47884V6.17589C5.26751 5.84125 5.53879 5.56998 5.87342 5.56998Z"
      fill="#7DA8FB"
    />
    <path
      d="M5.60245 9.57235C5.90014 9.42351 6.26178 9.54272 6.41292 9.83849L6.41336 9.83932C6.41487 9.8421 6.41854 9.84876 6.42443 9.85875C6.43624 9.8788 6.4567 9.91177 6.4862 9.95342C6.54566 10.0374 6.63887 10.1523 6.76827 10.2673C7.02196 10.4929 7.41412 10.7202 7.9941 10.7202C8.57408 10.7202 8.96624 10.4929 9.21994 10.2673C9.34934 10.1523 9.44254 10.0374 9.50201 9.95342C9.5315 9.91177 9.55196 9.8788 9.56378 9.85875C9.56791 9.85173 9.57096 9.84635 9.57292 9.84281L9.57335 9.84232C9.57405 9.84106 9.57444 9.84005 9.57484 9.83932L9.57528 9.83849C9.72642 9.54272 10.0881 9.42351 10.3858 9.57235C10.6851 9.72201 10.8064 10.086 10.6567 10.3853L10.1148 10.1143C10.6567 10.3853 10.6566 10.3855 10.6565 10.3858L10.6555 10.3876L10.6541 10.3904L10.6505 10.3974C10.6478 10.4026 10.6444 10.409 10.6404 10.4165C10.6323 10.4315 10.6214 10.4508 10.6078 10.474C10.5806 10.5202 10.5419 10.5819 10.4909 10.6539C10.3894 10.7971 10.2365 10.9851 10.025 11.1731C9.59708 11.5535 8.92889 11.932 7.9941 11.932C7.05931 11.932 6.39113 11.5535 5.96318 11.1731C5.75175 10.9851 5.5988 10.7971 5.49732 10.6539C5.44635 10.5819 5.40764 10.5202 5.3804 10.474C5.36676 10.4508 5.35593 10.4315 5.34782 10.4165C5.34377 10.409 5.34039 10.4026 5.33767 10.3974L5.33408 10.3904L5.33266 10.3876L5.33204 10.3864L5.33175 10.3858C5.33161 10.3855 5.33148 10.3853 5.87342 10.1143L5.33148 10.3853C5.18183 10.086 5.30314 9.72201 5.60245 9.57235Z"
      fill="#7DA8FB"
    />
    <path
      d="M9.57335 9.84232L6.41292 9.83849L9.57292 9.84281L9.57335 9.84232Z"
      fill="#7DA8FB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9941 1.32861C4.31303 1.32861 1.3291 4.31254 1.3291 7.99361C1.3291 11.6747 4.31303 14.6586 7.9941 14.6586C11.6752 14.6586 14.6591 11.6747 14.6591 7.99361C14.6591 4.31254 11.6752 1.32861 7.9941 1.32861ZM2.54092 7.99361C2.54092 4.98181 4.9823 2.54043 7.9941 2.54043C11.0059 2.54043 13.4473 4.98181 13.4473 7.99361C13.4473 11.0054 11.0059 13.4468 7.9941 13.4468C4.9823 13.4468 2.54092 11.0054 2.54092 7.99361Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99967 14.6668C4.31767 14.6668 1.33301 11.6822 1.33301 8.00016C1.33301 4.31816 4.31767 1.3335 7.99967 1.3335C11.6817 1.3335 14.6663 4.31816 14.6663 8.00016C14.6663 11.6822 11.6817 14.6668 7.99967 14.6668ZM7.99967 13.3335C9.41416 13.3335 10.7707 12.7716 11.7709 11.7714C12.7711 10.7712 13.333 9.41465 13.333 8.00016C13.333 6.58567 12.7711 5.22912 11.7709 4.22893C10.7707 3.22873 9.41416 2.66683 7.99967 2.66683C6.58519 2.66683 5.22863 3.22873 4.22844 4.22893C3.22824 5.22912 2.66634 6.58567 2.66634 8.00016C2.66634 9.41465 3.22824 10.7712 4.22844 11.7714C5.22863 12.7716 6.58519 13.3335 7.99967 13.3335ZM5.33301 9.3335H10.6663V10.6668H5.33301V9.3335ZM5.33301 7.3335C5.06779 7.3335 4.81344 7.22814 4.6259 7.0406C4.43836 6.85307 4.33301 6.59871 4.33301 6.3335C4.33301 6.06828 4.43836 5.81393 4.6259 5.62639C4.81344 5.43885 5.06779 5.3335 5.33301 5.3335C5.59822 5.3335 5.85258 5.43885 6.04011 5.62639C6.22765 5.81393 6.33301 6.06828 6.33301 6.3335C6.33301 6.59871 6.22765 6.85307 6.04011 7.0406C5.85258 7.22814 5.59822 7.3335 5.33301 7.3335ZM10.6663 7.3335C10.4011 7.3335 10.1468 7.22814 9.95923 7.0406C9.7717 6.85307 9.66634 6.59871 9.66634 6.3335C9.66634 6.06828 9.7717 5.81393 9.95923 5.62639C10.1468 5.43885 10.4011 5.3335 10.6663 5.3335C10.9316 5.3335 11.1859 5.43885 11.3734 5.62639C11.561 5.81393 11.6663 6.06828 11.6663 6.3335C11.6663 6.59871 11.561 6.85307 11.3734 7.0406C11.1859 7.22814 10.9316 7.3335 10.6663 7.3335Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99967 14.6668C4.31767 14.6668 1.33301 11.6822 1.33301 8.00016C1.33301 4.31816 4.31767 1.3335 7.99967 1.3335C11.6817 1.3335 14.6663 4.31816 14.6663 8.00016C14.6663 11.6822 11.6817 14.6668 7.99967 14.6668ZM7.99967 13.3335C9.41416 13.3335 10.7707 12.7716 11.7709 11.7714C12.7711 10.7712 13.333 9.41465 13.333 8.00016C13.333 6.58567 12.7711 5.22912 11.7709 4.22893C10.7707 3.22873 9.41416 2.66683 7.99967 2.66683C6.58519 2.66683 5.22863 3.22873 4.22844 4.22893C3.22824 5.22912 2.66634 6.58567 2.66634 8.00016C2.66634 9.41465 3.22824 10.7712 4.22844 11.7714C5.22863 12.7716 6.58519 13.3335 7.99967 13.3335ZM4.66634 11.3335C4.66634 10.4494 5.01753 9.60159 5.64265 8.97647C6.26777 8.35135 7.11562 8.00016 7.99967 8.00016C8.88373 8.00016 9.73158 8.35135 10.3567 8.97647C10.9818 9.60159 11.333 10.4494 11.333 11.3335H9.99967C9.99967 10.8031 9.78896 10.2944 9.41389 9.91928C9.03882 9.54421 8.53011 9.3335 7.99967 9.3335C7.46924 9.3335 6.96053 9.54421 6.58546 9.91928C6.21039 10.2944 5.99967 10.8031 5.99967 11.3335H4.66634ZM5.33301 7.3335C5.06779 7.3335 4.81344 7.22814 4.6259 7.0406C4.43836 6.85307 4.33301 6.59871 4.33301 6.3335C4.33301 6.06828 4.43836 5.81393 4.6259 5.62639C4.81344 5.43885 5.06779 5.3335 5.33301 5.3335C5.59822 5.3335 5.85258 5.43885 6.04011 5.62639C6.22765 5.81393 6.33301 6.06828 6.33301 6.3335C6.33301 6.59871 6.22765 6.85307 6.04011 7.0406C5.85258 7.22814 5.59822 7.3335 5.33301 7.3335ZM10.6663 7.3335C10.4011 7.3335 10.1468 7.22814 9.95923 7.0406C9.7717 6.85307 9.66634 6.59871 9.66634 6.3335C9.66634 6.06828 9.7717 5.81393 9.95923 5.62639C10.1468 5.43885 10.4011 5.3335 10.6663 5.3335C10.9316 5.3335 11.1859 5.43885 11.3734 5.62639C11.561 5.81393 11.6663 6.06828 11.6663 6.3335C11.6663 6.59871 11.561 6.85307 11.3734 7.0406C11.1859 7.22814 10.9316 7.3335 10.6663 7.3335Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.62079 5.74387C6.69972 5.79306 6.78889 5.82326 6.88134 5.8321H6.88523C6.96756 5.83198 7.04799 5.80722 7.11626 5.76097C7.18424 5.71492 7.2371 5.64965 7.26816 5.57341C7.2976 5.52154 7.3166 5.46436 7.32407 5.40512C7.33161 5.3454 7.32728 5.28477 7.31134 5.22674L7.31023 5.22283C7.2751 5.10154 7.19959 4.99607 7.09634 4.92406L5.6769 4.05958L5.67912 4.06069C5.6272 4.0307 5.56987 4.01135 5.51046 4.00378C5.45105 3.99621 5.39074 4.00056 5.33301 4.01658L5.32912 4.01769C5.20846 4.05301 5.10354 4.12891 5.0319 4.2327L5.03301 4.23046C5.00317 4.28265 4.98392 4.34028 4.97639 4.4C4.96886 4.45972 4.97318 4.52035 4.98912 4.57838L4.99023 4.58229C5.02536 4.70357 5.10087 4.80905 5.20412 4.88106L6.62357 5.74554L6.62079 5.74387Z"
      fill="#7DA8FB"
    />
    <path
      d="M6.05489 7.11336C6.05489 7.33547 5.96713 7.54849 5.81093 7.70555C5.65472 7.86261 5.44286 7.95084 5.22195 7.95084C5.00104 7.95084 4.78918 7.86261 4.63298 7.70555C4.47677 7.54849 4.38902 7.33547 4.38902 7.11336V7.10976C4.38902 6.64894 4.76045 6.27548 5.21876 6.27548H5.22594C5.68425 6.27548 6.05568 6.64894 6.05568 7.10976V7.11336H6.05489Z"
      fill="#7DA8FB"
    />
    <path
      d="M11.6219 7.11637V7.11276C11.6175 6.89208 11.5278 6.68131 11.3725 6.52531C11.2172 6.36931 11.0079 6.27979 10.7884 6.27548C10.5674 6.27548 10.3555 6.36374 10.1993 6.52083C10.0431 6.67793 9.95527 6.891 9.95527 7.11316C9.95527 7.33533 10.0431 7.54839 10.1993 7.70549C10.3555 7.86258 10.5674 7.95084 10.7884 7.95084H10.792C11.2504 7.95084 11.6219 7.57729 11.6219 7.11637Z"
      fill="#7DA8FB"
    />
    <path
      d="M10.5801 11.9717L10.5798 11.7556L10.5806 11.756V11.9728H10.6668C10.6783 11.967 10.6908 11.9612 10.7035 11.9552C10.7861 11.9165 10.8881 11.8687 10.9251 11.7567L10.9245 11.7572C10.964 11.7097 10.9891 11.6519 10.9968 11.5905C11.0046 11.5292 10.9948 11.4669 10.9684 11.411L10.9623 11.3886C10.7581 10.7544 10.3592 10.2014 9.82294 9.80923C9.28664 9.41703 8.64051 9.20572 7.97731 9.20565H7.93786C6.54231 9.20565 5.35953 10.1215 4.94675 11.4104L4.94731 11.4071C4.91672 11.5248 4.93182 11.6497 4.98953 11.7567L4.99064 11.7589C5.04474 11.8605 5.13599 11.9369 5.24507 11.9721C5.30414 11.9936 5.36695 12.0029 5.42971 11.9995C5.49286 11.996 5.55464 11.9796 5.61129 11.9513C5.66794 11.923 5.71828 11.8835 5.75923 11.835C5.80019 11.7866 5.83091 11.7303 5.84953 11.6695L5.85453 11.6533C6.00885 11.2045 6.29833 10.8151 6.68279 10.5391C7.06726 10.2631 7.52765 10.1142 8.00008 10.1131H7.99842L8.03453 10.1126C9.02064 10.1126 9.85675 10.7593 10.1506 11.6695L10.1512 11.6723C10.1786 11.7593 10.2328 11.8353 10.306 11.8892C10.3793 11.9431 10.4677 11.9722 10.5584 11.9722L10.5801 11.9717Z"
      fill="#7DA8FB"
    />
    <path
      d="M9.11686 5.83204H9.11575C9.03897 5.83214 8.96343 5.81249 8.89633 5.77495C8.82923 5.73742 8.77281 5.68325 8.73242 5.6176L8.73131 5.61592C8.66231 5.51397 8.63196 5.39053 8.64575 5.268V5.27024C8.67607 5.14608 8.75237 5.03823 8.85909 4.96867L8.86075 4.96756L10.2802 4.10308C10.3323 4.07306 10.3899 4.05384 10.4495 4.04655C10.5092 4.03927 10.5697 4.04405 10.6274 4.06063L10.6241 4.06007C10.7441 4.10866 10.8446 4.18238 10.9241 4.27508L10.9252 4.2762C10.9551 4.32859 10.9742 4.38647 10.9814 4.44642C10.9887 4.50636 10.9839 4.56716 10.9674 4.62523L10.968 4.62188C10.9377 4.74604 10.8614 4.85389 10.7546 4.92344L10.753 4.92456L9.33353 5.78904C9.26878 5.82904 9.19188 5.84431 9.11686 5.83204Z"
      fill="#7DA8FB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM13.333 8.00016C13.333 10.9457 10.9452 13.3335 7.99967 13.3335C5.05416 13.3335 2.66634 10.9457 2.66634 8.00016C2.66634 5.05464 5.05416 2.66683 7.99967 2.66683C10.9452 2.66683 13.333 5.05464 13.333 8.00016Z"
      fill="#7DA8FB"
    />
  </svg>,
];

const sentimentColors = {
  POSITIVE_2: "#3EC30F",
  POSITIVE_1: "#D4E536",
  NEUTRAL: "#F8B259",
  NEGATIVE_1: "#D88A4C",
  NEGATIVE_2: "#D85C41",
};

function BubbleChart({
  type,
  setType,
  data,
  fetchLoading,
  dateRangeHandler,
  isTeamAnalytic,
  isSentimentAvailable,
}) {
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);
  const [detailModal, setDetailModal] = useState({
    status: false,
    data: null,
    label: null,
  });
  const [apexData, setApexData] = useState([]);
  const backendKey = type?.value;

  const transformData = () => {
    setLoading(true);
    if (!data) {
      return;
    }

    let currentData = {};
    data?.map((meeting, i) => {
      let meetingDate = isTeamAnalytic
        ? new Date(meeting.startTime * 1000).getTime()
        : new Date(meeting.createdAt).getTime();
      if (
        isTeamAnalytic &&
        meetingDate <
          new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000
      ) {
        return;
      }
      let dataWithType = meeting[backendKey];
      if (!dataWithType) {
        setLoading(false);
        return;
      }

      if (backendKey === "topicSentiments") {
        dataWithType?.map((value) => {
          if (Object.keys(currentData).indexOf(value?.labelName) > -1) {
            currentData[value?.labelName].push({
              id: isTeamAnalytic ? meeting?.meetingId : meeting?.id,
              title: meeting?.title,
              emotion: value?.emotion,
              count: value?.count,
            });
          } else {
            currentData[value?.labelName] = [
              {
                id: isTeamAnalytic ? meeting?.meetingId : meeting?.id,
                title: meeting?.title,
                emotion: value?.emotion,
                count: value?.count,
              },
            ];
          }
        });
      } else {
        Object.keys(dataWithType)?.map((value, index) => {
          if (Object.keys(currentData).indexOf(value) > -1) {
            currentData[value].push({
              id: isTeamAnalytic ? meeting?.meetingId : meeting?.id,
              title: meeting?.title,
              emotion: Object.values(dataWithType)[index]?.emotion,
              count: Object.values(dataWithType)[index]?.count,
            });
          } else {
            currentData[value] = [
              {
                id: isTeamAnalytic ? meeting?.meetingId : meeting?.id,
                title: meeting?.title,
                emotion: Object.values(dataWithType)[index]?.emotion,
                count: Object.values(dataWithType)[index]?.count,
              },
            ];
          }
        });
      }
    });

    const avarageSentiment = (meetingDatas) => {
      if (!meetingDatas || !meetingDatas?.length || !isSentimentAvailable) {
        return sentimentColors.NEUTRAL;
      }
      let sentimentCount = 0;
      let totalCount = 0;
      meetingDatas?.map((meeting) => {
        if (meeting?.emotion === "POSITIVE_2") {
          sentimentCount += 100 * meeting?.count;
        } else if (meeting?.emotion === "POSITIVE_1") {
          sentimentCount += 75 * meeting?.count;
        } else if (
          meeting?.emotion === "NEUTRAL" ||
          meeting?.emotion === "MIXED"
        ) {
          sentimentCount += 50 * meeting?.count;
        } else if (meeting?.emotion === "NEGATIVE_1") {
          sentimentCount += 25 * meeting?.count;
        }
        totalCount += meeting?.count;
      });
      let overallSentimentNumber = sentimentCount / totalCount || 0;
      if (overallSentimentNumber === 100) {
        return sentimentColors.POSITIVE_2;
      } else if (overallSentimentNumber > 74) {
        return sentimentColors.POSITIVE_1;
      } else if (overallSentimentNumber > 49) {
        return sentimentColors.NEUTRAL;
      } else if (overallSentimentNumber > 24) {
        return sentimentColors.NEGATIVE_1;
      }
      return sentimentColors.NEGATIVE_2;
    };
    let newApexData = [];
    Object.values(currentData).map((item, index) => {
      newApexData.push({
        x: Object.keys(currentData)[index],
        y: item.map((item) => item.count).reduce((a, b) => a + b, 0),
        fillColor: avarageSentiment(item),
        meetingData: item,
      });
    });
    setApexData(newApexData);
    setLoading(false);
    setIsParentDataShouldChange(false);
  };

  useEffect(transformData, [data, isParentDataShouldChange, backendKey]);

  const chartOnClick = (dataset) => {
    if (dataset) {
      setDetailModal({
        status: true,
        data: dataset?.meetingData,
        label: dataset?.x,
      });
    }
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      boxShadow: "none",
      border: "1px solid #2C73FF",
      alignItems: "center",
      justifyContent: "center",
      width: "max-content",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#2C73FF", // Custom colour
    }),
    valueContainer: (base) => ({
      ...base,
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "#707070",
      fontSize: "12px",
      border: "none",
      boxShadow: "none",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#f3f3f3",
      color: state.isFocused && "#000",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div
      className={`analytics-chart bubble chart ${
        isTeamAnalytic ? "team-analytics" : ""
      }`}
    >
      {detailModal?.status && (
        <BubbleChartModal
          data={detailModal?.data}
          label={detailModal?.label}
          open={detailModal.status}
          onClose={() =>
            setDetailModal({ status: false, data: null, label: null })
          }
        />
      )}
      <div className="chart-header">
        {isTeamAnalytic ? (
          <Select
            value={type}
            options={[
              { value: "matchedProducts", label: "Product & Sentiments" },
              {
                value: "matchedCompetitors",
                label: "Competitors & Sentiments",
              },
              { value: "topicSentiments", label: "Topics & Sentiments" },
            ].filter((item) => item.value !== type?.value)}
            onChange={(item) => setType(item)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={selectStyles}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        ) : (
          <h6>
            {backendKey === "matchedProducts"
              ? "Products"
              : backendKey === "matchedCompetitors"
              ? "Competitors"
              : backendKey === "topicSentiments"
              ? "Topics"
              : ""}{" "}
            & Sentiment
          </h6>
        )}
        {isTeamAnalytic && (
          <DateRangeDropdown
            selected={dateRange}
            setDateRange={(item) => {
              setDateRange(item);
              if (dateRangeHandler(item)) {
                setLoading(true);
              } else {
                setIsParentDataShouldChange(true);
              }
            }}
          />
        )}
      </div>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ height: "130px", minHeight: "130px" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : !loading && !fetchLoading && !apexData?.length ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ height: "130px", minHeight: "130px" }}
        >
          No data found
        </Box>
      ) : (
        <div className="chart-canvas">
          <Chart
            type={"treemap"}
            series={[
              {
                data: apexData,
              },
            ]}
            options={{
              legend: {
                show: false,
              },
              chart: {
                height: isTeamAnalytic ? 180 : 130,
                toolbar: {
                  show: false,
                },
                events: {
                  click: function (event, chartContext, config) {
                    if (config?.dataPointIndex > -1) {
                      chartOnClick(apexData[config.dataPointIndex]);
                    }
                  },
                },
              },
              states: {
                hover: {
                  filter: {
                    type: "darken",
                    value: 0.05,
                  },
                },
              },
              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  let { x, meetingData, fillColor } =
                    w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                  let sentiment = "";

                  if (!isSentimentAvailable) {
                    sentiment = "";
                  } else if (meetingData[0]?.emotion === "NEGATIVE_2") {
                    sentiment = "Very negative";
                  } else if (meetingData[0]?.emotion === "NEGATIVE_1") {
                    sentiment = "Negative";
                  } else if (meetingData[0]?.emotion === "NEUTRAL") {
                    sentiment = "Neutral";
                  } else if (meetingData[0]?.emotion === "POSITIVE_1") {
                    sentiment = "Positive";
                  } else if (meetingData[0]?.emotion === "POSITIVE_2") {
                    sentiment = "Very positive";
                  }

                  return `<div style="border-radius: .5rem; padding: .3em .5em;">
                            ${
                              sentiment !== ""
                                ? `<div style="display: flex; align-items: center;">
                                    <div style="border-radius: 50%; width: 10px; height: 10px; background: ${fillColor}; margin-right: .3em;"></div>
                                    ${sentiment}
                            </div>`
                                : ""
                            }
                            <b>${x}</b>
                          </div>`;
                },
              },
              grid: {
                padding: {
                  left: 0,
                  right: 0,
                },
              },
              plotOptions: {
                treemap: {
                  distributed: true,
                },
              },
            }}
            height={isTeamAnalytic ? 180 : 130}
          />
        </div>
      )}
      <div className="sentiment-meter-container">
        <div className="sentiment-icons">
          {sentimentIcons.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
        <div className="sentiment-meter" />
      </div>
    </div>
  );
}

export default BubbleChart;
