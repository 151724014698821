import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import SentimentNeutralOutlinedIcon from "@material-ui/icons/SentimentNeutral";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ml10: {
    marginLeft: "10px",
  },
  ml20: {
    marginLeft: "20px",
  },
  mb10: {
    marginBottom: "10px",
  },
}));

function MeetingOutcome({ meetingOutcome, meetingId, ...props }) {
  const classes = useStyles();

  const [outcome, setOutcome] = useState("");

  useMemo(() => {
    if (meetingOutcome) {
      setOutcome(meetingOutcome);
    }
  }, [meetingOutcome]);

  const onMeetingOutcome = (opt) => {
    let newOutcome = opt === outcome ? "null" : opt;
    props.updateMeetingOutcomeRequest({
      meetingId: meetingId,
      meetingOutcome: newOutcome,
    });
    if (opt) {
      setOutcome(newOutcome);
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Meeting outcome saved successfully.",
      });
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={classes.mb10}
        style={{ fontSize: "14px", fontWeight: "600" }}
      >
        Meeting outcomes
      </Typography>
      <Box display="flex">
        <Box
          display="flex"
          title="Positive"
          style={{ cursor: "pointer" }}
          onClick={() => onMeetingOutcome("POSITIVE")}
        >
          <SentimentSatisfiedOutlinedIcon
            className={"thumb-down-icon"}
            sx={{
              color: outcome === "POSITIVE" ? "#2c73ff !important" : "#707070",
            }}
          />
        </Box>
        <Box
          display="flex"
          className={classes.ml20}
          title="Uncertain"
          style={{ cursor: "pointer" }}
          onClick={() => onMeetingOutcome("UNCERTAIN")}
        >
          <SentimentNeutralOutlinedIcon
            className={"thumb-down-icon"}
            sx={{
              color: outcome === "UNCERTAIN" ? "#2c73ff !important" : "#707070",
            }}
          />
        </Box>
        <Box
          display="flex"
          className={classes.ml20}
          title="Negative"
          style={{ cursor: "pointer" }}
          onClick={() => onMeetingOutcome("NEGATIVE")}
        >
          <SentimentDissatisfiedOutlinedIcon
            className={"thumb-down-icon"}
            sx={{
              color: outcome === "NEGATIVE" ? "#2c73ff !important" : "#707070",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

MeetingOutcome.propTypes = {
  meetingId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  meetingDetails:
    (state.meetings &&
      state.meetings.meetingDetails &&
      state.meetings.meetingDetails.data) ||
    {},
});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
  fetchMeetingRequest: (data) => dispatch(actions.fetchMeetingRequest(data)),
  updateMeetingOutcomeRequest: (data) =>
    dispatch(actions.updateMeetingOutcomeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingOutcome);
