import React, { useState, useEffect } from "react";
import { Post } from "../../utils/axiosUtils";
import Dialog from "@mui/material/Dialog";
import ModalTabular from "./ModalTabular";

function ModalMeetingsTable({
  meetingIds,
  open,
  onClose,
  isFromSentiment = false,
  contactName,
}) {
  const [loading, setLoading] = useState(true);
  const [meetingsData, setMeetingsData] = useState([]);
  const fetchMeetings = () => {
    Post("meeting/meetingDataByIds", { meetingIds })
      .then(({ data }) => {
        setMeetingsData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(fetchMeetings, [meetingIds]);
  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={open}>
      <ModalTabular
        data={meetingsData}
        fetchLoading={loading}
        isFromSentiment={isFromSentiment}
        contactName={contactName}
      />
    </Dialog>
  );
}

export default ModalMeetingsTable;
