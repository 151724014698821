import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";
import { fetchLead } from "./actions";

const initialState = {
  leads: [],
  leadsPages: 0,
  loading: false,
  totalLeads: 0,

  contacts: [],
  contactsPages: 0,
  contactsloading: false,
  totalContacts: 0,

  accounts: [],
  accountsPages: 0,
  accountsloading: false,
  totalAccounts: 0,
};

const startLeadFetching = (state, action) => {
  return update(state, {
    loading: { $set: true },
  });
};
const stopLeadFetching = (state, action) => {
  return update(state, {
    loading: { $set: false },
  });
};
const setLeads = (state, action) => {
  console.log(action.payload);

  if (action.payload.totalLeads) {
    return update(state, {
      leads: { $set: action.payload.leads },
      leadsPages: { $set: state.leadsPages + 1 },
      totalLeads: { $set: action.payload.totalLeads },
    });
  }
  return update(state, {
    leads: { $set: action.payload.leads },
    leadsPages: { $set: state.leadsPages + 1 },
  });
};

const removeLeads = (state, action) => {
  let newLeads = state.leads.filter(
    (lead) => !action.payload.leads.includes(lead.id)
  );
  return update(state, {
    leads: { $set: newLeads },
  });
};

const updateLead = (state, action) => {
  let updatedLeads = state.leads.map((lead) => {
    if (lead.id == action.payload.leadId) {
      return { ...action.payload.leadDetails, id: action.payload.leadId };
    } else {
      return lead;
    }
  });

  return update(state, {
    leads: { $set: updatedLeads },
  });
};

//contacts

const startContactFetching = (state, action) => {
  return update(state, {
    contactsloading: { $set: true },
  });
};
const stopContactFetching = (state, action) => {
  return update(state, {
    contactsloading: { $set: false },
  });
};
const setContacts = (state, action) => {
  if (action.payload.totalContacts) {
    return update(state, {
      contacts: { $set: action.payload.contacts },
      contactsPages: { $set: state.contactsPages + 1 },
      totalContacts: { $set: action.payload.totalContacts },
    });
  }
  return update(state, {
    contacts: { $set: action.payload.contacts },
    contactsPages: { $set: state.contactsPages + 1 },
  });
};

const removeContacts = (state, action) => {
  let newContacts = state.contacts.filter(
    (contact) => !action.payload.contacts.includes(contact.id)
  );
  return update(state, {
    contacts: { $set: newContacts },
  });
};

const updateContact = (state, action) => {
  let updatedContacts = state.contacts.map((contact) => {
    if (contact.id == action.payload.contactId) {
      return { ...action.payload.contactDetails, id: action.payload.contactId };
    } else {
      return contact;
    }
  });

  return update(state, {
    contacts: { $set: updatedContacts },
  });
};

//accounts

const startAccountFetching = (state, action) => {
  return update(state, {
    accountsloading: { $set: true },
  });
};
const stopAccountFetching = (state, action) => {
  return update(state, {
    accountsloading: { $set: false },
  });
};
const setAccounts = (state, action) => {
  if (action.payload.totalAccounts) {
    return update(state, {
      accounts: { $set: action.payload.accounts },
      accountsPages: { $set: state.accountsPages + 1 },
      totalAccounts: { $set: action.payload.totalAccounts },
    });
  }
  return update(state, {
    accounts: { $set: action.payload.accounts },
    accountsPages: { $set: state.accountsPages + 1 },
  });
};

const removeAccounts = (state, action) => {
  let newAccounts = state.accounts.filter(
    (account) => !action.payload.accounts.includes(account.id)
  );
  return update(state, {
    accounts: { $set: newAccounts },
  });
};

export default handleActions(
  {
    [constants.CRM_LEAD_START_FETCHING_LEAD]: startLeadFetching,
    [constants.CRM_LEAD_STOP_FETCHING_LEAD]: stopLeadFetching,
    [constants.CRM_LEAD_SET_LEADS]: setLeads,
    [constants.CRM_LEAD_REMOVE_LEADS]: removeLeads,
    [constants.CRM_LEAD_UPDATE_LEAD]: updateLead,

    [constants.CRM_CONTACT_START_FETCHING_CONTACT]: startContactFetching,
    [constants.CRM_CONTACT_STOP_FETCHING_CONTACT]: stopContactFetching,
    [constants.CRM_CONTACT_SET_CONTACTS]: setContacts,
    [constants.CRM_CONTACT_REMOVE_CONTACTS]: removeContacts,
    [constants.CRM_CONTACT_UPDATE_CONTACT]: updateContact,

    [constants.CRM_ACCOUNT_START_FETCHING_ACCOUNT]: startAccountFetching,
    [constants.CRM_ACCOUNT_STOP_FETCHING_ACCOUNT]: stopAccountFetching,
    [constants.CRM_ACCOUNT_SET_ACCOUNTS]: setAccounts,
    [constants.CRM_ACCOUNT_REMOVE_ACCOUNTS]: removeAccounts,
  },
  initialState
);
