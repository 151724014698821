import React from "react";

export const sentimentIcons = {
    POSITIVE_2: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM8 13H16C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
                fill="#2C73FF"
            />
        </svg>
    ),
    POSITIVE_1: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
                stroke="#2C73FF"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M15.5 9V9.5"
                stroke="#2C73FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 9V9.5"
                stroke="#2C73FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5 15.5C15.5 15.5 14.5 17.5 12 17.5C9.5 17.5 8.5 15.5 8.5 15.5"
                stroke="#2C73FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    NEUTRAL: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM8 14H16V16H8V14ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
                fill="#2C73FF"
            />
        </svg>
    ),
    NEGATIVE_1: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 17C7 15.6739 7.52678 14.4021 8.46447 13.4645C9.40215 12.5268 10.6739 12 12 12C13.3261 12 14.5979 12.5268 15.5355 13.4645C16.4732 14.4021 17 15.6739 17 17H15C15 16.2044 14.6839 15.4413 14.1213 14.8787C13.5587 14.3161 12.7956 14 12 14C11.2044 14 10.4413 14.3161 9.87868 14.8787C9.31607 15.4413 9 16.2044 9 17H7ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
                fill="#2C73FF"
            />
        </svg>
    ),
    NEGATIVE_2: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.93167 8.61555C10.0501 8.68935 10.1838 8.73464 10.3225 8.74791H10.3283C10.4518 8.74773 10.5725 8.71059 10.6749 8.64121C10.7768 8.57214 10.8561 8.47422 10.9027 8.35987C10.9469 8.28207 10.9754 8.1963 10.9866 8.10743C10.9979 8.01785 10.9914 7.92691 10.9675 7.83986L10.9658 7.834C10.9131 7.65207 10.7999 7.49386 10.645 7.38584L8.51584 6.08912L8.51917 6.0908C8.44129 6.0458 8.3553 6.01678 8.26618 6.00542C8.17707 5.99406 8.08659 6.00059 8 6.02462L7.99417 6.02629C7.81319 6.07927 7.65579 6.19312 7.54834 6.3488L7.55 6.34545C7.50524 6.42374 7.47638 6.51018 7.46507 6.59976C7.45377 6.68934 7.46026 6.78028 7.48417 6.86732L7.48584 6.87319C7.53853 7.05511 7.6518 7.21333 7.80667 7.32134L9.93584 8.61807L9.93167 8.61555Z"
                fill="#2C73FF"
            />
            <path
                d="M9.08282 10.6698C9.08282 11.003 8.95118 11.3225 8.71688 11.5581C8.48257 11.7937 8.16478 11.926 7.83342 11.926C7.50205 11.926 7.18426 11.7937 6.94995 11.5581C6.71565 11.3225 6.58401 11.003 6.58401 10.6698V10.6644C6.58401 9.97317 7.14116 9.41298 7.82863 9.41298H7.83939C8.52686 9.41298 9.08401 9.97317 9.08401 10.6644V10.6698H9.08282Z"
                fill="#2C73FF"
            />
            <path
                d="M17.4334 10.6743V10.6689C17.4267 10.3379 17.2922 10.0217 17.0593 9.78772C16.8263 9.55373 16.5123 9.41944 16.1831 9.41298C15.8517 9.41298 15.5338 9.54536 15.2994 9.78101C15.0651 10.0166 14.9334 10.3362 14.9334 10.6695C14.9334 11.0027 15.0651 11.3223 15.2994 11.558C15.5338 11.7936 15.8517 11.926 16.1831 11.926H16.1885C16.8761 11.926 17.4334 11.3657 17.4334 10.6743Z"
                fill="#2C73FF"
            />
            <path
                d="M15.8706 17.9572L15.8702 17.6332L15.8714 17.6338V17.9589H16.0006C16.0179 17.9503 16.0367 17.9415 16.0558 17.9325C16.1796 17.8745 16.3326 17.8029 16.3881 17.6347L16.3873 17.6356C16.4464 17.5644 16.4841 17.4776 16.4957 17.3856C16.5074 17.2935 16.4926 17.2001 16.4531 17.1162L16.4439 17.0827C16.1377 16.1313 15.5393 15.3019 14.7349 14.7136C13.9305 14.1253 12.9613 13.8083 11.9664 13.8082H11.9073C9.81395 13.8082 8.03978 15.182 7.42061 17.1154L7.42145 17.1104C7.37558 17.2869 7.39822 17.4744 7.48478 17.6347L7.48645 17.6381C7.5676 17.7904 7.70447 17.9051 7.86809 17.9578C7.95671 17.9902 8.05092 18.0042 8.14506 17.9989C8.23978 17.9937 8.33245 17.9691 8.41743 17.9267C8.5024 17.8843 8.5779 17.825 8.63934 17.7523C8.70077 17.6797 8.74686 17.5952 8.77478 17.5041L8.78228 17.4798C9.01377 16.8065 9.44798 16.2223 10.0247 15.8084C10.6014 15.3944 11.292 15.1711 12.0006 15.1695H11.9981L12.0523 15.1686C13.5314 15.1686 14.7856 16.1386 15.2264 17.5041L15.2273 17.5082C15.2684 17.6387 15.3497 17.7527 15.4595 17.8336C15.5694 17.9144 15.702 17.958 15.8381 17.9581L15.8706 17.9572Z"
                fill="#2C73FF"
            />
            <path
                d="M13.6758 8.74782H13.6741C13.5589 8.74797 13.4456 8.71849 13.345 8.66219C13.2443 8.60588 13.1597 8.52463 13.0991 8.42615L13.0975 8.42364C12.994 8.27071 12.9484 8.08555 12.9691 7.90176V7.90511C13.0146 7.71888 13.129 7.55709 13.2891 7.45277L13.2916 7.45109L15.4208 6.15437C15.499 6.10935 15.5853 6.08052 15.6748 6.06959C15.7643 6.05865 15.855 6.06583 15.9416 6.0907L15.9366 6.08987C16.1166 6.16275 16.2675 6.27332 16.3866 6.41237L16.3883 6.41405C16.4331 6.49265 16.4618 6.57947 16.4726 6.66938C16.4835 6.7593 16.4764 6.8505 16.4516 6.9376L16.4525 6.93257C16.407 7.11881 16.2925 7.28059 16.1325 7.38492L16.13 7.38659L14.0008 8.68332C13.9037 8.74332 13.7883 8.76622 13.6758 8.74782Z"
                fill="#2C73FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                fill="#2C73FF"
            />
        </svg>
    ),
};
