import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, useTheme, styled } from "@material-ui/core/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
} from "@material-ui/core";

import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&::before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    backgroundColor: "#B345D2",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

function NavItem({ item, active, isShow, pathname }) {
  const theme = useTheme();
  let isActiveRoot;
  if (item?.children?.length > 0) {
    //search for children pathname matches
    const filter = item.children.filter((child) => child.path === pathname);
    if (filter?.length > 0) {
      isActiveRoot = true;
    } else {
      isActiveRoot = false;
    }
  } else {
    isActiveRoot = active(item.path);
  }
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "white",
    fontWeight: "fontWeightMedium",
    bgcolor: "#B345D2",
    // "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "#B345D2 !important",
    // fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    {/* <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (theme) =>
                            theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle> */}
                    <ListItemText
                      style={{ paddingLeft: "40px" }}
                      disableTypography
                      primary={title}
                    />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  let { pathname } = useLocation();
  const { fireStoreuserObj } = useAuth();

  if (
    matchPath(pathname, { path: "/crm/lead/:id" }) ||
    matchPath(pathname, { path: "/crm/lead/edit/:id" }) ||
    matchPath(pathname, { path: "/crm/leads/create" })
  ) {
    pathname = "/crm/leads";
  }

  if (
    matchPath(pathname, { path: "/crm/contact/:id" }) ||
    matchPath(pathname, { path: "/crm/contact/edit/:id" }) ||
    matchPath(pathname, { path: "/crm/contact/create" })
  ) {
    pathname = "/crm/contacts";
  }

  if (
    matchPath(pathname, { path: "/crm/account/:id" }) ||
    matchPath(pathname, { path: "/crm/account/edit/:id" }) ||
    matchPath(pathname, { path: "/crm/accounts/create" })
  ) {
    pathname = "/crm/accounts";
  }

  if (matchPath(pathname, { path: "/teams/:id" })) {
    pathname = "/teams";
  }

  const match = (path) =>
    path ? !!matchPath(pathname, { path, end: false }) : false;

  return (
    <Box {...other} id="sidebar-navigation">
      {navConfig.map((list) => {
        let { subheader, items } = list;
        if (!["ADMIN", "TEAM_MANAGER"].includes(fireStoreuserObj.role)) {
          items = items.filter((item) => item.path !== "/teams");
        }
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                active={match}
                isShow={isShow}
                pathname={pathname}
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
