import { v4 as uuidv4 } from "uuid";

export const ConversationData = [
  {
    id: uuidv4(),
    firstSelectResult: 'Hosted By',
    secondSelectResult: 'Anyone',
    secondSelectList: ['Anyone', 'By me', 'Not by me', 'Specific people', 'Contains', 'Does not contain'],
    typeResult: 'Internal',
    typeList: ['Internal', 'External'],
    selectedAttendanceRate: 'Less than',
    attendanceRateList: ['Less than', 'More than', 'Equals to'],
    selectedSpecificPeoples: [],
    selectedTopics: [],
    selects: [
      {
        id:uuidv4(),
        dimension: "Hosted By",
        options: [
          "Anyone",
          "By me",
          "Not by me",
          "Specific people",
          "Contains",
          "Does not contain",
        ],
      },
      {
        id:uuidv4(),
        dimension: "Key takeaways",
        options: [
          "Contains",
          "Does not contain",
        ],
      },
      {
        id:uuidv4(),
        dimension: "Participants",
        options: [
          "Anyone",
          "By me",
          "Not by me",
          "Specific people",
          "Contains",
          "Does not contain",
        ],
      },
      {
        id:uuidv4(),
        dimension: "Number of participant(s)",
        options: ["Less than", "More than", "Equals to"],
      },
      {
        id:uuidv4(),
        dimension: "Outcome",
        options: ["Includes", "Does not include"],
      },
      {
        id:uuidv4(),
        dimension: "Overall Meeting Sentiment",
        options: ["Includes", "Does not include"],
      },
      {
        id:uuidv4(),
        dimension: "Attendance",
        options: ['Meetings I missed', 'Meetings I joined late', 'Meetings I left early', 'Meetings missed by others', 'Meeting joined late by others', 'Meetings left early by others','Attendance rate']
      },
      {
        id:uuidv4(),
        dimension: "Type (Internal vs External)",
        options: ["Includes", "Does not include"],
      },
      {
        id:uuidv4(),
        dimension: "Transcripts",
        options: ["Contains", "Does not contain", "Exactly matches"],
      },
      {
        id:uuidv4(),
        dimension: "Notes",
        options: ["Contains", "Does not contain", "Exactly matches"],
      },
      {
        id:uuidv4(),
        dimension: "Title",
        options: ["Contains", "Does not contain", "Exactly matches"],
      },
      {
        id:uuidv4(),
        dimension: "Products",
        options: ["Includes", "Does not includes"],
      },
      {
        id:uuidv4(),
        dimension: "Competitors",
        options: ["Includes", "Does not includes"],
      },
      {
        id:uuidv4(),
        dimension: "Topics",
        options: ["Includes", "Does not includes"],
      },
      {
        id:uuidv4(),
        dimension: "Price",
        options: ["Less than", "More than", "Equals to"],
      },
    ]
  }
]