export const isImage = (fileName) => {
    if(!fileName) {
        return false;
    }
    let splitFileName = fileName.split(".");
    let extension = splitFileName[splitFileName.length - 1];
    const imageFileTypes = ["png", "jpg", "jpeg", "gif", "tiff"];
    if (imageFileTypes.indexOf(extension) > -1) {
        return true;
    } else {
        return false;
    }
};
