import socketIOClient from "socket.io-client";
import firebase from "./../../firebase/firebase.js";

import {
  startMediaDevice,
  getMediaStream,
  startMediaDeviceWithScreenShare,
  stopMediaStream,
} from "./AudioService";

const audioUtils = require("./audioUtils"); // for encoding audio data as PCM
const mic = require("microphone-stream").default; // collect microphone input as a stream of raw bytes
// our global variables for managing state

let sampleRate = 16000;
let inputSampleRate;
let socket = false;
let micStream = false;
let localMeetingId = false;

export const startRecordingNew = (startRecordingCallback, meetingId) => {
  localMeetingId = meetingId;
  startMediaDevice().then(() => {
    setTimeout(() => {
      getMediaStream().then(async (userMediaStream) => {
        micStream = new mic();
        micStream.on("format", function (data) {
          inputSampleRate = data.sampleRate;
        });

        micStream.on("data", function (rawAudioChunk) {
          let binary = convertAudioToBinaryMessage(rawAudioChunk);

          if (socket.readyState === socket.OPEN) {
            socket.emit("binaryData", {
              binary,
            });
          }
        });

        let authToken = await firebase.auth().currentUser.getIdToken();
        let socketURL = process.env.REACT_APP_AUDIO_STREAMER
          ? process.env.REACT_APP_AUDIO_STREAMER
          : "https://dev-audio-streamer.bluecap.ai";
        socket = socketIOClient(`${socketURL}?token=${authToken}`);

        socket.on("connect", function () {
          socket.emit("startStreaming", {
            meetingId: localMeetingId,
          });
          setTimeout(() => {
            micStream.setStream(userMediaStream);
            startRecordingCallback(userMediaStream);
          }, 1000);
        });
      });
    }, 1000);
  });
};
export const startOnlineMeetingRecording = (
  startRecordingCallback,
  meetingId,
  setError
) => {
  localMeetingId = meetingId;
  startMediaDeviceWithScreenShare()
    .then(() => {
      setTimeout(() => {
        getMediaStream().then(async (userMediaStream) => {
          micStream = new mic();
          micStream.on("format", function (data) {
            inputSampleRate = data.sampleRate;
          });

          micStream.on("data", function (rawAudioChunk) {
            let binary = convertAudioToBinaryMessage(rawAudioChunk);

            if (socket.readyState === socket.OPEN) {
              socket.emit("binaryData", {
                binary,
              });
            }
          });

          let authToken = await firebase.auth().currentUser.getIdToken();
          let socketURL = process.env.REACT_APP_AUDIO_STREAMER
            ? process.env.REACT_APP_AUDIO_STREAMER
            : "https://dev-audio-streamer.bluecap.ai";
          socket = socketIOClient(`${socketURL}?token=${authToken}`);

          socket.on("connect", function () {
            socket.emit("startStreaming", {
              meetingId: localMeetingId,
            });
            setTimeout(() => {
              micStream.setStream(userMediaStream);
              startRecordingCallback(userMediaStream);
            }, 1000);
          });
        });
      }, 1000);
    })
    .catch((e) => setError(e));
};

export const closeSocket = function () {
  socket.emit(
    "stopStreaming",
    {
      meetingId: localMeetingId,
    },
    (response) => {
      if (response.status) {
        if (socket && socket.readyState === socket.OPEN) {
          micStream.stop();
          stopMediaStream();
          socket.close();
        } else {
          if (micStream && micStream.stop) micStream.stop();
          stopMediaStream();
          socket.close();
        }
      }
    }
  );
};

export const pauseSocket = function () {
  if (micStream && micStream.pauseRecording) micStream.pauseRecording();
};
export const resumeStreamAudioToWebSocket = function (setIsRecording) {
  if (micStream && micStream.playRecording) {
    micStream.playRecording();
    setIsRecording(true);
  }
};

function convertAudioToBinaryMessage(audioChunk) {
  let raw = mic.toRaw(audioChunk);

  if (raw == null) return;

  // downsample and convert the raw audio bytes to PCM
  let downsampledBuffer = audioUtils.downsampleBuffer(
    raw,
    inputSampleRate,
    sampleRate
  );
  let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);
  return pcmEncodedBuffer; // for google speech to text
}
