import * as React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Page from "../Page";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import AddDeal from "./AddDeal";
import ViewDeal from "./ViewDeal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import DealsBoard from "./DealsBoard";

import {
  GetDeal,
  GetDeals,
  updateDeal,
  deleteDeal,
  getElasticToken,
} from "./../../firebase/firestore.js";

import ElasticContactAutocomplete from "../Tasks/ElasticContactAutocomplete";
import ElasticAccountAutocomplete from "../Tasks/ElasticAccountAutocomplete";

export default function Deals() {
  let [addDealDialogStatus, setAddDealDialogStatus] = useState(false);
  let [viewDealDialogStatus, setViewDealDialogStatus] = useState(false);
  let [openDealId, setOpenDealId] = useState(null);
  let [deals, setDeals] = useState([]);
  let [deleteDealId, setDeleteDealId] = useState(null);

  let [searchQuery, setSearchQuery] = useState("");
  let [relatedToEntityFilter, setRelatedToEntityFilter] = useState("contact");
  let [relatedToFilter, setRelatedToFilter] = useState(null);

  const [elasticToken, setElasticToken] = useState(null);

  let [defaultDealStatus, setDefaultDealStatus] = useState(
    "identify_decision_makers"
  );

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  useEffect(() => {
    const loadDeals = async () => {
      let deals = await GetDeals();
      setDeals(deals);
    };
    loadDeals();
  }, []);

  const handleCloseAddDealDialog = () => {
    setAddDealDialogStatus(false);
  };

  const handleAddDealClick = () => {
    setAddDealDialogStatus(true);
  };

  const addDealInList = (deal) => {
    console.log(deal);
    let tempDeals = [...deals];
    tempDeals.push(deal);
    setDeals(tempDeals);
  };

  const handleDeleteDealClick = (dealId) => {
    setDeleteDealId(dealId);
  };

  const handleDeleteDeal = async () => {
    let deletedDeal = await deleteDeal(deleteDealId);
    if (deletedDeal) {
      let newDealList = deals.filter((deal) => deal.id != deleteDealId);
      setDeals(newDealList);
    }
    setDeleteDealId(null);
  };

  const handleDragDrop = (result) => {
    if (result.source.droppableId == result.destination.droppableId) {
      console.log("same column: do nothing");
      return;
    } else {
      let newDeals = deals.map((deal, i) => {
        if (deal.id == result.draggableId) {
          deal.status = result.destination.droppableId;
          updateDeal(deal.id, {
            status: result.destination.droppableId,
          });
          // order++;
        }

        return deal;
      });
      setDeals(newDeals);
    }
  };

  const getFilteredDeals = () => {
    let filteredDeals = [...deals];
    if (searchQuery.length) {
      filteredDeals = deals.filter((deal) =>
        deal?.title?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
    }

    if (relatedToFilter) {
      filteredDeals = filteredDeals.filter((deal) => {
        if (!deal.relatedTo) return false;

        if (relatedToFilter.type == "contact") {
          if (deal.relatedTo.type != "contact") {
            return false;
          } else {
            return (
              deal.relatedTo.type == relatedToFilter.type &&
              deal.relatedTo.contactId == relatedToFilter.contactId
            );
          }
        }

        if (relatedToFilter.type == "account") {
          if (deal.relatedTo.type != "account") {
            return false;
          } else {
            return (
              deal.relatedTo.type == relatedToFilter.type &&
              deal.relatedTo.accountId == relatedToFilter.accountId
            );
          }
        }
      });
    }
    return filteredDeals;
  };

  const handleOpenDealClick = (dealId) => {
    setOpenDealId(dealId);
    setViewDealDialogStatus(true);
  };

  const handleCloseViewDealDialog = async () => {
    if (openDealId) {
      let modifiedDeal = await GetDeal(openDealId);
      if (modifiedDeal.success) {
        let newDealList = deals.map((deal) => {
          if (deal.id == openDealId) {
            return modifiedDeal.deal;
          }
          return deal;
        });

        setDeals(newDealList);
      }
    }
    setViewDealDialogStatus(false);
  };

  const handleAddDeal = (title) => {
    let status = "identify_decision_makers";

    if (title == "Needs Analysis") {
      status = "needs_analysis";
    } else if (title == "Value Proposition") {
      status = "value_proposition";
    } else if (title == "Proposal/Price Quote") {
      status = "proposal_price_quote";
    } else if (title == "Negotiation Review") {
      status = "negotiation_review";
    } else if (title == "Closed Won") {
      status = "closed_won";
    } else if (title == "Closed Lost") {
      status = "closed_lost";
    } else if (title == "Closed-Lost to Competition") {
      status = "closed_lost_to_competition";
    }

    setDefaultDealStatus(status);
    setAddDealDialogStatus(true);
  };
  // const elRef = useRef(null);
  // useEffect(() => {
  //   if (!algoliaContactAutocompleteInstance) {
  //     loadContactAutocomplete();
  //   }
  // }, [elRef.current]);
  console.log(relatedToEntityFilter, "realted to entity filter");
  return (
    <Page>
      <Container maxWidth={"xl"}>
        <Dialog
          open={deleteDealId ? true : false}
          onClose={() => setDeleteDealId(null)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this deal?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="modal-button white"
              onClick={() => setDeleteDealId(null)}
            >
              Cancel
            </button>
            <button className="modal-button red ml" onClick={handleDeleteDeal}>
              Delete
            </button>
          </DialogActions>
        </Dialog>

        {addDealDialogStatus && (
          <AddDeal
            addDealDialogStatus={addDealDialogStatus}
            handleCloseAddDealDialog={handleCloseAddDealDialog}
            addDealInList={addDealInList}
            defaultDealStatus={defaultDealStatus}
          />
        )}
        {viewDealDialogStatus && (
          <ViewDeal
            viewDealDialogStatus={viewDealDialogStatus}
            handleCloseViewDealDialog={handleCloseViewDealDialog}
            dealId={openDealId}
          />
        )}
        <Box>
          <Box
            display="flex"
            // justifyContent="space-between"
            alignItems="center"
            // width={700}
          >
            <Box display="flex" alignItems="center">
              <h4>Deals</h4>

              <TextField
                size="small"
                style={{ marginLeft: "5px", maxWidth: "150px" }}
                label="Search deals"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <Box width={3 / 5} marginLeft={2}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <p style={{ marginRight: "10px" }}>Related To: </p>
                <FormControl size="small" style={{ minWidth: "150px" }}>
                  <InputLabel
                    id="entity-select-label"
                    // className="task-select-label"
                  >
                    Entity
                  </InputLabel>
                  <Select
                    labelId="entity-select-label"
                    id="entity-select"
                    value={relatedToEntityFilter}
                    label="Entity"
                    onChange={(e) => {
                      setRelatedToFilter(null);
                      setRelatedToEntityFilter(e.target.value);
                    }}
                    className="deal-select"
                  >
                    {relatedToEntityFilter && false && (
                      <MenuItem value="" className="deal-select-item">
                        Clear filter
                      </MenuItem>
                    )}

                    <MenuItem value="contact" className="deal-select-item">
                      Contact
                    </MenuItem>
                    <MenuItem value="account" className="deal-select-item">
                      Account
                    </MenuItem>
                  </Select>
                </FormControl>

                <Box style={{ marginLeft: 5 }}>
                  {elasticToken &&
                    (relatedToEntityFilter === "contact" ? (
                      <ElasticContactAutocomplete
                        setRelatedTo={setRelatedToFilter}
                        apiKey={elasticToken}
                        className={"elastic-view-deal-input"}
                      />
                    ) : (
                      relatedToEntityFilter === "account" && (
                        <ElasticAccountAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <DealsBoard
            deals={getFilteredDeals()}
            handleDragDrop={handleDragDrop}
            addDeal={handleAddDeal}
            handleOpenDealClick={handleOpenDealClick}
            handleDeleteDealClick={handleDeleteDealClick}
          />
        </Box>
      </Container>
    </Page>
  );
}
