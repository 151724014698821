import React, { useEffect, useState } from "react";

import { Grid, TextField, Box, AppBar, Typography } from "@material-ui/core";

import firebase from "./../../firebase/firebase";
import { getData } from "../../utils";
import { Button, CircularProgress, Table } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { createStyles, makeStyles } from "@material-ui/styles";
import ExtensionOffIcon from "@mui/icons-material/ExtensionOff";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";

const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 1s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  })
);

export default function DomainVerification() {
  const [loading, setLoading] = useState(true);
  const [domain, setDomain] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [verifyDomainRequestLoading, setVerifyDomainRequestLoading] =
    useState(false);
  const [refreshStatusLoading, setRefreshStatusLoading] = useState(false);

  const [dnsRecords, setDnsRecords] = useState([]);

  const classes = useStyles();

  let checkDomainVerificationStatus = async () => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let result = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/organization/domain-varification-status`,
      jwtToken
    );
    setLoading(false);
    setRefreshStatusLoading(false);
    if (result.status === "success") {
      setDomain(result.data.domain);
      if (result.data.verificationStatus) {
        setVerificationStatus(true);
      }
    }
  };
  useEffect(() => {
    checkDomainVerificationStatus();
  }, []);

  const handleVerifyDomainVerificationClick = async () => {
    setVerifyDomainRequestLoading(true);
    let jwtToken = await firebase.auth().currentUser.getIdToken();
    let result = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/organization/domain-varification-request`,
      jwtToken
    );
    setVerifyDomainRequestLoading(false);

    if (result.status === "success") {
      setDnsRecords(result.data.sending_dns_records);
    }
  };

  const handleRefreshStatusClick = async () => {
    // setLoading(true);
    setRefreshStatusLoading(true);
    checkDomainVerificationStatus();
  };
  return (
    <>
      <Box
        backgroundColor="#fff"
        borderRadius="6px"
        padding="20px"
        width="230px"
        height="260px"
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        onClick={() => {
          if (!loading && !verificationStatus) {
            handleVerifyDomainVerificationClick();
          }
        }}
        style={{ cursor: "pointer", position: "relative" }}
        sx={{ mr: 2 }}
        title={
          verificationStatus
            ? "Connected with organization domain"
            : "Connect with organization domain"
        }
      >
        <Box>
          <Box
            height="120px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <DomainVerificationIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
          </Box>
          <p style={{ textAlign: "center" }}>Domain verification</p>
          <p style={{ textAlign: "center", margin: "10px 0" }}>
            <span
              style={{
                backgroundColor: "rgb(231, 231, 231)",
                width: "50px",
                padding: "2px 5px",
                borderRadius: "6px",
                textAlign: "center",
                fontSize: "14px",
                color: "#808080",
              }}
            >
              Domain
            </span>
          </p>
        </Box>
        <Box
          style={{
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {loading ? (
            <CircularProgress style={{ marginLeft: "10px" }} size={"20px"} />
          ) : verifyDomainRequestLoading ? (
            <CircularProgress style={{ marginLeft: "10px" }} size={"20px"} />
          ) : verificationStatus ? (
            <>
              <CheckCircleOutlineIcon
                style={{
                  color: "green",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
              <span>Connected</span>
            </>
          ) : (
            <span>Connect organization domain</span>
          )}
        </Box>
      </Box>

      <Box marginTop={2}>
        {dnsRecords.length > 0 && (
          <Box marginTop={2} overflow="auto">
            <Box>
              <p>
                Your domain is not authorized. You can authorize it now if you
                have DNS access.
              </p>
              <p style={{ color: "red" }}>
                It can take up to 24 hours after add records in DNS settings. If
                you have added DNS records already then please wait for 24 hours
                otherwise click below button to get DNS records for your domain.
              </p>
              <Box display={"flex"} alignItems="center" marginY={1}>
                <Button
                  className="modal-button blue"
                  onClick={handleRefreshStatusClick}
                  disabled={refreshStatusLoading}
                >
                  <AutorenewIcon
                    className={refreshStatusLoading ? classes.rotateIcon : ""}
                    style={{ cursor: "pointer", marginRight: 8 }}
                  />
                  Check verification
                </Button>
              </Box>
            </Box>
            <Table>
              <thead>
                <th style={{ textAlign: "left" }}>Type</th>
                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ textAlign: "left" }}>Value</th>
              </thead>

              <tbody>
                {dnsRecords
                  .filter((r) => r.record_type === "TXT")
                  .map((record) => {
                    return (
                      <tr>
                        <td>
                          <Box
                            display={"flex"}
                            alignItems="center"
                            width={40}
                            height={100}
                          >
                            {record.record_type}
                          </Box>
                        </td>
                        <td>
                          <Box display={"flex"} alignItems="center" width={300}>
                            {record.name}
                            <ContentCopyIcon
                              onClick={(e) => {
                                navigator.clipboard.writeText(record.name);
                                e.target.style.color = "green";
                              }}
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </Box>
                        </td>
                        <td>
                          <Box
                            display={"flex"}
                            maxWidth={500}
                            style={{ wordBreak: "break-word" }}
                            alignItems="center"
                          >
                            {record.value}
                            <ContentCopyIcon
                              onClick={(e) => {
                                navigator.clipboard.writeText(record.value);
                                e.target.style.color = "green";
                              }}
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            />
                          </Box>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
}
