import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress, Box, Button } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Grid } from "@material-ui/core";
import TextEditor from "../TextEditor";
import AddIcon from "@mui/icons-material/Add";
import { Post } from "../../utils/axiosUtils";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function FollowUpMail({
  meetingId,
  handleClose,
  meetingTitle,
  participants,
  attendeeEmails,
}) {
  const [loading, setLoading] = useState(true);
  const [domainVerificationStatus, setDomainVerificationStatus] =
    useState(true);
  const [domainVerificationStatusMsg, setDomainVerificationStatusMsg] =
    useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [subject, setSubject] = useState(`Follow up mail: ${meetingTitle}`);
  const [body, setBody] = useState("");
  const [tempParticipant, setTempParticipant] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState([]);
  const [scheduledTime, setScheduledTime] = useState(new Date());
  const [participantsEmailList, setParticipantsEmailList] = useState(() => {
    let attendeeEmailsArr = attendeeEmails.map((email) => {
      return {
        email: email,
        name: email,
      };
    });
    return attendeeEmailsArr.filter(
      (p) => p.email && p.email !== firebase.auth().currentUser.email
    );
  });

  const [recipientsError, setRecipientsError] = useState("");
  const [subjectError, setSubjectError] = useState("");

  useEffect(() => {
    console.log("firebase.auth().currentUser", firebase.auth().currentUser);
    const loadGPT3Data = async () => {
      let jwtToken = await firebase.auth().currentUser.getIdToken();
      let res = await getData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/follow-up-mail-content?meetingId=${meetingId}`,
        jwtToken
      );
      setLoading(false);
      setBody(res.data.suggestedContent);
      // let rawContent = convertFromRaw(res.data.suggestedContent);
      // console.log("rawContent", rawContent);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(
            res.data.suggestedContent.replaceAll("\n\n", "\n")
          )
        )
      );
    };

    const checkDomainVerificationStatus = async () => {
      let jwtToken = await firebase.auth().currentUser.getIdToken();

      let result = await getData(
        `${process.env.REACT_APP_BASE_API_URL}/organization/domain-varification-status`,
        jwtToken
      );

      if (result.status === "success") {
        if (!result.data.verificationStatus) {
          setDomainVerificationStatus(false);
          let displayName = await firebase.auth().currentUser.displayName;
          let email = await firebase.auth().currentUser.email;
          let msg = `Hey ${displayName}! Want to send your follow-up emails using your own email address ${email}?`;
          setDomainVerificationStatusMsg(msg);
        }
      }
    };
    loadGPT3Data();
    checkDomainVerificationStatus();
  }, []);

  const handleSendMail = async () => {
    let recipients = participantsEmailList.map((p) => p.email);

    if (!subject || subject === "") {
      setSubjectError("Please add email subject!");
      return;
    }

    if (!recipients.length) {
      setRecipientsError("Please add recipients!");
      return;
    }
    setApiLoading(true);

    let formattedBody = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    let formData = new FormData();
    attachments?.map((attachment) => {
      formData.append("attachments", attachment);
    });
    formData.append("meetingId", meetingId);
    formData.append("subject", subject);
    formData.append("body", formattedBody);
    formData.append("recipients", recipients);
    formData.append("schedule", new Date(scheduledTime).toISOString());
    Post("meeting/participants/followup", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(() => {
        handleClose();
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err, "error when uploading files");
        setApiLoading(false);
      });
  };

  const addParticipants = () => {
    let newArr = participantsEmailList;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(tempParticipant).toLowerCase())) {
      newArr.push({
        email: String(tempParticipant).toLowerCase(),
        name: tempParticipant,
      });

      let participantEmails = [];
      let tmpUnique = newArr.filter((n) => {
        if (!participantEmails.includes(n.email)) {
          participantEmails.push(n.email);
          return true;
        }
        return false;
      });

      if (tmpUnique.length) {
        setRecipientsError("");
      }
      setParticipantsEmailList(tmpUnique);

      setTempParticipant("");
    }
  };

  const handleRemoveFile = (fileName) => {
    let filterAttachments = attachments.filter(
      (item) => item.name !== fileName
    );
    setAttachments(filterAttachments);
  };

  const fileUpload = (e, addType = "input") => {
    if (addType === "input") {
      let willAddAttachments = e.target?.files[e.target?.files?.length - 1];
      if (willAddAttachments) {
        setAttachments([...attachments, willAddAttachments]);
      }
    } else if (addType === "editor") {
      setAttachments([...attachments, ...e]);
    }
  };

  const handleClick = () => {
    let doc = document.getElementById("attachment-input");
    if (doc) {
      doc?.click();
    }
  };

  return (
    <Dialog maxWidth="lg" fullWidth onClose={handleClose} open={true}>
      {/* <DialogTitle id="responsive-dialog-title" sx={{ marginBottom: "2em" }}>
        {"Send follow up mail to all meeting participants"}
      </DialogTitle> */}
      <DialogContent style={{ minHeight: loading ? "20vh" : "70vh" }}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="20vh"
            justifyContent="center"
          >
            <CircularProgress size={"30px"} sx={{ marginBottom: "1em" }} />
            <p style={{ paddingLeft: "10px" }}>
              Hang tight. I am going through your meeting notes. I will have it
              ready in a minute.
            </p>
          </Box>
        ) : (
          <Box display={"flex"} flexDirection="column">
            <Grid container spacing={3} paddingY={"15px"}>
              <Grid item md={6} xs={6}>
                <TextField
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  id="subject"
                  name="subject"
                  variant="outlined"
                  label="Subject"
                  helperText={subjectError ? subjectError : ""}
                  error={subjectError.length}
                  fullWidth
                  placeholder="Enter subject here"
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  placeholder="Add recipients"
                  inputProps={{ "aria-label": "Add recipients" }}
                  value={tempParticipant}
                  variant="outlined"
                  label="Add recipients"
                  helperText={recipientsError ? recipientsError : ""}
                  error={recipientsError.length}
                  onChange={(e) => setTempParticipant(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      addParticipants();
                    }
                  }}
                  onBlur={addParticipants}
                />

                <div style={{ paddingTop: "10px" }}>
                  {participantsEmailList.map((p, index) => {
                    // if (!p.email) return null;
                    return (
                      <Chip
                        // margin="2px"
                        style={{ margin: "2px" }}
                        key={index}
                        avatar={
                          <Avatar src={p.avatar} alt={p?.name}>
                            {p.name.split("")[0].toUpperCase()}
                          </Avatar>
                        }
                        label={p.name}
                        onDelete={() => {
                          let newArr = participantsEmailList.filter(
                            (participant) => participant.email != p.email
                          );
                          setParticipantsEmailList(newArr);
                        }}
                      />
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                    label={"Schedule time"}
                    value={scheduledTime}
                    onChange={(newValue) => {
                      setScheduledTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <h6 className="agenda">Attachments</h6>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id={"attachment-input"}
                  onChange={fileUpload}
                />
                <AddIcon
                  fontSize={"medium"}
                  sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
                  onClick={handleClick}
                />
              </Box>
              <div className="attachments">
                {attachments?.map((attachment, index) => (
                  <div
                    className="attachment"
                    key={index}
                    //onClick={() => attachmentOpen(attachment.id)}
                  >
                    <span className="title">{attachment?.name}</span>
                    <ClearIcon
                      fontSize={"18px"}
                      color={"#303030"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleRemoveFile(attachment?.name)}
                    />
                  </div>
                ))}
              </div>
            </Box>
            <TextEditor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              mentions={[]}
              handlePastedFiles={(files) => fileUpload(files, "editor")}
              isBorder
              placeholder={"Email content"}
              className={"task-editor followup-email"}
            />
            {!domainVerificationStatus && (
              <Box marginTop={2}>
                <p style={{ color: "red" }}>{domainVerificationStatusMsg}</p>
                <p style={{ color: "red" }}>
                  Go to settings>Integrations and complete domain verification.
                  For help, visit:{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                    target={"_blank"}
                    href="https://support.bluecap.ai"
                  >
                    https://support.bluecap.ai
                  </a>
                  .
                </p>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <button className="modal-button white" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="modal-button blue ml"
            onClick={handleSendMail}
            disabled={apiLoading}
          >
            {apiLoading ? (
              <CircularProgress size={"15px"} sx={{ color: "#fff" }} />
            ) : (
              "Send"
            )}
          </button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default FollowUpMail;
