import React, { useEffect, useState } from "react";
import MeetingDetailTranscript from "./MeetingDetailTranscript";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MeetingDetailNotes from "./MeetingDetailNotes";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Menu from "@mui/material/Menu";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@material-ui/core";
import MeetingDetailAttachments from "./MeetingDetailAttachments";
import { Get, Post } from "../../utils/axiosUtils";
import { markTranscriptAsReadFs } from "../../firebase/firestore";
import HistoryIcon from "@material-ui/icons/History";
import envelopeIconBlue from "../../assets/images/envelopeForwardBlue.png";

import { getData } from "../../utils";
import firebase from "../../firebase/firebase";

function MeetingDetailSidemenu({
  meetingId,
  meetingType,
  menuExpand,
  setMenuExpand,
  transcriptRead,
  setTranscriptRead,
  showSnackbar,
  participants,
  transcriptionData,
  setTranscriptionData,
  showChangeLog,
  openShareModal,
  activeDialog,
  activeEntity,
  isHybrid,
  openForwardEmailModal,
}) {
  const [activeTab, setActiveTab] = useState("transcript");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [uploadUrlText, setUploadUrlText] = useState("");
  const [uploading, setUploading] = useState({ status: false, uploadType: "" });
  const [attachmentLoading, setAttachmentLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [transcriptReadLoading, setTranscriptReadLoading] = useState(false);
  const [disableShare, setDisableShare] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpand = () => {
    let doc = document.getElementById("meeting-sidemenu");
    if (doc && doc?.classList) {
      if (doc.classList?.contains("collapse")) {
        doc.classList.remove("collapse");
        doc.classList.add("expand");
        setTimeout(() => {
          setMenuExpand(true);
        }, 500);
      } else {
        if (doc.classList?.contains("expand")) {
          doc.classList.remove("expand");
        }
        doc.classList.add("collapse");
        setTimeout(() => {
          setMenuExpand(false);
        }, 500);
      }
    }
  };

  const getAttachments = () => {
    if (!meetingId) {
      return;
    }
    Get(`attachments/meetings/${meetingId}`)
      .then(({ data }) => {
        setAttachments(data.data);
        setAttachmentLoading(false);
      })
      .catch((err) => {
        console.log(err, "error happen when fetching attachments");
      });
  };
  useEffect(getAttachments, [meetingId]);

  let loadMeetingAccessSettings = async () => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let meetingAccessData = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/user/get-meeting-access-settings`,
      jwtToken
    );

    if (meetingAccessData.status === "success") {
      if (meetingAccessData.data.accessLevel === "org") {
        setDisableShare(true);
      }
    }
  };

  useEffect(() => {
    loadMeetingAccessSettings();
  }, []);

  const fileUpload = (e, uploadType = "disk") => {
    setUploading({ status: true, uploadType });
    let formData = new FormData();
    if (uploadType === "disk") {
      formData.append("attachment", e.target.files[0]);
    } else {
      formData.append("file_url", uploadUrlText);
    }
    formData.append("type", "meetings");
    formData.append("typeid", meetingId);
    Post("attachments/create", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(({ data }) => {
        setAttachments([data.data, ...attachments]);
        setUploading({ status: false, uploadType: "" });
        setAnchorEl(false);
        setUploadUrlText("");
      })
      .catch((err) => {
        console.log(err);
        setUploading({ status: false, uploadType: "" });
      });
  };

  const fileInputOpener = () => {
    let doc = document.getElementById("meeting-detail-attachment-input");
    if (doc) {
      doc?.click();
    }
  };

  const markTranscriptAsRead = async () => {
    setTranscriptReadLoading(true);
    if (!transcriptRead) {
      let res = await markTranscriptAsReadFs(meetingId);
      if (res) {
        setTranscriptRead(true);
        showSnackbar({
          show: true,
          severity: "success",
          message: "Transcript marked as reviewed successfully.",
        });
      } else {
        showSnackbar({
          show: true,
          severity: "error",
          message: "Something went wrong.",
        });
      }
    }
    setTranscriptReadLoading(false);
  };

  return (
    <div
      className={`detail-sidemenu ${menuExpand ? "expand" : "collapse"}`}
      id={"meeting-sidemenu"}
    >
      <div className="sidemenu-header">
        <div className="context-menu">
          <ul className="tabs">
            <li
              className={activeTab === "transcript" ? "active" : ""}
              onClick={() => setActiveTab("transcript")}
            >
              Transcript
            </li>
            <li
              className={activeTab === "attachments" ? "active" : ""}
              onClick={() => setActiveTab("attachments")}
            >
              Attachments
            </li>
            <li
              className={activeTab === "notes" ? "active" : ""}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </li>
          </ul>

          {activeTab === "transcript" ? (
            <MeetingDetailTranscript
              meetingId={meetingId}
              meetingType={meetingType}
              markTranscriptAsRead={markTranscriptAsRead}
              participants={participants}
              transcriptionData={transcriptionData}
              setTranscriptionData={setTranscriptionData}
              showSnackbar={showSnackbar}
              activeDialog={activeDialog}
              activeEntity={activeEntity}
              isHybrid={isHybrid}
            />
          ) : activeTab === "attachments" ? (
            <MeetingDetailAttachments
              attachments={attachments}
              fetchLoading={attachmentLoading}
              setAttachments={setAttachments}
              openAttachmentMenu={handleClick}
            />
          ) : activeTab === "notes" ? (
            <MeetingDetailNotes meetingId={meetingId} />
          ) : null}
        </div>
        <div className="icons-menu">
          <div className="meeting-detail-icon icon-hr">
            {menuExpand ? (
              <ChevronRightIcon
                sx={{ color: "#2C73FF" }}
                onClick={handleExpand}
              />
            ) : (
              <ChevronLeftIcon
                sx={{ color: "#2C73FF" }}
                onClick={handleExpand}
              />
            )}
          </div>
          <div className="meeting-detail-icon">
            <img
              src={envelopeIconBlue}
              alt="envelope"
              onClick={openForwardEmailModal}
            />
          </div>
          <div className="meeting-detail-icon">
            <HistoryIcon sx={{ color: "#2c73ff" }} onClick={showChangeLog} />
          </div>
          <div
            className="meeting-detail-icon icon-hr"
            title={disableShare ? "Not allowed" : "Share meeting"}
          >
            <ShareIcon
              style={{ cursor: disableShare ? "not-allowed" : "pointer" }}
              sx={{ color: "#2c73ff" }}
              onClick={disableShare ? console.log("disabled") : openShareModal}
            />
          </div>
          {menuExpand && activeTab === "transcript" ? (
            <div className="meeting-detail-icon">
              {transcriptReadLoading ? (
                <Box>
                  <CircularProgress sx={{ color: "#2c73ff" }} size={"16px"} />
                </Box>
              ) : transcriptRead ? (
                <CheckCircleOutlineIcon sx={{ color: "#2c73ff" }} />
              ) : (
                <CheckCircleOutlineIcon
                  sx={{ color: "#707070" }}
                  onClick={markTranscriptAsRead}
                />
              )}
            </div>
          ) : menuExpand && activeTab === "attachments" ? (
            <>
              <div className="meeting-detail-icon">
                <AddOutlinedIcon
                  sx={{ color: "#2c73ff" }}
                  onClick={handleClick}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id={"meeting-detail-attachment-input"}
                  onChange={fileUpload}
                />
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: "40ch",
                    },
                  }}
                >
                  <Box p={2}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        width="fullwidth"
                        label="Upload via url"
                        variant="outlined"
                        value={uploadUrlText}
                        onChange={(e) => setUploadUrlText(e.target.value)}
                        className={"tasks-title-input"}
                        style={{ marginRight: "1em" }}
                      />
                      <button
                        className="modal-button blue"
                        onClick={(e) => fileUpload(e, "url")}
                        disabled={uploading?.status}
                        style={{ height: "43px", width: "90px" }}
                      >
                        {uploading?.status &&
                        uploading?.uploadType === "url" ? (
                          <CircularProgress
                            size="15px"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </Box>
                    <Box width="100%">
                      <button
                        className="modal-button white"
                        onClick={fileInputOpener}
                        disabled={uploading?.status}
                        style={{ marginTop: "1em", width: "100%" }}
                      >
                        {uploading?.status &&
                        uploading?.uploadType === "disk" ? (
                          <CircularProgress
                            size={"15px"}
                            style={{ color: "2c73ff" }}
                          />
                        ) : (
                          "Upload from disk"
                        )}
                      </button>
                    </Box>
                  </Box>
                </Menu>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MeetingDetailSidemenu;
