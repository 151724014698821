import React from "react";
import { Box } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import TaskColumn from "./TaskColumn";

export default function TasksBoard({
  tasks,
  handleDragDrop,
  addTask,
  handleOpenTaskClick,
  handleDeleteTaskClick,
}) {
  console.log("tasks tasks", tasks);

  tasks.sort((t1, t2) => t1.order - t2.order);

  let notStartedTasks = tasks.filter((task) => task.status == "not_started");
  let deferredTasks = tasks.filter((task) => task.status == "deferred");
  let inprogressTasks = tasks.filter((task) => task.status == "inprogress");
  let completedTasks = tasks.filter((task) => task.status == "completed");
  let waitingForInputTasks = tasks.filter(
    (task) => task.status == "waiting_for_input"
  );

  const onDragEnd = async (result) => {
    console.log(result);
    handleDragDrop(result);
    //https://codesandbox.io/s/create-react-app-forked-dm46j?file=/src/App.js:1779-5349
  };
  return (
    <Box paddingTop={0} marginTop={2} display="flex">
      <DragDropContext onDragEnd={onDragEnd}>
        <Box sx={{ display: "flex" }} id={"analyticChartsContainer"}>
          <TaskColumn
            droppableId="not_started"
            key="not_started"
            tasks={notStartedTasks}
            title="Not Started"
            addTask={addTask}
            handleOpenTaskClick={handleOpenTaskClick}
            handleDeleteTaskClick={handleDeleteTaskClick}
          />
          <TaskColumn
            droppableId="deferred"
            key="deferred"
            tasks={deferredTasks}
            title="Deferred"
            addTask={addTask}
            handleOpenTaskClick={handleOpenTaskClick}
            handleDeleteTaskClick={handleDeleteTaskClick}
          />
          <TaskColumn
            droppableId="inprogress"
            key="inprogress"
            tasks={inprogressTasks}
            title="In-Progress"
            addTask={addTask}
            handleOpenTaskClick={handleOpenTaskClick}
            handleDeleteTaskClick={handleDeleteTaskClick}
          />
          <TaskColumn
            droppableId="completed"
            key="completed"
            tasks={completedTasks}
            title="Completed"
            addTask={addTask}
            handleOpenTaskClick={handleOpenTaskClick}
            handleDeleteTaskClick={handleDeleteTaskClick}
          />
          <TaskColumn
            droppableId="waiting_for_input"
            key="waiting_for_input"
            tasks={waitingForInputTasks}
            title="Waiting for input"
            addTask={addTask}
            handleOpenTaskClick={handleOpenTaskClick}
            handleDeleteTaskClick={handleDeleteTaskClick}
          />
        </Box>
      </DragDropContext>
    </Box>
  );
}
