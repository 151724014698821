import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import { Box } from "@material-ui/core";
import EventIcon from "@mui/icons-material/Event";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TaskIcon from "@mui/icons-material/Task";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Post } from "../../utils/axiosUtils";
import CreateEvent from "../dashboard/CreateEvent";
import AddTask from "../Tasks/AddTask";
import CreateReminder from "../common/CreateReminder";

function InsightItem({
  title,
  description,
  icon,
  loading,
  id,
  handleCloseItem,
  playOpacity,
  isRisks,
  meetingId,
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dislikeLoading, setDislikeLoading] = useState(false);
  const [createMeetingModal, setCreateMeetingModal] = useState(false);
  const [createTaskModal, setCreateTaskModal] = useState(false);
  const [createReminderModal, setCreateReminderModal] = useState(false);

  const handleOpenMenu = (event) => {
    isRisks
      ? history.push(`/meeting/${meetingId}`)
      : setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDislike = () => {
    setDislikeLoading(true);
    Post("/insights/userReaction", {
      insightId: id,
      reaction: "dislike",
    })
      .then(() => {
        setDislikeLoading(false);
        handleCloseItem(id, "dislike");
      })
      .catch((err) => {
        console.log(err, "error happen when dislike");
        setDislikeLoading(false);
      });
  };

  const handleLike = () => {
    Post("/insights/userReaction", {
      insightId: id,
      reaction: "like",
    })
      .then(() => {
        handleCloseItem(id, "like");
      })
      .catch((err) => {
        console.log(err, "error happen when like");
      });
  };

  const open = Boolean(anchorEl);
  return (
    <div
      className={`insight-task ${playOpacity ? "insightOpacityAnimation" : ""}`}
      id={id}
    >
      {createMeetingModal && (
        <CreateEvent
          onClose={() => setCreateMeetingModal(false)}
          open={createMeetingModal}
          title={title}
          onSuccessCallback={handleLike}
        />
      )}
      {createTaskModal && (
        <AddTask
          addTaskDialogStatus={createTaskModal}
          handleCloseAddTaskDialog={() => setCreateTaskModal(false)}
          title={title}
          onSuccessCallback={handleLike}
          tasks={[title]}
        />
      )}
      {createReminderModal && (
        <CreateReminder
          addReminderDialogStatus={createReminderModal}
          handleCloseAddReminderDialog={() => setCreateReminderModal(false)}
          title={title}
          onSuccessCallback={handleLike}
        />
      )}

      <div className="context">
        {loading ? (
          <Stack spacing={1} animation="wave">
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={25}
              sx={{ bgcolor: "#cCc" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={13}
              sx={{ bgcolor: "#cCc", mt: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={13}
              sx={{ bgcolor: "#cCc" }}
            />
          </Stack>
        ) : (
          <>
            <h6>{title}</h6>
            <div
              className="description"
              onClick={() => history.push(`/meeting/${meetingId}`)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </Box>
              <span>{description}</span>
            </div>
          </>
        )}
      </div>
      {!loading && (
        <div className="actions">
          <button className="action-button" onClick={handleOpenMenu}>
            {isRisks ? "Review" : "Create"}
          </button>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                marginTop: 8,
                maxHeight: 200,
                maxWidth: "40ch",
              },
            }}
          >
            <Box p={2} display="flex" flexDirection="column">
              <button
                className="modal-button white"
                onClick={() => {
                  handleCloseMenu();
                  setCreateMeetingModal(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <EventIcon sx={{ fontSize: 16, color: "#2c73ff", mr: 1 }} />
                Meeting
              </button>
              <button
                className="modal-button white d-flex-center"
                onClick={() => {
                  handleCloseMenu();
                  setCreateTaskModal(true);
                }}
                style={{
                  marginTop: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <TaskIcon sx={{ fontSize: 16, color: "#2c73ff", mr: 1 }} />
                Task
              </button>
              {process.env.REACT_APP_APP_ENV === "dev" && (
                <button
                  className="modal-button white d-flex-center"
                  onClick={() => {
                    handleCloseMenu();
                    setCreateReminderModal(true);
                  }}
                  style={{
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <NotificationsActiveIcon
                    sx={{ fontSize: 16, color: "#2c73ff", mr: 1 }}
                  />
                  Reminder
                </button>
              )}
            </Box>
          </Menu>
          <Box className="action-icon">
            {dislikeLoading ? (
              <CircularProgress size={24} sx={{ color: "#707070" }} />
            ) : (
              <ThumbDownAltOutlinedIcon
                className="thumb-down-icon"
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={handleDislike}
              />
            )}
          </Box>
        </div>
      )}
    </div>
  );
}

export default InsightItem;
