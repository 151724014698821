import React, { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import { Delete, Get } from "../../utils/axiosUtils";
import AddIcon from "@mui/icons-material/Add";

function MeetingDetailAttachments({
  fetchLoading,
  attachments,
  setAttachments,
  openAttachmentMenu,
}) {
  const [attachmentLoading, setAttachmentLoading] = useState({
    status: false,
    attachmentId: "",
  });

  const attachmentOpen = (attachmentId) => {
    setAttachmentLoading({ status: true, attachmentId });
    Get(`attachments/${attachmentId}`)
      .then(({ data }) => {
        window.open(data.data.url, "_blank");
        setAttachmentLoading({ status: false, attachmentId: "" });
      })
      .catch((err) => {
        console.log(err, "error happen when opening attachment");
        setAttachmentLoading({ status: false, attachmentId: "" });
      });
  };

  const deleteAttachment = (e, attachmentId) => {
    e.stopPropagation();
    setAttachmentLoading({ status: true, attachmentId });
    Delete(`attachments/${attachmentId}`)
      .then(() => {
        let filterAttachments = attachments.filter(
          (item) => item.id !== attachmentId
        );
        setAttachments(filterAttachments);
        setAttachmentLoading({ status: false, attachmentId: "" });
      })
      .catch((err) => {
        console.log(err, "error happen when deleting attachment");
      });
  };

  return (
    <div className="dialog-container">
      {fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <div className="meeting-detail-tab-container">
          <div className="add-attachment" onClick={openAttachmentMenu}>
            <AddIcon
                fontSize={"medium"}
                sx={{ color: "#2C73FF", cursor: "pointer", mr:1 }}
            />
            <span>Add attachment</span>
          </div>
          <div className="meeting-detail-attachments">
            {attachments && attachments?.length > 0 ? (
                attachments.map((attachment, index) => (
                    <div
                        className="attachment"
                        key={index}
                        onClick={() => attachmentOpen(attachment.id)}
                    >
                      <span className="title">{attachment.fileName}</span>
                      {attachmentLoading?.status &&
                      attachmentLoading?.attachmentId === attachment.id ? (
                          <CircularProgress
                              size={"18px"}
                              style={{ color: "#303030" }}
                          />
                      ) : (
                          <ClearIcon
                              fontSize={"18px"}
                              color={"#303030"}
                              sx={{ cursor: "pointer" }}
                              onClick={(e) => deleteAttachment(e, attachment.id)}
                          />
                      )}
                    </div>
                ))
            ) : (
                <span style={{ textAlign: "center", width: "100%" }}>
              There is not any attachment.
            </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MeetingDetailAttachments;
