import React, { useEffect, useState, useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";
import { updateLead } from "../../../firebase/firestore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    boxShadow: "none",
    border: "1px solid #707070",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "30px",
    height: "30px",
    margin: "1em 0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#707070", // Custom colour
    padding: ".1em",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 6px",
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#707070",
    fontSize: "12px",
    border: "none",
    boxShadow: "none",
    zIndex: 3,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f3f3f3" : state.isDisabled ? "#cCc" : "#fff",
      color: state.isDisabled ? "#fff" : "#000",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

function LeadStatus({
  data,
  leadId,
  setLeadDetails,
  inputs,
  leadStatusSelectOptions,
  setInputs,
}) {
  const selectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [textArea, setTextArea] = useState(data.description);
  const [selectOptions, setSelectOption] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const generateOptions = () => {
    if (!leadStatusSelectOptions) {
      return;
    }
    let newSelectOptions = [];
    leadStatusSelectOptions.map((option) => {
      if (option.value === "email" && (!inputs.email || inputs.email === "")) {
        newSelectOptions.push({ ...option, disabled: true });
      } else if (
        option.value === "accountName" &&
        (!inputs.accountName || inputs.accountName === "")
      ) {
        newSelectOptions.push({ ...option, disabled: true });
      } else {
        newSelectOptions.push(option);
      }
    });
    setSelectOption(newSelectOptions);
  };

  useEffect(generateOptions, [leadStatusSelectOptions, inputs]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref?.current?.menuListRef && !ref?.current?.menuListRef?.contains(event?.target)) {
          setMenuOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(selectRef)

  const saveDescription = async () => {
    setLoading(true);
    await updateLead(leadId, { description: textArea });
    let tmpLeadData = { ...data };
    tmpLeadData.description = textArea;
    setLeadDetails(tmpLeadData);
    setEditMode(false);
    setLoading(false);
  };

  const saveLeadStatus = async () => {
    setStatusLoading(true);
    await updateLead(leadId, { leadStatus: inputs.leadStatus });
    let tmpLeadData = { ...data };
    tmpLeadData.leadStatus = inputs.leadStatus;
    setLeadDetails(tmpLeadData);
    setStatusLoading(false);
  };

  const onCancelChangeLeadStatus = () => {
    let newInputs = Object.assign({},inputs);
    newInputs.leadStatus = data.leadStatus;
    setInputs(newInputs);
    setMenuOpen(false);
  }

  const DropdownIndicator = () => (
    <Box
      sx={{
        marginRight: ".5em",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {data?.leadStatus !== inputs.leadStatus ? (
        <>
          {statusLoading ? (
            <CircularProgress size={18} sx={{ color: "#707070" }} />
          ) : (
            <>
              <ClearIcon
                fontSize={"18px"}
                sx={{ color: "#707070", cursor: "pointer" }}
                onClick={onCancelChangeLeadStatus}
              />
              <CheckIcon
                fontSize={"18px"}
                sx={{ color: "#707070", cursor: "pointer", marginLeft: ".5em" }}
                onClick={saveLeadStatus}
              />
            </>
          )}
        </>
      ) : (
        <EditIcon
          fontSize={"18px"}
          sx={{ color: "#707070", cursor: "pointer" }}
          onClick={() => {
            setMenuOpen(true);
          }}
        />
      )}
    </Box>
  );

  const handleOnChange = (newValue) => {
    let newInputs = Object.assign({}, inputs);
    newInputs.leadStatus = newValue.label;
    setInputs(newInputs);
    setMenuOpen(false);
  };

  return (
    <div className="box">
      <div className="lead-status-header">
        <div className="text-header">
          <h6>Lead Status</h6>
        </div>
      </div>
      <Select
        value={selectOptions.find((item) => item.label === inputs.leadStatus)}
        options={selectOptions}
        onChange={(item) => handleOnChange(item)}
        isOptionDisabled={(option) => option?.disabled}
        styles={selectStyles}
        menuIsOpen={menuOpen}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <DropdownIndicator />,
        }}
        isSearchable={false}
        menuPortalTarget={document.body}
        ref={selectRef}
      />
      <div className="description-container">
        <textarea
          value={textArea}
          onChange={(e) => setTextArea(e.target.value)}
          name="description"
          placeholder={"Descriptions"}
          disabled={!editMode}
          autoFocus={!editMode}
        />
        <div className="buttons">
          {loading ? (
            <CircularProgress size={18} sx={{ color: "#707070" }} />
          ) : editMode ? (
            <>
              <ClearIcon
                fontSize={"18px"}
                sx={{ color: "#707070", cursor: "pointer" }}
                onClick={() => {
                  setTextArea(data.description);
                  setEditMode(false);
                }}
              />
              <CheckIcon
                fontSize={"18px"}
                sx={{ color: "#707070", cursor: "pointer", marginLeft: ".5em" }}
                onClick={saveDescription}
              />
            </>
          ) : (
            <EditIcon
              fontSize={"18px"}
              className={"icon"}
              onClick={() => {
                setEditMode(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LeadStatus;
