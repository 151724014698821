// export const meetingHours = {
//     Basic: {
//         hours:"Up to 4 hours of meeting",
//         additional:""
//     }
// }

export const PricingText = {
  Basic: [
    {
      title: "Real-time interactions",
      description:
        "Real-time interactions with bluecap. Unlimited on demand note-taking, schedule meeting and more.",
      available: true,
    },
    {
      title: "AI Powered Search",
      description:
        "AI powered personalized search. Discover exactly what you are looking for in milliseconds with assistive intent and context relevance intelligence.",
      available: true,
      extraInfo: "10",
    },
    {
      title: "Meeting Intelligence",
      description:
        "Automate online meeting (Google Meet, Microsoft Teams and Zoom) recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: false,
    },
    {
      title: "Telephony Intelligence",
      description:
        "Automate incoming and outgoing calls, recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: false,
    },
    {
      title: "Intelligent Hybrid Workspace",
      description:
        "On premise - hybrid meetings, conferences, classrooms, concerts and more.",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Meeting Analytics",
      description:
        "Analyze meeting analytics, discover attendance and scheduling trends, participation rate and more. Drive informed engagement delighting participants and driving optimal business outcomes",
      available: false,
    },
    {
      title: "Automated Classification by Entities",
      description:
        "Automate granular context recognition. Augment first party customer data with structured intent and psychographics with NLU entities",
      available: false,
    },
    {
      title: "Relationship Intelligence",
      description:
        "AI powered automated customer data population, CRM with emotional and actionable assistive intelligence",
      available: false,
    },
    {
      title: "Business Intelligence",
      description:
        "Automate products, services, deals risk tracking and  competitive intelligence at scale",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "Help team members maximize their potentiality",
      available: false,
    },
    {
      title: "Custom Data Connectors",
      description:
        "Connect on premise and multi cloud, third party integrations: Salesforce, Zoho, Power BI, Data Studio, Tableau and more",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Scale Cognitive automations",
      description:
        "Trigger alerts, customer emails, surveys, business actions based on emotional intelligence and AI powered events",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "HIPAA Compliance",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
  ],

  Advanced: [
    {
      title: "Real-time interactions",
      description:
        "Real-time interactions with bluecap. Unlimited on demand note-taking, schedule meeting and more.",
      available: true,
    },
    {
      title: "AI Powered Search",
      description:
        "AI powered personalized search. Discover exactly what you are looking for in milliseconds with assistive intent and context relevance intelligence.",
      available: true,
      extraInfo: "1000",
    },
    {
      title: "Meeting Intelligence",
      description:
        "Automate online meeting (Google Meet, Microsoft Teams and Zoom) recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
    },
    {
      title: "Telephony Intelligence",
      description:
        "Automate incoming and outgoing calls, recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: false,
    },
    {
      title: "Intelligent Hybrid Workspace",
      description:
        "On premise - hybrid meetings, conferences, classrooms, concerts and more.",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Meeting Analytics",
      description:
        "Analyze meeting analytics, discover attendance and scheduling trends, participation rate and more. Drive informed engagement delighting participants and driving optimal business outcomes",
      available: true,
    },
    {
      title: "Automated Classification by Entities",
      description:
        "Automate granular context recognition. Augment first party customer data with structured intent and psychographics with NLU entities",
      available: false,
    },
    {
      title: "Relationship Intelligence",
      description:
        "AI powered automated customer data population, CRM with emotional and actionable assistive intelligence",
      available: false,
    },
    {
      title: "Business Intelligence",
      description:
        "Automate products, services, deals risk tracking and  competitive intelligence at scale",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "Help team members maximize their potentiality",
      available: false,
    },
    {
      title: "Custom Data Connectors",
      description:
        "Connect on premise and multi cloud, third party integrations: Salesforce, Zoho, Power BI, Data Studio, Tableau and more",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Scale Cognitive automations",
      description:
        "Trigger alerts, customer emails, surveys, business actions based on emotional intelligence and AI powered events",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "HIPAA Compliance",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
  ],

  Professional: [
    {
      title: "Real-time interactions",
      description:
        "Real-time interactions with bluecap. Unlimited on demand note-taking, schedule meeting and more.",
      available: true,
    },
    {
      title: "AI Powered Search",
      description:
        "AI powered personalized search. Discover exactly what you are looking for in milliseconds with assistive intent and context relevance intelligence.",
      available: true,
      extraInfo: "3000",
    },
    {
      title: "Meeting Intelligence",
      description:
        "Automate online meeting (Google Meet, Microsoft Teams and Zoom) recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
    },
    {
      title: "Telephony Intelligence",
      description:
        "Automate incoming and outgoing calls, recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
      extraInfo: "Coming soon",
    },
    {
      title: "Intelligent Hybrid Workspace",
      description:
        "On premise - hybrid meetings, conferences, classrooms, concerts and more.",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Meeting Analytics",
      description:
        "Analyze meeting analytics, discover attendance and scheduling trends, participation rate and more. Drive informed engagement delighting participants and driving optimal business outcomes",
      available: true,
    },
    {
      title: "Automated Classification by Entities",
      description:
        "Automate granular context recognition. Augment first party customer data with structured intent and psychographics with NLU entities",
      available: true,
    },
    {
      title: "Relationship Intelligence",
      description:
        "AI powered automated customer data population, CRM with emotional and actionable assistive intelligence",
      available: true,
    },
    {
      title: "Business Intelligence",
      description:
        "Automate products, services, deals risk tracking and  competitive intelligence at scale",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "Help team members maximize their potentiality",
      available: false,
    },
    {
      title: "Custom Data Connectors",
      description:
        "Connect on premise and multi cloud, third party integrations: Salesforce, Zoho, Power BI, Data Studio, Tableau and more",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Scale Cognitive automations",
      description:
        "Trigger alerts, customer emails, surveys, business actions based on emotional intelligence and AI powered events",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "HIPAA Compliance",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
  ],

  BusinessOld: [
    {
      title: "Real-time interactions",
      description:
        "Real-time interactions with bluecap. Unlimited on demand note-taking, schedule meeting and more.",
      available: true,
    },
    {
      title: "AI Powered Search",
      description:
        "AI powered personalized search. Discover exactly what you are looking for in milliseconds with assistive intent and context relevance intelligence.",
      available: true,
      extraInfo: "Unlimited",
    },
    {
      title: "Meeting Intelligence",
      description:
        "Automate online meeting (Google Meet, Microsoft Teams and Zoom) recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
    },
    {
      title: "Telephony Intelligence",
      description:
        "Automate incoming and outgoing calls, recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
      extraInfo: "Coming soon",
    },
    {
      title: "Intelligent Hybrid Workspace",
      description:
        "On premise - hybrid meetings, conferences, classrooms, concerts and more.",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Meeting Analytics",
      description:
        "Analyze meeting analytics, discover attendance and scheduling trends, participation rate and more. Drive informed engagement delighting participants and driving optimal business outcomes",
      available: true,
    },
    {
      title: "Automated Classification by Entities",
      description:
        "Automate granular context recognition. Augment first party customer data with structured intent and psychographics with NLU entities",
      available: true,
    },
    {
      title: "Relationship Intelligence",
      description:
        "AI powered automated customer data population, CRM with emotional and actionable assistive intelligence",
      available: true,
    },
    {
      title: "Business Intelligence",
      description:
        "Automate products, services, deals risk tracking and  competitive intelligence at scale",
      available: true,
    },
    {
      title: "Team Analytics",
      description: "Help team members maximize their potentiality",
      available: true,
    },
    {
      title: "Custom Data Connectors",
      description:
        "Connect on premise and multi cloud, third party integrations: Salesforce, Zoho, Power BI, Data Studio, Tableau and more",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Scale Cognitive automations",
      description:
        "Trigger alerts, customer emails, surveys, business actions based on emotional intelligence and AI powered events",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "HIPAA Compliance",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
      onlyForEnterprise: true,
    },
  ],

  Starter: [
    {
      title: "CRM",
      description: "",
      available: true,
    },
    {
      title: "Transcription (Upto 2 hours)",
      description: "",
      available: true,
    },
    {
      title: "Conversational AI (Upto 2 hours)",
      description: "",
      available: true,
    },
    {
      title: "Speech and Culture Analytics (Upto 2 hours)",
      description: "",
      available: true,
    },
    {
      title: "Business Risk and Opportunities AI (Upto 2 hours)",
      description: "",
      available: true,
    },

    {
      title: "Storage* (100GB)",
      description: "",
      available: true,
    },
    {
      title: "HIPAA",
      description: "",
      available: false,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "",
      available: false,
    },
    {
      title: "Data Export",
      description: "",
      available: false,
    },
  ],

  Business: [
    {
      title: "CRM",
      description: "",
      available: true,
    },
    {
      title: "Transcription",
      description: "",
      available: true,
    },
    {
      title: "Conversational AI",
      description: "",
      available: true,
    },
    {
      title: "Speech and Culture Analytics",
      description: "",
      available: true,
    },
    {
      title: "Business Risk and Opportunities AI",
      description: "",
      available: true,
    },

    {
      title: "Storage* (1TB)",
      description: "",
      available: true,
    },
    {
      title: "HIPAA",
      description: "",
      available: false,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "",
      available: true,
    },
    {
      title: "Data Export",
      description: "",
      available: true,
    },
  ],

  Accelerator: [
    {
      title: "CRM",
      description: "",
      available: true,
    },
    {
      title: "Transcription",
      description: "",
      available: true,
    },
    {
      title: "Conversational AI",
      description: "",
      available: true,
    },
    {
      title: "Speech and Culture Analytics",
      description: "",
      available: true,
    },
    {
      title: "Business Risk and Opportunities AI (Pay as you go)",
      description: "",
      available: true,
    },

    {
      title: "Storage* (500GB)",
      description: "",
      available: true,
    },
    {
      title: "HIPAA",
      description: "",
      available: false,
    },
    {
      title: "Private Cloud",
      description: "",
      available: false,
    },
    {
      title: "Team Analytics",
      description: "",
      available: true,
    },
    {
      title: "Data Export",
      description: "",
      available: true,
    },
  ],

  Enterprise: [
    {
      title: "Real-time interactions",
      description:
        "Real-time interactions with bluecap. Unlimited on demand note-taking, schedule meeting and more.",
      available: true,
    },
    {
      title: "AI Powered Search",
      description:
        "AI powered personalized search. Discover exactly what you are looking for in milliseconds with assistive intent and context relevance intelligence.",
      available: true,
      extraInfo: "Unlimited",
    },
    {
      title: "Meeting Intelligence",
      description:
        "Automate online meeting (Google Meet, Microsoft Teams and Zoom) recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
    },
    {
      title: "Telephony Intelligence",
      description:
        "Automate incoming and outgoing calls, recordings, transcription, human-like executive meeting summaries and key takeaways.",
      available: true,
      extraInfo: "Coming soon",
    },
    {
      title: "Intelligent Hybrid Workspace",
      description:
        "On premise - hybrid meetings, conferences, classrooms, concerts and more.",
      available: true,
      extraInfo: "Beta",
      onlyForEnterprise: true,
    },
    {
      title: "Meeting Analytics",
      description:
        "Analyze meeting analytics, discover attendance and scheduling trends, participation rate and more. Drive informed engagement delighting participants and driving optimal business outcomes",
      available: true,
    },
    {
      title: "Automated Classification by Entities",
      description:
        "Automate granular context recognition. Augment first party customer data with structured intent and psychographics with NLU entities",
      available: true,
    },
    {
      title: "Relationship Intelligence",
      description:
        "AI powered automated customer data population, CRM with emotional and actionable assistive intelligence",
      available: true,
    },
    {
      title: "Business Intelligence",
      description:
        "Automate products, services, deals risk tracking and  competitive intelligence at scale",
      available: true,
    },
    {
      title: "Team Analytics",
      description: "Help team members maximize their potentiality",
      available: true,
    },
    {
      title: "Custom Data Connectors",
      description:
        "Connect on premise and multi cloud, third party integrations: Salesforce, Zoho, Power BI, Data Studio, Tableau and more",
      available: true,
      onlyForEnterprise: true,
    },
    {
      title: "Scale Cognitive automations",
      description:
        "Trigger alerts, customer emails, surveys, business actions based on emotional intelligence and AI powered events",
      available: true,
      onlyForEnterprise: true,
    },
    {
      title: "HIPAA Compliance",
      description: "",
      available: true,
      onlyForEnterprise: true,
    },
    {
      title: "Private Cloud",
      description: "",
      available: true,
      onlyForEnterprise: true,
    },
  ],
};
