import React, { useState, useEffect } from "react";
import AnalyticsChartDropdown from "../dropdowns/AnalyticsChartDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Line } from "react-chartjs-2";
import { Box, CircularProgress } from "@material-ui/core";
import { sentimentIcons } from "../../utils/sentimentIcons";

const clockIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5V10L13.3333 11.6667"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function MiniCharts({ type, data, fetchLoading, dateRangeHandler, chartType }) {
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [yAxisHeight, setYAxisHeight] = useState(3);
  const [currentData, setCurrentData] = useState(0);
  const [previousDelta, setPreviousDelta] = useState("");
  const [chartValues, setChartValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);

  const calculatePercantage = (currentCount, previousCount) => {
    let calculatedPercentage = Math.ceil(
      ((currentData - previousCount) / previousCount) * 100
    );
    if (previousCount === 0) {
      calculatedPercentage = 100;
    }
    return `${calculatedPercentage > 0 ? "+" : ""} ${calculatedPercentage}%`;
  };

  const dateFormatter = (date) => {
    //This function return ISO dates to mm/dd/yyyy format.
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const sum = (previous, current) => {
    if (!current) {
      return previous;
    }
    return previous + current;
  };

  const calculateAvarageSentiment = (sentiments) => {
    if (!sentiments || sentiments?.length === 0) {
      return 0;
    }
    const sentimentMeter = (sentiment) => {
      switch (sentiment) {
        case "NEGATIVE_2":
          return 0;
        case "NEGATIVE_1":
          return 25;
        case "NEUTRAL":
          return 50;
        case "POSITIVE_1":
          return 75;
        case "POSITIVE_2":
          return 100;
        default:
          return 0;
      }
    };
    let totalValue = 0;
    sentiments?.map((item) => (totalValue += sentimentMeter(item)));
    return Math.round(totalValue / sentiments?.length);
  };

  const numberToSentiment = (value) => {
    if (!Number(value)) {
      return "";
    }
    if (value < 25) {
      return "Very Negative";
    } else if (value < 50) {
      return "Negative";
    } else if (value < 75) {
      return "Neutral";
    } else if (value < 100) {
      return "Positive";
    } else if (value === 100) {
      return "Very Positive";
    }
    return value;
  };

  const calculateAvarageEngagement = (engagements) => {
    if (!engagements || engagements?.length === 0) {
      return 0;
    }
    let totalEngagement = 0;
    engagements.map((item) => (totalEngagement += item));
    return totalEngagement / engagements?.length;
  };

  const transformData = () => {
    if(!data) {
      return;
    }
    if(data?.length === 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    let currentDataStart =
      new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000;
    let previousDataStart =
      new Date().getTime() - dateRange.value * 2 * 24 * 60 * 60 * 1000;

    let values = [];

    for (let i = 0; i < dateRange.value; i++) {
      const today = new Date();
      let newDate = dateFormatter(new Date(today.setDate(today.getDate() - i)));
      values.unshift({
        date: newDate,
        totalMeetings: 0,
        totalMeetingDuration: 0,
        sentiments: [],
        engagements: [],
        totalPositiveMeetings: 0,
      });
    }

    if (
      type === "All meetings" ||
      type === "Avg. duration" ||
      type === "Avg. sentiment" ||
      type === "Avg. engagement" ||
      type === "Positive meeting rate"
    ) {
      let previousMeetingsCount = 0;

      data?.map((meeting) => {
        let meetingDate = new Date(meeting.startTime * 1000);
        let meetingDateByTime = meetingDate.getTime();
        if (meetingDateByTime > currentDataStart) {
          // Meeting in range
          let meetingDateFormatted = dateFormatter(meetingDate);
          let meetingDay = values.find(
            (item) => item.date === meetingDateFormatted
          );
          if (meetingDay) {
            meetingDay.totalMeetings += 1;
            meetingDay.totalMeetingDuration += meeting.duration;

            // meeting outcome calculation
            if(meeting?.meetingOutcome) {
              meetingDay.totalPositiveMeetings += 1;
            }
            if (chartType === "member") {
              if (meeting?.sentiment?.emotion) {
                meetingDay.sentiments.push(
                  meeting.sentiment.emotion === "MIXED"
                    ? "NEUTRAL"
                    : meeting.sentiment.emotion
                );
              }
              if (meeting?.participation?.participation) {
                if (meeting?.participation?.noShow) {
                  meetingDay.engagements.push(0);
                } else {
                  meetingDay.engagements.push(
                    Number(meeting.participation.participation)
                  );
                }
              }
            } else {
              if (
                meeting?.sentiments?.length > 0 &&
                meeting?.sentiments[0]?.emotion
              ) {
                meetingDay.sentiments.push(
                  meeting.sentiments[0].emotion === "MIXED"
                    ? "NEUTRAL"
                    : meeting.sentiments[0].emotion
                );
              }
              if (
                meeting?.participations?.length > 0 &&
                meeting?.participations[0]?.participation
              ) {
                if (meeting?.participations[0]?.noShow) {
                  meetingDay.engagements.push(0);
                } else {
                  meetingDay.engagements.push(
                    Number(meeting?.participations[0]?.participation)
                  );
                }
              }
            }
          }
        } else if (
          meetingDateByTime < currentDataStart &&
          meetingDateByTime > previousDataStart
        ) {
          // Previous meeting
          previousMeetingsCount += 1;
        }
      });

      const allMeetingsCount = values
        .map((item) => item.totalMeetings)
        .reduce(sum);
      if (type === "All meetings") {
        setCurrentData(allMeetingsCount);
        let delta = calculatePercantage(
          allMeetingsCount,
          previousMeetingsCount
        );
        setPreviousDelta(delta);

        // Chart height fix
        let highestCount = 0;
        values.map((item) =>
          item.totalMeetings > highestCount
            ? (highestCount = item.totalMeetings)
            : null
        );
        if (highestCount >= 3) {
          setYAxisHeight(highestCount * 1.2);
        }
      } else if (type === "Avg. duration") {
        const findDurations = values.filter((item) => item.totalMeetingDuration > 0);
        const durationsArray = findDurations.map(item => item.totalMeetingDuration);
        if(durationsArray?.length !== 0) {
          const sumDurations = durationsArray.reduce(sum);
          const avarageDuration = Math.floor(
              sumDurations / durationsArray?.length
          );
          let hours = Math.floor(avarageDuration / 60 / 60);
          let mins = Math.floor(avarageDuration / 60) - hours*60;
          setCurrentData(
              `${hours > 0 ? hours + "h" : ""} ${mins > 0 ? mins + "m" : ""}`
          );
        } else {
          setCurrentData(`0m`)
        }

        // Chart height fix
        let highestCount = 0;
        values.map((item) =>
          item.totalMeetingDuration / 60 > highestCount
            ? (highestCount = item.totalMeetingDuration / 60)
            : null
        );
        if (highestCount > 3) {
          setYAxisHeight(highestCount * 1.2);
        }
      } else if (type === "Avg. sentiment" || type === "Avg. engagement") {
        setYAxisHeight(110);
        if (type === "Avg. sentiment") {
          let allSentiments = [];
          values.map(
            (item) => (allSentiments = [...allSentiments, ...item.sentiments])
          );
          if(allSentiments?.length === 0) {
            setCurrentData({
              value: `0%`,
              icon: '',
            });
          } else {
            let avarageSentimentCount = calculateAvarageSentiment(allSentiments);
            let icon;
            if (avarageSentimentCount === 100) {
              icon = sentimentIcons.POSITIVE_2;
            } else if (avarageSentimentCount > 74) {
              icon = sentimentIcons.POSITIVE_1;
            } else if (avarageSentimentCount > 49) {
              icon = sentimentIcons.NEUTRAL;
            } else if (avarageSentimentCount > 24) {
              icon = sentimentIcons.NEGATIVE_1;
            } else if (avarageSentimentCount >= 0) {
              icon = sentimentIcons.NEGATIVE_2;
            }

            setCurrentData({
              value: `${avarageSentimentCount}%`,
              icon: icon,
            });
          }
        } else {
          const findEngagements = values.filter((item) => item.engagements > 0);
          let allEngagementScores = [];
          findEngagements.map((meeting) => {
            meeting.engagements?.map((engagementScore) =>
              allEngagementScores.push(engagementScore)
            );
          });
          if (allEngagementScores?.length > 0) {
            setCurrentData(
              (allEngagementScores.reduce(sum) / allEngagementScores?.length).toFixed(2) +
                "%"
            );
          } else {
            setCurrentData("0%");
          }
        }
      }
      setChartValues(values);
      setLoading(false);
      setIsParentDataShouldChange(false);
    } else {
      setChartValues([{ date: "", count: 0 }]);
      setLoading(false);
    }
  };

  useEffect(transformData, [data, isParentDataShouldChange]);

  const chartJSData = {
    labels: chartValues?.map((item) => item.date),
    datasets: [
      {
        id: type,
        data:
          type === "All meetings"
            ? chartValues?.map((item) => item.totalMeetings)
            : type === "Avg. duration"
            ? chartValues?.map((item) =>
                Math.round(item.totalMeetingDuration / 60)
              )
            : type === "Avg. sentiment"
            ? chartValues?.map((item) =>
                calculateAvarageSentiment(item.sentiments)
              )
            : type === "Avg. engagement"
            ? chartValues?.map((item) =>
                calculateAvarageEngagement(item.engagements)
              )
            : type === "Positive meeting rate"
            ? chartValues?.map((item) => item.totalPositiveMeetings)
            : null,
        tension: 0.4,
      },
    ],
  };

  const getGradient = (ctx) => {
    let gradient = ctx.createLinearGradient(0, 0, 0, 60);
    gradient.addColorStop(0, "rgba(44, 115, 255, 0.4)");
    gradient.addColorStop(0.5, "rgba(44, 115, 255, 0.3)");
    gradient.addColorStop(1, "rgba(44, 115, 255, 0)");
    return gradient;
  };

  const options = {
    responsive: true,
    fill: true,
    borderColor: "#1DA1F2",
    backgroundColor: function (context) {
      const chart = context.chart;
      const { ctx, chartArea } = chart;

      if (!chartArea) {
        // This case happens on initial chart load
        return null;
      }
      return getGradient(ctx, chartArea);
    },
    borderWidth: 0.6,
    maintainAspectRatio: false,
    bezierCurve: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        //intersect: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              if (context.dataset.id === "Avg. engagement") {
                label += context.parsed.y + "%";
              } else if (context.dataset.id === "Avg. sentiment") {
                label += numberToSentiment(context.parsed.y);
              } else if (context.dataset.id === "Avg. duration") {
                let hours = Math.floor(context.parsed.y / 60);
                let mins = Math.floor(context.parsed.y);
                if (hours === 0 && mins === 0) {
                  label += "0 m";
                } else {
                  label += `${hours > 0 ? hours + "h" : ""} ${
                    mins > 0 ? mins + "m" : ""
                  }`;
                }
              } else {
                label += context.parsed.y;
              }
            }
            return label;
          },
        },
        position: "average",
        backgroundColor: "#edf3ff",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    scales: {
      y: {
        max: yAxisHeight,
        stacked: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
        radius: 2,
      },
    },
  };

  return (
    <div className="mini-chart">
      <div className="chart-header">
        <h6>{type}</h6>
      </div>

      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ height: "100%" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          {type === "All meetings" ? (
            <div className="chart-counter">
              {currentData}
              <div className="increase">{previousDelta}</div>
            </div>
          ) : type === "Avg. duration" ? (
            <div className="duration-counter">
              {clockIcon}
              <span>{currentData}</span>
            </div>
          ) : type === "Avg. sentiment" ? (
            <div className="sentiment-counter">
              <div>
                <span>{currentData.value}</span>
                {currentData.icon}
              </div>
            </div>
          ) : type === "Avg. engagement" ? (
            <div className="chart-counter engagement">
              <span>{currentData}</span>
            </div>
          ) : null}
          <div>
            <div className="chart-canvas">
              <Line
                className="canvas"
                height={60}
                data={chartJSData}
                options={options}
              />
            </div>
            <AnalyticsChartDropdown
              selected={dateRange}
              setDateRange={(item) => {
                setDateRange(item);
                if (dateRangeHandler(item)) {
                  setLoading(true);
                } else {
                  setIsParentDataShouldChange(true);
                }
              }}
              type={"mini"}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MiniCharts;
