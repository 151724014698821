import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Video from "./video";
import MeetingEntities from "./MeetingEntities";

function VideoAndEntities({
  meetingId,
  setActiveDialog,
  transcriptionData,
  setActiveEntity,
  elasticToken,
  meetingData,
  meetingDuration,
}) {
  const [isVideoOptimized, setIsVideoOptimized] = useState(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoMarks, setVideoMarks] = useState([]);

  const entityToMarks = (
    timestamps,
    entityName,
    sentimentColor,
    sentimentName,
    isTopic
  ) => {
    if (
      !timestamps ||
      timestamps?.length === 0 ||
      meetingDuration === 0 ||
      !isVideoOptimized ||
      videoLoading
    ) {
      return;
    }
    let newVideoMarks = [
      {
        label: entityName,
        value: entityName,
        color: sentimentColor,
        sentiment: sentimentName,
        values: [],
      },
    ];
    let percentage = meetingDuration / 100;
    let meetingStartSecond = meetingData?.botJoinedAt?._seconds
      ? meetingData?.botJoinedAt?._seconds
      : meetingData?.createdAt?.seconds;
    if (!isTopic) {
      timestamps?.map((timestamp) => {
        let currentStart = timestamp?._seconds
          ? ((timestamp?._seconds - meetingStartSecond) / percentage).toFixed(2)
          : null;
        let currentEnd = timestamp?._seconds
          ? ((timestamp?._seconds - meetingStartSecond) / percentage).toFixed(
              2
            ) + 3
          : null; // It will update;
        let startSecond = timestamp?._seconds
          ? timestamp?._seconds - meetingStartSecond
          : null;

        newVideoMarks[0].values.push({
          start: currentStart,
          end: currentEnd,
          startSecond: startSecond,
        });
      });
    } else {
      newVideoMarks[0].values.push({
        start: (timestamps[0]?.start / percentage).toFixed(2) + 3,
        end: (timestamps[0]?.end / percentage).toFixed(2) + 3,
        startSecond: timestamps[0]?.start,
      });
    }

    setVideoMarks(newVideoMarks);
  };

  const onPlayingDialog = (videoDuration) => {
    if (!videoDuration || !transcriptionData?.length) {
      return;
    }
    let timestamps = transcriptionData.map((item) => item.duration);
    let closest = timestamps.reduce((prev, curr) => {
      return Math.abs(curr - videoDuration) < Math.abs(prev - videoDuration)
        ? curr
        : prev;
    });
    let find = transcriptionData.find((item) => item.duration === closest);
    if (find) {
      setActiveDialog(find?.uid);
    }
  };
  return (
    <>
      <Grid item xs={12} md={12}>
        <Video
          videoMarks={videoMarks}
          onPlayingDialog={onPlayingDialog}
          meetingId={meetingId}
          isVideoOptimized={isVideoOptimized}
          setIsVideoOptimized={setIsVideoOptimized}
          loading={videoLoading}
          setLoading={setVideoLoading}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Box
          bgcolor="#fff"
          borderRadius="10px"
          width="100%"
          minHeight="450px"
          maxHeight="450px"
          height="100%"
          overflow="auto"
          p={{ xs: 1, sm: 1, md: 1 }}
          style={{
            boxShadow: "none",
            padding: "20px",
          }}
        >
          <MeetingEntities
            meetingId={meetingId}
            meetingTopics={meetingData}
            entityToMarks={entityToMarks}
            setActiveEntity={setActiveEntity}
            elasticToken={elasticToken}
          />
        </Box>
      </Grid>
    </>
  );
}

export default VideoAndEntities;
