import React, { useState } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import Chip from "@mui/material/Chip";

import { makeStyles } from "@material-ui/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { styled } from "@mui/system";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import LinearProgress from "@mui/material/LinearProgress";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { StyledEngineProvider } from "@mui/material/styles";
import firebase from "./../../firebase/firebase.js";
import { getData, postData } from "./../../utils";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { PricingText } from "./../../utils/pricingText";

import { validateEmail } from "./../teams/validations";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pl: {
    paddingLeft: "20px",
  },
  bg: {
    backgroundColor: "unset",
    boxShadow: "unset",
    fontWeight: "500",
  },
  mb20: {
    paddingTop: "20px",
  },
  radioColor: {
    color: "#2C73FF !important",
  },
  lableFont: {
    fontSize: "18px",
    color: "#000",
    fontWeight: "600",
    marginBottom: "30px",
  },
  priceTxt: {
    color: "#2C73FF",
    fontSize: "22px",
  },
  priceBox: {
    height: "auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    width: "250px",
    boxShadow: "0px 0px 15px 0px #D0D0D0",
    marginTop: "10px",
  },
  freetxt: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
  },
  zeroTxt: {
    color: "#2C73FF",
    fontSize: "22px",
    textAlign: "center",
  },
  detailTxt: {
    fontSize: "12px",
    textAlign: "center",
    padding: "10px",
  },
  colorPrimary: {
    backgroundColor: "#b345d2",
  },
  barColorPrimary: {
    backgroundColor: "green",
  },
}));

const Root = styled("span")(`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #B3C3D3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 10px;
    background-color: #FFF;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 1px 8px rgba(0,0,0,0.25);
  }

  &.${switchUnstyledClasses.checked} { 
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #FFF;
    }

    .${switchUnstyledClasses.track} {
      background: #007FFF;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }`);

function SubscriptionPlans(props) {
  const classes = useStyles();
  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };
  const [progress, setProgress] = React.useState(0);
  const [invoicesList, setInvoicesList] = React.useState([]);
  const [usage, setUsage] = React.useState(null);
  const [currentPeriodEnd, setcurrentPeriodEnd] = React.useState(null);

  const [expanded, setExpanded] = React.useState("panel1");

  const [currentPlan, setCurrentPlan] = useState(false);
  const [confirmDialogStatus, setConfirmDialogStatus] = useState(false);
  const [newPlan, setNewPlan] = useState(null);

  const [billingAccountsList, setBillingAccountsList] = React.useState([]);
  const [inviteeEmailAddress, setInviteeEmailAddress] = React.useState("");
  const [addingInvitee, setAddingInvitee] = React.useState(false);

  function MyFormHelperText() {
    const { focused } = useFormControl() || {};
    let error = false;
    const helperText = React.useMemo(() => {
      if (focused) {
        if (inviteeEmailAddress.length == 0) {
          error = true;
          return "Please add email address and click save!";
        } else {
          if (!validateEmail(inviteeEmailAddress)) {
            error = true;
            return "Please add valid email address!";
          }
        }
        return "Click save to invite user";
      }

      return "Please add email address to invite user in your billing account";
    }, [focused]);

    return <FormHelperText error={error}>{helperText}</FormHelperText>;
  }

  React.useEffect(() => {
    if (props.hasBilling) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async function (jwtToken) {
          let currentPlanResponce = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/payment/getUserPlan`,
            jwtToken
          );
          console.log("currentPlanResponce", currentPlanResponce);
          if (currentPlanResponce.status == "success") {
            //setClientSecret(clientSecretResponce.data.clientSecret)
            setCurrentPlan(currentPlanResponce.data.planType);
          }

          let usageResponce = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/user/getUsage`,
            jwtToken
          );

          if (usageResponce.status == "success") {
            if (usageResponce.data.freeMinutes) {
              if (
                usageResponce.data.usedMinutes > usageResponce.data.freeMinutes
              ) {
                setProgress(
                  (100 * usageResponce.data.freeMinutes) /
                    usageResponce.data.usedMinutes
                );
              } else {
                setProgress(
                  (100 * usageResponce.data.usedMinutes) /
                    usageResponce.data.freeMinutes
                );
              }
              setUsage({
                freeMinutes: usageResponce.data.freeMinutes,
                usedMinutes: usageResponce.data.usedMinutes,
              });
            }
            // usageResponce.data.currentPeriodEnd = 1644252471;
            if (usageResponce.data.currentPeriodEnd) {
              setcurrentPeriodEnd(
                getFormattedTime(usageResponce.data.currentPeriodEnd)
              );
            }
            // else{

            //     usageResponce.data.freeMinutes = 120
            //     usageResponce.data.usedMinutes = 180

            //     if(usageResponce.data.usedMinutes > usageResponce.data.freeMinutes){

            //         setProgress(100*usageResponce.data.freeMinutes/usageResponce.data.usedMinutes);
            //     }else{
            //         setProgress(100*usageResponce.data.usedMinutes/usageResponce.data.freeMinutes);
            //     }
            //     setUsage({freeMinutes:usageResponce.data.freeMinutes, usedMinutes:usageResponce.data.usedMinutes})
            // }
          }

          //listInvoices
          let invoiceListResponce = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/payment/listInvoices`,
            jwtToken
          );

          if (invoiceListResponce.status == "success") {
            if (invoiceListResponce.data.data.length) {
              console.log("set data", invoiceListResponce.data);
              setInvoicesList(invoiceListResponce.data.data);
            }
          }

          //List billing accounts

          let listBillingAccountResponce = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/payment/listBillingAccounts`,
            jwtToken
          );

          if (listBillingAccountResponce.status == "success") {
            setBillingAccountsList(listBillingAccountResponce.data);
          }
        });
    } else {
      window.location.href = "/payment";
    }

    setProgress(75);
    // const timer = setInterval(() => {
    //     setProgress((oldProgress) => {
    //         if (oldProgress === 100) {
    //             return 0;
    //         }
    //         const diff = Math.random() * 10;
    //         return Math.min(oldProgress + diff, 100);
    //     });
    // }, 500);

    // return () => {
    //     clearInterval(timer);
    // };
  }, []);

  const getPrice = (data) => {
    setNewPlan(data.planType);
    setConfirmDialogStatus(true);
    return;

    // console.log(data);

    // firebase.auth().currentUser.getIdToken().then(async function (jwtToken) {

    //     let changePlanResponce = await getData(`${process.env.REACT_APP_BASE_API_URL}/payment/changePlan/${data.planType}`, jwtToken)
    //     console.log("changePlanResponce", changePlanResponce)
    //     if(changePlanResponce.status == "success"){
    //         //setClientSecret(clientSecretResponce.data.clientSecret)
    //         setCurrentPlan(data.planType)
    //     }
    // })
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleConfirmCancel = () => {
    console.log("cancel");
    setConfirmDialogStatus(false);
    setNewPlan(null);
  };

  const handleConfirmOk = () => {
    if (newPlan) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async function (jwtToken) {
          let changePlanResponce = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/payment/changePlan/${newPlan}`,
            jwtToken
          );

          localStorage.removeItem("userApprovalStatusData");
          localStorage.removeItem("userPaidStatusData");

          console.log("changePlanResponce", changePlanResponce);
          if (changePlanResponce.status === "success") {
            //setClientSecret(clientSecretResponce.data.clientSecret)
            setCurrentPlan(newPlan);
            setNewPlan(null);
            setConfirmDialogStatus(false);
          }
        });
    }
  };

  const getFormattedTime = (timestamp) => {
    if (timestamp.toString().length < 11) {
      timestamp = timestamp * 1000;
    }
    let createdAt = new Date(timestamp);
    let month = createdAt.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    let day = createdAt.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    let year = createdAt.getFullYear();

    return month + "/" + day + "/" + year;
  };

  return (
    <div>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={confirmDialogStatus}
      >
        <DialogContent dividers>
          {`Are you sure you want to change plan from ${currentPlan} to ${newPlan}?`}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirmOk}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Grid className={classes.mb20}>
        <FormLabel component="legend" className={classes.lableFont}>
          My Plan
        </FormLabel>
        <StyledEngineProvider injectFirst>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                backgroundColor="#fff"
                borderRadius="10px"
                width="100%"
                padding="0 20px"
              >
                <Box>
                  {currentPlan === "basic" && (
                    <>
                      {" "}
                      <div>Basic</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$0</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                  {currentPlan === "advanced" && (
                    <>
                      {" "}
                      <div>Advanced</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$13</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                  {currentPlan === "professional" && (
                    <>
                      {" "}
                      <div>Professional</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$22</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                  {currentPlan === "businessUnlimited" && (
                    <>
                      {" "}
                      <div>Business</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$100</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                  {currentPlan === "starter" && (
                    <>
                      {" "}
                      <div>Starter</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$0</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                  {currentPlan === "accelerator" && (
                    <>
                      {" "}
                      <div>Accelerator</div>
                      <Box display="flex" alignItems="center">
                        <div className={classes.priceTxt}>$40</div>{" "}
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                    </>
                  )}
                </Box>
                {currentPeriodEnd && (
                  <Box>
                    <div>Active till</div>
                    <div style={{ fontWeight: "600" }}>{currentPeriodEnd}</div>
                  </Box>
                )}
                <Box display="none">
                  <div>Auto renewal</div>
                  <div>
                    <SwitchUnstyled
                      component={Root}
                      {...label}
                      defaultChecked
                    />
                  </div>
                </Box>

                {usage && (
                  <div className="header-credit" style={{ minWidth: "100px" }}>
                    <Box sx={{ fontSize: 14 }}>Usage</Box>
                    {usage.usedMinutes > usage.freeMinutes ? (
                      <Box display="flex" justifyContent="space-between">
                        <p style={{ fontSize: "10px", paddingRight: "10px" }}>
                          Plan hours
                        </p>
                        <p style={{ fontSize: "10px" }}>Exceeded time</p>
                      </Box>
                    ) : (
                      <p style={{ fontSize: "10px" }}>Plan hours</p>
                    )}

                    <span>
                      <LinearProgress
                        classes={
                          usage.freeMinutes < usage.usedMinutes
                            ? {
                                colorPrimary: classes.colorPrimary,
                                barColorPrimary: classes.barColorPrimary,
                              }
                            : { barColorPrimary: classes.barColorPrimary }
                        }
                        variant="determinate"
                        value={progress}
                      />
                    </span>

                    {usage.usedMinutes > usage.freeMinutes ? (
                      <Box
                        style={{ fontSize: "10px" }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <p>100%</p>

                        {Math.round(
                          (Math.round((usage.usedMinutes / 60) * 100) / 100 -
                            Math.round((usage.freeMinutes / 60) * 100) / 100) *
                            100
                        ) /
                          100 >
                        1 ? (
                          <p>
                            {Math.round(
                              (Math.round((usage.usedMinutes / 60) * 100) /
                                100 -
                                Math.round((usage.freeMinutes / 60) * 100) /
                                  100) *
                                100
                            ) / 100}{" "}
                            hrs{" "}
                          </p>
                        ) : (
                          <p>
                            {Math.round(
                              (Math.round((usage.usedMinutes / 60) * 100) /
                                100 -
                                Math.round((usage.freeMinutes / 60) * 100) /
                                  100) *
                                100
                            ) / 100}{" "}
                            hr
                          </p>
                        )}
                      </Box>
                    ) : (
                      <p style={{ fontSize: "10px" }}>
                        {Math.round(
                          ((100 * usage.usedMinutes) / usage.freeMinutes) * 100
                        ) / 100}
                        %
                      </p>
                    )}
                  </div>
                )}
                <Box>
                  <Button variant="contained">Change Plan</Button>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-around"
                  marginBottom="20px"
                >
                  {currentPlan === "basic" && (
                    <Box className={classes.priceBox}>
                      <p className={classes.freetxt}>Basic</p>

                      <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                      >
                        <p className={classes.zeroTxt}>$0</p>
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>

                      <hr
                        style={{
                          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                          margin: "10px 0",
                        }}
                      />
                      {/* <p className={classes.detailTxt}>
                      Maximum of 15 Meetings. Unlimited hours*
                    </p>
                    <p className={classes.detailTxt}>
                      Free Transcripts Google Meet Only
                    </p>
                    <p className={classes.detailTxt}>
                      Unlimited interactions with bluecap during meetings
                    </p> */}

                      <Box
                        display="flex"
                        height={17 / 20}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box>
                          {/* {planFeatures.basic.map((feature) => { */}
                          {PricingText["Basic"]
                            .sort((a, b) => {
                              if (b.available) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .map((feature) => {
                              return (
                                <Box display={"flex"} flexDirection="column">
                                  <Box
                                    width={1}
                                    paddingY="5px"
                                    display="flex"
                                    // alignContent="flex-start"
                                    justifyContent="flex-start"
                                  >
                                    {feature.available ? (
                                      <CheckIcon
                                        style={{ marginRight: "5px" }}
                                        color="success"
                                      />
                                    ) : (
                                      <CloseIcon
                                        style={{ marginRight: "5px" }}
                                        color="error"
                                      />
                                    )}
                                    <p>
                                      {feature.description
                                        ? feature.description
                                        : feature.title
                                        ? feature.description
                                          ? feature.description
                                          : feature.title
                                        : feature.title}
                                    </p>
                                  </Box>
                                  {feature.extraInfo && (
                                    <Box marginLeft={"27px"}>
                                      <Chip
                                        style={{ minWidth: "50px" }}
                                        label={feature.extraInfo}
                                        color="primary"
                                        size="small"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                        </Box>
                        {currentPlan === "basic" && (
                          <Button
                            onClick={(e) => {
                              //getPrice({ planType: 'basic', price: '0' });
                            }}
                            variant="contained"
                            // color="error"
                            fullWidth="true"
                            style={{
                              marginTop: "25px",
                              backgroundColor: "#A9A9A9",
                              cursor: "not-allowed",
                            }}
                          >
                            Current Plan
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}

                  <Box className={classes.priceBox}>
                    <p className={classes.freetxt}>Starter</p>

                    <Box
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <p className={classes.zeroTxt}>$0</p>
                      <p style={{ fontSize: "12px" }}>/user/month</p>
                    </Box>

                    <hr
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        margin: "10px 0",
                      }}
                    />
                    {/* <p className={classes.detailTxt}>
                      Maximum of 15 Meetings. Unlimited hours*
                    </p>
                    <p className={classes.detailTxt}>
                      Free Transcripts Google Meet Only
                    </p>
                    <p className={classes.detailTxt}>
                      Unlimited interactions with bluecap during meetings
                    </p> */}

                    <Box
                      display="flex"
                      height={17 / 20}
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box>
                        {/* {planFeatures.basic.map((feature) => { */}
                        {PricingText["Starter"]
                          .sort((a, b) => {
                            if (b.available) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          .map((feature) => {
                            return (
                              <Box display={"flex"} flexDirection="column">
                                <Box
                                  width={1}
                                  paddingY="5px"
                                  display="flex"
                                  // alignContent="flex-start"
                                  justifyContent="flex-start"
                                >
                                  {feature.available ? (
                                    <CheckIcon
                                      style={{ marginRight: "5px" }}
                                      color="success"
                                    />
                                  ) : (
                                    <CloseIcon
                                      style={{ marginRight: "5px" }}
                                      color="error"
                                    />
                                  )}
                                  <p>
                                    {feature.description
                                      ? feature.description
                                      : feature.title
                                      ? feature.description
                                        ? feature.description
                                        : feature.title
                                      : feature.title}
                                  </p>
                                </Box>
                                {feature.extraInfo && (
                                  <Box marginLeft={"27px"}>
                                    <Chip
                                      style={{ minWidth: "50px" }}
                                      label={feature.extraInfo}
                                      color="primary"
                                      size="small"
                                    />
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                      </Box>
                      {currentPlan === "starter" && (
                        <Button
                          onClick={(e) => {
                            //getPrice({ planType: 'basic', price: '0' });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "#A9A9A9",
                            cursor: "not-allowed",
                          }}
                        >
                          Current Plan
                        </Button>
                      )}
                      {currentPlan !== "starter" && (
                        <Button
                          onClick={(e) => {
                            getPrice({ planType: "starter", price: "0" });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "lightGrey",
                          }}
                        >
                          Downgrade
                        </Button>
                      )}
                    </Box>
                  </Box>

                  {currentPlan === "advanced" && (
                    <Box className={classes.priceBox}>
                      <p className={classes.freetxt}>Advanced</p>

                      <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                      >
                        <p className={classes.zeroTxt}>$13</p>
                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                          margin: "10px 0",
                        }}
                      />

                      <Box
                        display="flex"
                        height={17 / 20}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box>
                          {PricingText["Advanced"]
                            .sort((a, b) => {
                              if (b.available) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .map((feature) => {
                              return (
                                <Box display={"flex"} flexDirection="column">
                                  <Box
                                    width={1}
                                    paddingY="5px"
                                    display="flex"
                                    // alignContent="flex-start"
                                    justifyContent="flex-start"
                                  >
                                    {feature.available ? (
                                      <CheckIcon
                                        style={{ marginRight: "5px" }}
                                        color="success"
                                      />
                                    ) : (
                                      <CloseIcon
                                        style={{ marginRight: "5px" }}
                                        color="error"
                                      />
                                    )}
                                    <p>
                                      {feature.description
                                        ? feature.description
                                        : feature.title}
                                    </p>
                                  </Box>
                                  {feature.extraInfo && (
                                    <Box marginLeft={"27px"}>
                                      <Chip
                                        style={{ minWidth: "50px" }}
                                        label={feature.extraInfo}
                                        color="primary"
                                        size="small"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                        </Box>

                        {currentPlan === "advanced" && (
                          <Button
                            onClick={(e) => {
                              //getPrice({ planType: 'advanced', price: '13' });
                            }}
                            variant="contained"
                            // color="error"
                            fullWidth="true"
                            style={{
                              marginTop: "25px",
                              backgroundColor: "#A9A9A9",
                              cursor: "not-allowed",
                            }}
                          >
                            Current Plan
                          </Button>
                        )}

                        {/* {(currentPlan == "professional" ||
                        currentPlan == "businessUnlimited") && (
                        <Button
                          onClick={(e) => {
                            getPrice({ planType: "advanced", price: "13" });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "lightGrey",
                          }}
                        >
                          Downgrade
                        </Button>
                      )} */}
                      </Box>
                    </Box>
                  )}
                  {currentPlan === "professional" && (
                    <Box className={classes.priceBox}>
                      <p className={classes.freetxt}>Professional</p>

                      <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                      >
                        <p className={classes.zeroTxt}>$22</p>

                        <p style={{ fontSize: "12px" }}>/user/month</p>
                      </Box>
                      <hr
                        style={{
                          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                          margin: "10px 0",
                        }}
                      />

                      <Box
                        display="flex"
                        height={17 / 20}
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box>
                          {PricingText["Professional"]
                            .sort((a, b) => {
                              if (b.available) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .map((feature) => {
                              return (
                                <Box display={"flex"} flexDirection="column">
                                  <Box
                                    width={1}
                                    paddingY="5px"
                                    display="flex"
                                    // alignContent="flex-start"
                                    justifyContent="flex-start"
                                  >
                                    {feature.available ? (
                                      <CheckIcon
                                        style={{ marginRight: "5px" }}
                                        color="success"
                                      />
                                    ) : (
                                      <CloseIcon
                                        style={{ marginRight: "5px" }}
                                        color="error"
                                      />
                                    )}
                                    <p>
                                      {feature.description
                                        ? feature.description
                                        : feature.title}
                                    </p>
                                  </Box>
                                  {feature.extraInfo && (
                                    <Box marginLeft={"27px"}>
                                      <Chip
                                        style={{ minWidth: "50px" }}
                                        label={feature.extraInfo}
                                        color="primary"
                                        size="small"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                        </Box>
                        {currentPlan === "professional" && (
                          <Button
                            onClick={(e) => {
                              //getPrice({ planType: 'professional', price: '22' });
                            }}
                            variant="contained"
                            fullWidth="true"
                            style={{
                              marginTop: "25px",
                              backgroundColor: "#A9A9A9",
                              cursor: "not-allowed",
                            }}
                          >
                            Current Plan
                          </Button>
                        )}

                        {/* {(currentPlan == "advanced" ||
                        currentPlan == "basic") && (
                        <Button
                          onClick={(e) => {
                            getPrice({ planType: "professional", price: "22" });
                          }}
                          variant="contained"
                          fullWidth="true"
                          style={{ marginTop: "25px" }}
                        >
                          Upgrade
                        </Button>
                      )} */}

                        {/* {currentPlan == "businessUnlimited" && (
                        <Button
                          onClick={(e) => {
                            getPrice({ planType: "professional", price: "22" });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "lightGrey",
                          }}
                        >
                          Downgrade
                        </Button>
                      )} */}

                        {/* { (currentPlan == "professional") && <Button
                                            onClick={(e) => {
                                                //getPrice({ planType: 'professional', price: '22' });
                                            }}
                                            variant="contained"
                                            fullWidth="true"
                                            style={{ marginTop: '37px', backgroundColor: '#A9A9A9' }}>
                                            Current Plan
                                        </Button>} */}
                      </Box>
                    </Box>
                  )}
                  <Box className={classes.priceBox}>
                    <p className={classes.freetxt}>Accelerator</p>

                    <Box
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <p className={classes.zeroTxt}>$40</p>
                      <p style={{ fontSize: "12px" }}>/user/month</p>
                    </Box>
                    <hr
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        margin: "10px 0",
                      }}
                    />

                    <Box
                      display="flex"
                      height={17 / 20}
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box>
                        {PricingText["Accelerator"]
                          .sort((a, b) => {
                            if (b.available) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          .map((feature) => {
                            return (
                              <Box display={"flex"} flexDirection="column">
                                <Box
                                  width={1}
                                  paddingY="5px"
                                  display="flex"
                                  // alignContent="flex-start"
                                  justifyContent="flex-start"
                                >
                                  {feature.available ? (
                                    <CheckIcon
                                      style={{ marginRight: "5px" }}
                                      color="success"
                                    />
                                  ) : (
                                    <CloseIcon
                                      style={{ marginRight: "5px" }}
                                      color="error"
                                    />
                                  )}
                                  <p>
                                    {feature.description
                                      ? feature.description
                                      : feature.title
                                      ? feature.description
                                        ? feature.description
                                        : feature.title
                                      : feature.title}
                                  </p>
                                </Box>
                                {feature.extraInfo && (
                                  <Box marginLeft={"27px"}>
                                    <Chip
                                      style={{ minWidth: "50px" }}
                                      label={feature.extraInfo}
                                      color="primary"
                                      size="small"
                                    />
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                      </Box>
                      {currentPlan === "accelerator" && (
                        <Button
                          onClick={(e) => {
                            //getPrice({ planType: 'business', price: '150' });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "#A9A9A9",
                            cursor: "not-allowed",
                          }}
                        >
                          Current Plan
                        </Button>
                      )}

                      {(currentPlan === "advanced" ||
                        currentPlan === "basic" ||
                        currentPlan === "starter" ||
                        currentPlan === "professional") && (
                        <Button
                          onClick={(e) => {
                            getPrice({
                              planType: "accelerator",
                              price: "40",
                            });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{ marginTop: "25px" }}
                        >
                          Upgrade
                        </Button>
                      )}

                      {currentPlan === "businessUnlimited" && (
                        <Button
                          onClick={(e) => {
                            getPrice({ planType: "accelerator", price: "40" });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "lightGrey",
                          }}
                        >
                          Downgrade
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.priceBox}>
                    <p className={classes.freetxt}>Business</p>

                    <Box
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <p className={classes.zeroTxt}>$100</p>
                      <p style={{ fontSize: "12px" }}>/user/month</p>
                    </Box>
                    <hr
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        margin: "10px 0",
                      }}
                    />

                    <Box
                      display="flex"
                      height={17 / 20}
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box>
                        {PricingText["Business"]
                          .sort((a, b) => {
                            if (b.available) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          .map((feature) => {
                            return (
                              <Box display={"flex"} flexDirection="column">
                                <Box
                                  width={1}
                                  paddingY="5px"
                                  display="flex"
                                  // alignContent="flex-start"
                                  justifyContent="flex-start"
                                >
                                  {feature.available ? (
                                    <CheckIcon
                                      style={{ marginRight: "5px" }}
                                      color="success"
                                    />
                                  ) : (
                                    <CloseIcon
                                      style={{ marginRight: "5px" }}
                                      color="error"
                                    />
                                  )}
                                  <p>
                                    {feature.description
                                      ? feature.description
                                      : feature.title
                                      ? feature.description
                                        ? feature.description
                                        : feature.title
                                      : feature.title}
                                  </p>
                                </Box>
                                {feature.extraInfo && (
                                  <Box marginLeft={"27px"}>
                                    <Chip
                                      style={{ minWidth: "50px" }}
                                      label={feature.extraInfo}
                                      color="primary"
                                      size="small"
                                    />
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                      </Box>
                      {currentPlan === "businessUnlimited" ? (
                        <Button
                          onClick={(e) => {
                            //getPrice({ planType: 'business', price: '150' });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{
                            marginTop: "25px",
                            backgroundColor: "#A9A9A9",
                            cursor: "not-allowed",
                          }}
                        >
                          Current Plan
                        </Button>
                      ) : (
                        <Button
                          onClick={(e) => {
                            getPrice({
                              planType: "businessUnlimited",
                              price: "100",
                            });
                          }}
                          variant="contained"
                          // color="error"
                          fullWidth="true"
                          style={{ marginTop: "25px" }}
                        >
                          Upgrade
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.priceBox}>
                    <p className={classes.freetxt}>Enterprise</p>
                    <p className={classes.zeroTxt}>Custom price</p>
                    <hr
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        margin: "10px 0",
                      }}
                    />
                    <p className={classes.detailTxt}>
                      Contact us for custom pricing.
                    </p>
                    <Button
                      onClick={(e) => {
                        //getPrice({ planType: 'enterprice', price: 'custom' });
                      }}
                      variant="contained"
                      color="error"
                      fullWidth="true"
                      style={{ marginTop: "457px" }}
                    >
                      Contact Us
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </StyledEngineProvider>

        <p style={{ padding: "10px", color: "#707070" }}>
          If you have any questions about subscription plans, visit our{" "}
          <a href="/plans">Pricing</a> page
        </p>

        {currentPlan !== "basic" && currentPlan !== "starter" && (
          <Box>
            <Button
              onClick={(e) => {
                getPrice({ planType: "starter", price: "0" });
              }}
              variant="contained"
              // color="grey"
              // fullWidth="true"
              style={{ marginTop: "20px", backgroundColor: "lightGrey" }}
            >
              Cancel Subscription
            </Button>
          </Box>
        )}

        <Box style={{ marginTop: "50px" }}>
          <Box
          // paddingTop={5}
          // style={{ backgroundColor: "#fff", borderRadius: "10px" }}
          >
            {invoicesList.length > 0 ? (
              <Box display="flex">
                <h4>Previous transactions</h4>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width={200}
                  alignItems="center"
                  marginLeft={5}
                  flexDirection="column"
                >
                  {invoicesList.map((inv) => {
                    let createdAt = getFormattedTime(inv.created);
                    return (
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={1}
                      >
                        <p>{createdAt}</p>
                        <Button
                          onClick={() => {
                            window.location.href = inv.invoice_pdf;
                          }}
                          variant="outlined"
                        >
                          Invoice
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              "No invoices found"
            )}
          </Box>
        </Box>

        <Box style={{ marginTop: "50px" }}>
          <Box display="flex" width={700} justifyContent="space-between">
            <Box>
              <h4>Billing Accounts</h4>

              {billingAccountsList.length > 0 && (
                <FormControl>
                  <RadioGroup
                    aria-labelledby="billing-accounts-radio-buttons-group"
                    name="Billing accounts"
                    value={
                      billingAccountsList.filter(
                        (account) => account.selected
                      )[0].id
                    }
                    onChange={async (e) => {
                      let jwtToken = await firebase
                        .auth()
                        .currentUser.getIdToken();

                      let result = await postData(
                        `${process.env.REACT_APP_BASE_API_URL}/payment/setBillingAccount`,
                        jwtToken,
                        { billingAccountId: e.target.value }
                      );

                      setBillingAccountsList((currentAccounts) => {
                        return currentAccounts.map((account) => {
                          if (account.id == e.target.value) {
                            account.selected = true;
                          } else {
                            account.selected = false;
                          }
                          return account;
                        });
                      });

                      props.showSnackbar({
                        show: true,
                        severity: "success",
                        message: "Your billing account updated successfully.",
                      });
                    }}
                  >
                    {billingAccountsList.map((account) => {
                      return (
                        <FormControlLabel
                          value={account.id}
                          control={<Radio />}
                          label={account.name}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
            <Box>
              <h4>Add User</h4>
              <Box
                display="flex"
                marginTop={1}
                width={400}
                justifyContent="space-between"
              >
                <FormControl sx={{ width: "25ch" }}>
                  <OutlinedInput
                    size="small"
                    value={inviteeEmailAddress}
                    onChange={(e) => setInviteeEmailAddress(e.target.value)}
                    placeholder="Please enter email address"
                  />
                  <MyFormHelperText />
                </FormControl>
                <Box>
                  <Box
                    display="flex"
                    width={100}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      onClick={async () => {
                        console.log("clicked button");
                        setAddingInvitee(true);

                        let jwtToken = await firebase
                          .auth()
                          .currentUser.getIdToken();

                        let result = await postData(
                          `${process.env.REACT_APP_BASE_API_URL}/payment/addUserOrganizationToBillingAccount`,
                          jwtToken,
                          {
                            billingAccountId: billingAccountsList.filter(
                              (account) => account.selected
                            )[0].id,
                            email: inviteeEmailAddress,
                          }
                        );
                        setAddingInvitee(false);
                        setInviteeEmailAddress("");
                        props.showSnackbar({
                          show: true,
                          severity: "success",
                          message: "User added successfully.",
                        });
                      }}
                      disabled={!validateEmail(inviteeEmailAddress)}
                      variant="contained"
                    >
                      Save
                    </Button>
                    {addingInvitee && (
                      <CircularProgress marginLeft={1} size="20px" />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid
        className={classes.mb20}
        style={{ marginTop: "40px", display: "none" }}
      >
        <FormLabel component="legend" className={classes.lableFont}>
          My cards
        </FormLabel>

        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          width="100%"
        >
          <img src="images/card1.png" height="30px" />
          <div style={{ padding: "0 30px" }}>
            <div style={{ fontWeight: "bold" }}>Mastercard ending 7831</div>
            <div>Expires 09/24</div>
          </div>
          <Button variant="outlined">Update</Button>
        </Box>
        <Button
          variant="contained"
          style={{ marginTop: "30px", padding: "5px 20px" }}
        >
          + Add card
        </Button>
      </Grid>
    </div>
  );
}

export default SubscriptionPlans;
