import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  storeBusinessTopics,
  storeSentimentSettings,
} from "../../firebase/firestore";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  mb20: {
    paddingTop: "20px",
  },
  lableFont: {
    color: "#000",
    fontWeight: "600",
  },
}));

function SentimentSettings({ organizationDetails }) {
  console.log(organizationDetails, "organization details");
  const dispatch = useDispatch();
  const { showSnackbar } = actions;
  const classes = useStyles();
  const [sentimentSettings, setSentimentSettings] = useState({
    users: organizationDetails?.sentimentSettings?.users
      ? organizationDetails.sentimentSettings.users
      : "all",
    managers: organizationDetails?.sentimentSettings?.managers
      ? organizationDetails.sentimentSettings.managers
      : "all",
    admins: organizationDetails?.sentimentSettings?.admins
      ? organizationDetails.sentimentSettings.admins
      : "all",
  });

  const handleChange = async (settingType, value) => {
    let newSentimentSettings = { ...sentimentSettings };
    newSentimentSettings[settingType] = value;
    let res = await storeSentimentSettings(newSentimentSettings);
    if (res.success === true) {
      dispatch(
        showSnackbar({
          show: true,
          severity: "success",
          message: "Saved.",
        })
      );
      setSentimentSettings(newSentimentSettings);
    } else {
      dispatch(
        showSnackbar({
          show: true,
          severity: "error",
          message: "Something went wrong.",
        })
      );
    }
  };

  return (
    <>
      <Grid container style={{ marginTop: "20px" }} className={classes.mb20}>
        <Grid item xs={5}>
          <FormLabel component="legend" className={classes.lableFont}>
            Report sentiment to users
          </FormLabel>
        </Grid>
        <Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="all"
                control={
                  <Radio onChange={() => handleChange("users", "all")} />
                }
                checked={sentimentSettings?.users === "all"}
                label="All"
              />
              <FormControlLabel
                value="userOnly"
                control={
                  <Radio onChange={() => handleChange("users", "userOnly")} />
                }
                checked={sentimentSettings?.users === "userOnly"}
                label="User only"
              />
              <FormControlLabel
                value="aggregated"
                disabled
                control={<Radio />}
                label="Aggregated (Coming soon)"
              />
              <FormControlLabel
                value="externalParticipants"
                disabled
                control={<Radio />}
                label="External participants (Coming soon)"
              />
              <FormControlLabel
                value="off"
                control={
                  <Radio onChange={() => handleChange("users", "off")} />
                }
                checked={sentimentSettings?.users === "off"}
                label="Off"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
      <Grid container style={{ marginTop: "20px" }} className={classes.mb20}>
        <Grid item xs={5}>
          <FormLabel component="legend" className={classes.lableFont}>
            Report sentiment to managers
          </FormLabel>
        </Grid>
        <Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="all"
                control={
                  <Radio onChange={() => handleChange("managers", "all")} />
                }
                checked={sentimentSettings?.managers === "all"}
                label="All"
              />
              <FormControlLabel
                value="aggregated"
                disabled
                control={<Radio />}
                label="Aggregated (Coming soon)"
              />
              <FormControlLabel
                value="off"
                control={
                  <Radio onChange={() => handleChange("managers", "off")} />
                }
                label="Off"
                checked={sentimentSettings?.managers === "off"}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
      <Grid container style={{ marginTop: "20px" }} className={classes.mb20}>
        <Grid item xs={5}>
          <FormLabel component="legend" className={classes.lableFont}>
            Report sentiment to admins
          </FormLabel>
        </Grid>
        <Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="all"
                control={
                  <Radio onChange={() => handleChange("admins", "all")} />
                }
                label="All"
                checked={sentimentSettings?.admins === "all"}
              />
              <FormControlLabel
                value="aggregated"
                disabled
                control={<Radio />}
                label="Aggregated (Coming soon)"
              />
              <FormControlLabel
                value="off"
                control={
                  <Radio onChange={() => handleChange("admins", "off")} />
                }
                label="Off"
                checked={sentimentSettings?.admins === "off"}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </>
  );
}

export default SentimentSettings;
