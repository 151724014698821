import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import EditShowComment from "./EditShowComment";

function Comments({
  commentsLoading,
  comments,
  setComments,
  attachments,
  setAttachments,
  suggestions,
  updateTaskCommentsAndAttachmentsCount,
}) {
  return (
    <Box width={4 / 6} height={"auto"} paddingTop={2} paddingRight={2}>
      {commentsLoading ? (
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size={"18px"} sx={{ color: "#2C73FF" }} />
        </Box>
      ) : (
        comments?.map((comment) => (
          <EditShowComment
            comment={comment}
            comments={comments}
            setComments={setComments}
            attachments={attachments}
            setAttachments={setAttachments}
            key={comment.id}
            suggestions={suggestions}
            updateTaskCommentsAndAttachmentsCount={
              updateTaskCommentsAndAttachmentsCount
            }
          />
        ))
      )}
    </Box>
  );
}

export default Comments;
