import React, { useState, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
// import "../../Tasks/ElasticAutocomplete.css";
import "./ElasticGlobalSearch.css";

import OutlinedInput from "@mui/material/OutlinedInput";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@material-ui/styles";

import { getElasticToken } from "../../../firebase/firestore";

import Meeting from "./Meeting";
import TranscriptsByMeeting from "./TranscriptsByMeeting";
import Contact from "./Contact";
import Filter from "./Filter";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    width: "50%",
    transition: "width 0.3s",
    "&:hover": {
      width: "100%",
    },
  },
}));

export default function GlobalElasticSearch() {
  // const classes = useStyles();
  const elasticHost = process.env.REACT_APP_ELASTIC_HOST
    ? process.env.REACT_APP_ELASTIC_HOST
    : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

  let [searchQuery, setSearchQuery] = useState("");
  let [searchApiKey, setSearchApiKey] = useState(null);
  let [suggestions, setSuggestions] = useState([]);
  let [showSuggestions, setShowSuggestions] = useState(false);
  let [filterStatus, setFilterStatus] = useState(false);

  let filter = useRef(null);

  let onSuggestionsFetchRequestedDebounce = useRef(() => console.log("test"));

  let searchInput = useRef(null);

  useEffect(() => {
    const fetchSearchApiKey = async () => {
      let apiKey = await getElasticToken();
      console.log("apikey", apiKey);
      setSearchApiKey(apiKey);

      let fn = getFn(apiKey, filter);
      onSuggestionsFetchRequestedDebounce.current = debounce(500, fn);
    };

    fetchSearchApiKey();
  }, []);

  const getFn = (searchApiKey, filter) => {
    console.log("filter==>", filter);
    return ({ value }) => {
      console.log("ff2==>", filter);

      let queryObject = {
        query: {
          query_string: {
            query: `*${value}*`,
          },
        },
      };

      if (filter.current) {
        let tmpFilterArr = [];
        let range = false;
        let channels = false;

        if (
          filter.current.selectedEndTime ||
          filter.current.selectedStartTime
        ) {
          range = { "createdAt._seconds": {} };

          if (filter.current.selectedEndTime) {
            range["createdAt._seconds"]["lte"] = filter.current.selectedEndTime;
          }
          if (filter.current.selectedStartTime) {
            range["createdAt._seconds"]["gte"] =
              filter.current.selectedStartTime;
          }
        }

        if (filter.current.channelType) {
          channels = { bool: { should: [] } };

          filter.current.channelType.forEach((channel) => {
            channels["bool"]["should"].push({
              term: {
                "channelType.keyword": channel,
              },
            });
          });
        }

        if (range) {
          tmpFilterArr.push({ range });
        }

        if (channels) {
          tmpFilterArr.push(channels);
        }
        queryObject = {
          query: {
            bool: {
              must: {
                multi_match: {
                  query: value,
                },
              },
              filter: tmpFilterArr,
            },
          },
        };
      }

      axios
        .post(
          `${elasticHost}/_search`,

          queryObject,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `ApiKey ${searchApiKey}`,
            },
          }
        )
        .then((res) => {
          const results = res.data.hits.hits.map((h) => {
            h._source._index = h._index;

            if (!h._source.objectID) {
              h._source.objectID = h._id;
            }
            return h._source;
          });
          console.log("results", results);

          let meetings = results.filter((item) => item._index === "meetings");
          let transcripts = results.filter(
            (item) => item._index === "transcripts"
          );
          let leads = results.filter((item) => item._index === "leads");
          let contacts = results.filter((item) => item._index === "contacts");

          let groupedResults = [];
          if (meetings.length) {
            groupedResults.push({ meetings });
          }

          if (transcripts.length) {
            // group transcripts by meeting
            let groupByMeetingId = {},
              meetingIDArr = [],
              tempArr = [];

            transcripts.map((hit) => {
              let objectID = hit.objectID ? hit.objectID : hit._id;
              if (groupByMeetingId[objectID]) {
                groupByMeetingId[objectID].push(hit);
              } else {
                groupByMeetingId[objectID] = [];
                groupByMeetingId[objectID].push(hit);
              }
              return "";
            });

            tempArr = transcripts.map((h) => {
              let objectID = h.objectID ? h.objectID : h._id;
              if (meetingIDArr.includes(objectID)) {
                h.skip = true;
              } else {
                h.skip = false;
                h.groupByMeetingId = groupByMeetingId[objectID];
                meetingIDArr.push(h._id);
              }

              return h;
            });

            let transcriptsByMeeting = tempArr.filter((h) => !h.skip);

            //
            groupedResults.push({ transcripts: transcriptsByMeeting });
          }

          if (leads.length) {
            groupedResults.push({ leads });
          }

          if (contacts.length) {
            groupedResults.push({ contacts });
          }
          console.log("groupedResults", groupedResults);
          if (groupedResults.length) {
            setSuggestions(groupedResults);
          } else {
            setSuggestions([{ no_data: true }]);
          }
        });
    };
  };

  const onChange = (event, { newValue }) => {
    console.log(event);
    setSearchQuery(newValue);
  };

  const showFilter = (event) => {
    !filterStatus ? setShowSuggestions(false) : setShowSuggestions(true);
    setFilterStatus(!filterStatus);
  };
  const renderSuggestion = (suggestion) => {
    if (suggestion.meetings && suggestion.meetings.length) {
      return (
        <Box>
          <Box py={1}>
            <h4>Meetings/Calls</h4>
          </Box>
          {suggestion.meetings.map((meeting, key) => {
            return (
              <Box key={key}>
                <Meeting
                  meeting={meeting}
                  setShowSuggestions={setShowSuggestions}
                  query={searchQuery}
                  searchInput={searchInput}
                />
              </Box>
            );
          })}
        </Box>
      );
    }

    if (suggestion.transcripts && suggestion.transcripts.length) {
      return (
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Box py={1}>
            <h4>Transcripts</h4>
          </Box>
          {/* {suggestion.transcripts.map((transcript) => {
            return (
              <Box>{transcript.transcript}</Box>
            );
          })} */}
          <TranscriptsByMeeting
            transcript={suggestion.transcripts}
            query={searchQuery}
            setShowSuggestions={setShowSuggestions}
            searchInput={searchInput}
          />
        </Box>
      );
    }
    if (suggestion.leads && suggestion.leads.length) {
      return (
        <Box>
          <Box py={1}>
            <h4>Leads</h4>
          </Box>
          {suggestion.leads.map((lead) => {
            return (
              <a
                style={{ textDecoration: "none" }}
                className="contact-link"
                href={`/crm/lead/${lead.objectID}`}
              >
                <Box display={"flex"} p={1}>
                  <Avatar
                    src={lead?.avatar}
                    style={{
                      height: 20,
                      width: 20,
                      border: "1px solid #2c73ff",
                      marginRight: "5px",
                    }}
                  />
                  <p>
                    {lead.firstName
                      ? lead.firstName + " " + lead.lastName
                      : lead.lastName}
                  </p>
                </Box>
              </a>
            );
          })}
        </Box>
      );
    }
    if (suggestion.contacts && suggestion.contacts.length) {
      return (
        <Box>
          <Box py={1}>
            <h4>Contacts</h4>
          </Box>
          {suggestion.contacts.map((contact) => {
            return (
              <Contact
                setShowSuggestions={setShowSuggestions}
                searchInput={searchInput}
                contact={contact}
                query={searchQuery}
              />
            );
          })}
        </Box>
      );
    }

    if (suggestion.no_data) {
      return <p>No results found!</p>;
    }
  };

  const shouldRenderSuggestions = (value, reason) => {
    return showSuggestions && !filterStatus && value.length > 1;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    id: "global-search-input",
    placeholder: "Search...",
    value: searchQuery,
    onChange,
    onFocus: () => {
      setShowSuggestions(true);
    },
    onBlur: (e) => {
      // console.log("blur", e);
      // setShowSuggestions(false);
    },
  };

  const renderInputComponent = (inputProps) => (
    <Box>
      <Box display={"flex"} justifyContent="center">
        <Box
          width={showSuggestions || filterStatus ? "100%" : "50%"}
          style={{ transition: "width 0.3s" }}
        >
          {/* {showSuggestions  ? <p>true</p> : <p>false</p>} */}
          <OutlinedInput
            type="text"
            size="small"
            {...inputProps}
            ref={searchInput}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    if (showSuggestions) {
                      setShowSuggestions(false);
                      setSearchQuery("");
                      setFilterStatus(false);
                    } else {
                      setShowSuggestions(true);
                    }
                  }}
                >
                  {showSuggestions ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
              </InputAdornment>
            }
            startAdornment={
              showSuggestions || filterStatus ? (
                <InputAdornment position="start">
                  <IconButton edge="start" onClick={showFilter}>
                    <Box
                      style={{ cursor: "pointer" }}
                      variant="contained"
                      display="flex"
                      alignItems="center"
                      alignContent={"center"}
                    >
                      <img alt="filter" src="/icons/filter.svg"></img>
                    </Box>
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          />
        </Box>
      </Box>

      <Filter
        filterStatus={filterStatus}
        filter={filter}
        setShowSuggestions={setShowSuggestions}
        setFilterStatus={setFilterStatus}
        searchInput={searchInput}
      />
    </Box>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      // <Box height={700} overflow="scroll" zIndex={"modal"}>
      <Box
        // width={showSuggestions ? "100%" : "50%"}
        // style={{ transition: "width 0.3s" }}
        {...containerProps}
      >
        {children}
        {/* <div>
          Press Enter to search <strong>{query}</strong>
        </div> */}
      </Box>
      // </Box>
    );
  };

  if (!searchApiKey) {
    return null;
  }
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounce.current}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => searchQuery}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
      focusInputOnSuggestionClick={false}
    />
  );
}
