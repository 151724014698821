import { Post } from "./axiosUtils";
import moment from "moment";

export const generateSentimentPieCsv = async (contactData) => {
  try {
    if (!Object.values(contactData)?.length) {
      throw "No data found";
    }

    let csvData = [
      [
        "Contact name",
        "Date/Time",
        "Title",
        "Engagement score",
        "Sentiment",
        "Participants",
      ],
    ];

    for await (const [index, contact] of Object.values(contactData).entries()) {
      const fetchMeetings = await Post("meeting/meetingDataByIds", {
        meetingIds: contact?.map((item) => item.meetingId),
      });
      if (fetchMeetings.status !== 200 || !fetchMeetings?.data?.data?.length) {
        throw "Server error. Please try again later";
      }
      const meetingsData = fetchMeetings.data.data;
      meetingsData.map((meeting) => {
        const allEngagements = [];
        const allSentiments = [];
        meeting?.participants?.map((participant) => {
          if (participant?.participation) {
            if (participant.participation?.noShow) {
              allEngagements.push(0);
            } else {
              allEngagements.push(
                Number(participant.participation.participation)
              );
            }
          }
          if (participant?.sentiment?.emotion) {
            if (participant.sentiment.emotion === "POSITIVE_2") {
              allSentiments.push(100);
            } else if (participant.sentiment.emotion === "POSITIVE_1") {
              allSentiments.push(75);
            } else if (
              participant.sentiment.emotion === "NEUTRAL" ||
              participant.sentiment.emotion === "MIXED"
            ) {
              allSentiments.push(50);
            } else if (participant.sentiment.emotion === "NEGATIVE_1") {
              allSentiments.push(25);
            } else if (participant.sentiment.emotion === "NEGATIVE_2") {
              allSentiments.push(0);
            }
          }
        });
        const sum = (previous, current) => {
          if (!current) {
            return previous;
          }
          return previous + current;
        };
        const calculatedEngagementScore =
          allEngagements?.length > 0
            ? Math.round(allEngagements.reduce(sum) / allEngagements.length)
            : "";
        const calculatedSentimentScore =
          allSentiments?.length > 0
            ? Math.round(allSentiments.reduce(sum) / allSentiments.length)
            : "";

        const sentimentScoreToString = (sentimentScore) => {
          if (sentimentScore < 24) {
            return "Very Negative";
          } else if (sentimentScore < 49) {
            return "Negative";
          } else if (sentimentScore < 74) {
            return "Neutral";
          } else if (sentimentScore < 100) {
            return "Positive";
          } else if (sentimentScore === 100) {
            return "Very Positive";
          }
        };

        csvData.push([
          Object.keys(contactData)[index],
          meeting?.startTime ? moment(meeting.startTime).format("lll") : "",
          meeting?.title,
          calculatedEngagementScore > 0 ? calculatedEngagementScore + "%" : "",
          sentimentScoreToString(calculatedSentimentScore),
          meeting?.participants?.map((item) => item.name).join(", "),
        ]);
      });
    }
    return {
      status: true,
      csvData,
    };
  } catch (error) {
    return {
      status: false,
      error,
    };
  }
};
