import React, { useState, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
import "./ElasticAutocomplete.css";

const Lead = ({ lead, setLeadSelected, setQuery, setRelatedTo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={"30px"}
      width={1}
      onClick={(event) => {
        let fullName = lead.firstName
          ? lead.firstName + " " + lead.lastName
          : lead.lastName;
        event.stopPropagation();
        setQuery(fullName);
        // setIsOpen(false);
        setLeadSelected(true);
        setRelatedTo({
          lastName: fullName,
          leadId: lead.objectID,
          type: "lead",
        });
      }}
    >
      <Avatar
        src={lead?.avatar}
        style={{
          height: 20,
          width: 20,
          border: "1px solid #2c73ff",
          marginRight: "5px",
        }}
      />
      <p>
        {lead.firstName ? lead.firstName + " " + lead.lastName : lead.lastName}
      </p>
    </Box>
  );
};

export default function ElasticLeadAutocomplete(props) {
  const { apiKey, setRelatedTo, placeholder, defaultValue } = props;
  console.log("apiKey==>", apiKey, defaultValue);
  const elasticHost = process.env.REACT_APP_ELASTIC_HOST
    ? process.env.REACT_APP_ELASTIC_HOST
    : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

  let [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  let [suggestions, setSuggestions] = useState([]);
  let [leadSelected, setLeadSelected] = useState(false);

  const onChange = (event, { newValue }) => {
    setValue(newValue);

    if (leadSelected) setLeadSelected(false);
  };

  const renderSuggestion = (suggestion) => {
    console.log(suggestion);
    return (
      <Lead
        lead={suggestion}
        setQuery={setValue}
        setLeadSelected={setLeadSelected}
        setRelatedTo={setRelatedTo}
      />
    );
  };

  const shouldRenderSuggestions = (value, reason) => {
    return !leadSelected && value.length > 1;
  };

  let onSuggestionsFetchRequested = ({ value }) => {
    axios
      .post(
        `${elasticHost}/leads/_search`,

        {
          query: {
            multi_match: {
              query: value,
              type: "bool_prefix",
              fields: ["fullName", "fullName._2gram", "fullName._3gram"],
            },
          },
          // sort: ["_score", { createdDate: "desc" }]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${apiKey}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.hits.hits.map((h) => h._source);
        console.log("results", results);
        setSuggestions(results);
      });
  };
  let onSuggestionsFetchRequestedDebounce = useRef(
    debounce(500, onSuggestionsFetchRequested)
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: placeholder ? placeholder : "Select leads",
    value,
    onChange,
    className: `react-autosuggest__input ${
      props?.className ? props?.className : ""
    }`,
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      {/* <div>custom stuff</div> */}
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        {/* <div>
          Press Enter to search <strong>{query}</strong>
        </div> */}
      </div>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounce.current}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.fullName}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
    />
  );
}
