import React from "react";
import { Avatar } from "@material-ui/core";

function CreateEventParticipantSuggestions({ suggestions, onSelect }) {
  return (
    <>
      {suggestions?.length ? (
        <div className="create-event-suggest-container">
          {suggestions.map((item, index) => (
            <div
              key={index}
              className="suggest-item"
              onClick={() => onSelect(item.email)}
            >
              <Avatar
                src={item?.avatar}
                sx={{
                  height: 24,
                  width: 24,
                  mr: 1,
                  border: "1px solid #2c73ff",
                }}
              />
              <div>
                <h6>{item.fullName}</h6>
                <span>{item.email}</span>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

export default CreateEventParticipantSuggestions;
