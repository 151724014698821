import React, { useState, useEffect, useRef } from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

function TabularColumnsMenu({ columns, activeColumns, setActiveColumns }) {
  const menuRef = useRef(null);
  const [menuStatus, setMenuStatus] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredColumns, setFilteredColumns] = useState([]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuStatus(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleFilter = () => {
    if (!filterText?.length) {
      setFilteredColumns(columns);
    } else {
      setFilteredColumns(
        columns.filter((column) =>
          column.headerName.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    }
  };

  useEffect(handleFilter, [filterText, columns]);

  const handleMenuStatus = () => {
    setMenuStatus(!menuStatus);
  };

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleSwitch = (e, fieldName) => {
    if (e.target.checked) {
      setActiveColumns([...activeColumns, fieldName]);
    } else {
      setActiveColumns(activeColumns.filter((item) => item !== fieldName));
    }
    setTimeout(() => {
      setFilterText("");
    }, 500);
  };

  const handleSelectAll = () => {
    setActiveColumns(columns.map((item) => item.field));
  };

  const handleHideAll = () => {
    setActiveColumns([]);
  };

  return (
    <div className="filter-icon-container" ref={menuRef}>
      <div
        className="lead-tabular-toolbar-icon filter-icon"
        onClick={handleMenuStatus}
      >
        <ViewColumnIcon sx={{ fontSize: 18, color: "#2c73ff", mr: 1 }} />
        Columns
      </div>
      {menuStatus && (
        <div className="filter-menu columns">
          <div style={{ padding: "1.5em 0.5em 0.5em 0.5em" }}>
            <TextField
              id="standard-required"
              label="Filter column"
              value={filterText}
              placeholder="Column title"
              variant="standard"
              onChange={handleFilterTextChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              InputProps={{
                style: { fontSize: 14 },
              }}
            />
          </div>
          <div className="column-switch-container">
            {filteredColumns.map((item, index) => (
              <div className="column-switch">
                <Switch
                  key={item.field + index}
                  value={activeColumns.includes(item.field)}
                  onChange={(e) => handleSwitch(e, item.field)}
                  checked={activeColumns.includes(item.field) ? true : false}
                  size={"small"}
                />
                {item.headerName}
              </div>
            ))}
          </div>
          <div className="column-filter-buttons">
            <span onClick={handleHideAll}>Hide all</span>
            <span onClick={handleSelectAll}>Show all</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default TabularColumnsMenu;
