import React, { useCallback, useMemo, useRef, useState } from "react";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";
import editorStyles from "../../assets/css/editorStyles.module.css";
import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons";
import createToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/static-toolbar";
import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";
import alignmentStyles from "../../assets/css/alignmentStyles.module.css";
import { Box, CircularProgress } from "@material-ui/core";
import createImagePlugin from "@draft-js-plugins/image";
import { useHistory } from "react-router";
import CreateEvent from "../dashboard/CreateEvent";
import HoverUserInfo from "./HoverUserInfo";

function TextEditor({
  editorState,
  onEditorStateChange,
  handlePastedFiles,
  mentions,
  toolbarHidden,
  readOnly,
  isBorder,
  className,
  isButtons,
  handleButton,
  buttonLoading,
  buttonTitle,
  handleCancelButton,
  cancelButtonTitle,
  onKeyPress,
  placeholder,
}) {
  const history = useHistory();
  const editorRef = useRef(null);
  const [openMention, setOpenMention] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const [showCreateEvent, setShowCreateEvent] = useState({
    status: false,
    title: "",
    email: "",
  });

  const { plugins, Toolbar, MentionSuggestions, textAlignmentPlugin } =
    useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        mentionPrefix: "@",
        mentionComponent(mentionProps) {
          return (
            <HoverUserInfo
              avatar={mentionProps?.mention?.avatar}
              name={mentionProps?.mention?.name}
              email={mentionProps?.mention?.email}
              button={true}
              buttonTitle={"Open Analytics"}
              buttonAction={() => {
                mentionProps?.mention?.id &&
                  history.push(`/teams/${mentionProps.mention.id}`);
              }}
              disableEventIcon={false}
              eventIconAction={() => {
                setShowCreateEvent({
                  status: true,
                  email: mentionProps?.mention?.email,
                  title: `Meeting with ${mentionProps?.mention?.name}`,
                });
              }}
            >
              <span className={mentionProps.className}>
                {mentionProps.children}
              </span>
            </HoverUserInfo>
          );
        },
      });
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      const imagePlugin = createImagePlugin();
      // eslint-disable-next-line no-shadow
      const textAlignmentPlugin = createTextAlignmentPlugin({
        theme: { alignmentStyles },
      });
      const staticToolbarPlugin = createToolbarPlugin();
      const { Toolbar } = staticToolbarPlugin;
      const plugins = [
        staticToolbarPlugin,
        mentionPlugin,
        textAlignmentPlugin,
        imagePlugin,
      ];
      return { plugins, Toolbar, MentionSuggestions, textAlignmentPlugin };
    }, [mentions]);

  const onOpenChange = useCallback((_open) => {
    setOpenMention(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  return (
    <>
      {showCreateEvent.status && (
        <CreateEvent
          onClose={() =>
            setShowCreateEvent({
              status: false,
              email: "",
              title: "",
            })
          }
          email={showCreateEvent.email}
          open={showCreateEvent.status}
          title={showCreateEvent.title}
        />
      )}
      <div
        className={`${editorStyles.editor} ${isBorder ? "editor-border" : ""} ${
          toolbarHidden ? "toolbar-hidden" : ""
        } ${className ? className : ""}`}
        onClick={() => {
          editorRef.current?.focus();
        }}
      >
        {!toolbarHidden && (
          <Toolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <div className="toolbar-buttons">
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <CodeButton {...externalProps} />
                  <Separator {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                  <CodeBlockButton {...externalProps} />
                  <textAlignmentPlugin.TextAlignment {...externalProps} />
                </div>
              )
            }
          </Toolbar>
        )}
        <Editor
          ref={editorRef}
          placeholder={placeholder || ""}
          handlePastedFiles={handlePastedFiles}
          editorState={editorState}
          onChange={onEditorStateChange}
          plugins={plugins}
          readOnly={readOnly}
          keyBindingFn={onKeyPress}
        />
        <MentionSuggestions
          open={openMention}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {}}
        />
      </div>
      {!toolbarHidden && isButtons && (
        <Box sx={{ my: 1 }}>
          <button className="modal-button blue" onClick={handleButton}>
            {buttonLoading ? (
              <CircularProgress size="15px" style={{ color: "#fff" }} />
            ) : (
              <span>{buttonTitle}</span>
            )}
          </button>
          <button
            className="modal-button white ml"
            onClick={handleCancelButton}
          >
            <span>{cancelButtonTitle}</span>
          </button>
        </Box>
      )}
    </>
  );
}

export default TextEditor;
