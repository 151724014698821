import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@material-ui/core/Avatar";

import {
  GetDeal,
  GetDeals,
  getUserByUserId,
} from "./../../firebase/firestore.js";

import AddDeal from "../Deals/AddDeal";
import ViewDeal from "../Deals/ViewDeal";

function Deals({ contactId, lastName, accountId, type }) {
  const [allDeals, setAllDeals] = useState([]);
  const [addDealDialogStatus, setAddDealDialogStatus] = useState(false);
  const [viewDealDialogStatus, setViewDealDialogStatus] = useState({
    status: false,
    dealId: "",
  });

  useEffect(() => {
    const loadDeals = async () => {
      let allDeals = await GetDeals();
      setAllDeals(allDeals);
    };
    loadDeals();
    // DeleteTasks();
  }, []);

  const handleCloseAddDealDialog = () => {
    setAddDealDialogStatus(false);
  };

  const openAddDealDialog = () => {
    setAddDealDialogStatus(true);
  };

  const dateFormatter = (date) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "," +
      date.getFullYear()
    );
  };

  const addDealInList = async (deal) => {
    let newDeal = await GetDeal(deal.id);
    let tmpDeals = [...allDeals];
    tmpDeals.push(newDeal.deal);
    setAllDeals(tmpDeals);
  };

  const getFormattedValue = (value) => {
    let formattedValue = parseInt(value).toLocaleString("en-US");
    return formattedValue;
  };

  return (
    <Box mt={2}>
      {addDealDialogStatus && (
        <AddDeal
          addDealDialogStatus={addDealDialogStatus}
          handleCloseAddDealDialog={handleCloseAddDealDialog}
          addDealInList={addDealInList}
          relatedToEntity={type}
          relatedTo={
            type === "contact"
              ? {
                  lastName,
                  contactId,
                  type,
                }
              : {
                  lastName,
                  accountId,
                  type,
                }
          }
        />
      )}
      {viewDealDialogStatus?.status && viewDealDialogStatus?.dealId && (
        <ViewDeal
          viewDealDialogStatus={viewDealDialogStatus?.status}
          handleCloseViewDealDialog={() =>
            setViewDealDialogStatus({ status: false, dealId: "" })
          }
          dealId={viewDealDialogStatus?.dealId}
        />
      )}
      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
        <h5 className="agenda">Deals</h5>
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          onClick={openAddDealDialog}
        />
      </Box>
      <div className="shadow-container">
        <div className="details-box notes">
          <table className="tasks-table">
            <thead>
              <tr>
                <th style={{ width: "35%" }}>Title</th>
                <th>Expected Closing Date</th>
                <th style={{ width: "35%" }}>Value (CA$)</th>
              </tr>
            </thead>
            <tbody>
              {allDeals
                .filter((deal) => {
                  if (type === "contact") {
                    return (
                      deal.relatedTo &&
                      deal.relatedTo.type === "contact" &&
                      deal.relatedTo.contactId === contactId
                    );
                  } else if (type === "account") {
                    return (
                      deal.relatedTo &&
                      deal.relatedTo.type === "account" &&
                      deal.relatedTo.accountId === accountId
                    );
                  }
                })
                .map((deal, index) => (
                  <tr key={index}>
                    <td
                      onClick={() =>
                        setViewDealDialogStatus({
                          status: true,
                          dealId: deal?.id,
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <h6 className="note-profile-name">{deal.title}</h6>
                    </td>
                    <td>
                      <span className="note-date">
                        {dateFormatter(deal.expectedClosingDate.toDate())}
                      </span>
                    </td>
                    <td>
                      <span className="note-date">
                        {getFormattedValue(deal.value)}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="shadow" />
      </div>
    </Box>
  );
}

export default Deals;
