import { render } from "react-dom";
import React, { createElement, Fragment } from "react";

import algoliasearch from "algoliasearch";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
// import "@algolia/autocomplete-theme-classic";
import "../App.css";
import "../AlgoliaTheme.css";
import { Box } from "@material-ui/core";

const Topic = ({ topic, setIsOpen, setQuery, setTopic, rowId }) => {
  console.log("topic", topic);
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={"10px"}
      height={1}
      width={1}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(topic.name);
        setIsOpen(false);
        setTopic({
          name: topic.name,
          topicId: topic.objectID,
          rowId: rowId,
        });
      }}
    >
      <Box>
        <p style={{ marginBottom: "10px", fontSize: "16px" }}>{topic.name}</p>
        <p style={{ fontSize: "12px" }}>{topic.email}</p>
      </Box>
    </Box>
  );
};

const CreateAccount = ({ query, setIsOpen, setQuery, setTopic }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      width={1}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(query);
        setIsOpen(false);
        setTopic({ isNew: true, name: query, id: null });
      }}
    >
      <p>Contacts not found: </p>
      <b style={{ marginLeft: "5px" }}> {query}</b>
    </Box>
  );
};

export default function AlgoliaTopicAutocomplete(apiKey, setTopic, idx, rowId) {
  const appId = process.env.REACT_APP_ALGOLIA_APP_ID
    ? process.env.REACT_APP_ALGOLIA_APP_ID
    : "TWE65SAYW0";
  const searchClient = algoliasearch(appId, apiKey);
  const autocompleteInstance = autocomplete({
    container: `#algolia-topic-autocomplete${idx}`,
    placeholder: "Topics",
    // debug: true,
    renderer: { createElement, Fragment },
    render({ children }, root) {
      render(children, root);
    },
    openOnFocus: true,

    getSources({ setQuery, refresh, setIsOpen, query }) {
      return [
        {
          sourceId: "topics",
          getItems({ query, state }) {
            let tmpQuery = {
              indexName: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_topics`,
              query,
              params: {
                hitsPerPage: 10,
              },
            };
            return getAlgoliaResults({
              searchClient,
              queries: [tmpQuery],
            });
          },
          templates: {
            item({ state, item, components }) {
              return (
                <Topic
                  topic={item}
                  setQuery={setQuery}
                  setIsOpen={setIsOpen}
                  setTopic={setTopic}
                  rowId={rowId}
                />
              );
            },
            noResults() {
              return (
                <CreateAccount
                  query={query}
                  setQuery={setQuery}
                  setIsOpen={setIsOpen}
                  setTopic={setTopic}
                />
              );
            },
          },
        },
      ];
    },
  });

  return autocompleteInstance;
}
