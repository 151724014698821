import React, { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import useAuth from "../../hooks/useAuth";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import firebase from "../../firebase/firebase";
import { getData, postData, patchData } from "../../utils";
import { useHistory } from "react-router-dom";

import GoogleMapLocation from "./../dashboard/GoogleMapLocation";

import * as actions from "../../redux/actions";
import { connect } from "react-redux";

const SaveMeeting = (props) => {
  const history = useHistory();

  const { closeModal, meetingId } = props;
  const { fireStoreuserObj } = useAuth();
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  // const [firstname, setFirstname] = useState("");
  // const [lastname, setLastname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [participants, setParticipants] = useState([]);
  const [organizer, setOrganizer] = useState(() =>
    fireStoreuserObj ? fireStoreuserObj.email : ""
  );

  const [organizerError, setOrganizerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");

  const setLocationTemp = (v) => {
    if (v) setLocation(v.description);
    else setLocation("");
  };

  const handleAddParticipant = (e) => {
    e.preventDefault();
    setParticipants([...participants, { name, email, avatar: "" }]);
    // setFirstname("");
    // setLastname("");
    setName("");
    setEmail("");
  };

  const handleDeleteParticipant = (participant) => {
    let tempParticipants = participants.filter(
      (item) => item.name !== participant?.name
    );
    setParticipants(tempParticipants);
  };

  const handleSave = async () => {
    if (title === "") {
      setTitleError(true);
      return false;
    }

    if (organizer === "") {
      setOrganizerError(true);
      return false;
    }
    setIsLoading(true);
    let updateData = {
      meetingId,
      participants,
      title,
      organizerEmail: organizer,
      location,
    };

    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let updateMeetingResponse = await patchData(
      `${process.env.REACT_APP_BASE_API_URL}/meeting/recorded/update`,
      jwtToken,
      updateData
    );

    if (updateMeetingResponse.status === "success") {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Meeting created successfully.",
      });
      closeModal();
      history.push(`/meeting/${meetingId}`);
    }
  };

  return (
    <Box
      sx={{ padding: "1em", width: "100%", height: "100%" }}
      display="flex"
      flexDirection="column"
    >
      <h4>Let’s add your meeting details:</h4>
      <Box
        width={"100%"}
        display="flex"
        // justifyContent={"space-between"}
        // style={{ backgroundColor: "green" }}
      >
        <Box pr={2} width={1 / 3}>
          <TextField
            fullWidth
            label="Meeting title"
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              if (e.target.value) {
                setTitleError(false);
              } else {
                setTitleError(true);
              }
            }}
            className={"tasks-title-input"}
            style={{ margin: "1em 0" }}
            error={titleError}
            helperText={titleError ? "Please add title!" : " "}
          />
        </Box>
        <Box pl={1} width={1 / 3}>
          <Box style={{ margin: "1em 0" }}>
            <GoogleMapLocation
              style={{ margin: "1em 0" }}
              value={location}
              setValue={setLocationTemp}
            />
          </Box>
        </Box>
        <Box pl={2} width={1 / 3}>
          <TextField
            fullWidth
            required
            label="Organizer`s Email"
            variant="outlined"
            value={organizer}
            onChange={(e) => {
              setOrganizer(e.target.value);
              if (e.target.value) {
                setOrganizerError(false);
              } else {
                setOrganizerError(true);
              }
            }}
            className={"tasks-title-input"}
            style={{ margin: "1em 0" }}
            error={organizerError}
            helperText={organizerError ? "Please add organizer`s email!" : " "}
          />
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h4 style={{ color: "#707070", marginBottom: "1em" }}>
            Participants
          </h4>
          <div className="on-promise-participant">
            <div>
              <div className="name">{fireStoreuserObj?.displayName}</div>
              <div className="email">{fireStoreuserObj?.email}</div>
            </div>
          </div>
          {participants?.map((participant, index) => (
            <div className="on-promise-participant" key={index}>
              <div>
                <div className="name">{`${participant.name}`}</div>
                <div className="email">{participant.email}</div>
              </div>
              <DeleteOutlineIcon
                onClick={() => handleDeleteParticipant(participant)}
                sx={{ color: "#2c73ff" }}
              />
            </div>
          ))}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <GoogleMapLocation value={location} setValue={setLocationTemp} />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <h4 style={{ color: "#707070", marginBottom: "1em" }}>
            Add new participant
          </h4>
          <form onSubmit={handleAddParticipant}>
            <div style={{ display: "flex" }}>
              <TextField
                id={"first-name"}
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={"tasks-title-input"}
                style={{ margin: ".5em .5em .5em 0", width: "100%" }}
                required
              />
              {/* <TextField
                id={"last-name"}
                label="Last name"
                variant="outlined"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className={"tasks-title-input"}
                style={{ margin: ".5em 0 .5em .5em", width: "100%" }}
                required
              /> */}

              <TextField
                id={"email-address"}
                label="Email address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={"tasks-title-input"}
                style={{ margin: ".5em 0 .5em .5em", width: "100%" }}
              />
            </div>

            <button
              className="modal-button blue"
              style={{ display: "flex", alignItems: "center" }}
              type={"submit"}
            >
              <AddIcon sx={{ color: "white", marginRight: ".3em" }} />
              Add
            </button>
          </form>
        </Grid>
      </Grid>
      <Box my={3}>
        <button className="modal-button blue" onClick={handleSave}>
          Save
        </button>
        {isLoading && <CircularProgress size={"15px"} />}
        <button className="modal-button white ml" onClick={closeModal}>
          Cancel
        </button>
      </Box>
    </Box>
  );
};

// export default SaveMeeting;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveMeeting);
