import React from "react";
import { Box } from "@material-ui/core";
import DateRangeDropdown from "./DateRangeDropdown";

const SentimentLineChartDropdown = ({
  selected,
  setDateRange,
  compare,
  setCompare,
}) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mr: 2,
        }}
      >
        <label className="switch">
          <input
            type="checkbox"
            checked={compare}
            onChange={(e) => setCompare(e.target.checked)}
          />
          <span className="slider round"></span>
        </label>
        <label>Compare</label>
      </Box>
      <DateRangeDropdown selected={selected} setDateRange={setDateRange} />
    </Box>
  );
};

export default SentimentLineChartDropdown;
