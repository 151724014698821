import React, { useState, useEffect } from "react";
import ShareIcon from "@material-ui/icons/Share";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControlLabel from "@mui/material/FormControlLabel";
import firebase from "../../firebase/firebase";
import { getElasticToken, getUserByUserId } from "../../firebase/firestore";
import { getData, postData } from "../../utils";
import GroupIcon from "@mui/icons-material/Group";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LinkIcon from "@mui/icons-material/Link";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import FormGroup from "@mui/material/FormGroup";
import { Get } from "../../utils/axiosUtils";
import ElasticContactAutocomplete from "../Tasks/ElasticContactAutocomplete";
import ElasticMultiAutocomplete from "../Tasks/ElasticMultiAutocomplete";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
  iconBrown: {
    color: "#212B36",
    cursor: "pointer",
    fontSize: "16px",
  },
  pl: {
    paddingLeft: "10px",
    color: "#212B36",
    cursor: "pointer",
  },
  doneButton: {
    float: "right",
    margin: "10px 0 15px 0",
    "&:hover": {
      color: "grey",
      backgroundColor: "#dcdcdc",
    },
  },
  closeButton: {
    float: "right",
    margin: "10px 0 15px 10px",
    "&:hover": {
      color: "grey",
      backgroundColor: "#dcdcdc",
    },
  },
  txtArea: {
    width: "100%",
    borderRadius: "10px",
    borderColor: "#dde1e5",
    padding: ".7em",
    fontFamily: "Poppins",
    minHeight: "100px !important",
    maxHeight: "500px !important",
    minWidth: "100%",
    maxWidth: "100%",
    "&:hover": {
      borderColor: "#000",
    },
  },
  txtField: {
    width: "80%",
    backgroundColor: "#f1f3f4",
  },
  closeBtn: {
    cursor: "pointer",
  },
}));

const sections = [
  {
    value: "transcript",
    label: "Transcript",
  },
  {
    value: "participants",
    label: "Participants",
  },
  {
    value: "action_items",
    label: "Action Items",
  },
  {
    value: "summary",
    label: "Summary",
  },
  {
    value: "notes",
    label: "Notes",
  },
];

function ShareMeeting({
  index,
  usersListData,
  setUsersListData,
  shareModal,
  setShareModal,
  ...props
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");
  const [meetingUrl, setMeetingUrl] = useState(window.location.href);
  const [checkboxStatus, setCheckboxStatus] = useState(true);
  const [message, setMessage] = useState("");
  const [mode, setMode] = useState(true);
  const [organizationId, setOrganizationId] = useState("");
  const [sharedUsers, setSharedUsers] = useState([]);
  const [exportSections, setExportSections] = useState({
    transcript: false,
    participants: false,
    action_items: false,
    summary: false,
    notes: false,
  });
  const [exportLoading, setExportLoading] = useState({
    status: false,
    type: "",
  });
  const [cachedDownloadLinks, setCachedDownloadLinks] = useState([]);
  const [elasticToken, setElasticToken] = useState(null);

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  let id = props.meetingId;
  let userId = firebase.auth().currentUser.uid;

  useEffect(() => {
    setMeetingUrl(window.location.href);

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let accessData = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/access/get?meetingId=` +
            id,
          jwtToken
        );

        let accessUsers = [];
        accessData.users.map((user, index) => {
          accessUsers.push({
            id: user.uid,
            lastName: user.name,
            email: user.email,
            type: "contact",
          });
        });

        setSharedUsers(accessUsers);
      });
  }, []);

  useEffect(async () => {
    let currentUser = await getUserByUserId(userId);
    let organizationId = currentUser.organizationId;
    setOrganizationId(organizationId);
  }, []);

  const handleClickOpenSharePopup = () => {
    setShareModal(true);
  };

  const handleCloseSharePopup = () => {
    setShareModal(false);
  };

  const handleChangeShareTab = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const copyUrl = () => {
    var copyText = document.getElementById("outlined-size-small");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Link copied.",
    });
  };

  const handleShareSubmit = (type) => {
    console.log("sharedUsers", sharedUsers);
    if (!sharedUsers?.length) {
      return;
    }
    let payload = {};
    payload.message = message;
    payload.meetingId = id;
    payload.notify = checkboxStatus;
    payload.public = mode;
    // payload.access = sharedUsers.map((item) => item.contactId);
    payload.access = sharedUsers;

    console.log("payload", payload);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let updatesAccess = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/access/update`,
          jwtToken,
          payload
        );
        console.log("updatesAccess", updatesAccess);
        if (updatesAccess.status == "success") {
          setShareModal(false);
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Meeting shared successfully.",
          });
        } else {
          setShareModal(false);
          props.showSnackbar({
            show: true,
            severity: "error",
            message:
              "You do not have permission to change the access to this meeting.",
          });
        }
      });
  };

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);
  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMode = (type) => {
    setAnchorElMenu(null);
    setMode(type);
  };

  // gets selected users from elastic search
  const handleUserSelection = (d) => {
    console.log("handleUserSelection", d);
    setSharedUsers([...sharedUsers, d]);
  };

  const removeSharedUser = (user) => {
    setSharedUsers(sharedUsers.filter((item) => item.email !== user.email));
  };

  const handleSectionChange = (selected, sectionValue) => {
    let newSections = Object.assign({}, exportSections);
    newSections[sectionValue] = selected;
    setExportSections(newSections);
    setCachedDownloadLinks([]);
  };

  const handleExport = (exportType) => {
    setExportLoading({ status: true, type: exportType });
    const fieldsToExport = Object.keys(exportSections)
      .filter((item, index) => Object.values(exportSections)[index])
      .join(",");
    Get(
      `meeting/export?meetingId=${id}&type=${exportType}&fieldsToExport=${fieldsToExport}`
    )
      .then(({ data }) => {
        setCachedDownloadLinks([
          ...cachedDownloadLinks,
          { type: exportType, url: data.url },
        ]);
        setExportLoading({ status: false, type: "" });
      })
      .catch((err) => {
        console.log("error happen when exporting", err);
        setExportLoading({ status: false, type: "" });
      });
  };

  const handleDownload = (type) => {
    const findUrl = cachedDownloadLinks?.find((item) => item.type === type);
    if (findUrl) {
      window.open(findUrl.url, "_blank");
    }
  };

  const isDocumentDownloaded = (type) => {
    const findFile = cachedDownloadLinks?.find((item) => item.type === type);
    if (findFile) {
      return true;
    }
    return false;
  };

  const notSelectedExportItems = () => {
    let isNotSelected = true;

    for (const item in exportSections) {
      if (exportSections[item]) {
        isNotSelected = false;
      }
    }

    return isNotSelected;
  };

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          onClick={handleClickOpenSharePopup}
        >
          <ShareIcon className={classes.iconBrown} />
          <p className={classes.pl}>Share</p>
        </Box>

        <Dialog
          open={shareModal}
          fullWidth={true}
          onClose={handleCloseSharePopup}
        >
          <DialogContent>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeShareTab("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <PersonAddAltIcon />
                <Typography
                  sx={{
                    width: "100%",
                    flexShrink: 0,
                    marginLeft: "15px",
                  }}
                >
                  Share with people and groups
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 2 }}>
                  {sharedUsers?.map((item, index) => (
                    <Chip
                      label={item.lastName}
                      key={index}
                      onDelete={() => removeSharedUser(item)}
                      sx={{ margin: "4px" }}
                    />
                  ))}
                </Box>
                {elasticToken && (
                  <ElasticMultiAutocomplete
                    setRelatedTo={handleUserSelection}
                    apiKey={elasticToken}
                    className={"elastic-input-tabular"}
                    clearInputAfterSet
                  />
                )}
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Notify people"
                  onChange={(event) => setCheckboxStatus(event.target.checked)}
                />
                {checkboxStatus == true && (
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={8}
                    placeholder="Message"
                    className={classes.txtArea}
                    onChange={(event) => setMessage(event.target.value)}
                    style={{ height: "100px" }}
                  />
                )}

                <button
                  className="modal-button blue"
                  onClick={handleShareSubmit}
                >
                  Done
                </button>
                <button
                  className="modal-button white ml"
                  onClick={handleCloseSharePopup}
                >
                  Cancel
                </button>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeShareTab("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <LinkIcon />
                <Typography
                  sx={{ width: "100%", flexShrink: 0, marginLeft: "15px" }}
                >
                  Get Link
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" alignItems="center">
                  <TextField
                    id="outlined-size-small"
                    value={meetingUrl}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    className={classes.txtField}
                  />

                  <Typography
                    onClick={copyUrl}
                    style={{
                      marginLeft: "20px",
                      fontSize: "14px",
                      color: "#2C73FF",
                      cursor: "pointer",
                    }}
                  >
                    Copy Link
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginY="20px">
                  {mode == false && <GroupIcon />}
                  {mode == true && <PublicOutlinedIcon />}

                  <Box marginLeft="10px" fontSize="12px">
                    <Box display="flex" alignItems="center">
                      <Button
                        id="basic-button"
                        aria-controls={openMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={handleClickMenu}
                      >
                        {mode == false && <p>Restricted</p>}
                        {mode == true && <p>Anyone with the link</p>}
                      </Button>
                      <ArrowDropDownIcon onClick={handleClickMenu} />
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElMenu}
                        open={openMenu}
                        onClose={() => handleMode(mode)}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => handleMode(false)}>
                          Restricted
                        </MenuItem>
                        <MenuItem onClick={() => handleMode(true)}>
                          Anyone with the link
                        </MenuItem>
                      </Menu>
                    </Box>
                    {mode == false && (
                      <p style={{ paddingLeft: "8px" }}>
                        Only people added can open with this link
                      </p>
                    )}
                    {mode == true && (
                      <p style={{ paddingLeft: "8px" }}>
                        Any bluecap app user can access the meeting
                      </p>
                    )}
                  </Box>
                </Box>
                <button
                  className="modal-button blue"
                  onClick={handleShareSubmit}
                >
                  Done
                </button>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChangeShareTab("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <ExitToAppIcon />
                <Typography
                  sx={{ width: "100%", flexShrink: 0, marginLeft: "15px" }}
                >
                  Export
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Sections</Typography>

                <FormGroup>
                  {sections.map((section, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          value={exportSections[section.value]}
                          onChange={(e) =>
                            handleSectionChange(e.target.checked, section.value)
                          }
                        />
                      }
                      label={section.label}
                    />
                  ))}
                </FormGroup>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  mt={1}
                >
                  <button
                    className="modal-button blue"
                    title={
                      notSelectedExportItems()
                        ? "Please select sections which you want to export"
                        : "Click to export"
                    }
                    style={{
                      cursor: notSelectedExportItems()
                        ? "not-allowed"
                        : "pointer",
                      background: isDocumentDownloaded("pdf") ? "#d9534f" : "",
                    }}
                    onClick={() => {
                      isDocumentDownloaded("pdf")
                        ? handleDownload("pdf")
                        : handleExport("pdf");
                    }}
                    disabled={exportLoading?.status || notSelectedExportItems()}
                  >
                    {exportLoading?.status && exportLoading?.type === "pdf" ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        <span>Preparing</span>
                        <CircularProgress
                          size="13px"
                          style={{ color: "#fff", marginLeft: ".5em" }}
                        />
                      </Box>
                    ) : isDocumentDownloaded("pdf") ? (
                      "Download as pdf"
                    ) : (
                      "Export as pdf"
                    )}
                  </button>
                  <button
                    title={
                      notSelectedExportItems()
                        ? "Please select sections which you want to export"
                        : "Click to export"
                    }
                    className="modal-button blue ml"
                    style={{
                      cursor: notSelectedExportItems()
                        ? "not-allowed"
                        : "pointer",
                      background: isDocumentDownloaded("docx") ? "#d9534f" : "",
                    }}
                    onClick={() => {
                      isDocumentDownloaded("docx")
                        ? handleDownload("docx")
                        : handleExport("docx");
                    }}
                    disabled={exportLoading?.status || notSelectedExportItems()}
                  >
                    {exportLoading?.status && exportLoading?.type === "docx" ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        <span>Preparing</span>
                        <CircularProgress
                          size="13px"
                          style={{ color: "#fff", marginLeft: ".5em" }}
                        />
                      </Box>
                    ) : isDocumentDownloaded("docx") ? (
                      "Download as docx"
                    ) : (
                      "Export as docx"
                    )}
                  </button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareMeeting);
