import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, CircularProgress } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";

const UseStyles = makeStyles((theme) => ({
  percent: {
    background: "#EDF3FF",
    borderRadius: "10px",
    width: "100px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Participation({ meetingId }) {
  const classes = UseStyles();
  const [attendance, setAttendance] = useState("");
  const [late, setLate] = useState("");
  const [leftEarly, setLeftEarly] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((jwtToken) => {
        getData(
          `${process.env.REACT_APP_BASE_API_URL}/analytics/attendance?meetingId=` +
            meetingId,
          jwtToken
        )
          .then((attendanceData) => {
            if (attendanceData.status === "success") {
              attendanceData.data.attendance
                ? setAttendance(attendanceData.data.attendance)
                : setAttendance(0);
              attendanceData.data.late
                ? setLate(attendanceData.data.late)
                : setLate(0);
              attendanceData.data.leftEarly
                ? setLeftEarly(attendanceData.data.leftEarly)
                : setLeftEarly(0);
            }
          })
          .catch((err) => console.log("err attendancee", err))
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        console.log("error happen when getting jwt token, attendance.js", err);
      });
  }, []);

  return (
    <>
      <h5 className="agenda">Attendance</h5>
      <Box
        bgcolor="#fff"
        borderRadius="10px"
        marginTop="10px"
        width="100%"
        height="350px"
        display="flex"
        flexDirection="column"
        alignItems={loading ? "center" : "unset"}
        justifyContent="center"
        padding="1em 2em"
      >
        {loading ? (
          <CircularProgress size="24px" style={{ color: "#2c73ff" }} />
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className={classes.percent}>{attendance} %</span>
            </div>
            <Box display="flex" justifyContent="space-between" marginTop="50px">
              <b>Late</b>
              <span>{late} %</span>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop="10px">
              <b>Left</b>
              <span>{leftEarly} %</span>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
