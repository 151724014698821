import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Button, CircularProgress } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import useAuth from "./../../hooks/useAuth";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  CreateReminder as SaveReminder,
  getElasticToken,
} from "./../../firebase/firestore.js";

import ElasticUserAutocomplete from "./../Tasks/ElasticUserAutocomplete";

export default function CreateReminder(props) {
  let {
    addReminderDialogStatus,
    handleCloseAddReminderDialog,
    onSuccessCallback,
  } = props;
  const { fireStoreuserObj } = useAuth();

  let [title, setTitle] = useState(() => {
    return props.title ? props.title : "";
  });

  let [loading, setLoading] = useState(false);

  let [reminderUser, setReminderUser] = useState({
    displayName: fireStoreuserObj.displayName,
    userId: fireStoreuserObj.uid,
  });
  let [dueDate, setDueDate] = useState(new Date());
  let [elasticToken, setElasticToken] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const setRelatedTo = (data) => {
    setReminderUser(data);
  };

  const loadUserAutocomplete = async () => {
    let elasticToken = await getElasticToken();
    setElasticToken(elasticToken);
  };

  useEffect(() => {
    if (addReminderDialogStatus) {
      loadUserAutocomplete();
    }
  }, [addReminderDialogStatus]);

  const resetForm = () => {
    setTitle("");

    setReminderUser({
      displayName: fireStoreuserObj.displayName,
      userId: fireStoreuserObj.uid,
    });
    setDueDate(new Date());
  };
  const addReminder = async () => {
    setLoading(true);
    let payload = {
      title,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      dueDate,
      assignedTo: reminderUser.userId,
    };

    let res = await SaveReminder(payload);

    if (res.success) {
      setLoading(false);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
      handleCloseAddReminderDialog();
      resetForm();
    }
  };
  return (
    <>
      <Dialog
        onClose={handleCloseAddReminderDialog}
        open={addReminderDialogStatus}
      >
        <div className="add-task-modal">
          <Box sx={{ padding: "1em" }} display="flex" flexDirection="column">
            <h4>Add Reminder</h4>

            <Box style={{ margin: "1em 0" }}>
              {elasticToken && (
                <ElasticUserAutocomplete
                  setRelatedTo={setRelatedTo}
                  apiKey={elasticToken}
                  className={"elastic-input-tabular"}
                />
              )}
            </Box>

            <TextField
              width="fullwidth"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={"tasks-title-input"}
              style={{ margin: "1em 0" }}
            />

            <Box display="flex" style={{ margin: "1em 0" }}>
              <DesktopDatePicker
                label="Reminder date"
                inputFormat="MMM,dd yyyy"
                value={dueDate}
                onChange={(date) => setDueDate(date)}
                renderInput={(params) => (
                  <TextField className="tasks-datepicker" {...params} />
                )}
              />
            </Box>

            <Box style={{ margin: "1em 0", height: "200px" }}>
              <Editor
                wrapperClassName={"task-view-editor-wrapper add-task"}
                editorClassName={"task-view-editor"}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "history",
                  ],
                }}
                editorState={editorState}
                // wrapperClassName="demo-wrapper"
                // editorClassName="demo-editor"
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
              />
            </Box>
            <Box display="flex" flexDirection="flex-start">
              <button
                className="modal-button blue"
                onClick={() => addReminder()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "#fff" }}
                  ></CircularProgress>
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="modal-button white ml"
                onClick={() => handleCloseAddReminderDialog()}
              >
                Close
              </button>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
}
