import React, { useEffect, useState } from "react";
import { Container, Box } from "@material-ui/core";
import Page from "../Page";
import ReportsDashboard from "./ReportsDashboard";
import InsightsDashboard from "./InsightsDashboard";
import { Post } from "../../utils/axiosUtils";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";

export default function Dashboard() {
  const { fireStoreuserObj } = useAuth();
  const [activeTab, setActiveTab] = useState("home");
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [meetingData, setMeetingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(true);

  const sentimentPermissionChecker = async () => {
    //PERMISSION CONTROL
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    if (sentimentSettings) {
      let userRole = fireStoreuserObj.role;
      if (userRole === "MEMBER") {
        if (
          sentimentSettings?.users === "userOnly" ||
          sentimentSettings?.users === "off"
        ) {
          setIsSentimentAvailable(false);
        }
      } else if (
        userRole === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "off"
      ) {
        setIsSentimentAvailable(false);
      } else if (userRole === "ADMIN" && sentimentSettings?.admins === "off") {
        setIsSentimentAvailable(false);
      }
    } else {
      setIsSentimentAvailable(true);
    }
  };

  const fetchData = () => {
    const postData = {
      start: new Date().getTime() - dateRange.value * 2 * 24 * 60 * 60 * 1000,
      end: new Date().getTime(),
    };
    Post("analytics/getUserMeetings", postData)
      .then(({ data: { data } }) => {
        setMeetingData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error happen when fetching analytic data");
        setLoading(false);
      });
  };

  useEffect(sentimentPermissionChecker, []);
  useEffect(fetchData, [dateRange]);

  const dateRangeHandler = (newDateRange) => {
    if (newDateRange.value > dateRange.value) {
      setDateRange(newDateRange);
      return true;
    }
    return false;
  };

  return (
    <Page>
      <Container maxWidth={"xl"}>
        <Box className={"dashboard-tab-menu-selector"}>
          <Box
            className={`dashboard-tab ${activeTab === "home" ? "active" : ""}`}
            onClick={() => setActiveTab("home")}
          >
            <span>Home</span>
            <Box className="dashboard-hr" />
          </Box>
          <Box
            className={`dashboard-tab ${
              activeTab === "report" ? "active" : ""
            }`}
            onClick={() => setActiveTab("report")}
          >
            <span>Report</span>
            <Box className="dashboard-hr" />
          </Box>
          <Box className="dashboard-tab fix">
            <Box style={{ height: "33px" }} />
            <Box className="dashboard-hr" />
          </Box>
        </Box>
        {activeTab === "report" ? (
          <ReportsDashboard
            chartData={meetingData}
            meetingsLoading={loading}
            dateRangeHandler={dateRangeHandler}
            isSentimentAvailable={isSentimentAvailable}
          />
        ) : (
          activeTab === "home" && (
            <InsightsDashboard
              dateRangeHandler={dateRangeHandler}
              meetingsLoading={loading}
              meetingData={meetingData}
              isSentimentAvailable={isSentimentAvailable}
            />
          )
        )}
      </Container>
    </Page>
  );
}
