import React from 'react';
import Select from "react-select";
import {DateRangeOptions} from "./DateRangeOptions";

function DateRangeDropdown({selected,setDateRange,additionalOptions}) {
    const styles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShadow: "none",
            border: "1px solid #2C73FF",
            alignItems: "center",
            justifyContent: "center",
            width: "max-content"
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "#2C73FF" // Custom colour
        }),
        valueContainer: (base) => ({
            ...base
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#707070",
            fontSize: "12px",
            border: "none",
            boxShadow: "none",
            zIndex:3
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused && "#f3f3f3",
            color: state.isFocused && "#000"
        }),
        menuPortal: (base) => ({...base, zIndex: 9999})
    };
    const handleChange = (item) => setDateRange(item);
    return (
        <Select
            value={selected}
            options={additionalOptions && additionalOptions?.length > 0 ? [...additionalOptions,...DateRangeOptions] : DateRangeOptions}
            onChange={(item) => handleChange(item)}
            styles={styles}
            components={{
                IndicatorSeparator: () => null
            }}
            isSearchable={false}
        />
    );
}

export default DateRangeDropdown;
