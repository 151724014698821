import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, CircularProgress } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeStyles } from "@material-ui/styles";
import {
  changeTranscript,
  UpdateTranscriptByMeetingId,
} from "../../firebase/firestore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  txtArea: {
    width: "100%",
    borderRadius: "10px",
    borderColor: "#dde1e5",
    padding: ".7em",
    fontFamily: "Poppins",
    minHeight: "100px !important",
    maxHeight: "500px !important",
    resize: "vertical",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));

function TranscriptBubble({
  dialog,
  meetingId,
  meetingType,
  markTranscriptAsRead,
  transcriptionData,
  setTranscriptionData,
  speakers,
  activeDialog,
  activeEntity,
  isHybrid,
  participants,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [editInput, setEditInput] = useState(dialog.text);
  const [saveLoading, setSaveLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const renderWithCustomText = () => {
    if (activeEntity?.entity && editInput?.includes(activeEntity?.entity)) {
      let dialogArr = editInput.split(activeEntity?.entity);
      return dialogArr?.map((item, index, totalIndex) => {
        return (
          <React.Fragment key={index}>
            <span>{item}</span>
            {totalIndex !== index && (
              <span
                style={{
                  background: activeEntity?.color || "#000",
                  color: "white",
                  padding: ".1em .5em",
                  borderRadius: ".3em",
                }}
              >
                {activeEntity.entity}
              </span>
            )}
          </React.Fragment>
        );
      });
    } else {
      return <span>{editInput}</span>;
    }
  };
  const handleCancel = () => {
    setEditInput(dialog.text);
    setEditMode(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const getSpeaker = async (e, speakerName, speakerAvatar) => {
    e.stopPropagation();
    if (!transcriptionData || transcriptionData?.length === 0) {
      return;
    }
    let updatedTranscripts = [];

    transcriptionData?.map(async (uTranscript) => {
      if (uTranscript?.name && uTranscript.name !== "") {
        if (uTranscript.uid === dialog.uid) {
          // console.log('1')
          uTranscript.name = speakerName;
          uTranscript.avatar = speakerAvatar;
          uTranscript.isAssigned = true;
          let updateTranscript = await UpdateTranscriptByMeetingId(
            meetingId,
            uTranscript.uid,
            speakerName,
            speakerAvatar
          );
        }
      } else {
        if (uTranscript.speaker === dialog.speaker) {
          uTranscript.name = speakerName;
          uTranscript.avatar = speakerAvatar;
          uTranscript.isAssigned = true;
          let updateTranscript = await UpdateTranscriptByMeetingId(
            meetingId,
            uTranscript.uid,
            speakerName,
            speakerAvatar
          );
        }
      }
      updatedTranscripts.push(uTranscript);
    });
    let newTranscriptData = [...transcriptionData];

    updatedTranscripts.map((item) => {
      let findUpdatedIndex = newTranscriptData.findIndex(
        (transcript) => transcript.uid === item.uid
      );
      if (findUpdatedIndex > -1) {
        newTranscriptData[findUpdatedIndex] = item;
      }
    });
    newTranscriptData.sort(
      (a, b) => a?.timestamp?.seconds - b?.timestamp?.seconds
    );
    setTranscriptionData(newTranscriptData);
    handleClosePop();
  };

  const handleSave = () => {
    setSaveLoading(true);
    changeTranscript({ transcriptId: dialog.uid, text: editInput }, meetingId)
      .then(() => {
        setSaveLoading(false);
        setEditMode(false);
        markTranscriptAsRead();
      })
      .catch((err) => {
        console.log(err, "error happen when updating dialog");
      });
  };

  const openPop = Boolean(anchorEl);

  const handleOpenContactLead = (participantName) => {
    if (!participantName) return;
    let findUser = participants.find((item) => item.name === participantName);
    if (findUser?.id && findUser?.type) {
      history.push(`/crm/${findUser.type}/${findUser.id}`);
    }
  };

  return (
    <>
      <Menu
        id={"speaker-menu"}
        anchorEl={anchorEl}
        open={openPop}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            padding: "6px 16px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(e) => getSpeaker(e, null)}
        >
          <ListItemText primary={"None"} style={{ marginLeft: "10px" }} />
        </Box>
        {speakers?.map((speaker, index) => {
          return (
            <Box
              sx={{
                padding: "6px 16px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              key={index}
              onClick={(e) =>
                getSpeaker(
                  e,
                  speaker?.name,
                  speaker?.avatar ? speaker.avatar : ""
                )
              }
            >
              <Avatar
                alt="DP"
                src={speaker.avatar}
                sx={{ width: 24, height: 24 }}
              />
              <ListItemText
                primary={speaker.name}
                style={{ marginLeft: "10px" }}
              />
            </Box>
          );
        })}
      </Menu>

      <div
        className={`dialog ${activeDialog === dialog?.uid ? "playing" : ""}`}
        id={dialog?.uid}
      >
        {!editMode && (
          <EditIcon
            className="dialog-edit-icon"
            onClick={() => setEditMode(true)}
          />
        )}
        <Avatar src={dialog.avatar} sx={{ height: 40, width: 40 }} />
        <div className="context">
          <h4
            style={{ display: "flex" }}
            className={
              participants.find(
                (item) =>
                  item.name === dialog?.name || item.name === dialog?.speaker
              )?.id
                ? "link-text"
                : ""
            }
            onClick={() =>
              handleOpenContactLead(
                dialog?.name ? dialog.name : dialog?.speaker
              )
            }
          >
            {meetingType &&
            (meetingType === "MS_TEAMS" ||
              meetingType === "ZOOM" ||
              meetingType === "RECORDING" ||
              meetingType === "GOOGLE_MEET" ||
              isHybrid) ? (
              <>
                {dialog?.name ? dialog.name : "Speaker: " + dialog.speaker}
                <ExpandMoreIcon
                  aria-describedby={dialog.uid}
                  onClick={(e) => handleClick(e)}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </>
            ) : (
              <>{dialog.name ? dialog.name : dialog.speaker}</>
            )}
          </h4>
          {editMode ? (
            <>
              <TextareaAutosize
                aria-label="minimum height"
                placeholder="Dialog"
                value={editInput}
                onChange={(event) => setEditInput(event.target.value)}
                className={classes.txtArea}
                style={{ height: "100px" }}
              />
              <button
                className="modal-button blue"
                onClick={handleSave}
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <CircularProgress size={"12px"} style={{ color: "#fff" }} />
                ) : (
                  "Save"
                )}
              </button>
              <button className="modal-button white ml" onClick={handleCancel}>
                Cancel
              </button>
            </>
          ) : (
            <div className="dialog-text">{renderWithCustomText()}</div>
          )}
          <div className="dialog-time">{dialog.renderTime}</div>
        </div>
      </div>
    </>
  );
}

export default TranscriptBubble;
