import React, { useEffect, useState } from "react";
import {
  setParticipantsFlag,
  UpdateTranscriptByMeetingId,
} from "../../firebase/firestore";
import TranscriptBubble from "./TranscriptBubble";
import { Box, CircularProgress } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { withStyles } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import { Post } from "../../utils/axiosUtils";

const CustomCheckbox = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function MeetingDetailTranscript({
  meetingId,
  meetingType,
  markTranscriptAsRead,
  participants,
  transcriptionData,
  setTranscriptionData,
  showSnackbar,
  activeDialog,
  activeEntity,
  isHybrid,
}) {
  const [loading, setLoading] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [newParticipantName, setNewParticipantName] = useState("");
  const [newParticipantLastname, setNewParticipantLastname] = useState("");
  const [addParticipantLoading, setAddParticipantLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogsData, setDialogsData] = useState([]);
  const open = Boolean(anchorEl);

  const autoScrollDialog = () => {
    if (loading || !activeDialog) {
      return;
    }
    let scrollContainer = document.getElementById("dialog-container");
    let activeBubble = document.getElementById(activeDialog);
    if (scrollContainer && activeBubble) {
      scrollContainer?.scrollTo({
        top: activeBubble.offsetTop - 300,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(autoScrollDialog, [activeDialog]);

  const handleSpeakersClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSpeakersClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!participants || participants?.length === 0) {
      return;
    }
    let newSpeakers = participants.map((item) => ({
      ...item,
      isSelected: item?.isSelected !== false,
      avatar: item?.avatar ? item.avatar : "",
      email: item?.email ? item.email : "",
    }));
    setSpeakers(newSpeakers);
  }, [participants]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight.toFixed(0) - e.target.scrollTop.toFixed(0) ===
      e.target.clientHeight;
    if (bottom) {
      markTranscriptAsRead();
    }
  };

  useEffect(() => {
    if (transcriptionData && transcriptionData?.length > -1) {
      setLoading(false);

      let sortedData = transcriptionData.sort(
        (a, b) => a?.timestamp?.seconds - b?.timestamp?.seconds
      );
      setDialogsData([...sortedData]);
    } else if (
      //hybrid meeting loading control
      (!transcriptionData && !loading) ||
      (transcriptionData?.length === 0 && !loading)
    ) {
      setLoading(true);
    }
  }, [transcriptionData]);

  const updateTranscriptInDB = async (id, transcriptId, transcriptName) => {
    let updateNameNull = await UpdateTranscriptByMeetingId(
      id,
      transcriptId,
      transcriptName
    );
    if (!updateNameNull) {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const handleSpeakerRemove = async (e, speaker) => {
    e.stopPropagation();

    let newSpeakers = [...speakers];
    let findSpeaker = newSpeakers.find((item) => item.name === speaker.name);
    if (findSpeaker.isSelected) {
      let newTranscriptList = transcriptionData.map((transcript) => {
        let transcriptId = transcript.uid;
        if (speaker.name === transcript.name) {
          transcript.name = null;
          transcript.avatar = "";
          updateTranscriptInDB(meetingId, transcriptId, transcript.name).catch(
            (err) => console.log(err)
          );
        }
        return transcript;
      });
      setTranscriptionData(newTranscriptList);

      showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
      findSpeaker.isSelected = !findSpeaker.isSelected;
    } else {
      findSpeaker.isSelected = !findSpeaker.isSelected;
    }
    let updateParticipants = await setParticipantsFlag(newSpeakers, meetingId);
    if (updateParticipants) {
      setSpeakers(newSpeakers);
    }
  };

  const handleResetSpeakerAssign = async () => {
    let newTranscriptList = transcriptionData.map((transcript) => {
      let transcriptId = transcript.uid;
      transcript.name = null;
      transcript.avatar = "";
      updateTranscriptInDB(meetingId, transcriptId, transcript.name).catch(
        (err) => console.log(err)
      );
      return transcript;
    });
    setTranscriptionData(newTranscriptList);
  };

  const addParticipant = () => {
    if (newParticipantName === "") {
      return;
    }
    setAddParticipantLoading(true);
    let checkParticipant = participants.find(
      (item) =>
        item.name.replace(/ /g, "").toLowerCase() ===
        `${newParticipantName
          .replace(/ /g, "")
          .toLowerCase()}${newParticipantLastname
          .replace(/ /g, "")
          .toLowerCase()}`
    );
    if (!checkParticipant) {
      let newParticipants = [...participants];
      let newSpeakers = [...speakers];
      newParticipants.push({
        name: `${newParticipantName} ${newParticipantLastname}`?.trim(),
        avatar: "",
        isSelected: true,
      });
      newSpeakers.push({
        name: `${newParticipantName} ${newParticipantLastname}`?.trim(),
        avatar: "",
        isSelected: true,
      });
      let postData = {
        meetingId,
        participants: [
          {
            name: `${newParticipantName} ${newParticipantLastname}`?.trim(),
            avatar: "",
          },
        ],
      };
      console.log("here");
      Post("meeting/hybrid/participants", postData)
        .then(() => {
          setSpeakers(newSpeakers);
          setNewParticipantName("");
          setNewParticipantLastname("");
          setAddParticipantLoading(false);
          showSnackbar({
            show: true,
            severity: "success",
            message: `Participant added successfully.`,
          });
        })
        .catch((err) => {
          console.log(err, "error happen when adding participant");
          setAddParticipantLoading(false);
          showSnackbar({
            show: true,
            severity: "error",
            message: `Server error happen.`,
          });
        });
    } else {
      setAddParticipantLoading(false);
      showSnackbar({
        show: true,
        severity: "error",
        message: `This participant already exist.`,
      });
    }
  };

  return (
    <div
      className="dialog-container"
      id={"dialog-container"}
      onScroll={handleScroll}
    >
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : !transcriptionData?.length ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
        >
          No data found
        </Box>
      ) : (
        <div className="meeting-detail-tab-container">
          {(meetingType === "MS_TEAMS" ||
            meetingType === "ZOOM" ||
            meetingType === "RECORDING" ||
            meetingType === "GOOGLE_MEET" ||
            isHybrid) && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                <p style={{ fontSize: "14px", color: "#303030" }}>
                  {" "}
                  &nbsp; Speakers:{" "}
                  {speakers.filter((item) => item.isSelected)?.length}
                </p>
                <AvatarGroup max={10} style={{ marginLeft: "10px" }}>
                  {speakers
                    .filter((item) => item.isSelected)
                    ?.map((speaker, index) => {
                      return (
                        <Avatar
                          key={index}
                          className="pop-avatar persons"
                          alt="Remy Sharp"
                          src={speaker.avatar}
                        />
                      );
                    })}
                </AvatarGroup>
                <ExpandMoreIcon
                  aria-describedby={meetingId}
                  onClick={handleSpeakersClick}
                  style={{ cursor: "pointer" }}
                />
                <Menu
                  id={"positioned-menu"}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleSpeakersClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  {speakers?.map((speaker, index) => {
                    return (
                      <Box
                        sx={{
                          padding: "6px 16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={index}
                        onClick={(e) => handleSpeakerRemove(e, speaker)}
                      >
                        <CustomCheckbox checked={speaker.isSelected} />
                        <Avatar
                          alt="DP"
                          src={speaker.avatar}
                          sx={{ width: 24, height: 24 }}
                        />
                        <ListItemText
                          primary={speaker.name}
                          style={{ marginLeft: "10px" }}
                        />
                      </Box>
                    );
                  })}
                  <Box sx={{ padding: "6px 16px" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        size="small"
                        style={{ width: "100%", marginRight: "1em" }}
                        className={"tasks-title-input"}
                        label="Name"
                        value={newParticipantName}
                        onChange={(e) => setNewParticipantName(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        className={"tasks-title-input"}
                        label="Last name"
                        value={newParticipantLastname}
                        onChange={(e) =>
                          setNewParticipantLastname(e.target.value)
                        }
                        onKeyDown={(e) => e.stopPropagation()}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <button
                        className="modal-button blue"
                        style={{ width: "100%" }}
                        onClick={addParticipant}
                        disabled={addParticipantLoading}
                      >
                        {addParticipantLoading ? (
                          <CircularProgress
                            size="15px"
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          "Add"
                        )}
                      </button>
                      <button
                        className="modal-button white"
                        style={{ width: "100%", marginTop: 8 }}
                        onClick={handleSpeakersClose}
                      >
                        Done
                      </button>
                    </Box>
                  </Box>
                </Menu>
              </Box>
              <button
                className="modal-button white"
                style={{ padding: ".3em .3em", minWidth: "unset" }}
                onClick={handleResetSpeakerAssign}
              >
                Reset
              </button>
            </Box>
          )}

          {dialogsData.map((dialog, index) => (
            <TranscriptBubble
              meetingType={meetingType}
              dialog={dialog}
              key={index}
              activeDialog={activeDialog}
              meetingId={meetingId}
              markTranscriptAsRead={markTranscriptAsRead}
              transcriptionData={dialogsData}
              setTranscriptionData={setTranscriptionData}
              speakers={speakers.filter((item) => item.isSelected)}
              activeEntity={activeEntity}
              isHybrid={isHybrid}
              participants={participants}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default MeetingDetailTranscript;
