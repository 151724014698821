import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Get, Post } from "../../../utils/axiosUtils";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  txtArea: {
    width: "100%",
    borderRadius: "10px",
    borderColor: "#dde1e5",
    padding: ".7em",
    fontFamily: "Poppins",
    minHeight: "100px !important",
    maxHeight: "100px !important",
    resize: "none",
    minWidth: "100%",
    maxWidth: "100%",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));

function AccountInsightContainer({ header }) {
  const classes = useStyles();
  const [insight, setInsight] = useState(null);
  const [insightInput, setInsightInput] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState({
    status: false,
    iconType: "",
  });

  const getInsight = () => {
    return new Promise((resolve, reject) => {
      let apiEndPoint = ``;
      //setLoading(false);
      //resolve();
      /*
            Get(apiEndPoint)
                .then(({ data }) => {
                    if (data?.data?.insights?.length) {
                        setInsight(data?.data?.insights[0]);
                        setInsightInput(data?.data?.insights[0]);
                    }
                    setLoading(false);
                    resolve();
                })
                .catch((err) => {
                    setLoading(false);
                    reject(err);
                });
             */
    });
  };
  useEffect(() => {
    getInsight().catch((err) =>
      console.log("error happen when getting insights", err)
    );
  }, []);

  const handleSave = () => {
    setSaveLoading(true);
    /*
    const postData = {
      insight: insightInput?.insight,
      contactId: contactId,
      leadId: leadId,
    };
    Post("/meeting/create-contact-insight", postData)
      .then(() => {
        getInsight()
          .then(() => {
            setEditMode(false);
            setSaveLoading(false);
          })
          .catch((err) =>
            console.log("error happen when getting insights", err)
          );
      })
      .catch((err) => {
        console.log(err, "error happen when creating new insight");
        setSaveLoading(false);
      });
     */
  };

  const handleLikeDislike = (like) => {
    if (insight) {
      /*
            setLikeLoading({ status: true, iconType: like ? "up" : "down" });
            let postData = {
                insightId: insight.id,
                leadId,
                contactId,
                like,
            };
            Post("/meeting/like-contact-insight", postData)
                .then(() => {
                    setInsight({ ...insight, like });
                    setLikeLoading({ status: false, iconType: "" });
                })
                .catch((err) => {
                    console.log(err);
                    setLikeLoading({ status: false, iconType: "" });
                });
             */
    }
  };
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="lead-status-header">
        <div className="text-header">
          <img
            src="/images/bot-head.png"
            alt="bothead"
            className={"bot-head-animation"}
            height="20"
          />
          <h6 style={{ marginLeft: "10px", paddingTop: "2px" }}>{header}</h6>
        </div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="meeting-detail-icon-container"
        >
          <div className="icons-container">
            <EditIcon
              sx={{ color: "#c3c3c3", cursor: "pointer" }}
              onClick={() => {
                if (insight) {
                  setEditMode(!editMode);
                }
              }}
            />
            {likeLoading?.status && likeLoading?.iconType === "up" ? (
              <CircularProgress size={20} sx={{ color: "#707070" }} />
            ) : (
              <ThumbUpAltOutlinedIcon
                sx={{
                  color: insight?.like === true ? "#2c73ff" : "#c3c3c3",
                  cursor: "pointer",
                }}
                onClick={() => handleLikeDislike(true)}
              />
            )}
            {likeLoading?.status && likeLoading?.iconType === "down" ? (
              <CircularProgress size={20} sx={{ color: "#707070" }} />
            ) : (
              <ThumbDownAltOutlinedIcon
                sx={{
                  color: insight?.like === false ? "#2c73ff" : "#c3c3c3",
                  cursor: "pointer",
                }}
                onClick={() => handleLikeDislike(false)}
              />
            )}
          </div>
        </Box>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress size="24px" style={{ color: "#2c73ff" }} />
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            overflow: "auto",
            opacity: 0.8,
            paddingTop: "20px",
          }}
        >
          {editMode && insightInput?.insight ? (
            <Box>
              <TextareaAutosize
                aria-label="minimum height"
                placeholder="Dialog"
                value={insightInput.insight}
                onChange={(e) =>
                  setInsightInput({ ...insightInput, insight: e.target.value })
                }
                className={classes.txtArea}
                style={{ height: "100%", overflow: "auto" }}
                disabled={saveLoading}
              />
              <button className="modal-button blue" onClick={handleSave}>
                {saveLoading ? (
                  <CircularProgress size="15px" style={{ color: "#fff" }} />
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="modal-button white ml"
                onClick={() => {
                  setInsightInput(insight);
                  setEditMode(false);
                }}
              >
                Cancel
              </button>
            </Box>
          ) : (
            <span>
              {insight?.insight
                ? insight.insight
                : "There are not enough meetings to generate insights."}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default AccountInsightContainer;
