import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Card, Container } from "@material-ui/core";
import { useHistory } from "react-router";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import useAuth from "../../hooks/useAuth";
import { getData, postData, patchData } from "./../../utils";
import firebase from "./../../firebase/firebase.js";

import Page from "../Page";
import InviteDialog from "./InviteDialog";
import Typography from "@mui/material/Typography";
import RemoveConfirm from "./RemoveConfirm";
import Snackbar from "../generic/SnackbarNotification";

export default function Teams() {
  const history = useHistory();
  const { fireStoreuserObj } = useAuth();

  const [members, setMembers] = React.useState([]);
  const [organization, setOrganization] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [removeMember, setRemoveMember] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({ open: false });

  const roles = [
    { value: "ADMIN", text: "Admin" },
    { value: "TEAM_MANAGER", text: "Team Manager" },
    { value: "MEMBER", text: "Member" },
  ];

  React.useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = () => {
    setLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
          jwtToken
        );
        setLoading(false);
        if (result.status === "success") {
          setMembers(result.data.members);
          setOrganization(result.data.organization);
        }
      });
  };

  const removeTeamMember = (id) => {
    setLoading(true);
    setRemoveMember(null);
    let data = {
      orgid: fireStoreuserObj.organizationId,
      memberId: id,
    };

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/member/remove`,
          jwtToken,
          data
        );
        setLoading(false);
        if (result.status === "success") {
          fetchTeamMembers();
        }
      });
  };

  const textMemberRole = (role) => {
    switch (role) {
      case "ADMIN":
        return "Admin";
      case "TEAM_MANAGER":
        return "Team Manager";
      default:
        return "Member";
    }
  };

  const validateDelete = (member) => {
    let valid = false;
    if (member.id !== fireStoreuserObj.uid) {
      if (
        fireStoreuserObj.role === "ADMIN" &&
        organization.createdBy !== member.id
      ) {
        valid = true;
      }
      if (fireStoreuserObj.role === "TEAM_MANAGER" && member.role !== "ADMIN") {
        valid = true;
      }

      if (!member.role) {
        valid = true;
      }
    }

    if (valid) {
      return (
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setRemoveMember(member.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  const validateMember = (member) => {
    let valid = false;
    if (member.id !== fireStoreuserObj.uid) {
      if (
        fireStoreuserObj.role === "ADMIN" &&
        organization.createdBy !== member.id
      ) {
        valid = true;
      }
      if (fireStoreuserObj.role === "TEAM_MANAGER" && member.role !== "ADMIN") {
        valid = true;
      }
    }
    return valid;
  };

  const handleChangeRole = (event, id) => {
    setLoading(true);
    let data = {
      orgid: fireStoreuserObj.organizationId,
      role: event.target.value,
    };
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await patchData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/member/` + id,
          jwtToken,
          data
        );
        setLoading(false);
        if (result.status === "success") {
          fetchTeamMembers();
          setSnackbar({
            open: true,
            severity: "success",
            message: "Member role updated succesfully.",
          });
        } else {
          setSnackbar({
            open: true,
            severity: "error",
            message: result.message,
          });
        }
      });
  };

  return (
    <Page>
      <Snackbar
        open={snackbar.open}
        severity={snackbar.severity}
        message={snackbar.message}
        handleSnackbar={(v) => setSnackbar({ open: false })}
      />
      <Container maxWidth={"xl"}>
        <Box display="flex" flexDirection="column">
          <Box marginTop={1} display="flex" justifyContent="space-between">
            <Typography variant="h4" gutterBottom component="div">
              Team Members
            </Typography>
            <InviteDialog onFetchMembers={fetchTeamMembers} />
          </Box>

          {loading && <LinearProgress sx={{ mt: 1 }} />}

          {removeMember && (
            <RemoveConfirm
              onCancel={() => setRemoveMember(null)}
              onConfirm={() => removeTeamMember(removeMember)}
            />
          )}

          {members.length > 0 && organization && (
            <Card sx={{ mt: 2 }}>
              <List sx={{ width: "100%" }}>
                {members.map((member, i) => (
                  <React.Fragment key={"teamMember_" + member.id}>
                    <ListItem
                      alignItems="center"
                      secondaryAction={validateDelete(member)}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push({
                          pathname: `/teams/${member.id}`,
                          state: { from: "teamManagement" },
                        })
                      }
                    >
                      <ListItemAvatar>
                        <Avatar alt={member.name} src={member.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ maxWidth: 300 }}
                        primary={member.name}
                        secondary={member.email}
                      />
                      <ListItemText
                        sx={{ maxWidth: 300 }}
                        primary={textMemberRole(member.role)}
                      />
                      <ListItemText
                        sx={{ maxWidth: 300 }}
                        primary={member.seniority ? member.seniority : "-"}
                      />
                      {validateMember(member) && (
                        <FormControl variant="standard" sx={{ minWidth: 150 }}>
                          <Select
                            value={member.role}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleChangeRole(e, member.id);
                            }}
                          >
                            {roles.map((role) => (
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                value={role.value}
                              >
                                {role.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </ListItem>
                    {i + 1 < members.length && (
                      <Divider variant="inset" component="li" sx={{ ml: 0 }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Card>
          )}
        </Box>
      </Container>
    </Page>
  );
}
