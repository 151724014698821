// routes
//import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  // <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
  <SvgIconStyle
    src={`/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  contacts: getIcon("contacts"),
  crm: getIcon("crm"),
  dashboard: getIcon("dashboard"),
  meetings: getIcon("meetings"),
  analytics: getIcon("analytics"),
  teams: getIcon("teams"),
  settings: getIcon("settings"),
  tasks: getIcon("tasks"),
  deals: getIcon("deals"),
  help: getIcon("help"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      { title: "Dashboard", path: "/dashboard", icon: ICONS.dashboard },
      { title: "Meetings", path: "/meetings/past", icon: ICONS.meetings },
      {
        title: "Teams",
        path: "/teams",
        icon: ICONS.teams,
        children: [
          { title: "Teams", path: "/teams" },
          { title: "Team Management", path: "/team-management" },
        ],
      },
      // { title: "Analytics", path: "/wds", icon: ICONS.analytics },
      // { title: "Teams", path: "/wds", icon: ICONS.teams },
      {
        title: "CRM",
        path: "/crm",
        icon: ICONS.crm,
        children: [
          { title: "Leads", path: "/crm/leads" },
          { title: "Contacts", path: "/crm/contacts" },
          { title: "Accounts", path: "/crm/accounts" },
        ],
      },
      { title: "Tasks", path: "/tasks", icon: ICONS.tasks },
      { title: "Deals", path: "/deals", icon: ICONS.deals },
      { title: "Settings", path: "/settings", icon: ICONS.settings },
      { title: "Help", path: "/help", icon: ICONS.help },
    ],
  },
];

if (process.env.REACT_APP_APP_ENV != "dev" && false) {
  let filtereItems = sidebarConfig[0].items.filter(
    (item) => item.title !== "Teams"
  );
  sidebarConfig[0].items = [
    ...filtereItems.slice(0, 2),
    {
      title: "Teams",
      path: "/team-management",
      icon: ICONS.teams,
    },
    ...filtereItems.slice(2, filtereItems.length),
  ];
}

export default sidebarConfig;
