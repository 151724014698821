import React, { useState, useEffect } from "react";

import DateRangePicker from "@mui/lab/DateRangePicker";

import CrmItem from "../crm/CrmItem";
import ConversationItem from "../conversations/ConversationItem";

import InputLabel from "@mui/material/InputLabel";

import { ConversationData } from "../conversations/ConversationData";
import { CrmData } from "../crm/CrmData";

import {
  Box,
  Grid,
  Button,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Collapse,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import googleMeetAvatar from "../../../assets/images/meet.png";
import msTeamAvatar from "../../../assets/images/ms-teams-icon.svg";
import recordingMeeting from "../../../assets/images/recording_meeting.png";

import zoomAvatar from "../../../assets/images/zoom-icon.svg";

export default function Filter({
  filter,
  setFilterStatus,
  setShowSuggestions,
  searchInput,
  filterStatus,
}) {
  const [dateFilter, setDateFilter] = useState([null, null]);

  const [conversationListData, setConversationListData] = useState([
    ...ConversationData,
  ]);
  const [crmListData, setCrmListData] = useState([...CrmData]);
  const [conversationsOpen, setConversationsOpenFn] = useState(false);
  const [crmOpen, setCrmOpenFn] = useState(false);

  const conversationsOpenHandleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setConversationsOpenFn(!conversationsOpen);
  };
  const crmOpenHandleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCrmOpenFn(!crmOpen);
  };

  const resterFilterHandleClick = () => {
    setDateFilter([null, null]);
    setCrmListData([...CrmData]);
    setConversationListData([...ConversationData]);
    setChannelType([]);

    setShowSuggestions(true);
    setFilterStatus(false);

    filter.current = null;

    setTimeout(() => {
      searchInput.current.querySelector("input").focus();
    }, 50);
  };

  const [channelType, setChannelType] = useState(() => []);

  const handleChannelType = (event, channels) => {
    setChannelType(channels);
  };

  const applyFilter = () => {
    console.log("dateFilter", dateFilter);

    let filterObject = {
      selectedEndTime: null,
      selectedStartTime: null,
    };

    if (dateFilter[0]) {
      filterObject.selectedStartTime = Math.round(
        dateFilter[0].getTime() / 1000
      );
    }
    if (dateFilter[1]) {
      filterObject.selectedEndTime = Math.round(dateFilter[1].getTime() / 1000);
    }

    if (channelType.length) {
      filterObject.channelType = channelType;
    }

    filter.current = filterObject;

    setShowSuggestions(true);
    setFilterStatus(false);

    setTimeout(() => {
      searchInput.current.querySelector("input").focus();
    }, 50);
  };

  if (!filterStatus) {
    return null;
  }
  return (
    <Box className="filter">
      <Box width={{ xs: "400px", md: "600px" }} minHeight={300} padding={4}>
        <Grid
          position="relative"
          display="grid"
          paddingBottom="30px"
          style={{
            gridTemplateColumns: "repeat(4, auto)",
            // borderBottom: "1px solid #7DA8FB",
          }}
        >
          <Box paddingTop="8px">
            <InputLabel
              id="demo-simple-select-helper-label"
              style={{ marginBottom: "5px", fontSize: " 10px" }}
            >
              Between:
            </InputLabel>
            {/* <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            // label="Date"
            inputFormat="dd/MM/yy"
            value={filterDate}
            onChange={handleFilterDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}
            <DateRangePicker
              value={dateFilter}
              onChange={(newValue) => {
                setDateFilter(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </Box>
        </Grid>
        <Grid
          position="relative"
          display="grid"
          padding="15px 0"
          style={{
            width: "100%",
          }}
        >
          <Grid>
            <Box
              display="flex"
              gap={"10px"}
              onClick={conversationsOpenHandleClick}
            >
              <InputLabel
                id="demo-multiple-name-label"
                style={{
                  marginBottom: "20px",
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Conversations
              </InputLabel>
              <div style={{ height: "15px", display: "flex" }}>
                {conversationsOpen ? (
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px" }}
                    className="expand-arrow-icon"
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ width: "15px", height: "15px" }}
                    className="expand-arrow-icon"
                  />
                )}
              </div>
            </Box>
            <Collapse in={conversationsOpen} timeout="auto" unmountOnExit>
              <Grid display="flex" gap="5px" mb="10px">
                <ToggleButtonGroup
                  color="secondary"
                  value={channelType}
                  onChange={handleChannelType}
                  size="small"
                >
                  <ToggleButton value="ZOOM">
                    <Avatar
                      src={zoomAvatar}
                      alt="meet"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ToggleButton>
                  <ToggleButton value="GOOGLE_MEET">
                    <Avatar
                      src={googleMeetAvatar}
                      alt="meet"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ToggleButton>
                  <ToggleButton value="MS_TEAMS">
                    <Avatar
                      src={msTeamAvatar}
                      alt="meet"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ToggleButton>
                  <ToggleButton value="RECORDING">
                    <Avatar
                      src={recordingMeeting}
                      alt="meet"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {conversationListData.map((elem, index) => {
                return (
                  <ConversationItem
                    elem={elem}
                    index={index}
                    key={index}
                    conversationListData={conversationListData}
                    setConversationListData={setConversationListData}
                  />
                );
              })}
            </Collapse>
          </Grid>

          <Grid>
            <Box display="flex" gap={"10px"} onClick={crmOpenHandleClick}>
              <InputLabel
                id="demo-multiple-name-label"
                style={{
                  marginBottom: "20px",
                  fontSize: "10px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                CRM
              </InputLabel>
              <div style={{ height: "15px", display: "flex" }}>
                {crmOpen ? (
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px" }}
                    className="expand-arrow-icon"
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ width: "15px", height: "15px" }}
                    className="expand-arrow-icon"
                  />
                )}
              </div>
            </Box>
            <Collapse in={crmOpen} timeout="auto" unmountOnExit>
              {crmListData.map((elem, index) => {
                return (
                  <CrmItem
                    elem={elem}
                    index={index}
                    key={index}
                    crmListData={crmListData}
                    setCrmListData={setCrmListData}
                  />
                );
              })}
            </Collapse>
          </Grid>
        </Grid>

        <Box padding={2} display="flex" justifyContent="center">
          <Button variant="text" onClick={resterFilterHandleClick}>
            Reset filter
          </Button>
          <Button variant="contained" onClick={applyFilter}>
            Search
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
