import React, { useState, useEffect } from "react";
import { Container, Grid, Box } from "@material-ui/core";
import Heatmap from "../charts.js/Heatmap";
import BubbleChart from "../charts.js/BubbleChart";
import TopicsLineChart from "../charts.js/TopicsLineChart";
import PerformanceTrends from "../charts.js/PerformanceTrends";
import AvarageScores from "../charts.js/AvarageScores";
import { Post } from "../../utils/axiosUtils";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import TeamMembersTable from "./TeamMembersTable";
import useAuth from "../../hooks/useAuth";
import Page from "../Page";
import { getOrgainzation } from "../../firebase/firestore";

function TeamAnalytics(props) {
  const { fireStoreuserObj } = useAuth();
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [fetchData, setFetchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bubbleChartType, setBubbleChartType] = useState({
    value: "topicSentiments",
    label: "Topics & Sentiments",
  });
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  const checkSentimentPermission = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );
  };

  useEffect(checkSentimentPermission, []);

  const dateRangeHandler = (newDateRange) => {
    if (newDateRange.value > dateRange.value) {
      setDateRange(newDateRange);
      return true;
    }
    return false;
  };

  const fetchDataFromServer = () => {
    const postData = {
      start: new Date().getTime() - dateRange.value * 2 * 24 * 60 * 60 * 1000,
      end: new Date().getTime(),
      organizationId: fireStoreuserObj?.organizationId,
    };
    Post("analytics/getOrganizationMeetingsData", postData)
      .then(({ data: { data } }) => {
        console.log(
          data.filter((item) => item.matchedProducts),
          "matchedProducts"
        );
        setFetchData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error happen when fetching my team analytics", err);
      });
  };

  useEffect(fetchDataFromServer, [dateRange]);

  return (
    <Page>
      <Container maxWidth={"xl"}>
        <h3 className="my-teams-header">My Teams</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <AvarageScores
              data={fetchData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
              chartType={"team"}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <PerformanceTrends
              data={fetchData}
              fetchLoading={loading}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Heatmap
              fetchLoading={loading}
              meetingsData={fetchData}
              dateRangeController={dateRangeHandler}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "2em" }}>
          <Grid item xs={12} lg={6}>
            <BubbleChart
              type={bubbleChartType}
              setType={setBubbleChartType}
              data={fetchData}
              fetchLoading={loading}
              dateRangeHandler={dateRangeHandler}
              isTeamAnalytic={true}
              isSentimentAvailable={isSentimentAvailable}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopicsLineChart
              fetchLoading={loading}
              fetchData={fetchData}
              dateRangeHandler={dateRangeHandler}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "2em" }}>
          <Grid item xs={12} sx={{ height: 400 }}>
            <TeamMembersTable isSentimentAvailable={isSentimentAvailable} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default TeamAnalytics;
