import React from "react";
import { Box } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import DealColumn from "./DealColumn";

export default function DealsBoard({
  deals,
  handleDragDrop,
  addDeal,
  handleOpenDealClick,
  handleDeleteDealClick,
}) {
  deals.sort((d1, d2) => d1.order - d2.order);

  const onDragEnd = async (result) => {
    handleDragDrop(result);
  };

  const dealStatuses = [
    {
      value: "identify_decision_makers",
      title: "Identify Decision Makers",
    },
    {
      value: "needs_analysis",
      title: "Needs Analysis",
    },
    {
      value: "value_proposition",
      title: "Value Proposition",
    },
    {
      value: "proposal_price_quote",
      title: "Proposal/Price Quote",
    },
    {
      value: "negotiation_review",
      title: "Negotiation Review",
    },
    {
      value: "closed_won",
      title: "Closed Won",
    },
    {
      value: "closed_lost",
      title: "Closed Lost",
    },
    {
      value: "closed_lost_to_competition",
      title: "Closed-Lost to Competition",
    },
  ];
  return (
    <Box paddingTop={0} marginTop={2} display="flex">
      <DragDropContext onDragEnd={onDragEnd}>
        <Box sx={{ display: "flex" }} id={"analyticChartsContainer"}>
          {dealStatuses.map((dealStatus) => {
            return (
              <DealColumn
                droppableId={dealStatus.value}
                key={dealStatus.value}
                deals={deals.filter((d) => d.status == dealStatus.value)}
                title={dealStatus.title}
                addDeal={addDeal}
                handleOpenDealClick={handleOpenDealClick}
                handleDeleteDealClick={handleDeleteDealClick}
              />
            );
          })}
        </Box>
      </DragDropContext>
    </Box>
  );
}
