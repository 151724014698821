import React, { useState, useEffect } from "react";
import InsightItem from "./InsightItem";
import moment from "moment";
import { Box } from "@material-ui/core";

function InsightContainer({ insights, loading, icon, isRisks }) {
  const sortedInsights =
    insights && insights.length
      ? insights.sort(
          (a, b) => b.meetingCreatedAt._seconds - a.meetingCreatedAt._seconds
        )
      : [];
  const [slicedInsights, setSlicedInsights] = useState([]);
  const [closedInsights, setClosedInsights] = useState([]);
  const generateSlicedInsights = () => {
    if (!insights?.length) {
      return;
    }
    setSlicedInsights(sortedInsights?.slice(0, 3));
  };
  useEffect(generateSlicedInsights, [sortedInsights]);

  const handleItemClose = (id, closeType) => {
    let insightDoc = document.getElementById(id);
    let anotherInsights = slicedInsights.filter((item) => item.id !== id);
    anotherInsights.map((item) => {
      let findDislikedElementIndex = slicedInsights.findIndex(
        (item) => item.id === id
      );
      let findCurrentElementIndex = slicedInsights.findIndex(
        (insight) => insight.id === item.id
      );
      if (findCurrentElementIndex > findDislikedElementIndex) {
        document.getElementById(item.id).style.animation =
          "1s insightGoUp ease";
        setTimeout(() => {
          document.getElementById(item.id).style.animation = "";
        }, 1000);
      }
    });
    if (insightDoc && insightDoc?.classList) {
      if (closeType === "dislike") {
        insightDoc.classList.add("disliked");
      } else {
        insightDoc.classList.add("liked");
      }
    }
    setTimeout(() => {
      let newClosedInsights = [...closedInsights, id];
      let allClosedInsights = [
        ...newClosedInsights,
        ...slicedInsights.map((item) => item.id),
      ];
      let getNewInsight = sortedInsights.find(
        (x) => !allClosedInsights.includes(x.id)
      );
      if (getNewInsight) {
        getNewInsight.playOpacity = true;
        setSlicedInsights([
          ...slicedInsights.filter((item) => item.id !== id),
          getNewInsight,
        ]);
      } else {
        setSlicedInsights(slicedInsights.filter((item) => item.id !== id));
      }
      setClosedInsights(newClosedInsights);
    }, 1000);
  };

  useEffect(() => {
    // Clearing opacity animation
    setTimeout(() => {
      if (sortedInsights?.length && slicedInsights?.length) {
        let newSlicedInsights = [...slicedInsights];
        newSlicedInsights?.map((item) => {
          if (item.playOpacity) {
            item.playOpacity = false;
          }
        });
        setSlicedInsights(newSlicedInsights);
      }
    }, 700);
  }, [closedInsights]);

  return (
    <div className="insight-tasks-container">
      {loading ? (
        <>
          <InsightItem loading />
          <InsightItem loading />
          <InsightItem loading />
        </>
      ) : !slicedInsights?.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={"450px"}
          maxHeight={"450px"}
        >
          You haven't got any insight data.
        </Box>
      ) : (
        slicedInsights?.map((task) => (
          <InsightItem
            title={task?.task}
            key={task.id}
            description={`From ${task.meetingTitle}, ${moment(
              task.meetingCreatedAt?._seconds * 1000
            ).format("MMM DD")}`}
            icon={icon}
            handleCloseItem={handleItemClose}
            id={task.id}
            playOpacity={task?.playOpacity}
            isRisks={isRisks}
            meetingId={task.meetingId}
          />
        ))
      )}
    </div>
  );
}

export default InsightContainer;
