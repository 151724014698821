import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import { sentimentIcons } from "../../utils/sentimentIcons";
import moment from "moment";
import GoogleMeetIcon from "../../assets/images/google-meet-logo.png";
import ZoomIcon from "../../assets/images/zoom.png";
import MsTeamsIcon from "../../assets/images/ms-teams-icon.svg";
import recordingMeeting from "../../assets/images/recording_meeting.png";
import { styled } from "@material-ui/core/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router";
import {
  dateTimeOperators,
  doesNotContainOperator,
  durationOperators,
  participantsOperator,
} from "../meetingTable/tabularDataConstants";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  // animation: 'ripple 1.2s infinite ease-in-out',
  width: 10,
  height: 12,
  // border: `1px solid ${theme.palette.background.paper}`,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // background: "#B345D2",
    backgroundImage: `url(/images/host.png)`,
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    left: 0,
    // color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function ParticipantsAvatars(props) {
  const { row, type } = props;

  let organizerName = "";
  if (props.organizerName !== undefined) {
    organizerName = props.organizerName;
  }

  // console.log('organizerName',organizerName)
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { participants } = row;
  if (participants !== undefined && organizerName !== undefined) {
    participants.organizerName = organizerName;
  }

  // sort by name
  // participants.sort(function (a, b) {
  //   console.log("a", a);
  //   console.log("b", b);
  //   var nameA = a.name; // ignore upper and lowercase
  //   var nameB = b.name; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // console.log('participants', participants)

  if (participants && participants.length > 0) {
    return (
      <Grid container onClick={(e) => e.stopPropagation()}>
        <Grid item xs={12} lg={8}>
          <AvatarGroup max={type === "past" ? 3 : 5}>
            {participants.map((p, index) => {
              const { name, avatar, email } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
                if (fChar === "" && email !== undefined && email !== "") {
                  fChar = email.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              if (
                participants.organizerName &&
                name == participants.organizerName
              ) {
                return (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="dot"
                    title="Organiser"
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                    }
                  >
                    <Avatar
                      className="bulk-avatar"
                      alt={name}
                      src={avatar}
                      key={index}
                      title={name}
                      style={{
                        border: "1px solid #2C73FF",
                        height: 20,
                        width: 20,
                      }}
                    />
                  </StyledBadge>
                  // <Badge
                  //   overlap="circular"
                  //   title="Organiser"
                  //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  //   badgeContent={
                  //     <SmallAvatar alt="Remy Sharp" src="/images/host.png" />
                  //   }
                  // >
                  //   <Avatar
                  //     className="pop-avatar persons"
                  //     alt={participants.name}
                  //     src={participants.avatar}
                  //     key={index}
                  //   />
                  // </Badge>
                );
              } else {
                return (
                  <Avatar
                    key={index}
                    alt={name}
                    src={avatar}
                    className="bulk-avatar"
                    title={name}
                    // style={{
                    //   border: "1px solid #7DA8FB",
                    // }}
                  >
                    {fChar}
                  </Avatar>
                );
              }
              // return (
              //   <>
              //     {
              //       participants.organizerName && name == participants.organizerName ? (
              //         <StyledBadge
              //           overlap="circular"
              //           anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              //           variant="dot"
              //           title="Organiser"
              //         >
              //           <Avatar
              //             className="bulk-avatar"
              //             alt={name}
              //             src={avatar}
              //             key={index}
              //           />
              //         </StyledBadge>
              //       ) : (
              //         <Avatar
              //           key={index}
              //           alt={name}
              //           src={avatar}
              //           className="bulk-avatar"
              //         >
              //           {fChar}
              //         </Avatar>
              //       )
              //     }

              //   </>
              // );
            })}
          </AvatarGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <a
            // href="/"
            aria-describedby={row.id}
            onClick={handleClick}
            className="expand-arrow-icon"
          >
            {open ? (
              <ExpandLessIcon className="arrow" />
            ) : (
              <ExpandMoreIcon className="arrow" />
            )}
          </a>
          <Popover
            id={row.id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { minWidth: "147px", paddingRight: "20px" },
            }}
          >
            {participants.map((p, index) => {
              // console.log('ppp',p)
              const { name, avatar, email } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
                if (fChar === "" && email !== undefined && email !== "") {
                  fChar = email.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              let txtToDisplay = name;
              if (name === undefined || name === "") {
                txtToDisplay = email;
              }
              return (
                <Grid key={index} container className="past-popup-participants">
                  <Grid item xs={3} lg={3}>
                    {participants.organizerName &&
                    name == participants.organizerName ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        variant="dot"
                        title="Organiser"
                        badgeContent={
                          <SmallAvatar
                            alt="Remy Sharp"
                            src="/images/host.png"
                          />
                        }
                      >
                        <Avatar
                          alt={name}
                          src={avatar}
                          className="pop-avatar"
                          style={{
                            border: "1px solid #2C73FF",
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      <Avatar
                        alt={name}
                        src={avatar}
                        className="pop-avatar"
                        title={name}
                        // style={{
                        //   border: "1px solid #7DA8FB",
                        // }}
                      >
                        {fChar}
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs={9} lg={9}>
                    {txtToDisplay}
                  </Grid>
                </Grid>
              );
            })}
          </Popover>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

let columns = [
  {
    field: "date",
    headerName: "Date/Time",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 96,
    flex: 1,
    filterOperators: dateTimeOperators,
    sortComparator: (v1, v2) =>
      new Date(v1)?.getTime() - new Date(v2)?.getTime(),
  },
  {
    field: "channelType",
    hide: true,
    type: "singleSelect",
    valueOptions: [
      {
        value: "GOOGLE_MEET",
        label: "Google Meet",
      },
      {
        value: "ZOOM",
        label: "Zoom",
      },
      {
        value: "MS_TEAMS",
        label: "MS Teams",
      },
    ],
    headerName: "Channel",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      return (
        <img
          src={params.row.meetingType}
          alt="channelType"
          className="data-table-channel-type-icon"
        />
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 112,
    flex: 1,
    filterOperators: [
      ...getGridStringOperators().filter(
        (operator) =>
          operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
      ),
      ...doesNotContainOperator,
    ],
    renderCell: (params) => {
      return params?.row?.title?.length > 112 / 10 ? (
        <>
          <img
            style={{ marginRight: "5px" }}
            src={params.row.meetingType}
            alt="channelType"
            className="data-table-channel-type-icon"
          />
          <LightTooltip
            title={params.row.title}
            componentsProps={{
              popper: { sx: { backgroundColor: "#FFFFE6" } },
            }}
          >
            <span className="data-table-title">{params.row.title}</span>
          </LightTooltip>
        </>
      ) : (
        <>
          <img
            style={{ marginRight: "5px" }}
            src={params.row.meetingType}
            alt="channelType"
            className="data-table-channel-type-icon"
          />
          <span className="data-table-title">{params.row.title}</span>
        </>
      );
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 64,
    flex: 1,
    filterOperators: durationOperators,
    sortComparator: (v1, v2) => {
      let v1Min = 0;
      let v2Min = 0;
      if (v1.indexOf("h") > -1) {
        let hoursArray = v1.split("h");
        v1Min += Number(hoursArray[0]) * 60;
        let minsArray = v1.split("m");
        v1Min += Number(
          minsArray[0].slice(v1.indexOf("h") + 1, minsArray[0].length)
        );
      } else {
        let minsArray = v1.split("m");
        v1Min += Number(minsArray[0]);
      }
      if (v2.indexOf("h") > -1) {
        let hoursArray = v2.split("h");
        v2Min += Number(hoursArray[0]) * 60;
        let minsArray = v2.split("m");
        v2Min += Number(
          minsArray[0].slice(v2.indexOf("h") + 1, minsArray[0].length)
        );
      } else {
        let minsArray = v2.split("m");
        v2Min += Number(minsArray[0]);
      }
      return v1Min - v2Min;
    },
  },
  {
    field: "engagementScore",
    headerName: "Engagement Score",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 64,
    flex: 1,
  },
  {
    field: "sentimentScore",
    headerName: "Sentiment",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 64,
    flex: 1,
    renderCell: (params) => {
      if (params.row.sentimentScore === 100) {
        return sentimentIcons.POSITIVE_2;
      } else if (params.row.sentimentScore >= 75) {
        return sentimentIcons.POSITIVE_1;
      } else if (params.row.sentimentScore >= 50) {
        return sentimentIcons.NEUTRAL;
      } else if (params.row.sentimentScore >= 25) {
        return sentimentIcons.NEGATIVE_1;
      } else if (params.row.sentimentScore > 0) {
        return sentimentIcons.NEGATIVE_2;
      } else {
        return "";
      }
    },
  },
  {
    field: "participants",
    headerName: "Participants",
    headerClassName: "data-table-header",
    cellClassName: "data-table-cell",
    minWidth: 80,
    flex: 1,
    renderCell: (params) => {
      return (
        <ParticipantsAvatars
          row={params.row.meetingData}
          organizerName={params.row.displayName}
          type={"past"}
        />
      );
    },
    filterOperators: participantsOperator,
    valueGetter: (params) =>
      params.row.meetingData?.participants
        ? params.row.meetingData.participants
        : { length: 0 },
    sortComparator: (v1, v2) => v1?.length - v2?.length,
  },
];

function ModalTabular({ data, fetchLoading, isFromSentiment, contactName }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const transformData = () => {
    setLoading(true);
    if (isFromSentiment) {
      columns = [
        {
          field: "contactName",
          headerName: "Contact name",
          headerClassName: "data-table-header",
          cellClassName: "data-table-cell",
          minWidth: 120,
          flex: 1,
        },
        ...columns,
      ];
    } else {
      let filterColumns = columns.filter(
        (item) => item.field !== "contactName"
      );
      columns = filterColumns;
    }
    const rows = [];
    data.map((meeting, index) => {
      let meetingUrl = "";
      meeting.htmlLink && meeting.htmlLink !== null
        ? (meetingUrl = meeting.htmlLink)
        : (meetingUrl = "");

      let displayName = "";
      if (
        meeting.organizer !== undefined &&
        meeting.organizer.displayName !== undefined
      ) {
        displayName = meeting.organizer.displayName;
      }

      const allEngagements = [];
      const allSentiments = [];
      meeting?.participants?.map((participant) => {
        if (participant?.participation) {
          if (participant.participation?.noShow) {
            allEngagements.push(0);
          } else {
            allEngagements.push(
              Number(participant.participation.participation)
            );
          }
        }
        if (participant?.sentiment?.emotion) {
          if (participant.sentiment.emotion === "POSITIVE_2") {
            allSentiments.push(100);
          } else if (participant.sentiment.emotion === "POSITIVE_1") {
            allSentiments.push(75);
          } else if (
            participant.sentiment.emotion === "NEUTRAL" ||
            participant.sentiment.emotion === "MIXED"
          ) {
            allSentiments.push(50);
          } else if (participant.sentiment.emotion === "NEGATIVE_1") {
            allSentiments.push(25);
          } else if (participant.sentiment.emotion === "NEGATIVE_2") {
            allSentiments.push(0);
          }
        }
      });
      const sum = (previous, current) => {
        if (!current) {
          return previous;
        }
        return previous + current;
      };
      const calculatedEngagementScore =
        allEngagements?.length > 0
          ? Math.round(allEngagements.reduce(sum) / allEngagements.length)
          : "";
      const calculatedSentimentScore =
        allSentiments?.length > 0
          ? Math.round(allSentiments.reduce(sum) / allSentiments.length)
          : "";
      rows.push({
        id: index + meeting.id,
        date: meeting?.startTime ? moment(meeting.startTime).format("lll") : "",
        meetingType:
          meeting?.channelType === "GOOGLE_MEET"
            ? GoogleMeetIcon
            : meeting?.channelType === "ZOOM"
            ? ZoomIcon
            : meeting?.channelType === "MS_TEAMS"
            ? MsTeamsIcon
            : meeting?.channelType === "RECORDING"
            ? recordingMeeting
            : "",
        title: meeting?.title,
        duration: meeting.duration,
        displayName: displayName,
        meetingData: meeting,
        meetingId: meeting?.id,
        meetingUrl: meetingUrl,
        channelType: meeting?.channelType,
        engagementScore:
          calculatedEngagementScore > 0 ? calculatedEngagementScore + "%" : "",
        sentimentScore: calculatedSentimentScore,
        contactName: isFromSentiment ? contactName : null,
      });
    });
    setTableRows(rows);
    setLoading(false);
  };

  useEffect(transformData, [data]);

  const handleRowClick = (meeting) => {
    history.push(`/meeting/${meeting.meetingId}`);
  };

  return (
    <>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
          minHeight={500}
          sx={{ background: "#fff", borderRadius: ".5rem" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <div style={{ height: "80vh" }}>
          <DataGrid
            rows={tableRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowCount={data.length}
            checkboxSelection={false}
            disableSelectionOnClick
            enableCellSelect={false}
            getRowClassName={() => `data-table-row`}
            onRowClick={(meeting) => handleRowClick(meeting.row)}
            className={`data-table`}
          />
        </div>
      )}
    </>
  );
}

export default ModalTabular;
