import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { Box } from "@material-ui/core";
import axios from "axios";

function CustomFilterInput(props) {
  const { item, applyValue, focusElementRef } = props;

  const inputRef = React.useRef(null);
  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current
        ?.querySelector(`input[value="${Number(item.value) || ""}"]`)
        ?.focus();
    },
  }));

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <TextField
      id="standard-required"
      label="Value"
      defaultValue=""
      placeholder="Filter value"
      variant="standard"
      onChange={handleFilterChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

function CustomFilterDurationRange(props, isRangeInput) {
  const { item, applyValue } = props;
  const [values, setValues] = useState([0, 0]);

  const handleInputChange = (value, type) => {
    if (!Number(value)) {
      value = 0;
    }
    let newValues = [...values];
    if (type === "start") {
      newValues[0] = Number(value);
    }
    if (type === "end") {
      newValues[1] = Number(value);
    }
    setValues(newValues);
  };

  useEffect(() => {
    //Running clear function
    if (!item?.value) {
      setValues([0, 0]);
    }
  }, [item]);

  useEffect(() => {
    if (values[0] === 0 && values[1] === 0) {
      return;
    }
    applyValue({ ...item, value: isRangeInput ? values : values[0] });
  }, [values]);

  return (
    <Box display={"flex"}>
      <TextField
        id="standard-required"
        label={isRangeInput ? "From (mins)" : "Value (mins)"}
        defaultValue=""
        placeholder="Filter value"
        variant="standard"
        value={values[0]}
        onChange={(e) => handleInputChange(e.target.value, "start")}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {isRangeInput && (
        <TextField
          id="standard-required"
          label="To (mins)"
          defaultValue=""
          placeholder="Filter value"
          variant="standard"
          value={values[1]}
          onChange={(e) => handleInputChange(e.target.value, "end")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </Box>
  );
}

function CustomFilterDatePicker(props) {
  const { item, applyValue } = props;
  const [date, setDate] = useState([
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - new Date().getDay() + 1,
      0
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - new Date().getDay() + 8,
      0
    ),
  ]);

  useEffect(() => {
    applyValue({ ...item, value: date });
  }, [date]);

  return (
    <Box display="flex">
      <DateRangePicker
        label="Advanced keyboard"
        value={date}
        reduceAnimations
        onChange={(newValue) => setDate(newValue)}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              id="standard-required"
              label="Start"
              defaultValue=""
              placeholder="Filter value"
              variant="standard"
              ref={startProps.inputRef}
              InputLabelProps={{
                shrink: true,
              }}
              {...startProps.inputProps}
            />
            <Box sx={{ mx: 1 }}> - </Box>
            <TextField
              id="standard-required"
              label="End"
              defaultValue=""
              placeholder="Filter value"
              variant="standard"
              ref={endProps.inputRef}
              InputLabelProps={{
                shrink: true,
              }}
              {...endProps.inputProps}
            />
          </React.Fragment>
        )}
      />
    </Box>
  );
}

function DateSelectInput(props) {
  const { item, applyValue } = props;
  let value;
  if (item?.operatorValue === "today") {
    value = [new Date(new Date().setHours(0, 0, 0)), new Date()];
  } else if (item?.operatorValue === "yesterday") {
    value = [
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1,
        0
      ),
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0
      ),
    ];
  } else if (item?.operatorValue === "lastWeek") {
    value = [
      new Date(
        new Date().setDate(
          new Date().getDate() - ((new Date().getDay() + 6) % 7)
        )
      ),
      new Date(
        new Date().setDate(
          new Date().getDate() - ((new Date().getDay() + 6) % 7)
        ) +
          7 * 24 * 60 * 60 * 1000
      ),
    ];
  } else if (item?.operatorValue === "lastMonth") {
    value = [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0),
      new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0),
    ];
  } else if (item?.operatorValue === "last7days") {
    value = [
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
        0
      ),
      new Date(),
    ];
  } else if (item?.operatorValue === "last30days") {
    value = [
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 30,
        0
      ),
      new Date(),
    ];
  }

  useEffect(() => {
    console.log(item, "ITEM OBJECT");
    applyValue({
      ...item,
      value,
    });
  }, [item?.operatorValue]);
  return <Box sx={{ display: "hidden" }}></Box>;
}

export const participantsOperator = [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) => {
        let filterRows = params.row.meetingData.participants?.filter((item) =>
          item.name.toLowerCase().includes(filterItem.value.toLowerCase())
        );
        if (filterRows && filterRows?.length > 0) {
          return filterRows;
        }
        return false;
      };
    },
    InputComponent: CustomFilterInput,
    InputComponentProps: { type: "number" },
  },
];

export const doesNotContainOperator = [
  {
    label: "does not contain",
    value: "doesNotContains",
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        let filterRows =
          params?.row?.title
            ?.toLowerCase()
            .indexOf(filterItem.value.toLowerCase()) < 0;
        if (filterRows) {
          return filterRows;
        }
        return false;
      };
    },
    InputComponent: CustomFilterInput,
    InputComponentProps: { type: "string" },
  },
];

export const dateTimeOperators = [
  {
    label: "Custom",
    value: "custom",
    InputComponent: CustomFilterDatePicker,
  },
  {
    label: "Today",
    value: "today",
    InputComponent: DateSelectInput,
  },
  {
    label: "Yesterday",
    value: "yesterday",
    InputComponent: DateSelectInput,
  },
  {
    label: "Last week",
    value: "lastWeek",
    InputComponent: DateSelectInput,
  },
  {
    label: "Last month",
    value: "lastMonth",
    InputComponent: DateSelectInput,
  },
  {
    label: "Last 7 days",
    value: "last7days",
    InputComponent: DateSelectInput,
  },
  {
    label: "Last 30 days",
    value: "last30days",
    InputComponent: DateSelectInput,
  },
];

function filterDuration(filterItem) {
  if (
    !filterItem.columnField ||
    !filterItem.value ||
    !filterItem.operatorValue
  ) {
    return null;
  }

  return (params) => {
    const { duration } = params?.row;
    let filterRows = null;
    let min = 0;
    if (duration.indexOf("h") > -1) {
      let hoursArray = duration?.split("h");
      min += Number(hoursArray[0]) * 60;
      let minsArray = duration?.split("m");
      min += Number(
        minsArray[0].slice(duration?.indexOf("h") + 1, minsArray[0].length)
      );
    } else {
      let minsArray = duration?.split("m");
      min += Number(minsArray[0]);
    }
    if (filterItem.operatorValue === "lessThan") {
      filterRows = min < Number(filterItem.value);
    }
    if (filterItem.operatorValue === "moreThan") {
      filterRows = min > Number(filterItem.value);
    }
    if (filterItem.operatorValue === "equals") {
      filterRows = min === Number(filterItem.value);
    }
    if (filterItem.operatorValue === "between") {
      filterRows =
        min >= Number(filterItem.value[0]) &&
        min <= Number(filterItem.value[1]);
    }
    if (filterRows) {
      return filterRows;
    }
    return false;
  };
}

export const durationOperators = [
  {
    label: "Less than",
    value: "lessThan",
    getApplyFilterFn: (filterItem) => {
      return filterDuration(filterItem);
    },
    InputComponent: (props) => CustomFilterDurationRange(props, false),
    InputComponentProps: { type: "minutes" },
  },
  {
    label: "More than",
    value: "moreThan",
    getApplyFilterFn: (filterItem) => {
      return filterDuration(filterItem);
    },
    InputComponent: (props) => CustomFilterDurationRange(props, false),
    InputComponentProps: { type: "minutes" },
  },
  {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem) => {
      return filterDuration(filterItem);
    },
    InputComponent: (props) => CustomFilterDurationRange(props, false),
    InputComponentProps: { type: "minutes" },
  },
  {
    label: "Between",
    value: "between",
    getApplyFilterFn: (filterItem) => {
      return filterDuration(filterItem);
    },
    InputComponent: (props) => CustomFilterDurationRange(props, true),
    InputComponentProps: { type: "minutes" },
  },
];

const elasticHost = process.env.REACT_APP_ELASTIC_HOST
  ? process.env.REACT_APP_ELASTIC_HOST
  : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

export async function elasticSearchMeeting(
  filters,
  sortField,
  sortType,
  pageNumber,
  apiKey
) {
  if (!apiKey) {
    return false;
  }
  let query = {
    bool: {
      must: [
        {
          range: {
            "endedAt._seconds": {
              lt: new Date().getTime() / 1000,
            },
          },
        },
      ],
    },
  };
  let sort = {};
  if (filters?.length) {
    let queries = [];
    filters.map((item) => {
      if (item.columnValue === "createdAt" && item.value?.length === 2) {
        queries.push({
          range: {
            "createdAt._seconds": {
              gt: new Date(item.value[0]).getTime() / 1000,
              lt: new Date(item.value[1]).getTime() / 1000,
            },
          },
        });
      } else if (item.columnValue === "participants") {
        queries.push({
          match: {
            participantNames: {
              query: item.value,
              operator: "and",
              zero_terms_query: "all",
            },
          },
        });
      } else if (item.operatorValue === "isEmpty") {
        queries.push({
          regexp: {
            [`${item.columnValue}.keyword`]: "",
          },
        });
      } else if (item.operatorValue === "isNotEmpty") {
        queries.push({
          query_string: {
            query: "?*",
            fields: [item.columnValue],
          },
        });
      } else if (item.columnValue === "duration") {
        queries.push({
          range: {
            duration: {
              gt:
                item.operatorValue === "equals"
                  ? Number(item.value) * 60
                  : item.operatorValue === "greaterThan"
                  ? (Number(item.value) + 1) * 60
                  : null,
              lt:
                item.operatorValue === "equals"
                  ? (Number(item.value) + 1) * 60
                  : item.operatorValue === "lowerThan"
                  ? Number(item.value) * 60
                  : null,
            },
          },
        });
      } else {
        queries.push({
          query_string: {
            query:
              item.operatorValue === "contains"
                ? `*${item.value}*`
                : item.operatorValue === "equals"
                ? `\"${item.value}\"`
                : item.operatorValue === "doesNotContains" ||
                  item.operatorValue === "not"
                ? `NOT *${item.value}*`
                : item.operatorValue === "isAnyOf"
                ? `${item.value?.map((item) => `\"${item}\"`).join(" OR ")}`
                : null,
            fields: [item.columnValue],
          },
        });
      }
    });
    queries.map((item) => query.bool.must.push(item));
  }
  if (sortField === "title" && sortType) {
    sort = [{ "title.keyword": { order: sortType } }];
  } else if (sortField === "date" && sortType) {
    sort = [{ "createdAt._seconds": { order: sortType } }];
  } else if (sortField === "participants" && sortType) {
    sort = {
      _script: {
        type: "number",
        script: "doc['participantNames.keyword'].size()",
        order: sortType,
      },
    };
  } else if (sortField === "duration" && sortType) {
    sort = [{ duration: { order: sortType } }];
  }

  if (!sortField) {
    sort = [{ "createdAt._seconds": { order: "desc" } }];
  }

  const searchQuery = await axios.post(
    `${elasticHost}/meetings/_search`,
    {
      query,
      sort,
      size: 50,
      from: 50 * pageNumber,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${apiKey}`,
      },
    }
  );

  const results = [];
  if (searchQuery.status === 200) {
    searchQuery.data.hits.hits.map((h) =>
      results.push({
        id: h._id,
        startTime: h._source?.createdAt?._seconds * 1000,
        ...h._source,
      })
    );
    return {
      total: searchQuery.data.hits.total.value,
      results,
    };
  }
  console.log(searchQuery?.data);
  return false;
}

export async function elasticSearchCRM(
  filters,
  sortField,
  sortType,
  pageNumber,
  type,
  apiKey
) {
  if (!apiKey) {
    return false;
  }
  let query = { bool: {} };
  let sort = {};
  if (type === "leads") {
    query["bool"]["must_not"] = [
      {
        match: {
          leadStatus: "converted",
        },
      },
    ];
  }
  if (filters?.length) {
    let queries = [];
    filters.map((item) => {
      if (item.columnValue === "createdAt") {
        queries.push({
          range: {
            "createdAt._seconds": {
              gt: new Date(item.value[0]).getTime() / 1000,
              lt: new Date(item.value[1]).getTime() / 1000,
            },
          },
        });
      } else if (item.columnValue === "annualRevenue") {
        queries.push({
          range: {
            annual_revenue: {
              gt:
                item.operatorValue === "greaterThan"
                  ? Number(item.value)
                  : null,
              lt:
                item.operatorValue === "lowerThan" ? Number(item.value) : null,
              gte: item.operatorValue === "equals" ? Number(item.value) : null,
              lte: item.operatorValue === "equals" ? Number(item.value) : null,
            },
          },
        });
      } else if (item.operatorValue === "isEmpty") {
        if (
          item.columnValue === "accountName" ||
          item.columnValue === "accountType"
        ) {
          if (query?.bool?.must_not?.length) {
            query.bool.must_not.push({
              exists: {
                field: item.columnValue,
              },
            });
          } else {
            query["bool"]["must_not"] = [
              {
                exists: {
                  field: item.columnValue,
                },
              },
            ];
          }
        } else {
          queries.push({
            regexp: {
              [`${item.columnValue}.keyword`]: "",
            },
          });
        }
      } else if (item.operatorValue === "isNotEmpty") {
        queries.push({
          query_string: {
            query: "?*",
            fields: [item.columnValue],
          },
        });
      } else {
        queries.push({
          query_string: {
            query:
              item.operatorValue === "contains"
                ? `*${item.value}*`
                : item.operatorValue === "equals"
                ? `\"${item.value}\"`
                : item.operatorValue === "doesNotContains" ||
                  item.operatorValue === "not"
                ? `NOT *${item.value}*`
                : item.operatorValue === "isAnyOf"
                ? `${item.value?.map((item) => `\"${item}\"`).join(" OR ")}`
                : null,
            fields: [item.columnValue],
          },
        });
      }
    });
    query.bool.must = queries;
  }

  if (
    sortField === "name" &&
    sortType &&
    (type === "leads" || type === "contacts")
  ) {
    sort = [{ "fullName.keyword": { order: sortType } }];
  } else if (sortField === "createdAt" && sortType) {
    sort = [{ "createdAt._seconds": { order: sortType } }];
  } else if (sortField && sortType) {
    sort = [{ [`${sortField}.keyword`]: { order: sortType } }];
  }

  if (!sortField) {
    sort = [{ "createdAt._seconds": { order: "desc" } }];
  }

  const searchQuery = await axios.post(
    `${elasticHost}/${type}/_search`,
    {
      query,
      sort,
      size: 50,
      from: 50 * pageNumber,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${apiKey}`,
      },
    }
  );
  if (searchQuery.status === 200) {
    const results = searchQuery.data.hits.hits.map((h) => ({
      id: h._id,
      ...h._source,
      createdAt: { seconds: h._source?.createdAt?._seconds },
      annualRevenue: h._source?.annual_revenue,
    }));
    return {
      total: searchQuery.data.hits.total.value,
      results,
    };
  }
  console.log(searchQuery?.data);
  return false;
}

export const CrmFilters = [
  {
    label: "Name",
    value: "fullName",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "does not contains",
        value: "doesNotContains",
      },
    ],
    isLead: true,
    isContact: true,
    isAccount: false,
  },
  {
    label: "Role",
    value: "role",
    operators: [
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is any of",
        value: "isAnyOf",
      },
      {
        label: "is empty",
        value: "isEmpty",
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
      },
    ],
    isLead: true,
    isContact: true,
    isAccount: false,
  },
  {
    label: "Company",
    value: "accountName",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is empty",
        value: "isEmpty",
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
      },
    ],
    isLead: true,
    isContact: true,
    isAccount: false,
  },
  {
    label: "Email",
    value: "email",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is empty",
        value: "isEmpty",
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
      },
    ],
    isLead: true,
    isContact: true,
    isAccount: false,
  },
  {
    label: "Lead status",
    value: "leadStatus",
    operators: [
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is any of",
        value: "isAnyOf",
      },
      {
        label: "is empty",
        value: "isEmpty",
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
      },
    ],
    isLead: true,
    isContact: false,
    isAccount: false,
  },
  {
    label: "Name",
    value: "name",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "does not contains",
        value: "doesNotContains",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: true,
  },
  {
    label: "Account type",
    value: "accountType",
    operators: [
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is any of",
        value: "isAnyOf",
      },
      {
        label: "is empty",
        value: "isEmpty",
      },
      {
        label: "is not empty",
        value: "isNotEmpty",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: true,
  },
  {
    label: "Annual revenue",
    value: "annualRevenue",
    operators: [
      {
        label: "Greater than",
        value: "greaterThan",
      },
      {
        label: "Lower than",
        value: "lowerThan",
      },
      {
        label: "equals",
        value: "equals",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: true,
  },
  {
    label: "Created at",
    value: "createdAt",
    operators: [
      {
        label: "Custom",
        value: "custom",
      },
      {
        label: "Today",
        value: "today",
      },
      {
        label: "Yesterday",
        value: "yesterday",
      },
      {
        label: "Last week",
        value: "lastWeek",
      },
      {
        label: "Last month",
        value: "lastMonth",
      },
      {
        label: "Last 7 days",
        value: "last7days",
      },
      {
        label: "Last 30 days",
        value: "last30days",
      },
    ],
    isLead: true,
    isContact: true,
    isAccount: true,
  },
  {
    label: "Date/Time",
    value: "createdAt",
    operators: [
      {
        label: "Custom",
        value: "custom",
      },
      {
        label: "Today",
        value: "today",
      },
      {
        label: "Yesterday",
        value: "yesterday",
      },
      {
        label: "Last week",
        value: "lastWeek",
      },
      {
        label: "Last month",
        value: "lastMonth",
      },
      {
        label: "Last 7 days",
        value: "last7days",
      },
      {
        label: "Last 30 days",
        value: "last30days",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Channel",
    value: "channelType",
    operators: [
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is any of",
        value: "isAnyOf",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Title",
    value: "title",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "does not contains",
        value: "doesNotContains",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Duration",
    value: "duration",
    operators: [
      {
        label: "Greater than",
        value: "greaterThan",
      },
      {
        label: "Lower than",
        value: "lowerThan",
      },
      {
        label: "equals",
        value: "equals",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Engagement score",
    value: "engagementScore",
    operators: [
      {
        label: "Greater than",
        value: "greaterThan",
      },
      {
        label: "Lower than",
        value: "lowerThan",
      },
      {
        label: "equals",
        value: "equals",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Sentiment",
    value: "sentiment",
    operators: [
      {
        label: "equals",
        value: "equals",
      },
      {
        label: "is any of",
        value: "isAnyOf",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
  {
    label: "Participants",
    value: "participants",
    operators: [
      {
        label: "contains",
        value: "contains",
      },
    ],
    isLead: false,
    isContact: false,
    isAccount: false,
    isPastMeetings: true,
  },
];
