import TextEditor from "../TextEditor";
import ClearIcon from "@mui/icons-material/Clear";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Box, CircularProgress,Tooltip } from "@material-ui/core";
import React from "react";
import { styled } from "@material-ui/core/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";


const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    background: "#2c73ff",
    color: "#fff",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      // animation: "ripple 1.2s infinite ease-in-out",
      // border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: "0 !important",
  },
}));

const NoteTextEditor = ({
  mentionSuggestions,
  isUpdate,
  editorState,
  setEditorState,
  title,
  setTitle,
  onSubmit,
  buttonLoading,
  attachments,
  onFileUpload,
  onRemoveAttachment,
  onCancel,
}) => {
  const onAttachmentClick = (e) => {
    let doc = document.getElementById("noteAttachment");
    if (doc) {
      doc.click();
    }
  };

  const handleFileUpload = (files) => {
    onFileUpload({ target: { files } });
  };

  return (
    <form
      className={`create-note focused`}
      onSubmit={onSubmit}
      id={"createNoteContainer"}
    >
      <div>
        <input
          type="text"
          placeholder={"Title"}
          className="note-title"
          id={"note-title"}
          value={title}
          style={{ width: "100%" }}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <TextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          mentions={mentionSuggestions}
          className={"note-editor"}
          placeholder={"Add a note"}
          handlePastedFiles={(files) =>
            !isUpdate && handleFileUpload(files, editorState, setEditorState)
          }
        />
      </div>

      <div className="controls">
        {!isUpdate && (
          <>
            <input
              type="file"
              style={{ display: "none" }}
              id={"noteAttachment"}
              multiple
              onChange={onFileUpload}
            />
            <LightTooltip
              title={
                <>
                  {attachments?.map((item, index) => (
                    <div
                      className="attachment-tooltip"
                      onClick={() => onRemoveAttachment(item)}
                      key={index}
                    >
                      <span>{item?.name}</span>
                      <ClearIcon
                        className={"clear-icon"}
                        sx={{ color: "#707070", fontSize: 16, ml: 2 }}
                      />
                    </div>
                  ))}
                </>
              }
              placement={"top"}
              componentsProps={{
                popper: { sx: { backgroundColor: "#FFFFE6" } },
              }}
            >
              <StyledBadge
                invisible={attachments?.length === 0}
                badgeContent={attachments?.length}
                max={9}
              >
                <button
                  onClick={onAttachmentClick}
                  type="button"
                  className="attacment-input"
                >
                  <AttachmentIcon
                    sx={{ color: "#2c73ff", transform: "rotate(315deg)" }}
                    fontSize={"small"}
                  />
                </button>
              </StyledBadge>
            </LightTooltip>
          </>
        )}
        {isUpdate ? (
          <Box>
            <button
              className="add-note cancel"
              type={"button"}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="add-note ml"
              disabled={buttonLoading}
              type={"submit"}
            >
              {buttonLoading ? (
                <Box
                  sx={{ minWidth: 50 }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress
                    style={{ color: "inherit" }}
                    size={"15px"}
                  />
                </Box>
              ) : (
                "Update"
              )}
            </button>
          </Box>
        ) : (
          <button className="add-note" disabled={buttonLoading} type={"submit"}>
            {buttonLoading ? (
              <Box
                sx={{ minWidth: 50 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress style={{ color: "inherit" }} size={"15px"} />
              </Box>
            ) : (
              "Create"
            )}
          </button>
        )}
      </div>
    </form>
  );
};

export default NoteTextEditor;
