import React, { useState, useMemo } from "react";
import { Box } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router";
import { sentimentIcons } from "../../../utils/sentimentIcons";

function AccountDetailContactTable({ contacts, analyticData }) {
  const history = useHistory();
  const [data, setData] = useState([]);

  const generateData = () => {
    let allContacts = [];
    contacts.map((contact) => {
      let contactData = {};
      contactData.contactId = contact.contactId;
      contactData.name = `${contact?.firstName ? contact.firstName : ""} ${
        contact?.lastName ? contact.lastName : ""
      }`;
      contactData.role = contact?.role ? contact.role : "";

      let meetingDatas = analyticData?.find(
        (item) => item.contact === contactData.name
      );
      if (meetingDatas && meetingDatas?.meetings?.length) {
        // Recent sentiment
        contactData.recentSentiment =
          meetingDatas.meetings[0]?.sentiment?.emotion || "";

        // Recent engagement
        contactData.recentEngagement = meetingDatas.meetings[0]?.participation
          ?.noShow
          ? "0"
          : meetingDatas.meetings[0]?.participation?.participation+"%" || "";

        // Avarage sentiment && avarage engagements
        let allSentiments = [];
        let allEngagementScores = [];
        meetingDatas.meetings.map((meeting) => {
          if (meeting?.sentiment?.emotion === "POSITIVE_2")
            allSentiments.push(100);
          else if (meeting?.sentiment?.emotion === "POSITIVE_1")
            allSentiments.push(75);
          else if (meeting?.sentiment?.emotion === "NEUTRAL")
            allSentiments.push(50);
          else if (meeting?.sentiment?.emotion === "NEGATIVE_1")
            allSentiments.push(25);
          else if (meeting?.sentiment?.emotion === "NEGATIVE_2")
            allSentiments.push(0);
          if (meeting?.participation?.noShow) allEngagementScores.push(0);
          else if (meeting?.participation?.participation)
            allEngagementScores.push(
              parseFloat(meeting.participation.participation)
            );
        });
        let avarageSentimentLabel;
        let avarageSentimentCount =
          allSentiments.reduce((a, b) => a + b, 0) / allSentiments.length || 0;
        if (avarageSentimentCount === 100) avarageSentimentLabel = "POSITIVE_2";
        else if (avarageSentimentCount > 74)
          avarageSentimentLabel = "POSITIVE_1";
        else if (avarageSentimentCount > 49) avarageSentimentLabel = "NEUTRAL";
        else if (avarageSentimentCount > 24)
          avarageSentimentLabel = "NEGATIVE_1";
        else if (avarageSentimentCount > 0)
          avarageSentimentLabel = "NEGATIVE_2";
        contactData.avarageSentiment = avarageSentimentLabel;
        contactData.avarageEngagement =
          `${(
            allEngagementScores.reduce((a, b) => a + b, 0) /
            allSentiments.length
          ).toFixed(2)}%` || "";
      }
      allContacts.push(contactData);
    });
    console.log(allContacts, "all contactss");
    setData(allContacts);
  };

  useMemo(generateData, [contacts, analyticData]);

  return (
    <div className="shadow-container" style={{ width: "100%" }}>
      <div className="details-box account-table notes">
        <table className="tasks-table">
          <thead>
            <tr>
              <th style={{ minWidth: "150px" }}>Name & Role</th>
              <th style={{ minWidth: "150px" }}>Avg. Sentiment</th>
              <th style={{ minWidth: "150px" }}>Recent Sentiment</th>
              <th style={{ minWidth: "150px" }}>Avg. Engagement</th>
              <th style={{ minWidth: "150px" }}>Recent Engagement</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((contact, index) => (
              <tr key={index}>
                <td
                  onClick={() =>
                    history.push(`/crm/contact/${contact.contactId}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Box display="flex">
                    <Avatar
                      src={contact?.avatar}
                      sx={{
                        width: 40,
                        height: 40,
                        border: "1px solid #2C73FF",
                      }}
                    />
                    <Box
                      sx={{
                        ml: 1,
                        display: contact?.role ? "block" : "flex",
                        alignItems: contact?.role ? "unset" : "center",
                      }}
                    >
                      <h6 className="note-profile-name">{contact.name}</h6>
                      {contact?.role && (
                        <span className="note-date">{contact.role}</span>
                      )}
                    </Box>
                  </Box>
                </td>
                <td>
                  {contact?.avarageSentiment
                    ? sentimentIcons[contact.avarageSentiment]
                    : ""}
                </td>
                <td>
                  {contact?.recentSentiment
                    ? sentimentIcons[contact.recentSentiment]
                    : ""}
                </td>
                <td>{contact?.avarageEngagement}</td>
                <td>{contact?.recentEngagement}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="shadow" />
    </div>
  );
}

export default AccountDetailContactTable;
