import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import useAuth from "../../hooks/useAuth";
import { Delete, Get, Patch, Post } from "../../utils/axiosUtils";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "@draft-js-plugins/mention/lib/plugin.css";
import NoteTextEditor from "./NoteTextEditor";
import NotesAccordion from "./NotesAccordion";

const NoteRows = ({ createMode, setCreateMode, typeId, type, height }) => {
  const { fireStoreuserObj } = useAuth();
  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedNote, setExpandedNote] = useState(null);

  const getNotes = () => {
    Get(`notes/${type}/${typeId}`)
      .then(({ data }) => {
        setNotes(data.data);
        getMentionOptions()
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log("mentions couldnt loaded successfully", err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("err happing when fetching notes", err);
      });
  };
  useEffect(getNotes, []);

  const getMentionOptions = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (jwtToken) => {
          let result = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
            jwtToken
          );
          if (result.status === "success") {
            let suggestionData = result.data.members?.filter(
              (member) => member.id !== fireStoreuserObj.uid
            );
            let suggestionToEditor = [];
            suggestionData?.map((member) => {
              suggestionToEditor.push({
                name: member?.name,
                url: "",
                avatar: member?.avatar,
                id: member?.id,
              });
            });
            setMentionSuggestions(suggestionToEditor);
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const createNote = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    let postBody = {
      type,
      typeid: typeId,
      content: convertToRaw(editorState.getCurrentContent()),
      title,
    };
    Post("notes/create", postBody)
      .then(({ data }) => {
        if (attachments?.length > 0) {
          attachments.map((file) => {
            let formData = new FormData();
            formData.append("attachment", file);
            formData.append("type", "notes");
            formData.append("typeid", data.data.id);
            Post("attachments/create", formData, {
              "Content-Type": "multipart/form-data; boundary=1",
            }).catch((err) => {
              console.log(err, "error when uploading files");
            });
          });
          setAttachments([]);
        }
        setNotes([data.data, ...notes]);
        setCreateLoading(false);
        setTitle("");
        setEditorState(EditorState.createEmpty());
        setCreateMode("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fileUpload = (e) => {
    let newFiles = [...attachments, ...e.target.files];
    setAttachments(newFiles);
  };

  const removeAttachment = (file) => {
    let files = [...attachments];
    let filteredFiles = files.filter((item) => item.name !== file.name);
    setAttachments(filteredFiles);
  };

  const handleScrollbar = () => {
    let doc = document.getElementById("scroolContainer");
    if (doc) {
      doc?.classList?.add("on-scroll");
    }
    setTimeout(() => {
      if (doc && doc?.classList?.contains("on-scroll")) {
        doc.classList.remove("on-scroll");
      }
    }, 1000);
  };

  return (
    <div className="shadow-container" style={height ? {height} : {} }>
      <div
        className="details-box notes"
        id={"scroolContainer"}
        onScroll={handleScrollbar}
        style={height ? {height:'100%'} : {}}
      >
        {createMode && (
          <NoteTextEditor
            mentionSuggestions={mentionSuggestions}
            editorState={editorState}
            setEditorState={setEditorState}
            title={title}
            setTitle={setTitle}
            buttonLoading={createLoading}
            onSubmit={createNote}
            attachments={attachments}
            onFileUpload={fileUpload}
            onRemoveAttachment={removeAttachment}
          />
        )}
        <div className="note-table-container">
          {loading ? (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              height={200}
            >
              <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
            </Box>
          ) : (
            <>
              {notes?.map((note, index) => (
                <NotesAccordion
                  key={index}
                  mentionSuggestions={mentionSuggestions}
                  setNotes={setNotes}
                  notes={notes}
                  note={note}
                  expandedNote={expandedNote}
                  setExpandedNote={setExpandedNote}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className="shadow" />
    </div>
  );
};

function Notes({ type, typeId, height }) {
  const [mode, setMode] = useState("");

  const clearAnimation = () => {
    setTimeout(() => {
      let doc = document.getElementById("createNoteContainer");
      if (doc) {
        doc.style.animation = "1s anyanimation forwards"; // this line just placeholder for removing current animation
        doc.style.maxHeight = "1000px";
        doc.style.minHeight = "260px";
        doc.style.height = "auto";
      }
    }, 800);
  };

  return (
    <>
      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
        <h5 className="agenda">Notes</h5>
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          onClick={() => {
            if (mode === "create") {
              setMode("");
            } else {
              setMode("create");
              clearAnimation();
            }
          }}
          className={mode === "create" ? "rotateBack" : "rotateIcon"}
        />
      </Box>
      <NoteRows
        createMode={mode === "create"}
        setCreateMode={setMode}
        type={type}
        typeId={typeId}
        height={height}
      />
    </>
  );
}

export default Notes;
