import { Box, Button, TextareaAutosize, Container } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "./../../../firebase/firebase.js";
import {
  saveLead,
  getOrganization,
  getOrgainzationMembers,
  getUserByUserId,
  getOrganizationContactByEmail,
  getOrganizationLeadByEmail,
} from "./../../../firebase/firestore.js";
import * as actions from "./../../../redux/actions";
import { connect } from "react-redux";
import { camelCase } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { LeadFieldsArray } from "./../../settings/CRMSettings/LeadFieldsArray";
const CreateLead = (props) => {
  const history = useHistory();

  const [fieldsArray, setFieldsArray] = useState([]);
  const [fieldsStateObject, setFieldsStateObject] = useState({});

  useEffect(() => {
    (async () => {
      let organization = await getOrganization();
      let organizationMembers = await getOrgainzationMembers();
      let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

      setFieldsArray(organization.leadFieldsSetting);

      // else {
      //   setFieldsArray(LeadFieldsArray);
      //   tmpFieldsArray = LeadFieldsArray;
      // }

      let tempStateObject = {};
      organization.leadFieldsSetting.map((f) => {
        let defaultVal = f.defaultValue ? f.defaultValue : "";

        if (f.type === "select") {
          if (f.title === "Lead Owner") {
            if (organizationMembers) {
              f.options = [];
              organizationMembers.members.map((member) => {
                let owner = {
                  title: member.name,
                  value: member.id,
                  isDefault:
                    member.id === firebase.auth().currentUser.uid
                      ? true
                      : false,
                };
                f.options.push(owner);
              });
            } else {
              f.options = [
                {
                  title: userDetails.displayName,
                  value: firebase.auth().currentUser.uid,
                  isDefault: true,
                },
              ];
            }
          }

          let defaultOpt = f.options.find((opt) => opt.isDefault);
          if (defaultOpt) {
            // defaultVal = defaultOpt.title;
            defaultVal = defaultOpt.value ? defaultOpt.value : defaultOpt.title;
          }
        }
        tempStateObject[f.title] = {
          value: defaultVal,
          error: false,
          errorMsg: "",
          ...f,
        };
      });
      setFieldsStateObject(tempStateObject);
    })();
  }, []);

  const handleChangeField = (fieldTitle, value) => {
    let tempStateObject = { ...fieldsStateObject };
    tempStateObject[fieldTitle].value = value;
    if (tempStateObject[fieldTitle].defaultValue) {
      tempStateObject[fieldTitle].defaultValue = "";
    }
    setFieldsStateObject(tempStateObject);
  };

  // const [firstName, setFirstName] = useState("Ronak");
  // const [firstNameError, setFirstNameError] = useState(false);

  // const [lastName, setLastName] = useState("Patel");
  // const [lastNameError, setLastNameError] = useState(false);

  // const [role, setRole] = useState("");
  // const [company, setCompany] = useState("");

  // const [emailAddress, setEmailAddress] = useState("ronak@patel.com");
  // const [emailAddressError, setEmailAddressError] = useState(false);

  // const [mobileNumber, setMobileNumber] = useState("");
  // const [telephoneNumber, setTelephoneNumber] = useState("");
  // const [address, setAddress] = useState("");
  // const [website, setWebsite] = useState("");
  // const [noOFEmployee, setNoOFEmployee] = useState("");
  // const [leadStatus, setLeadStatus] = useState("");
  // const [leadSource, setLeadSource] = useState("");
  // const [description, setDescription] = useState("");

  const handleSubmit = async () => {
    let validationError = false;

    let tempStateObject = { ...fieldsStateObject };

    for (let key in tempStateObject) {
      if (tempStateObject[key].error) {
        tempStateObject[key].error = false;
        tempStateObject[key].errorMsg = "";
      }

      if (tempStateObject[key].isRequired && tempStateObject[key].value == "") {
        tempStateObject[key].error = true;
        tempStateObject[key].errorMsg = "This field is required";
        validationError = true;
      }
    }

    //check for unique email per lead and contact

    if (tempStateObject["Email"].value) {
      let contactsByEmail = await getOrganizationContactByEmail(
        tempStateObject["Email"].value
      );
      let leadsByEmail = await getOrganizationLeadByEmail(
        tempStateObject["Email"].value
      );

      if (contactsByEmail.length) {
        tempStateObject["Email"].error = true;
        tempStateObject["Email"].errorMsg =
          "Contact already exist with this email address!";
        validationError = true;
      }
      if (leadsByEmail.length) {
        tempStateObject["Email"].error = true;
        tempStateObject["Email"].errorMsg =
          "Lead already exist with this email address!";
        validationError = true;
      }
    }

    setFieldsStateObject(tempStateObject);

    if (validationError) {
      return false;
    } else {
      let leadDetails = {};
      for (let k in tempStateObject) {
        leadDetails[camelCase(k)] = tempStateObject[k].value;
      }

      let res = await saveLead(leadDetails);

      if (res.success) {
        props.showSnackbar({
          show: true,
          severity: "success",
          message: "Lead created successfully.",
        });

        history.push({
          pathname: `/crm/leads`,
          state: { from: "createLead" },
        });
      } else {
        props.showSnackbar({
          show: true,
          severity: "error",
          message: res.message,
        });
      }
    }
  };

  // const handleSubmitOld = async () => {
  //   let validationError = false;

  //   if (!firstName) {
  //     setFirstNameError(true);
  //     validationError = true;
  //   } else {
  //     setFirstNameError(false);
  //   }

  //   if (!lastName) {
  //     setLastNameError(true);
  //     validationError = true;
  //   } else {
  //     setLastNameError(false);
  //   }

  //   if (
  //     !emailAddress ||
  //     !emailAddress.match(
  //       /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     )
  //   ) {
  //     setEmailAddressError(true);
  //     validationError = true;
  //   } else {
  //     setEmailAddressError(false);
  //   }

  //   if (validationError) {
  //     return false;
  //   }
  //   let leadDetails = {
  //     firstName,
  //     lastName,
  //     role,
  //     company,
  //     emailAddress,
  //     mobileNumber,
  //     telephoneNumber,
  //     address,
  //     website,
  //     noOFEmployee,
  //     leadStatus,
  //     leadSource,
  //     description,
  //   };
  //   let res = await saveLead(leadDetails);

  //   if (res.success) {
  //     props.showSnackbar({
  //       show: true,
  //       severity: "success",
  //       message: "Lead created successfully.",
  //     });
  //     history.push("/crm/leads");
  //   } else {
  //     props.showSnackbar({
  //       show: true,
  //       severity: "error",
  //       message: res.message,
  //     });
  //   }
  // };

  const getDefaultOptionFromList = (options) => {
    let defaultVal = "";
    options.map((opt) => {
      if (opt.isDefault) {
        defaultVal = opt.value ? opt.value : opt.title;
      }
    });
    return defaultVal;
  };

  return (
    <Container maxWidth={"xl"}>
      <Box
        padding={2}
        flexDirection="column"
        justifyContent="space-between"
        display="flex"
        minHeight={700}
        //   height={1}
      >
        <Box paddingBottom={2}>
          <h2>Create Lead</h2>
        </Box>

        <Box
          width={2 / 3}
          flexWrap="wrap"
          display="flex"
          justifyContent={fieldsArray?.length ? "space-between" : "center"}
          alignItems="center"
          margin="auto"
          backgroundColor={fieldsArray?.length && "#fff"}
          borderRadius="15px"
        >
          {!fieldsArray?.length ? (
            <CircularProgress size={36} sx={{ color: "#2c73ff" }} />
          ) : (
            fieldsArray.map((field) => {
              return (
                <Box width={{ md: 1 / 2, xs: "100%" }} padding={5}>
                  {field.type == "text" && fieldsStateObject[field.title] && (
                    <Box>
                      <TextField
                        error={fieldsStateObject[field.title].error}
                        required={field.isRequired}
                        label={field.title}
                        value={
                          fieldsStateObject[field.title].value
                            ? fieldsStateObject[field.title].value
                            : fieldsStateObject[field.title].defaultValue
                            ? fieldsStateObject[field.title].defaultValue
                            : ""
                        }
                        onChange={(e) =>
                          handleChangeField(field.title, e.target.value)
                        }
                        variant="outlined"
                        helperText={
                          fieldsStateObject[field.title].error
                            ? fieldsStateObject[field.title].errorMsg
                            : ""
                        }
                        fullWidth
                      />
                    </Box>
                  )}

                  {field.type == "select" && fieldsStateObject[field.title] && (
                    <Box>
                      <FormControl
                        fullWidth
                        error={fieldsStateObject[field.title].error}
                        required={field.isRequired}
                      >
                        <InputLabel id={`${field.title}-select`}>
                          {field.title}
                        </InputLabel>
                        <Select
                          labelId={`${field.title}-select`}
                          label={field.title}
                          value={
                            fieldsStateObject[field.title].value
                              ? fieldsStateObject[field.title].value
                              : getDefaultOptionFromList(
                                  fieldsStateObject[field.title].options
                                )
                          }
                          onChange={(e) => {
                            handleChangeField(field.title, e.target.value);
                          }}
                        >
                          {field.options.map((option) => {
                            return (
                              <MenuItem
                                value={
                                  option.value ? option.value : option.title
                                }
                              >
                                {option.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {fieldsStateObject[field.title].error && (
                          <FormHelperText>
                            {fieldsStateObject[field.title].errorMsg}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  )}

                  {field.type == "multi-line-text" &&
                    fieldsStateObject[field.title] && (
                      <Box>
                        <TextareaAutosize
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderColor: "#DCDCDC",
                            borderRadius: "10px",
                          }}
                          maxRows={10}
                          minRows={4}
                          placeholder={field.title}
                          onChange={(e) =>
                            handleChangeField(field.title, e.target.value)
                          }
                          value={fieldsStateObject[field.title].value}
                        ></TextareaAutosize>
                      </Box>
                    )}

                  {field.type == "checkbox" && fieldsStateObject[field.title] && (
                    <Box>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                fieldsStateObject[field.title].value
                                  ? fieldsStateObject[field.title].value
                                  : fieldsStateObject[field.title].defaultValue
                                  ? fieldsStateObject[field.title].defaultValue
                                  : false
                              }
                              onChange={(e) => {
                                handleChangeField(
                                  field.title,
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label={field.title}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Box>
              );
            })
          )}
          {fieldsArray?.length ? (
            <Box width={1 / 2} display="flex" justifyContent="flex-start">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                style={{ marginLeft: "40px" }}
              >
                Create
              </Button>

              <Button
                color="secondary"
                style={{ marginLeft: "30px" }}
                onClick={() => history.push("/crm/leads")}
              >
                Cancel
              </Button>
            </Box>
          ) : null}
        </Box>

        {/* <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <TextField
            error={firstNameError}
            required
            label="First name"
            // value={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
            helperText={firstNameError ? "First name is required" : ""}
          />
        </Box>
        <Box width={1 / 3}>
          <TextField
            error={lastNameError}
            required
            label="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
            helperText={lastNameError ? "Last name is required" : ""}
          />
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <Box width={5 / 6}>
            <FormControl fullWidth>
              <InputLabel id="role-select">Role</InputLabel>
              <Select
                labelId="role-select"
                label="Role"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <MenuItem value="Decision Maker">Decision Maker</MenuItem>
                <MenuItem value="Influencer">Influencer</MenuItem>
                <MenuItem value="Evaluator">Evaluator</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box width={1 / 3}>
          <TextField
            label="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <TextField
            error={emailAddressError}
            required
            email
            label="Email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            variant="outlined"
            helperText={
              emailAddressError ? "Valid email address is required" : ""
            }
          />
        </Box>
        <Box width={1 / 3}>
          <TextField
            label="Mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <TextField
            label="Telephone number"
            value={telephoneNumber}
            onChange={(e) => setTelephoneNumber(e.target.value)}
            variant="outlined"
          />
        </Box>
        <Box width={1 / 3}>
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <TextField
            label="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            variant="outlined"
          />
        </Box>
        <Box width={1 / 3}>
          <TextField
            label="No. Of employee"
            value={noOFEmployee}
            onChange={(e) => setNoOFEmployee(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <Box width={5 / 6}>
            <FormControl fullWidth>
              <InputLabel id="lead-status-select">Lead status</InputLabel>
              <Select
                labelId="lead-status-select"
                value={leadStatus}
                label="Lead status"
                onChange={(e) => setLeadStatus(e.target.value)}
              >
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Not Qualified">Not Qualified</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box width={1 / 3}>
          <Box width={5 / 6}>
            <FormControl fullWidth>
              <InputLabel id="lead-source-select">Lead source</InputLabel>
              <Select
                labelId="lead-source-select"
                value={leadSource}
                label="Lead source"
                onChange={(e) => setLeadSource(e.target.value)}
              >
                <MenuItem value="Google Ads">Google Ads</MenuItem>
                <MenuItem value="Webform">Webform</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex">
        <Box width={1 / 3}>
          <TextareaAutosize
            style={{ width: "83%" }}
            maxRows={10}
            minRows={4}
            placeholder="Description..."
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></TextareaAutosize>
        </Box>
        <Box width={1 / 3}></Box>
      </Box> */}

        {/* <Box
          justifyContent="space-around"
          width={1}
          display="flex"
        >
          <Box width={1 / 3}></Box>
          <Box width={1 / 3}>
            <Box width={1 / 2} display="flex" justifyContent="space-around">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
              >
                Create
              </Button>

              <Button
                color="secondary"
                onClick={() => history.push("/crm/leads")}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLead);
