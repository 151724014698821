import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

import {
  Grid,
  Box,
  Popover,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import GoogleMeetIcon from "../../assets/images/google-meet-logo.png";
import ZoomIcon from "../../assets/images/zoom.png";
import MsTeamsIcon from "../../assets/images/ms-team-logo.png";
import RecordingIcon from "../../assets/images/recording_meeting.png";
import { Get } from "../../utils/axiosUtils";
import { PastMeetingsList, UpcomingMeetingsUrl } from "../../utils/endpoints";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { tooltipClasses } from "@mui/material/Tooltip";
import { sentimentIcons } from "../../utils/sentimentIcons";
import { elasticSearchMeeting } from "./tabularDataConstants";
import useAuth from "../../hooks/useAuth";
import { getElasticToken, getOrgainzation } from "../../firebase/firestore";
import TabularFilterMenu from "../CRM/TabularFilterMenu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TabularColumnsMenu from "../CRM/TabularColumnsMenu";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  // animation: 'ripple 1.2s infinite ease-in-out',
  width: 10,
  height: 12,
  // border: `1px solid ${theme.palette.background.paper}`,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundImage: `url(/images/host.png)`,
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    left: 0,
    width: "5px",
    backgroundSize: "cover",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function ParticipantsAvatars(props) {
  const { row, type } = props;

  let organizerName = "";
  if (props.organizerName !== undefined) {
    organizerName = props.organizerName;
  }

  // console.log('organizerName',organizerName)
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { participants } = row;
  if (participants !== undefined && organizerName !== undefined) {
    participants.organizerName = organizerName;
  }

  // sort by name
  // participants.sort(function (a, b) {
  //   console.log("a", a);
  //   console.log("b", b);
  //   var nameA = a.name; // ignore upper and lowercase
  //   var nameB = b.name; // ignore upper and lowercase
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // console.log('participants', participants)

  if (participants && participants.length > 0) {
    return (
      <Grid container onClick={(e) => e.stopPropagation()}>
        <Grid item xs={12} lg={8}>
          <AvatarGroup
            total={type === "past" ? 3 : 5}
            sx={{ "& > * ": { borderWidth: "1px !important" } }}
          >
            {participants.map((p, index) => {
              const { name, avatar, email } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
                if (fChar === "" && email !== undefined && email !== "") {
                  fChar = email.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              return (
                <Avatar
                  key={index}
                  alt={name}
                  src={avatar}
                  className="bulk-avatar"
                  title={name}
                  style={
                    participants.organizerName &&
                    name === participants.organizerName
                      ? {
                          border: "1px solid #2C73FF",
                          height: 20,
                          width: 20,
                        }
                      : {
                          border: "1px solid #fff",
                          height: 20,
                          width: 20,
                        }
                  }
                >
                  {fChar}
                </Avatar>
              );
            })}
          </AvatarGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <a
            // href="/"
            aria-describedby={row.id}
            onClick={handleClick}
            className="expand-arrow-icon"
          >
            {open ? (
              <ExpandLessIcon className="arrow" />
            ) : (
              <ExpandMoreIcon className="arrow" />
            )}
          </a>
          <Popover
            id={row.id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { minWidth: "147px", paddingRight: "20px" },
            }}
          >
            {participants.map((p, index) => {
              // console.log('ppp',p)
              const { name, avatar, email } = p;
              let fChar = "";
              if (avatar === undefined || avatar === "") {
                if (name !== undefined && name !== "") {
                  fChar = name.charAt(0);
                }
                if (fChar === "" && email !== undefined && email !== "") {
                  fChar = email.charAt(0);
                }
              }
              if (fChar !== "") {
                fChar = fChar.toUpperCase();
              }
              let txtToDisplay = name;
              if (name === undefined || name === "") {
                txtToDisplay = email;
              }
              return (
                <Grid key={index} container className="past-popup-participants">
                  <Grid item xs={3} lg={3}>
                    {participants.organizerName &&
                    name == participants.organizerName ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        variant="dot"
                        title="Organiser"
                        badgeContent={
                          <SmallAvatar
                            alt="Remy Sharp"
                            src="/images/host.png"
                          />
                        }
                      >
                        <Avatar
                          alt={name}
                          src={avatar}
                          className="pop-avatar"
                          style={{
                            border: "1px solid #2C73FF",
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      <Avatar
                        alt={name}
                        src={avatar}
                        className="pop-avatar"
                        title={name}
                        // style={{
                        //   border: "1px solid #7DA8FB",
                        // }}
                      >
                        {fChar}
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs={9} lg={9}>
                    {txtToDisplay}
                  </Grid>
                </Grid>
              );
            })}
          </Popover>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const CustomToolbar = (props) => {
  let {
    setActiveFilters,
    currentPage,
    handlePageChange,
    totalRecords,
    columns,
    activeColumns,
    setActiveColumns,
  } = props;
  return (
    <GridToolbarContainer
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: ".5rem",
      }}
    >
      <Box display="flex" alignItems="center">
        <TabularColumnsMenu
          columns={columns.filter((item) => item.field !== "channelType")} // removing channel type from columns hide/show menu
          activeColumns={activeColumns}
          setActiveColumns={setActiveColumns}
        />
        <TabularFilterMenu
          type={"pastMeetings"}
          setFilters={setActiveFilters}
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        {`${totalRecords > 0 ? currentPage * 50 + 1 : 0}-${
          (currentPage + 1) * 50 < totalRecords
            ? (currentPage + 1) * 50
            : totalRecords
        } of ${totalRecords}`}
        <Box display="flex" alignItems="center" justifyContent="center">
          <ChevronLeftIcon
            className={`tabular-data-pagination-icon ${
              currentPage - 1 < 0 ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          <ChevronRightIcon
            className={`tabular-data-pagination-icon ${
              currentPage + 1 > Math.floor(totalRecords / 50) ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default function MeetingTable({ width, height, type, isFull }) {
  const { fireStoreuserObj } = useAuth();
  const [organizationSettings, setOrganizationSettings] = useState(null);
  const history = useHistory();
  const [meetingData, setMeetingData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(isFull && type === "past" ? 50 : 15);
  const [elasticToken, setElasticToken] = useState(null);
  const [activeFilters, setActiveFilters] = useState({ items: [] });
  const [activeSort, setActiveSort] = useState([]);
  const [activeColumns, setActiveColumns] = useState(
    isFull && type === "past"
      ? [
          "date",
          "title",
          "duration",
          "engagementScore",
          "sentimentScore",
          "participants",
        ]
      : !isFull && type === "past"
      ? ["date", "title", "duration", "participants"]
      : type === "upcoming"
      ? ["date", "title", "participants"]
      : []
  );
  const [totalColumns, setTotalColumns] = useState([]);

  const fetchData = (isFilterClear = false) => {
    let payload = { perPage: isFull ? pageSize : 20, page: currentPage + 1 };
    let apiUrl =
      type === "past"
        ? PastMeetingsList
        : type === "upcoming"
        ? UpcomingMeetingsUrl
        : "";
    Get(apiUrl, payload)
      .then((res) => {
        const { data, total } = res.data;
        if (isFilterClear) {
          setMeetingData([...data]);
        } else {
          setMeetingData([...meetingData, ...data]);
        }
        setTotalRecords(total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPastMeetingDataFromElasticSearch = () => {
    setCurrentPage(0);
    elasticSearchMeeting(
      activeFilters,
      activeSort[0]?.field,
      activeSort[0]?.sort,
      currentPage,
      elasticToken
    )
      .then((data) => {
        if (data?.results?.length > -1) {
          setMeetingData(data.results);
          setTotalRecords(data.total);
        }
      })
      .catch((err) => {
        console.log(err, "new elastic search response");
      });
  };

  useEffect(() => {
    setLoading(true);
    let loadDatas = async () => {
      let elasticTokenValue = await getElasticToken();
      let organizationSettings = await getOrgainzation(
        fireStoreuserObj.organizationId
      );
      setOrganizationSettings(
        organizationSettings ? organizationSettings : null
      );
      setElasticToken(elasticTokenValue);
    };
    if (type === "past") {
      loadDatas();
    }
  }, [type]);

  const onFilterChange = (newFilters) => {
    if (
      newFilters.items[0]?.columnField ===
        activeFilters.items[0]?.columnField &&
      newFilters.items[0]?.operatorValue ===
        activeFilters.items[0]?.operatorValue &&
      activeFilters.items[0]?.value === newFilters.items[0]?.value
    ) {
      setActiveFilters({ items: [] });
    } else {
      setActiveFilters(newFilters);
    }
  };

  const handleSortModelChange = (sortModel) => {
    // Here you save the data you need from the sort model
    setActiveSort(sortModel);
  };

  const handleRowClick = (meeting) => {
    if (meeting.meetingUrl !== "" && type === "upcoming") {
      window.open(meeting.meetingUrl, "_blank");
    } else {
      history.push(`/meeting/${meeting.meetingId}`, {
        meetingData: meeting.meetingData,
      });
    }
  };

  const generateRows = () => {
    console.log(meetingData, "meeting data");
    const columns = [
      {
        field: "date",
        headerName: "Date/Time",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 96,
        flex: 1,
      },
      {
        field: "channelType",
        hide: true,
        type: "singleSelect",
        valueOptions: [
          {
            value: "GOOGLE_MEET",
            label: "Google Meet",
          },
          {
            value: "ZOOM",
            label: "Zoom",
          },
          {
            value: "MS_TEAMS",
            label: "MS Teams",
          },
        ],
        headerName: "Channel",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: Math.floor((width / 5) * 0.6),
        flex: 1,
        renderCell: (params) => {
          return (
            <img
              src={params.row.meetingType}
              alt="channelType"
              className="data-table-channel-type-icon"
            />
          );
        },
      },
      {
        field: "title",
        headerName: "Title",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 112,
        flex: 1,
        renderCell: (params) => {
          return params?.row?.title?.length > ((width / 5) * 1.4) / 10 ? (
            <>
              <img
                style={{ marginRight: "5px" }}
                src={params.row.meetingType}
                alt="channelType"
                className="data-table-channel-type-icon"
              />
              <LightTooltip
                title={params.row.title}
                componentsProps={{
                  popper: { sx: { backgroundColor: "#FFFFE6" } },
                }}
              >
                <span className="data-table-title">{params.row.title}</span>
              </LightTooltip>
            </>
          ) : (
            <>
              <img
                style={{ marginRight: "5px" }}
                src={params.row.meetingType}
                alt="channelType"
                className="data-table-channel-type-icon"
              />
              <span className="data-table-title">{params.row.title}</span>
            </>
          );
        },
      },
      {
        field: "participants",
        headerName: "Participants",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 80,
        flex: 1,
        renderCell: (params) => {
          return (
            <ParticipantsAvatars
              row={params.row.meetingData}
              organizerName={params.row.displayName}
              type={type}
            />
          );
        },
        valueGetter: (params) =>
          params.row.meetingData?.participants
            ? params.row.meetingData.participants
            : { length: 0 },
      },
    ];

    if (type === "past") {
      columns.splice(3, 0, {
        field: "duration",
        headerName: "Duration",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 64,
        flex: 1,
      });
    }

    if (type === "past" && isFull) {
      columns.splice(4, 0, {
        field: "engagementScore",
        headerName: "Engagement Score",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 64,
        flex: 1,
      });
      let sentimentSettings = organizationSettings?.sentimentSettings;
      if (
        (fireStoreuserObj.role === "MEMBER" &&
          sentimentSettings?.users === "all") ||
        (fireStoreuserObj.role === "TEAM_MANAGER" &&
          sentimentSettings?.managers === "all") ||
        (fireStoreuserObj.role === "ADMIN" &&
          sentimentSettings?.admins === "all") ||
        !sentimentSettings
      ) {
        columns.splice(5, 0, {
          field: "sentimentScore",
          headerName: "Sentiment",
          headerClassName: "data-table-header",
          cellClassName: "data-table-cell",
          type: "singleSelect",
          valueOptions: [
            {
              value: "POSITIVE_2",
              label: "Very positive",
            },
            {
              value: "POSITIVE_1",
              label: "Positive",
            },
            {
              value: "NEUTRAL",
              label: "Neutral",
            },
            {
              value: "NEGATIVE_1",
              label: "Negative",
            },
            {
              value: "NEGATIVE_2",
              label: "Very negative",
            },
          ],
          minWidth: 64,
          flex: 1,
          renderCell: (params) => {
            if (params.row.sentimentScore === 100) {
              return sentimentIcons.POSITIVE_2;
            } else if (params.row.sentimentScore >= 75) {
              return sentimentIcons.POSITIVE_1;
            } else if (params.row.sentimentScore >= 50) {
              return sentimentIcons.NEUTRAL;
            } else if (params.row.sentimentScore >= 25) {
              return sentimentIcons.NEGATIVE_1;
            } else if (params.row.sentimentScore > 0) {
              return sentimentIcons.NEGATIVE_2;
            } else {
              return "";
            }
          },
        });
      }
    }

    const rows = [];
    meetingData.map((meeting, index) => {
      let meetingUrl = "";
      meeting.htmlLink && meeting.htmlLink !== null
        ? (meetingUrl = meeting.htmlLink)
        : (meetingUrl = "");

      let displayName = "";
      if (
        meeting.organizer !== undefined &&
        meeting.organizer.displayName !== undefined
      ) {
        displayName = meeting.organizer.displayName;
      }

      const allEngagements = [];
      const allSentiments = [];
      meeting?.participants?.map((participant) => {
        if (participant?.participation) {
          if (participant.participation?.noShow) {
            allEngagements.push(0);
          } else {
            allEngagements.push(
              Number(participant.participation.participation)
            );
          }
        }
        if (participant?.sentiment?.emotion) {
          if (participant.sentiment.emotion === "POSITIVE_2") {
            allSentiments.push(100);
          } else if (participant.sentiment.emotion === "POSITIVE_1") {
            allSentiments.push(75);
          } else if (
            participant.sentiment.emotion === "NEUTRAL" ||
            participant.sentiment.emotion === "MIXED"
          ) {
            allSentiments.push(50);
          } else if (participant.sentiment.emotion === "NEGATIVE_1") {
            allSentiments.push(25);
          } else if (participant.sentiment.emotion === "NEGATIVE_2") {
            allSentiments.push(0);
          }
        }
      });
      const sum = (previous, current) => {
        if (!current) {
          return previous;
        }
        return previous + current;
      };
      const calculatedEngagementScore =
        allEngagements?.length > 0
          ? Math.round(allEngagements.reduce(sum) / allEngagements.length)
          : "";
      const calculatedSentimentScore =
        allSentiments?.length > 0
          ? Math.round(allSentiments.reduce(sum) / allSentiments.length)
          : "";
      rows.push({
        id: index + meeting.id,
        date: meeting?.startTime
          ? isFull
            ? moment(meeting.startTime).format("lll")
            : moment(meeting.startTime).format("MMM DD, h:mm A")
          : "",
        meetingType:
          meeting?.channelType === "GOOGLE_MEET"
            ? GoogleMeetIcon
            : meeting?.channelType === "ZOOM"
            ? ZoomIcon
            : meeting?.channelType === "MS_TEAMS"
            ? MsTeamsIcon
            : meeting?.channelType === "RECORDING"
            ? RecordingIcon
            : "",
        title: meeting?.title,
        duration: meeting?.duration
          ? moment
              .utc(meeting.duration * 1000)
              .format("H[h] m[ mins]")
              .replace(/0h/, "")
              .replace(/0m/, "")
              .replace(/1 mins/, "1 min")
          : "",
        displayName: displayName,
        meetingData: {
          ...meeting,
          createdAt: {
            seconds: meeting?.createdAt?._seconds,
            nanoseconds: meeting?.createdAt?._nanoseconds,
          },
          endedAt: {
            seconds: meeting?.endedAt?._seconds,
            nanoseconds: meeting?.endedAt?._nanoseconds,
          },
          updatedAt: {
            seconds: meeting?.updatedAt?._seconds,
            nanoseconds: meeting?.updatedAt?._nanoseconds,
          },
          participants:
            meeting?.participants?.filter((item) => item.name) || [],
        },
        meetingId: meeting?.id,
        meetingUrl: meetingUrl,
        channelType: meeting?.channelType,
        engagementScore:
          calculatedEngagementScore > 0 ? calculatedEngagementScore + "%" : "",
        sentimentScore: calculatedSentimentScore,
      });
    });
    let getActiveColumns = columns.filter((item) =>
      activeColumns.includes(item.field)
    );
    setTableRows(rows);
    setTotalColumns(columns);
    setTableColumns(getActiveColumns);
    setLoading(false);
  };

  useMemo(generateRows, [meetingData, activeColumns]);
  useMemo(() => {
    if (type === "past" && elasticToken) {
      fetchPastMeetingDataFromElasticSearch();
    }
  }, [activeFilters, activeSort, elasticToken]);
  useMemo(() => {
    if (type === "upcoming") {
      fetchData();
    }
  }, [currentPage, pageSize]);

  const handlePageChange = (pageNumber) => {
    if (type === "past") {
      if (pageNumber > Math.floor(totalRecords / 50) || pageNumber < 0) {
        return;
      }
      if (
        pageNumber > currentPage &&
        pageNumber * 50 >= meetingData?.length &&
        meetingData?.length < totalRecords
      ) {
        elasticSearchMeeting(
          activeFilters,
          activeSort[0]?.field,
          activeSort[0]?.sort,
          pageNumber,
          elasticToken
        )
          .then((data) => {
            if (data?.results?.length > -1) {
              setMeetingData([...meetingData, ...data.results]);
              setTotalRecords(data.total);
            }
          })
          .catch((err) => {
            console.log(err, "new elastic search response");
          });
      }
    }
    setCurrentPage(pageNumber);
  };

  const SeeAllFooter = () => (
    <div className="footer-see-all">
      <a
        onClick={(e) => {
          e.preventDefault();
          history.push(`/meetings/${type}`);
        }}
        style={{ cursor: "pointer" }}
      >
        See all
      </a>
    </div>
  );

  return (
    <>
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
          minHeight={500}
          sx={{ background: "#fff", borderRadius: ".5rem" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          <DataGrid
            rows={tableRows}
            columns={tableColumns}
            filterMode={"server"}
            sortingMode={"server"}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={onFilterChange}
            disableColumnMenu
            pageSize={
              isFull ? pageSize : height > 0 ? Math.round(height / 68) : 5
            }
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={currentPage}
            onPageChange={(pageNumber) => handlePageChange(pageNumber + 1)}
            rowsPerPageOptions={isFull && type === "past" ? [50] : [10, 15, 20]}
            rowCount={totalRecords}
            checkboxSelection={false}
            disableSelectionOnClick
            enableCellSelect={false}
            getRowClassName={() => `data-table-row`}
            onRowClick={(meeting) => handleRowClick(meeting.row)}
            className={`data-table ${isFull ? "" : "meetings-table"}`}
            components={
              !isFull
                ? {
                    Pagination: SeeAllFooter,
                  }
                : isFull &&
                  type === "past" && {
                    Toolbar: CustomToolbar,
                    Pagination: null,
                  }
            }
            componentsProps={
              isFull &&
              type === "past" && {
                toolbar: {
                  setActiveFilters,
                  currentPage,
                  handlePageChange,
                  totalRecords,
                  columns: totalColumns,
                  activeColumns,
                  setActiveColumns,
                },
              }
            }
          />
        </>
      )}
    </>
  );
}
