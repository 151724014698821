import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import firebase from "./../../firebase/firebase.js";
import { postData } from "./../../utils";
import { modalButton, inviteButton, InviteInput, InviteSelect } from "./styles";
import useAuth from "../../hooks/useAuth";
import { validateEmail } from "./validations";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    paddingTop: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    paddingBottom: theme.spacing(10),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, pb: 0, fontSize: "24px !important" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function InviteDialog(props) {
  const { fireStoreuserObj } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("MEMBER");
  const [seniority, setSeniority] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const handleInvite = async () => {
    setErrorMsg("");
    if (!email || !role) {
      setErrorMsg("Email address & Role field can not be empty!");
      return false;
    }
    if (!validateEmail(email)) {
      setErrorMsg("Please enter a valid email address.");
      return false;
    }
    setLoading(true);
    let data = {
      orgid: fireStoreuserObj.organizationId,
      email: email,
      role: role,
    };

    if (seniority) {
      data.seniority = seniority;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/invite/member`,
          jwtToken,
          data
        );
        setLoading(false);
        if (result.status === "success") {
          props.onFetchMembers();
          setOpen(false);
        } else {
          setErrorMsg(result.message);
        }
        setEmail("");
        setRole("MEMBER");
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        style={modalButton}
        onClick={() => setOpen(true)}
      >
        Invite Members
      </Button>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle onClose={() => setOpen(false)} align="center">
          Invite Members
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography align="center">
            Invite the people you have meetings with to your shared workspace
          </Typography>
          {errorMsg && (
            <Stack sx={{ width: "100%" }} mt={2}>
              <Alert severity="error" sx={{ pt: "8px !important" }}>
                {errorMsg}
              </Alert>
            </Stack>
          )}

          <Box display="flex" justifyContent="center" mt={5} gap={0.75}>
            <FormControl>
              <TextField
                label="Email Address"
                variant="outlined"
                sx={InviteInput}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
              />
            </FormControl>

            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                label="Role"
                onChange={(e) => setRole(e.target.value)}
                sx={InviteSelect}
              >
                {fireStoreuserObj.role === "ADMIN" && (
                  <MenuItem value="ADMIN">Admin</MenuItem>
                )}
                <MenuItem value="TEAM_MANAGER">Team Manager</MenuItem>
                <MenuItem value="MEMBER">Member</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Seniority level</InputLabel>
              <Select
                value={seniority}
                label="Seniority level"
                onChange={(e) => setSeniority(e.target.value)}
                sx={InviteSelect}
              >
                <MenuItem value="Volunteer">Volunteer</MenuItem>
                <MenuItem value="Internship">Internship</MenuItem>
                <MenuItem value="Training">Training</MenuItem>
                <MenuItem value="Entry Level">Entry Level</MenuItem>
                <MenuItem value="Associate">Associate</MenuItem>
                <MenuItem value="Mid-Senior Level">Mid-Senior Level</MenuItem>
                <MenuItem value="Director Executive (CX)">
                  Director Executive (CX)
                </MenuItem>
              </Select>
            </FormControl>

            {!loading ? (
              <Button style={inviteButton} onClick={handleInvite}>
                Send Invite
              </Button>
            ) : (
              <Box display="flex" ml={2} alignItems="center">
                <CircularProgress />
              </Box>
            )}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
