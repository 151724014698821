import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@material-ui/core";
import { getContact, getLead } from "../../firebase/firestore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@mui/material/Radio";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import { Post } from "../../utils/axiosUtils";
import useAuth from "../../hooks/useAuth";

const labels = [
  {
    key: "firstName",
    label: "First name",
    isLead: true,
  },
  {
    key: "lastName",
    label: "Last name",
    isLead: true,
  },
  {
    key: "role",
    label: "Role",
    isLead: true,
  },
  {
    key: "accountName",
    label: "Account name",
    isLead: true,
  },
  {
    key: "website",
    label: "Website",
    isLead: true,
  },
  {
    key: "industry",
    label: "Industry",
    isLead: true,
  },
  {
    key: "noOfEmployee",
    label: "No. of employee",
    isLead: true,
  },
  {
    key: "telephoneNumber",
    label: "Phone number",
    isLead: true,
  },
  {
    key: "assigneeNames",
    label: "Assigned to",
    isLead: true,
  },
  {
    key: "gender",
    label: "Gender",
    isLead: true,
  },
  {
    key: "description",
    label: "Description",
    isLead: true,
  },
];

function MergeLeadContactDialog({ onClose, onSuccessClose, selecteds, type }) {
  const { fireStoreuserObj } = useAuth();
  const [selectedsData, setSelectedsData] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [editedMasterData, setEditedMasterData] = useState({});
  const [checkedDatas, setCheckedDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mergeLoading, setMergeLoading] = useState(false);

  const fetchCRMData = async () => {
    if (!selecteds?.length) {
      return;
    }
    let tempSelectedsData = [];
    let tempMasterData = {};
    for await (const id of selecteds) {
      let data;
      if (type === "leads") {
        data = await getLead(id);
        if (data?.lead) {
          tempSelectedsData.push(data.lead);
        }
      } else if (type === "contacts") {
        data = await getContact(id);
        if (data?.contact) {
          tempSelectedsData.push(data.contact);
        }
      }
    }
    if (tempSelectedsData?.length < 2) {
      return;
    }

    /**
     * IF ASSIGNEE EXIST THEN FETCH USER NAME
     */

    if (tempSelectedsData?.map((item) => item?.assignee)?.length) {
      let jwtToken = await firebase.auth().currentUser.getIdToken();

      let result = await getData(
        `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
        jwtToken
      );
      if (result.status === "success") {
        let teamData = result.data.members?.filter(
          (member) => member.id !== fireStoreuserObj.uid
        );
        let teamMembers = [];
        teamData?.map((member) => {
          teamMembers.push({
            name: member?.name,
            id: member?.id,
          });
        });
        tempSelectedsData.map((item) => {
          if (item?.assignee?.length) {
            let assigneeNames = [];
            item.assignee.map((assignee) => {
              let findMemberName = teamMembers.find(
                (item) => item.id === assignee
              );
              if (findMemberName) {
                assigneeNames.push(findMemberName.name);
              }
            });
            item.assigneeNames = assigneeNames.join(", ");
          }
        });
      }
    }

    let leadKeys = labels.filter((item) => item.isLead).map((item) => item.key);
    leadKeys.map((key) => {
      let values = [];
      tempSelectedsData.map((lead) => lead[key] && values.push(lead[key]));
      values.every((value) => {
        if (value === values[values.length - 1]) {
          tempMasterData[key] = values[values.length - 1];
        }
      });
    });
    setEditedMasterData(tempMasterData);
    setMasterData(tempMasterData);
    setSelectedsData(tempSelectedsData);
    setLoading(false);
  };

  useEffect(() => {
    fetchCRMData();
  }, []);

  const onRadioButtonChecked = (e, selected, key) => {
    let newCheckedDatas = [...checkedDatas];
    let newMasterData = Object.assign({}, editedMasterData);
    let controlChecks = newCheckedDatas.find((item) => item.key === key);
    if (controlChecks) {
      controlChecks.value = selected[key];
    } else {
      newCheckedDatas.push({
        key,
        value: selected[key],
      });
    }

    if (key === "accountName") {
      // Changing account id
      newMasterData.account = selected.account;
    }
    if (key === "assigneeNames") {
      // Changing assignee ids
      newMasterData.assignee = selected.assignee;
    }

    newMasterData[key] = selected[key];
    setEditedMasterData(newMasterData);
    setCheckedDatas(newCheckedDatas);
  };

  const radioButtonValue = (key, value) => {
    let controlCheckedValue = checkedDatas.find(
      (item) => item.key === key
    )?.value;
    if (controlCheckedValue === value) {
      return true;
    } else {
      return false;
    }
  };

  const onMerge = () => {
    setMergeLoading(true);
    if (!editedMasterData?.role?.length) {
      delete editedMasterData.role;
    }
    if (editedMasterData?.assigneeNames) {
      delete editedMasterData.assigneeNames;
    }
    let postData = {
      ids: selecteds,
      values: editedMasterData,
    };
    Post(`${type}/merge/${selecteds[0]}`, postData)
      .then(() => {
        setMergeLoading(false);
        onSuccessClose();
      })
      .catch((err) => {
        setMergeLoading(false);
        console.log(err, "error happen when merging");
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={true}
      PaperProps={{ style: { overflow: "hidden" } }}
    >
      <Box p={5} minHeight={"60vh"} width={"80vw"} maxWidth={1200}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={"calc(60vh - 80px)"}
            width={"100%"}
          >
            <CircularProgress size={20} sx={{ color: "#2c73ff" }} />
          </Box>
        ) : (
          <Box className={"merge-swiper"}>
            <div className="merge-lead-container">
              <h6 className="agenda">Master Record</h6>
              {labels.map((label, index) => {
                let findValue =
                  label.key === "assignee"
                    ? editedMasterData["assigneeNames"]
                    : editedMasterData[label.key];
                return (
                  <div className="merge-row" key={`master-${index}`}>
                    <div>{label.label}</div>
                    <span>{findValue}</span>
                  </div>
                );
              })}
            </div>
            {selectedsData.map((selected, index) => (
              <div className="merge-lead-container" key={index}>
                <h6 className="agenda">{`Record ${index + 1}`}</h6>
                {labels.map((label) => {
                  let findValue =
                    label.key === "assignee"
                      ? selected["assigneeNames"]
                      : selected[label.key];
                  let findMasterValue = masterData[label.key];
                  let isChecked = radioButtonValue(label.key, findValue);
                  return (
                    <div
                      className="merge-row"
                      key={`record-${index + 1}-${label.key}`}
                    >
                      <div>
                        {!findMasterValue &&
                          findValue &&
                          (findValue !== "" || !findValue?.length) && (
                            <Radio
                              checked={isChecked}
                              onChange={(e) =>
                                onRadioButtonChecked(e, selected, label.key)
                              }
                              value={`record-${index + 1}-${label.key}`}
                              name="radio-buttons"
                            />
                          )}
                        {label.label}
                      </div>
                      <span>{findValue}</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </Box>
        )}
        {!loading && (
          <Box my={2}>
            <button onClick={onClose} className="modal-button white">
              Cancel
            </button>
            <button onClick={onMerge} className="modal-button blue ml">
              {mergeLoading ? (
                <CircularProgress
                  size="13px"
                  style={{ color: "#fff", marginRight: ".5em" }}
                />
              ) : (
                "Merge"
              )}
            </button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default MergeLeadContactDialog;
