import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

import { Box, Collapse, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";

import { getOrgainzation } from "../../../firebase/firestore";

import googleMeetAvatar from "../../../assets/images/meet.png";
import recordingMeeting from "../../../assets/images/recording_meeting.png";
import zoomAvatar from "../../../assets/images/zoom-icon.svg";
import msTeamAvatar from "../../../assets/images/ms-teams-icon.svg";

import moment from "moment";
import { PastMeetingsList } from "../../../utils/endpoints";
import { Get } from "../../../utils/axiosUtils";

export default function Contact({
  contact,
  query,
  setShowSuggestions,
  searchInput,
}) {
  const [open, setOpenFn] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [lastMeeting, setLastMeeting] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let org = await getOrgainzation(contact.organizationId);
      setOrganization(org);
    }
    fetchData();
  }, [contact.organizationId]);

  useEffect(() => {
    (async () => {
      let payload = { perPage: 10, page: 1 };
      let apiRes = await Get(PastMeetingsList, payload);
      if (apiRes.status == 200 && apiRes.data.status == "success") {
        const { data } = apiRes.data;
        let arr = [];
        data.forEach((el) => {
          if (el.participants) {
            const filteredData = el.participants.filter(
              (p) =>
                p.name === contact.name ||
                contact.email === p.email ||
                (contact.firstName &&
                  contact.lastName &&
                  p.name === contact.firstName + " " + contact.lastName)
            );
            if (filteredData.length) {
              filteredData[0].title = el.title;
              filteredData[0].channelType = el.channelType;
              filteredData[0].lastMeetingDate = moment(
                filteredData[0].joinedAt._seconds * 1000
              ).format("lll");
              arr.push(filteredData[0]);
              setPastMeetings(arr);
            }
          }
        });
      }
    })();
  }, []);

  useEffect(() => {
    let result = Math.max.apply(
      null,
      pastMeetings.map((y) => y.joinedAt._seconds)
    );
    let maxValue = pastMeetings.filter((i) => i.joinedAt._seconds === result);
    setLastMeeting(maxValue[0]);
  }, [pastMeetings]);

  const handleClick = (event) => {
    if (event.target.classList.contains("contact-link")) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    searchInput.current.querySelector("input").onfocus = () => {
      setShowSuggestions(true);
    };

    setOpenFn(!open);
  };

  return (
    <Grid
      display="flex"
      flexDirection="column"
      gap="10px"
      p={1}
      style={{ backgroundColor: "#FAFAFA" }}
      onClick={handleClick}
    >
      <Grid display="flex" gap="10px">
        <Icon
          icon="fluent:book-contacts-20-regular"
          style={{ color: "#2C73FF", width: "25px", height: "25px" }}
        />
        <Box display="flex" alignItems="center" gap="10px">
          {(contact.avatarURL || contact.avatar) && (
            <img
              alt="cantact"
              src={contact.avatarURL ? contact.avatarURL : contact.avatar}
              style={{
                color: "#2C73FF",
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                objectFit: "cover",
                border: "1px solid #7DA8FB",
              }}
            />
          )}
          <a
            style={{ textDecoration: "none" }}
            className="contact-link"
            href={`/crm/contact/${contact.objectID}`}
          >
            {/* {contact.name || contact.firstName + " " + contact.lastName || ""} */}
            {contact.name
              ? contact.name
              : contact.firstName && contact.firstName
              ? contact.firstName + " " + contact.lastName
              : contact.firstName
              ? contact.firstName
              : contact.lastName
              ? contact.lastName
              : ""}
          </a>
        </Box>
        {contact.role ? (
          <Box mb="8px" mt="8px">
            {contact.role}
          </Box>
        ) : (
          <></>
        )}
        <Box mb="8px" mt="8px">
          {contact.email || ""}
        </Box>
        <Box margin={1} color="#82ABFB">
          {organization.name}
        </Box>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {lastMeeting && (
          <Grid margin={1} display="flex" alignItems="center" gap="10px">
            Last Meeting:{" "}
            <Box
              item
              xs={6}
              sm={6}
              display="flex"
              fontSize="14px"
              alignItems="center"
              gap="5px"
            >
              {lastMeeting.channelType === "ZOOM" ? (
                <Avatar
                  src={zoomAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : lastMeeting.channelType === "GOOGLE_MEET" ? (
                <Avatar
                  src={googleMeetAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : lastMeeting.channelType === "RECORDING" ? (
                <Avatar
                  src={recordingMeeting}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <Avatar
                  src={msTeamAvatar}
                  alt="meet"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
              <p style={{ textDecoration: "underline" }}>
                {lastMeeting?.title}
              </p>
              <Box item xs={2} sm={2} fontSize="12px" color="#707070">
                {lastMeeting.joinedAt._seconds
                  ? moment(lastMeeting.joinedAt._seconds * 1000).format("lll")
                  : "--"}
              </Box>
            </Box>
          </Grid>
        )}
      </Collapse>
    </Grid>
  );
}
