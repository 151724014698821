import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ContactsList from "./ContactsList";
export default function Leads() {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column">
      {/* <Box display="flex" justifyContent="flex-end" flexDirection="row">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push("/crm/contact/create")}
        >
          Create Contact
        </Button>
      </Box> */}
      <Box marginTop={1}>
        <ContactsList />
      </Box>
    </Box>
  );
}
