import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Grid } from "@material-ui/core";
import Analytics from "../charts.js/Analytics";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SentimentChart from "../charts.js/SentimentChart";
import SentimentLineChart from "../charts.js/SentimentLineChart";
import ContactsProductsTable from "../charts.js/ContactsProductsTable";
import Heatmap from "../charts.js/Heatmap";

function ReportsDashboard({
  chartData,
  dateRangeHandler,
  meetingsLoading,
  isSentimentAvailable,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const doc = document.getElementById("analyticChartsContainer");
  const handleScroll = (rotate) => {
    doc?.scrollTo({
      top: 0,
      left: rotate === "right" ? 1020 : 0,
      behavior: "smooth",
    });
    setScrollPosition(rotate === "right" ? 1020 : 0);
  };
  return (
    <>
      <Box className="arrow-scroll-container">
        {scrollPosition > 0 && (
          <div className="arrow left" onClick={() => handleScroll("left")}>
            <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
          </div>
        )}
        <Box
          sx={{ display: "flex" }}
          id={"analyticChartsContainer"}
          onScroll={(e) => setScrollPosition(e?.target?.scrollLeft)}
        >
          <Analytics
            type="meetingAsAll"
            data={chartData?.meetingAsAll}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
          <Analytics
            type="meetingAsHost"
            data={chartData?.meetingAsHost}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
          <Analytics
            type="meetingAsParticipant"
            data={chartData?.meetingAsParticipant}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
          <Analytics
            type="absentMeetings"
            data={chartData?.absentMeetings}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
          <Analytics
            type="meetingAttendedLate"
            data={chartData?.meetingAttendedLate}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
        </Box>
        {scrollPosition === 0 && (
          <div className="arrow right" onClick={() => handleScroll("right")}>
            <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 50 }} />
          </div>
        )}
      </Box>
      <Grid container spacing={3} sx={{ height: "100%", marginBottom: "2em" }}>
        {isSentimentAvailable && (
          <Grid item xs={12} lg={4}>
            <SentimentChart />
          </Grid>
        )}
        <Grid item xs={12} lg={isSentimentAvailable ? 8 : 12}>
          <SentimentLineChart
            data={chartData?.meetingAsAll}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <ContactsProductsTable
            title={"Products"}
            subtitle={"Total Mentions"}
            meetingsData={chartData?.meetingAsAll}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ContactsProductsTable
            title={"Competitors"}
            subtitle={"Total Mentions"}
            meetingsData={chartData?.meetingAsAll}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Heatmap
            meetingsData={chartData?.meetingAsAll}
            fetchLoading={meetingsLoading}
            dateRangeController={dateRangeHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ReportsDashboard;
