export const ContactFieldsArray = [
  {
    title: "Contact Owner",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [],
  },
  {
    title: "Account Name",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [],
  },
  {
    title: "Salutation",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [
      {
        title: "Mr.",
      },
      {
        title: "Mrs.",
      },
      {
        title: "Ms.",
      },
      {
        title: "Dr.",
      },
      {
        title: "Prof.",
      },
    ],
  },
  {
    title: "First name",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: true,
    validation: [
      {
        maxLength: 40,
      },
    ],
  },
  {
    title: "Title",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: [
      {
        maxLength: 100,
      },
    ],
  },
  {
    title: "Last name",
    type: "text",
    isRequired: true,
    isDefault: true,
    showColumn: true,
    validation: [
      {
        maxLength: 80,
      },
    ],
  },
  {
    title: "Assistant",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: [
      {
        maxLength: 80,
      },
    ],
  },

  {
    title: "Asst. Phone",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: [
      {
        maxLength: 80,
      },
    ],
  },

  {
    title: "Role",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: true,
    options: [
      {
        title: "Decision Maker",
      },
      {
        title: "Influencer",
      },
      {
        title: "Evaluator",
      },
      {
        title: "Other",
      },
    ],
  },
  // {
  //   title: "Company",
  //   type: "text",
  //   isRequired: true,
  //   isDefault: true,
  //   showColumn: true,
  //   validation: [
  //     {
  //       maxLength: 100,
  //     },
  //   ],
  // },
  {
    title: "Email",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: true,
    validation: {
      maxLength: "100",
      email: true,
    },
  },
  {
    title: "Secondary Email",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: {
      maxLength: "100",
      email: true,
    },
  },
  {
    title: "Skype ID",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: {
      maxLength: "50",
      email: true,
    },
  },
  {
    title: "Mobile number",
    type: "text",
    isRequired: false,
    isDefault: true,
  },
  {
    title: "Telephone number",
    type: "text",
    isRequired: false,
    isDefault: true,
  },
  {
    title: "Fax",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "30",
    },
  },
  {
    title: "Street",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "250",
    },
  },
  {
    title: "City",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "50",
    },
  },
  {
    title: "State",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "50",
    },
  },
  {
    title: "Zip Code",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "30",
    },
  },
  {
    title: "Country",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "50",
    },
  },
  {
    title: "Website",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "150",
    },
  },
  {
    title: "No. Of employee",
    type: "select",
    isRequired: false,
    isDefault: true,
    options: [
      {
        title: "1-10 People",
      },
      {
        title: "11-50 People",
      },
      {
        title: "51-100 People",
      },
      {
        title: "100+ People",
      }
    ]
  },
  {
    title: "Gender",
    type: "select",
    isRequired: false,
    isDefault: true,
    options: [
      {
        title: "Male",
      },
      {
        title: "Female",
      }
    ]
  },
  {
    title: "Email Opt-out",
    type: "checkbox",
    isRequired: false,
    isDefault: true,
    showColumn: false,
  },
  {
    title: "Annual Revenue",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: {
      maxLength: "20",
    },
  },
  {
    title: "Industry",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [
      {
        title: "Accounting",
      },
      {
        title: "Agribusiness",
      },
      {
        title: "Cleantech",
      },
      {
        title: "Construction",
      },
      {
        title: "Entertainment & Media",
      },
      {
        title: "Hospitality",
      },
      {
        title: "Insurance",
      },
      {
        title: "Life Sciences",
      },
      {
        title: "Manufacturing",
      },
      {
        title: "Natural Resources",
      },
      {
        title: "Professional Service",
      },
      {
        title: "Retail",
      },
      {
        title: "Technology",
      },
      {
        title: "Travel",
      },
    ],
  },
  {
    title: "Description",
    type: "multi-line-text",
    isRequired: false,
    isDefault: true,
  },
];
