const constants = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  FETCH_NOTIFICATION_CRITICALS: "FETCH_NOTIFICATION_CRITICALS",
  SHOW_NOTIFICATION_CRITICALS: "SHOW_NOTIFICATION_CRITICALS",
  CLOSE_NOTIFICATION_CRITICALS: "CLOSE_NOTIFICATION_CRITICALS",

  FETCH_TOPICS_REQUEST: "FETCH_TOPICS_REQUEST",
  FETCH_TOPICS_SUCCESS: "FETCH_TOPICS_SUCCESS",
  FETCH_TOPICS_ERROR: "FETCH_TOPICS_ERROR",

  ADD_NEW_TOPIC_REQUEST: "ADD_NEW_TOPIC_REQUEST",
  ADD_NEW_TOPIC_SUCCESS: "ADD_NEW_TOPIC_SUCCESS",
  ADD_NEW_TOPIC_ERROR: "ADD_NEW_TOPIC_ERROR",

  DELETE_TOPIC_REQUEST: "DELETE_TOPIC_REQUEST",
  DELETE_TOPIC_SUCCESS: "DELETE_TOPIC_SUCCESS",
  DELETE_TOPIC_ERROR: "DELETE_TOPIC_ERROR",

  ADD_MEETING_TOPIC_REQUEST: "ADD_MEETING_TOPIC_REQUEST",
  ADD_MEETING_TOPIC_SUCCESS: "ADD_MEETING_TOPIC_SUCCESS",
  ADD_MEETING_TOPIC_ERROR: "ADD_MEETING_TOPIC_ERROR",

  DELETE_MEETING_TOPIC_REQUEST: "DELETE_MEETING_TOPIC_REQUEST",
  DELETE_MEETING_TOPIC_SUCCESS: "DELETE_MEETING_TOPIC_SUCCESS",
  DELETE_MEETING_TOPIC_ERROR: "DELETE_MEETING_TOPIC_ERROR",

  FETCH_MEETING_REQUEST: "FETCH_MEETING_REQUEST",
  FETCH_MEETING_SUCCESS: "FETCH_MEETING_SUCCESS",
  FETCH_MEETING_ERROR: "FETCH_MEETING_ERROR",

  UPDATE_MEETING_OUTCOME_REQUEST: "UPDATE_MEETING_OUTCOME_REQUEST",
  UPDATE_MEETING_OUTCOME_SUCCESS: "UPDATE_MEETING_OUTCOME_SUCCESS",
  UPDATE_MEETING_OUTCOME_ERROR: "UPDATE_MEETING_OUTCOME_ERROR",

  CRM_LEAD_FETCH_LEAD: "CRM_LEAD_FETCH_LEAD",
  CRM_LEAD_START_FETCHING_LEAD: "CRM_LEAD_START_FETCHING_LEAD",
  CRM_LEAD_STOP_FETCHING_LEAD: "CRM_LEAD_STOP_FETCHING_LEAD",
  CRM_LEAD_SET_LEADS: "CRM_LEAD_SET_LEADS",
  CRM_LEAD_REMOVE_LEADS: "CRM_LEAD_REMOVE_LEADS",
  CRM_LEAD_UPDATE_LEAD: "CRM_LEAD_UPDATE_LEAD",

  CRM_CONTACT_FETCH_CONTACT: "CRM_CONTACT_FETCH_CONTACT",
  CRM_CONTACT_START_FETCHING_CONTACT: "CRM_CONTACT_START_FETCHING_CONTACT",
  CRM_CONTACT_STOP_FETCHING_CONTACT: "CRM_CONTACT_STOP_FETCHING_CONTACT",
  CRM_CONTACT_SET_CONTACTS: "CRM_CONTACT_SET_CONTACTS",
  CRM_CONTACT_REMOVE_CONTACTS: "CRM_CONTACT_REMOVE_CONTACTS",
  CRM_CONTACT_UPDATE_CONTACT: "CRM_CONTACT_UPDATE_CONTACT",

  CRM_ACCOUNT_FETCH_ACCOUNT: "CRM_ACCOUNT_FETCH_ACCOUNT",
  CRM_ACCOUNT_START_FETCHING_ACCOUNT: "CRM_ACCOUNT_START_FETCHING_ACCOUNT",
  CRM_ACCOUNT_STOP_FETCHING_ACCOUNT: "CRM_ACCOUNT_STOP_FETCHING_ACCOUNT",
  CRM_ACCOUNT_SET_ACCOUNTS: "CRM_ACCOUNT_SET_ACCOUNTS",
  CRM_ACCOUNT_REMOVE_ACCOUNTS: "CRM_ACCOUNT_REMOVE_ACCOUNTS",
  CRM_ACCOUNT_UPDATE_ACCOUNT: "CRM_ACCOUNT_UPDATE_ACCOUNT",
};

export default constants;
