import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
//import Home from '../components/home/home';
import Welcome from "../components/landing/welcome";
import Login from "../components/login/login";
// import Signin from '../components/Signin';
import DashboardLayout from "../components/dashboard";
import PastMeetingDetails from "../components/past-meetings/pastMeetingDetails";
import UpcomingMeetingDetails from "../components/upcoming-meetings/upcomingMeetingDetails";

import Dashboard from "../components/_dashboard/Dashboard";
import Main from "../components/Main";
import Meetings from "../components/meetings";
import Settings from "../components/settings/settings";
import Help from "../components/help/help";
import PendingApproval from "../components/PendingApproval";
import BillingRequired from "../components/BillingRequired";
import firebaseAnalytics from "./../firebase/firebaseAnalytics";
import Plans from "../components/plans/plans";
import Payment from "../components/payment/payment";

//Routes for CRM-Leads module
import Leads from "../components/CRM/Leads";
import CreateLead from "../components/CRM/Leads/CreateLead";
import EditLead from "../components/CRM/Leads/EditLead";
import LeadDetails from "../components/CRM/Leads/LeadDetails";

import Contacts from "../components/CRM/Contacts";
import EditContact from "../components/CRM/Contacts/EditContact";
import CreateContact from "../components/CRM/Contacts/CreateContact";
import ContactDetails from "../components/CRM/Contacts/ContactDetails";
import Teams from "../components/teams";

import ConvertLead from "../components/CRM/Leads/ConvertLead";
import TeamAnalytics from "../components/teams/TeamAnalytics";
import MemberAnalytics from "../components/teams/MemberAnalytics";
//Routes end for CRM-Leads module

//Tasks module routes
import Tasks from "../components/Tasks";

import Deals from "../components/Deals";

import Accounts from "../components/CRM/Accounts";
import CreateAccount from "../components/CRM/Accounts/CreateAccount";
import EditAccount from "../components/CRM/Accounts/EditAccount";
import AccountDetails from "../components/CRM/Accounts/AccountDetails";

function withLayout(WrappedComponent, isMeetingDetail) {
  return class extends React.Component {
    render() {
      return (
        <Main isMeetingDetail={isMeetingDetail}>
          <WrappedComponent></WrappedComponent>
        </Main>
      );
    }
  };
}

const Routes = () => {
  let location = useLocation();
  React.useEffect(() => {
    firebaseAnalytics.logEvent("spa_page_view", {
      url: location.pathname,
      title: document.title,
    });
  }, [location]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute
        exact
        path="/pending-approval"
        component={withLayout(PendingApproval)}
      />
      <PrivateRoute
        exact
        path="/billing-required"
        component={withLayout(BillingRequired)}
      />
      <PrivateRoute exact path={"/"} component={Welcome} />
      <PrivateRoute exact path={"/welcome"} component={Welcome} />
      <PrivateRoute
        exact
        path="/meeting/:id"
        component={withLayout(PastMeetingDetails, true)}
      />
      {/* <PrivateRoute exact path='/past-meeting-details' component={withLayout(PastMeetingDetails)} /> */}

      <PrivateRoute exact path="/dashboard" component={withLayout(Dashboard)} />
      <PrivateRoute
        exact
        path="/meetings/:type"
        component={withLayout(Meetings)}
      />
      <PrivateRoute exact path="/teams" component={withLayout(TeamAnalytics)} />
      <PrivateRoute
        exact
        path="/teams/:id"
        component={withLayout(MemberAnalytics)}
      />
      <PrivateRoute path="/team-management" component={withLayout(Teams)} />
      <PrivateRoute
        exact
        path="/crm/contacts"
        component={withLayout(Contacts)}
      />

      <PrivateRoute
        exact
        path="/crm/contact/create"
        component={withLayout(CreateContact)}
      />

      <PrivateRoute
        exact
        path="/crm/contact/edit/:contactId"
        component={withLayout(EditContact)}
      />

      <PrivateRoute
        exact
        path="/crm/contact/:contactId"
        component={withLayout(ContactDetails)}
      />

      <PrivateRoute exact path="/crm/leads" component={withLayout(Leads)} />
      <PrivateRoute
        exact
        path="/crm/leads/create"
        component={withLayout(CreateLead)}
      />

      <PrivateRoute
        exact
        path="/crm/lead/edit/:leadId"
        component={withLayout(EditLead)}
      />

      <PrivateRoute
        exact
        path="/crm/lead/:leadId"
        component={withLayout(LeadDetails)}
      />

      <PrivateRoute
        exact
        path="/crm/lead/:leadId/convert"
        component={withLayout(ConvertLead)}
      />

      <PrivateRoute exact path="/tasks" component={withLayout(Tasks)} />

      <PrivateRoute exact path="/deals" component={withLayout(Deals)} />

      <PrivateRoute
        exact
        path="/crm/accounts"
        component={withLayout(Accounts)}
      />

      <PrivateRoute
        exact
        path="/crm/accounts/create"
        component={withLayout(CreateAccount)}
      />

      <PrivateRoute
        exact
        path="/crm/account/edit/:accountId"
        component={withLayout(EditAccount)}
      />

      <PrivateRoute
        exact
        path="/crm/account/:accountId"
        component={withLayout(AccountDetails)}
      />

      <PrivateRoute exact path="/settings" component={withLayout(Settings)} />
      <PrivateRoute
        exact
        path="/settings/:tabIndex"
        component={withLayout(Settings)}
      />
      <PrivateRoute exact path="/help" component={withLayout(Help)} />
      {/* <PrivateRoute exact path='/dashboard' component={DashboardLayout} /> */}
      {/* <PrivateRoute exact path='/past-meeting-details' component={PastMeetingDetails} /> */}

      <PrivateRoute
        exact
        path="/upcoming-meeting-details"
        component={UpcomingMeetingDetails}
      />
      <PrivateRoute exact path="/plans" component={Plans} />
      <PrivateRoute exact path="/payment" component={Payment} />
    </Switch>
  );
};
export default Routes;
