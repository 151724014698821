import React, { useState, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
import "./ElasticAutocomplete.css";

const Contact = ({
  contact,
  setContactSelected,
  setQuery,
  setRelatedTo,
  clearInputAfterSet,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={"30px"}
      width={1}
      onClick={(event) => {
        let fullName = contact.firstName
          ? contact.firstName + " " + contact.lastName
          : contact.lastName;
        event.stopPropagation();
        if (clearInputAfterSet) {
          setQuery("");
        } else {
          setQuery(fullName);
        }
        // setIsOpen(false);
        setContactSelected(true);
        setRelatedTo({
          lastName: fullName,
          contactId: contact.objectID,
          type: "contact",
        });
      }}
    >
      <Avatar
        src={contact?.avatar}
        style={{
          height: 20,
          width: 20,
          border: "1px solid #2c73ff",
          marginRight: "5px",
        }}
      />
      <p>
        {contact.firstName
          ? contact.firstName + " " + contact.lastName
          : contact.lastName}
      </p>
    </Box>
  );
};

export default function ElasticContactAutocomplete(props) {
  const {
    apiKey,
    setRelatedTo,
    placeholder,
    defaultValue,
    clearInputAfterSet,
  } = props;
  const elasticHost = process.env.REACT_APP_ELASTIC_HOST
    ? process.env.REACT_APP_ELASTIC_HOST
    : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

  // let [value, setValue] = useState("");

  let [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  let [suggestions, setSuggestions] = useState([]);
  let [contactSelected, setContactSelected] = useState(false);

  //   useEffect(() => {

  //   }, []);

  const onChange = (event, { newValue }) => {
    setValue(newValue);

    if (contactSelected) setContactSelected(false);
  };

  const renderSuggestion = (suggestion) => {
    return (
      <Contact
        contact={suggestion}
        setQuery={setValue}
        setContactSelected={setContactSelected}
        setRelatedTo={setRelatedTo}
        clearInputAfterSet={clearInputAfterSet}
      />
    );
  };

  const shouldRenderSuggestions = (value, reason) => {
    return !contactSelected && value.length > 2;
  };

  let onSuggestionsFetchRequested = ({ value }) => {
    axios
      .post(
        `${elasticHost}/contacts/_search`,
        {
          query: {
            multi_match: {
              query: value,
              type: "bool_prefix",
              fields: ["fullName", "fullName._2gram", "fullName._3gram"],
            },
          },
          // sort: ["_score", { createdDate: "desc" }]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${apiKey}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.hits.hits.map((h) => h._source);
        setSuggestions(results);
      });
  };
  let onSuggestionsFetchRequestedDebounce = useRef(
    debounce(500, onSuggestionsFetchRequested)
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: placeholder ? placeholder : "Select contacts",
    value,
    onChange,
    className: `react-autosuggest__input ${
      props?.className ? props?.className : ""
    }`,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounce.current}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.fullName}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
    />
  );
}
