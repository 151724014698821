import React, { useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import Avatar from "@material-ui/core/Avatar";
import Drawer from "@material-ui/core/Drawer";
import { getChangeLog } from "../../firebase/firestore";
import moment from "moment";

function PastMeetingsChangeLog({
  drawerStatus,
  handleDrawerClose,
  meetingId,
  setHistoryNumber,
  setShowHistoryPopup,
  setActionHistoryNumber,
  setShowActionHistoryPopup,
}) {
  const [changeLogs, setChangeLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleActionChangeClick = (actionUpdateNo) => {
    handleDrawerClose();
    setShowActionHistoryPopup(true);
    setActionHistoryNumber(actionUpdateNo);
  };

  const handleSummaryChangeClick = (summaryUpdateNo) => {
    handleDrawerClose();
    setShowHistoryPopup(true);
    setHistoryNumber(summaryUpdateNo);
  };

  const getChangeLogs = async () => {
    setLoading(true);
    let changeLog = await getChangeLog(meetingId);
    if (!changeLog) {
      setLoading(false);
      return;
    }
    const formatedChangeLogs = [];
    let totalSummaryUpdateNo = 0;
    let totalActionUpdateNo = 0;
    changeLog.data.forEach((changeLogDoc) => {
      let changeDate = moment(new Date(changeLogDoc.timestamp)).format(
        "DD MMM YYYY"
      );
      let dataObj = {
        description: changeLogDoc.description,
        photoURL: changeLogDoc.photoURL,
        displayName: changeLogDoc.displayName,
        id: changeLogDoc.id,
        time: moment(new Date(changeLogDoc.timestamp)).format("hh:mm A"),
      };
      if (changeLogDoc.description === "Updated the Summary") {
        totalSummaryUpdateNo++;
        dataObj.summaryUpdateNo = totalSummaryUpdateNo;
      }

      if (changeLogDoc.description === "Updated the Actions") {
        totalActionUpdateNo++;
        dataObj.actionUpdateNo = totalActionUpdateNo;
      }

      let searchDate = formatedChangeLogs.find(
        (item) => item.day === changeDate
      );
      if (searchDate) {
        searchDate.values.push(dataObj);
      } else {
        formatedChangeLogs.push({
          day: changeDate,
          values: [dataObj],
        });
      }
    });
    setChangeLogs(formatedChangeLogs);
    setLoading(false);
  };
  useMemo(() => {
    if (drawerStatus) {
      getChangeLogs();
    }
  }, [drawerStatus]);
  return (
    <Drawer anchor="right" open={drawerStatus} onClose={handleDrawerClose}>
      <div style={{ width: "300px" }}>
        <Box
          display="flex"
          flexWrap="nowrap"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
          style={{ height: "70px" }}
        >
          <div>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Change history
            </Typography>
          </div>
          <Box
            display="flex"
            onClick={() => {
              handleDrawerClose();
            }}
            alignItems="center"
          >
            <Typography
              style={{
                cursor: "pointer",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "10px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Close
            </Typography>
            <ClearIcon style={{ cursor: "pointer", fontSize: "12px" }} />
          </Box>
        </Box>
      </div>

      <div style={{ padding: "10px" }}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress style={{ color: "blue" }} size="18px" />
          </Box>
        ) : (
          <>
            {!changeLogs?.length ? (
              <Typography
                style={{
                  paddingLeft: "10px",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "13px",
                  color: "#303030",
                }}
              >
                There is not any changes
              </Typography>
            ) : (
              changeLogs?.map((log, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <Box
                    display="flex"
                    alignContent="center"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <div style={{ borderTop: "1px ridge", width: "28%" }}></div>
                    <p>{log.day}</p>
                    <div style={{ borderTop: "1px ridge", width: "28%" }}></div>
                  </Box>

                  <div
                    style={{
                      height: "140px",
                      width: "2px",
                      backgroundColor: "#E6E6FF",
                      marginTop: "15px",
                      marginLeft: "5px",
                      position: "absolute",
                    }}
                  ></div>
                  {log.values?.map((value) => (
                    <Box key={value.id}>
                      <Box display="flex" alignItems="center">
                        <RadioButtonUncheckedOutlinedIcon
                          style={{
                            color: "#2C73FF",
                            fontSize: "12px",
                            border: "3px",
                          }}
                        />
                        <p style={{ paddingLeft: "10px", color: "#303030" }}>
                          {value.time}
                        </p>
                      </Box>
                      <div style={{ padding: "15px", paddingLeft: "20px" }}>
                        <Box display="flex" alignItems="center">
                          {value.photoURL && (
                            <Avatar
                              style={{
                                border: "1px solid #2c73ff",
                                height: "25px",
                                width: "25px",
                                borderColor: "blue",
                              }}
                              alt={`${value.displayName}`}
                              src={`${value.photoURL}`}
                            />
                          )}

                          {!value.photoURL && value.displayName && (
                            <Avatar
                              style={{
                                border: "1px solid #2c73ff",
                                height: "25px",
                                width: "25px",
                                borderColor: "blue",
                              }}
                              alt={`${value.displayName}`}
                            >
                              {" "}
                              {value.displayName.charAt(0).toUpperCase()}{" "}
                            </Avatar>
                          )}

                          <Typography
                            style={{
                              paddingLeft: "10px",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "13px",
                              color: "#303030",
                            }}
                          >
                            {value.displayName}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          style={{
                            paddingTop: "20px",
                            paddingBottom: "30px",
                          }}
                        >
                          <Typography
                            onClick={() => {
                              if (value.description === "Updated the Summary") {
                                handleSummaryChangeClick(value.summaryUpdateNo);
                              } else if (
                                value.description === "Updated the Actions"
                              ) {
                                handleActionChangeClick(value.actionUpdateNo);
                              }
                            }}
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontStyle: "normal",
                              color: "#828282",
                              fontWeight: "400",
                              lineHeight: "17px",
                              letterSpacing: "0em",
                              textAlign: "left",
                              cursor: "pointer",
                            }}
                          >
                            {value.description}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  ))}
                </div>
              ))
            )}
          </>
        )}
      </div>
    </Drawer>
  );
}

export default PastMeetingsChangeLog;
