import { Box, Button, TextareaAutosize } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase/firebase.js";
import {
  addContact,
  getOrganization,
  getAlgoliaToken,
} from "../../firebase/firestore.js";
import { connect } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";

import AlgoliaAccountAutocomplete from "../../utils/algoliaSidebar.js";

const ContactAlgoliaSelect = (props) => {
  const history = useHistory();
  const { contactAccount, setContactAccount } = props;
  const [fieldsArray, setFieldsArray] = useState([]);
  const [fieldsStateObject, setFieldsStateObject] = useState({});
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  // const [contactAccount, setContactAccount] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let algoliaToken = await getAlgoliaToken();
    AlgoliaAccountAutocomplete(
      algoliaToken,
      setContactAccount,
      props.elementIdx,
      props.rowId,
      props.sourceId,
      props.indexName,
      props.organizationId,
      props.owners,
      props.handleUserSelection
    );
    setContactAccount(contactAccount);
  }, []);

  useEffect(() => {
    const newSelectedUsersList = [...selectedUsersList];
    if (Object.keys(contactAccount).length) {
      newSelectedUsersList.push(contactAccount);
    }
    setSelectedUsersList(newSelectedUsersList);
  }, [contactAccount]);

  const handleSubmit = async () => {
    let validationError = false;
    let tempStateObject = { ...fieldsStateObject };
  };

  return <Box id={`algolia-account-autocomplete${props.elementIdx}`}></Box>;
};

export default ContactAlgoliaSelect;
