import Dialog from "@mui/material/Dialog";
import { deleteAccounts } from "./../../../firebase/firestore.js";
import React, { useState } from "react";
import { Box, Button, CircularProgress, Container } from "@material-ui/core";
import ListTabular from "../ListTabular";
import Page from "../../Page";

export default function AccountsList() {
  const [accounts, setAccounts] = useState([]);
  let [selectedAccounts, setSelectedAccounts] = useState([]);
  let [accountDeleting, setAccountDeleting] = useState(false);
  const [deleteAccountDialogStatus, setDeleteAccountDialogStatus] =
    useState(false);

  const removeAccountsFromList = (removeAccounts) => {
    setAccounts(accounts.filter((item) => !removeAccounts.includes(item.id)));
  };

  return (
    <Page>
      <Dialog
        fullWidth
        open={deleteAccountDialogStatus}
        onClose={() => setDeleteAccountDialogStatus(false)}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={3}
        >
          <p>
            Are you sure you want to delete <b>{selectedAccounts.length}</b>{" "}
            account
            {selectedAccounts.length > 1 ? "s" : ""}?
          </p>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setDeleteAccountDialogStatus(false);
              }}
            >
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setAccountDeleting(true);
                let res = await deleteAccounts(selectedAccounts);

                setAccountDeleting(false);

                if (res.success == true) {
                  removeAccountsFromList(selectedAccounts);
                  setDeleteAccountDialogStatus(false);
                  setSelectedAccounts([]);
                }
              }}
            >
              Delete{" "}
            </Button>
            {accountDeleting && (
              <Box>
                <CircularProgress style={{ color: "blue" }} size="15px" />
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
      <Container maxWidth={"xl"}>
        <ListTabular
          stateData={accounts}
          setStateData={setAccounts}
          setDeleteLeadDialogStatus={setDeleteAccountDialogStatus}
          selectedRows={selectedAccounts}
          setSelectedRows={setSelectedAccounts}
          type={"accounts"}
        />
      </Container>
    </Page>
  );
}
