import React, { useState, useEffect, useMemo } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import KeyTakeawayActions from "./KeyTakeawayActions";
import { makeStyles } from "@material-ui/styles";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  getSummary,
  markActionAsReadFs,
  storeActionRatings,
  storeActions,
} from "../../firebase/firestore";
import { useHistory } from "react-router-dom";
import HoverUserInfo from "../TextEditor/HoverUserInfo";
import CreateEvent from "../dashboard/CreateEvent";

const useStyles = makeStyles((theme) => ({
  actionBox: {
    height: "300px",
    overflow: "auto",
    marginTop: "10px",
    boxShadow: "none",
    padding: "10px",
  },
  txtArea: {
    width: "100%",
    borderRadius: "10px",
    borderColor: "#dde1e5",
    padding: ".7em",
    fontFamily: "Poppins",
    minHeight: "250px !important",
    maxHeight: "250px !important",
    resize: "none",
    minWidth: "100%",
    maxWidth: "100%",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));

function MeetingDetailActionPlans({
  meetingId,
  userName,
  meetingTitle,
  showSnackbar,
  setActionHistoryNumber,
  setShowActionHistoryPopup,
  summaryData,
  participants,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [actionPlans, setActionPlans] = useState([]);
  const [actionRead, setActionRead] = useState(false);
  const [actionPlansInput, setActionPlansInput] = useState("");
  const [actionEditMode, setActionEditMode] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [actionRatings, setActionRatings] = useState();
  const [showCreateEvent, setShowCreateEvent] = useState({
    status: false,
    title: "",
    email: "",
  });

  const deleteAction = async (val) => {
    let res = await getSummary(meetingId);
    if (res) {
      const actionPlans = res.actionPlans ? res.actionPlans : [];
      let ret = actionPlans.replace(val, "");
      let newActionData = ret.split("\n");
      setActionPlans(newActionData);
      handleSaveActions(ret);
      showSnackbar({
        show: true,
        severity: "success",
        message: "Removed.",
      });
    } else {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const handleSaveActions = async (val) => {
    const actionPlans = val.split("\n");
    const removeEmptyKeys = actionPlans?.filter((item) => item !== "-");
    let payload = {
      actionPlans: removeEmptyKeys.join("\n"),
    };
    await storeActions(payload, meetingId);
    setActionPlans(removeEmptyKeys);
  };

  const generateActionPlans = () => {
    const actionPlanRegex = /\-(?![a-z])+/g;
    let actionPlans = [];
    if (summaryData?.actionPlans) {
      if (
        summaryData.actionPlans?.split("\n")?.filter((item) => item !== "")
          ?.length > 1
      ) {
        actionPlans = summaryData.actionPlans
          ?.split("\n")
          ?.filter((item) => item !== "");
      } else if (
        ("-" + summaryData.actionPlans)?.split(actionPlanRegex)?.length
      ) {
        actionPlans = ("-" + summaryData.actionPlans)
          ?.split(actionPlanRegex)
          ?.map((item) => (item !== "" ? "-" + item : null))
          .filter((item) => item);
      }
    }
    setActionPlans(actionPlans);
    setActionRead(
      summaryData?.actionPlansRead ? summaryData.actionPlansRead : false
    );
    setActionRatings(
      summaryData?.actionPlansReaction === "UP"
        ? 1
        : summaryData?.actionPlansReaction === "DOWN"
        ? 2
        : null
    );
    setLoading(false);
  };

  useMemo(() => {
    if (summaryData) {
      generateActionPlans();
    } else if (summaryData === false) {
      setActionPlans([]);
      setLoading(false);
    }
  }, [summaryData]);

  const transfromActionPlansToString = () => {
    if (Array.isArray(actionPlans) && actionPlans?.length > 0) {
      try {
        let actions = [];
        actionPlans.map((action) => actions.push(`-${action}\n`));
        let inputVal = actionPlans.join("\n");
        setActionPlansInput(inputVal);
      } catch (e) {
        setActionPlansInput("");
      }
    }
  };

  useEffect(transfromActionPlansToString, [actionPlans]);

  const handleSave = async () => {
    setSaveLoading(true);
    await handleSaveActions(actionPlansInput);
    if (!actionRead) {
      markActionAsRead();
    }
    setSaveLoading(false);
    setActionEditMode(false);
  };

  const markActionAsRead = async () => {
    if (!actionRead) {
      let res = await markActionAsReadFs(meetingId);
      if (res) {
        setActionRead(true);
        showSnackbar({
          show: true,
          severity: "success",
          message: "Action plan marked as reviewed successfully.",
        });
      } else {
        showSnackbar({
          show: true,
          severity: "error",
          message: "Something went wrong.",
        });
      }
    }
  };

  const getRatingAction = async (payload) => {
    let payloadStore = payload === 1 ? "UP" : "DOWN";
    let res = await storeActionRatings(meetingId, payloadStore);
    if (res) {
      setActionRatings(payload);
      showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
    } else {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const handleActionScrollEvent = async (e) => {
    const bottom =
      e.target.scrollHeight.toFixed(0) - e.target.scrollTop.toFixed(0) ===
      e.target.clientHeight;
    if (bottom) {
      markActionAsRead();
    }
  };

  const actionPlanRenderWithLinks = (actionPlan) => {
    let findedParticipants = [];
    participants
      .filter(
        (item) =>
          !item.name.includes(userName) ||
          !item.name.includes(userName.split(" ")[0])
      )
      .map((participant) => {
        // find participant names inside action plan and push them into to above array
        if (
          actionPlan.includes(participant.name) ||
          actionPlan.includes(participant.name.split(" ")[0])
        ) {
          findedParticipants.push(participant);
        }
      });
    let indexes = [];
    findedParticipants.map((item) => {
      // define index count of participant names beginning to end
      if (!item?.name?.length) {
        return;
      }
      let startIndex = 0;
      let endIndex = 0;
      if (actionPlan.toLowerCase().indexOf(item.name.toLowerCase()) > -1) {
        startIndex = actionPlan.toLowerCase().indexOf(item.name.toLowerCase());
        endIndex = startIndex + item.name?.length;
      } else if (
        actionPlan
          .toLowerCase()
          .indexOf(item.name.split(" ")[0]?.toLowerCase()) > -1
      ) {
        startIndex = actionPlan
          .toLowerCase()
          .indexOf(item.name.split(" ")[0]?.toLowerCase());
        endIndex = startIndex + item.name.split(" ")[0]?.length;
      }

      indexes.push({
        start: startIndex,
        end: endIndex,
        participant: item,
      });
    });

    let newActionPlanArray = [];
    indexes
      .sort((a, b) => a.start - b.start)
      .map((points, index) => {
        // Slice action plan text with defined index counts, then add participant data between sliced items.
        if (index === 0) {
          newActionPlanArray.push(actionPlan.slice(0, points.start));
          newActionPlanArray.push(points.participant);
        } else {
          newActionPlanArray.push(
            actionPlan.slice(indexes[index - 1].end, points.start)
          );
          newActionPlanArray.push(points.participant);
        }
        if (index + 1 === indexes.length) {
          newActionPlanArray.push(actionPlan.slice(points.end));
        }
      });

    // Return data as JSX
    return (
      <div className="action-txt">
        {newActionPlanArray?.length
          ? newActionPlanArray.map((item, index) =>
              typeof item === "string" ? (
                <span key={index}>{item}</span>
              ) : (
                <HoverUserInfo
                  key={index}
                  name={item?.name}
                  email={item?.email}
                  button={item?.type && item?.id}
                  buttonTitle={"Open details"}
                  buttonAction={() => {
                    history.push(`/crm/${item.type}/${item.id}`);
                  }}
                  avatar={item?.avatar}
                  disableEventIcon={false}
                  eventIconAction={() => {
                    setShowCreateEvent({
                      status: true,
                      email: item?.email,
                      title: `Meeting with ${item?.name}`,
                    });
                  }}
                >
                  <u style={{ cursor: "pointer" }}>{item?.name}</u>
                </HoverUserInfo>
              )
            )
          : actionPlan}
      </div>
    );
  };

  return (
    <>
      {showCreateEvent.status && (
        <CreateEvent
          onClose={() =>
            setShowCreateEvent({
              status: false,
              email: "",
              title: "",
            })
          }
          email={showCreateEvent.email}
          open={showCreateEvent.status}
          title={showCreateEvent.title}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="past-meeting-section-title"
      >
        <h5 className="agenda">Key Takeaways</h5>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="meeting-detail-icon-container"
        >
          <div className="icons-container">
            {actionPlans && actionPlans.length > 0 && (
              <>
                {!actionRead ? (
                  <CheckCircleOutlineIcon
                    sx={{ color: "#c3c3c3", cursor: "pointer" }}
                    onClick={() => markActionAsRead()}
                  />
                ) : (
                  <CheckCircleOutlineIcon sx={{ color: "#2c73ff" }} />
                )}
              </>
            )}
            <EditIcon
              sx={{ color: "#c3c3c3", cursor: "pointer" }}
              onClick={() => {
                if (actionPlans?.length) {
                  setActionEditMode(!actionEditMode);
                }
              }}
            />

            {actionPlans?.length > 0 && (
              <HistoryIcon
                sx={{ color: "#c3c3c3", cursor: "pointer" }}
                onClick={() => {
                  setActionHistoryNumber(false);
                  setShowActionHistoryPopup(true);
                }}
              />
            )}
            <ThumbUpAltOutlinedIcon
              sx={{
                color: actionRatings === 1 ? "#2c73ff" : "#c3c3c3",
                cursor: "pointer",
              }}
              onClick={() => {
                if (actionPlans?.length) {
                  getRatingAction(1);
                }
              }}
            />
            <ThumbDownAltOutlinedIcon
              sx={{
                color: actionRatings === 2 ? "#2c73ff" : "#c3c3c3",
                cursor: "pointer",
              }}
              onClick={() => {
                if (actionPlans?.length) {
                  getRatingAction(2);
                }
              }}
            />
          </div>
        </Box>
      </Box>
      <Box
        bgcolor="#fff"
        borderRadius="10px"
        className={classes.actionBox}
        sx={
          actionEditMode
            ? { height: "auto !important" }
            : { height: "calc(100% - 36px)" }
        }
        onScroll={(e) => handleActionScrollEvent(e)}
      >
        {actionPlans?.length > 0 &&
          !actionEditMode &&
          actionPlans?.map((actionPlan, index) => {
            if (actionPlan.length > 0) {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  key={index}
                  alignItems="center"
                >
                  <Box>{actionPlanRenderWithLinks(actionPlan)}</Box>
                  <Box>
                    <KeyTakeawayActions
                      deleteAction={deleteAction}
                      keyTakeaway={actionPlan}
                      meetingTitle={meetingTitle}
                    />
                  </Box>
                </Box>
              );
            }
          })}
        {loading ? (
          <Box
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ fontSize: 14 }}
          >
            <CircularProgress size="24px" style={{ color: "#2c73ff" }} />
          </Box>
        ) : (
          !actionPlans?.length && (
            <Box
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ fontSize: 14 }}
            >
              No data found
            </Box>
          )
        )}
        {actionEditMode && actionPlans.length ? (
          <Box>
            <TextareaAutosize
              aria-label="minimum height"
              placeholder="Dialog"
              value={actionPlansInput}
              onChange={(e) => setActionPlansInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setActionPlansInput(actionPlansInput + "\n-");
                }
              }}
              className={classes.txtArea}
              style={{ height: "100%", overflow: "auto" }}
            />
            <button className="modal-button blue" onClick={handleSave}>
              {saveLoading ? (
                <CircularProgress size="15px" style={{ color: "#fff" }} />
              ) : (
                "Save"
              )}
            </button>
            <button
              className="modal-button white ml"
              onClick={() => {
                transfromActionPlansToString();
                setActionEditMode(false);
              }}
            >
              Cancel
            </button>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default MeetingDetailActionPlans;
