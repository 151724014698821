import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import firebase from "../../firebase/firebase";
import { getData, postData } from "../../utils";

// import MicIcon from "@mui/icons-material/Mic";
// import StopCircleIcon from "@mui/icons-material/StopCircle";
// import AudioAnalyser from "./AudioAnalyser";
import SaveMeeting from "./SaveMeeting";

import Recorder from "./Recorder";
import RecordOnlineMeeting from "./RecordOnlineMeeting";

function OnpromiseMeetingModal({ open, onClose }) {
  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // const [audio, setAudio] = useState(null);
  // const [chunks, setChunks] = useState([]);
  // const [recordStartTime, setRecordStartTime] = useState(null);
  // const [duration, setDuration] = useState("00:00:00");
  // const [audioURL, setAudioURL] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [meetingId, setMeetingId] = useState(null);

  const [fileError, setFileError] = useState(false);

  // let mediaRecorder;

  // const changeDuration = () => {
  //   if (recordStartTime) {
  //     const getDiffInSec = Math.round(
  //       (new Date().getTime() - recordStartTime) / 1000
  //     );

  //     let hours = Math.floor(getDiffInSec / 60 / 60);
  //     let mins = Math.floor(getDiffInSec / 60) - hours * 60;
  //     let secs = Math.floor(getDiffInSec) - mins * 60 - hours * 60;
  //     console.log(getDiffInSec, secs);

  //     setDuration(
  //       `${hours > 0 ? (hours < 10 ? `0${hours}` : hours) : "00"}:${
  //         mins > 0 ? (mins < 10 ? `0${mins}` : mins) : "00"
  //       }:${secs < 10 ? `0${secs}` : `${secs}`}`
  //     );
  //   }
  // };

  // useEffect(() => {
  //   let durationInterval;
  //   if (audio) {
  //     if (!recordStartTime) {
  //       setRecordStartTime(new Date().getTime());
  //     } else {
  //       durationInterval = setInterval(() => changeDuration(), 1000);
  //     }
  //   } else {
  //     setRecordStartTime(null);
  //     clearInterval(durationInterval);
  //   }
  //   return () => {
  //     clearInterval(durationInterval);
  //   };
  // }, [audio, recordStartTime]);

  // const startRecord = (stream) => {
  //   mediaRecorder = new MediaRecorder(stream);
  //   mediaRecorder.start();
  //   mediaRecorder.ondataavailable = function (e) {
  //     setChunks([...chunks, e.data]);
  //   };
  //   mediaRecorder.onstop = function (e) {
  //     console.log("recorder stopped");
  //     const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
  //     setChunks([]);
  //     const generatedAudioURL = window.URL.createObjectURL(blob);
  //     setAudioURL(generatedAudioURL);
  //     console.log(generatedAudioURL, "audio URL");
  //   };
  // };

  // const stopRecord = () => {
  //   mediaRecorder?.stop();
  // };

  // const getMicrophone = async () => {
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     const getAudio = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: false,
  //     });
  //     startRecord(getAudio);
  //     setAudio(getAudio);
  //   }
  // };

  // const stopMicrophone = () => {
  //   audio.getTracks().forEach((track) => track.stop());
  //   stopRecord();
  //   setAudio(null);
  // };
  // const toggleMicrophone = () => {
  //   if (audio) {
  //     stopMicrophone();
  //   } else {
  //     getMicrophone();
  //   }
  // };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleFIleUpload = async (files, rejectedFiles) => {
    console.log("files", files, rejectedFiles);

    if (files.length) {
      setFileError(false);

      let jwtToken = await firebase.auth().currentUser.getIdToken();
      let newMeetingResponse = await getData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/recorded/create`,
        jwtToken
      );

      if (newMeetingResponse.status === "success") {
        setMeetingId(newMeetingResponse.data.id);
        let uploadURLResponse = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/recorded/upload`,
          jwtToken,
          { meetingId: newMeetingResponse.data.id, filename: files[0].name }
        );

        console.log("uploadURLResponse", uploadURLResponse);

        if (uploadURLResponse.status === "success") {
          setUploading(true);
          axios
            .request({
              method: "put",
              url: uploadURLResponse.data.url,
              headers: {
                "content-type": "application/octet-stream",
              },
              data: files[0],
              onUploadProgress: (p) => {
                document.getElementById("upload-progress").value =
                  p.loaded / p.total - 0.05;
              },
            })
            .then((data) => {
              setStep(2);
            });
        }
      }
    } else {
      if (rejectedFiles.length) {
        setFileError(rejectedFiles[0].errors[0].message);
      }
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "unset",
            height: "80vh",
          },
        },
      }}
    >
      <div className="onpromise-meeting-container">
        {step === 2 ? (
          <SaveMeeting closeModal={onClose} meetingId={meetingId} />
        ) : (
          <Box width={8 / 10} height={8 / 10}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Record meeting now" {...a11yProps(0)} />
              <Tab label="Upload recording" {...a11yProps(1)} />
              <Tab label="Online meeting" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
              <Box>
                <Recorder setStep={setStep} setMeetingId={setMeetingId} />
              </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Box>
                <Dropzone
                  maxFiles={3}
                  multiple={false}
                  maxSize={3000000000}
                  onDrop={handleFIleUpload}
                  accept="audio/*,video/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      padding="20px"
                      style={{
                        // height: "100px",
                        cursor: "pointer",
                        borderWidth: "2px",
                        borderRedius: "2px",
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "#bdbdbd",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <CloudUploadIcon />
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop recorded file here, or click to select
                          file
                        </p>
                      </Box>

                      {uploading && (
                        <div>
                          {" "}
                          upload: <progress id="upload-progress"></progress>
                        </div>
                      )}
                    </Box>
                  )}
                </Dropzone>
                {fileError && <p style={{ color: "red" }}>{fileError}</p>}
              </Box>
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
              <RecordOnlineMeeting
                setStep={setStep}
                setMeetingId={setMeetingId}
              />
            </TabPanel>
          </Box>
        )}
      </div>
    </Dialog>
  );
}

export default OnpromiseMeetingModal;
