import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Post } from "../../utils/axiosUtils";
import { sentimentIcons } from "../../utils/sentimentIcons";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function TeamMembersTable({ isSentimentAvailable }) {
  const { fireStoreuserObj } = useAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  let columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              src={params.row.avatar}
              sx={{
                width: 40,
                height: 40,
                border: "1px solid #7DA8FB",
                mr: 1,
              }}
            />
            {params.row.name}
          </Box>
        );
      },
    },
    {
      field: "avarageDuration",
      headerName: "Avarage Duration",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "avarageMeetingsPerDay",
      headerName: "Avarage Meetings Per Day",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "avarageEngagement",
      headerName: "Avarage Engagement",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "positiveMeetingRate",
      headerName: "Positive Meeting Rate",
      headerClassName: "data-table-header",
      cellClassName: "data-table-cell",
      minWidth: 120,
      flex: 1,
    },
  ];

  const sum = (previous, current) => {
    if (!current) {
      return previous;
    }
    return previous + current;
  };

  const getTeamMembers = () => {
    setLoading(true);
    const postBody = {
      start: new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
      end: new Date().getTime(),
      organizationId: fireStoreuserObj.organizationId, //"a8a69ba0-1362-448c-9d57-07371257aa2e"
    };
    Post("analytics/getOrganizationUsersData", postBody)
      .then(({ data: { data } }) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const transformData = () => {
    if (!data) {
      return;
    }
    if (data?.length === 0) {
      return;
    }
    setLoading(true);
    const newTableRows = [];
    data?.map((user, index) => {
      let userData = { id: index };
      userData.name = user?.displayName;
      userData.id = user?.uid;
      userData.avatar = user?.photoURL;

      // Calculate durations
      let durations = user?.meetingData?.map((item) => item?.duration);
      if (durations?.length > 0) {
        let totalDuration = durations.reduce(sum) || 0;
        let avarageDuration = Math.round(totalDuration / durations?.length);
        let hours = Math.floor(avarageDuration / 60 / 60);
        let mins = Math.floor(avarageDuration / 60) - hours * 60;
        let avarageDurationText = `${hours > 0 ? hours + "h " : ""}${
          mins > 0 ? mins + "m" : ""
        }`;
        userData.avarageDuration = avarageDurationText;
      } else {
        userData.avarageDuration = "0m";
      }

      // Avarage meetings per day
      let totalMeetings = user.meetingData?.length;
      userData.avarageMeetingsPerDay = (totalMeetings / 7).toFixed(2); // 7 is date range, date range stable for that component

      // Calculate Sentiments
      if (isSentimentAvailable) {
        //add sentiment column
        columns.splice(3, 0, {
          field: "avarageSentiment",
          headerName: "Average Sentiment",
          headerClassName: "data-table-header",
          cellClassName: "data-table-cell",
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            return (
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    mr: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {sentimentIcons[params.row.avarageSentiment?.value]}
                </Box>
                {params.row.avarageSentiment?.label}
              </Box>
            );
          },
          sortComparator: (v1, v2) =>
            v1.sentimentScore.localeCompare(v2.sentimentScore),
        });

        let sentiments = [];
        user?.meetingData?.map((meeting) => {
          if (meeting?.sentiment?.emotion) {
            let sentiment = meeting.sentiment.emotion;
            if (sentiment === "NEGATIVE_2") {
              sentiments.push(0);
            } else if (sentiment === "NEGATIVE_1") {
              sentiments.push(25);
            } else if (sentiment === "NEUTRAL" || sentiment === "MIXED") {
              sentiments.push(50);
            } else if (sentiment === "POSITIVE_1") {
              sentiments.push(75);
            } else if (sentiment === "POSITIVE_2") {
              sentiments.push(100);
            }
          }
        });
        let totalSentimentScore =
          sentiments.length > 0 ? sentiments.reduce(sum) : null;
        let avarageSentimentScore =
          sentiments?.length > 0
            ? totalSentimentScore / sentiments.length
            : null;

        let sentimentLabel;
        if (!avarageSentimentScore) {
          sentimentLabel = {
            label: "",
            value: "",
          };
        } else if (avarageSentimentScore < 25) {
          sentimentLabel = {
            label: "Very negative",
            value: "NEGATIVE_2",
          };
        } else if (avarageSentimentScore <= 49) {
          sentimentLabel = {
            label: "Negative",
            value: "NEGATIVE_1",
          };
        } else if (avarageSentimentScore <= 74) {
          sentimentLabel = {
            label: "Neutral",
            value: "NEUTRAL",
          };
        } else if (avarageSentimentScore < 100) {
          sentimentLabel = {
            label: "Positive",
            value: "POSITIVE_1",
          };
        } else if (avarageSentimentScore === 100) {
          sentimentLabel = {
            label: "Very Positive",
            value: "POSITIVE_2",
          };
        }
        sentimentLabel.sentimentScore = avarageSentimentScore
          ? avarageSentimentScore.toString()
          : "0";
        userData.avarageSentiment = sentimentLabel;
      }

      // Calculate Engagements
      let totalEngagements = 0;
      user?.meetingData?.map((meeting) => {
        if (
          meeting?.participation?.participation &&
          !meeting?.participation?.noShow
        ) {
          totalEngagements += Number(meeting.participation.participation);
        }
      });
      if (totalEngagements === 0) {
        userData.avarageEngagement = "0%";
      } else {
        userData.avarageEngagement =
          Math.round(totalEngagements / user?.meetingData?.length) + "%";
      }

      // Calculate positive meetings
      let totalPositiveMeetings = 0;
      user?.meetingData?.map((meeting) => {
        if (meeting?.meetingOutcome === "POSITIVE") {
          totalPositiveMeetings += 1;
        }
      });

      if (totalPositiveMeetings === 0) {
        userData.positiveMeetingRate = "0%";
      } else {
        userData.positiveMeetingRate =
          Math.round(totalPositiveMeetings / user?.meetingData?.length) + "%";
      }
      userData.meetings = user?.meetingData;

      newTableRows.push(userData);
    });
    setTableRows(newTableRows);
    setTableColumns(columns);
    setLoading(false);
  };

  useEffect(getTeamMembers, []);
  useEffect(transformData, [data]);

  return (
    <>
      {loading ? (
        <Box justifyContent="center" alignItems="center" display="flex">
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <DataGrid
          rows={tableRows}
          columns={tableColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          disableSelectionOnClick
          enableCellSelect={false}
          getRowClassName={() => `data-table-row`}
          onRowClick={(member) =>
            history.push({
              pathname: `/teams/${member.id}`,
              state: { from: "teams" },
            })
          }
          className={"data-table"}
        />
      )}
    </>
  );
}

export default TeamMembersTable;
