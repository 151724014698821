import React, { useState, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import { TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Icon } from "@iconify/react";
import ContactAlgoliaSelect from "../ContactAlgoliaSelect";
import TopicAlgoliaSelect from "../topicsAlgolia/TopicAlgoliaSelect";
import {
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

const ConversationItem = ({
  elem,
  index,
  conversationListData,
  setConversationListData,
}) => {
  const [contactsOrAnd, setContactsOrAnd] = useState("");
  const [contactAccount, setContactAccount] = useState({});
  const [topic, setTopic] = useState({});
  const [filterDimensionsContains, setFilterDimensionsContains] =
    useState("Hosted By");
  const [conversationsDimensionsItems, setConversationsDimensionsItems] =
    useState([
      "Anyone",
      "By me",
      "Not by me",
      "Specific people",
      "Contains",
      "Does not contain",
    ]);
  const outcomeOptions = ["positive", "neutral", "negative"];

  const handleContactsOrAndChange = (event, newAlignment) => {
    setContactsOrAnd(newAlignment);
  };

  const handlefilterDimensionsChange = (event, idx) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].firstSelectResult = event.target.value;
    if (event.target.value === "Hosted By") {
      newConversationListData[idx].secondSelectResult = "Anyone";
      newConversationListData[idx].secondSelectList = [
        "Anyone",
        "By me",
        "Not by me",
        "Specific people",
        "Contains",
        "Does not contain",
      ];
    }
    if (event.target.value === "Key takeaways") {
      newConversationListData[idx].secondSelectResult = "Contains";
      newConversationListData[idx].secondSelectList = [
        "Contains",
        "Does not contain",
      ];
    } else if (event.target.value === "Participants") {
      newConversationListData[idx].secondSelectResult = "Anyone";
      newConversationListData[idx].secondSelectList = [
        "Anyone",
        "By me",
        "Not by me",
        "Specific people",
        "Contains",
        "Does not contain",
      ];
    } else if (event.target.value === "Number of participant(s)") {
      newConversationListData[idx].secondSelectResult = "Less than";
      newConversationListData[idx].secondSelectList = [
        "Less than",
        "More than",
        "Equals to",
      ];
    } else if (event.target.value === "Outcome") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Overall Meeting Sentiment") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Type (Internal vs External)") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Transcripts") {
      newConversationListData[idx].secondSelectResult = "Contains";
      newConversationListData[idx].secondSelectList = [
        "Contains",
        "Does not contain",
        "Exactly matches",
      ];
    } else if (event.target.value === "Notes") {
      newConversationListData[idx].secondSelectResult = "Contains";
      newConversationListData[idx].secondSelectList = [
        "Contains",
        "Does not contain",
        "Exactly matches",
      ];
    } else if (event.target.value === "Title") {
      newConversationListData[idx].secondSelectResult = "Contains";
      newConversationListData[idx].secondSelectList = [
        "Contains",
        "Does not contain",
        "Exactly matches",
      ];
    } else if (event.target.value === "Products") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Competitors") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Topics") {
      newConversationListData[idx].secondSelectResult = "Includes";
      newConversationListData[idx].secondSelectList = [
        "Includes",
        "Does not include",
      ];
    } else if (event.target.value === "Price") {
      newConversationListData[idx].secondSelectResult = "Less than";
      newConversationListData[idx].secondSelectList = [
        "Less than",
        "More than",
        "Equals to",
      ];
    } else if (event.target.value === "Attendance") {
      newConversationListData[idx].secondSelectResult = "Meetings I missed";
      newConversationListData[idx].secondSelectList = [
        "Meetings I missed",
        "Meetings I joined late",
        "Meetings I left early",
        "Meetings missed by others",
        "Meeting joined late by others",
        "Meetings left early by others",
        "Attendance rate",
      ];
    }
    setConversationListData(newConversationListData);
  };

  const handlefilterDimensionsItemsChange = (event, idx) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].secondSelectResult = event.target.value;
    setConversationListData(newConversationListData);
  };

  const contactDeleteHandleClick = (idx, peopleId) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].selectedSpecificPeoples.splice(peopleId, 1);
    setConversationListData(newConversationListData);
  };
  const topicDeleteHandleClick = (idx, peopleId) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].selectedTopics.splice(peopleId, 1);
    setConversationListData(newConversationListData);
  };

  const handleAttendaceRateItemsChange = (event, idx) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].selectedAttendanceRate = event.target.value;
    setConversationListData(newConversationListData);
  };

  const handleTypeItemsChange = (event, idx) => {
    let newConversationListData = [...conversationListData];
    newConversationListData[idx].typeResult = event.target.value;
    setConversationListData(newConversationListData);
  };

  const conversationsOrHandleClick = () => {
    setConversationListData([
      ...conversationListData,
      {
        id: uuidv4(),
        firstSelectResult: "Hosted By",
        secondSelectResult: "Anyone",
        secondSelectList: [
          "Anyone",
          "By me",
          "Not by me",
          "Specific people",
          "Contains",
          "Does not contain",
        ],
        typeResult: "Internal",
        typeList: ["Internal", "External"],
        selectedAttendanceRate: "Less than",
        attendanceRateList: ["Less than", "More than", "Equals to"],
        selectedSpecificPeoples: [],
        selectedTopics: [],
        selects: [
          {
            id: 1,
            dimension: "Hosted By",
            options: [
              "Anyone",
              "By me",
              "Not by me",
              "Specific people",
              "Contains",
              "Does not contain",
            ],
          },
          {
            id: uuidv4(),
            dimension: "Key takeaways",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 2,
            dimension: "Participants",
            options: [
              "Anyone",
              "By me",
              "Not by me",
              "Specific people",
              "Contains",
              "Does not contain",
            ],
          },
          {
            id: 3,
            dimension: "Number of participant(s)",
            options: ["Less than", "More than", "Equals to"],
          },
          {
            id: 4,
            dimension: "Outcome",
            options: ["Includes", "Does not include"],
          },
          {
            id: 5,
            dimension: "Overall Meeting Sentiment",
            options: ["Includes", "Does not include"],
          },
          {
            id: 6,
            dimension: "Attendance",
            options: [
              "Meetings I missed",
              "Meetings I joined late",
              "Meetings I left early",
              "Meetings missed by others",
              "Meeting joined late by others",
              "Meetings left early by others",
              "Attendance rate",
            ],
          },
          {
            id: 7,
            dimension: "Type (Internal vs External)",
            options: ["Includes", "Does not include"],
          },
          {
            id: 8,
            dimension: "Transcripts",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 9,
            dimension: "Notes",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 10,
            dimension: "Title",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 11,
            dimension: "Products",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 12,
            dimension: "Competitors",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 13,
            dimension: "Topics",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 14,
            dimension: "Price",
            options: ["Less than", "More than", "Equals to"],
          },
        ],
      },
    ]);
  };

  const conversationsAndHandleClick = () => {
    setConversationListData([
      ...conversationListData,
      {
        id: uuidv4(),
        firstSelectResult: "Hosted By",
        secondSelectResult: "Anyone",
        secondSelectList: [
          "Anyone",
          "By me",
          "Not by me",
          "Specific people",
          "Contains",
          "Does not contain",
        ],
        typeResult: "Internal",
        typeList: ["Internal", "External"],
        selectedAttendanceRate: "Less than",
        attendanceRateList: ["Less than", "More than", "Equals to"],
        selectedSpecificPeoples: [],
        selectedTopics: [],
        selects: [
          {
            id: 1,
            dimension: "Hosted By",
            options: [
              "Anyone",
              "By me",
              "Not by me",
              "Specific people",
              "Contains",
              "Does not contain",
            ],
          },
          {
            id: uuidv4(),
            dimension: "Key takeaways",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 2,
            dimension: "Participants",
            options: [
              "Anyone",
              "By me",
              "Not by me",
              "Specific people",
              "Contains",
              "Does not contain",
            ],
          },
          {
            id: 3,
            dimension: "Number of participant(s)",
            options: ["Less than", "More than", "Equals to"],
          },
          {
            id: 4,
            dimension: "Outcome",
            options: ["Includes", "Does not include"],
          },
          {
            id: 5,
            dimension: "Overall Meeting Sentiment",
            options: ["Includes", "Does not include"],
          },
          {
            id: 6,
            dimension: "Attendance",
            options: [
              "Meetings I missed",
              "Meetings I joined late",
              "Meetings I left early",
              "Meetings missed by others",
              "Meeting joined late by others",
              "Meetings left early by others",
              "Attendance rate",
            ],
          },
          {
            id: 7,
            dimension: "Type (Internal vs External)",
            options: ["Includes", "Does not include"],
          },
          {
            id: 8,
            dimension: "Transcripts",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 9,
            dimension: "Notes",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 10,
            dimension: "Title",
            options: ["Contains", "Does not contain", "Exactly matches"],
          },
          {
            id: 11,
            dimension: "Products",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 12,
            dimension: "Competitors",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 13,
            dimension: "Topics",
            options: ["Includes", "Does not includes"],
          },
          {
            id: 14,
            dimension: "Price",
            options: ["Less than", "More than", "Equals to"],
          },
        ],
      },
    ]);
  };

  const conversationsCancelHandleClick = () => {
    if (conversationListData.length > 1) {
      conversationListData.pop();
    } else {
      setConversationListData([
        {
          id: uuidv4(),
          firstSelectResult: "Hosted By",
          secondSelectResult: "Anyone",
          secondSelectList: [
            "Anyone",
            "By me",
            "Not by me",
            "Specific people",
            "Contains",
            "Does not contain",
          ],
          typeResult: "Internal",
          typeList: ["Internal", "External"],
          selectedAttendanceRate: "Less than",
          attendanceRateList: ["Less than", "More than", "Equals to"],
          selectedSpecificPeoples: [],
          selectedTopics: [],
          selects: [
            {
              id: 1,
              dimension: "Hosted By",
              options: [
                "Anyone",
                "By me",
                "Not by me",
                "Specific people",
                "Contains",
                "Does not contain",
              ],
            },
            {
              id: uuidv4(),
              dimension: "Key takeaways",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 2,
              dimension: "Participants",
              options: [
                "Anyone",
                "By me",
                "Not by me",
                "Specific people",
                "Contains",
                "Does not contain",
              ],
            },
            {
              id: 3,
              dimension: "Number of participant(s)",
              options: ["Less than", "More than", "Equals to"],
            },
            {
              id: 4,
              dimension: "Outcome",
              options: ["Includes", "Does not include"],
            },
            {
              id: 5,
              dimension: "Overall Meeting Sentiment",
              options: ["Includes", "Does not include"],
            },
            {
              id: 6,
              dimension: "Attendance",
              options: [
                "Meetings I missed",
                "Meetings I joined late",
                "Meetings I left early",
                "Meetings missed by others",
                "Meeting joined late by others",
                "Meetings left early by others",
                "Attendance rate",
              ],
            },
            {
              id: 7,
              dimension: "Type (Internal vs External)",
              options: ["Includes", "Does not include"],
            },
            {
              id: 8,
              dimension: "Transcripts",
              options: ["Contains", "Does not contain", "Exactly matches"],
            },
            {
              id: 9,
              dimension: "Notes",
              options: ["Contains", "Does not contain", "Exactly matches"],
            },
            {
              id: 10,
              dimension: "Title",
              options: ["Contains", "Does not contain", "Exactly matches"],
            },
            {
              id: 11,
              dimension: "Products",
              options: ["Includes", "Does not includes"],
            },
            {
              id: 12,
              dimension: "Competitors",
              options: ["Includes", "Does not includes"],
            },
            {
              id: 13,
              dimension: "Topics",
              options: ["Includes", "Does not includes"],
            },
            {
              id: 14,
              dimension: "Price",
              options: ["Less than", "More than", "Equals to"],
            },
          ],
        },
      ]);
    }
  };

  useEffect(() => {
    let newConversationListData = [...conversationListData];

    if (Object.keys(contactAccount).length) {
      newConversationListData.map((elem) => {
        if (elem.id === contactAccount.rowId) {
          elem.selectedSpecificPeoples.push(contactAccount);
        }
      });
      setConversationListData(newConversationListData);
    }
  }, [contactAccount]);

  useEffect(() => {
    let newConversationListData = [...conversationListData];

    if (Object.keys(topic).length) {
      newConversationListData.map((elem) => {
        if (elem.id === topic.rowId) {
          elem.selectedTopics.push(topic);
        }
      });
      setConversationListData(newConversationListData);
    }
  }, [topic]);

  useEffect(() => {
    conversationListData.map((elem) => {
      elem.selects.map((item) => {
        if (filterDimensionsContains === item.dimension) {
          setConversationsDimensionsItems(item.options);
          elem.firstSelectResult = item.dimension;
          elem.secondSelectList = item.options;
          elem.secondSelectResult = item.options[0];
        }
      });
    });
  }, [filterDimensionsContains]);

  return (
    <Grid
      position="relative"
      display="flex"
      padding="15px 0"
      justifyContent="space-between"
      style={{
        width: "100%",
        position: "relative",
        minHeight: "100px",
        borderRadius: "10px",
      }}
    >
      <Grid xs={10} sm={10} md={10}>
        <Select
          value={elem.firstSelectResult}
          onChange={(e) => handlefilterDimensionsChange(e, index)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          style={{ marginRight: "10px", maxWidth: "120px" }}
        >
          {elem.selects.map((elem) => {
            return (
              <MenuItem key={elem.id} value={elem.dimension}>
                {elem.dimension}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          value={elem.secondSelectResult}
          onChange={(e) => handlefilterDimensionsItemsChange(e, index)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          style={{ marginRight: "10px", maxWidth: "120px" }}
        >
          {elem.secondSelectList.length &&
            elem.secondSelectList.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
        </Select>
        {elem.secondSelectResult === "Contains" ||
        elem.secondSelectResult === "Does not contain" ? (
          <TextField type="text" size="small" style={{ width: "150px" }} />
        ) : elem.secondSelectResult === "Less than" ||
          elem.secondSelectResult === "More than" ||
          elem.secondSelectResult === "Equals to" ? (
          <TextField type="number" size="small" style={{ width: "150px" }} />
        ) : elem.secondSelectResult === "Specific people" ? (
          <Box display="flex" justifyContent="space-between" marginTop={"10px"}>
            {elem.selectedSpecificPeoples?.length ? (
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="center"
                gap="3px"
                flexWrap="wrap"
              >
                {elem.selectedSpecificPeoples.map((people, i) => {
                  return (
                    <div
                      style={{ position: "relative" }}
                      className="contact-icon-section"
                    >
                      <img
                        src={people.avatar || people.avatarURL}
                        alt="img"
                        width={30}
                        height={30}
                        style={{ borderRadius: "50%" }}
                      />
                      <Icon
                        icon="ci:close-big"
                        color="white"
                        className="contact-icon"
                        cursor={"pointer"}
                        onClick={() => contactDeleteHandleClick(index, i)}
                      />
                    </div>
                  );
                })}
              </Box>
            ) : (
              <Box></Box>
            )}
            <ContactAlgoliaSelect
              contactAccount={contactAccount}
              setContactAccount={setContactAccount}
              elementIdx={index}
              rowId={elem.id}
              sourceId={"contacts"}
              indexName={`${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_contacts`}
            />
          </Box>
        ) : elem.firstSelectResult === "Topics" ? (
          <Box display="flex" justifyContent="space-between" marginTop={"10px"}>
            {elem.selectedTopics?.length ? (
              <Box
                display={"grid"}
                gridTemplateColumns="1fr"
                alignItems="center"
                justifyContent="center"
                gap="5px"
                flexWrap="wrap"
              >
                {elem.selectedTopics.map((topic, i) => {
                  return (
                    <div
                      style={{ position: "relative", cursor: "pointer" }}
                      className="topic-section"
                    >
                      <p onClick={() => topicDeleteHandleClick(index, i)}>
                        <span style={{ marginRight: "3px" }}>{i + 1}.</span>
                        {topic.name}
                      </p>
                    </div>
                  );
                })}
              </Box>
            ) : (
              <Box></Box>
            )}
            <TopicAlgoliaSelect
              topic={topic}
              setTopic={setTopic}
              elementIdx={index}
              rowId={elem.id}
            />
          </Box>
        ) : elem.secondSelectResult === "Attendance rate" ? (
          <>
            <Select
              value={elem.selectedAttendanceRate}
              onChange={(e) => handleAttendaceRateItemsChange(e, index)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              size="small"
              style={{ marginLeft: "10px", maxWidth: "120px" }}
            >
              {elem.attendanceRateList.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
            {elem.selectedAttendanceRate === "Less than" ||
            elem.selectedAttendanceRate === "More than" ||
            elem.selectedAttendanceRate === "Equals to" ? (
              <TextField
                type="number"
                size="small"
                style={{ width: "150px", marginTop: "10px" }}
              />
            ) : (
              <></>
            )}
          </>
        ) : elem.firstSelectResult === "Outcome" &&
          (elem.secondSelectResult === "Includes" ||
            elem.secondSelectResult === "Does not include") ? (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={outcomeOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            style={{ width: "140px", display: "inline-flex" }}
            size="small"
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Outcome types" />
            )}
          />
        ) : elem.firstSelectResult === "Type (Internal vs External)" &&
          (elem.secondSelectResult === "Includes" ||
            elem.secondSelectResult === "Does not include") ? (
          <Select
            value={elem.typeResult}
            onChange={(e) => handleTypeItemsChange(e, index)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            size="small"
            style={{ marginLeft: "10px", maxWidth: "120px" }}
          >
            {elem.typeList.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        ) : (
          <></>
        )}
      </Grid>
      <Grid xs={2} sm={2} md={2} textAlign="right">
        <ToggleButtonGroup
          color="primary"
          value={contactsOrAnd}
          exclusive
          onChange={handleContactsOrAndChange}
          size="small"
        >
          <ToggleButton value="-" onClick={conversationsCancelHandleClick}>
            -
          </ToggleButton>
          <ToggleButton value="or" onClick={conversationsOrHandleClick}>
            or
          </ToggleButton>
          <ToggleButton value="and" onClick={conversationsAndHandleClick}>
            and
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ConversationItem;
