/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import SentimentLineChartDropdown from "../dropdowns/SentimentLineChartDropdown";
import { Box, CircularProgress } from "@material-ui/core";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { sentimentIcons } from "../../utils/sentimentIcons";
import ModalMeetingsTable from "../modalMeetingsTable";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const yAxesIcons = [
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM8 13H16C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 9V9.5"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 9V9.5"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 15.5C15.5 15.5 14.5 17.5 12 17.5C9.5 17.5 8.5 15.5 8.5 15.5"
      stroke="#7DA8FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM8 14H16V16H8V14ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
      fill="#7DA8FB"
    />
  </svg>,
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 17C7 15.6739 7.52678 14.4021 8.46447 13.4645C9.40215 12.5268 10.6739 12 12 12C13.3261 12 14.5979 12.5268 15.5355 13.4645C16.4732 14.4021 17 15.6739 17 17H15C15 16.2044 14.6839 15.4413 14.1213 14.8787C13.5587 14.3161 12.7956 14 12 14C11.2044 14 10.4413 14.3161 9.87868 14.8787C9.31607 15.4413 9 16.2044 9 17H7ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z"
      fill="#7DA8FB"
    />
  </svg>,
];

const SentimentLineChart = ({ data, fetchLoading, dateRangeController }) => {
  const { fireStoreuserObj } = useAuth();
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [compare, setCompare] = useState(false);
  const [days, setDays] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [yAxisHeight, setYAxisHeight] = useState(10);
  const [loading, setLoading] = useState(true);
  const [totalAvarages, setTotalAvarages] = useState({
    allMeetings: 0,
    participation: 0,
    sentiment: 0,
    previousAllMeetingsRate: 0,
    previousParticipationRate: 0,
    previousSentimentRate: 0,
  });
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);
  const [meetingModal, setMeetingModal] = useState(false);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);
  const [meetingIds, setMeetingIds] = useState([]);

  const dateFormatter = (date) => {
    //This function return ISO dates to mm/dd/yyyy format.
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const sum = (previous, current) => {
    if (!current) {
      return previous;
    }
    return previous + current;
  };

  const calculateTotalAvarage = () => {
    if (days.length === 0) {
      return;
    }
    const values = days.slice(dateRange.value, days.length);

    // Current Values Calculation

    let currentSentimentCount = 0;
    let quantityOfSentiment = 0;
    values.map((item) => {
      item.sentiment.map((sentiment) => (currentSentimentCount += sentiment));
      item.sentiment?.length > 0
        ? (quantityOfSentiment += item.sentiment.length)
        : null;
    });
    let currentParticipationCount = 0;
    let quantityOfParticipation = 0;
    values.map((item) => {
      item.participation.map((item) => (currentParticipationCount += item));
      item.participation?.length > 0
        ? (quantityOfParticipation += item.participation.length)
        : null;
    });

    const sentimentToText = (value, quantity) => {
      if (quantity === 0) {
        return "";
      }
      if (value < 25) {
        return "Very Negative";
      } else if (value < 50) {
        return "Negative";
      } else if (value < 75) {
        return "Neutral";
      } else if (value < 100) {
        return "Positive";
      } else if (value === 100) {
        return "Very Positive";
      } else {
        return "";
      }
    };

    const allMeetings = values.map((item) => item?.allMeetings).reduce(sum);
    const participation =
      quantityOfParticipation > 0
        ? Math.round(currentParticipationCount / quantityOfParticipation)
        : 0;
    let sentiment =
      quantityOfSentiment > 0
        ? Math.round(currentSentimentCount / quantityOfSentiment)
        : 0;
    let sentimentValue = sentimentToText(sentiment, quantityOfSentiment);

    // Previous Values Calculation
    const previousValues = days.slice(0, dateRange.value);

    let previousSentimentCount = 0;
    let previousQuantityOfSentiment = 0;
    previousValues.map((item) => {
      item.sentiment.map((sentiment) => (previousSentimentCount += sentiment));
      item.sentiment?.length > 0
        ? (previousQuantityOfSentiment += item.sentiment.length)
        : null;
    });

    let previousQuantityOfParticipation = 0;
    let previousParticipationCount = 0;
    previousValues.map((item) => {
      item.participation.map((item) => (previousParticipationCount += item));
      item.participation?.length > 0
        ? (previousQuantityOfParticipation += item.participation.length)
        : null;
    });
    const previousAllMeetings = previousValues
      .map((item) => item?.allMeetings)
      .reduce(sum);
    const previousParticipation =
      previousQuantityOfParticipation > 0
        ? Math.round(
            previousParticipationCount / previousQuantityOfParticipation
          )
        : 0;
    let previousSentiment =
      previousQuantityOfSentiment > 0
        ? Math.round(previousSentimentCount / previousQuantityOfSentiment)
        : 0;

    // isNaN fixes
    if (isNaN(previousSentiment)) {
      previousSentiment = 0;
    }

    if (isNaN(sentiment)) {
      sentiment = 0;
    }

    setTotalAvarages({
      allMeetings,
      participation: participation + "%",
      sentiment: sentimentValue,
      previousAllMeetingsRate: calculateDiffirence(
        allMeetings,
        previousAllMeetings,
        true
      ),
      previousParticipationRate: calculateDiffirence(
        participation,
        previousParticipation,
        true
      ),
      previousSentimentRate: calculateDiffirence(
        sentiment,
        previousSentiment,
        true
      ),
    });
  };

  const calculateAvarage = (array) => {
    // This function takes number arrays as parameters then calculate avarage of them.
    // Also function finds highest meeting count at given date range then rate it with calculated avarage value.
    if (!array || array.length === 0) {
      return 0;
    }

    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue;
    const total = array.reduce(reducer);
    let highestMeetingCount = 0;
    days.map((item) => {
      if (item.allMeetings > highestMeetingCount) {
        return (highestMeetingCount = item.allMeetings);
      }
      return false;
    });

    setYAxisHeight(highestMeetingCount * 1.2);
    return total / array.length / (100 / highestMeetingCount);
  };

  const calculateLabelValue = (value, isSentiment) => {
    // This function calculates label values.
    // Function takes values and multiples that value with highest meeting value
    const labelValue = Math.floor((value * 100) / (yAxisHeight * 0.83));
    if (!isSentiment) {
      if (isNaN(labelValue)) {
        return "0%";
      }
      return labelValue + "%";
    } else {
      if (labelValue === 0) {
        return "";
      } else if (labelValue < 25) {
        return "Very Negative";
      } else if (labelValue < 50) {
        return "Negative";
      } else if (labelValue < 75) {
        return "Neutral";
      } else if (labelValue < 100) {
        return "Positive";
      } else if (labelValue >= 100) {
        return "Very Positive";
      }
    }
  };

  const calculateDiffirence = (
    currentValue,
    previousValue,
    returnNumber = false
  ) => {
    // This function calculate diffirence by percentage for given 2 numbers
    let diffirence = Math.ceil(
      ((currentValue - previousValue) / previousValue) * 100
    );
    if (previousValue === 0) {
      diffirence = 100;
    }
    if (currentValue === 0 && previousValue === 0) {
      diffirence = 0;
    }
    if (returnNumber) {
      return diffirence;
    }
    if (diffirence > 0) {
      return `   ⬆ ${diffirence}%`;
    } else if (diffirence < 0) {
      return `   ⬇ ${diffirence}%`;
    } else {
      return `   ➡ ${diffirence}%`;
    }
  };

  const generateDataSet = async () => {
    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "MEMBER" &&
        sentimentSettings?.users === "all") ||
      (fireStoreuserObj.role === "MEMBER" &&
        sentimentSettings?.users === "userOnly") ||
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    if (!sentimentSettings) {
      isSentimentAvailableCheck = true;
    }
    // This function generates datasets for chart
    let newDataSet = [
      {
        id: 1,
        label: "My All Meetings",
        data: days
          .slice(dateRange.value, days.length)
          .map((item) => item.allMeetings),
        tension: 0.4,
        borderColor: "#2c73ff",
        backgroundColor: "#2c73ff",
      },
      {
        id: 2,
        label: "My participation rate",
        data: days
          .slice(dateRange.value, days.length)
          .map((item) => calculateAvarage(item.participation)),
        tension: 0.4,
        borderColor: "#EF5DA8",
        backgroundColor: "#EF5DA8",
      },
      {
        id: 3,
        label: "My Sentiment",
        data:
          isSentimentAvailableCheck &&
          days
            .slice(dateRange.value, days.length)
            .map((item) => calculateAvarage(item.sentiment)),
        tension: 0.4,
        borderColor: "#E4A400",
        backgroundColor: "#E4A400",
      },
    ];
    if (compare) {
      newDataSet.push(
        {
          id: 4,
          label: "My Sentiment Previous",
          data: days
            .slice(0, dateRange.value)
            .map((item) => calculateAvarage(item.sentiment)),
          tension: 0.4,
          borderColor: "#e2cf9e",
          backgroundColor: "#e2cf9e",
          borderDash: [10, 5],
        },
        {
          id: 5,
          label: "My Participation Previous",
          data: days
            .slice(0, dateRange.value)
            .map((item) => calculateAvarage(item.participation)),
          tension: 0.4,
          borderColor: "#edc9db",
          backgroundColor: "#edc9db",
          borderDash: [10, 5],
        },
        {
          id: 6,
          label: "My All Meetings Previous",
          data: days.slice(0, dateRange.value).map((item) => item.allMeetings),
          tension: 0.4,
          borderColor: "#9ec0ff",
          backgroundColor: "#9ec0ff",
          borderDash: [10, 5],
        }
      );
    }
    setDataset(newDataSet);
    setLoading(false);
    setIsParentDataShouldChange(false);
    setIsSentimentAvailable(isSentimentAvailableCheck);
  };

  const sentimentMeter = (sentiment) => {
    // This function turns sentiment values to numbers
    switch (sentiment) {
      case "NEGATIVE_2":
        return 0;
      case "NEGATIVE_1":
        return 25;
      case "MIXED":
        return 50;
      case "NEUTRAL":
        return 50;
      case "POSITIVE_1":
        return 75;
      case "POSITIVE_2":
        return 100;
      default:
        return 0;
    }
  };

  const transformData = () => {
    setLoading(true);
    // This function firstly calculate days as giving date range.
    const today = new Date();
    new Date(today.setDate(today.getDate() - dateRange.value * 2));
    const days = [];
    const months = [];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (let i = 0; i < dateRange.value * 2; i++) {
      let newDate = new Date(today.setDate(today.getDate() + 1));
      if (
        !months.includes((newDate.getMonth() + 1).toString()) ||
        i === dateRange.value
      ) {
        months.push((newDate.getMonth() + 1).toString());
        days.push({
          label: [newDate.getDate().toString(), monthNames[newDate.getMonth()]],
          key: dateFormatter(newDate),
          allMeetings: 0,
          participation: [],
          sentiment: [],
          meetingIds: [],
        });
      } else {
        days.push({
          label: newDate.getDate().toString(),
          key: dateFormatter(newDate),
          allMeetings: 0,
          participation: [],
          sentiment: [],
          meetingIds: [],
        });
      }
    }

    // For using server response data you should uncomment next line !
    const allMeetings = data;
    // Next line generates mock data for chart.
    // const allMeetings = mockData(dateRange.value).data.meetingAsAll;
    let values = [...days];
    allMeetings?.map((meeting) => {
      const meetingDate = new Date(Number(meeting.startTime + "000"));
      const dateKey = dateFormatter(meetingDate);
      const findValue = values.find((item) => item.key === dateKey);
      if (findValue) {
        findValue.meetingIds.push(meeting?.meetingId);
        if (meeting?.participation?.participation) {
          if (!meeting?.participation?.noShow) {
            findValue.participation.push(
              Number(meeting.participation.participation)
            );
          } else {
            findValue.participation.push(0);
          }
        }
        if (meeting?.sentiment?.emotion) {
          findValue.sentiment.push(sentimentMeter(meeting?.sentiment?.emotion));
        }
        findValue.allMeetings += 1;
        return true;
      }
      return false;
    });
    setDays(values);
  };

  useEffect(transformData, [isParentDataShouldChange, data]);
  useEffect(() => {
    generateDataSet();
    calculateTotalAvarage();
  }, [compare, days]);
  const chartJSData = {
    labels: days.slice(dateRange.value, days.length).map((item) => item.label),
    datasets: dataset,
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    events: ["click", "mousemove"],
    hover: {
      mode: "index",
      intersect: false,
    },
    onClick: function (event, element) {
      setMeetingIds(
        days[days.length - (dateRange.value - Number(element[0].index))]
          ?.meetingIds
      );
      setMeetingModal(true);
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          title: function (datasetIndex) {
            if (!compare) {
              const labels = days.slice(dateRange.value, days.length);
              return labels[datasetIndex[0].dataIndex].key;
            } else {
              const currentLabels = days.slice(dateRange.value, days.length);
              const previusLabels = days.slice(0, dateRange.value);
              return (
                previusLabels[datasetIndex[0].dataIndex]?.key +
                " vs. " +
                currentLabels[datasetIndex[0].dataIndex]?.key
              );
            }
          },
          label: function (context, index) {
            if (
              context.dataset.id === 1 ||
              context.dataset.id === 2 ||
              context.dataset.id === 3
            ) {
              var label = context.dataset.label || "";

              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null && context.dataset.id === 1) {
                label += context.parsed.y;
              }

              if (context.dataset.id === 2 || context.dataset.id === 3) {
                label += calculateLabelValue(
                  context.parsed.y,
                  context.dataset.id === 3
                )
                  ? calculateLabelValue(
                      context.parsed.y,
                      context.dataset.id === 3
                    )
                  : 0;
              }

              if (compare) {
                const previousDatas = days.slice(0, dateRange.value);
                if (context.dataset.id === 1) {
                  label += calculateDiffirence(
                    Number(context.parsed.y),
                    previousDatas[context.dataIndex].allMeetings
                  );
                } else if (context.dataset.id === 2) {
                  label += calculateDiffirence(
                    Number(context.parsed.y),
                    calculateAvarage(
                      previousDatas[context.dataIndex].participation
                    )
                  );
                } else if (context.dataset.id === 3) {
                  label += calculateDiffirence(
                    Number(context.parsed.y),
                    calculateAvarage(previousDatas[context.dataIndex].sentiment)
                  );
                }
              }

              return label;
            }
          },
        },
        position: "average",
        backgroundColor: "#edf3ff",
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: true,
        caretSize: 5,
      },
    },
    scales: {
      y: {
        max: yAxisHeight,
        position: "right",
        ticks: {
          callback: function (val) {
            return val % 5 === 0 ? Math.round(val) : "";
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          autoSkip: false,
          display: true,
          callback: function (val, index) {
            const labels = days.slice(dateRange.value, days.length);
            if (dateRange.value === 7) {
              return labels[val].label;
            } else if (
              dateRange.value === 30 &&
              (typeof labels[val].label === "object" ||
                val % 2 === 0 ||
                val === 0 ||
                val === labels.length - 1)
            ) {
              return labels[val].label;
            } else if (
              labels.length > 31 &&
              (typeof labels[val].label === "object" ||
                val % 7 === 0 ||
                val === 0 ||
                val === labels.length - 1)
            ) {
              return labels[val].label;
            }
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div className="chart">
      {meetingModal && (
        <ModalMeetingsTable
          open={meetingModal}
          onClose={() => {
            setMeetingModal(false);
            setMeetingIds([]);
          }}
          meetingIds={meetingIds}
        />
      )}
      <div className="chart-header">
        <h6>Sentiment overtime</h6>
        <SentimentLineChartDropdown
          compare={compare}
          setCompare={(compare) => setCompare(compare)}
          selected={dateRange}
          setDateRange={(dateRange) => {
            setDateRange(dateRange);
            if (dateRangeController(dateRange)) {
              setLoading(true);
            } else {
              setIsParentDataShouldChange(true);
            }
          }}
        />
      </div>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={300}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "1em .5em", height: "100%", minHeight: 450 }}
        >
          {isSentimentAvailable && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              sx={{ height: "100%" }}
            >
              {Object.values(sentimentIcons).map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </Box>
          )}
          <Line data={chartJSData} options={options} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div className="compare-chart-info">
          <div className="dot blue" />
          All Meetings
          <div className="avarage-container">
            (<span className="blue">{totalAvarages.allMeetings}</span>
            {compare && (
              <span
                className={
                  totalAvarages.previousAllMeetingsRate > 0
                    ? "green"
                    : totalAvarages.previousAllMeetingsRate < 0
                    ? "red"
                    : "gray"
                }
              >
                {totalAvarages.previousAllMeetingsRate > 0
                  ? "⬆ "
                  : totalAvarages.previousAllMeetingsRate < 0
                  ? "⬇ "
                  : "➡ "}
                {totalAvarages.previousAllMeetingsRate}%
              </span>
            )}
            )
          </div>
        </div>

        <div className="compare-chart-info">
          <div className="dot pink" />
          My Participation Rate
          <div className="avarage-container">
            (<span className="pink">Avg. {totalAvarages.participation}</span>
            {compare && (
              <span
                className={
                  totalAvarages.previousParticipationRate > 0
                    ? "green"
                    : totalAvarages.previousParticipationRate < 0
                    ? "red"
                    : "gray"
                }
              >
                {totalAvarages.previousParticipationRate > 0
                  ? "⬆ "
                  : totalAvarages.previousParticipationRate < 0
                  ? "⬇ "
                  : "➡ "}
                {totalAvarages.previousParticipationRate}%
              </span>
            )}
            )
          </div>
        </div>

        {isSentimentAvailable && (
          <div className="compare-chart-info">
            <div className="dot orange" />
            My Sentiment
            <div className="avarage-container">
              (<span className="orange">Avg. {totalAvarages.sentiment}</span>
              {compare && (
                <span
                  className={
                    totalAvarages.previousSentimentRate > 0
                      ? "green"
                      : totalAvarages.previousSentimentRate < 0
                      ? "red"
                      : "gray"
                  }
                >
                  {totalAvarages.previousSentimentRate > 0
                    ? "⬆ "
                    : totalAvarages.previousSentimentRate < 0
                    ? "⬇ "
                    : "➡ "}
                  {totalAvarages.previousSentimentRate}%
                </span>
              )}
              )
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default SentimentLineChart;
