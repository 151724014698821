import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import { Box, CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "5px",
    minWidth: 160,
    padding: 0,
  },
}));

// time slider
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Video({
  videoMarks,
  onPlayingDialog,
  playerState,
  setPlayerState,
  isVideoOptimized,
  setIsVideoOptimized,
  meetingId,
  loading,
  setLoading,
}) {
  const playerRef = useRef(null);
  const [marks, setMarks] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  const fetchVideo = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let vUrl = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/getVideoUrls?meetingId=` +
            meetingId,
          jwtToken
        );
        if (vUrl.status === "success") {
          //not needed to process videos from source recall api - Google Meet, Zoom, MS Teams
          // setIsVideoOptimized(vUrl?.optimizedVideo); just temporary fix by ronak
          setIsVideoOptimized(true);
          if (vUrl.urls.length) {
            let fUrl = vUrl.urls[0];
            setVideoUrl(fUrl[0]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error happen when fetching video url");
        setLoading(false);
      });
  };

  useEffect(fetchVideo, []);

  useEffect(
    () => onPlayingDialog(playerRef?.current?.currentTime),
    [playerRef?.current?.currentTime]
  );

  const reRunAnimation = () => {
    let doc = document.getElementById("entity-marks");
    if (doc?.classList) {
      if (doc.classList?.contains("slide")) {
        doc.classList.remove("slide");
        setTimeout(() => {
          doc.classList.add("slide");
        }, 150);
      } else {
        doc.classList.add("slide");
      }
    }
  };

  useEffect(() => {
    console.log(videoMarks, "videoMarks");
    if (!videoMarks || videoMarks?.length === 0 || !isVideoOptimized) {
      return;
    }
    setMarks(videoMarks);
    reRunAnimation();
    playerRef.current.currentTime = videoMarks[0]?.values[0]?.startSecond;
  }, [videoMarks]);

  return (
    <Box
      bgcolor="#fff"
      borderRadius="10px"
      width="100%"
      height="100%"
      minHeight={"400px"}
      style={{ boxShadow: "none !important" }}
    >
      {loading ? (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress style={{ color: "#2c73ff" }} />
        </Box>
      ) : !videoUrl || !videoUrl.length ? (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          No data found
        </Box>
      ) : (
        <div className="meeting-detail-video">
          <video
            controls
            ref={playerRef}
            onTimeUpdate={(e) => onPlayingDialog(e?.target?.currentTime)}
            style={{
              background: "black",
              borderRadius: !isVideoOptimized ? "10px 10px 0 0" : "10px",
              minHeight: 400,
              maxHeight: 400,
              position: "relative",
              width: "100%",
            }}
          >
            <source src={videoUrl} />
          </video>
          {!isVideoOptimized && (
            <div className="video-processing-container">
              <HtmlTooltip
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      padding: ".5em 1em",
                      color: "#707070",
                      borderRadius: "5px",
                    }}
                  >
                    <b style={{ fontWeight: "bold" }}>
                      Seekable video still processing
                    </b>
                    <span>
                      It takes some time to process your videos, in the meantime
                      we give the raw video immediately so you don't have to
                      wait. Your processed and seekable video will be here as
                      soon as possible.
                    </span>
                  </div>
                }
              >
                <InfoIcon sx={{ color: "#2c73ff", mr: 1 }} />
              </HtmlTooltip>
              Seekable video still processing
            </div>
          )}

          <Box
            display="flex"
            flexDirection="column"
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
              padding: "0px 16px 5px",
            }}
          >
            <Box sx={{ padding: "0 10px" }}>
              {marks?.length > 0 && (
                <div className="video-entity-marks slide" id={"entity-marks"}>
                  {marks[0]?.values?.map((markValue, index) => (
                    <HtmlTooltip
                      key={index}
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            padding: ".5em 1em",
                            color: "#707070",
                            border: `1px solid ${
                              marks[0]?.color ? marks[0]?.color : "#000"
                            }`,
                            borderRadius: "5px",
                          }}
                        >
                          <b>{marks[0].label}</b>
                          {marks[0]?.sentiment && (
                            <span>{marks[0].sentiment}</span>
                          )}
                        </div>
                      }
                    >
                      <div
                        className="entity-mark-needle"
                        style={{
                          background: marks[0]?.color || "#000",
                          left: `${markValue.start}%`,
                        }}
                      >
                        <div
                          className="entity-mark-dot"
                          style={{
                            background: marks[0]?.color || "#000",
                            border:
                              !marks[0]?.color || marks[0]?.color === "#000"
                                ? "1px solid white"
                                : "none",
                          }}
                          onClick={() => {
                            if (!isVideoOptimized) {
                              return;
                            }
                            playerRef.current.currentTime =
                              markValue?.startSecond;
                          }}
                        />
                      </div>
                    </HtmlTooltip>
                  ))}
                </div>
              )}
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
}
