import * as React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Page from "../Page";
import AddTask from "./AddTask";
import ViewTask from "./ViewTask";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { EditorState, convertFromRaw } from "draft-js";

import ElasticContactAutocomplete from "../Tasks/ElasticContactAutocomplete";
import ElasticAccountAutocomplete from "../Tasks/ElasticAccountAutocomplete";
import ElasticDealsAutocomplete from "../Tasks/ElasticDealsAutocomplete";
import ElasticLeadAutocomplete from "../Tasks/ElasticLeadAutocomplete";

import useAuth from "./../../hooks/useAuth";

import {
  GetTask,
  GetTasks,
  updateTask,
  deleteTask,
  getElasticToken,
} from "./../../firebase/firestore.js";

import TasksBoard from "./TasksBoard";
import { Avatar, CircularProgress } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: ".3em !important",
  },
}));

export default function Tasks() {
  const { fireStoreuserObj } = useAuth();

  let [addTaskDialogStatus, setAddTaskDialogStatus] = useState(false);
  let [taskDefaultStatus, setTaskDefaultStatus] = useState("not_started");
  let [viewTaskDialogStatus, setViewTaskDialogStatus] = useState(false);
  let [openTaskId, setOpenTaskId] = useState(null);
  let [deleteTaskId, setDeleteTaskId] = useState(null);
  let [priorityFilter, setPriorityFilter] = useState([]);

  let [relatedToEntityFilter, setRelatedToEntityFilter] = useState("contact");
  let [relatedToFilter, setRelatedToFilter] = useState(null);

  const [elasticToken, setElasticToken] = useState(null);

  let [searchQuery, setSearchQuery] = useState("");
  let [tasks, setTasks] = useState(false);
  let [selectedMembers, setSelectedMembers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersLoading, setTeamMembersLoading] = useState(true);

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  const getTeamMembers = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
          jwtToken
        );
        if (result.status === "success") {
          let teamMembers = result.data.members;
          let allTeamMembers = [];
          teamMembers?.map((member) => {
            allTeamMembers.push({
              name: member?.name,
              url: "",
              avatar: member?.avatar,
              id: member?.id,
            });
          });
          setTeamMembers(allTeamMembers);
          setTeamMembersLoading(false);
        }
      });
  };

  useEffect(getTeamMembers, []);

  useEffect(() => {
    const loadTasks = async () => {
      let tasks = await GetTasks();
      setTasks(tasks);
    };
    loadTasks();
  }, []);
  const handleCloseAddTaskDialog = () => {
    setAddTaskDialogStatus(false);
  };

  const handleCloseViewTaskDialog = async () => {
    if (openTaskId) {
      let modifiedTask = await GetTask(openTaskId);
      if (modifiedTask.success) {
        let newTaskList = tasks.map((task) => {
          if (task.id == openTaskId) {
            return modifiedTask.task;
          }
          return task;
        });

        setTasks(newTaskList);
      }
    }
    setViewTaskDialogStatus(false);
  };

  const handleOpenTaskClick = (taskId) => {
    setOpenTaskId(taskId);
    setViewTaskDialogStatus(true);
  };

  const handleDeleteTaskClick = (taskId) => {
    setDeleteTaskId(taskId);
  };
  const handleDeleteTask = async () => {
    let deletedTask = await deleteTask(deleteTaskId);
    if (deletedTask) {
      let newTaskList = tasks.filter((task) => task.id != deleteTaskId);
      setTasks(newTaskList);
    }
    setDeleteTaskId(null);
  };

  const addTaskInList = (task) => {
    let tempTasks = [...tasks];
    tempTasks.push(task);
    setTasks(tempTasks);
  };

  const handleDragDrop = async (result) => {
    if (result.source.droppableId == result.destination.droppableId) {
      let order = 0;
      let newP = tasks.map((t, i) => {
        if (t.status != result.destination.droppableId) {
          return t;
        }

        if (t.id != result.draggableId) {
          if (order == result.destination.index) {
            order++;
          }
          t.order = order;
          updateTask(t.id, { order: order });
          order++;
        }

        if (t.id == result.draggableId) {
          t.order = result.destination.index;
          updateTask(t.id, { order: result.destination.index });
          // order++;
        }

        return t;
      });
      newP.sort((a, b) => a.order - b.order);
      setTasks(newP);
    } else {
      let order = 0;
      let newP = tasks.map((t, i) => {
        if (
          t.status != result.destination.droppableId &&
          t.id != result.draggableId
        ) {
          return t;
        }

        if (t.id != result.draggableId) {
          if (order == result.destination.index) {
            order++;
          }
          t.order = order;
          updateTask(t.id, { order: order });
          order++;
        }

        if (t.id == result.draggableId) {
          t.order = result.destination.index;
          t.status = result.destination.droppableId;
          updateTask(t.id, {
            order: result.destination.index,
            status: result.destination.droppableId,
          });
          // order++;
        }

        return t;
      });
      newP.sort((a, b) => a.order - b.order);
      setTasks(newP);
    }
  };

  const getFilteredTasks = () => {
    let filteredTasks = [...tasks];
    if (searchQuery.length) {
      filteredTasks = tasks.filter((task) =>
        task?.title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        task?.description
          ? typeof task.description === "string"
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(task?.description))
              )
                ?.getCurrentContent()
                ?.getPlainText("\u0001")
                ?.toLowerCase()
                ?.includes(searchQuery.toLowerCase())
            : EditorState.createWithContent(convertFromRaw(task?.description))
                ?.getCurrentContent()
                ?.getPlainText("\u0001")
                ?.toLowerCase()
                ?.includes(searchQuery.toLowerCase())
          : false
      );
    }

    if (priorityFilter.length) {
      filteredTasks = filteredTasks.filter((task) =>
        priorityFilter.includes(task.priority)
      );
    }

    if (relatedToFilter) {
      filteredTasks = filteredTasks.filter((task) => {
        if (!task.relatedTo) return false;

        if (relatedToFilter.type === "contact") {
          if (task.relatedTo.type !== "contact") {
            return false;
          } else {
            return (
              task.relatedTo.type === relatedToFilter.type &&
              task.relatedTo.contactId === relatedToFilter.contactId
            );
          }
        }

        if (relatedToFilter.type === "account") {
          if (task.relatedTo.type !== "account") {
            return false;
          } else {
            return (
              task.relatedTo.type === relatedToFilter.type &&
              task.relatedTo.accountId === relatedToFilter.accountId
            );
          }
        }

        if (relatedToFilter.type === "lead") {
          if (task.relatedTo.type !== "lead") {
            return false;
          } else {
            return (
              task.relatedTo.type === relatedToFilter.type &&
              task.relatedTo.leadId === relatedToFilter.leadId
            );
          }
        }

        if (relatedToFilter.type === "deal") {
          if (task.relatedTo.type !== "deal") {
            return false;
          } else {
            return (
              task.relatedTo.type === relatedToFilter.type &&
              task.relatedTo.dealId === relatedToFilter.dealId
            );
          }
        }
      });
    }

    if (selectedMembers.length) {
      let selectedMemberIds = getSelectedMemberIds();
      return filteredTasks.filter((task) => {
        return (
          selectedMemberIds.includes(task.assignedTo) ||
          task?.taskMembers?.filter((taskMember) =>
            selectedMemberIds.includes(taskMember)
          ).length > 0
        );
      });
    } else {
      return filteredTasks;
    }
  };

  const handlePriorityFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPriorityFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const addSelectedMember = (newMember) => {
    setSelectedMembers((CurrentSelectedMembers) => [
      ...CurrentSelectedMembers,
      newMember,
    ]);
  };

  const removeSelectedMember = (member) => {
    setSelectedMembers((CurrentSelectedMembers) =>
      CurrentSelectedMembers.filter((m) => m !== member)
    );
  };

  const getSelectedMemberIds = () => {
    return selectedMembers.map((m) => m);
  };

  const handleAddTask = (title) => {
    let taskStatus = "not_started";
    if (title == "Deferred") {
      taskStatus = "deferred";
    } else if (title == "In-Progress") {
      taskStatus = "inprogress";
    } else if (title == "Completed") {
      taskStatus = "completed";
    } else if (title == "Waiting for input") {
      taskStatus = "waiting_for_input";
    }

    setTaskDefaultStatus(taskStatus);
    setAddTaskDialogStatus(true);
  };

  return (
    <Page>
      <Container maxWidth={"xl"}>
        <Dialog
          open={deleteTaskId ? true : false}
          onClose={() => setDeleteTaskId(null)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this task?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="modal-button white"
              onClick={() => setDeleteTaskId(null)}
            >
              Cancel
            </button>
            <button className="modal-button red ml" onClick={handleDeleteTask}>
              Delete
            </button>
          </DialogActions>
        </Dialog>

        {addTaskDialogStatus && (
          <AddTask
            addTaskDialogStatus={addTaskDialogStatus}
            handleCloseAddTaskDialog={handleCloseAddTaskDialog}
            addTaskInList={addTaskInList}
            tasks={tasks}
            defaultStatus={taskDefaultStatus}
          />
        )}
        {viewTaskDialogStatus && (
          <ViewTask
            viewTaskDialogStatus={viewTaskDialogStatus}
            handleCloseViewTaskDialog={handleCloseViewTaskDialog}
            taskId={openTaskId}
          />
        )}
        <Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            // width={700}
          >
            <TextField
              size="small"
              style={{
                width: "200px",
              }}
              label="Search tasks"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={"tasks-title-input"}
              sx={{
                "& > label": { top: "0 !important" },
              }}
            />

            <div className="task-filter-members">
              {teamMembersLoading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: "0 !important" }}
                >
                  <CircularProgress style={{ color: "#2c73ff" }} size="20px" />
                </Box>
              ) : (
                teamMembers?.map((member, index) => (
                  <LightTooltip title={member?.name} key={index}>
                    <Avatar
                      alt={member?.name}
                      src={member?.avatar}
                      sx={{
                        width: 35,
                        height: 35,
                        border: selectedMembers?.find(
                          (item) => item === member?.id
                        )
                          ? "3px solid #2c73ff"
                          : "",
                      }}
                      onClick={() => {
                        let selectedStatus = selectedMembers?.find(
                          (item) => item === member?.id
                        );
                        if (selectedStatus) {
                          removeSelectedMember(member?.id);
                        } else {
                          addSelectedMember(member?.id);
                        }
                      }}
                    />
                  </LightTooltip>
                ))
              )}
            </div>

            <FormControl size="small" sx={{ width: 150 }}>
              <InputLabel id="multiple-priority-filter-label">
                Select Priority
              </InputLabel>

              <Select
                labelId="multiple-priority-filter-label"
                // size="small"
                // label="Priority"
                displayEmpty
                multiple
                value={priorityFilter}
                onChange={handlePriorityFilterChange}
                input={<OutlinedInput label="Select Priority" />}
                className="task-select"
              >
                <MenuItem value="low" className="task-select-item">
                  Low
                </MenuItem>
                <MenuItem value="medium" className="task-select-item">
                  Medium
                </MenuItem>
                <MenuItem value="high" className="task-select-item">
                  High
                </MenuItem>
                <MenuItem value="urgent" className="task-select-item">
                  Urgent
                </MenuItem>
              </Select>
            </FormControl>

            <Box width={2 / 5} marginLeft={1}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <p style={{ marginRight: "10px" }}>Related To: </p>
                <FormControl size="small" style={{ minWidth: "150px" }}>
                  <InputLabel
                    id="entity-select-label"
                    // className="task-select-label"
                  >
                    Entity
                  </InputLabel>
                  <Select
                    labelId="entity-select-label"
                    id="entity-select"
                    value={relatedToEntityFilter}
                    label="Entity"
                    onChange={(e) => {
                      setRelatedToFilter(null);
                      setRelatedToEntityFilter(e.target.value);
                    }}
                    className="deal-select"
                  >
                    {relatedToEntityFilter && false && (
                      <MenuItem value="" className="deal-select-item">
                        Clear filter
                      </MenuItem>
                    )}

                    <MenuItem value="lead" className="deal-select-item">
                      Lead
                    </MenuItem>
                    <MenuItem value="contact" className="deal-select-item">
                      Contact
                    </MenuItem>
                    <MenuItem value="account" className="deal-select-item">
                      Account
                    </MenuItem>
                    <MenuItem value="deal" className="deal-select-item">
                      Deal
                    </MenuItem>
                  </Select>
                </FormControl>

                <Box style={{ marginLeft: 5 }}>
                  {elasticToken &&
                    false &&
                    (relatedToEntityFilter === "contact" ? (
                      <ElasticContactAutocomplete
                        setRelatedTo={setRelatedToFilter}
                        apiKey={elasticToken}
                        className={"elastic-view-deal-input"}
                      />
                    ) : (
                      relatedToEntityFilter === "account" && (
                        <ElasticAccountAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )
                    ))}

                  {elasticToken && (
                    <>
                      {relatedToEntityFilter === "lead" && (
                        <ElasticLeadAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )}

                      {relatedToEntityFilter === "contact" && (
                        <ElasticContactAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )}

                      {relatedToEntityFilter === "account" && (
                        <ElasticAccountAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )}

                      {relatedToEntityFilter === "deal" && (
                        <ElasticDealsAutocomplete
                          setRelatedTo={setRelatedToFilter}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {tasks && (
            <TasksBoard
              tasks={getFilteredTasks()}
              handleDragDrop={handleDragDrop}
              addTask={handleAddTask}
              handleOpenTaskClick={handleOpenTaskClick}
              handleDeleteTaskClick={handleDeleteTaskClick}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
}
