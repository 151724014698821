import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "../../assets/css/pastMeeting.scss";
import HistoryIcon from "@material-ui/icons/History";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import ShareMeeting from "./ShareMeeting";
import { Box } from "@material-ui/core";
import envelopeIcon from "../../assets/images/envelopeForward.png";
import {
  getTranscriptByMeetingId,
  getSummary,
  getMeetingByMeetingId,
  meetingsRealtimeUpdatesStore,
  getUserBillingStatus,
  getElasticToken,
} from "../../firebase/firestore";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import Page from "../Page";
import Attendance from "./attendance";
import ShowChangeHistory from "./ShowChangeHistory";
import ShowActionChangeHistory from "./ShowActionChangeHistory";
import firebase from "../../firebase/firebase";
import { postData } from "../../utils";
import { useHistory } from "react-router-dom";
import MeetingDetailSidemenu from "./MeetingDetailSidemenu";
import MeetingDetailSummary from "./MeetingDetailSummary";
import MeetingDetailActionPlans from "./MeetingDetailActionPlans";
import MeetingDetailAnalytics from "./MeetingDetailAnalytics";
import MeetingDetailSubHeader from "./MeetingDetailSubHeader";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import HybridMeetingPrompt from "./HybridMeetingPrompt";
import FollowUpMail from "./FollowUpMail";
import { Post } from "../../utils/axiosUtils";
import useAuth from "../../hooks/useAuth";
import { elasticSearchCRMSuggestions } from "../../utils/elasticSearch";
import PastMeetingsChangeLog from "./PastMeetingsChangeLog";
import VideoAndEntities from "./VideoAndEntities";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: "20px",
  },
}));

function CenteredGrid({ ...props }) {
  const { fireStoreuserObj } = useAuth();
  const { isCollapse } = useCollapseDrawer();
  const classes = useStyles();
  const isMobile = window.innerWidth < 600;
  const [sideMenu, setSideMenu] = useState(!isMobile);
  let { id } = useParams();
  let userId = firebase.auth().currentUser.uid;
  const [summaryData, setSummaryData] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [transcriptionData, setTranscriptionData] = useState(null);
  const [transcriptRead, setTranscriptRead] = useState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [meetingDataLoading, setMeetingDataLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [fulldate, setfullDate] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [changeLogDrawer, setChangeLogDrawer] = useState(false);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false);
  const [showActionHistoryPopup, setShowActionHistoryPopup] = useState(false);
  const [historyNumber, setHistoryNumber] = useState(false);
  const [actionHistoryNumber, setActionHistoryNumber] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [isPaidUser, setIsPaidUser] = useState(true);
  const [validUser, setValidUser] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [meetingDuration, setMeetingDuration] = useState(0);
  const [elasticToken, setElasticToken] = useState(null);
  const [activeDialog, setActiveDialog] = useState("");
  const [activeEntity, setActiveEntity] = useState({ entity: "", color: "" });
  const [hybridPromptOpen, setHybridPromptOpen] = useState(false);
  const [followUpMailPopup, setFollowUpMailPopup] = useState(false);
  const [hybridLoading, setHybridLoading] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);
  const history = useHistory();

  const highlightUnnamedField = (uid) => {
    let doc = document.getElementById(uid);
    if (doc && doc?.classList && doc?.offsetTop) {
      let container = document.getElementById("dialog-container");
      container.scrollTo({
        top: doc.offsetTop - 50,
        left: 0,
        behavior: "smooth",
      });
      doc.classList.add("highlight-dialog");
      setTimeout(() => {
        if (doc && doc?.classList) {
          doc.classList.remove("highlight-dialog");
        }
      }, 1600);
    }
  };

  // Generate summary manually
  const handleGenerateSummary = () => {
    let findUnnamedDialogs = transcriptionData.filter((item) => !item.name);
    if (findUnnamedDialogs?.length > 0) {
      highlightUnnamedField(findUnnamedDialogs[0].uid);
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Please, select a participant for unnamed fields.",
      });
      return;
    }
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        setShowLoader(true);
        props.showSnackbar({
          show: true,
          severity: "success",
          message: "We are processing this meeting, please check back soon.",
        });
        setIsHybrid(false);
        let genSummary = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/generateZoomAI`,
          jwtToken,
          { meetingId: id }
        );
        setSummaryLoading(true);
        setShowLoader(false);
        if (genSummary.status === "success") {
          if (isHybrid) {
            setIsHybrid(false);
          }

          let getSummaryOn = await getSummary(id);
          setSummaryData(getSummaryOn);
          setSummaryLoading(false);

          let meetingData = await getMeetingByMeetingId(id);
          setMeetingData(meetingData);
          let newParticipants = [];
          if (meetingData?.participants?.length > 0) {
            let elasticTokenValue = await getElasticToken();
            setElasticToken(elasticTokenValue);
            let participantIds = await elasticSearchCRMSuggestions(
              meetingData?.participantNames.filter(
                (item) => item !== fireStoreuserObj.displayName
              ),
              ["leads", "contacts"],
              false,
              elasticTokenValue
            );
            meetingData.participants.map((participant) => {
              let searchForId = participantIds?.find(
                (item) => item.fullName === participant.name
              );
              newParticipants.push({
                name: participant.name,
                email: participant.email,
                isAssigned: participant?.isAssigned
                  ? participant.isAssigned
                  : false,
                avatar: participant?.avatar ? participant.avatar : "",
                isSelected: participant?.isSelected !== false,
                id: searchForId?.id ? searchForId.id : "",
                type: searchForId?.type ? searchForId.type : "",
              });
            });
            setParticipants(newParticipants);
          }
        } else {
          // error handling
          if (meetingData?.channelType === "GOOGLE_MEET") {
            setIsHybrid(true);
          }
          props.showSnackbar({
            show: true,
            severity: "error",
            message: genSummary?.message,
          });
        }
      });
  };

  const getMeetingData = async () => {
    try {
      // Meeting data
      setMeetingDataLoading(true);
      let meetingData;
      if (history?.location?.state?.meetingData && false) {
        meetingData = history.location.state.meetingData;
      } else {
        meetingData = await getMeetingByMeetingId(id);
      }
      if (meetingData) {
        let fulldate = moment(
          new Date(meetingData?.createdAt?.seconds * 1000)
        ).format("lll");
        let timeZone = moment().tz(moment.tz.guess()).format("z");
        setfullDate(fulldate);
        setTimeZone(timeZone);
        setMeetingData(meetingData);
        setValidUser(true);
        setMeetingDuration(
          meetingData?.botJoinedAt?._seconds
            ? meetingData?.endedAt?.seconds - meetingData?.botJoinedAt?._seconds
            : meetingData?.endedAt?.seconds - meetingData?.createdAt?.seconds
        );
        setIsHybrid(
          meetingData?.channelHybrid ? meetingData.channelHybrid : false
        );
      } else {
        throw "meeting data not found";
      }

      // User paid status
      let userPaidStatus = await getUserBillingStatus();
      if (!userPaidStatus?.paidPlan) {
        setIsPaidUser(false);
      }

      // get participant ids from elastic search
      let newParticipants = [];
      if (meetingData?.participants?.length > 0) {
        let elasticTokenValue = await getElasticToken();
        setElasticToken(elasticTokenValue);
        let participantIds = await elasticSearchCRMSuggestions(
          meetingData?.participantNames.filter(
            (item) => item !== fireStoreuserObj.displayName
          ),
          ["leads", "contacts"],
          false,
          elasticTokenValue
        );
        if (!participantIds) {
          participantIds = [];
        }
        meetingData.participants.map((participant) => {
          let searchForId = participantIds?.find(
            (item) => item.fullName === participant.name
          );
          newParticipants.push({
            name: participant.name,
            email: participant.email,
            isAssigned: participant?.isAssigned
              ? participant.isAssigned
              : false,
            avatar: participant?.avatar ? participant.avatar : "",
            isSelected: participant?.isSelected !== false,
            id: searchForId?.id ? searchForId.id : "",
            type: searchForId?.type ? searchForId.type : "",
            sentiment: participant?.sentiment?.emotion,
            participation: participant?.participation?.noShow
              ? "no show"
              : participant?.participation?.participation
              ? participant?.participation?.participation + "%"
              : "",
            lateMinutes: participant?.lateMinutes
              ? Number(participant.lateMinutes)
              : null,
          });
        });
        setParticipants(newParticipants);
      }
      setMeetingDataLoading(false);

      // Summaries and action plans
      let summaryData = await getSummary(id);
      setSummaryData(summaryData);
      setSummaryLoading(false);

      // set transcriptions
      setTranscriptRead(
        meetingData?.transcriptRead ? meetingData.transcriptRead : false
      );
      let transcriptionAll = [];
      let isAssigned = "";
      let transcriptionData = await getTranscriptByMeetingId(id);
      transcriptionData.forEach((doc) => {
        let Astatus = meetingData.participants.filter(
          (v) => v.name == doc.name
        );

        Astatus.length
          ? (isAssigned = Astatus[0].isAssigned)
          : (isAssigned = false);

        let mDuration = meetingData?.botJoinedAt?._seconds
          ? doc.timestamp.seconds - meetingData?.botJoinedAt?._seconds
          : doc.timestamp.seconds - meetingData?.createdAt?.seconds;
        if (doc.timestamp.seconds < 10000) {
          // On promise meetings providing exact second
          mDuration = doc.timestamp.seconds;
        }

        let date = new Date(null);
        date.setSeconds(mDuration); // specify value for SECONDS here
        let time = date.toISOString().substr(11, 8);
        let findAvatar = meetingData?.participants?.find(
          (item) => item.name === doc.speaker
        );

        transcriptionAll.push({
          text: doc.text,
          speaker: doc.speaker,
          name: doc.name,
          isAssigned: isAssigned,
          timestamp: doc.timestamp,
          uid: doc.id,
          duration: mDuration,
          renderTime: time,
          avatar: findAvatar ? findAvatar.avatar : "",
        });
      });
      setTranscriptionData(transcriptionAll);
      // For hybrid meetings
      setHybridLoading(false);
      setHybridPromptOpen(false);
    } catch (e) {
      console.log(e);
      setValidUser(false);
    }
  };

  useEffect(() => {
    let myObservers = {
      initializedMeetingsRealtimeUpdates: false,
      refresh: function (type) {
        getMeetingData().catch((err) =>
          console.log(err, "error happen when refreshing meeting data")
        );
      },
    };
    let unsubscribeMeetingsRealtimeUpdatesObserver;
    unsubscribeMeetingsRealtimeUpdatesObserver = meetingsRealtimeUpdatesStore
      .doc(id)
      .onSnapshot((doc) => {
        if (myObservers.initializedMeetingsRealtimeUpdates) {
          myObservers.refresh();
        } else {
          myObservers.initializedMeetingsRealtimeUpdates = true;
        }
      });
    if (typeof unsubscribeMeetingsRealtimeUpdatesObserver == "function") {
      return function unsubscribe() {
        unsubscribeMeetingsRealtimeUpdatesObserver();
      };
    }
  }, []);

  useEffect(() => {
    getMeetingData().catch((err) => console.log(err));
  }, []);

  const handleChangeHybridMeeting = () => {
    setHybridLoading(true);
    let postData = {
      meetingId: id,
    };
    Post("meeting/hybrid/convert", postData)
      .then(() => {
        setIsHybrid(true);
        setSummaryData(false);
        setTranscriptionData(null);
        setRefreshData(refreshData + 1);
      })
      .catch((err) => {
        setHybridLoading(false);
        console.log(err, "error happen when changing meeting as hybrid");
      });
  };

  const handleGetGPT3Content = async () => {
    setFollowUpMailPopup(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <Page
        style={
          !isMobile
            ? {
                paddingTop: "24px",
                width:
                  isCollapse && sideMenu
                    ? "calc(100% - 30vw - 90px)"
                    : !isCollapse && sideMenu
                    ? "calc(100% - 30vw)"
                    : isCollapse && !sideMenu
                    ? "calc(100% - 131px)"
                    : "calc(100% - 41px)",
              }
            : {
                width: "calc(100% - 41px)",
              }
        }
      >
        <Container maxWidth={"xxl"}>
          <div className={classes.container}>
            {showHistoryPopup && (
              <ShowChangeHistory
                summaryData={summaryData}
                setSummaryData={setSummaryData}
                historyNo={historyNumber}
                meetingId={id}
                open={showHistoryPopup}
                onClose={setShowHistoryPopup}
              />
            )}
            {showActionHistoryPopup && (
              <ShowActionChangeHistory
                summaryData={summaryData}
                setSummaryData={setSummaryData}
                historyNo={actionHistoryNumber}
                meetingId={id}
                open={showActionHistoryPopup}
                onClose={setShowActionHistoryPopup}
              />
            )}
            {hybridPromptOpen && (
              <HybridMeetingPrompt
                handleClose={() => setHybridPromptOpen(false)}
                handleChangeHybrid={handleChangeHybridMeeting}
                hybridLoading={hybridLoading}
              />
            )}
            {followUpMailPopup && (
              <FollowUpMail
                handleClose={() => setFollowUpMailPopup(false)}
                meetingId={id}
                meetingTitle={meetingData?.title ? meetingData.title : ""}
                participants={participants}
                attendeeEmails={
                  meetingData?.attendeeEmails?.length
                    ? meetingData.attendeeEmails
                    : []
                }
              />
            )}
            <PastMeetingsChangeLog
              drawerStatus={changeLogDrawer}
              handleDrawerClose={() => setChangeLogDrawer(false)}
              meetingId={id}
              setHistoryNumber={setHistoryNumber}
              setShowHistoryPopup={setShowHistoryPopup}
              setActionHistoryNumber={setActionHistoryNumber}
              setShowActionHistoryPopup={setShowActionHistoryPopup}
            />

            <Grid container item xs={12} md={12} className="backtolist-block">
              <Box className={"meeting-detail-top-button-container"}>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIcon />
                  <span style={{ marginLeft: "10px" }}>Back</span>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Box
                    display={"flex"}
                    alignContent="center"
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                    mr={1}
                    title="Send follow up mail"
                    onClick={handleGetGPT3Content}
                  >
                    <img
                      src={envelopeIcon}
                      alt="envelope"
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: ".5em",
                        cursor: "pointer",
                      }}
                    />
                    AI Follow Up Email
                  </Box>
                  {meetingData?.channelType === "GOOGLE_MEET" && !isHybrid && (
                    <Box
                      display="flex"
                      alignItems="center"
                      onClick={() => setHybridPromptOpen(true)}
                      mr={1}
                    >
                      <InterpreterModeIcon
                        sx={{
                          color: "#212B36",
                          fontSize: "16px",
                          cursor: "pointer",
                          marginRight: ".5em",
                        }}
                      />
                      Hybrid Meeting
                    </Box>
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() => setChangeLogDrawer(true)}
                    mr={1}
                  >
                    <HistoryIcon
                      sx={{
                        color: "#212B36",
                        fontSize: "16px",
                        cursor: "pointer",
                        marginRight: ".5em",
                      }}
                    />
                    Change History
                  </Box>
                  <ShareMeeting
                    meetingId={id}
                    shareModal={shareModal}
                    setShareModal={setShareModal}
                  />
                </Box>
              </Box>
            </Grid>
            {validUser ? (
              <Box>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} mb={3}>
                      <MeetingDetailSubHeader
                        fulldate={fulldate}
                        timeZone={timeZone}
                        meetingData={meetingData}
                        meetingId={id}
                        meetingDuration={meetingDuration}
                        showSnackbar={props.showSnackbar}
                      />
                    </Grid>
                  </Grid>
                  {isPaidUser && (
                    <Grid container spacing={3} mb={3}>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <MeetingDetailSummary
                          meetingId={id}
                          handleGenerateSummary={handleGenerateSummary}
                          showSnackbar={props.showSnackbar}
                          channelType={meetingData?.channelType}
                          setHistoryNumber={setHistoryNumber}
                          setShowHistoryPopup={setShowHistoryPopup}
                          showHistoryPopup={showHistoryPopup}
                          showLoader={showLoader}
                          summaryData={summaryData}
                          loading={summaryLoading}
                          isHybrid={isHybrid}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={6}
                        minHeight={"350px"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <MeetingDetailActionPlans
                          meetingId={id}
                          userName={fireStoreuserObj.displayName}
                          meetingTitle={
                            meetingData?.title ? meetingData.title : ""
                          }
                          showSnackbar={props.showSnackbar}
                          setActionHistoryNumber={setActionHistoryNumber}
                          setShowActionHistoryPopup={setShowActionHistoryPopup}
                          summaryData={summaryData}
                          participants={participants}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={3} className="mb-30">
                    <VideoAndEntities
                      meetingId={id}
                      setActiveDialog={setActiveDialog}
                      transcriptionData={transcriptionData}
                      meetingData={meetingData}
                      meetingDuration={meetingDuration}
                      setActiveEntity={setActiveEntity}
                      elasticToken={elasticToken}
                    />
                    <Grid item xs={12} md={sideMenu ? 12 : 8} xl={8}>
                      <MeetingDetailAnalytics
                        isPaidUser={isPaidUser}
                        participants={participants}
                        meetingDataLoading={meetingDataLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={sideMenu ? 12 : 4} xl={4}>
                      <Attendance meetingId={id} />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            ) : (
              <Box>
                <p style={{ textAlign: "center", padding: "200px 0" }}>
                  This Meeting is Private.
                </p>
              </Box>
            )}
          </div>
        </Container>
      </Page>
      <MeetingDetailSidemenu
        meetingId={id}
        menuExpand={sideMenu}
        setMenuExpand={setSideMenu}
        meetingType={meetingData?.channelType}
        transcriptRead={transcriptRead}
        setTranscriptRead={setTranscriptRead}
        showSnackbar={props.showSnackbar}
        participants={participants}
        transcriptionData={transcriptionData}
        setTranscriptionData={setTranscriptionData}
        showChangeLog={() => setChangeLogDrawer(true)}
        openShareModal={() => setShareModal(true)}
        activeDialog={activeDialog}
        activeEntity={activeEntity}
        isHybrid={isHybrid}
        openForwardEmailModal={() => setFollowUpMailPopup(true)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CenteredGrid);
