export const DateRangeOptions = [
    {
        value: 7,
        label: "Last 7 Days"
    },
    {
        value: 30,
        label: "Last 30 Days"
    },
    {
        value: 90,
        label: "Last 90 Days"
    }
];
