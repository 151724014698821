import React, { useEffect, useState } from "react";
import { Grid, TextField, Box, AppBar, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { storeBusinessTopics } from "../../firebase/firestore";
import firebase from "../../firebase/firebase";
import FormLabel from "@material-ui/core/FormLabel";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pl: {
    paddingLeft: "20px",
  },
  bg: {
    backgroundColor: "unset",
    boxShadow: "unset",
    fontWeight: "500",
  },
  mb20: {
    paddingTop: "20px",
  },
  radioColor: {
    color: "#2C73FF !important",
  },
  lableFont: {
    color: "#000",
    fontWeight: "600",
  },
  priceTxt: {
    color: "#2C73FF",
    fontSize: "22px",
  },
}));

function BusinessTopics({ ...props }) {
  const [selectedColor, setSelectedColor] = useState("");
  const [topicName, setTopicName] = useState("");
  const [selectedTopics, setselectedTopics] = useState([]);

  useEffect(() => {
    // await props.fetchBusinessTopicsRequest();
  }, []);

  const handleSelectedTopics = async (value) => {
    let res = await storeBusinessTopics(value);
    console.log("resres", res);
    if (res.success == true) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const classes = useStyles();

  const doBusinessTopicDelete = (index, topic) => {
    props.deleteBusinessTopicRequest(index);
  };

  const addTopic = () => {
    if (topicName.trim() === "") {
    } else {
      let color =
        preDefinedColors[Math.floor(Math.random() * preDefinedColors.length)];
      props.addNewBusinessTopicRequest({
        topicName: topicName.trim(),
        color: color,
      });
      // setSelectedColor("");
      setTopicName("");
    }
  };

  let topicLabelsCheck = 0;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bg}></AppBar>

      {/* Competitors preference setting */}
      <Grid container style={{ marginTop: "20px" }} className={classes.mb20}>
        <Grid item xs={5}>
          <FormLabel component="legend" className={classes.lableFont}>
            Business Topics
          </FormLabel>
          <Typography variant="caption">
            What conversation topics do you consider to be business topics?
          </Typography>
        </Grid>

        <Box width={{ md: "240px", xs: "160px" }}>
          {props.defalutBusinessTopics && (
            <Autocomplete
              multiple
              limitTags={2}
              options={preDefinedTopics}
              getOptionLabel={(option) => option}
              onChange={(event, value) => handleSelectedTopics(value)}
              defaultValue={[...props.defalutBusinessTopics]}
              renderInput={(params) => (
                <TextField {...params} placeholder="Start typing..." />
              )}
            />
          )}
          {props.defalutBusinessTopics == false && (
            <Autocomplete
              multiple
              limitTags={2}
              options={preDefinedTopics}
              getOptionLabel={(option) => option}
              onChange={(event, value) => handleSelectedTopics(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Start typing..." />
              )}
            />
          )}
        </Box>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  topics: (state.settings.topics && state.settings.topics.data) || [],
});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
  // addNewBusinessTopicRequest: (data) =>
  //   dispatch(actions.addNewBusinessTopicRequest(data)),
  // fetchBusinessTopicsRequest: (data) =>
  //   dispatch(actions.fetchBusinessTopicsRequest(data)),
  // deleteBusinessTopicRequest: (data) =>
  //   dispatch(actions.deleteBusinessTopicRequest(data)),
});

let preDefinedColors = [
  "#FAD1CA",
  "yellow",
  "#EDFA99",
  "#E9EACF",
  "#B4E0F9",
  "green",
  "orange",
  "lightgreen",
  "olive",
  "gold",
];

let preDefinedTopics = [
  "Auto Body Styles",
  "Commercial Trucks",
  "Sedan",
  "Station Wagon",
  "SUV",
  "Van",
  "Convertible",
  "Coupe",
  "Crossover",
  "Hatchback",
  "Microcar",
  "Minivan",
  "Off-Road Vehicles",
  "Pickup Trucks",
  "Auto Type",
  "Budget Cars",
  "Certified Pre-Owned Cars",
  "Classic Cars",
  "Concept Cars",
  "Driverless Cars",
  "Green Vehicles",
  "Luxury Cars",
  "Performance Cars",
  "Car Culture",
  "Dash Cam Videos",
  "Motorcycles",
  "Road-Side Assistance",
  "Scooters",
  "Auto Buying And Selling",
  "Auto Insurance",
  "Auto Parts",
  "Auto Recalls",
  "Auto Repair",
  "Auto Safety",
  "Auto Shows",
  "Auto Technology",
  "Auto Infotainment Technologies",
  "Auto Navigation Systems",
  "Auto Safety Technologies",
  "Auto Rentals",
  "Books And Literature",
  "Art And Photography Books",
  "Biographies",
  "Children's Literature",
  "Comics And Graphic Novels",
  "Cookbooks",
  "Fiction",
  "Poetry",
  "Travel Books",
  "Young Adult Literature",
  "Business And Finance",
  "Business",
  "Business Accounting& Finance",
  "Human Resources",
  "Large Business",
  "Logistics",
  "Marketing And Advertising",
  "Sales",
  "Small And Medium-sized Business",
  "Startups",
  "Business Administration",
  "Business Banking& Finance",
  "Angel Investment",
  "Bankruptcy",
  "Business Loans",
  "Debt Factoring & Invoice Discounting",
  "Mergers And Acquisitions",
  "Private Equity",
  "Sale & Lease Back",
  "Venture Capital",
  "Business I.T.",
  "Business Operations",
  "Consumer Issues",
  "Recalls",
  "Executive Leadership & Management",
  "Government Business",
  "Green Solutions",
  "Business Utilities",
  "Economy",
  "Commodities",
  "Currencies",
  "Financial Crisis",
  "Financial Reform",
  "Financial Regulation",
  "Gasoline Prices",
  "Housing Market",
  "Interest Rates",
  "Job Market",
  "Industries",
  "Advertising Industry",
  "Education Industry",
  "Entertainment Industry",
  "Environmental Services Industry",
  "Financial Industry",
  "Food Industry",
  "Healthcare Industry",
  "Hospitality Industry",
  "Information Services Industry",
  "Legal Services Industry",
  "Logistics And Transportation Industry",
  "Agriculture",
  "Management Consulting Industry",
  "Manufacturing Industry",
  "Mechanical And Industrial Engineering Industry",
  "Media Industry",
  "Metals Industry",
  "Non-Profit Organizations",
  "Pharmaceutical Industry",
  "Power And Energy Industry",
  "Publishing Industry",
  "Real Estate Industry",
  "Apparel Industry",
  "Retail Industry",
  "Technology Industry",
  "Telecommunications Industry",
  "Automotive Industry",
  "Aviation Industry",
  "Biotech And Biomedical Industry",
  "Civil Engineering Industry",
  "Construction Industry",
  "Defense Industry",
  "Careers",
  "Apprenticeships",
  "Career Advice",
  "Career Planning",
  "Job Search",
  "Job Fairs",
  "Resume Writing And Advice",
  "Remote Working",
  "Vocational Training",
  "Education",
  "Adult Education",
  "Private School",
  "Secondary Education",
  "Special Education",
  "College Education",
  "College Planning",
  "Postgraduate Education",
  "Professional School",
  "Undergraduate Education",
  "Early Childhood Education",
  "Educational Assessment",
  "Standardized Testing",
  "Homeschooling",
  "Homework And Study",
  "Language Learning",
  "Online Education",
  "Primary Education",
  "Events And Attractions",
  "Amusement And Theme Parks",
  "Fashion Events",
  "Historic Site And Landmark Tours",
  "Malls & Shopping Centers",
  "Museums & Galleries",
  "Musicals",
  "National & Civic Holidays",
  "Nightclubs",
  "Outdoor Activities",
  "Parks& Nature",
  "Party Supplies And Decorations",
  "Awards Shows",
  "Personal Celebrations & Life Events",
  "Anniversary",
  "Wedding",
  "Baby Shower",
  "Bachelor Party",
  "Bachelorette Party",
  "Birth",
  "Birthday",
  "Funeral",
  "Graduation",
  "Prom",
  "Political Event",
  "Religious Events",
  "Sporting Events",
  "Theater Venues And Events",
  "Zoos & Aquariums",
  "Bars & Restaurants",
  "Business Expos & Conferences",
  "Casinos & Gambling",
  "Cinemas And Events",
  "Comedy Events",
  "Concerts & Music Events",
  "Fan Conventions",
  "Family And Relationships",
  "Bereavement",
  "Dating",
  "Divorce",
  "Eldercare",
  "Marriage And Civil Unions",
  "Parenting",
  "Adoption And Fostering",
  "Daycare And Pre- School",
  "Internet Safety",
  "Parenting Babies And Toddlers",
  "Parenting Children Aged 4-11",
  "Parenting Teens",
  "Special Needs Kids",
  "Single Life",
  "Fine Art",
  "Costume",
  "Dance",
  "Design",
  "Digital Arts",
  "Fine Art Photography",
  "Modern Art",
  "Opera",
  "Theater",
  "Food & Drink",
  "Alcoholic Beverages",
  "Vegan Diets",
  "Vegetarian Diets",
  "World Cuisines",
  "Barbecues And Grilling",
  "Cooking",
  "Desserts And Baking",
  "Dining Out",
  "Food Allergies",
  "Food Movements",
  "Healthy Cooking And Eating",
  "Non-Alcoholic Beverages",
  "Healthy Living",
  "Children's Health",
  "Fitness And Exercise",
  "Participant Sports",
  "Running And Jogging",
  "Men's Health",
  "Nutrition",
  "Senior Health",
  "Weight Loss",
  "Wellness",
  "Alternative Medicine",
  "Herbs And Supplements",
  "Holistic Health",
  "Physical Therapy",
  "Smoking Cessation",
  "Women's Health",
  "Hobbies & Interests",
  "Antiquing And Antiques",
  "Magic And Illusion",
  "Model Toys",
  "Musical Instruments",
  "Paranormal Phenomena",
  "Radio Control",
  "Sci-fi And Fantasy",
  "Workshops And Classes",
  "Arts And Crafts",
  "Beadwork",
  "Candle And Soap Making",
  "Drawing And Sketching",
  "Jewelry Making",
  "Needlework",
  "Painting",
  "Photography",
  "Scrapbooking",
  "Woodworking",
  "Beekeeping",
  "Birdwatching",
  "Cigars",
  "Collecting",
  "Comic Books",
  "Stamps And Coins",
  "Content Production",
  "Audio Production",
  "Freelance Writing",
  "Screenwriting",
  "Video Production",
  "Games And Puzzles",
  "Board Games And Puzzles",
  "Card Games",
  "Roleplaying Games",
  "Genealogy And Ancestry",
  "Home & Garden",
  "Gardening",
  "Remodeling & Construction",
  "Smart Home",
  "Home Appliances",
  "Home Entertaining",
  "Home Improvement",
  "Home Security",
  "Indoor Environmental Quality",
  "Interior Decorating",
  "Landscaping",
  "Outdoor Decorating",
  "Medical Health",
  "Diseases And Conditions",
  "Allergies",
  "Ear, Nose And Throat Conditions",
  "Endocrine And Metabolic Diseases",
  "Hormonal Disorders",
  "Menopause",
  "Thyroid Disorders",
  "Eye And Vision Conditions",
  "Foot Health",
  "Heart And Cardiovascular Diseases",
  "Infectious Diseases",
  "Injuries",
  "First Aid",
  "Lung And Respiratory Health",
  "Mental Health",
  "Reproductive Health",
  "Birth Control",
  "Infertility",
  "Pregnancy",
  "Blood Disorders",
  "Sexual Health",
  "Sexual Conditions",
  "Skin And Dermatology",
  "Sleep Disorders",
  "Substance Abuse",
  "Bone And Joint Conditions",
  "Brain And Nervous System Disorders",
  "Cancer",
  "Cold And Flu",
  "Dental Health",
  "Diabetes",
  "Digestive Disorders",
  "Medical Tests",
  "Pharmaceutical Drugs",
  "Surgery",
  "Vaccines",
  "Cosmetic Medical Services",
  "Movies",
  "Action And Adventure Movies",
  "Romance Movies",
  "Science Fiction Movies",
  "Indie And Arthouse Movies",
  "Animation Movies",
  "Comedy Movies",
  "Crime And Mystery Movies",
  "Documentary Movies",
  "Drama Movies",
  "Family And Children Movies",
  "Fantasy Movies",
  "Horror Movies",
  "World Movies",
  "Music And Audio",
  "Adult Contemporary Music",
  "Soft AC Music",
  "Urban AC Music",
  "Adult Album Alternative",
  "Alternative Music",
  "Children's Music",
  "Classic Hits",
  "Classical Music",
  "College Radio",
  "Comedy( Music And Audio)",
  "Contemporary Hits/ Pop/ Top40",
  "Country Music",
  "Dance And Electronic Music",
  "World/ International Music",
  "Songwriters/ Folk",
  "Gospel Music",
  "Hip Hop Music",
  "Inspirational/ New Age Music",
  "Jazz",
  "Oldies/ Adult Standards",
  "Reggae",
  "Blues",
  "Religious( Music And Audio)",
  "R&B/ Soul/ Funk",
  "Rock Music",
  "Album-oriented Rock",
  "Alternative Rock",
  "Classic Rock",
  "Hard Rock",
  "Soft Rock",
  "Soundtracks, TV And Showtunes",
  "Sports Radio",
  "Talk Radio",
  "Business News Radio",
  "Educational Radio",
  "News Radio",
  "News/ Talk Radio",
  "Public Radio",
  "Urban Contemporary Music",
  "Variety( Music And Audio)",
  "News And Politics",
  "Crime",
  "Disasters",
  "International News",
  "Law",
  "Local News",
  "National News",
  "Politics",
  "Elections",
  "Political Issues",
  "War And Conflicts",
  "Weather",
  "Personal Finance",
  "Consumer Banking",
  "Financial Assistance",
  "Government Support And Welfare",
  "Student Financial Aid",
  "Financial Planning",
  "Frugal Living",
  "Insurance",
  "Health Insurance",
  "Home Insurance",
  "Life Insurance",
  "Motor Insurance",
  "Pet Insurance",
  "Travel Insurance",
  "Personal Debt",
  "Credit Cards",
  "Home Financing",
  "Personal Loans",
  "Student Loans",
  "Personal Investing",
  "Hedge Funds",
  "Mutual Funds",
  "Options",
  "Stocks And Bonds",
  "Personal Taxes",
  "Retirement Planning",
  "Home Utilities",
  "Gas And Electric",
  "Internet Service Providers",
  "Phone Services",
  "Water Services",
  "Pets",
  "Birds",
  "Cats",
  "Dogs",
  "Fish And Aquariums",
  "Large Animals",
  "Pet Adoptions",
  "Reptiles",
  "Veterinary Medicine",
  "Pet Supplies",
  "Pop Culture",
  "Celebrity Deaths",
  "Celebrity Families",
  "Celebrity Homes",
  "Celebrity Pregnancy",
  "Celebrity Relationships",
  "Celebrity Scandal",
  "Celebrity Style",
  "Humor And Satire",
  "Real Estate",
  "Apartments",
  "Retail Property",
  "Vacation Properties",
  "Developmental Sites",
  "Hotel Properties",
  "Houses",
  "Industrial Property",
  "Land And Farms",
  "Office Property",
  "Real Estate Buying And Selling",
  "Real Estate Renting And Leasing",
  "Religion & Spirituality",
  "Agnosticism",
  "Spirituality",
  "Astrology",
  "Atheism",
  "Buddhism",
  "Christianity",
  "Hinduism",
  "Islam",
  "Judaism",
  "Sikhism",
  "Science",
  "Biological Sciences",
  "Chemistry",
  "Environment",
  "Genetics",
  "Geography",
  "Geology",
  "Physics",
  "Space And Astronomy",
  "Shopping",
  "Coupons And Discounts",
  "Flower Shopping",
  "Gifts And Greetings Cards",
  "Grocery Shopping",
  "Holiday Shopping",
  "Household Supplies",
  "Lotteries And Scratchcards",
  "Sales And Promotions",
  "Children's Games And Toys",
  "Sports",
  "American Football",
  "Boxing",
  "Cheerleading",
  "College Sports",
  "College Football",
  "College Basketball",
  "College Baseball",
  "Cricket",
  "Cycling",
  "Darts",
  "Disabled Sports",
  "Diving",
  "Equine Sports",
  "Horse Racing",
  "Extreme Sports",
  "Canoeing And Kayaking",
  "Climbing",
  "Paintball",
  "Scuba Diving",
  "Skateboarding",
  "Snowboarding",
  "Surfing And Bodyboarding",
  "Waterskiing And Wakeboarding",
  "Australian Rules Football",
  "Fantasy Sports",
  "Field Hockey",
  "Figure Skating",
  "Fishing Sports",
  "Golf",
  "Gymnastics",
  "Hunting And Shooting",
  "Ice Hockey",
  "Inline Skating",
  "Lacrosse",
  "Auto Racing",
  "Motorcycle Sports",
  "Martial Arts",
  "Olympic Sports",
  "Summer Olympic Sports",
  "Winter Olympic Sports",
  "Poker And Professional Gambling",
  "Rodeo",
  "Rowing",
  "Rugby",
  "Rugby League",
  "Rugby Union",
  "Sailing",
  "Skiing",
  "Snooker/ Pool/ Billiards",
  "Soccer",
  "Badminton",
  "Softball",
  "Squash",
  "Swimming",
  "Table Tennis",
  "Tennis",
  "Track And Field",
  "Volleyball",
  "Walking",
  "Water Polo",
  "Weightlifting",
  "Baseball",
  "Wrestling",
  "Basketball",
  "Beach Volleyball",
  "Bodybuilding",
  "Bowling",
  "Sports Equipment",
  "Style& Fashion",
  "Beauty",
  "Hair Care",
  "Makeup And Accessories",
  "Nail Care",
  "Natural And Organic Beauty",
  "Perfume And Fragrance",
  "Skin Care",
  "Women's Fashion",
  "Women's Accessories",
  "Women's Glasses",
  "Women's Handbags And Wallets",
  "Women's Hats And Scarves",
  "Women's Jewelry And Watches",
  "Women's Clothing",
  "Women's Business Wear",
  "Women's Casual Wear",
  "Women's Formal Wear",
  "Women's Intimates And Sleepwear",
  "Women's Outerwear",
  "Women's Sportswear",
  "Women's Shoes And Footwear",
  "Body Art",
  "Children's Clothing",
  "Designer Clothing",
  "Fashion Trends",
  "High Fashion",
  "Men's Fashion",
  "Men's Accessories",
  "Men's Jewelry And Watches",
  "Men's Clothing",
  "Men's Business Wear",
  "Men's Casual Wear",
  "Men's Formal Wear",
  "Men's Outerwear",
  "Men's Sportswear",
  "Men's Underwear And Sleepwear",
  "Men's Shoes And Footwear",
  "Personal Care",
  "Bath And Shower",
  "Deodorant And Antiperspirant",
  "Oral Care",
  "Shaving",
  "Street Style",
  "Technology& Computing",
  "Artificial Intelligence",
  "Augmented Reality",
  "Computing",
  "Computer Networking",
  "Computer Peripherals",
  "Computer Software And Applications",
  "3-D Graphics",
  "Photo Editing Software",
  "Shareware And Freeware",
  "Video Software",
  "Web Conferencing",
  "Antivirus Software",
  "Browsers",
  "Computer Animation",
  "Databases",
  "Desktop Publishing",
  "Digital Audio",
  "Graphics Software",
  "Operating Systems",
  "Data Storage And Warehousing",
  "Desktops",
  "Information And Network Security",
  "Internet",
  "Cloud Computing",
  "Web Development",
  "Web Hosting",
  "Email",
  "Internet For Beginners",
  "Internet Of Things",
  "IT And Internet Support",
  "Search",
  "Social Networking",
  "Web Design And HTML",
  "Laptops",
  "Programming Languages",
  "Consumer Electronics",
  "Cameras And Camcorders",
  "Home Entertainment Systems",
  "Smartphones",
  "Tablets And E-readers",
  "Wearable Technology",
  "Robotics",
  "Virtual Reality",
  "Television",
  "Animation TV",
  "Soap Opera TV",
  "Special Interest TV",
  "Sports TV",
  "Children's TV",
  "Comedy TV",
  "Drama TV",
  "Factual TV",
  "Holiday TV",
  "Music TV",
  "RealityTV",
  "Science Fiction TV",
  "Travel",
  "Travel Accessories",
  "Travel Locations",
  "Africa Travel",
  "Asia Travel",
  "Australia And Oceania Travel",
  "Europe Travel",
  "North America Travel",
  "Polar Travel",
  "South America Travel",
  "Travel Preparation And Advice",
  "Travel Type",
  "Adventure Travel",
  "Family Travel",
  "Honeymoons And Getaways",
  "Hotels And Motels",
  "Rail Travel",
  "Road Trips",
  "Spas",
  "Air Travel",
  "Beach Travel",
  "Bed & Breakfasts",
  "Budget Travel",
  "Business Travel",
  "Camping",
  "Cruises",
  "Day Trips",
  "Video Gaming",
  "Console Games",
  "E Sports",
  "Mobile Games",
  "PC Games",
  "Video Game Genres",
  "Action Video Games",
  "Role-Playing Video Games",
  "Simulation Video Games",
  "Sports Video Games",
  "Strategy Video Games",
  "Action-Adventure Video Games",
  "Adventure Video Games",
  "Casual Games",
  "Educational Video Games",
  "Exercise And Fitness Video Games",
  "MMOs",
  "Music And Party Video Games",
  "Puzzle Video Games",
];

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTopics);
