import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import logo from '../../assets/images/logo.svg';
import logo from "../../assets/images/logo-blk.png";
import Radio from "../inputs/radio";
import firebaseAnalytics from "./../../firebase/firebaseAnalytics";
import firebase from "./../../firebase/firebase.js";
import useAuth from "../../hooks/useAuth";
import Hidden from "@material-ui/core/Hidden";
import { getData, postData } from "./../../utils";
import OrganizationList from "./OrganizationList";
import CircularProgress from "@material-ui/core/CircularProgress";

function Welcome() {
  let history = useHistory();
  const { fireStoreuserObj, setOrganizationId, getIsOrganizationOwner } =
    useAuth();

  const [organizationName, setOrganizationName] = useState("");
  const [meetingType, setMeetingType] = useState("ALL");
  const [promotionCodeId, setPromotionCodeId] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [createOrg, setCreateOrg] = useState(false);
  const [orgid, setOrgid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgLoader, setOrgLoader] = useState(false);

  useEffect(() => {
    document.title = "Meeting Details - BlueCAP";
  }, []);

  window.dataLayer = window.dataLayer || [];

  const joinButton = {
    backgroundColor: "#2C73FF",
    boxShadow: "0px 11px 12px rgba(44, 115, 255, 0.25)",
    borderRadius: "10px",
    width: "224px",
    height: "48px",
    color: "#fff",
    textTransform: "unset",
  };

  const handleChangeOrgName = (e) => {
    setOrganizationName(e.target.value);
  };

  const changeListener = (value) => {
    setMeetingType(value);
    window.dataLayer.push({
      event: "change-meeting-join-setting",
      "meeting-type": value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data = {
      organizationId: orgid,
      organizationName: organizationName,
      meetingType: meetingType,
    };
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/setup`,
          jwtToken,
          data
        );
        setLoading(false);

        if (result.status === "success") {
          firebaseAnalytics.logEvent("sign up", {
            success: true,
            member_id: firebase.auth().currentUser.uid,
          });

          let isOrgOwner = await getIsOrganizationOwner(
            result.data.organizationId
          );

          if (isOrgOwner) {
            await getData(
              `${process.env.REACT_APP_BASE_API_URL}/payment/associateStripeCustomer`,
              jwtToken
            );

            let payload = {
              planType: "freeTrial",
            };

            if (promotionCodeId.length) {
              payload.promotionCodeId = promotionCodeId;
            }

            await postData(
              `${process.env.REACT_APP_BASE_API_URL}/payment/initiatePlan`,
              jwtToken,
              payload
            );
          }

          await setOrganizationId(result.data.organizationId);

          window.dataLayer.push({
            event: "signup-complete",
            "organization-name": organizationName,
            "organization-id": result.data.organizationId,
            "member-id": firebase.auth().currentUser.uid,
          });

          fireStoreuserObj.organizationId = true;
          history.push("/dashboard");
        }
      });
  };

  useEffect(() => {
    let domain = fireStoreuserObj.email.split("@")[1];
    if (!["gmail.com", "outlook.com"].includes(domain)) {
      setOrgLoader(true);
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (jwtToken) => {
          let result = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/organization/check/${domain}`,
            jwtToken
          );
          setOrgLoader(false);
          if (result.data.length > 0) {
            setOrganizations(result.data);
          }
        });
    }
  }, []);

  if (fireStoreuserObj.organizationId) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container className="main-container" maxWidth="sm">
        <Box
          bgcolor="#fff"
          borderRadius="10px"
          p={{ xs: 2, sm: 3, md: 4 }}
          style={{ position: "relative" }}
        >
          <Grid align="center">
            <img src={logo} width="300px" className="bc-icon" alt="" />
            <Typography variant="h4" gutterBottom>
              One last step
            </Typography>
            <Typography className="subtitle" variant="body2" gutterBottom>
              Please provide the following information.
            </Typography>
          </Grid>

          {orgLoader ? (
            <Grid align="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {!createOrg && organizations.length > 0 && (
                <OrganizationList
                  organizations={organizations}
                  orgid={orgid}
                  showCreateOrganization={() => setCreateOrg(true)}
                  selectOrganization={(id) => setOrgid(id)}
                />
              )}
              {(createOrg || organizations.length === 0) && (
                <div className="frm-block">
                  <FormLabel component="legend" className="meeting-label">
                    Organization name
                  </FormLabel>
                  <TextField
                    className="organization-name"
                    id="organization-name"
                    name="organization-name"
                    variant="outlined"
                    fullWidth
                    // defaultValue="My Organization"
                    placeholder="My Organization"
                    value={organizationName}
                    onChange={handleChangeOrgName}
                  />
                  {organizations.length > 0 && (
                    <Button
                      variant="text"
                      sx={{
                        display: "block",
                        float: "right",
                        textTransform: "none",
                        fontSize: 12,
                      }}
                      onClick={() => setCreateOrg(false)}
                    >
                      Join an existing organization
                    </Button>
                  )}
                </div>
              )}

              {(createOrg || orgid || organizations.length === 0) && (
                <>
                  <div className="frm-block">
                    <FormLabel component="legend" className="meeting-label">
                      Meeting settings
                    </FormLabel>
                    <Radio changeListener={changeListener} />
                  </div>
                  <div className="frm-block">
                    <FormLabel component="legend" className="meeting-label">
                      Promo Code
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Enter Promo Code Here"
                      value={promotionCodeId}
                      onChange={(e) => setPromotionCodeId(e.target.value)}
                    />
                  </div>
                  <div>
                    <Grid align="center">
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          style={joinButton}
                          onClick={handleSubmit}
                        >
                          Save and Continue
                        </Button>
                      )}
                    </Grid>
                  </div>
                </>
              )}
            </>
          )}

          <div>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              Copyright © {new Date().getFullYear()} Uncanny Lab Ltd. All rights
              reserved.
            </p>
          </div>

          <Hidden only={["sm", "xs"]}>
            <div
              style={{
                width: "200px",
                position: "absolute",
                left: "-160px",
                top: "740px",
              }}
            >
              <img src="/images/mascot.png"></img>
            </div>
          </Hidden>
        </Box>
      </Container>
    </>
  );
}

export default Welcome;
