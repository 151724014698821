import { Box, Button, TextareaAutosize } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "./../../../firebase/firebase.js";
import {
  saveLeadFieldsSetting,
  getOrganization,
} from "./../../../firebase/firestore.js";
import * as actions from "./../../../redux/actions";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { LeadFieldsArray } from "./LeadFieldsArray";
import SettingsIcon from "@mui/icons-material/Settings";

const typesOfFields = [
  "text",
  "multi-line-text",
  "number",
  "select",
  "multi-select",
  "date",
  "datetime",
  "checkbox",
];

function FieldSettingDialog(props) {
  const {
    onClose,
    open,
    DefaultLeadFormFields,
    setDefaultLeadFormFields,
    editField,
  } = props;

  const [newSelectOption, setNewSelectOption] = useState("");
  const handleClose = () => {
    setNewSelectOption("");
    onClose();
  };

  if (!editField) {
    return "";
  }

  let editFieldObject = DefaultLeadFormFields.find((f) => f.title == editField);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box width={500} padding={3}>
        <p>Title: {editFieldObject.title}</p>
        <p>Type: {editFieldObject.type}</p>
        <FormGroup style={{ paddingTop: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={editFieldObject.isRequired ? true : false}
                onChange={(e) => {
                  let tempDefaultFields = DefaultLeadFormFields.map((f) => {
                    if (f.title == editFieldObject.title) {
                      f.isRequired = e.target.checked;
                    }
                    return f;
                  });
                  setDefaultLeadFormFields(tempDefaultFields);
                }}
              />
            }
            label="Is Required"
          />
        </FormGroup>
        {editFieldObject.type == "text" && (
          <FormGroup>
            <TextField
              label="Default value"
              onChange={(e) => {
                let tempDefaultFields = DefaultLeadFormFields.map((f) => {
                  if (f.title == editFieldObject.title) {
                    f.defaultValue = e.target.value;
                  }
                  return f;
                });
                setDefaultLeadFormFields(tempDefaultFields);
              }}
              defaultValue={
                editFieldObject.defaultValue ? editFieldObject.defaultValue : ""
              }
            />
          </FormGroup>
        )}

        {editFieldObject.type == "select" && editFieldObject.options && (
          <Box padding={2}>
            <h4>Manage Options</h4>
            <ReactSortable
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={editFieldObject.options}
              setList={(newOptions) => {
                let formattedOption = newOptions.map((opt) => {
                  delete opt.selected;
                  delete opt.chosen;
                  return opt;
                });
                let tempDefaultFields = DefaultLeadFormFields.map((f) => {
                  if (f.title == editFieldObject.title) {
                    f.options = formattedOption;
                  }
                  return f;
                });
                setDefaultLeadFormFields(tempDefaultFields);
              }}
            >
              {editFieldObject.options.map((option) => {
                return (
                  <div
                    style={{
                      // border: "1px solid lightgray",
                      padding: "10px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      // padding={2}
                      alignItems="center"
                      style={{ cursor: "all-scroll" }}
                    >
                      <p>{option.title}</p>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={option.isDefault ? true : false}
                            onChange={(e) => {
                              let tempDefaultFields = DefaultLeadFormFields.map(
                                (f) => {
                                  if (f.title == editFieldObject.title) {
                                    let newOptions =
                                      editFieldObject.options.map((opt) => {
                                        if (opt.title == option.title) {
                                          return {
                                            ...opt,
                                            isDefault: !opt.isDefault,
                                          };
                                        } else {
                                          return { ...opt, isDefault: false };
                                        }
                                      });

                                    f.options = newOptions;
                                  }
                                  return f;
                                }
                              );
                              setDefaultLeadFormFields(tempDefaultFields);
                            }}
                          />
                        }
                        label="Make this Default"
                      />
                    </Box>
                  </div>
                );
              })}
            </ReactSortable>

            <FormGroup style={{ paddingTop: "10px" }}>
              <TextField
                label="Add new option"
                value={newSelectOption}
                onChange={(e) => setNewSelectOption(e.target.value)}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: "20px", width: "100px" }}
                onClick={() => {
                  let newList = DefaultLeadFormFields.map((f) => {
                    if (
                      f.title == editFieldObject.title &&
                      newSelectOption.length
                    ) {
                      if (
                        f.options &&
                        !f.options.some((i) => i.title == newSelectOption)
                      ) {
                        f.options.push({ title: newSelectOption });
                      }
                    }
                    return f;
                  });
                  setNewSelectOption("");
                  setDefaultLeadFormFields(newList);
                }}
              >
                Add
              </Button>
            </FormGroup>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

//https://codesandbox.io/s/create-react-app-forked-pjz0s?file=/src/Sortable.js
const LeadFieldTab = (props) => {
  const history = useHistory();

  const [DefaultLeadFormFields, setDefaultLeadFormFields] = useState([]);
  const [fieldSettingDialogStatus, setFieldSettingDialogStatus] =
    useState(false);

  const [editField, setEditField] = useState(null);

  const handleFieldSettingDilogClose = () => {
    setFieldSettingDialogStatus(false);
  };

  const handleFieldSettingIconClick = (field) => {
    setFieldSettingDialogStatus(true);
    setEditField(field);
  };

  useEffect(async () => {
    let organization = await getOrganization();
    if (organization.leadFieldsSetting) {
      setDefaultLeadFormFields(organization.leadFieldsSetting);
    } else {
      setDefaultLeadFormFields(LeadFieldsArray);
    }
  }, []);
  return (
    <Box
      padding={2}
      flexDirection="column"
      justifyContent="space-between"
      display="flex"
    >
      <FieldSettingDialog
        open={fieldSettingDialogStatus}
        onClose={handleFieldSettingDilogClose}
        DefaultLeadFormFields={DefaultLeadFormFields}
        setDefaultLeadFormFields={setDefaultLeadFormFields}
        editField={editField}
      />
      <Box
        width={1 / 3}
        display="flex"
        justifyContent="space-between"
        paddingBottom={2}
      >
        <h3>Leads Form Setting</h3>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => {
            let formattedList = DefaultLeadFormFields.map((f) => {
              delete f.selected;
              delete f.chosen;
              return f;
            });

            let res = await saveLeadFieldsSetting(formattedList);

            if (res.success) {
              props.showSnackbar({
                show: true,
                severity: "success",
                message: "Settings saved successfully.",
              });
              // history.push("/crm/leads");
            } else {
              props.showSnackbar({
                show: true,
                severity: "error",
                message: res.message,
              });
            }
          }}
        >
          Save
        </Button>
      </Box>
      <Box>
        <h4>CRM Default Fields</h4>
        <Box>
          <Box display="flex">
            <ReactSortable
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={DefaultLeadFormFields}
              setList={setDefaultLeadFormFields}
            >
              {DefaultLeadFormFields.map((field) => {
                return (
                  <div style={{ padding: "3px" }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width={1}
                      minWidth="400px"
                      height={50}
                      padding={2}
                      style={{
                        backgroundColor: "lightgrey",
                        cursor: "all-scroll",
                      }}
                    >
                      <p>
                        {field.title}{" "}
                        {field.isRequired && (
                          <span
                            aria-hidden="true"
                            className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-1szckaa-MuiFormLabel-asterisk"
                          >
                             *
                          </span>
                        )}
                      </p>
                      <Box display="flex">
                        <p>{field.type}</p>
                        <Box
                          marginLeft="20px"
                          onClick={() => {
                            console.log(`${field.title} settings`);
                            handleFieldSettingIconClick(field.title);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <SettingsIcon></SettingsIcon>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                );
              })}
            </ReactSortable>
          </Box>
        </Box>
      </Box>

      <Box justifyContent="space-around" width={1} display="flex"></Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadFieldTab);
