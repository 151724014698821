import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import useAuth from "./../../hooks/useAuth";
import {
  EditorState,
  convertToRaw,
  ContentState,
  AtomicBlockUtils,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AddTask as SaveTask,
  GetTask,
  getElasticToken,
} from "./../../firebase/firestore.js";
import ElasticLeadAutocomplete from "./ElasticLeadAutocomplete.js";
import ElasticContactAutocomplete from "./ElasticContactAutocomplete.js";
import TextEditor from "../TextEditor";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import { Get, Post } from "../../utils/axiosUtils";
import { isImage } from "../../utils/isImage";
import ElasticDealsAutocomplete from "./ElasticDealsAutocomplete";
import ElasticAccountAutocomplete from "./ElasticAccountAutocomplete";
import MemberAutocomplete from "./MemberAutocomplete";

export default function AddTask(props) {
  let {
    addTaskDialogStatus,
    handleCloseAddTaskDialog,
    addTaskInList,
    tasks,
    onSuccessCallback,
  } = props;
  const { fireStoreuserObj } = useAuth();

  let [title, setTitle] = useState(() => {
    return props.title ? props.title : "";
  });
  let [status, setStatus] = useState(() => {
    return props.defaultStatus ? props.defaultStatus : "not_started";
  });
  let [loading, setLoading] = useState(false);
  let [relatedTo, setRelatedTo] = useState(() =>
    props.relatedTo ? props.relatedTo : null
  );
  let [relatedToEntity, setRelatedToEntity] = useState(() => {
    if (props.relatedToEntity && props.relatedToEntity == "contact") {
      return "contact";
    } else if (props.relatedToEntity && props.relatedToEntity == "lead") {
      return "lead";
    } else {
      return null;
    }
  });
  let [taskMembers, setTaskMembers] = useState([
    { displayName: fireStoreuserObj.displayName, userId: fireStoreuserObj.uid },
  ]);
  let [priority, setPriority] = useState("low");

  let [taskUser, setTaskUser] = useState({
    displayName: fireStoreuserObj.displayName,
    userId: fireStoreuserObj.uid,
  });
  let [dueDate, setDueDate] = useState(new Date());
  let [elasticToken, setElasticToken] = useState(null);

  const [editorState, setEditorState] = useState(() => {
    if (props.description) {
      const contentBlock = htmlToDraft(props.description);
      if (contentBlock) {
        return EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks)
        );
      }
      return EditorState.createEmpty();
    } else {
      return EditorState.createEmpty();
    }
  });
  const [attachments, setAttachments] = useState([]);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [editorLoading, setEditorLoading] = useState(true);

  const addTaskMember = (newMember) => {
    setTaskMembers((CurrentSelectedMembers) => [
      ...CurrentSelectedMembers,
      newMember,
    ]);
  };

  const removeTaskMember = (member) => {
    setTaskMembers((CurrentSelectedMembers) =>
      CurrentSelectedMembers.filter((m) => m.userId != member)
    );
  };

  const getSelectedMemberIds = () => {
    return taskMembers.map((m) => m.userId);
  };

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  const getMentionOptions = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (jwtToken) => {
        let result = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
          jwtToken
        );
        if (result.status === "success") {
          let suggestionData = result.data.members?.filter(
            (member) => member.id !== fireStoreuserObj.uid
          );
          let suggestionToEditor = [];
          suggestionData?.map((member) => {
            suggestionToEditor.push({
              name: member?.name,
              url: "",
              avatar: member?.avatar,
              id: member?.id,
            });
          });
          suggestionToEditor.push({
            name: fireStoreuserObj.displayName,
            url: "",
            avatar: fireStoreuserObj.displayName,
            id: fireStoreuserObj.uid,
          });
          setMentionSuggestions(suggestionToEditor);
          setEditorLoading(false);
        }
      });
  };

  useEffect(getMentionOptions, []);

  const resetForm = () => {
    setTitle("");
    setStatus("not_started");
    setRelatedTo(null);
    setRelatedToEntity(null);
    setTaskMembers([
      {
        displayName: fireStoreuserObj.displayName,
        userId: fireStoreuserObj.uid,
      },
    ]);
    setPriority("low");

    setTaskUser({
      displayName: fireStoreuserObj.displayName,
      userId: fireStoreuserObj.uid,
    });
    setDueDate(new Date());
  };
  const addTask = async () => {
    setLoading(true);

    let selectedTasks = tasks.filter((t) => t.status == status);
    selectedTasks.sort((t1, t2) => t1.order - t2.order);
    let order = 0;
    if (selectedTasks.length > 0) {
      order = selectedTasks[selectedTasks.length - 1].order + 1;
    }

    let payload = {
      title,
      description: JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      ),
      status,
      order,
      relatedTo: null,
      dueDate,
      assignedTo: taskUser.userId,
      priority,
      descriptionAttachments: attachments,
    };

    if (relatedTo) {
      payload.relatedTo = relatedTo;
    }

    if (taskMembers.length) {
      payload.taskMembers = taskMembers.map((m) => m.userId);
    }
    try {
      let res = await SaveTask(payload);
      if (res.success) {
        let newTaskRes = await GetTask(res.task.id);
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        handleCloseAddTaskDialog();
        setLoading(false);
        if (addTaskInList) {
          addTaskInList(newTaskRes.task);
        }
        resetForm();
      } else {
        throw "undefined error happen";
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleFileUpload = (files) => {
    const formData = new FormData();
    formData.append("attachment", files[0]);
    formData.append("type", "tasks");
    formData.append("typeid", "");
    Post("attachments/create", formData, {
      "Content-Type": "multipart/form-data; boundary=1",
    })
      .then(({ data }) => {
        Get(`attachments/${data.data.id}`).then((urlRes) => {
          setAttachments([
            { url: urlRes.data.data.url, ...data.data },
            ...attachments,
          ]);
          if (isImage(data.data.fileName)) {
            // Check file type
            setEditorState(insertImage(urlRes.data.data.url, data.data.id));
          }
        });
      })
      .catch((err) => {
        console.log("err happen when uploading attachment", err);
      });
  };

  const handleDeleteFiles = () => {
    let allEntities = Object.values(
      convertToRaw(editorState.getCurrentContent()).entityMap
    );
    let imageEntities = allEntities
      .filter((item) => item.type === "IMAGE")
      .map((item) => item.data.id);
    let imageAttachments = [];
    attachments.map((attachment) => {
      if (isImage(attachment.fileName)) {
        imageAttachments.push(attachment);
      }
    });
    if (imageAttachments.length !== imageEntities.length) {
      let willDeleteAttachments = imageAttachments
        .filter((item) => !imageEntities.includes(item.id))
        .map((item) => item.id);
      let newAttachments = [...attachments];
      newAttachments = newAttachments.filter(
        (item) => !willDeleteAttachments.includes(item.id)
      );
      setAttachments(newAttachments);
    }
  };

  useEffect(handleDeleteFiles, [editorState]);

  const insertImage = (url, id) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url, id }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const handleDeleteAttachment = (e, attachment) => {
    e.stopPropagation();
    let newAttachments = [...attachments];
    newAttachments = newAttachments.filter((item) => item.id !== attachment.id);
    setAttachments(newAttachments);
  };

  return (
    <>
      <Dialog onClose={handleCloseAddTaskDialog} open={addTaskDialogStatus}>
        <div className="add-task-modal">
          <Box sx={{ padding: "1em" }} display="flex" flexDirection="column">
            <h4>Add Task</h4>

            <Box sx={{ margin: "1em 0" }}>
              <MemberAutocomplete
                suggestions={mentionSuggestions}
                setTaskUser={setTaskUser}
                defaultValue={taskUser?.displayName}
                className={"elastic-add-task-input"}
              />
            </Box>

            <TextField
              width="fullwidth"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={"tasks-title-input"}
              style={{ margin: "1em 0" }}
            />
            <Box sx={{ margin: "1em 0" }}>
              <FormControl fullWidth>
                <InputLabel id="task-status" className="task-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="task-status"
                  id="task-status"
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  className="task-select"
                >
                  <MenuItem value={"not_started"} className="task-select-item">
                    Not Started
                  </MenuItem>
                  <MenuItem value={"deferred"} className="task-select-item">
                    Deferred
                  </MenuItem>
                  <MenuItem value={"inprogress"} className="task-select-item">
                    In Progress
                  </MenuItem>
                  <MenuItem value={"completed"} className="task-select-item">
                    completed
                  </MenuItem>
                  <MenuItem
                    value={"waiting_for_input"}
                    className="task-select-item"
                  >
                    Waiting for input
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              style={{ margin: "1em 0" }}
              display="flex"
              flexDirection="column"
            >
              <Box my={1}>
                {taskMembers.map((member, index) => {
                  return (
                    <Chip
                      key={index}
                      label={member.displayName}
                      onDelete={() => removeTaskMember(member.userId)}
                    />
                  );
                })}
              </Box>
              <MemberAutocomplete
                suggestions={mentionSuggestions}
                setTaskUser={addTaskMember}
                clearInputAfterSet
                className={"elastic-add-task-input"}
              />
            </Box>
            <Box display="flex" style={{ margin: "1em 0" }}>
              <FormControl
                style={{
                  minWidth: "120px",
                  paddingRight: "10px",
                  height: "auto",
                }}
              >
                <InputLabel
                  id="priority-select-label"
                  className="task-select-label"
                >
                  Priority
                </InputLabel>
                <Select
                  labelId="priority-select-label"
                  id="priority-select"
                  value={priority}
                  label="Entity"
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                  className="task-select h-100"
                >
                  <MenuItem value="low" className="task-select-item">
                    Low
                  </MenuItem>
                  <MenuItem value="medium" className="task-select-item">
                    Medium
                  </MenuItem>
                  <MenuItem value="high" className="task-select-item">
                    High
                  </MenuItem>
                  <MenuItem value="urgent" className="task-select-item">
                    Urgent
                  </MenuItem>
                </Select>
              </FormControl>
              <DesktopDatePicker
                label="Due date"
                inputFormat="MMM,dd yyyy"
                value={dueDate}
                onChange={(date) => setDueDate(date)}
                renderInput={(params) => (
                  <TextField className="tasks-datepicker" {...params} />
                )}
              />
            </Box>
            {!props.relatedTo && (
              <Box display="flex" flexDirection="column">
                <Box m={"1em 0"}>
                  <p>Related to: </p>
                </Box>
                <Box display="flex">
                  <Box display="flex" alignItems="flex-end">
                    <FormControl style={{ minWidth: "100px" }}>
                      <InputLabel
                        id="entity-select-label"
                        className="task-select-label"
                      >
                        Entity
                      </InputLabel>
                      <Select
                        labelId="entity-select-label"
                        id="entity-select"
                        value={relatedToEntity}
                        label="Entity"
                        onChange={(e) => {
                          setRelatedTo(null);
                          setRelatedToEntity(e.target.value);
                          //refreshAutocomplete(e.target.value);
                        }}
                        className="task-select"
                      >
                        <MenuItem value="lead" className="task-select-item">
                          Lead
                        </MenuItem>
                        <MenuItem value="contact" className="task-select-item">
                          Contact
                        </MenuItem>
                        <MenuItem value="account" className="task-select-item">
                          Account
                        </MenuItem>
                        <MenuItem value="deal" className="task-select-item">
                          Deal
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {relatedToEntity && (
                    <Box sx={{ width: "100%" }}>
                      <Box style={{ marginLeft: "5px" }}>
                        {relatedToEntity === "lead" ? (
                          <ElasticLeadAutocomplete
                            setRelatedTo={setRelatedTo}
                            apiKey={elasticToken}
                            className={"elastic-add-task-input"}
                          />
                        ) : relatedToEntity === "contact" ? (
                          <ElasticContactAutocomplete
                            setRelatedTo={setRelatedTo}
                            apiKey={elasticToken}
                            className={"elastic-add-task-input"}
                          />
                        ) : relatedToEntity === "deal" ? (
                          <ElasticDealsAutocomplete
                            setRelatedTo={setRelatedTo}
                            apiKey={elasticToken}
                            className={"elastic-add-task-input"}
                          />
                        ) : relatedToEntity === "account" ? (
                          <ElasticAccountAutocomplete
                            setRelatedTo={setRelatedTo}
                            apiKey={elasticToken}
                            className={"elastic-add-task-input"}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {attachments?.length > 0 && (
              <Box sx={{ margin: "1.5em 0", width: "100%", height: "auto" }}>
                <b style={{ paddingBottom: "1em", color: "#404040" }}>
                  Attachments ({attachments.length})
                </b>
                <div className="attachments">
                  {attachments.map((attachment, index) => (
                    <div
                      className="attachment"
                      onClick={
                        () =>
                          attachment?.url
                            ? window.open(attachment.url, "_blank")
                            : null //attachmentOpen(attachment?.id)
                      }
                      key={index}
                    >
                      <span className="title">{attachment.fileName}</span>
                      {!isImage(attachment.fileName) && (
                        <ClearIcon
                          onClick={(e) => handleDeleteAttachment(e, attachment)}
                          style={{ fontSize: "16px", color: "#303030" }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Box>
            )}

            <Box style={{ margin: "1em 0" }}>
              {!editorLoading && (
                <TextEditor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  mentions={mentionSuggestions}
                  handlePastedFiles={(files) => handleFileUpload(files)}
                  isBorder
                  placeholder={"Add description"}
                  className={"task-editor comment"}
                  isButtons
                  buttonTitle={"Save"}
                  buttonLoading={loading}
                  handleButton={addTask}
                  cancelButtonTitle={"Cancel"}
                  handleCancelButton={handleCloseAddTaskDialog}
                />
              )}
            </Box>
            {/*
              <Box display="flex" flexDirection="flex-start">
              <button
                className="modal-button blue"
                onClick={() => addTask()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "#fff" }}
                  ></CircularProgress>
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="modal-button white ml"
                onClick={() => handleCloseAddTaskDialog()}
              >
                Close
              </button>
            </Box>
               */}
          </Box>
        </div>
      </Dialog>
    </>
  );
}
