import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Button, CircularProgress } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import useAuth from "./../../hooks/useAuth";

import {
  addDeal as saveDeal,
  GetDeal,
  getElasticToken,
} from "./../../firebase/firestore.js";

import ElasticContactAutocomplete from "../Tasks/ElasticContactAutocomplete";
import ElasticAccountAutocomplete from "../Tasks/ElasticAccountAutocomplete";

export default function AddDeal(props) {
  let {
    addDealDialogStatus,
    handleCloseAddDealDialog,
    addDealInList,
    dealValue,
  } = props;
  const { fireStoreuserObj } = useAuth();
  console.log(dealValue, "DEAL VALUE");
  let [title, setTitle] = useState("");
  let [value, setValue] = useState(dealValue ? dealValue : "");
  let [status, setStatus] = useState(() =>
    props.defaultDealStatus
      ? props.defaultDealStatus
      : "identify_decision_makers"
  );
  let [loading, setLoading] = useState(false);
  let [relatedTo, setRelatedTo] = useState(() =>
    props.relatedTo ? props.relatedTo : null
  );
  let [relatedToEntity, setRelatedToEntity] = useState(() => {
    if (
      props.relatedToEntity &&
      (props.relatedToEntity === "contact" ||
        props.relatedToEntity === "account")
    ) {
      return props.relatedToEntity;
    } else {
      return null;
    }
  });

  let [expectedClosingDate, setExpectedClosingDate] = useState(new Date());

  const [elasticToken, setElasticToken] = useState(null);

  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };

    loadElasticToken();
  }, []);

  const resetForm = () => {
    setTitle("");
    setStatus("not_started");
    setRelatedTo(null);
    setRelatedToEntity(null);

    setExpectedClosingDate(new Date());
  };
  const addDeal = async () => {
    setLoading(true);

    let payload = {
      title,
      status,
      value,
      relatedTo: null,
      expectedClosingDate,
    };

    if (relatedTo) {
      payload.relatedTo = relatedTo;
    }

    let res = await saveDeal(payload);
    if (res.success) {
      let newDealRes = await GetDeal(res.deal.id);
      setLoading(false);
      handleCloseAddDealDialog();
      if (addDealInList) {
        addDealInList(newDealRes.deal);
      }
      resetForm();
    }
  };
  return (
    <>
      <Dialog
        onClose={handleCloseAddDealDialog}
        fullWidth
        open={addDealDialogStatus}
      >
        <div className="add-deal-modal">
          <Box sx={{ padding: "1em" }} display="flex" flexDirection="column">
            <h4>Add Deal</h4>

            <TextField
              width="fullwidth"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={"deals-title-input"}
              style={{ margin: "1em 0" }}
            />

            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel htmlFor="deal-value">Deal value</InputLabel>
              <OutlinedInput
                id="deal-value"
                value={value}
                onChange={(e) => {
                  if (e.target.value && !Number(e.target.value)) {
                    return;
                  }

                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">CA$</InputAdornment>
                }
                label="Deal value"
              />
            </FormControl>

            <Box sx={{ margin: "1em 0" }}>
              <FormControl fullWidth>
                <InputLabel id="deal-status" className="deal-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="deal-status"
                  id="deal-status"
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  className="deal-select"
                >
                  <MenuItem
                    value={"identify_decision_makers"}
                    className="deal-select-item"
                  >
                    Identify Decision Makers
                  </MenuItem>
                  <MenuItem
                    value={"needs_analysis"}
                    className="deal-select-item"
                  >
                    Needs Analysis
                  </MenuItem>
                  <MenuItem
                    value={"value_proposition"}
                    className="deal-select-item"
                  >
                    Value Proposition
                  </MenuItem>
                  <MenuItem
                    value={"proposal_price_quote"}
                    className="deal-select-item"
                  >
                    Proposal/Price Quote
                  </MenuItem>
                  <MenuItem
                    value={"negotiation_review"}
                    className="deal-select-item"
                  >
                    Negotiation Review
                  </MenuItem>

                  <MenuItem value={"closed_won"} className="deal-select-item">
                    Closed Won
                  </MenuItem>

                  <MenuItem value={"closed_lost"} className="deal-select-item">
                    Closed Lost
                  </MenuItem>

                  <MenuItem
                    value={"closed_lost_to_competition"}
                    className="deal-select-item"
                  >
                    Closed-Lost to Competition
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" style={{ margin: "1em 0" }}>
              <DesktopDatePicker
                label="Expected Closing Date"
                inputFormat="dd/MM/yyyy"
                value={expectedClosingDate}
                onChange={(date) => setExpectedClosingDate(date)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    className="deals-datepicker"
                    {...params}
                  />
                )}
              />
            </Box>
            {!props.relatedTo && (
              <Box display="flex" flexDirection="column">
                <Box m={"1em 0"}>
                  <p>Related to: </p>
                </Box>
                <Box display="flex">
                  <Box display="flex" alignItems="flex-end">
                    <FormControl style={{ minWidth: "100px" }}>
                      <InputLabel
                        id="entity-select-label"
                        className="task-select-label"
                      >
                        Entity
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="entity-select-label"
                        id="entity-select"
                        value={relatedToEntity}
                        label="Entity"
                        onChange={(e) => {
                          setRelatedTo(null);
                          setRelatedToEntity(e.target.value);
                        }}
                        className="deal-select"
                      >
                        <MenuItem value="contact" className="deal-select-item">
                          Contact
                        </MenuItem>
                        <MenuItem value="account" className="deal-select-item">
                          Account
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box style={{ marginLeft: 5 }}>
                    {elasticToken &&
                      (relatedToEntity === "contact" ? (
                        <ElasticContactAutocomplete
                          setRelatedTo={setRelatedTo}
                          apiKey={elasticToken}
                          className={"elastic-view-deal-input"}
                        />
                      ) : (
                        relatedToEntity === "account" && (
                          <ElasticAccountAutocomplete
                            setRelatedTo={setRelatedTo}
                            apiKey={elasticToken}
                            className={"elastic-view-deal-input"}
                          />
                        )
                      ))}
                  </Box>
                </Box>
              </Box>
            )}

            <Box display="flex" my={3} flexDirection="flex-start">
              <button
                className="modal-button blue"
                onClick={() => addDeal()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "#fff" }}
                  ></CircularProgress>
                ) : (
                  "Save"
                )}
              </button>
              <button
                className="modal-button white ml"
                onClick={() => handleCloseAddDealDialog()}
              >
                Close
              </button>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
}
