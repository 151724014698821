import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import {
  Container,
  Grid,
  TextField,
  Box,
  AppBar,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { withStyles } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import Input from "@material-ui/core/Input";
import { getData, postData } from "../../utils";
import { Get } from "../../utils/axiosUtils";
import axios from "axios";
import CSVReader from "react-csv-reader";
import DomainVerification from "./DomainVerification";

import {
  storeUserSetting,
  getUserByUserId,
  setCalendars,
  setProductsAndCompetitors,
  unauthorizeZoom,
  unauthorizeMicrosoft,
  getUserBillingStatus,
  getOrgainzation,
} from "../../firebase/firestore";
import firebase from "../../firebase/firebase";
import Page from "../Page";
import SubscriptionPlans from "./subscriptionPlans";
import CRMSettings from "./CRMSettings";
import TopicLabels from "./TopicLabels";
import BusinessTopics from "./businessTopics";
import useAuth from "../../hooks/useAuth";
import SentimentSettings from "./SentimentSettings";
import MeetingAccessSettings from "./MeetingAccessSettings";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExtensionOffIcon from "@mui/icons-material/ExtensionOff";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";

const CustomCheckbox = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pl: {
    paddingLeft: "20px",
  },
  bg: {
    backgroundColor: "unset",
    boxShadow: "unset",
    fontWeight: "500",
  },
  mb20: {
    paddingTop: "20px",
  },
  radioColor: {
    color: "#2C73FF !important",
  },
  lableFont: {
    color: "#000",
    fontWeight: "600",
  },
  priceTxt: {
    color: "#2C73FF",
    fontSize: "22px",
  },
  disabledButton: {
    cursor: "not-allowed !important",
    pointerEvents: "all !important",
  },
}));

function Settings({ ...props }) {
  const classes = useStyles();
  const { fireStoreuserObj } = useAuth();
  const [value, setValue] = React.useState(0);
  const [notifications, setValueNotifications] = React.useState("");
  const [defalutBusinessTopics, setDefalutBusinessTopics] =
    React.useState(false);
  const [hasBilling, setHasBilling] = useState(true);
  const [zohoAccess, setZohoAccess] = useState(false);
  const [isZoomAuthenticated, setIsZoomAuthenticated] = useState(false);
  const [isMicrosoftAuthenticated, setIsMicrosoftAuthenticated] =
    useState(false);
  const [organizationDetails, setOrganizationDetails] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { code } = queryString.parse(props.location.search);

  const storeZohoToken = async (code, accountsServer, location) => {
    // console.log("fetch data");
    // let payload = { code: code };
    // let apiRes = await Get("ZohoAuth/ZohoCode", payload);
    // if (apiRes.status == 200 && apiRes.data.status == "success") {
    //   const { data, total } = apiRes.data;
    //   console.log(data, "contacts getting");
    //   console.log(data, "lool");
    // } else {
    //   // const { data, total } = apiRes.data;
    //   console.log("data not fetched");
    // }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let result = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/user/zoho/auth`,
          jwtToken,
          { code, location, accountsServer }
        );

        if (result.status == "success") {
          setZohoAccess(true);
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Your zoho account integrated successfully.",
          });
        } else {
          props.showSnackbar({
            show: true,
            severity: "error",
            message: "Something went wrong!",
          });
        }
      });
  };

  // useEffect(() => {
  //   FetchToken(code);
  // }, [props.location.search]);

  useEffect(async () => {
    let queryStringObj = queryString.parse(props.location.search);

    if (queryStringObj.from && queryStringObj.from == "zoho_auth") {
      await storeZohoToken(
        queryStringObj.code,
        queryStringObj["accounts-server"],
        queryStringObj.location
      );

      props.showSnackbar({
        show: true,
        severity: "success",
        message: "You have logged in successfully  ",
      });
      setValue(1);
    }

    const db = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;
    let user = await getUserByUserId(userId);

    let orgDetails = await getOrgainzation(user.organizationId);
    setOrganizationDetails(orgDetails);
    orgDetails.businessTopics
      ? setDefalutBusinessTopics(orgDetails.businessTopics)
      : setDefalutBusinessTopics([]);

    let billindFlags = await getUserBillingStatus();

    if (billindFlags.status == "success") {
      if (!billindFlags.billingAddress) {
        setHasBilling(false);
      }
    }

    // if (!user.billingAddress) {
    //   setHasBilling(false);
    // }

    if (user.zoho && user.zoho.accessValid) {
      setZohoAccess(true);
    }
    if (user.zoom) {
      setIsZoomAuthenticated(true);
    } else {
      setIsZoomAuthenticated(false);
    }
    if (user.microsoft) {
      setIsMicrosoftAuthenticated(true);
    } else {
      setIsMicrosoftAuthenticated(false);
    }

    let mSettingsDef = user.settings.join;
    setValueMeetingSettings(mSettingsDef);

    if (user.settings.calendars && Array.isArray(user.settings.calendars)) {
      setSelectedCalendars(user.settings.calendars.map((c) => c.calendarId));
    }

    if (
      orgDetails.matching?.products &&
      Array.isArray(orgDetails.matching?.products)
    ) {
      setProducts(orgDetails.matching.products);
    }

    if (
      orgDetails.matching?.competitors &&
      Array.isArray(orgDetails.matching?.competitors)
    ) {
      setCompetitors(orgDetails.matching.competitors);
    }

    if (
      orgDetails.matching?.customWords &&
      Array.isArray(orgDetails.matching?.customWords)
    ) {
      setCustomWords(orgDetails.matching.customWords);
    }

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function (jwtToken) {
        let userCalendars = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/calendar/listCalendars`,
          jwtToken
        );
        console.log("userCalendars", userCalendars);
        setTotalCalendars(userCalendars.data);
      });

    const handleChangeNotifications = (event) => {
      // console.log(event.target.value);
      let data = storeUserSetting(event.target.value, "notifications");
      setValueNotifications(event.target.value);
    };

    // let notifyDef = user.settings.preferences.notifications;
    // setValueNotifications(notifyDef);

    // let summariesDef = user.settings.preferences.summaries;
    // setValueSummaries(summariesDef);

    // let sharingDef = user.settings.preferences.sharing;
    // setValueSharing(sharingDef);
    let { match } = props;
    let { params } = match;
    let tabIndex = params.tabIndex ? params.tabIndex : 0;
    console.log("tabIndex", tabIndex);
    if (params.tabIndex == "myPlan") {
      setValue(2);
    } else {
      setValue(0);
    }
  }, []);

  const [mSettings, setValueMeetingSettings] = React.useState("");
  const handleChangeMeetingSettings = (event) => {
    const response = storeUserSetting(event.target.value, "meeting-settings");
    if (response) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      setValueMeetingSettings(event.target.value);
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong",
      });
    }
  };

  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [totalCalendars, setTotalCalendars] = useState([]);
  const [tempProduct, setTempProduct] = useState("");
  const [tempCompetitor, setTempCompetitor] = useState("");
  const [tempCustomWords, setTempCustomWords] = useState("");
  const [products, setProducts] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [customWords, setCustomWords] = useState([]);

  const addPorduct = async () => {
    let productArr = products;
    productArr.push(tempProduct);
    let res = await setProductsAndCompetitors(productArr, "products");
    if (res) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      setProducts(productArr);
      setTempProduct("");
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const addCompetitor = async () => {
    let competitorArr = competitors;
    competitorArr.push(tempCompetitor);

    let res = await setProductsAndCompetitors(competitorArr, "competitors");
    if (res) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      setCompetitors(competitorArr);
      setTempCompetitor("");
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const addCustomWord = async () => {
    let customWordsArr = customWords;
    customWordsArr.push(tempCustomWords);

    let res = await setProductsAndCompetitors(customWordsArr, "customWords");
    if (res) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      setCustomWords(customWordsArr);
      setTempCustomWords("");
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  function debounce(func) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 2000);
    };
  }

  // const ConnectToZoho=async ()=>{

  //   // let payload = { perPage: totalRecordsPerPage, page: currentPage };
  //   let responseCode=await axios.get('https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=1000.IW0ZA1MP1NN9Z419F13VXJDHD6OEZU&scope=ZohoCRM.settings.profiles.ALL&redirect_uri=http://localhost:3000/settings&access_type=offline&prompt=consent',{headers: {
  //     'Accept':'application/json',
  //     'Content-Type':'application/json'
  //   }});
  //   if(responseCode){
  //     console.log('responseCode',responseCode);
  //   }

  //   // let payload={}
  //   // let apiRes = await Get("ZohoAuth/ZohoCode", payload);
  //   // if (apiRes.status == 200 && apiRes.data.status == "success") {
  //   //   const { data, total } = apiRes.data;
  //   //   console.log(data,'contacts getting')
  //   //  console.log(data,'lool')
  //   // } else {
  //   //   // const { data, total } = apiRes.data;
  //   //   console.log('data not fetched')
  //   // }

  // }

  const handleChangeCalendar = async (e) => {
    setSelectedCalendars(e.target.value);
    let selectedC = totalCalendars.filter(
      (c) => e.target.value.indexOf(c.calendarId) > -1
    );

    //let result = await setCalendars(selectedC)

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let result = await postData(
          `${process.env.REACT_APP_BASE_API_URL}/user/settings/updateCalendars`,
          jwtToken,
          { calendars: selectedC }
        );

        if (result) {
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Your calendar preference updated successfully.",
          });
        } else {
          props.showSnackbar({
            show: true,
            severity: "error",
            message: "Something went wrong!",
          });
        }
      });
  };

  const handleDisconnectZoom = async (e) => {
    await unauthorizeZoom(firebase.auth().currentUser.uid);
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Zoom account has been unauthorized.",
    });
    setIsZoomAuthenticated(false);
  };
  const handleDisconnectMicrosoft = async (e) => {
    await unauthorizeMicrosoft(firebase.auth().currentUser.uid);
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Microsoft account has been unauthorized.",
    });
    setIsMicrosoftAuthenticated(false);
  };

  const debounceHandleChangeCalendar = React.useCallback(
    debounce(handleChangeCalendar),
    []
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Page>
      <Container maxWidth={"xl"}>
        <div className={classes.root}>
          <AppBar position="static" className={classes.bg}>
            <Tabs value={value} onChange={handleChange} className={classes.pl}>
              <Tab label="Preferences" {...a11yProps(0)} />
              {/* <Tab label="Organization" {...a11yProps(1)} /> */}
              <Tab label="Integrations" {...a11yProps(2)} />
              <Tab label="My Plan / Usage" {...a11yProps(1)} />

              {(process.env.REACT_APP_APP_ENV == "dev" || true) && (
                <Tab label="CRM Settings" {...a11yProps(3)} />
              )}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2} className={classes.mb20}>
              <Grid item xs={5} md={2}>
                <FormLabel component="legend" className={classes.lableFont}>
                  Meeting Settings
                </FormLabel>
              </Grid>
              <Grid item xs={7} md={10}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="meeting-settings"
                    name="meeting-settings"
                    value={mSettings}
                    onChange={handleChangeMeetingSettings}
                  >
                    <FormControlLabel
                      value="ALL"
                      control={<Radio className={classes.radioColor} />}
                      label="Join All Meetings"
                      id="settings-join-all"
                    />
                    <FormControlLabel
                      value="OWNED"
                      control={<Radio className={classes.radioColor} />}
                      label="Join Meetings I Own"
                      id="settings-join-owned"
                    />
                    <FormControlLabel
                      value="ON_DEMAND"
                      control={<Radio className={classes.radioColor} />}
                      label="On Demand"
                      id="settings-join-ondemand"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <MeetingAccessSettings
              role={fireStoreuserObj.role ? fireStoreuserObj.role : "MEMBER"}
              showSnackbar={props.showSnackbar}
            />

            {totalCalendars && (
              <Grid container spacing={2} className={classes.mb20}>
                <Grid item xs={5} md={2}>
                  <FormLabel component="legend" className={classes.lableFont}>
                    Calendars
                  </FormLabel>
                </Grid>
                <Grid item xs={7} md={10}>
                  <FormControl component="fieldset" style={{ width: "200px" }}>
                    <Select
                      multiple
                      value={selectedCalendars}
                      onChange={handleChangeCalendar}
                      input={<Input />}
                      renderValue={(selected) => {
                        let r = "";
                        selected.forEach((s) => {
                          let thisCalendar = totalCalendars.filter(
                            (c) => s == c.calendarId
                          );
                          let title = thisCalendar.length
                            ? thisCalendar[0].summary
                            : "";
                          r = r.length ? r + ", " + title : r + title;
                        });
                        return r;
                      }}
                      MenuProps={MenuProps}
                    >
                      {totalCalendars.map((calendar) => {
                        return (
                          <MenuItem
                            key={calendar.calendarId}
                            value={calendar.calendarId}
                          >
                            <CustomCheckbox
                              checked={
                                selectedCalendars.indexOf(calendar.calendarId) >
                                -1
                              }
                            />
                            <ListItemText primary={calendar.summary} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {/* custom words setting */}
            <Grid
              container
              style={{ marginTop: "20px" }}
              className={classes.mb20}
            >
              <Grid item xs={5}>
                <FormLabel component="legend" className={classes.lableFont}>
                  Custom Words
                </FormLabel>
                <Typography variant="caption">
                  Add the custom words to be matched with the transcript.
                </Typography>
              </Grid>
              <Box width={{ md: "240px", xs: "160px" }}>
                <TextField
                  //style={{ width: "100%" }}

                  placeholder="Add Custom words"
                  inputProps={{ "aria-label": "Add Custom words" }}
                  value={tempCustomWords}
                  onChange={(e) => setTempCustomWords(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (
                        !customWords.includes(tempCustomWords) &&
                        tempCustomWords.length
                      ) {
                        addCustomWord();
                      }
                    }
                  }}
                />
              </Box>

              <Box
                display={{
                  xs: "block",
                  md: "none",
                  marginTop: "12px",
                  marginLeft: "5px",
                }}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  onClick={() => {
                    if (
                      !customWords.includes(tempCustomWords) &&
                      tempCustomWords.length
                    ) {
                      addCustomWord();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>

              <Box marginLeft="20px">
                or
                <a
                  style={{ paddingLeft: "5px", textDecoration: "none" }}
                  href="/example-custom-words.csv"
                >
                  ( Example CSV for custom words )
                </a>
                <CSVReader
                  label="Select CSV file for custom words: "
                  onFileLoaded={async (data, fileInfo, originalFile) => {
                    let existingCustomWords = [];
                    customWords.map((c) => existingCustomWords.push(c));
                    data.map((d) => {
                      if (!customWords.includes(d[0]))
                        existingCustomWords.push(d[0]);
                    });

                    let res = await setProductsAndCompetitors(
                      existingCustomWords,
                      "customWords"
                    );
                    if (res) {
                      setCustomWords(existingCustomWords);
                      props.showSnackbar({
                        show: true,
                        severity: "success",
                        message: "Setting updated successfully",
                      });
                    } else {
                      props.showSnackbar({
                        show: true,
                        severity: "error",
                        message: "Something went wrong!",
                      });
                    }

                    document.getElementById("react-csv-reader-input").value =
                      "";
                  }}
                />
              </Box>
              <div
                style={{
                  paddingTop: "10px",
                  paddingLeft: "10px",
                  maxHeight: "100px",
                  overflowY: "scroll",
                }}
              >
                {customWords.map((p, index) => {
                  return (
                    <Chip
                      key={index}
                      label={p}
                      style={{ marginLeft: "5px" }}
                      onDelete={async () => {
                        let newArr = customWords.filter(
                          (customWord) => customWord != p
                        );

                        let res = await setProductsAndCompetitors(
                          newArr,
                          "customWords"
                        );
                        if (res) {
                          props.showSnackbar({
                            show: true,
                            severity: "success",
                            message: "Setting updated successfully",
                          });
                          setCustomWords(newArr);
                        } else {
                          props.showSnackbar({
                            show: true,
                            severity: "error",
                            message: "Something went wrong!",
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </Grid>

            {/* Competitors preference setting */}
            <Grid
              container
              style={{ marginTop: "20px" }}
              className={classes.mb20}
            >
              <Grid item xs={5}>
                <FormLabel component="legend" className={classes.lableFont}>
                  Products/Services
                </FormLabel>
                <Typography variant="caption">
                  Add the products and services to be matched with the
                  transcript.
                </Typography>
              </Grid>
              <Box width={{ md: "240px", xs: "160px" }}>
                <TextField
                  //style={{ width: "100%" }}

                  placeholder="Add Products/Services"
                  inputProps={{ "aria-label": "Add Products/Services" }}
                  value={tempProduct}
                  onChange={(e) => setTempProduct(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (
                        !products.includes(tempProduct) &&
                        tempProduct.length
                      ) {
                        addPorduct();
                      }
                    }
                  }}
                />
              </Box>

              <Box
                display={{
                  xs: "block",
                  md: "none",
                  marginTop: "12px",
                  marginLeft: "5px",
                }}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  onClick={() => {
                    if (!products.includes(tempProduct) && tempProduct.length) {
                      addPorduct();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                {products.map((p, index) => {
                  return (
                    <Chip
                      key={index}
                      label={p}
                      style={{ marginLeft: "5px" }}
                      onDelete={async () => {
                        let newArr = products.filter((product) => product != p);

                        let res = await setProductsAndCompetitors(
                          newArr,
                          "products"
                        );
                        if (res) {
                          props.showSnackbar({
                            show: true,
                            severity: "success",
                            message: "Setting updated successfully",
                          });
                          setProducts(newArr);
                        } else {
                          props.showSnackbar({
                            show: true,
                            severity: "error",
                            message: "Something went wrong!",
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </Grid>
            <Grid
              container
              style={{ marginTop: "20px" }}
              className={classes.mb20}
            >
              <Grid item xs={5}>
                <FormLabel component="legend" className={classes.lableFont}>
                  Competitors
                </FormLabel>
                <Typography variant="caption">
                  Add the Competitors to be matched with the transcript.
                </Typography>
              </Grid>
              <Box width={{ md: "240px", xs: "160px" }}>
                <TextField
                  placeholder="Add Competitors"
                  inputProps={{ "aria-label": "Add Competitors" }}
                  value={tempCompetitor}
                  onChange={(e) => setTempCompetitor(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (
                        !competitors.includes(tempCompetitor) &&
                        tempCompetitor.length
                      ) {
                        addCompetitor();
                      }
                    }
                  }}
                />
              </Box>

              <Box
                display={{
                  xs: "block",
                  md: "none",
                  marginTop: "12px",
                  marginLeft: "5px",
                }}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  onClick={() => {
                    if (
                      !competitors.includes(tempCompetitor) &&
                      tempCompetitor.length
                    ) {
                      addCompetitor();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>

              <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                {competitors.map((c, index) => {
                  return (
                    <Chip
                      key={index}
                      label={c}
                      style={{ marginLeft: "5px" }}
                      onDelete={async () => {
                        let newArr = competitors.filter(
                          (competitor) => competitor != c
                        );
                        let res = await setProductsAndCompetitors(
                          newArr,
                          "competitors"
                        );

                        if (res) {
                          props.showSnackbar({
                            show: true,
                            severity: "success",
                            message: "Setting updated successfully",
                          });
                          setCompetitors(newArr);
                        } else {
                          props.showSnackbar({
                            show: true,
                            severity: "error",
                            message: "Something went wrong!",
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </Grid>
            {/*topic labels */}
            <TopicLabels />
            <BusinessTopics defalutBusinessTopics={defalutBusinessTopics} />
            {fireStoreuserObj?.role === "ADMIN" && organizationDetails && (
              <SentimentSettings organizationDetails={organizationDetails} />
            )}
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            Organization
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <Box display={"flex"} flexDirection="column">
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
                borderRadius="10px"
              >
                <Box
                  backgroundColor="#fff"
                  borderRadius="10px"
                  padding="20px"
                  width="230px"
                  height="260px"
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  style={{ cursor: "pointer" }}
                  sx={{ mr: 2 }}
                  title={
                    zohoAccess ? "Connected with zoho" : "Connect with Zoho"
                  }
                  onClick={() => {
                    if (!zohoAccess) {
                      window.open(process.env.REACT_APP_ZOHO_REDIRECT_URL);
                    }
                  }}
                >
                  <Box>
                    <Box
                      height="120px"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img src="/images/zohologo.png" width="100" height="75" />
                    </Box>
                    <p style={{ textAlign: "center" }}>Zoho</p>
                    <p style={{ textAlign: "center", margin: "10px 0" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(231, 231, 231)",
                          width: "50px",
                          padding: "2px 5px",
                          borderRadius: "10px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#808080",
                        }}
                      >
                        CRM
                      </span>
                    </p>
                  </Box>
                  <Box
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {zohoAccess ? (
                      <>
                        <CheckCircleOutlineIcon
                          style={{
                            color: "green",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <span>Connected with Zoho</span>
                      </>
                    ) : (
                      "Connect with Zoho"
                    )}
                  </Box>
                </Box>
                <Box
                  backgroundColor="#fff"
                  borderRadius="10px"
                  padding="20px"
                  width="230px"
                  height="260px"
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (!isZoomAuthenticated) {
                      window.location = `https://zoom.us/oauth/authorize?client_id=${
                        process.env.REACT_APP_ZOOM_CLIENT_ID
                      }&response_type=code&redirect_uri=${
                        process.env.REACT_APP_ZOOM_REDIRECT_URL +
                        "&state=" +
                        firebase.auth().currentUser.uid
                      }`;
                    }
                  }}
                  style={{ cursor: "pointer", position: "relative" }}
                  title={
                    isZoomAuthenticated
                      ? "Connected with Zoom"
                      : "Connect with Zoom"
                  }
                >
                  {isZoomAuthenticated && (
                    <Box
                      onClick={handleDisconnectZoom}
                      className={"disconnect-button"}
                    >
                      <ExtensionOffIcon sx={{ fontSize: 16, color: "#fff" }} />
                      <span>Disconnect</span>
                    </Box>
                  )}
                  <Box>
                    <Box
                      height="120px"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img src="/images/zoom-logo.png" width="50" height="50" />
                    </Box>
                    <p style={{ textAlign: "center" }}>Zoom</p>
                    <p style={{ textAlign: "center", margin: "10px 0" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(231, 231, 231)",
                          width: "50px",
                          padding: "2px 5px",
                          borderRadius: "10px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#808080",
                        }}
                      >
                        Meetings
                      </span>
                    </p>
                  </Box>

                  <Box
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {isZoomAuthenticated ? (
                      <>
                        <CheckCircleOutlineIcon
                          style={{
                            color: "green",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <span>Connected with Zoom</span>
                      </>
                    ) : (
                      "Connect with Zoom"
                    )}
                  </Box>
                </Box>
                <Box
                  backgroundColor="#fff"
                  borderRadius="6px"
                  padding="20px"
                  width="230px"
                  height="260px"
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (!isMicrosoftAuthenticated) {
                      const authUrl =
                        "https://login.microsoftonline.com/common" +
                        "/oauth2/v2.0/authorize?client_id=" +
                        process.env.REACT_APP_MICROSOFT_CLIENT_ID +
                        "&response_type=code&redirect_uri=" +
                        process.env.REACT_APP_MICROSOFT_REDIRECT_URL +
                        "&response_mode=query&scope=offline_access user.read calendars.readwrite OnlineMeetings.ReadWrite&state=" +
                        firebase.auth().currentUser.uid;
                      window.location = authUrl;
                    }
                  }}
                  style={{ cursor: "pointer", position: "relative" }}
                  title={
                    isMicrosoftAuthenticated
                      ? "Connected with Microsoft"
                      : "Connect with Microsoft"
                  }
                >
                  {isMicrosoftAuthenticated && (
                    <Box
                      onClick={handleDisconnectMicrosoft}
                      className={"disconnect-button"}
                    >
                      <ExtensionOffIcon sx={{ fontSize: 16, color: "#fff" }} />
                      <span>Disconnect</span>
                    </Box>
                  )}
                  <Box>
                    <Box
                      height="120px"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img
                        src="/images/ms-teams-logo.png"
                        width="50"
                        height="50"
                      />
                    </Box>
                    <p style={{ textAlign: "center" }}>Microsoft</p>
                    <p style={{ textAlign: "center", margin: "10px 0" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(231, 231, 231)",
                          width: "50px",
                          padding: "2px 5px",
                          borderRadius: "6px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#808080",
                        }}
                      >
                        Calendar
                      </span>
                    </p>
                  </Box>
                  <Box
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {isMicrosoftAuthenticated ? (
                      <>
                        <CheckCircleOutlineIcon
                          style={{
                            color: "green",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <span>Connected with Microsoft</span>
                      </>
                    ) : (
                      "Connect Microsoft account"
                    )}
                  </Box>
                </Box>

                <Box
                  backgroundColor="#fff"
                  borderRadius="6px"
                  padding="20px"
                  width="230px"
                  height="260px"
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  sx={{ mr: 2 }}
                  onClick={() => {
                      const authUrl =
                        process.env.REACT_APP_RINGCENTRAL_AUTH_URL + firebase.auth().currentUser.uid;                        
                      window.location = authUrl;
                  }}
                  style={{ cursor: "pointer", position: "relative" }}
                  title="Connect to RingCentral"
                >
                  <Box>
                    <Box
                      height="120px"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img
                        src="/images/ring-central.png"
                        width="50"
                        height="50"
                      />
                    </Box>
                    <p style={{ textAlign: "center" }}>RingCentral</p>
                    <p style={{ textAlign: "center", margin: "10px 0" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(231, 231, 231)",
                          width: "50px",
                          padding: "2px 5px",
                          borderRadius: "6px",
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#808080",
                        }}
                      >
                        Telephony
                      </span>
                    </p>
                  </Box>
                  <Box
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                  </Box>
                </Box>









                <DomainVerification />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2} id="subscriptionPlans">
            <SubscriptionPlans
              showSnackbar={props.showSnackbar}
              hasBilling={hasBilling}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <CRMSettings />
          </TabPanel>
        </div>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
