import React from "react";
import { useHistory } from "react-router";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import crownIcon from "../../assets/images/crownIcon.png";
import HandshakeIcon from "../../assets/images/handshake.png";
import Popover from "@mui/material/Popover";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Diff from "react-stylable-diff";

function PolygonChartScoreTable({
  teamworkScore,
  leaderScore,
  cultureScore,
  cultureDetails,
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <table className="tasks-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th style={{ minWidth: "35px" }}></th>
          <th style={{ paddingLeft: ".5em" }}></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ cursor: "pointer", display: "flex" }}>
            <SentimentSatisfiedAltIcon
              sx={{ fontSize: 22, color: "#2c73ff" }}
            />
          </td>
          <td>
            <span className="polygon-table-label">Inclusivity</span>
          </td>
          <td>
            <div className="culture-level-cont">
              <div
                className="level"
                style={{
                  width: cultureScore > 0 ? `${cultureScore * 20}%` : 0,
                }}
              />
            </div>
          </td>
          <td>
            <div
              className="culture-graph-review"
              onMouseEnter={handleClick}
              style={{ paddingLeft: ".5em" }}
            >
              Review
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{
                onMouseLeave: handleClose,
                style: { borderRadius: ".5rem" },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="culture-popover-container">
                {!cultureDetails?.length ? (
                  <span>No suggestions to display.</span>
                ) : (
                  cultureDetails.map((item, index) => (
                    <div
                      className="corrections"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/meeting/${item.meeting}`)}
                      key={index}
                    >
                      <span className="sentence">
                        <Diff
                          inputA={item.sentence}
                          inputB={item.suggestion}
                          type="words"
                        />
                      </span>
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: 24, color: "#2c73ff" }}
                      />
                      <span className="suggestion">
                        <Diff
                          inputA={item.sentence}
                          inputB={item.suggestion}
                          type="words"
                        />
                      </span>
                    </div>
                  ))
                )}
              </div>
            </Popover>
          </td>
        </tr>
        <tr>
          <td style={{ cursor: "pointer" }}>
            <img
              src={crownIcon}
              alt="crown"
              style={{ width: 24, height: 24 }}
            />
          </td>
          <td>
            <span className="polygon-table-label">Leadership</span>
          </td>
          <td>
            <div className="culture-level-cont">
              <div
                className="level"
                style={{ width: leaderScore > 0 ? `${leaderScore * 20}%` : 0 }}
              />
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td style={{ cursor: "pointer" }}>
            <img
              src={HandshakeIcon}
              alt="handshake"
              style={{ width: 24, height: 24 }}
            />
          </td>
          <td>
            <span className="polygon-table-label">Teamwork</span>
          </td>
          <td>
            <div className="culture-level-cont">
              <div
                className="level"
                style={{
                  width: teamworkScore > 0 ? `${teamworkScore * 20}%` : 0,
                }}
              />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
}

export default PolygonChartScoreTable;
