import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress } from "@material-ui/core";
import { dateDifference } from "../../utils/dateDifference";
import TextEditor from "../TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import useAuth from "../../hooks/useAuth";
import { Delete, Patch } from "../../utils/axiosUtils";
import { isImage } from "../../utils/isImage";
import { updateTask } from "../../firebase/firestore";

function EditShowComment({
  comment,
  comments,
  setComments,
  attachments,
  setAttachments,
  suggestions,
  updateTaskCommentsAndAttachmentsCount,
}) {
  const { fireStoreuserObj } = useAuth();
  const [commentEditorState, setCommentEditorState] = useState(
    EditorState.createEmpty()
  );
  const [editComment, setEditComment] = useState(false);
  const [updateCommentLoading, setUpdateCommentLoading] = useState(false);
  const [pendingAttachments, setPendingAttachments] = useState([]);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);

  const generateEditorState = () => {
    setEditorLoading(true);
    try {
      setCommentEditorState(
        EditorState.createWithContent(convertFromRaw(comment.content))
      );
      setEditorLoading(false);
    } catch (e) {
      setCommentEditorState(EditorState.createEmpty());
      setEditorLoading(false);
    }
  };

  useEffect(generateEditorState, []);

  const handleUpdateComment = () => {
    setUpdateCommentLoading(true);
    Patch(`comments/update/${comment.id}`, {
      content: convertToRaw(commentEditorState.getCurrentContent()),
      title: "task_comments",
    })
      .then(({ data }) => {
        let Index = comments.findIndex((item) => item.id === comment.id);
        let newComments = [...comments];
        newComments[Index].content = data.data.content;
        setComments(newComments);
        setUpdateCommentLoading(false);
        setEditComment(false);
        let allEntities = Object.values(
          convertToRaw(commentEditorState.getCurrentContent()).entityMap
        );
        let imageEntities = allEntities
          .filter((item) => item.type === "IMAGE")
          .map((item) => item.data.id);

        let imageAttachments = [];
        attachments
          .filter((item) => item.commentId === comment.id)
          .map((attachment) => {
            if (isImage(attachment.fileName)) {
              imageAttachments.push(attachment);
            }
          });
        if (imageAttachments.length !== imageEntities.length) {
          let willDeleteAttachments = imageAttachments
            .filter((item) => !imageEntities.includes(item.id))
            .map((item) => item.id);
          let newAttachments = [...attachments, ...pendingAttachments];
          newAttachments = newAttachments.filter(
            (item) => !willDeleteAttachments.includes(item.id)
          );
          willDeleteAttachments.map((deleteAttachmentId) => {
            Delete(`attachments/${deleteAttachmentId}`).catch((err) =>
              console.log(err)
            );
          });
          setAttachments(newAttachments);
        } else {
          setAttachments([...attachments, ...pendingAttachments]);
        }
        setPendingAttachments([]);
      })
      .then(() => updateTaskCommentsAndAttachmentsCount())
      .catch((err) => {
        setUpdateCommentLoading(false);
        console.log(err);
      });
  };

  const handleEditComment = (comment) => {
    if (!comment?.content) {
      return;
    }
    try {
      const rawData = convertFromRaw(comment.content);
      setCommentEditorState(EditorState.createWithContent(rawData));
    } catch (e) {
      setCommentEditorState(EditorState.createEmpty());
    }
    setEditComment(true);
  };

  const handleDeleteComment = (commentId) => {
    setDeleteCommentLoading(true);
    Delete(`comments/${commentId}`)
      .then(() => {
        let filterAttachments = attachments.filter(
          (item) => item.commentId !== commentId
        );
        let newComments = comments.filter((item) => item.id !== commentId);
        setComments(newComments);
        setAttachments(filterAttachments);
        setDeleteCommentLoading(false);
      })
      .then(() => updateTaskCommentsAndAttachmentsCount())
      .catch((err) => {
        console.log(err, "error happen when deleting comment");
        setDeleteCommentLoading(false);
      });
  };

  return (
    <Box display="flex" alignItems="flex-start" width="100%" marginBottom={2}>
      <Box
        sx={{
          height: 43,
          minWidth: 43,
          maxWidth: 43,
          mr: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={comment?.user?.photo}
          sx={{
            height: 35,
            width: 35,
            border: "1px solid #2C73FF",
          }}
        />
      </Box>
      <div style={{ width: "100%" }}>
        <Box display="flex" alignItems="flex-end">
          <span className="comment-username">{comment?.user?.name}</span>
          <span className="comment-date">
            {dateDifference(comment?.createdAt)}
          </span>
        </Box>

        {!editorLoading && (
          <TextEditor
            editorState={commentEditorState}
            onEditorStateChange={setCommentEditorState}
            readOnly={!editComment}
            toolbarHidden={!editComment}
            isBorder={editComment}
            isButtons
            buttonTitle={"Save"}
            handleButton={handleUpdateComment}
            handleCancelButton={() => {
              setEditComment(false);
              generateEditorState();
            }}
            buttonLoading={updateCommentLoading}
            cancelButtonTitle={"Cancel"}
            mentions={suggestions}
            className={editComment ? "task-editor comment" : ""}
            handlePastedFiles={() => {}}
          />
        )}
        {comment?.user?.email === fireStoreuserObj.email && (
          <Box display="flex">
            <button
              className="task-comment-edit"
              onClick={() => handleEditComment(comment)}
            >
              Edit
            </button>
            <button
              className="task-comment-edit ml"
              onClick={() => handleDeleteComment(comment.id)}
            >
              {deleteCommentLoading ? (
                <CircularProgress size="15px" style={{ color: "#404040" }} />
              ) : (
                "Delete"
              )}
            </button>
          </Box>
        )}
      </div>
    </Box>
  );
}

export default EditShowComment;
