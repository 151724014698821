import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CircularProgress, Drawer, Grid } from "@material-ui/core";
import { Get } from "../../../utils/axiosUtils";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";

function NewsDrawer({ closeDrawer, searchKey }) {
  const [loading, setLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [newsDrawerWith, setNewsDrawerWidth] = useState(434);

  const applyFullWidth = () => {
    if (newsDrawerWith !== 434) {
      setNewsDrawerWidth(434);
    } else {
      requestAnimationFrame(() => {
        setNewsDrawerWidth(window.innerWidth - 100);
      });
    }
  };

  const searchNews = () => {
    if (!searchKey) {
      setLoading(false);
      return;
    }
    Get(`news/everything?q="${searchKey}"`)
      .then(({ data }) => {
        setLoading(false);
        console.log(data);
        setNewsData(data?.data?.articles);
      })
      .catch((err) => console.log(err, "error happen when getting news"));
  };

  useEffect(searchNews, [searchKey]);

  return (
    <Drawer anchor="right" open={true} onClose={closeDrawer}>
      <Box
        width={newsDrawerWith}
        style={{ transition: "all 0.5s", height: "100%" }}
      >
        <Box padding={2} height={"100%"}>
          {newsDrawerWith === 434 ? (
            <ChevronLeftIcon
              style={{
                fontSize: "36px",
                color: "#2c73ff",
                cursor: "pointer",
              }}
              onClick={applyFullWidth}
            />
          ) : (
            <ChevronRightIcon
              style={{
                fontSize: "36px",
                color: "#2c73ff",
                cursor: "pointer",
              }}
              onClick={applyFullWidth}
            />
          )}
          {loading ? (
            <Box display="flex" width={"100%"} height={"100%"}>
              <Box margin="auto">
                <CircularProgress style={{ color: "#2c73ff" }} size="40px" />
              </Box>
            </Box>
          ) : newsData?.length === 0 ? (
            <Box display="flex" width={"100%"} height={"100%"}>
              <Box margin="auto">
                <span style={{ color: "#2c73ff" }}>
                  There is not any latest news.
                </span>
              </Box>
            </Box>
          ) : (
            <Grid container spacing={3}>
              {newsData?.map((news) => (
                <Grid
                  item
                  xs={12}
                  md={newsDrawerWith === 434 ? 12 : 3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "inherit",
                  }}
                >
                  <div className="news-container">
                    <img
                      src={news?.urlToImage}
                      alt={"news"}
                      onClick={() => window.open(news?.url, "_blank")}
                    />
                    <div className="news-context">
                      <div>
                        <h6
                          className="news-title"
                          onClick={() => window.open(news?.url, "_blank")}
                        >
                          {news?.title}
                        </h6>
                        <span>{news.description}</span>
                      </div>
                      <div className="information">
                        <div className="author-container">
                          <PersonIcon
                            style={{
                              fontSize: "24px",
                              color: "#707070",
                              marginRight: ".5em",
                            }}
                          />
                          <div className="author-text">
                            <h6>{news?.author}</h6>
                            <span>{news?.source?.name}</span>
                          </div>
                        </div>
                        <div style={{ maxWidth: "40%" }}>
                          {moment(new Date(news?.publishedAt)).format("lll")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default NewsDrawer;
