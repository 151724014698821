import React, { useEffect, useState } from "react";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import { Bar } from "react-chartjs-2";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Box, CircularProgress } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TopicsLineChart({ fetchData, fetchLoading, dateRangeHandler }) {
  const { fireStoreuserObj } = useAuth();
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [yMax, setYMax] = useState(7);

  useEffect(() => {
    setLoading(true);
    if(!fetchData || fetchData?.length === 0) {
      setLoading(false);
      setIsParentDataShouldChange(false);
      return;
    }
    getOrgainzation(fireStoreuserObj.organizationId)
      .then((organization) => {
        let userTopics = organization.businessTopics;
        let meetingsInDateRange = fetchData.filter(
          (item) =>
            item.startTime * 1000 >
            new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000
        );
        let allMeetingsWithTopics = meetingsInDateRange.filter(
          (item) => item?.topicSentiments?.length > 0
        );
        let countsByMeeting = [];
        allMeetingsWithTopics.map((item) => {
          let businessTopicsCount = 0;
          let otherTopicsCount = 0;
          item.topicSentiments.map((topic) => {
            if (userTopics.indexOf(topic.labelName) > -1) {
              businessTopicsCount += topic.count;
            } else {
              otherTopicsCount += topic.count;
            }
          });
          let checkMeetingInSameDay = countsByMeeting.find(
            (meeting) =>
              meeting.meetingDate ===
              moment(item.startTime * 1000).format("D MMM")
          );
          if (checkMeetingInSameDay) {
            checkMeetingInSameDay.businessTopicsCount += businessTopicsCount;
            checkMeetingInSameDay.otherTopicsCount += otherTopicsCount;
          } else {
            countsByMeeting.push({
              meetingDate: moment(item.startTime * 1000).format("D MMM"),
              businessTopicsCount,
              otherTopicsCount,
            });
          }
        });
        setChartData(countsByMeeting);

        // yMax
        let newYMax = 7;
        countsByMeeting.map((item) => {
          if (item.businessTopicsCount * 1.2 > newYMax) {
            newYMax = item.businessTopicsCount * 1.2;
          }
          if (item.otherTopicsCount * 1.2 > newYMax) {
            newYMax = item.otherTopicsCount * 1.2;
          }
        });
        if (newYMax > 7) {
          setYMax(newYMax);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
    setIsParentDataShouldChange(false);
  }, [isParentDataShouldChange, fetchData]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        //intersect: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
        position: "average",
        backgroundColor: "#edf3ff",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    scales: {
      y: {
        max: yMax,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: chartData.map((item) => item.meetingDate),
    datasets: [
      {
        label: "Business topics",
        data: chartData.map((item) => item.businessTopicsCount),
        backgroundColor: "#2C73FF",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 12,
      },
      {
        label: "Other topics",
        data: chartData.map((item) => item.otherTopicsCount),
        backgroundColor: "#7DA8FB",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 12,
      },
    ],
  };

  return (
    <div className="chart" style={{ position: "relative" }}>
      <div className="chart-header">
        <h6>Business topics vs Other</h6>
        <DateRangeDropdown
          selected={dateRange}
          setDateRange={(item) => {
            setDateRange(item);
            if (dateRangeHandler(item)) {
              setLoading(true);
            } else {
              setIsParentDataShouldChange(true);
            }
          }}
        />
      </div>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ height: "130px", minHeight: "130px" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <Bar options={options} height={130} data={data} />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div className="compare-chart-info">
          <div className="dot blue" />
          Business topics
        </div>

        <div className="compare-chart-info">
          <div className="dot light-blue" />
          Other topics
        </div>
      </Box>
    </div>
  );
}

export default TopicsLineChart;
