import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";

function HybridMeetingPrompt({
  handleChangeHybrid,
  handleClose,
  hybridLoading,
}) {
  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={true}>
      <DialogTitle id="responsive-dialog-title" sx={{ marginBottom: "2em" }}>
        {"Do you want to change meeting as hybrid ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please note your meeting data including transcript, summary and key
          takeaways will be regenerated. <br />
          The outcomes may be different.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button className="modal-button white" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="modal-button blue ml"
          disabled={hybridLoading}
          onClick={handleChangeHybrid}
        >
          {hybridLoading ? (
            <CircularProgress
              size="15px"
              style={{ color: "#fff", marginRight: ".5em" }}
            />
          ) : (
            "Continue"
          )}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default HybridMeetingPrompt;
