export const AccountFieldsArray = [
  {
    title: "Account Owner",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [],
  },
  {
    title: "Name",
    type: "text",
    isRequired: true,
    isDefault: true,
    showColumn: true,
    validation: [
      {
        maxLength: 80,
      },
    ],
  },
  {
    title: "Website",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: true,
  },
  {
    title: "Account Type",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: true,
    options: [
      {
        title: "Analyst",
      },
      {
        title: "Competitor",
      },
      {
        title: "Customer",
      },
      {
        title: "Distributor",
      },
      {
        title: "Integrator",
      },
      {
        title: "Investor",
      },
      {
        title: "Other",
      },
      {
        title: "Partner",
      },
      {
        title: "Press",
      },
      {
        title: "Prospect",
      },
      {
        title: "Reseller",
      },
      {
        title: "Supplier",
      },
      {
        title: "Vendor",
      },
    ],
  },
  {
    title: "Annual Revenue",
    type: "text",
    isRequired: true,
    isDefault: true,
    showColumn: true,
    validation: [
      {
        maxLength: 100,
      },
    ],
  },
  {
    title: "No. Of employee",
    type: "select",
    isRequired: false,
    isDefault: true,
    options: [
      {
        title: "1-10 People",
      },
      {
        title: "11-50 People",
      },
      {
        title: "51-100 People",
      },
      {
        title: "100+ People",
      },
    ],
  },
  {
    title: "Phone",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: true,
    validation: {
      maxLength: "100",
      email: true,
    },
  },
  {
    title: "Billing Street",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: {
      maxLength: "100",
      email: true,
    },
  },
  {
    title: "Billing City",
    type: "text",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    validation: {
      maxLength: "50",
      email: true,
    },
  },
  {
    title: "Billing State",
    type: "text",
    isRequired: false,
    isDefault: true,
  },
  {
    title: "Billing Code",
    type: "text",
    isRequired: false,
    isDefault: true,
  },
  {
    title: "Billing Country",
    type: "text",
    isRequired: false,
    isDefault: true,
  },
  {
    title: "Fax",
    type: "text",
    isRequired: false,
    isDefault: true,
    validation: {
      maxLength: "30",
    },
  },

  {
    title: "Industry",
    type: "select",
    isRequired: false,
    isDefault: true,
    showColumn: false,
    options: [
      {
        title: "ASP (Application Service Provider)",
      },
      {
        title: "Data/Telecom OEM",
      },
      {
        title: "ERP (Enterprise Resource Planning)",
      },
      {
        title: "Government/Military",
      },
      {
        title: "Large Enterprise",
      },
      {
        title: "ManagementISV",
      },
      {
        title: "MSP (Management Service Provider)",
      },
      {
        title: "Network Equipment Enterprise",
      },
      {
        title: "Non-management ISV",
      },
      {
        title: "Optical Networking",
      },
      {
        title: "Service Provider",
      },
      {
        title: "Small/Medium Enterprise",
      },
      {
        title: "Storage Equipment",
      },
      {
        title: "Storage Service Provider",
      },
      {
        title: "Systems Integrator",
      },
      {
        title: "Wireless Industry",
      },
      {
        title: "Communications",
      },
      {
        title: "Consulting",
      },
      {
        title: "Education",
      },
      {
        title: "Financial Services",
      },
      {
        title: "Manufacturing",
      },
      {
        title: "Real Estate",
      },
      {
        title: "Technology",
      },
    ],
  },

  {
    title: "Description",
    type: "multi-line-text",
    isRequired: false,
    isDefault: true,
  },
];
