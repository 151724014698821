import firebase from "./firebase";
import { firestore } from "firebase/firestore";
import { _log, getData, postData } from "../utils";
import { Get } from "../utils/axiosUtils";
import { v4 as uuidv4 } from "uuid";
import { documentId } from "firebase/firestore";
import { LeadFieldsArray } from "./../components/settings/CRMSettings/LeadFieldsArray";
import { ContactFieldsArray } from "./../components/settings/CRMSettings/ContactFieldsArray";
import { AccountFieldsArray } from "./../components/settings/CRMSettings/AccountFieldsArray";

import moment from "moment";
const db = firebase.firestore();

const DBusers = db.collection("users");
const DBorganizations = db.collection("organizations");
const transcription = db.collection("transcription");
const summaryActions = db.collection("summary");
export const summaryStore = db.collection("summaries");
export const notesStore = db.collection("notes");
export const DBmeeting = db.collection("meetings");
export const DBcriticalNotifications = db.collection("criticalNotifications");
export const LeadCollection = db.collection("leads");
export const ContactCollection = db.collection("contacts");
export const AccountCollection = db.collection("accounts");

const TaskCollection = db.collection("tasks");
const DealCollection = db.collection("deals");

export const meetingsRealtimeUpdatesStore = db.collection(
  "meetingsRealtimeUpdates"
);
export const notifications = db.collection("notifications");

// import { Timestamp } from "firebase/firestore";

// export summaryStore;
// export notesStore;

export const getUserByUserId = async (uid) => {
  // console.log("getuserby id uid", uid);
  return await DBusers.doc(uid)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        //_log(doc.data())
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

//Store user details

export const setUserDetailsByUserId = async (uid, payload) => {
  try {
    DBusers.doc(uid).update({
      email: payload.email,
      displayName: payload.displayName,
      photoURL: payload.photoURL,
      timeZone: new Date().getTimezoneOffset(),
      updatedAt: new Date(),
    });
  } catch (error) {}
};

// unauthorize zoom
export const unauthorizeZoom = async (uid) => {
  try {
    DBusers.doc(uid).update({
      zoom: null,
      updatedAt: new Date(),
    });
  } catch (error) {}
};

// unauthorize zoom
export const unauthorizeMicrosoft = async (uid) => {
  try {
    DBusers.doc(uid).update({
      microsoft: null,
      updatedAt: new Date(),
    });
  } catch (error) {}
};

// Store meeting settings

export const storeMeetingSettings = async (payload, meetingType) => {
  let organizationId = uuidv4();
  return await DBorganizations.doc(organizationId)
    .set(payload)
    .then(async (response) => {
      return response;
    })
    .then(async () => {
      const userUpdated = await DBusers.doc(payload.createdBy)
        .update({
          organizationId: organizationId,
          "settings.join": meetingType,
        })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      return userUpdated;
    })
    .catch((error) => {
      console.error("Error writing Oragnization: ", error);
      return {
        success: false,
        message: (error && error.message) || "",
      };
    });
};

export const getTranscriptByMeetingIdOld = async (meetingId) => {
  let transcriptions = await db
    .collection("meetings")
    .doc(meetingId)
    .collection("transcription")
    .orderBy("timestamp")
    .get();

  // _log('logg:', doc.data())

  return transcriptions;
  // })
  // .catch(function (error) {
  //   console.log("Error getting document:", error);
  //   return false;
  // });
};
export const getTranscriptByMeetingId = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/get-meeting-data`,
        jwtToken,
        {
          meetingId,
          transcript: true,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.transcripts) {
        let transcripts = res.data.transcripts.map((transcript) => {
          transcript.timestamp = new firebase.firestore.Timestamp(
            transcript.timestamp._seconds,
            transcript.timestamp._nanoseconds
          );

          return transcript;
        });
        return transcripts;
      } else {
        return [];
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// export const getMeetingByMeetingId = async (meetingId) => {
//   let meetingData = await db.collection('meetings')
//     .doc(meetingId)
//     .get()
//     return meetingData;
// };

export const getMeetingByMeetingIdOld = async (meetingId) => {
  return await db
    .collection("meetings")
    .doc(meetingId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        // _log('logg:',doc.data())
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getMeetingByMeetingId = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/get-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.meeting) {
        let meeting = res.data.meeting;

        meeting.createdAt = new firebase.firestore.Timestamp(
          meeting.createdAt._seconds,
          meeting.createdAt._nanoseconds
        );

        meeting.updatedAt = new firebase.firestore.Timestamp(
          meeting.updatedAt._seconds,
          meeting.updatedAt._nanoseconds
        );

        meeting.endedAt = new firebase.firestore.Timestamp(
          meeting.endedAt._seconds,
          meeting.endedAt._nanoseconds
        );

        return meeting;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

export const getSummaryOld = async (meetingId) => {
  return await db
    .collection("summaries")
    .doc(meetingId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        // _log('logg:',doc.data())
        console.log("summary-->", doc.data());
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getSummary = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/get-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          summary: true,
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.summary) {
        let summary = res.data.summary;

        if (summary.history_actionPlans) {
          summary.history_actionPlans = summary.history_actionPlans.map(
            (history_actionPlan) => {
              history_actionPlan.timestamp = new firebase.firestore.Timestamp(
                history_actionPlan.timestamp._seconds,
                history_actionPlan.timestamp._nanoseconds
              );
              return history_actionPlan;
            }
          );
        }

        if (summary.history_summary) {
          summary.history_summary = summary.history_summary.map(
            (history_summary_version) => {
              history_summary_version.timestamp =
                new firebase.firestore.Timestamp(
                  history_summary_version.timestamp._seconds,
                  history_summary_version.timestamp._nanoseconds
                );
              return history_summary_version;
            }
          );
        }

        // getNotes(meetingId);
        return summary;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

export const getNotesOld = async (meetingId) => {
  return await db
    .collection("notes")
    .doc(meetingId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        _log("logg:", doc.data());
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getNotes = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/get-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          notes: true,
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.notes) {
        let notes = res.data.notes;

        notes.specialNotes = notes.specialNotes.map((specialNote) => {
          specialNote.timestamp = new firebase.firestore.Timestamp(
            specialNote.timestamp._seconds,
            specialNote.timestamp._nanoseconds
          );
          return specialNote;
        });

        return notes;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

export const getOrgainzation = async (organizationsId) => {
  return await db
    .collection("organizations")
    .doc(organizationsId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        // _log('logg:',doc.data())
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

export const storeSummaryOld = async (payload, meetingId) => {
  let summaryRef = summaryStore.doc(meetingId);
  let currentSummary = "";

  await summaryRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        currentSummary = doc.data().summary;
        payload.history_summary = firebase.firestore.FieldValue.arrayUnion({
          value: currentSummary,
          timestamp: new Date(),
        });
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });

  return summaryRef
    .update(payload)

    .then(async (response) => {
      let changeLogId = uuidv4();
      let changeLogData = {
        timestamp: new Date(),
        userId: firebase.auth().currentUser.uid,
        field: "SUMMARY",
        action: "UPDATE",
      };

      return DBmeeting.doc(meetingId)
        .collection("changeLogs")
        .doc(changeLogId)
        .set(changeLogData);
    })
    .then(() => true)

    .catch((error) => {
      console.error("Error writing Oragnization: ", error);
      return {
        success: false,
        message: (error && error.message) || "",
      };
    });
};

export const storeSummary = async (payload, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          storeSummaryPayload: payload,
          actions: ["storeSummary"],
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.storeSummary && res.data.storeSummary.status) {
        return true;
      } else {
        return {
          success: false,
          message: "Something went wrong!",
        };
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

export const storeActionsOld = async (payload, meetingId) => {
  let summaryRef = summaryStore.doc(meetingId);
  let currentActionPlans = "";

  await summaryRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        currentActionPlans = doc.data().actionPlans;
        payload.history_actionPlans = firebase.firestore.FieldValue.arrayUnion({
          value: currentActionPlans,
          timestamp: new Date(),
        });
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });

  return await summaryRef
    .update(payload)

    .then(async (response) => {
      let changeLogId = uuidv4();
      let changeLogData = {
        timestamp: new Date(),
        userId: firebase.auth().currentUser.uid,
        field: "ACTIONS",
        action: "UPDATE",
      };
      return DBmeeting.doc(meetingId)
        .collection("changeLogs")
        .doc(changeLogId)
        .set(changeLogData);
    })
    .then(() => true)

    .catch((error) => {
      console.error("Error writing Oragnization: ", error);
      return {
        success: false,
        message: (error && error.message) || "",
      };
    });
};

export const storeActions = async (payload, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          storeActionsPayload: payload,
          actions: ["storeActions"],
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.storeActions && res.data.storeActions.status) {
        return true;
      } else {
        return {
          success: false,
          message: "Something went wrong!",
        };
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

//store notes changes

export const changeNotes = async (payload, meetingId) => {
  return notesStore
    .doc(meetingId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        let currentNotes = doc.data();

        let updatedNotes = currentNotes.specialNotes.map((note, index) => {
          if (index == payload.index) {
            note.note = payload.note;
            return note;
          } else {
            return note;
          }
        });

        return notesStore.doc(meetingId).update({ specialNotes: updatedNotes });
      } else {
        throw "doc not found";
      }
    })
    .then(() => {
      let changeLogId = uuidv4();
      let changeLogData = {
        timestamp: new Date(),
        userId: firebase.auth().currentUser.uid,
        field: "NOTES",
        action: "UPDATE",
      };

      return DBmeeting.doc(meetingId)
        .collection("changeLogs")
        .doc(changeLogId)
        .set(changeLogData);
    })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

//store transcript changes

export const changeTranscriptOld = async (payload, meetingId) => {
  let response = await DBmeeting.doc(meetingId)
    .collection("transcription")
    .doc(payload.transcriptId)
    .update({ text: payload.text })
    .then(() => {
      console.log("store change log here");

      let changeLogId = uuidv4();
      let changeLogData = {
        timestamp: new Date(),
        userId: firebase.auth().currentUser.uid,
        field: "TRANSCRIPT",
        action: "UPDATE",
      };

      return DBmeeting.doc(meetingId)
        .collection("changeLogs")
        .doc(changeLogId)
        .set(changeLogData);
    })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });

  return response;
};

export const changeTranscript = async (payload, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          changeTranscriptPayload: payload,
          actions: ["changeTranscript"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.changeTranscript &&
        res.data.changeTranscript.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// get change logs of meeting
export const getChangeLog = (meetingId) => {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return getData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/changelog?meetingId=${meetingId}`,
        jwtToken
      );
    })
    .then((changeLogData) => {
      return changeLogData;
    })
    .catch(() => {
      return false;
    });

  return DBmeeting.doc(meetingId)
    .collection("changeLogs")
    .orderBy("timestamp", "desc")
    .get()
    .then((data) => {
      return data;
    })
    .catch((e) => e);
};

// store user setting

export const storeUserSetting = async (payload, type) => {
  let userId = firebase.auth().currentUser.uid;
  let userUpdated = "";

  if (type == "notifications") {
    userUpdated = await DBusers.doc(userId).update({
      "settings.preferences.notifications": payload,
    });
  } else if (type == "sharing") {
    userUpdated = await DBusers.doc(userId).update({
      "settings.preferences.sharing": payload,
    });
  } else if (type == "meeting-settings") {
    return DBusers.doc(userId)
      .update({
        "settings.join": payload,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  } else {
    userUpdated = await DBusers.doc(userId).update({
      "settings.preferences.summaries": payload,
    });
  }

  return userUpdated;
};

// store Summary ratings
export const storeSummaryRatingsOld = async (meetingId, payload) => {
  return summaryStore
    .doc(meetingId)
    .update({
      summaryReaction: payload,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const storeSummaryRatings = async (meetingId, payload) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          storeSummaryRatingsPayload: payload,
          actions: ["storeSummaryRatings"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.storeSummaryRatings &&
        res.data.storeSummaryRatings.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// store Action ratings
export const storeActionRatingsOld = async (meetingId, payload) => {
  return summaryStore
    .doc(meetingId)
    .update({
      actionPlansReaction: payload,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const storeActionRatings = async (meetingId, payload) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          storeActionRatingsPayload: payload,
          actions: ["storeActionRatings"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.storeActionRatings &&
        res.data.storeActionRatings.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// set users selected calendars from settings

export const setCalendars = (calendars) => {
  return DBusers.doc(firebase.auth().currentUser.uid)
    .update({
      "settings.calendars": calendars,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

// set users Products/Services and Competitors
export const setProductsAndCompetitors = async (arr, type) => {
  let obj =
    type == "competitors"
      ? { "matching.competitors": arr }
      : type == "customWords"
      ? { "matching.customWords": arr }
      : { "matching.products": arr };

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  return DBorganizations.doc(userDetails.organizationId)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

//Mark transcript as read

export const markTranscriptAsReadFsOld = async (meetingId) => {
  return DBmeeting.doc(meetingId)
    .update({ transcriptRead: true })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const markTranscriptAsReadFs = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["markTranscriptAsReadFs"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.markTranscriptAsReadFs &&
        res.data.markTranscriptAsReadFs.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// mark summary as read

export const markSummaryAsReadFsOld = async (meetingId) => {
  return summaryStore
    .doc(meetingId)
    .update({ summaryRead: true })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const markSummaryAsReadFs = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["markSummaryAsReadFs"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.markSummaryAsReadFs &&
        res.data.markSummaryAsReadFs.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// mark actions as read

export const markActionAsReadFsOld = async (meetingId) => {
  return summaryStore
    .doc(meetingId)
    .update({ actionPlansRead: true })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const markActionAsReadFs = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["markActionAsReadFs"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.markActionAsReadFs &&
        res.data.markActionAsReadFs.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};
// mark notes as read

export const markNotesAsReadFsOld = async (meetingId) => {
  return notesStore
    .doc(meetingId)
    .update({ notesRead: true })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const markNotesAsReadFs = async (meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["markNotesAsReadFs"],
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.markNotesAsReadFs &&
        res.data.markNotesAsReadFs.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// get all critical notifications
export const getCriticalNotifications = async (uid) => {
  const snapshot = await DBcriticalNotifications.get();
  return snapshot.docs.map((doc) => {
    return {
      ...doc.data(),
      docId: doc.id,
    };
  });
};

// add new topic
export const addNewTopic = async (data) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let existingTopics = [];
  if (userDetails && userDetails.settings && userDetails.settings.topics) {
    existingTopics = userDetails.settings.topics;
  }
  existingTopics.push(data);
  let obj = {
    "settings.topics": existingTopics,
  };
  return DBusers.doc(firebase.auth().currentUser.uid)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

// fetch topics
export const fetchTopics = async (data) => {
  let existingTopics = [];
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  if (userDetails && userDetails.settings && userDetails.settings.topics) {
    existingTopics = userDetails.settings.topics;
  }
  return existingTopics;
};

// delete topic
export const deleteTopic = async (data) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let existingTopics = [];
  if (userDetails && userDetails.settings && userDetails.settings.topics) {
    existingTopics = userDetails.settings.topics;
  }
  let newTopics = existingTopics.filter((i, index) => data !== index);
  let obj = {
    "settings.topics": newTopics,
  };
  return DBusers.doc(firebase.auth().currentUser.uid)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

// add meeting topic
export const addMeetingTopicOld = async (data) => {
  const { meetingId, topic } = data;
  let meetingDetails = await getMeetingByMeetingId(meetingId);
  let existingTopics = [];
  if (meetingDetails && meetingDetails.topics) {
    existingTopics = meetingDetails.topics;
  }
  existingTopics.push(topic);
  let obj = {
    topics: existingTopics,
  };
  return DBmeeting.doc(meetingId)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const addMeetingTopic = async (data) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId: data.meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["addMeetingTopic"],
          addMeetingTopicPayload: data.topic,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.addMeetingTopic &&
        res.data.addMeetingTopic.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// delete meeting topic
export const deleteMeetingTopicOld = async (data) => {
  const { meetingId, topic } = data;
  let meetingDetails = await getMeetingByMeetingId(meetingId);
  let topicsToUpdate = [];
  if (meetingDetails && meetingDetails.topics) {
    meetingDetails.topics.forEach((t) => {
      if (t !== topic) {
        topicsToUpdate.push(t);
      }
    });
  }
  let obj = {
    topics: topicsToUpdate,
  };
  return DBmeeting.doc(meetingId)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const deleteMeetingTopic = async (data) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId: data.meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["deleteMeetingTopic"],
          deleteMeetingTopicPayload: data.topic,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.deleteMeetingTopic &&
        res.data.deleteMeetingTopic.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// set speakers flag
export const setParticipantsFlagOld = (obj, meetingId) => {
  return DBmeeting.doc(meetingId)

    .update({
      participants: obj,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const setParticipantsFlag = (obj, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["setParticipantsFlag"],
          setParticipantsFlagPayload: obj,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.setParticipantsFlag &&
        res.data.setParticipantsFlag.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

//update transcript
export const UpdateTranscriptByMeetingIdOld = async (
  meetingId,
  transcriptId,
  name,
  avatar
) => {
  // console.log(meetingId, transcriptId, name)
  let transcriptions = await db
    .collection("meetings")
    .doc(meetingId)
    .collection("transcription")
    .doc(transcriptId)
    .update({
      name: name,
      avatar: avatar ? avatar : "",
    })
    .then(() => {
      // console.log('then')
      return true;
    })
    .catch((e) => {
      // console.log(e)
      return false;
    });
  return transcriptions;
};

export const UpdateTranscriptByMeetingId = async (
  meetingId,
  transcriptId,
  name,
  avatar
) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["updateTranscriptByMeetingId"],
          updateTranscriptByMeetingIdPayload: { transcriptId, name, avatar },
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.updateTranscriptByMeetingId &&
        res.data.updateTranscriptByMeetingId.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

//addContact
export const addContact = async (contactDetails) => {
  let contactID = uuidv4();
  contactDetails.contactId = contactID;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  contactDetails.organizationId = userDetails.organizationId;
  contactDetails.updatedAt = new Date();
  contactDetails.createdAt = new Date();
  contactDetails.createdBy = firebase.auth().currentUser.uid;

  // console.log(contactDetails);
  // let uid = firebase.auth().currentUser.uid;
  // console.log(uid, "inapp");
  let res = await db
    .collection("contacts")
    .doc(contactID)
    .set(contactDetails)
    .then(async () => {
      // let user = (await db.collection("users").doc(uid).get()).data();
      // if (user.contact) {
      //   user.contact.push(contactID);
      //   await db.collection("users").doc(uid).set(user);
      // } else {
      //   user.contact = [];
      //   user.contact.push(contactID);
      //   await db.collection("users").doc(uid).set(user);
      // }
      return { success: true, id: contactID };
    })
    .catch((e) => {
      return {
        success: false,
        message: (e && e.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getContact = async (contactId) => {
  console.log("contactId", contactId);

  let res = await ContactCollection.doc(contactId)
    .get()
    .then((contact) => {
      return { success: true, contact: contact.data() };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getContactById = async (ID) => {
  try {
    let contact = (await db.collection("contacts").doc(ID).get()).data();
    if (contact) {
      return contact;
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateContactOld = async (updatedUserInfo, ID) => {
  try {
    console.log(updatedUserInfo, "updated data here");
    let contact = await db.collection("contacts").doc(ID).set(updatedUserInfo);
    console.log(contact, "lool");
    if (contact) {
      return "true";
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteContact = async (ID) => {
  try {
    let contact = await db.collection("contacts").doc(ID).delete();
    if (contact) {
      return "true";
    }
  } catch (e) {
    console.log(e);
  }
};

// update meeting outcome
export const updateMeetingOutcomeOld = async (data) => {
  const { meetingId, meetingOutcome } = data;
  let meetingDetails = await getMeetingByMeetingId(meetingId);
  let obj = {
    meetingOutcome: meetingOutcome,
  };
  return DBmeeting.doc(meetingId)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const updateMeetingOutcome = async (data) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId: data.meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["updateMeetingOutcome"],
          updateMeetingOutcomePayload: data.meetingOutcome,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.updateMeetingOutcome &&
        res.data.updateMeetingOutcome.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// update attendees type
export const updateAttendeesTypeOld = async (data) => {
  const { meetingId, attendeesType } = data;
  let meetingDetails = await getMeetingByMeetingId(meetingId);
  let obj = {
    attendeesType: attendeesType,
  };
  return DBmeeting.doc(meetingId)
    .update(obj)
    .then(() => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
};

export const updateAttendeesType = async (data) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId: data.meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["updateAttendeesType"],
          updateAttendeesTypePayload: data.attendeesType,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.updateAttendeesType &&
        res.data.updateAttendeesType.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// get meeting list by meetingId array
export const getMeetingsByMeetingIdList = async (meetingIds) => {
  // const meetingDetails = await DBmeeting.where(
  //   firestore.FieldPath.documentId(),
  //   "in",
  //   meetingIds
  // )
  //   .get()
  //   .then(() => {
  //     console.log("meetingDetails", meetingDetails);
  //   })
  //   .catch(function (error) {
  //     console.log("error", error);
  //   });
  // const meetingDetails = userIds.map(id => this.firestore.doc(users/${id}))
  // this.firestore.getAll(...refs).then(users => console.log(users.map(doc => doc.data())))
  // const meetingDetails = meetingIds.map((id) =>
  //   DBmeeting.doc(meetings / { id })
  // );
  // db.getAll(...meetingDetails).then((meetings) =>
  //   console.log(meetings.map((doc) => doc.data()))
  // );
};

//Store/save leads
export const saveLead = async (payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  payload.organizationId = userDetails.organizationId;
  payload.updatedAt = new Date();
  payload.createdAt = new Date();
  payload.createdBy = firebase.auth().currentUser.uid;

  let leadId = uuidv4();
  let res = await LeadCollection.doc(leadId)
    .set(payload)
    .then(() => {
      return { success: true, leadId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

//Update lead
export const updateLead = async (leadId, payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  // payload.organizationId = userDetails.organizationId;
  payload.updatedAt = new Date();
  // payload.createdAt = new Date();
  payload.modifiedBy = firebase.auth().currentUser.uid;

  let res = await LeadCollection.doc(leadId)
    .update(payload)
    .then(() => {
      return { success: true, leadId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const saveLeadFieldsSetting = async (payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  console.log(userDetails.organizationId);
  let res = await DBorganizations.doc(userDetails.organizationId)
    .update({ leadFieldsSetting: payload })
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getOrganization = async () => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let res = await DBorganizations.doc(userDetails.organizationId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        return doc.data();
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
  if (!res.leadFieldsSetting) {
    res.leadFieldsSetting = LeadFieldsArray;
  }
  if (!res.contactFieldsSetting) {
    res.contactFieldsSetting = ContactFieldsArray;
  }

  if (!res.accountFieldsSetting) {
    res.accountFieldsSetting = AccountFieldsArray;
  }
  return res;
};

export const getOrgainzationMembers = async () => {
  let jwtToken = await firebase.auth().currentUser.getIdToken();
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let memberResponse = await getData(
    `${process.env.REACT_APP_BASE_API_URL}/organization/members/${userDetails.organizationId}`,
    jwtToken
  );

  if (memberResponse.status === "success") {
    console.log("memberResponse", memberResponse);
    return memberResponse.data;
  } else {
    return false;
  }
};

export const getOrganizationLeadsCount = async () => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let queryRef = LeadCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );
  queryRef = queryRef.where("leadStatus", "!=", "converted");
  return await queryRef.get().then((qs) => qs.size);
};

export const getOrganizationLeads = async (payload) => {
  let page = payload.page ? payload.page : 1;
  let limit = 20 * page;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let leads = [];

  let queryRef = LeadCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  queryRef = queryRef.where("leadStatus", "!=", "converted");
  if (payload.sortBy && payload.sortBy.length) {
    queryRef = queryRef.orderBy("leadStatus");
    queryRef = queryRef.orderBy("firstName", payload.sortBy[0].sort);
  } else {
    queryRef = queryRef.orderBy("leadStatus");
    queryRef = queryRef.orderBy("createdAt", "desc");
  }
  await queryRef

    .limit(limit)
    .get()
    .then((qs) => {
      qs.forEach((lead) => {
        leads.push({ ...lead.data(), id: lead.id });
      });
    });

  return leads;
  // if (!(payload.sortBy && payload.sortBy.length)) {
  //   console.log("inin");
  //   leads = leads.sort((l1, l2) => {
  //     return l1.createdAt.seconds - l2.createdAt.seconds;
  //   });
  //   console.log("leads");
  //   return leads;
  // } else {
  //   return leads;
  // }
};

export const getLead = async (leadId) => {
  let res = await LeadCollection.doc(leadId)
    .get()
    .then((lead) => {
      return { success: true, lead: lead.data() };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getAlgoliaToken = async () => {
  return "algoliaSearchKey";

  // let algoliaSearchKey = "";
  // if (localStorage.getItem("algoliaSearchKeyData") !== null) {
  //   let algoliaSearchKeyData = JSON.parse(
  //     localStorage.getItem("algoliaSearchKeyData")
  //   );
  //   algoliaSearchKey = algoliaSearchKeyData.algoliaSearchKey;

  //   if (algoliaSearchKeyData.time < Date.now() - 1000 * 60 * 60 * 8) {
  //     localStorage.removeItem("algoliaSearchKeyData");
  //   }
  // } else {
  //   let jwtToken = await firebase.auth().currentUser.getIdToken();

  //   let res = await getData(
  //     `${process.env.REACT_APP_BASE_API_URL}/user/algolia-token`,
  //     jwtToken
  //   );
  //   if (res.status == "success") {
  //     localStorage.setItem(
  //       "algoliaSearchKeyData",
  //       JSON.stringify({
  //         algoliaSearchKey: res.data.algoliaKey,
  //         time: Date.now(),
  //       })
  //     );
  //     algoliaSearchKey = res.data.algoliaKey;
  //   }
  // }

  //return algoliaSearchKey;
};

export const getElasticToken = async () => {
  let elasticSearchKey = "";
  if (localStorage.getItem("elasticSearchKeyData") !== null) {
    let elasticSearchKeyData = JSON.parse(
      localStorage.getItem("elasticSearchKeyData")
    );
    elasticSearchKey = elasticSearchKeyData.elasticSearchKey;

    if (elasticSearchKeyData.time < Date.now() - 1000 * 60 * 60 * 8) {
      localStorage.removeItem("elasticSearchKeyData");
    }
  } else {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let res = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/user/elastic-token`,
      jwtToken
    );
    if (res.status == "success") {
      localStorage.setItem(
        "elasticSearchKeyData",
        JSON.stringify({
          elasticSearchKey: res.data.elasticKey,
          time: Date.now(),
        })
      );
      elasticSearchKey = res.data.elasticKey;
    }
  }

  return elasticSearchKey;
};

export const deleteLeads = async (leads) => {
  let refs = leads.map((leadId) => LeadCollection.doc(leadId).delete());
  let res = await Promise.all(refs)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};
// add custom notes
export const addNotesOld = async (payload, meetingId) => {
  payload.createdBy = firebase.auth().currentUser.uid;
  payload.speakerName = firebase.auth().currentUser.displayName;
  payload.timestamp = new Date();

  return notesStore
    .doc(meetingId)
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        let currentNotes = doc.data();
        console.log("currentNotes", currentNotes);
        currentNotes.specialNotes.push(payload);

        await notesStore
          .doc(meetingId)
          .update({ specialNotes: currentNotes.specialNotes });
        return 1;
      } else {
        let newPayload = {};
        let specialNotes = [];
        specialNotes.push({
          note: payload.note,
          createdBy: firebase.auth().currentUser.uid,
          speakerName: firebase.auth().currentUser.displayName,
          timestamp: new Date(),
        });
        newPayload.specialNotes = specialNotes;

        await notesStore
          .doc(meetingId)
          .set(newPayload)
          .then(() => {
            console.log("Note added");
          })
          .catch((e) => {
            console.log("eee", e);
            return e;
          });
        return 1;
      }
    });
};

export const addNotes = async (payload, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["addNotes"],
          addNotesPayload: payload,
        }
      );
    })
    .then((res) => {
      if (res.data && res.data.addNotes && res.data.addNotes.status) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

// update meeting title with changelog

export const updateMeetingTitleOld = async (val, meetingId) => {
  return DBmeeting.doc(meetingId)
    .get()
    .then(function (doc) {
      if (doc.exists) {
        return DBmeeting.doc(meetingId).update({ title: val });
      } else {
        throw "doc not found";
      }
    })
    .then(() => {
      let changeLogId = uuidv4();
      let changeLogData = {
        timestamp: new Date(),
        userId: firebase.auth().currentUser.uid,
        field: "TITLE",
        action: "UPDATE",
      };

      return DBmeeting.doc(meetingId)
        .collection("changeLogs")
        .doc(changeLogId)
        .set(changeLogData);
    })
    .then(() => {
      return true;
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      return false;
    });
};

export const updateMeetingTitle = async (val, meetingId) => {
  let res = firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return postData(
        `${process.env.REACT_APP_BASE_API_URL}/meeting/update-meeting-data`,
        jwtToken,
        {
          meetingId,
          // userId: "ZnMptStmWbclfCovF9gDH695ju82",
          actions: ["updateMeetingTitle"],
          updateMeetingTitlePayload: val,
        }
      );
    })
    .then((res) => {
      if (
        res.data &&
        res.data.updateMeetingTitle &&
        res.data.updateMeetingTitle.status
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });

  return res;
};

export const saveContactFieldsSetting = async (payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let res = await DBorganizations.doc(userDetails.organizationId)
    .update({ contactFieldsSetting: payload })
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const deleteContacts = async (contacts) => {
  let refs = contacts.map((contactId) =>
    ContactCollection.doc(contactId).delete()
  );
  let res = await Promise.all(refs)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getOrganizationContactsCount = async () => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let queryRef = ContactCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );
  return await queryRef.get().then((qs) => qs.size);
};

export const getOrganizationContacts = async (page = 1) => {
  let limit = 20 * page;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let contacts = [];

  let crmUsersObj = {};

  let queryRef = ContactCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  queryRef = queryRef.orderBy("createdAt", "desc");
  await queryRef

    .limit(limit)
    .get()
    .then((qs) => {
      qs.forEach(async (contact) => {
        let tempData = contact.data();
        if (crmUsersObj[tempData.contactOwner]) {
          tempData.contactOwner = crmUsersObj[tempData.contactOwner];
        } else {
          let userData = await getUserByUserId(tempData.contactOwner);
          crmUsersObj[tempData.contactOwner] = userData.displayName;
          tempData.contactOwner = userData.displayName;
        }
        contacts.push({ ...tempData, id: contact.id });
      });
    });

  return contacts;
};

export const updateContact = async (contactId, payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  // payload.organizationId = userDetails.organizationId;
  payload.updatedAt = new Date();
  // payload.createdAt = new Date();
  payload.modifiedBy = firebase.auth().currentUser.uid;

  let res = await ContactCollection.doc(contactId)
    .update(payload)
    .then(() => {
      return { success: true, contactId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

//save accounts

export const saveAccount = async (payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  payload.organizationId = userDetails.organizationId;
  payload.updatedAt = new Date();
  payload.createdAt = new Date();
  payload.createdBy = firebase.auth().currentUser.uid;

  let accountId = uuidv4();
  let res = await AccountCollection.doc(accountId)
    .set(payload)
    .then(() => {
      return { success: true, accountId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getOrgainzationAccounts = async (query = "") => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let accounts = [];

  let queryRef = AccountCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  if (query.length) {
    queryRef = queryRef.where("name", "==", query);
  }

  await queryRef.get().then((qs) => {
    qs.forEach((account) => {
      accounts.push({ ...account.data(), id: account.id });
    });
  });

  return accounts;
};

//Add users tasks

export const AddTask = async (payload) => {
  let taskID = uuidv4();
  payload.userId = firebase.auth().currentUser.uid;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  payload.organizationId = userDetails.organizationId;
  payload.createdAt = new Date();
  payload.updatedAt = new Date();

  payload.commentsCount = 0;
  payload.attachmentsCount = 0;

  let res = await TaskCollection.doc(taskID)
    .set(payload)
    .then(async () => {
      payload.id = taskID;
      return { success: true, task: payload };
    })
    .catch((e) => {
      return {
        success: false,
        message: (e && e.message) || "Something went wrong!",
      };
    });

  return res;
};

export const GetTasks = async () => {
  let tasks = [];

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  await TaskCollection
    //.where("userId", "==", firebase.auth().currentUser.uid)
    .where("organizationId", "==", userDetails.organizationId)
    .get()
    .then((qs) => {
      let promises = [];
      qs.forEach((task) => {
        promises.push(
          getLatestDocOfTask(
            { ...task.data(), id: task.id },
            task.data().descriptionAttachments
          )
        );
        // tasks.push({ ...task.data(), id: task.id });
      });

      return Promise.all(promises);
    })
    .then((t) => {
      tasks = t;
    });

  return tasks;
};

export const updateTask = async (taskId, payload) => {
  payload.updatedAt = new Date();
  // payload.createdAt = new Date();
  payload.modifiedBy = firebase.auth().currentUser.uid;

  let res = await TaskCollection.doc(taskId)
    .update(payload)
    .then(() => {
      return { success: true, taskId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const DeleteTasks = async () => {
  let tasks = [];

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  await TaskCollection.where("userId", "==", firebase.auth().currentUser.uid)
    .where("organizationId", "==", userDetails.organizationId)
    .get()
    .then((qs) => {
      qs.forEach((task) => {
        // task.delete();
        TaskCollection.doc(task.id).delete();
        // tasks.push({ ...task.data(), id: task.id });
      });
    });

  return tasks;
};

// store Business Topics
export const storeBusinessTopics = async (payload) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let res = await DBorganizations.doc(userDetails.organizationId)
    .update({ businessTopics: payload })
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

// store sentiment settings
export const storeSentimentSettings = async (payload) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let res = await DBorganizations.doc(userDetails.organizationId)
    .update({ sentimentSettings: payload })
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });
  return res;
};

const getLatestDocOfTask = (task, oldAtts) => {
  return new Promise((res, rej) => {
    if (task.descriptionAttachments) {
      let promises = task.descriptionAttachments.map((att) => {
        return new Promise((res, rej) => {
          const urlParams = new URLSearchParams(att.url);
          const signedTime = urlParams.get("X-Goog-Date");
          let expirySeconds = urlParams.get("X-Goog-Expires");

          if (signedTime && expirySeconds) {
            expirySeconds = parseInt(expirySeconds);
            const currentTimestamp = parseInt(moment().format("X"));
            const signedTimestamp = parseInt(moment(signedTime).format("X"));

            if (signedTimestamp + expirySeconds < currentTimestamp) {
              Get(`attachments/${att.id}`)
                .then((urlRes) => {
                  att.url = urlRes.data.data.url;
                  res(att);
                })
                .catch((e) => {
                  res(att);
                });
            } else {
              res(att);
            }
          } else {
            res(att);
          }
        });
      });
      Promise.all(promises).then((data) => {
        console.log("promise all", data, oldAtts);
        oldAtts.map((oldAtt) => {
          let newAtt = data.filter((d) => d.id === oldAtt.id);
          let newUrl = newAtt[0].url;
          task.description = task.description.replace(oldAtt.url, newUrl);
        });

        res(task);
      });
    } else {
      res(task);
    }
  });
};
export const GetTask = async (taskId) => {
  let taskDetails;
  let oldAtts = [];
  let res = await TaskCollection.doc(taskId)
    .get()
    .then((task) => {
      taskDetails = { success: true, task: { id: task.id, ...task.data() } };
      oldAtts = task.data().descriptionAttachments;
      console.log("aabb", oldAtts);
      return taskDetails;
    })
    .then((data) => {
      // oldAtts = [...data.task.descriptionAttachments];
      let promises = data.task.descriptionAttachments.map((att) => {
        return new Promise((res, rej) => {
          const urlParams = new URLSearchParams(att.url);
          const signedTime = urlParams.get("X-Goog-Date");
          let expirySeconds = urlParams.get("X-Goog-Expires");
          console.log("signedTime", signedTime);
          if (signedTime && expirySeconds) {
            expirySeconds = parseInt(expirySeconds);
            const currentTimestamp = parseInt(moment().format("X"));
            const signedTimestamp = parseInt(moment(signedTime).format("X"));

            if (signedTimestamp + expirySeconds < currentTimestamp) {
              console.log("heeer if");
              Get(`attachments/${att.id}`)
                .then((urlRes) => {
                  att.url = urlRes.data.data.url;
                  res(att);
                })
                .catch((e) => {
                  res(att);
                });
            } else {
              res(att);
            }
          } else {
            res(att);
          }
        });
      });
      return Promise.all(promises);
    })
    .then((data) => {
      console.log("taskDetails", taskDetails);
      oldAtts.map((oldAtt) => {
        let newAtt = data.filter((d) => d.id === oldAtt.id);
        let newUrl = newAtt[0].url;
        console.log("newUrl,", newUrl, newAtt, oldAtt);
        taskDetails.task.description = taskDetails.task.description.replace(
          oldAtt.url,
          newUrl
        );
      });
      // taskDetails.task.descriptionAttachments = data;
      return taskDetails;
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const deleteTask = async (ID) => {
  try {
    let task = await TaskCollection.doc(ID).delete();
    return true;
  } catch (e) {
    console.log(e);
  }
};

// export const getUserPaidStatus = () => {
//   if (localStorage.getItem("userPaidStatusData") !== null) {
//     let userPaidStatusData = JSON.parse(
//       localStorage.getItem("userPaidStatusData")
//     );

//     if (userPaidStatusData.time < Date.now() - 1000 * 60 * 60) {
//       localStorage.removeItem("userPaidStatusData");
//     } else {
//       return userPaidStatusData.res;
//     }
//   }

//   return firebase
//     .auth()
//     .currentUser.getIdToken()
//     .then(async function (jwtToken) {
//       return getData(
//         `${process.env.REACT_APP_BASE_API_URL}/user/status/paidPlan`,
//         jwtToken
//       );
//     })
//     .then((res) => {
//       localStorage.setItem(
//         "userPaidStatusData",
//         JSON.stringify({
//           res,
//           time: Date.now(),
//         })
//       );
//       return res;
//     })
//     .catch(() => {
//       return false;
//     });
// };

// export const getUserApprovedStatus = () => {
//   if (localStorage.getItem("userApprovalStatusData") !== null) {
//     let userApprovalStatusData = JSON.parse(
//       localStorage.getItem("userApprovalStatusData")
//     );

//     if (userApprovalStatusData.time < Date.now() - 1000 * 60 * 60) {
//       localStorage.removeItem("userApprovalStatusData");
//     } else {
//       return userApprovalStatusData.res;
//     }
//   }

//   return firebase
//     .auth()
//     .currentUser.getIdToken()
//     .then(async function (jwtToken) {
//       return getData(
//         `${process.env.REACT_APP_BASE_API_URL}/user/status/approval`,
//         jwtToken
//       );
//     })
//     .then((res) => {
//       localStorage.setItem(
//         "userApprovalStatusData",
//         JSON.stringify({
//           res,
//           time: Date.now(),
//         })
//       );

//       return res;
//     })
//     .catch(() => {
//       return false;
//     });
// };
export const getUserBillingStatus = () => {
  // if (localStorage.getItem("userBillingStatusData") !== null) {
  //   let userBillingStatusData = JSON.parse(
  //     localStorage.getItem("userBillingStatusData")
  //   );

  //   if (userBillingStatusData.time < Date.now() - 1000 * 60 * 60) {
  //     localStorage.removeItem("userBillingStatusData");
  //   } else {
  //     return userBillingStatusData.res;
  //   }
  // }

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function (jwtToken) {
      return getData(
        `${process.env.REACT_APP_BASE_API_URL}/user/status/billing`,
        jwtToken
      );
    })
    .then((res) => {
      if (res.approved) {
        localStorage.setItem(
          "userBillingStatusData",
          JSON.stringify({
            res,
            time: Date.now(),
          })
        );
      }

      return res;
    })
    .catch(() => {
      return false;
    });
};

export const storeUserLocation = async (payload) => {
  let userId = firebase.auth().currentUser.uid;
  await DBusers.doc(userId).update({
    location: payload,
  });
  return true;
};

//Add users deals

export const addDeal = async (payload) => {
  let dealID = uuidv4();
  payload.userId = firebase.auth().currentUser.uid;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  payload.organizationId = userDetails.organizationId;
  payload.createdAt = new Date();
  payload.updatedAt = new Date();
  payload.commentsCount = 0;
  payload.attachmentsCount = 0;

  let res = await DealCollection.doc(dealID)
    .set(payload)
    .then(async () => {
      payload.id = dealID;
      return { success: true, deal: payload };
    })
    .catch((e) => {
      return {
        success: false,
        message: (e && e.message) || "Something went wrong!",
      };
    });

  return res;
};

export const GetDeals = async () => {
  let deals = [];

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  await DealCollection
    //.where("userId", "==", firebase.auth().currentUser.uid)
    .where("organizationId", "==", userDetails.organizationId)
    .get()
    .then((qs) => {
      qs.forEach((deal) => {
        deals.push({ ...deal.data(), id: deal.id });
      });
    });

  return deals;
};

export const updateDeal = async (dealId, payload) => {
  payload.updatedAt = new Date();
  // payload.createdAt = new Date();
  // payload.modifiedBy = firebase.auth().currentUser.uid;

  let res = await DealCollection.doc(dealId)
    .update(payload)
    .then(() => {
      return { success: true, dealId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const deleteDeal = async (ID) => {
  try {
    let deal = await DealCollection.doc(ID).delete();
    return true;
  } catch (e) {
    console.log(e);
  }
};

export const GetDeal = async (dealId) => {
  let res = await DealCollection.doc(dealId)
    .get()
    .then((deal) => {
      return { success: true, deal: { id: deal.id, ...deal.data() } };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const CreateReminder = (payload) => {
  console.log(payload);
  return { success: true };
};

export const getOrganizationLeadByEmail = async (email) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let leads = [];

  let queryRef = LeadCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  queryRef = queryRef.where("leadStatus", "!=", "converted");
  queryRef = queryRef.where("email", "==", email);
  await queryRef.get().then((qs) => {
    qs.forEach((lead) => {
      leads.push({ ...lead.data(), id: lead.id });
    });
  });

  return leads;
};

export const getOrganizationContactByEmail = async (email) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let contacts = [];

  let queryRef = ContactCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  queryRef = queryRef.where("email", "==", email);
  await queryRef.get().then((qs) => {
    qs.forEach((contact) => {
      contacts.push({ ...contact.data(), id: contact.id });
    });
  });

  return contacts;
};

export const getOrganizationAccountsCount = async () => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  let queryRef = AccountCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );
  return await queryRef.get().then((qs) => qs.size);
};

export const getOrganizationAccounts = async (payload) => {
  let page = payload.page ? payload.page : 1;
  let limit = 20 * page;
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let accounts = [];

  let queryRef = AccountCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  if (payload.sortBy && payload.sortBy.length) {
    queryRef = queryRef.orderBy("firstName", payload.sortBy[0].sort);
  } else {
    queryRef = queryRef.orderBy("createdAt", "desc");
  }
  await queryRef

    .limit(limit)
    .get()
    .then((qs) => {
      qs.forEach((account) => {
        accounts.push({ ...account.data(), id: account.id });
      });
    });
  console.log("accounts", accounts);
  return accounts;
};

export const deleteAccounts = async (accountIds) => {
  let refs = accountIds.map((accountId) =>
    AccountCollection.doc(accountId).delete()
  );
  let res = await Promise.all(refs)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });
  return res;
};

//Update lead
export const updateAccount = async (accountId, payload) => {
  // console.log(payload);

  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);

  // payload.organizationId = userDetails.organizationId;
  payload.updatedAt = new Date();
  // payload.createdAt = new Date();
  payload.modifiedBy = firebase.auth().currentUser.uid;

  let res = await AccountCollection.doc(accountId)
    .update(payload)
    .then(() => {
      return { success: true, accountId };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getAccount = async (accountId) => {
  let res = await AccountCollection.doc(accountId)
    .get()
    .then((account) => {
      return { success: true, account: account.data() };
    })
    .catch((error) => {
      return {
        success: false,
        message: (error && error.message) || "Something went wrong!",
      };
    });

  return res;
};

export const getAccountContacts = async (accountId) => {
  let userDetails = await getUserByUserId(firebase.auth().currentUser.uid);
  let contacts = [];

  let crmUsersObj = {};

  let queryRef = ContactCollection.where(
    "organizationId",
    "==",
    userDetails.organizationId
  );

  queryRef = queryRef.where("account", "==", accountId);
  await queryRef.get().then((qs) => {
    qs.forEach(async (contact) => {
      let tempData = contact.data();
      contacts.push({ ...tempData, id: contact.id });
    });
  });

  return contacts;
};
