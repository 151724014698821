import React, { useState } from "react";
import Box from "@mui/material/Box";

import firebase from "../../firebase/firebase";
import { getData } from "../../utils";

import {
  startOnlineMeetingRecording,
  resumeStreamAudioToWebSocket,
  pauseSocket,
  closeSocket,
} from "./AudioStreamService";

import Wave from "./Wave";
import { Button, CircularProgress } from "@material-ui/core";

export default function RecordOnlineMeeting({ setStep, setMeetingId }) {
  const [audioStream, setAudioStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  let [seconds, setSeconds] = useState(0);
  let [interval, setinterval] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [enableStopButton, setEnableStopButton] = useState(false);
  let [tmpMeetingId, setTmpMeetingId] = useState(null);
  let [error, setError] = useState(null);

  const startRecordingCallback = (stream) => {
    setAudioStream(stream);
    setIsRecording(true);
    setIsLoading(false);
    setEnableStopButton(true);
    setSeconds(0);
    let intervalTmp = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    setinterval(intervalTmp);
  };

  const startRecording = async () => {
    setIsLoading(true);
    let jwtToken = await firebase.auth().currentUser.getIdToken();
    let newMeetingResponse = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/meeting/recorded/create`,
      jwtToken
    );

    if (newMeetingResponse.status === "success") {
      startOnlineMeetingRecording(
        startRecordingCallback,
        newMeetingResponse.data.id,
        setError
      );
      setTmpMeetingId(newMeetingResponse.data.id);
    }
  };

  const pauseRecording = () => {
    setIsRecording(false);
    pauseSocket();

    if (interval) {
      clearInterval(interval);
      setinterval(null);
    }
  };
  const stopRecording = () => {
    console.log("stopRecording");
    setIsRecording(false);
    closeSocket();
    setAudioStream(null);

    setTimeout(() => {
      setMeetingId(tmpMeetingId);
      setStep(2);
    }, 2000);

    if (interval) {
      clearInterval(interval);
      setinterval(null);
    }
  };

  const resumeRecording = () => {
    resumeStreamAudioToWebSocket(setIsRecording);
    let intervalTmp = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    setinterval(intervalTmp);
  };

  return (
    <Box>
      <Box
        display="flex"
        width={400}
        alignItems="center"
        justifyContent="space-between"
      >
        {!isRecording && !audioStream && (
          <Box title="Start Recording" style={{ cursor: "pointer" }}>
            {/* <RadioButtonCheckedIcon color="primary" onClick={startRecording} /> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={startRecording}
            >
              Start Recording
            </Button>
          </Box>
        )}

        {!isRecording && audioStream && (
          <Box title="Resume Recording" style={{ cursor: "pointer" }}>
            {/* <RadioButtonCheckedIcon color="primary" onClick={resumeRecording} /> */}

            <Button
              variant="contained"
              color="secondary"
              onClick={resumeRecording}
            >
              Resume Recording
            </Button>
          </Box>
        )}

        {isRecording && (
          <Box title="Pause Recording" style={{ cursor: "pointer" }}>
            {/* <PauseCircleOutlineIcon color="primary" onClick={pauseRecording} /> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={pauseRecording}
            >
              Pause Recording
            </Button>
          </Box>
        )}

        <Box
          title="Stop Recording"
          style={
            enableStopButton ? { cursor: "pointer" } : { cursor: "not-allowed" }
          }
        >
          {/* <StopCircleIcon
            color="error"
            onClick={
              enableStopButton ? stopRecording : () => console.log("disabled")
            }
          /> */}

          <Button
            variant="contained"
            color="error"
            disabled={!enableStopButton}
            onClick={
              enableStopButton ? stopRecording : () => console.log("disabled")
            }
          >
            Stop Recording
          </Button>
        </Box>

        <Box>{new Date(seconds * 1000).toISOString().substr(11, 8)}</Box>
        <Box>
          {isLoading && (
            <Box>
              <CircularProgress size="15px" />
            </Box>
          )}
        </Box>
      </Box>
      <Box p={2}>
        {audioStream && <Wave audio={audioStream} isRecording={isRecording} />}
      </Box>

      {error && <Box color={"red"}>{error}</Box>}
    </Box>
  );
}
