export const modalButton = {
  backgroundColor: "#2C73FF",
  boxShadow: "0px 11px 12px 0px rgba(44, 115, 255, 0.25)",
  borderRadius: "12px",
  width: "187px",
  height: "40px",
  color: "#fff",
  textTransform: "unset",
};

export const inviteButton = {
  backgroundColor: "#2C73FF",
  boxShadow: "0px 11px 12px 0px rgba(44, 115, 255, 0.25)",
  borderRadius: "8px",
  width: "152px",
  color: "#fff",
  textTransform: "unset",
};

export const InviteInput = {
  width: "300px",
  boxShadow: "0px 0px 10px #E5E6FF",
};
export const InviteSelect = {
  width: "180px",
  boxShadow: "0px 0px 10px #E5E6FF",
};
