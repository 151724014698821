import React, { useState, useEffect, useRef } from "react";

import { Box } from "@material-ui/core";

import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "throttle-debounce";
import "../../Tasks/ElasticAutocomplete.css";

const Account = ({
  account,
  setQuery,
  setContactAccount,
  setAccountSelected,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      width={1}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(account.name);
        setAccountSelected(true);
        setContactAccount({
          isNew: false,
          name: account.name,
          accountId: account.objectID,
        });
      }}
    >
      <p>{account.name}</p>
    </Box>
  );
};

const CreateAccount = ({
  query,
  setQuery,
  setContactAccount,
  setAccountSelected,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height={1}
      width={1}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        setQuery(query);
        setAccountSelected(true);
        setContactAccount({ isNew: true, name: query, id: null });
      }}
    >
      <p>create account:</p>
      <b style={{ marginLeft: "5px" }}> {query}</b>
    </Box>
  );
};

// export default function AlgoliaAccountAutocomplete(apiKey, setContactAccount) {
//   const appId = process.env.REACT_APP_ALGOLIA_APP_ID
//     ? process.env.REACT_APP_ALGOLIA_APP_ID
//     : "TWE65SAYW0";
//   const searchClient = algoliasearch(appId, apiKey);

//   const autocompleteInstance = autocomplete({
//     container: "#algolia-account-autocomplete",
//     debug: true,
//     renderer: { createElement, Fragment },
//     // render({ children }, root) {
//     //   render(children, root);
//     // },

//     render({ children }, root) {
//       const container = document.querySelector("#algolia-account-autocomplete");
//       const rect = container.getBoundingClientRect();
//       root.style.top = rect.top + rect.height + "px";

//       render(children, root);
//     },
//     openOnFocus: true,

//     getSources({ setQuery, refresh, setIsOpen, query }) {
//       return [
//         {
//           sourceId: "accounts",
//           getItems({ query, state }) {
//             let tmpQuery = {
//               indexName: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_accounts`,
//               query,
//               params: {
//                 hitsPerPage: 10,
//               },
//             };
//             return getAlgoliaResults({
//               searchClient,
//               queries: [tmpQuery],
//             });
//           },
//           templates: {
//             item({ state, item, components }) {
//               return (
//                 <Account
//                   account={item}
//                   setQuery={setQuery}
//                   setIsOpen={setIsOpen}
//                   setContactAccount={setContactAccount}
//                 />
//               );
//             },
//             noResults({ state }) {
//               if (!state.query.length) {
//                 setIsOpen(false);
//                 return false;
//               }
//               return (
//                 <CreateAccount
//                   query={query}
//                   setQuery={setQuery}
//                   setIsOpen={setIsOpen}
//                   setContactAccount={setContactAccount}
//                 />
//               );
//             },
//           },
//         },
//       ];
//     },
//   });

//   return autocompleteInstance;
// }

export default function ElasticAccountAutocomplete(props) {
  const { apiKey, setContactAccount, placeholder, defaultValue } = props;

  const elasticHost = process.env.REACT_APP_ELASTIC_HOST
    ? process.env.REACT_APP_ELASTIC_HOST
    : "https://bluecap.es.us-central1.gcp.cloud.es.io:9243";

  let [value, setValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  let [suggestions, setSuggestions] = useState([]);
  let [accountSelected, setAccountSelected] = useState(false);

  const onChange = (event, { newValue }) => {
    setValue(newValue);

    if (accountSelected) setAccountSelected(false);
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion.noResults) {
      return (
        <CreateAccount
          query={value}
          setQuery={setValue}
          setContactAccount={setContactAccount}
          setAccountSelected={setAccountSelected}
        />
      );
    } else {
      return (
        <Account
          account={suggestion}
          setQuery={setValue}
          setAccountSelected={setAccountSelected}
          setContactAccount={setContactAccount}
        />
      );
    }
  };

  const shouldRenderSuggestions = (value, reason) => {
    return !accountSelected;
  };

  let onSuggestionsFetchRequested = ({ value }) => {
    axios
      .post(
        `${elasticHost}/accounts/_search`,

        {
          query: {
            multi_match: {
              query: value,
              type: "bool_prefix",
              fields: ["name", "name._2gram", "name._3gram"],
            },
          },
          // sort: ["_score", { createdDate: "desc" }]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${apiKey}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.hits.hits.map((h) => h._source);
        console.log("results", results);
        if (results.length) {
          setSuggestions(results);
        } else {
          setSuggestions([{ noResults: true }]);
        }
      });
  };
  let onSuggestionsFetchRequestedDebounce = useRef(
    debounce(500, onSuggestionsFetchRequested)
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: placeholder ? placeholder : "Select accounts",
    value,
    onChange,
    className: `react-autosuggest__input ${
      props?.className ? props?.className : ""
    }`,
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      {/* <div>custom stuff</div> */}
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        {/* <div>
            Press Enter to search <strong>{query}</strong>
          </div> */}
      </div>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounce.current}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion.fullName}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
    />
  );
}
