import React, { useState, useEffect } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import ClearIcon from "@mui/icons-material/Clear";
import { getOrganization } from "../../firebase/firestore";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import DateRangePicker from "@mui/lab/DateRangePicker";
import moment from "moment";
import { CrmFilters } from "../meetingTable/tabularDataConstants";

const StyledBadge = styled(Badge)(() => ({
  position: "absolute",
  top: "5px",
  right: "5px",
  "& .MuiBadge-badge": {
    background: "#2c73ff",
    color: "#fff",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      // animation: "ripple 1.2s infinite ease-in-out",
      // border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

/**
 * Important note !
 * Columns operator's values must be unique and must match with elasticsearch response keys!
 */

/**
 * TODO -> startsWith, endsWith
 */

const leadStatusOptions = [
  {
    value: "Prospecting",
    label: "Prospecting",
  },
  {
    value: "Contact confirmed",
    label: "Contact confirmed",
  },
  {
    value: "Company confirmed",
    label: "Company confirmed",
  },
  {
    value: "Junk/Not qualified",
    label: "Junk/Not qualified",
  },
];

const channelOptions = [
  {
    value: "GOOGLE_MEET",
    label: "Google Meet",
  },
  {
    value: "ZOOM",
    label: "Zoom",
  },
  {
    value: "MS_TEAMS",
    label: "MS Teams",
  },
];

const sentimentOptions = [
  {
    value: "POSITIVE_2",
    label: "Very positive",
  },
  {
    value: "POSITIVE_1",
    label: "Positive",
  },
  {
    value: "NEUTRAL",
    label: "Neutral",
  },
  {
    value: "NEGATIVE_1",
    label: "Negative",
  },
  {
    value: "NEGATIVE_2",
    label: "Very negative",
  },
];

const SingleSelect = ({ value, setValue, selectOptions, label, ml }) => {
  return (
    <FormControl
      fullWidth
      sx={{ ml: ml ? 1 : 0, minWidth: "170px", maxWidth: "170px" }}
    >
      <InputLabel id="task-status" className="task-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="task-status"
        id="task-status"
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
        className="task-select"
        disabled={!selectOptions?.length}
      >
        {selectOptions?.map((operator, i) => (
          <MenuItem key={i} value={operator.value} className="task-select-item">
            {operator.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MultipleSelect = ({ label, value, setValue, selectOptions, ml }) => {
  return (
    <FormControl
      fullWidth
      sx={{ ml: ml ? 1 : 0, minWidth: "170px", maxWidth: "170px" }}
    >
      <InputLabel id="task-status" className="task-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="task-status"
        id="task-status"
        value={value}
        label={label}
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          setValue(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
          );
        }}
        className="task-select"
        disabled={!selectOptions?.length}
        multiple={true}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              return (
                <Chip
                  key={value}
                  label={
                    selectOptions.find((item) => item.value === value)?.label
                  }
                />
              );
            })}
          </Box>
        )}
      >
        {selectOptions?.map((operator, i) => (
          <MenuItem key={i} value={operator.value} className="task-select-item">
            {operator.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DateSelect = ({ date, setDate, isDisabled }) => {
  return (
    <Box display="flex" sx={{ ml: 1, width: "340px" }}>
      <DateRangePicker
        label="Advanced keyboard"
        value={date}
        reduceAnimations
        onChange={(newValue) => setDate(newValue)}
        disabled={isDisabled}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              id="standard-required"
              label="Start"
              placeholder="Filter value"
              variant="standard"
              ref={startProps.inputRef}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ minWidth: 125 }}
              {...startProps.inputProps}
            />
            <Box sx={{ mx: 1 }}> - </Box>
            <TextField
              id="standard-required"
              label="End"
              placeholder="Filter value"
              variant="standard"
              ref={endProps.inputRef}
              sx={{ minWidth: 125 }}
              InputLabelProps={{
                shrink: true,
              }}
              {...endProps.inputProps}
            />
          </React.Fragment>
        )}
      />
    </Box>
  );
};

function TabularFilterMenu({ type, setFilters }) {
  const [menuStatus, setMenuStatus] = useState(false);
  const [columnsSelect, setColumnsSelect] = useState("");
  const [operatorsSelect, setOperatorsSelect] = useState("");
  const [valueText, setValueText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [availableOperators, setAvailableOperators] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [date, setDate] = useState([
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - new Date().getDay() + 1,
      0
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - new Date().getDay() + 8,
      0
    ),
  ]);

  const autoDateChanger = () => {
    let value;
    if (operatorsSelect === "today") {
      value = [new Date(new Date().setHours(0, 0, 0)), new Date()];
    } else if (operatorsSelect === "yesterday") {
      value = [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 1,
          0
        ),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0
        ),
      ];
    } else if (operatorsSelect === "lastWeek") {
      value = [
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 6) % 7)
          )
        ),
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 6) % 7)
          ) +
            7 * 24 * 60 * 60 * 1000
        ),
      ];
    } else if (operatorsSelect === "lastMonth") {
      value = [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0),
        new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0),
      ];
    } else if (operatorsSelect === "last7days") {
      value = [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7,
          0
        ),
        new Date(),
      ];
    } else if (operatorsSelect === "last30days") {
      value = [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 30,
          0
        ),
        new Date(),
      ];
    }
    setDate(value);
  };

  useEffect(() => {
    let dateFields = [
      "today",
      "yesterday",
      "lastWeek",
      "lastMonth",
      "last7days",
      "last30days",
    ];
    if (dateFields.includes(operatorsSelect)) {
      autoDateChanger();
    }
    if (
      operatorsSelect === "isEmpty" ||
      operatorsSelect === "isNotEmpty" ||
      columnsSelect === "channelType"
    ) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [operatorsSelect]);

  const getRoleOptions = async () => {
    setIsSelect(true);
    let organization = await getOrganization();
    const fieldType =
      type === "leads" ? "leadFieldsSetting" : "contactFieldsSetting";
    const RoleOptions = organization[fieldType].find(
      (item) => item.title === "Role"
    )?.options;
    const roleOptionsArray = [];
    RoleOptions?.map((option) =>
      roleOptionsArray.push({ label: option.title, value: option.title })
    );
    setSelectOptions(roleOptionsArray);
  };

  const getAccountTypeOptions = async () => {
    setIsSelect(true);
    let organization = await getOrganization();
    const accountTypeOptions = organization["accountFieldsSetting"].find(
      (item) => item.title === "Account Type"
    )?.options;
    const accountTypeOptionsArray = [];
    accountTypeOptions?.map((option) =>
      accountTypeOptionsArray.push({
        label: option.title,
        value: option.title,
      })
    );
    setSelectOptions(accountTypeOptionsArray);
  };

  const getLeadStatusOptions = () => {
    setIsSelect(true);
    setSelectOptions(leadStatusOptions);
  };

  const getChannelOptions = () => {
    setIsSelect(true);
    setSelectOptions(channelOptions);
  };

  const getSentimentOptions = () => {
    setIsSelect(true);
    setSelectOptions(sentimentOptions);
  };

  useEffect(() => {
    if (columnsSelect === "role") {
      getRoleOptions();
    } else if (columnsSelect === "accountType") {
      getAccountTypeOptions();
    } else if (columnsSelect === "leadStatus") {
      getLeadStatusOptions();
    } else if (columnsSelect === "channelType") {
      getChannelOptions();
    } else if (columnsSelect === "sentiment") {
      getSentimentOptions();
    } else {
      setIsSelect(false);
    }
    setValueText("");
    let newAvailableOperators = CrmFilters.find(
      (item) => item.value === columnsSelect
    )?.operators;
    if (newAvailableOperators?.length) {
      setAvailableOperators(newAvailableOperators);
      setOperatorsSelect(newAvailableOperators[0]?.value);
    }
  }, [columnsSelect]);

  const handleMenuStatus = () => {
    setFilters(filterData);
    setMenuStatus(!menuStatus);
  };

  const handleApply = () => {
    console.log(filterData, "filter data");
    setFilters(filterData);
    setMenuStatus(false);
  };

  const handleAddFilter = () => {
    const getColumnData = CrmFilters.find(
      (item) => item.value === columnsSelect
    );
    const columnLabel = getColumnData?.label;
    const operatorLabel = getColumnData?.operators?.find(
      (item) => item.value === operatorsSelect
    )?.label;
    if (columnsSelect === "createdAt") {
      setFilterData([
        ...filterData,
        {
          columnLabel,
          operatorLabel:
            operatorLabel === "Custom"
              ? `${moment(date[0]).format("ll")} - ${moment(date[1]).format(
                  "ll"
                )}`
              : operatorLabel,
          columnValue: columnsSelect,
          operatorValue: operatorsSelect,
          value: date,
        },
      ]);
    } else if (
      operatorsSelect === "isEmpty" ||
      operatorsSelect === "isNotEmpty"
    ) {
      setFilterData([
        ...filterData,
        {
          columnLabel,
          operatorLabel,
          columnValue: columnsSelect,
          operatorValue: operatorsSelect,
          value: "",
        },
      ]);
    } else if (valueText !== "" && columnLabel && operatorLabel) {
      setFilterData([
        ...filterData,
        {
          columnLabel,
          operatorLabel,
          columnValue: columnsSelect,
          operatorValue: operatorsSelect,
          value: valueText,
        },
      ]);
      setValueText(operatorsSelect === "isAnyOf" ? [] : "");
    }
  };

  const handleClearFilter = (filter) => {
    let diff = filterData.filter((item) => ![filter].includes(item));
    setFilterData(diff);
  };

  return (
    <div className="filter-icon-container">
      <div
        className="lead-tabular-toolbar-icon filter-icon"
        onClick={handleMenuStatus}
      >
        <StyledBadge
          invisible={filterData?.length === 0}
          badgeContent={filterData?.length}
          max={9}
        />
        <FilterListIcon sx={{ fontSize: 18, color: "#2c73ff", mr: 1 }} />
        Filters
      </div>
      {menuStatus && (
        <div className="filter-menu">
          <div className="filter-selector">
            <SingleSelect
              value={columnsSelect}
              setValue={setColumnsSelect}
              selectOptions={CrmFilters.filter((item) =>
                type === "leads"
                  ? item.isLead
                  : type === "contacts"
                  ? item.isContact
                  : type === "accounts"
                  ? item.isAccount
                  : type === "pastMeetings"
                  ? item.isPastMeetings
                  : null
              )}
              label={"Columns"}
              ml={false}
            />
            <SingleSelect
              value={operatorsSelect}
              setValue={(newOperatorValue) => {
                if (
                  operatorsSelect === "isAnyOf" &&
                  newOperatorValue !== "isAnyOf"
                ) {
                  setValueText("");
                } else if (
                  operatorsSelect !== "isAnyOf" &&
                  newOperatorValue === "isAnyOf"
                ) {
                  setValueText([]);
                }
                setOperatorsSelect(newOperatorValue);
              }}
              selectOptions={availableOperators}
              label={"Operators"}
              ml={true}
            />
            {isSelect ? (
              operatorsSelect === "isAnyOf" ? (
                <MultipleSelect
                  selectOptions={selectOptions}
                  label={"Select value"}
                  ml={true}
                  value={valueText}
                  setValue={setValueText}
                />
              ) : operatorsSelect === "equals" ? (
                <SingleSelect
                  value={valueText}
                  setValue={setValueText}
                  selectOptions={selectOptions}
                  label={"Select value"}
                  ml={true}
                />
              ) : null
            ) : columnsSelect === "createdAt" ? (
              <DateSelect
                date={date}
                setDate={setDate}
                isDisabled={!(operatorsSelect === "custom")}
              />
            ) : (
              <TextField
                width="fullwidth"
                label="Value"
                variant="outlined"
                value={valueText}
                onChange={(e) => setValueText(e.target.value)}
                className={"tasks-title-input"}
                sx={{ ml: 1, minWidth: "170px", maxWidth: "170px" }}
              />
            )}
            <button
              className="modal-button blue ml"
              style={{ maxHeight: "unset", height: 43 }}
              onClick={handleAddFilter}
            >
              Add
            </button>
          </div>
          {filterData.map((filter, index) => (
            <div key={index} className="applied-filters">
              <div>
                <b>{filter.columnLabel}</b>
                {` ${filter.operatorLabel} `}
                {filter.columnValue !== "createdAt" && (
                  <u>
                    {filter.operatorValue === "isAnyOf"
                      ? filter.value?.join(", ")
                      : filter.value}
                  </u>
                )}
              </div>
              <ClearIcon onClick={() => handleClearFilter(filter)} />
            </div>
          ))}
          {filterData?.length ? (
            <div style={{ marginTop: ".5em" }}>
              <button className="modal-button blue" onClick={handleApply}>
                Apply
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default TabularFilterMenu;
