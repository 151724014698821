import React, { useEffect, useState } from "react";
import { Grid, TextField, Box, AppBar, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { makeStyles, withStyles } from "@material-ui/styles";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { blue } from "@material-ui/core/colors";
import { Checkbox } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormLabel from "@material-ui/core/FormLabel";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Stack from "@mui/material/Stack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomCheckbox = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pl: {
    paddingLeft: "20px",
  },
  bg: {
    backgroundColor: "unset",
    boxShadow: "unset",
    fontWeight: "500",
  },
  mb20: {
    paddingTop: "20px",
  },
  radioColor: {
    color: "#2C73FF !important",
  },
  lableFont: {
    color: "#000",
    fontWeight: "600",
  },
  priceTxt: {
    color: "#2C73FF",
    fontSize: "22px",
  },
}));

function AddMeetingTopics({ ...props }) {
  const { meetingId, meetingDetails, topics } = props;
  let meetingTopics = meetingDetails?.topics;
  const [allTopics, setAllTopics] = useState([]);
  useEffect(() => {
    if (!topics || topics?.length === 0) {
      return;
    }
    let newAllTopics = [];
    topics.map(topic => newAllTopics.push({...topic,selected:false}));
    if (meetingTopics?.length > 0) {
      meetingTopics?.map((meetingTopic) => {
        let findInAllTopics = newAllTopics?.find(
          (item) => item.topicName === meetingTopic
        );
        if (findInAllTopics) {
          findInAllTopics.selected = true;
        }
      });
    }
    setAllTopics(newAllTopics);
  }, [meetingTopics, topics]);

  useEffect(async () => {
    await props.fetchTopicsRequest();
    await props.fetchMeetingRequest({
      meetingId: props.meetingId,
    });
  }, []);

  const classes = useStyles();

  const onToggleTopic = (val) => {
    let optedVal = val.topic;
    let findTopic = allTopics?.find(item => item.topicName === optedVal);
    if(findTopic) {
      if(findTopic?.selected) {
        props.deleteMeetingTopicRequest({
          meetingId,
          topic:optedVal
        })
      } else {
        props.addMeetingTopicRequest({
          meetingId,
          topic:optedVal
        })
      }
    }
    if (optedVal) {
      props.showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
    } else {
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  return (
    <div>
      {props.topics.length !== 0 && (
        <FormControl variant="standard" sx={{ marginLeft: 0 }}>
          <Typography
            className={classes.mb10}
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            Meeting Purposes/goals
          </Typography>
          <Box display="flex" flexWrap="wrap" style={{ margin: "15px 0" }}>
            {allTopics?.map((topic, key) => {
              return (
                <div
                  key={key}
                  className={`meeting-detail-topic ${topic?.selected ? "selected" : ""}`}
                  onClick={() =>
                    onToggleTopic({
                      topic: topic.topicName,
                    })
                  }
                >
                  <CheckCircleIcon className={"selected-icon"} />
                  {topic?.topicName}
                </div>
              );
            })}
          </Box>
        </FormControl>
      )}
    </div>
  );
}

AddMeetingTopics.propTypes = {
  meetingId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  topics: (state.settings.topics && state.settings.topics.data) || [],
  meetingDetails:
    (state.meetings &&
      state.meetings.meetingDetails &&
      state.meetings.meetingDetails.data) ||
    {},
});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
  fetchTopicsRequest: (data) => dispatch(actions.fetchTopicsRequest(data)),
  fetchMeetingRequest: (data) => dispatch(actions.fetchMeetingRequest(data)),
  addMeetingTopicRequest: (data) =>
    dispatch(actions.addMeetingTopicRequest(data)),
  deleteMeetingTopicRequest: (data) =>
    dispatch(actions.deleteMeetingTopicRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMeetingTopics);
