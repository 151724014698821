import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Topics from "./Topics";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";
import Entity from "./Entity";

const useStyles = makeStyles((theme) => ({
  chipBox: {
    marginLeft: "-10px",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    display: "inline-block",
    padding: "4px 10px",
    height: "32px",
    fontSize: "0.8125rem",
    borderRadius: "25px",
    fontFamily: "Poppins",
    color: "#212B36",
    marginLeft: "5px",
    marginTop: "10px",
  },
  chipNo: {
    backgroundColor: "#F0F0F0",
    borderRadius: "25px",
    padding: "0px 7px",
  },
}));

const getSentiment = (sentiment) => {
  let sBorder;
  let tsentiment = sentiment;
  switch (tsentiment) {
    case "MIXED":
      sBorder = "1px solid #2C73FF";
      break;
    case "NEUTRAL":
      sBorder = "1px solid #F8B259";
      break;
    case "POSITIVE_1":
      sBorder = "1px solid #D4E536";
      break;
    case "POSITIVE_2":
      sBorder = "1px solid #3EC30F";
      break;
    case "NEGATIVE_1":
      sBorder = "1px solid #D95E41";
      break;
    case "NEGATIVE_2":
      sBorder = "1px solid #FF0000";
      break;
    default:
      sBorder = "1px solid #2C73FF";
  }
  return sBorder;
};

const sentimentToColor = (sentiment, isSentimentAvailable) => {
  if (!isSentimentAvailable) {
    return "#000";
  }
  if (sentiment === "POSITIVE_2") {
    return "#00C800";
  } else if (sentiment === "POSITIVE_1") {
    return "#b3cb00";
  } else if (sentiment === "NEUTRAL" || sentiment === "mixed") {
    return "#FFB043";
  } else if (sentiment === "NEGATIVE_1") {
    return "#EA5435";
  } else if (sentiment === "NEGATIVE_2") {
    return "#FF0000";
  } else {
    return null;
  }
};

const getEntity = (entity, setName, setEntityObj) => {
  // console.log("entity", entity);
  let topicSentiments = [];
  let chipBorder = "";
  if (entity.length > 0) {
    entity.map((item) => {
      if (item.sentiment !== null && item.sentiment.emotion) {
        chipBorder = getSentiment(item.sentiment.emotion);
        topicSentiments.push({
          name: item.name,
          chipBorder: chipBorder,
          emotion: item.sentiment.emotion,
          score: item.sentiment.score,
          magnitude: item.sentiment.magnitude,
          timestamp: item?.timestamp,
        });
      } else {
        chipBorder = getSentiment("NEUTRAL");
        topicSentiments.push({
          name: item.name,
          chipBorder: chipBorder,
          emotion: "",
          score: 0,
          magnitude: 0,
          timestamp: item?.timestamp,
        });
      }
    });

    let resultArr = [];
    let resultObj = {};
    let count = "";
    topicSentiments.map((item) => {
      if (!resultArr.includes(item.name)) {
        resultArr.push(item.name);
      }
      // console.log("item", item);
      if (resultObj[item.name]) {
        count = resultObj[item.name].count++;
        resultObj[item.name].items.push(item);
      } else {
        resultObj[item.name] = { count: 1, items: [item] };
      }
    });
    setName(resultArr);
    setEntityObj(resultObj);
  } else {
    setName([]);
  }
};

function ShowEntity(props) {
  const classes = useStyles();
  let entity = props.entity;
  let entityObj = props.entityObj;
  const [expandedEntity, setExpandedEntity] = useState(null);

  const handleExpand = (entity) => {
    setExpandedEntity(entity);
  };

  return (
    <>
      <Box className={classes.chipBox}>
        {entity.map((item, index) => {
          let score = 0;
          let magnitude = 0;
          let mAvg = 0;
          let sAvg = 0;
          let objLength = 0;
          let sentimentColor = "";
          let emotion = "";
          let itemList = [];

          if (Object.keys(entityObj).length > 0) {
            if (entityObj[item].items.length > 1) {
              itemList = entityObj[item].items;
              objLength = itemList.length;
              entityObj[item].items.map((item, index) => {
                score += item.score;
                magnitude += item.magnitude;
              });
              sAvg = score / objLength;
              mAvg = magnitude / objLength;

              if (sAvg >= -0.1 && sAvg <= 0.1 && mAvg > 3) {
                emotion = "mixed";
                sentimentColor = sentimentToColor(
                  emotion,
                  props.isSentimentAvailable
                );
              } else if (sAvg > 0.1) {
                emotion = "POSITIVE_1";
                sentimentColor = sentimentToColor(
                  emotion,
                  props.isSentimentAvailable
                );

                if (magnitude > 3) {
                  emotion = "POSITIVE_2";
                  sentimentColor = sentimentToColor(
                    emotion,
                    props.isSentimentAvailable
                  );
                }
              } else if (sAvg < -0.1) {
                emotion = "NEGATIVE_1";
                sentimentColor = sentimentToColor(
                  emotion,
                  props.isSentimentAvailable
                );
                if (mAvg > 3) {
                  emotion = "NEGATIVE_2";
                  sentimentColor = sentimentToColor(
                    emotion,
                    props.isSentimentAvailable
                  );
                }
              } else {
                emotion = "NEUTRAL";
                sentimentColor = sentimentToColor(
                  emotion,
                  props.isSentimentAvailable
                );
              }
            } else {
              emotion = entityObj[item].items[0].emotion;
              sentimentColor = sentimentToColor(
                entityObj[item].items[0].emotion,
                props.isSentimentAvailable
              );
            }
          }
          return (
            <Entity
              key={index}
              entityName={item}
              emotionKey={emotion}
              entityCount={objLength}
              sentimentColor={sentimentColor}
              handleExpand={handleExpand}
              expandedEntity={expandedEntity}
              timestamp={
                entityObj[item]?.items
                  ?.filter((item) => item.timestamp)
                  ?.map((item) => item.timestamp) || null
              }
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={props.isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={props.type}
              elasticToken={props.elasticToken}
            />
          );
        })}
        {/*
            entity.map((item, index) => {
            let score = 0;
            let magnitude = 0;
            let mAvg = 0;
            let sAvg = 0;
            let objLength = 0;
            let chipBorder = "";
            let emotion = "";
            let itemList = [];

            if (Object.keys(entityObj).length > 0) {
              if (entityObj[item].items.length > 1) {
                // console.log("entityObj", entityObj[item].items);
                itemList = entityObj[item].items;
                objLength = entityObj[item].items.length;
                entityObj[item].items.map((item, index) => {
                  score += item.score;
                  magnitude += item.magnitude;
                });
                sAvg = score / objLength;
                mAvg = magnitude / objLength;

                if (sAvg >= -0.1 && sAvg <= 0.1 && mAvg > 3) {
                  emotion = "mixed";
                  chipBorder = getSentiment(emotion);
                } else if (sAvg > 0.1) {
                  emotion = "POSITIVE_1";
                  chipBorder = getSentiment(emotion);

                  if (magnitude > 3) {
                    emotion = "POSITIVE_2";
                    chipBorder = getSentiment(emotion);
                  }
                } else if (sAvg < -0.1) {
                  emotion = "NEGATIVE_1";
                  chipBorder = getSentiment(emotion);
                  if (mAvg > 3) {
                    emotion = "NEGATIVE_2";
                    chipBorder = getSentiment(emotion);
                  }
                } else {
                  emotion = "NEUTRAL";
                  chipBorder = getSentiment(emotion);
                }
              } else {
                chipBorder = entityObj[item].items[0].chipBorder;
              }
              // console.log("chipBorder", chipBorder);
            }

            return (
              <div
                key={index}
                className={classes.chip}
                style={{
                  border: chipBorder,
                }}
                // onClick={handleClick}
              >
                {item}{" "}
                {objLength > 0 && (
                  <span className={classes.chipNo}> x {objLength} </span>
                )}
              </div>
            );
          })
          */}
      </Box>
    </>
  );
}

function Products(props) {
  const classes = useStyles();
  const [expandedEntity, setExpandedEntity] = useState(null);

  const handleExpand = (entity) => {
    setExpandedEntity(entity);
  };

  return (
    <Box className={classes.chipBox}>
      {props.products.map((product, index) => {
        return (
          <Entity
            key={index}
            sentimentColor={sentimentToColor(
              product.emotion,
              props.isSentimentAvailable
            )}
            entityName={product.name}
            emotionKey={product.emotion}
            handleExpand={handleExpand}
            expandedEntity={expandedEntity}
            entityCount={product.count}
            entityToMarks={props.entityToMarks}
            isSentimentAvailable={props.isSentimentAvailable}
            setActiveEntity={props.setActiveEntity}
            elasticToken={props.elasticToken}
          />
        );
      })}
    </Box>
  );
}

function Competitors(props) {
  const classes = useStyles();
  const [expandedEntity, setExpandedEntity] = useState(null);

  const handleExpand = (entity) => {
    setExpandedEntity(entity);
  };
  return (
    <Box className={classes.chipBox}>
      {props.competitors.map((competitor, index) => {
        return (
          <Entity
            key={index}
            sentimentColor={sentimentToColor(
              competitor.emotion,
              props.isSentimentAvailable
            )}
            emotionKey={competitor.emotion}
            entityName={competitor.name}
            handleExpand={handleExpand}
            expandedEntity={expandedEntity}
            entityCount={competitor.count}
            entityToMarks={props.entityToMarks}
            isSentimentAvailable={props.isSentimentAvailable}
            setActiveEntity={props.setActiveEntity}
            elasticToken={props.elasticToken}
          />
        );
      })}
    </Box>
  );
}

function MeetingEntities(props) {
  const classes = useStyles();
  const { fireStoreuserObj } = useAuth();
  const [products, setProducts] = React.useState([]);
  const [competitors, setCompetitors] = React.useState([]);
  const [mEvents, setMevents] = React.useState([]);
  const [mEventsObj, setmEventsObj] = React.useState([]);
  const [mLocation, setMlocation] = React.useState([]);
  const [mLocationObj, setMlocationObj] = React.useState([]);
  const [other, setOther] = React.useState([]);
  const [organization, setOrganization] = React.useState([]);
  const [organizationObj, setOrganizationObj] = React.useState([]);
  const [person, setPerson] = React.useState([]);
  const [personObj, setPersonObj] = React.useState([]);
  const [workOfArt, setWorkOfArt] = React.useState([]);
  const [workOfArtObj, setWorkOfArtObj] = React.useState([]);
  const [consumerGood, setConsumerGood] = React.useState([]);
  const [consumerGoodObj, setConsumerGoodObj] = React.useState([]);
  const [address, setAddress] = React.useState([]);
  const [addressObj, setAddressObj] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState([]);
  const [phoneNumberObj, setPhoneNumberObj] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [dateObj, setDateObj] = React.useState([]);
  const [number, setNumber] = React.useState([]);
  const [numberObj, setNumberObj] = React.useState([]);
  const [price, setPrice] = React.useState([]);
  const [priceObj, setPriceObj] = React.useState([]);
  const [otherObj, setOtherObj] = React.useState([]);

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [loading, setLoading] = useState(true);
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let meetingEntityList = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/entities/` +
            props.meetingId,
          jwtToken
        );

        let productCompititers = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/meeting/getSettingPageData/` +
            props.meetingId,
          jwtToken
        );

        let organizationSettings = await getOrgainzation(
          fireStoreuserObj.organizationId
        );
        let sentimentSettings = organizationSettings?.sentimentSettings;
        let isSentimentAvailableCheck =
          (fireStoreuserObj.role === "MEMBER" &&
            sentimentSettings?.users === "all") ||
          (fireStoreuserObj.role === "TEAM_MANAGER" &&
            sentimentSettings?.managers === "all") ||
          (fireStoreuserObj.role === "ADMIN" &&
            sentimentSettings?.admins === "all");
        setIsSentimentAvailable(
          !sentimentSettings ? true : isSentimentAvailableCheck
        );

        if (productCompititers.data.products) {
          let products = [];
          let chipBorder = "";
          Object.keys(productCompititers.data.products).map(function (key) {
            chipBorder = getSentiment(
              productCompititers.data.products[key].emotion
            );
            products.push({
              name: key,
              emotion: productCompititers.data.products[key].emotion,
              count: productCompititers.data.products[key].count,
              score: productCompititers.data.products[key].score,
              magnitude: productCompititers.data.products[key].magnitude,
              chipBorder: chipBorder,
            });
          });
          setProducts(products);
        }

        if (productCompititers.data.competitors) {
          let competitors = [];
          let chipBorder = "";
          Object.keys(productCompititers.data.competitors).map(function (key) {
            chipBorder = getSentiment(
              productCompititers.data.competitors[key].emotion
            );
            competitors.push({
              name: key,
              emotion: productCompititers.data.competitors[key].emotion,
              count: productCompititers.data.competitors[key].count,
              score: productCompititers.data.competitors[key].score,
              magnitude: productCompititers.data.competitors[key].magnitude,
              chipBorder: chipBorder,
            });
          });
          setCompetitors(competitors);
        }

        if (meetingEntityList.status == "success") {
          console.log("meetingEntityList", meetingEntityList.data);

          meetingEntityList.data && meetingEntityList.data.EVENT
            ? getEntity(meetingEntityList.data.EVENT, setMevents, setmEventsObj)
            : setMevents([]);
          meetingEntityList.data && meetingEntityList.data.LOCATION
            ? getEntity(
                meetingEntityList.data.LOCATION,
                setMlocation,
                setMlocationObj
              )
            : setMlocation([]);
          meetingEntityList.data && meetingEntityList.data.ORGANIZATION
            ? getEntity(
                meetingEntityList.data.ORGANIZATION,
                setOrganization,
                setOrganizationObj
              )
            : setOrganization([]);
          meetingEntityList.data && meetingEntityList.data.PERSON
            ? getEntity(meetingEntityList.data.PERSON, setPerson, setPersonObj)
            : setPerson([]);
          meetingEntityList.data && meetingEntityList.data.WORK_OF_ART
            ? getEntity(
                meetingEntityList.data.WORK_OF_ART,
                setWorkOfArt,
                setWorkOfArtObj
              )
            : setWorkOfArt([]);
          meetingEntityList.data && meetingEntityList.data.CONSUMER_GOOD
            ? getEntity(
                meetingEntityList.data.CONSUMER_GOOD,
                setConsumerGood,
                setConsumerGoodObj
              )
            : setConsumerGood([]);
          meetingEntityList.data && meetingEntityList.data.ADDRESS
            ? getEntity(
                meetingEntityList.data.ADDRESS,
                setAddress,
                setAddressObj
              )
            : setAddress([]);
          meetingEntityList.data && meetingEntityList.data.PHONE_NUMBER
            ? getEntity(
                meetingEntityList.data.PHONE_NUMBER,
                setPhoneNumber,
                setPhoneNumberObj
              )
            : setPhoneNumber([]);
          meetingEntityList.data && meetingEntityList.data.DATE
            ? getEntity(meetingEntityList.data.DATE, setDate, setDateObj)
            : setDate([]);
          meetingEntityList.data && meetingEntityList.data.NUMBER
            ? getEntity(meetingEntityList.data.NUMBER, setNumber, setNumberObj)
            : setNumber([]);
          meetingEntityList.data && meetingEntityList.data.PRICE
            ? getEntity(meetingEntityList.data.PRICE, setPrice, setPriceObj)
            : setPrice([]);
          meetingEntityList.data && meetingEntityList.data.OTHER
            ? getEntity(meetingEntityList.data.OTHER, setOther, setOtherObj)
            : setPrice([]);
        }
        setLoading(false);
      });
  }, []);

  return (
    <Box height={"100%"}>
      {loading && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
        >
          <CircularProgress style={{ color: "#2c73ff" }} />
        </Box>
      )}
      {!loading && products.length > 0 && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            // id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Products
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <Products
              products={products}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && competitors.length > 0 && (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            // id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Competitors
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <Competitors
              competitors={competitors}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && (
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            // id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Topics</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <Topics
              meetingTopics={props.meetingTopics}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && mEvents.length > 0 && (
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Event</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={mEvents}
              entityObj={mEventsObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={"event"}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && mLocation.length > 0 && (
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Location
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={mLocation}
              entityObj={mLocationObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && organization.length > 0 && (
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Organization
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={organization}
              entityObj={organizationObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={"organization"}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && person.length > 0 && (
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Person</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={person}
              entityObj={personObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={"contact"}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && workOfArt.length > 0 && (
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Work of art
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={workOfArt}
              entityObj={workOfArtObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!loading && consumerGood.length > 0 && (
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Consumer Good
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={consumerGood}
              entityObj={consumerGoodObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && address.length > 0 && (
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Address
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={address}
              entityObj={addressObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!loading && phoneNumber.length > 0 && (
        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Phone Number
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={phoneNumber}
              entityObj={phoneNumberObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!loading && date.length > 0 && (
        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Date</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={date}
              entityObj={dateObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={"date"}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!loading && number.length > 0 && (
        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Number</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={number}
              entityObj={numberObj}
              entityToMarks={props.entityToMarks}
              type={"number"}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {!loading && price.length > 0 && (
        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Price</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={price}
              entityObj={priceObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              type={"price"}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {!loading && other.length > 0 && (
        <Accordion
          expanded={expanded === "panel15"}
          onChange={handleChange("panel15")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            // id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Other</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "0" }}>
            <ShowEntity
              entity={other}
              entityObj={otherObj}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              elasticToken={props.elasticToken}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

export default MeetingEntities;
