import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AccountsList from "./AccountsList";
export default function Accounts() {
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column">
      <Box marginTop={1}>
        <AccountsList />
      </Box>
    </Box>
  );
}
