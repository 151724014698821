import React, { useEffect, useState } from "react";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";
import { Box, CircularProgress } from "@material-ui/core";
import { sentimentIcons } from "../../utils/sentimentIcons";
import ModalMeetingsTable from "../modalMeetingsTable";
import { getOrgainzation } from "../../firebase/firestore";
import useAuth from "../../hooks/useAuth";

function ContactsProductsTable({
  title,
  subtitle,
  meetingsData,
  fetchLoading,
  dateRangeController,
}) {
  const { fireStoreuserObj } = useAuth();
  const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
  const [data, setData] = useState({});
  const [previousData, setPreviousData] = useState({});
  const [loading, setLoading] = useState({});
  const [total, setTotal] = useState(0);
  const [totalDelta, setTotalDelta] = useState("");
  const [isParentDataShouldChange, setIsParentDataShouldChange] =
    useState(false);
  const [meetingModal, setMeetingModal] = useState({ status: false, key: "" });
  const [isSentimentAvailable, setIsSentimentAvailable] = useState(false);

  const transformData = async () => {
    setLoading(true);
    let currentMeetingData = {};
    let previousMeetingData = {};
    const backendEndpoint =
      title === "Products" ? "matchedProducts" : "matchedCompetitors";

    let organizationSettings = await getOrgainzation(
      fireStoreuserObj.organizationId
    );
    let sentimentSettings = organizationSettings?.sentimentSettings;
    let isSentimentAvailableCheck =
      (fireStoreuserObj.role === "MEMBER" &&
        sentimentSettings?.users === "all") ||
      (fireStoreuserObj.role === "TEAM_MANAGER" &&
        sentimentSettings?.managers === "all") ||
      (fireStoreuserObj.role === "ADMIN" &&
        sentimentSettings?.admins === "all");
    setIsSentimentAvailable(
      !sentimentSettings ? true : isSentimentAvailableCheck
    );

    meetingsData?.map((meeting) => {
      const meetingDate = new Date(meeting.startTime * 1000);
      let data = meeting[backendEndpoint];
      Object.keys(data).map((value, index) => {
        if (
          meetingDate >
          new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000
        ) {
          // meeting inside of date range
          if (Object.keys(currentMeetingData).indexOf(value) > -1) {
            currentMeetingData[value].count +=
              Object.values(data)[index]?.count;
            currentMeetingData[value].meetingIds.push(meeting?.meetingId);
          } else {
            currentMeetingData[value] = {
              count: Object.values(data)[index]?.count,
              sentiment: Object.values(data)[index]?.emotion,
              meetingIds: [meeting?.meetingId],
            };
          }
        } else {
          // previous meetings
          if (Object.keys(previousMeetingData).indexOf(value) > -1) {
            previousMeetingData[value] += Object.values(data)[index]?.count;
          } else {
            previousMeetingData[value] = Object.values(data)[index]?.count;
          }
        }
      });
    });
    setData(currentMeetingData);
    setPreviousData(previousMeetingData);

    const sum = (previous, current) => {
      if (!current) {
        return previous;
      }
      return previous + current;
    };
    const totalCurrent =
      Object.values(currentMeetingData).length > 0
        ? Object.values(currentMeetingData)
            .map((item) => item.count)
            .reduce(sum)
        : 0;
    const totalPrevious =
      Object.values(previousMeetingData).length > 0
        ? Object.values(previousMeetingData).reduce(sum)
        : 0;

    const calculateTotalDelta = () => {
      let diffirence = Math.ceil(
        ((totalCurrent - totalPrevious) / totalPrevious) * 100
      );
      if (totalPrevious === 0) {
        diffirence = 100;
      }
      if (totalCurrent === 0 && totalPrevious === 0) {
        diffirence = 0;
      }
      return `${diffirence > 0 ? "+" : diffirence === 0 ? "" : "-"}${
        diffirence < 0 ? diffirence * -1 : diffirence
      }%`;
    };

    setTotal(totalCurrent);
    setTotalDelta(calculateTotalDelta());

    setLoading(false);
    setIsParentDataShouldChange(false);
  };

  useEffect(transformData, [isParentDataShouldChange, meetingsData]);

  const calculateDelta = (key) => {
    const currentCount = data[key]?.count || 0;
    const previousCount = previousData[key] || 0;
    let diffirence = Math.ceil(
      ((currentCount - previousCount) / previousCount) * 100
    );
    if (previousCount === 0) {
      diffirence = 100;
    }
    if (currentCount === 0 && previousCount === 0) {
      diffirence = 0;
    }
    return `${diffirence}%`;
  };

  return (
    <div className="chart table">
      {meetingModal?.status && (
        <ModalMeetingsTable
          open={meetingModal.status}
          onClose={() => setMeetingModal({ status: false, key: "" })}
          meetingIds={data[meetingModal.key]?.meetingIds}
        />
      )}
      <div className="chart-header">
        <h6>{title}</h6>
        <DateRangeDropdown
          selected={dateRange}
          setDateRange={(newDateRange) => {
            setDateRange(newDateRange);
            if (dateRangeController(newDateRange)) {
              setLoading(true);
            } else {
              setIsParentDataShouldChange(true);
            }
          }}
        />
      </div>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={300}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          <div className="contacts-total">
            <div className="count-container">
              <h6>{subtitle}</h6>
              <div className="count">
                <span>{total}</span>
                <span>{totalDelta}</span>
              </div>
            </div>
            <div className="hr" />
          </div>
          {Object.keys(data)?.length > 0 && (
            <div className="table-container">
              <table className="contacts-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Counts</th>
                    <th>Delta</th>
                    {isSentimentAvailable && <th>Sentiment</th>}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((item, index) => (
                    <tr
                      key={index}
                      onClick={() =>
                        setMeetingModal({ status: true, key: item })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{item}</td>
                      <td>{Object.values(data)[index].count}</td>
                      <td>{calculateDelta(item)}</td>
                      {isSentimentAvailable && (
                        <td>
                          {sentimentIcons[Object.values(data)[index].sentiment]}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ContactsProductsTable;
