import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import NoteTextEditor from "../CRM/NoteTextEditor";
import { convertToRaw, EditorState } from "draft-js";
import { Get, Post } from "../../utils/axiosUtils";
import firebase from "../../firebase/firebase";
import { getData } from "../../utils";
import useAuth from "../../hooks/useAuth";
import { Box, CircularProgress } from "@material-ui/core";
import NotesAccordion from "../CRM/NotesAccordion";

function MeetingDetailNotes({ meetingId }) {
  const { fireStoreuserObj } = useAuth();
  const [mentions, setMentions] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [createMode, setCreateMode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedNote, setExpandedNote] = useState(null);

  const getMentionOptions = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (jwtToken) => {
          let result = await getData(
            `${process.env.REACT_APP_BASE_API_URL}/organization/members/${fireStoreuserObj.organizationId}`,
            jwtToken
          );
          if (result.status === "success") {
            let suggestionData = result.data.members?.filter(
              (member) => member.id !== fireStoreuserObj.uid
            );
            let suggestionToEditor = [];
            suggestionData?.map((member) => {
              suggestionToEditor.push({
                name: member?.name,
                url: "",
                avatar: member?.avatar,
                id: member?.id,
              });
            });
            setMentions(suggestionToEditor);
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getNotes = () => {
    Get(`notes/meetings/${meetingId}`)
      .then(({ data }) => {
        console.log(data,"NOTES")
        setNotes(data.data);
        getMentionOptions()
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log("mentions couldnt loaded successfully", err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("err happing when fetching notes", err);
      });
  };

  useEffect(getNotes, [meetingId]);

  const createNote = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    let postBody = {
      type: "meetings",
      typeid: meetingId,
      content: convertToRaw(editorState.getCurrentContent()),
      title,
    };
    Post("notes/create", postBody)
      .then(({ data }) => {
        if (attachments?.length > 0) {
          attachments.map((file) => {
            let formData = new FormData();
            formData.append("attachment", file);
            formData.append("type", "notes");
            formData.append("typeid", data.data.id);
            Post("attachments/create", formData, {
              "Content-Type": "multipart/form-data; boundary=1",
            }).catch((err) => {
              console.log(err, "error when uploading files");
            });
          });
          setAttachments([]);
        }
        setNotes([data.data, ...notes]);
        setCreateLoading(false);
        setTitle("");
        setEditorState(EditorState.createEmpty());
        setCreateMode(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fileUpload = (e) => {
    let newFiles = [...attachments, ...e.target.files];
    setAttachments(newFiles);
  };

  const removeAttachment = (file) => {
    let files = [...attachments];
    let filteredFiles = files.filter((item) => item.name !== file.name);
    setAttachments(filteredFiles);
  };

  const clearAnimation = () => {
    setTimeout(() => {
      let doc = document.getElementById("createNoteContainer");
      if (doc) {
        doc.style.animation = "1s anyanimation forwards"; // this line just placeholder for removing current animation
        doc.style.maxHeight = "65vh";
        doc.style.minHeight = "260px";
        doc.style.height = "auto";
      }
    }, 800);
  };

  return (
    <div className="meeting-detail-tab-container">
      <div
        className="add-attachment"
        onClick={() => {
          setCreateMode(!createMode);
          clearAnimation();
        }}
      >
        <AddIcon
          fontSize={"medium"}
          sx={{ color: "#2C73FF", cursor: "pointer", ml: 1 }}
          className={createMode ? "rotateBack" : "rotateIcon"}
        />
        <span>Add note</span>
      </div>
      {createMode && loading ? (
        <Box
          sx={{ width: "100%" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress style={{ color: "#2c73ff" }} size={"16px"} />
        </Box>
      ) : (
        createMode && (
          <NoteTextEditor
            mentionSuggestions={mentions}
            editorState={editorState}
            setEditorState={setEditorState}
            title={title}
            setTitle={setTitle}
            buttonLoading={createLoading}
            onSubmit={createNote}
            attachments={attachments}
            onFileUpload={fileUpload}
            onRemoveAttachment={removeAttachment}
          />
        )
      )}
      <>
        {notes?.map((note, index) => (
          <NotesAccordion
            key={index}
            mentionSuggestions={mentions}
            setNotes={setNotes}
            notes={notes}
            note={note}
            expandedNote={expandedNote}
            setExpandedNote={setExpandedNote}
          />
        ))}
      </>
    </div>
  );
}

export default MeetingDetailNotes;
