import React, { useEffect, useState } from "react";

import { Grid, Box, CircularProgress } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { getData, postData } from "../../utils";
import firebase from "../../firebase/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pl: {
    paddingLeft: "20px",
  },
  bg: {
    backgroundColor: "unset",
    boxShadow: "unset",
    fontWeight: "500",
  },
  mb20: {
    paddingTop: "20px",
  },
  radioColor: {
    color: "#2C73FF !important",
  },
  lableFont: {
    color: "#000",
    fontWeight: "600",
  },
  priceTxt: {
    color: "#2C73FF",
    fontSize: "22px",
  },
  disabledButton: {
    cursor: "not-allowed !important",
    pointerEvents: "all !important",
  },
}));

export default function MeetingAccessSettings({ role, showSnackbar }) {
  let classes = useStyles();

  let [accessType, setAccessType] = useState(null);
  let [accessLevel, setAccessLevel] = useState(null);

  let loadMeetingAccessSettings = async () => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let meetingAccessData = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/user/get-meeting-access-settings`,
      jwtToken
    );

    if (meetingAccessData.status === "success") {
      setAccessLevel(meetingAccessData.data.accessLevel);
      setAccessType(meetingAccessData.data.accessType);
    }
  };

  useEffect(() => {
    loadMeetingAccessSettings();
  }, []);

  const handleChangeMeetingAccessSettings = async (event) => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let meetingAccessData = await postData(
      `${process.env.REACT_APP_BASE_API_URL}/user/update-meeting-access-settings`,
      jwtToken,
      { accessType: event.target.value, accessLevel: "user" }
    );

    if (meetingAccessData.status === "success") {
      showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      loadMeetingAccessSettings();
    }
  };

  const handleChangeOrgMeetingAccessSettings = async (event) => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let meetingAccessData = await postData(
      `${process.env.REACT_APP_BASE_API_URL}/user/update-meeting-access-settings`,
      jwtToken,
      { accessType: event.target.value, accessLevel: "org" }
    );

    if (meetingAccessData.status === "success") {
      showSnackbar({
        show: true,
        severity: "success",
        message: "Setting updated successfully",
      });
      loadMeetingAccessSettings();
    }
  };

  if (!accessLevel || !accessType) {
    return (
      <Box display="flex" alignItems={"center"}>
        <p>Loading meeting access settings!</p>
        <CircularProgress style={{ marginLeft: "10px" }} size="15px" />
      </Box>
    );
  }
  return (
    <>
      {role === "ADMIN" && (
        <Grid container spacing={2} className={classes.mb20}>
          <Grid item xs={5} md={2}>
            <FormLabel component="legend" className={classes.lableFont}>
              Organization Meeting Access Settings
            </FormLabel>
          </Grid>
          <Grid item xs={7} md={10}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="meeting-settings"
                name="meeting-settings"
                value={accessLevel === "user" ? "default" : accessType}
                onChange={handleChangeOrgMeetingAccessSettings}
              >
                <FormControlLabel
                  value="private"
                  control={<Radio className={classes.radioColor} />}
                  label="All Meetings are Private"
                />
                <FormControlLabel
                  value="org_public"
                  control={<Radio className={classes.radioColor} />}
                  label="Share all Meetings with Organization Members"
                />
                <FormControlLabel
                  value="default"
                  control={<Radio className={classes.radioColor} />}
                  label="Allow the member to choose"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {accessLevel === "user" && (
        <Grid container spacing={2} className={classes.mb20}>
          <Grid item xs={5} md={2}>
            <FormLabel component="legend" className={classes.lableFont}>
              Meeting Access Settings
            </FormLabel>
          </Grid>
          <Grid item xs={7} md={10}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="meeting-settings"
                name="meeting-settings"
                value={accessType}
                onChange={handleChangeMeetingAccessSettings}
              >
                <FormControlLabel
                  value="private"
                  control={<Radio className={classes.radioColor} />}
                  label="Make all Meetings Private"
                />
                <FormControlLabel
                  value="org_public"
                  control={<Radio className={classes.radioColor} />}
                  label="Share all Meetings with Organization Members"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {accessLevel === "org" && role !== "ADMIN" && (
        <Grid container spacing={2} className={classes.mb20}>
          <Grid item xs={5} md={2}>
            <FormLabel component="legend" className={classes.lableFont}>
              Meeting Access Settings
            </FormLabel>
          </Grid>
          <Grid item xs={7} md={10}>
            <p>The organization admin controls this setting.</p>
          </Grid>
        </Grid>
      )}
    </>
  );
}
