import { Box, Button, TextareaAutosize } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../../firebase/firebase.js";
import {
  addContact,
  getOrganization,
  getAlgoliaToken,
} from "../../../firebase/firestore.js";
import { connect } from "react-redux";

import AlgoliaTopicAutocomplete from "../../../utils/algoliaTopic.js";

const TopicAlgoliaSelect = (props) => {
  const history = useHistory();
  const { topic, setTopic } = props;
  const [fieldsArray, setFieldsArray] = useState([]);
  const [fieldsStateObject, setFieldsStateObject] = useState({});
  // const [contactAccount, setContactAccount] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let algoliaToken = await getAlgoliaToken();
    AlgoliaTopicAutocomplete(
      algoliaToken,
      setTopic,
      props.elementIdx,
      props.rowId
    );
    setTopic(setTopic);
  }, []);

  const handleSubmit = async () => {
    let validationError = false;
    let tempStateObject = { ...fieldsStateObject };
  };

  return <Box id={`algolia-topic-autocomplete${props.elementIdx}`}></Box>;
};

export default TopicAlgoliaSelect;
