import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { generateSentimentPieCsv } from "../../utils/generateCSV";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";

function ChartDownloadButton({ type, data, fileName }) {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState(null);

  const generateCsv = () => {
    switch (type) {
      case "sentimentPie":
        setLoading(true);
        generateSentimentPieCsv(data)
          .then((res) => {
            if (res.status) {
              setCsvData(res.csvData);
            } else {
              setError(res.error);
              setTimeout(() => {
                setError(null);
              }, 3000);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
        break;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <div
      className={`chart-download-button ${
        csvData?.length > 0 || loading || error ? "show" : ""
      }`}
    >
      {loading ? (
        <CircularProgress size={14} sx={{ color: "#2c73ff" }} />
      ) : error ? (
        <ErrorIcon size={14} sx={{ color: "#ff6347 !important" }} />
      ) : (
        <SaveIcon
          sx={{
            color: csvData?.length > 0 ? "#ff6347 !important" : "#707070",
            fontSize: 14,
          }}
        />
      )}
      {csvData?.length > 0 ? (
        <CSVLink
          data={csvData}
          onClick={() => {
            setCsvData([]);
          }}
          filename={`${fileName}.csv`}
        >
          Download CSV
        </CSVLink>
      ) : loading ? (
        <span className="text">Your file is preparing</span>
      ) : error ? (
        <span className="text red">{error}</span>
      ) : (
        <span className="text" onClick={generateCsv}>
          Generate CSV
        </span>
      )}
    </div>
  );
}

export default ChartDownloadButton;
