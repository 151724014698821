import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import { makeStyles } from "@material-ui/styles";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  markSummaryAsReadFs,
  storeSummary,
  storeSummaryRatings,
} from "../../firebase/firestore";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const useStyles = makeStyles((theme) => ({
  summaryBox: {
    fontSize: "14px",
    padding: "10px",
    height: "300px",
    overflow: "auto",
    marginTop: "10px",
    boxShadow: "none",
  },
  txtArea: {
    width: "100%",
    borderRadius: "10px",
    borderColor: "#dde1e5",
    padding: ".7em",
    fontFamily: "Poppins",
    minHeight: "250px !important",
    maxHeight: "500px !important",
    minWidth: "100%",
    maxWidth: "100%",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));

function MeetingDetailSummary({
  meetingId,
  channelType,
  showSnackbar,
  showLoader,
  handleGenerateSummary,
  summaryData,
  setHistoryNumber,
  setShowHistoryPopup,
  showHistoryPopup,
  loading,
  isHybrid,
}) {
  const classes = useStyles();
  const [summaryEditMode, setSummaryEditMode] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [summary, setSummary] = useState(summaryData?.summary);
  const [summaryInput, setSummaryInput] = useState(summaryData?.summary);
  const [summaryRead, setSummaryRead] = useState(summaryData?.summaryRead);
  const [summaryRatings, setSummaryRatings] = useState(
    summaryData?.summaryReaction === "UP"
      ? 1
      : summaryData?.summaryReaction === "DOWN"
      ? 2
      : null
  );

  const stateControl = () => {
    setSummaryInput(summaryData?.summary);
    setSummary(summaryData?.summary);
    setSummaryRead(summaryData?.summaryRead);
    setSummaryRatings(
      summaryData?.summaryReaction === "UP"
        ? 1
        : summaryData?.summaryReaction === "DOWN"
        ? 2
        : null
    );
  };
  useEffect(stateControl, [summaryData]);

  const saveRatingSummary = async (payload) => {
    let payloadStore = payload === 1 ? "UP" : "DOWN";
    let res = await storeSummaryRatings(meetingId, payloadStore);
    if (res) {
      setSummaryRatings(payload);
      showSnackbar({
        show: true,
        severity: "success",
        message: "Saved.",
      });
    } else {
      showSnackbar({
        show: true,
        severity: "error",
        message: "Something went wrong.",
      });
    }
  };

  const markSummaryAsRead = async () => {
    if (!summaryRead) {
      let res = await markSummaryAsReadFs(meetingId);
      if (res) {
        setSummaryRead(true);
        showSnackbar({
          show: true,
          severity: "success",
          message: "Summary marked as reviewed successfully.",
        });
      } else {
        showSnackbar({
          show: true,
          severity: "error",
          message: "Something went wrong.",
        });
      }
    }
  };

  const handleSave = async () => {
    setSaveLoading(true);
    let payload = {
      summary: summaryInput,
    };
    let storeSummaryRes = await storeSummary(payload, meetingId);
    setSummary(summaryInput);
    setSaveLoading(false);
    setSummaryEditMode(false);
    if (storeSummaryRes && !summaryRead) {
      markSummaryAsRead().catch((err) =>
        console.log(err, "error happen when marking summary as read")
      );
    }
  };

  const handleSummaryScrollEvent = async (e) => {
    const bottom =
      e.target.scrollHeight.toFixed(0) - e.target.scrollTop.toFixed(0) ===
      e.target.clientHeight;
    if (bottom) {
      markSummaryAsRead();
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        className="past-meeting-section-title"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          height="max-content"
        >
          <h5 className="agenda">AI Summary</h5>
          {(channelType === "ZOOM" ||
            channelType === "MS_TEAMS" ||
            channelType === "RECORDING" ||
            channelType === "GOOGLE_MEET" ||
            isHybrid) &&
            !summaryData?.summary && (
              <button
                className="modal-button blue d-flex-center"
                onClick={handleGenerateSummary}
                disabled={showLoader}
                style={{ marginLeft: "1em", borderRadius: "10px" }}
              >
                {showLoader ? (
                  <CircularProgress
                    size="15px"
                    style={{ color: "#fff", marginRight: ".5em" }}
                  />
                ) : (
                  <>
                    <AutoFixHighIcon sx={{ color: "#fff" }} />
                    <span>Generate</span>
                  </>
                )}
              </button>
            )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="meeting-detail-icon-container"
        >
          <div className="icons-container">
            {summaryData?.summary && (
              <>
                {!summaryRead ? (
                  <CheckCircleOutlineIcon
                    sx={{ color: "#c3c3c3", cursor: "pointer" }}
                    onClick={() => markSummaryAsRead()}
                  />
                ) : (
                  <CheckCircleOutlineIcon sx={{ color: "#2c73ff" }} />
                )}
              </>
            )}

            <EditIcon
              sx={{ color: "#c3c3c3", cursor: "pointer" }}
              onClick={() => {
                if (summaryData?.summary && summaryData.summary !== "") {
                  setSummaryEditMode(!summaryEditMode);
                }
              }}
            />

            {summaryData?.summary && (
              <HistoryIcon
                sx={{ color: "#c3c3c3", cursor: "pointer" }}
                onClick={() => {
                  setHistoryNumber(false);
                  setShowHistoryPopup(!showHistoryPopup);
                }}
              />
            )}

            <ThumbUpAltOutlinedIcon
              sx={{
                color: summaryRatings === 1 ? "#2c73ff" : "#c3c3c3",
                cursor: "pointer",
              }}
              onClick={() => {
                if (summaryData?.summary && summaryData.summary !== "") {
                  saveRatingSummary(1);
                }
              }}
            />
            <ThumbDownAltOutlinedIcon
              sx={{
                color: summaryRatings === 2 ? "#2c73ff" : "#c3c3c3",
                cursor: "pointer",
              }}
              onClick={() => {
                if (summaryData?.summary && summaryData.summary !== "") {
                  saveRatingSummary(2);
                }
              }}
            />
          </div>
        </Box>
      </Box>
      <Box
        bgcolor="#fff"
        borderRadius="10px"
        className={classes.summaryBox}
        sx={
          summaryEditMode
            ? { height: "auto !important" }
            : { height: "calc(100% - 36px)" }
        }
        onScroll={(e) => handleSummaryScrollEvent(e)}
      >
        {loading ? (
          <Box
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size="24px" style={{ color: "#2c73ff" }} />
          </Box>
        ) : !summaryEditMode ? (
          summaryData?.summary ? (
            summary
          ) : (
            <Box
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              No data found
            </Box>
          )
        ) : (
          <Box>
            <TextareaAutosize
              aria-label="minimum height"
              placeholder="Dialog"
              value={summaryInput}
              onChange={(e) => setSummaryInput(e.target.value)}
              className={classes.txtArea}
              style={{ height: "100%" }}
            />
            <button className="modal-button blue" onClick={handleSave}>
              {saveLoading ? (
                <CircularProgress size="15px" style={{ color: "#fff" }} />
              ) : (
                "Save"
              )}
            </button>
            <button
              className="modal-button white ml"
              onClick={() => {
                setSummaryInput(summary);
                setSummaryEditMode(false);
              }}
            >
              Cancel
            </button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default MeetingDetailSummary;
