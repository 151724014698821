import { Box } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";

import * as actions from "./../../redux/actions";
import { connect } from "react-redux";
import DealComments from "./DealComments";

import {
  GetDeal,
  updateDeal,
  getUserByUserId,
  getElasticToken,
} from "./../../firebase/firestore.js";
import EditIcon from "@mui/icons-material/Edit";
import ElasticContactAutocomplete from "../Tasks/ElasticContactAutocomplete";
import ElasticAccountAutocomplete from "../Tasks/ElasticAccountAutocomplete";
import { dateDifference } from "../../utils/dateDifference";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  taskTitle: {
    borderRadius: 10,
    color: "#404040",
    "&:hover": {
      backgroundColor: "#EDEEFF",
      cursor: "pointer",
    },
  },
  titleInput: {
    fontWeight: "bold",
    fontSize: "1.17em",
    color: "#404040",
  },
});

function ViewDeal(props) {
  let { dealId, handleCloseViewDealDialog, viewDealDialogStatus } = props;
  let classes = useStyles();

  let [loader, setLoader] = useState(false);

  let [editTitle, setEditTitle] = useState(false);
  let [editedTitle, setEditedTitle] = useState("");

  let [editValue, setEditValue] = useState(false);
  let [editedValue, setEditedValue] = useState("");

  let [dealDetails, setDealDetails] = useState(null);
  let [tmpExpectedClosingDate, settmpExpectedClosingDate] = useState(
    new Date()
  );

  let [relatedToEntity, setRelatedToEntity] = useState(null);
  let [relatedTo, setRelatedTo] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  const [elasticToken, setElasticToken] = useState(null);
  useEffect(() => {
    let loadElasticToken = async () => {
      let elasticTokenValue = await getElasticToken();
      setElasticToken(elasticTokenValue);
    };
    loadElasticToken();
  }, []);

  useEffect(() => {
    setLoader(true);
    const loadDeal = async () => {
      let getDealResponse = await GetDeal(dealId);
      if (getDealResponse.success) {
        console.log(getDealResponse, " GET DEAL RESPONSE");
        setLoader(false);
        setDealDetails(getDealResponse.deal);

        settmpExpectedClosingDate(
          getDealResponse.deal.expectedClosingDate.toDate()
        );
        if (getDealResponse.deal.relatedTo) {
          setRelatedToEntity(getDealResponse.deal.relatedTo.type);

          if (getDealResponse?.deal.relatedTo?.lastName) {
            setRelatedTo(getDealResponse.deal.relatedTo.lastName);
            setDefaultValue(getDealResponse.deal.relatedTo.lastName);
          } else if (getDealResponse?.deal.relatedTo?.accountName) {
            setRelatedTo(getDealResponse.deal.relatedTo.accountName);
            setDefaultValue(getDealResponse.deal.relatedTo.accountName);
          }
        }
      }
    };
    if (dealId) {
      loadDeal();
    }
  }, [dealId]);

  useEffect(() => {
    if (relatedTo) {
      saveRelatedTo(relatedTo);
    }
  }, [relatedTo]);

  const saveTitle = async () => {
    setDealDetails((dealDetails) => {
      return { ...dealDetails, title: editedTitle };
    });
    await updateDeal(dealId, { title: editedTitle });
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Changes updated successfully.",
    });
  };

  const saveValue = async () => {
    setDealDetails((dealDetails) => {
      return { ...dealDetails, value: editedValue };
    });
    await updateDeal(dealId, { value: editedValue });
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Changes updated successfully.",
    });
  };

  const saveStatus = async (status) => {
    setDealDetails((dealDetails) => {
      return { ...dealDetails, status };
    });
    await updateDeal(dealId, { status });
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Changes updated successfully.",
    });
  };

  const saveExpectedClosingDate = async (expectedClosingDate) => {
    settmpExpectedClosingDate(expectedClosingDate);
    setDealDetails((dealDetails) => {
      return { ...dealDetails, expectedClosingDate };
    });
    await updateDeal(dealId, { expectedClosingDate });
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Changes updated successfully.",
    });
  };

  const saveRelatedTo = async (relatedTo) => {
    setDealDetails((dealDetails) => {
      return { ...dealDetails, relatedTo };
    });
    if (relatedTo.lastName) {
      setDefaultValue(relatedTo.lastName);
    } else if (relatedTo.accountName) {
      setDefaultValue(relatedTo.accountName);
    }
    await updateDeal(dealId, { relatedTo });
    props.showSnackbar({
      show: true,
      severity: "success",
      message: "Changes updated successfully.",
    });
  };

  const dealStatuses = [
    {
      value: "identify_decision_makers",
      title: "Identify Decision Makers",
    },
    {
      value: "needs_analysis",
      title: "Needs Analysis",
    },
    {
      value: "value_proposition",
      title: "Value Proposition",
    },
    {
      value: "proposal_price_quote",
      title: "Proposal/Price Quote",
    },
    {
      value: "negotiation_review",
      title: "Negotiation Review",
    },
    {
      value: "closed_won",
      title: "Closed Won",
    },
    {
      value: "closed_lost",
      title: "Closed Lost",
    },
    {
      value: "closed_lost_to_competition",
      title: "Closed-Lost to Competition",
    },
  ];

  const getFormattedValue = (value) => {
    let formattedValue = parseInt(value).toLocaleString("en-US");
    return formattedValue;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleCloseViewDealDialog}
      open={viewDealDialogStatus}
    >
      <Box p={5} minHeight={"60vh"} maxHeight={"90vh"} width={1}>
        {loader && (
          <Box
            width={"100%"}
            height={"60vh"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={24} sx={{ color: "#2c73ff" }} />
          </Box>
        )}
        {dealDetails && !loader && (
          <Box display="flex" flexDirection="column">
            <Box width={1}>
              {!editTitle ? (
                <div
                  onClick={() => {
                    setEditTitle(true);
                    setEditedTitle(dealDetails.title);
                  }}
                  className="tasks-assignedTo title"
                >
                  <h3>{dealDetails.title}</h3>
                  <EditIcon fontSize={"18px"} className="tasks-icon" />
                </div>
              ) : (
                <TextField
                  autoFocus
                  size="small"
                  style={{ width: "100%" }}
                  className={"tasks-title-input"}
                  label=""
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={() => {
                    saveTitle();
                    setEditTitle(false);
                  }}
                />
              )}
            </Box>
            <Box paddingTop={1}>
              {!editValue ? (
                <div
                  onClick={() => {
                    setEditValue(true);
                    setEditedValue(dealDetails.value);
                  }}
                  // className="deal-value"
                  className="tasks-assignedTo title"
                >
                  <Box display="flex" width={1} alignItems="center">
                    <h3>CA$ {getFormattedValue(dealDetails.value)}</h3>
                    <EditIcon fontSize={"18px"} className="tasks-icon" />
                  </Box>
                </div>
              ) : (
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="deal-value">Deal value</InputLabel>
                  <OutlinedInput
                    autoFocus
                    id="deal-value"
                    value={editedValue}
                    onChange={(e) => {
                      if (e.target.value && !Number(e.target.value)) {
                        return;
                      }

                      setEditedValue(e.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">CA$</InputAdornment>
                    }
                    label="Deal value"
                    onBlur={() => {
                      saveValue();
                      setEditValue(false);
                    }}
                  />
                </FormControl>
              )}
            </Box>

            <Box display="flex" width={1} height={"auto"}>
              <Box paddingTop={2} sx={{ width: "100%" }}>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel
                        id="task-status"
                        // className="task-select-label"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="task-status"
                        id="task-status"
                        value={dealDetails.status}
                        label="Status"
                        onChange={(e) => saveStatus(e.target.value)}
                        className="task-select"
                      >
                        {dealStatuses.map((status) => {
                          return (
                            <MenuItem
                              value={status.value}
                              className="task-select-item"
                            >
                              {status.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box marginTop={2}>
                    <DesktopDatePicker
                      label="Expected closing date"
                      inputFormat="dd/MM/yyyy"
                      value={tmpExpectedClosingDate}
                      onChange={(date) => saveExpectedClosingDate(date)}
                      renderInput={(params) => (
                        <TextField className="tasks-datepicker" {...params} />
                      )}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <Box marginTop={2}>
                      <p>Related to: </p>
                    </Box>
                    <Box
                      marginTop={2}
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      <FormControl style={{ minWidth: "100px" }}>
                        <InputLabel
                          id="entity-select-label"
                          // className="task-select-label"
                        >
                          Entity
                        </InputLabel>
                        <Select
                          labelId="entity-select-label"
                          id="entity-select"
                          value={relatedToEntity ? relatedToEntity : ""}
                          label="Entity"
                          onChange={(e) => {
                            setDefaultValue(null);
                            setRelatedTo(null);
                            setRelatedToEntity(e.target.value);
                          }}
                          className="task-select"
                        >
                          <MenuItem
                            value="contact"
                            className="task-select-item"
                          >
                            Contact
                          </MenuItem>
                          <MenuItem
                            value="account"
                            className="task-select-item"
                          >
                            Account
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {elasticToken && (
                        <Box sx={{ width: "100%", marginLeft: "5px" }}>
                          {relatedToEntity === "contact" ? (
                            <ElasticContactAutocomplete
                              setRelatedTo={setRelatedTo}
                              apiKey={elasticToken}
                              defaultValue={defaultValue}
                              className={"elastic-add-task-input"}
                            />
                          ) : relatedToEntity === "account" ? (
                            <ElasticAccountAutocomplete
                              setRelatedTo={setRelatedTo}
                              apiKey={elasticToken}
                              defaultValue={defaultValue}
                              className={"elastic-add-task-input"}
                            />
                          ) : null}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" marginTop={2}>
                    <DealComments dealId={dealId} />
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"flex-end"}
                    flexDirection={"column"}
                    mt={2}
                  >
                    <div className="comment-date">
                      Created at{" "}
                      {dateDifference(
                        new Date(dealDetails?.createdAt?.seconds * 1000)
                      )}
                    </div>
                    <div className="comment-date">
                      Updated at{" "}
                      {dateDifference(
                        new Date(dealDetails?.updatedAt?.seconds * 1000)
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDeal);
