import React, { useEffect, useState, useRef } from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { getUserBillingStatus } from "../firebase/firestore";
import { Box, CircularProgress } from "@material-ui/core";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user, fireStoreuserObj, setOrganizationId } =
    useAuth();

  let [loading, setLoading] = useState(true);
  // let [approvalStatus, setApprovalStatus] = useState(false);
  let [isOrganizationOwner, setIsOrganizationOwner] = useState(false);
  let [billingAddress, setBillingAddress] = useState(false);
  let [freeTrialEnded, setFreeTrialEnded] = useState(false);

  const prevPathRef = useRef();
  useEffect(() => {
    prevPathRef.current = rest.path;
  }, [rest.path]);

  useEffect(() => {
    setLoading(true);

    if (isAuthenticated) {
      let loadApprovedStatus = async () => {
        let res = await getUserBillingStatus();

        if (res.status == "success") {
          // if (res.approved) {
          //   setApprovalStatus(true);
          // }

          if (res.billingDetailsAvailable) {
            setBillingAddress(true);
          }

          if (res.isOrganizationOwner) {
            setIsOrganizationOwner(true);
          }

          setFreeTrialEnded(res.freeTrialEnded);
        }
        setLoading(false);
      };

      loadApprovedStatus();
    } else {
      setLoading(false);
    }
  }, [rest.path, isAuthenticated]);

  if (
    fireStoreuserObj &&
    fireStoreuserObj.organizationId &&
    !("isOrganizationOwner" in fireStoreuserObj)
  ) {
    setOrganizationId(fireStoreuserObj.organizationId);
  }

  if (loading || prevPathRef.current != rest.path) {
    return (
      <Box display="flex" width={1} height={1}>
        <Box margin="auto">
          <CircularProgress style={{ color: "blue" }} size="40px" />
        </Box>
      </Box>
    );
  }

  // if (
  //   !billingAddress &&
  //   freeTrialEnded &&
  //   isOrganizationOwner &&
  //   rest.path != "/payment"
  // ) {
  //   return <Redirect to="/payment" />;
  // }

  // if (
  //   !billingAddress &&
  //   freeTrialEnded &&
  //   !isOrganizationOwner &&
  //   rest.path != "/billing-required" &&
  //   rest.path != "/payment"
  // ) {
  //   return <Redirect to="/billing-required" />;
  // }

  if (
    fireStoreuserObj &&
    rest.path != "/pending-approval" &&
    rest.path != "/welcome" &&
    rest.path != "/billing-required"
  ) {
    //return <Redirect to='/pending-approval' />;
    if (
      fireStoreuserObj &&
      !billingAddress &&
      rest.path != "/payment" &&
      isOrganizationOwner &&
      freeTrialEnded
    ) {
      window.dataLayer.push({'event': 'trial_expired', 'userId': fireStoreuserObj.uid});
      return <Redirect to="/payment" />;
    }
    // console.log(billingAddress, isOrganizationOwner, freeTrialEnded);
    if (
      fireStoreuserObj &&
      !billingAddress &&
      rest.path != "/payment" &&
      !isOrganizationOwner &&
      freeTrialEnded
    ) {
      window.dataLayer.push({'event': 'trial_expired', 'userId': fireStoreuserObj.uid});
      return <Redirect to="/billing-required" />;
    }
  }

  // if (fireStoreuserObj && approvalStatus && rest.path == "/pending-approval") {
  //   return <Redirect to="/dashboard" />;
  // }

  if (
    rest.path == "/teams" &&
    !["ADMIN", "TEAM_MANAGER"].includes(fireStoreuserObj.role)
  ) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
