import Slider from "@mui/material/Slider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Scatter } from "react-chartjs-2";
import zoomIcon from "../../../assets/images/zoom.png";
import googleMeetIcon from "../../../assets/images/google-meet-logo.png";
import attachmentIcon from "../../../assets/images/attachment.png";
import noteIcon from "../../../assets/images/note.png";
import productIcon from "../../../assets/images/puzzle.png";
import competitorsIcon from "../../../assets/images/competitors.png";
import Select from "react-select";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const zoomLogo = new Image(16, 16);
zoomLogo.src = zoomIcon;

const googleMeetLogo = new Image(16, 16);
googleMeetLogo.src = googleMeetIcon;

const attachmentPng = new Image(16, 16);
attachmentPng.src = attachmentIcon;

const notePng = new Image(16, 16);
notePng.src = noteIcon;

const productPng = new Image(16, 16);
productPng.src = productIcon;

const competitorsPng = new Image(16, 16);
competitorsPng.src = competitorsIcon;

const dropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    boxShadow: "none",
    border: "1px solid #2C73FF",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    maxWidth: "100%",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#2C73FF", // Custom colour
  }),
  valueContainer: (base) => ({
    ...base,
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#707070",
    fontSize: "12px",
    border: "none",
    boxShadow: "none",
    zIndex: 3,
    width: "fit-content",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#f3f3f3",
    color: state.isFocused && "#000",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const selectOptions = [
  {
    value: "Meeting Channels",
    label: "Meeting Channels",
  },
  {
    value: "Deals",
    label: "Deals",
  },
  {
    value: "Tasks",
    label: "Tasks",
  },
  {
    value: "Attachments",
    label: "Attachments",
  },
  {
    value: "Notes",
    label: "Notes",
  },
  {
    value: "Products",
    label: "Products",
  },
  {
    value: "Competitors",
    label: "Competitors",
  },
];

const minDistance = 15 * 24 * 60 * 60;
const maxDistance = 30 * 24 * 60 * 60;
const stepDistance = 5 * 24 * 60 * 60;

const getMarks = (nowTime, minusSixMonth) => {
  let m = [],
    intervalIncrement = 24 * 60 * 60;
  for (let i = minusSixMonth; i < nowTime; i = i + intervalIncrement) {
    if (moment.unix(i).date() == 1) {
      m.push({ value: i, label: moment.unix(i).format("MMM DD") });
    }
  }
  return m;
};

let datasets = [
  {
    id: "ZOOM",
    label: "Meeting Channels",
    data: [{ x: 8.5, y: 0.5 }],
    pointStyle: zoomLogo,
    pointHoverRadius: 10,
  },
  {
    id: "GOOGLE_MEET",
    label: "Meeting Channels",
    data: [{ x: 6.5, y: 0.5 }],
    pointStyle: googleMeetLogo,
    pointHoverRadius: 10,
  },
  {
    id: "Attachment",
    label: "Attachments",
    data: [
      { x: 5, y: 6 },
      { x: 13, y: 7 },
    ],
    pointStyle: attachmentPng,
    pointHoverRadius: 10,
  },
  {
    id: "Note",
    label: "Notes",
    data: [
      { x: 3, y: 4 },
      { x: 11, y: 5 },
    ],
    pointStyle: notePng,
    pointHoverRadius: 10,
  },
  {
    id: "Products",
    label: "Products",
    data: [
      { x: 15, y: 8 },
      { x: 25, y: 9 },
    ],
    pointStyle: productPng,
    pointHoverRadius: 10,
  },
  {
    id: "Competitors",
    label: "Competitors",
    data: [
      { x: 8, y: 10 },
      { x: 18, y: 10 },
    ],
    pointStyle: competitorsPng,
    pointHoverRadius: 10,
  },
  {
    id: "Task1",
    label: "Tasks",
    data: [
      {
        x: 1.5,
        y: 2,
      },
      {
        x: 3.5,
        y: 2,
      },
      {
        // add same data as the first one, to draw the closing line
        x: 1.5,
        y: 2,
      },
    ],
    borderColor: "#2C73FF",
    borderWidth: 1,
    pointBackgroundColor: ["#2C73FF"],
    pointBorderColor: ["#2C73FF"],
    pointRadius: 5,
    pointHoverRadius: 5,
    fill: false,
    tension: 0,
    showLine: true,
  },
  {
    id: "Task2",
    label: "Tasks",
    data: [
      {
        x: 2.5,
        y: 3,
      },
      {
        x: 5.5,
        y: 3,
      },
      {
        // add same data as the first one, to draw the closing line
        x: 2.5,
        y: 3,
      },
    ],
    borderColor: "#2C73FF",
    borderWidth: 1,
    pointBackgroundColor: ["#2C73FF"],
    pointBorderColor: ["#2C73FF"],
    pointRadius: 5,
    pointHoverRadius: 5,
    fill: false,
    tension: 0,
    showLine: true,
  },
];

export default function LeadTimeline() {
  let minusOneMonth = moment().subtract(1, "months").unix();
  let minusSixMonth = moment().subtract(6, "months").unix();
  let nowTime = moment().unix();
  const [timeRange, setTimeRange] = useState([minusOneMonth, nowTime]);
  const [days, setDays] = useState([]);
  const [filterSelect, setFilterSelect] = useState([]);
  const [chartData, setChartData] = useState({ datasets });
  const [xMax, setXMax] = useState(31);

  const generateDays = () => {
    let beginningDate = timeRange[0] * 1000;
    let endDate = timeRange[1] * 1000;
    let daysInRange = [];
    for (
      let dt = new Date(beginningDate);
      dt <= endDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      daysInRange.push(new Date(dt));
    }
    setDays(daysInRange);
    if (daysInRange.length < 31) {
      setXMax(daysInRange.length);
    }
  };

  useEffect(generateDays, [timeRange]);

  const filterChartData = () => {
    if (filterSelect?.length === 0) {
      setChartData({ datasets: datasets });
      return;
    }
    let newDataSet = [];
    filterSelect.map((item) => {
      const filteredData = datasets.filter((data) => data.label === item.value);
      if (filteredData) {
        newDataSet = [...newDataSet, ...filteredData];
      }
    });
    setChartData({ datasets: newDataSet });
  };

  useEffect(filterChartData, [filterSelect]);

  //   console.log("timeRange", timeRange);
  //   const sixMonthsAgo = moment().subtract(6, "months");
  //   console.log(sixMonthsAgo.unix());

  const valuetext = (val) => {
    return moment.unix(val).format("MMM DD, YYYY");
  };
  const marks = getMarks(nowTime, minusSixMonth);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], nowTime - minDistance);
        setTimeRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minusSixMonth + minDistance);
        setTimeRange([clamped - minDistance, clamped]);
      }
    } else if (newValue[1] - newValue[0] > maxDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], nowTime + maxDistance);
        setTimeRange([clamped, clamped + maxDistance]);
      } else {
        const clamped = Math.max(newValue[1], minusSixMonth - maxDistance);
        setTimeRange([clamped - maxDistance, clamped]);
      }
    } else {
      setTimeRange(newValue);
    }
  };

  const getGradient = (ctx, axis) => {
    let gradient;
    if (axis === "x") {
      gradient = ctx.createLinearGradient(0, 0, 0, 360);
    } else {
      gradient = ctx.createLinearGradient(360, 360, 350, 0);
    }
    gradient.addColorStop(0, "rgba(230, 230, 255, 0)");
    gradient.addColorStop(0.5, "rgba(230, 230, 255, 1)");
    gradient.addColorStop(1, "rgba(230, 230, 255, 0)");
    return gradient;
  };

  const handleSelectChange = (item) => {
    setFilterSelect(item);
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          title: function (datasetIndex) {
            if (days?.length === 0) {
              return "";
            }
            if (datasetIndex[0].parsed.x % 1 === 0.5) {
              return new Date(
                days[datasetIndex[0].parsed.x - 0.5]
              ).toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              });
            }
          },
          label: function (context, index) {
            if (context.dataset.label === "Notes") {
              return "note detail";
            } else if (context.dataset.label === "Attachments") {
              return "attachment detail";
            } else if (context.dataset.label === "Products") {
              return "product detail";
            } else if (context.dataset.label === "Competitors") {
              return "Competitors";
            }
          },
        },
        position: "average",
        backgroundColor: "#edf3ff",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
    scales: {
      y: {
        max: 12,
        beginAtZero: true,
        ticks: {
          display: false,
          autoSkip: false,
          beginAtZero: true,
          stepSize: 1,
          color: "#7DA8FB",
        },
        grid: {
          display: true,
          color: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getGradient(ctx, "y");
          },
        },
      },
      x: {
        max: xMax,
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: false,
          beginAtZero: true,
          stepSize: 0.5,
          callback: function (val, index) {
            if (days?.length === 0) {
              return "";
            }
            if (val % 1 === 0.5) {
              return new Date(days[Math.floor(index - val)]).toLocaleDateString(
                "en-US",
                {
                  month: "numeric",
                  day: "numeric",
                }
              );
            }
          },
        },
        grid: {
          display: true,
          color: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getGradient(ctx, "x");
          },
        },
      },
    },
  };

  return (
    <>
      <Box display="flex" alignItems="center" sx={{ mb: 1, minHeight: 32 }}>
        <h5 className="agenda">Timeline</h5>
      </Box>
      <div className="details-box" style={{ padding: 0, height: 546 }}>
        <Box sx={{ padding: "1em" }}>
          <Select
            value={filterSelect}
            options={selectOptions}
            onChange={(item) => handleSelectChange(item)}
            styles={dropdownStyles}
            placeholder={"All"}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={true}
            isMulti
          />
        </Box>
        <div className="timeline-chart">
          <Scatter data={chartData} options={options} />
        </div>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{ padding: "1em", background: "#FBFBFB" }}
        >
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img src={zoomIcon} alt="zoom" className="timeline-legend-img" />
            <span className="timeline-legend-span">Zoom</span>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img
              src={googleMeetIcon}
              alt="google-meet"
              className="timeline-legend-img"
            />
            <span className="timeline-legend-span">Google Meet</span>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img
              src={attachmentIcon}
              alt="attachment"
              className="timeline-legend-img"
            />
            <span className="timeline-legend-span">Attachment</span>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img src={noteIcon} alt="note" className="timeline-legend-img" />
            <span className="timeline-legend-span">Note</span>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img
              src={productIcon}
              alt="product"
              className="timeline-legend-img"
            />
            <span className="timeline-legend-span">Products</span>
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginRight: ".5em" }}>
            <img
              src={competitorsIcon}
              alt="competitors"
              className="timeline-legend-img"
            />
            <span className="timeline-legend-span">Competitors</span>
          </Box>
        </Box>
        <div className="slider-container">
          <div className="slider-button">
            <ChevronLeftIcon sx={{ color: "#2C73FF" }} />
          </div>
          <Slider
            aria-label="Restricted values"
            min={minusSixMonth}
            max={nowTime}
            //   defaultValue={moment().unix()}
            step={stepDistance}
            //   marks
            valueLabelFormat={valuetext}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            marks={marks}
            value={timeRange}
            onChange={handleChange}
            disableSwap
            sx={{
              color: "#2C73FF",
              width: "80%",
            }}
          />
          <div className="slider-button">
            <ChevronRightIcon sx={{ color: "#2C73FF" }} />
          </div>
        </div>
      </div>
    </>
  );
}
