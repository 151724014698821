import React, { useEffect, useState, Component } from "react";
import Grid from "@material-ui/core/Grid";
import AudioAnalyser from "./AudioAnalyser";

const Wave = (props) => {
  return (
    <Grid container spacing={2}>
      <AudioAnalyser isRecording={props.isRecording} audio={props.audio} />
    </Grid>
  );
};

export default Wave;
