import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import MeetingTable from "../meetingTable/MeetingTable";
import ConsiderReview from "../_considerReview/ConsiderReview";
import CircleIcon from "@mui/icons-material/Circle";
import ErrorIcon from "@mui/icons-material/Error";
import InsightItem from "./InsightItem";
import { Get } from "../../utils/axiosUtils";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import PolygonChart from "../charts.js/PolygonChart";
import moment from "moment";
import InsightContainer from "./InsightContainer";
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import { DateRangeOptions } from "../dropdowns/DateRangeOptions";

const CheckCircleIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78051 3.72205L6.00251 7.50005L4.11401 5.61105"
      stroke="#2C73FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 6C10.5 8.4855 8.4855 10.5 6 10.5C3.5145 10.5 1.5 8.4855 1.5 6C1.5 3.5145 3.5145 1.5 6 1.5C6.7265 1.5 7.4105 1.6765 8.018 1.982"
      stroke="#2C73FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function InsightsDashboard({
  meetingsLoading,
  meetingData,
  dateRangeHandler,
  isSentimentAvailable,
}) {
  const [dailyInsight, setDailyInsight] = useState([]);
  const [importantTasks, setImportantTasks] = useState([]);
  const [businessOpportunities, setBusinessOpportunities] = useState([]);
  const [businessRisks, setBusinessRisks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [importantTasksLoading, setImportantTasksLoading] = useState(true);
  const [businessOpportunitiesLoading, setBusinessOpportunitiesLoading] =
    useState(true);
  const [businessRisksLoading, setBusinessRisksLoading] = useState(true);

  const getDailyInsight = () => {
    Get("insights/getDailyInsight")
      .then(({ data }) => {
        setDailyInsight(data.data.split("\n").filter((item) => item !== ""));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getImportantTasks = () => {
    Get("insights/getImportantTasks")
      .then(({ data }) => {
        setImportantTasks(data.data);
        setImportantTasksLoading(false);
      })
      .catch((err) =>
        console.log(err, "error happen when fetching important tasks")
      );
  };
  const getBusinessOpportunities = () => {
    Get("insights/getBusinessOpportunities")
      .then(({ data }) => {
        setBusinessOpportunities(data.data);
        setBusinessOpportunitiesLoading(false);
      })
      .catch((err) =>
        console.log(err, "error happen when fetching business opportunities")
      );
  };
  const getBusinessRisks = () => {
    Get("insights/getBusinessRisks")
      .then(({ data }) => {
        setBusinessRisks(data.data);
        setBusinessRisksLoading(false);
      })
      .catch((err) =>
        console.log(err, "error happen when fetching business opportunities")
      );
  };

  useEffect(() => {
    getDailyInsight();
    getImportantTasks();
    getBusinessOpportunities();
    getBusinessRisks();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        <div className="insight-box">
          <h6 className="insight-title">
            {loading ? (
              <Stack spacing={1} animation="wave">
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={25}
                  sx={{ bgcolor: "#cCc" }}
                />
              </Stack>
            ) : dailyInsight?.length ? (
              dailyInsight[0]
            ) : null}
          </h6>
          <div className="insight-description">
            {loading ? (
              <Stack spacing={1} animation="wave">
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={25}
                  sx={{ bgcolor: "#cCc" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={25}
                  sx={{ bgcolor: "#cCc" }}
                />
              </Stack>
            ) : dailyInsight?.length ? (
              dailyInsight.slice(1).join("\n")
            ) : null}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className="insight-header" sx={{ mb: 2 }}>
                <h6 className="insight-title">
                  Possible Important / Pressing Matters
                </h6>
              </Box>
              <InsightContainer
                loading={importantTasksLoading}
                insights={importantTasks}
                icon={<CheckCircleIcon />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <PolygonChart
                meetingsData={meetingData}
                meetingsLoading={meetingsLoading}
                dateRangeHandler={dateRangeHandler}
                isSentimentAvailable={isSentimentAvailable}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className="insight-box">
          <div className="insight-header">
            <h6 className="insight-title">Your business opportunities</h6>
            <div className="insight-description">
              Here are few business opportunities from your past interactions:
            </div>
          </div>
          <InsightContainer
            loading={businessOpportunitiesLoading}
            insights={businessOpportunities}
            icon={<CircleIcon sx={{ fontSize: 12, color: "#2c73ff" }} />}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className="insight-box">
          <h6 className="insight-title">Your business risks</h6>
          <div className="insight-description">
            Here are few business risks from your past interactions:
          </div>
          <InsightContainer
            loading={businessRisksLoading}
            insights={businessRisks}
            icon={<ErrorIcon sx={{ fontSize: 16, color: "#ff0000" }} />}
            isRisks
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6} id={"pastMeetingsContainer"}>
        <Box
          sx={{
            background: "white",
            borderRadius: "0.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            //boxShadow: "0 16px 32px -4px rgba(145, 158, 171, .24)",
            height: 724,
          }}
        >
          <Box
            sx={{
              padding: "1em",
              color: "#000",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            Past Meetings
          </Box>
          <MeetingTable
            width={
              document.getElementById("pastMeetingsContainer")?.offsetWidth -
                60 >
              400
                ? document.getElementById("pastMeetingsContainer").offsetWidth -
                  60
                : 400
            }
            type={"past"}
            height={620}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} id={"upcomingMeetingsContainer"}>
        <Box
          sx={{
            background: "white",
            borderRadius: "0.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            //boxShadow: "0 16px 32px -4px rgba(145, 158, 171, .24)",
            height: 540,
          }}
        >
          <Box
            sx={{
              padding: "1em",
              color: "#000",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            Upcoming Meetings
          </Box>
          <MeetingTable
            width={
              document.getElementById("pastMeetingsContainer")?.offsetWidth -
                60 >
              400
                ? document.getElementById("pastMeetingsContainer").offsetWidth -
                  60
                : 400
            }
            type={"upcoming"}
            height={410}
          />
        </Box>
        <Grid item xs={12} md={12} sx={{ height: 160 }}>
          <ConsiderReview />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InsightsDashboard;
