import React, { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { connect } from "react-redux";
import { ConversationData } from "./conversations/ConversationData";
import { CrmData } from "./crm/CrmData";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Post } from "./../../utils/axiosUtils";
import { AddInstantMeeting } from "./../../utils/endpoints";
import * as actions from "../../redux/actions";
import { getData } from "./../../utils";

import firebase from "../../firebase/firebase";
import Dialog from "@material-ui/core/Dialog";
import CreateEvent from "./CreateEvent";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AddLinkIcon from "@mui/icons-material/AddLink";
// import DateAdapter from "@material-ui/lab/AdapterDateFns";
// import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
// import DesktopDatePicker from "@material-ui/lab/DesktopDatePicker";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  InputBase,
  TextField,
  CircularProgress,
  Drawer,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import { MHidden } from "../../components/@material-extend";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import NotificationCriticalPopover from "./NotificationCriticalPopover";
// import logo from "../../assets/images/logo.svg";
import logo from "../../assets/images/logo-blk.png";
import { getUserByUserId, storeUserLocation } from "../../firebase/firestore";

import googleMeetAvatar from "../../assets/images/meet.png";
import zoomAvatar from "../../assets/images/zoom-icon.svg";
import msTeamAvatar from "../../assets/images/ms-teams-icon.svg";
import "../../assets/css/navbar.scss";

import OnpromiseMeetingModal from "../onPromiseMeetingModal/OnpromiseMeetingModal";
import GlobalElasticSearch from "./GlobalElasticSearch";

import Notifications from "./Notifications";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  color: "#000000",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.up("lg")]: {
    //  width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
const TypographyStyle = styled(Typography)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  top: 10,
}));

const UserName = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  marginLeft: "16%",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "10%",
    width: "auto",
  },
}));

const ScheduleMeeting = styled(Button)(({ theme }) => ({
  background: "#2C73FF",
  boxShadow: "0px 11px 12px rgba(44, 115, 255, 0.25)",
  borderRadius: 10,
  padding: "10px 20px",
  color: "#fff",
  marginLeft: 10,
  fontWeight: 100,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBaseSearch = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
  boxShadow: "0px 0px 10px #e5e6ff",
  borderRadius: 10,
  padding: "3px 15px",
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

function DashboardNavbar({ onOpenSidebar, user, logout, ...props }) {
  const [displayName, setDisplayName] = useState(" ");

  const [joinLink, setJoinLink] = useState("");
  const [urlValidation, setUrlValidation] = useState({
    status: false,
    type: null,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (
        navigator.geolocation &&
        navigator.permissions &&
        navigator.permissions.query
      ) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(async function (result) {
            if (result.state === "granted") {
              navigator.geolocation.getCurrentPosition(fetchWeatherData);
            } else if (result.state === "prompt") {
              console.log(result.state);
              navigator.geolocation.getCurrentPosition(
                fetchWeatherData,
                permissionsDeclined,
                weatherOptions
              );
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
            result.onchange = function () {
              console.log(result.state);
            };
          });
      } else {
        navigator.geolocation.getCurrentPosition(
          fetchWeatherData,
          permissionsDeclined,
          weatherOptions
        );
      }

      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          let userData = await getUserByUserId(firebase.auth().currentUser.uid);
          let zoomTokens = userData.zoom;
          let microsoftTokens = userData.microsoft;
          if (zoomTokens) {
            setIsZoomAuthenticated(true);
          } else {
            setIsZoomAuthenticated(false);
          }
          if (microsoftTokens) {
            setIsMicrosoftAuthenticated(true);
          } else {
            setIsMicrosoftAuthenticated(false);
          }
          let userName = user.displayName.split(" ");
          setDisplayName(userName[0]);
        }
      });
    })();
  }, []);
  const { isCollapse, onToggleCollapse } = useCollapseDrawer();

  const validURL = () => {
    //todo: this regex dont work for msteams
    let googleMeetPattern =
      /^(http:\/\/|https:\/\/)?meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}/g;
    let zoomMeetPattern = /^(http:\/\/|https:\/\/)?us.?.web\.zoom\.us\/j\//g;
    let zoomPattern2 = /^(http:\/\/|https:\/\/)?(www\.)?zoom\.us\/j\//g;
    let msTeamPattern = /^(http:\/\/|https:\/\/)?teams\.microsoft\.com/g;
    let googleMeet = googleMeetPattern.test(joinLink);
    let zoom = zoomMeetPattern.test(joinLink);
    let zoom2 = zoomPattern2.test(joinLink);
    let msTeam = msTeamPattern.test(joinLink);

    if (!(googleMeet || zoom || zoom2 || msTeam)) {
      zoom = joinLink.includes("zoom");
    }

    if (googleMeet || zoom || zoom2 || msTeam) {
      setUrlValidation({
        status: true,
        type: googleMeet
          ? "googleMeet"
          : zoom || zoom2
          ? "zoom"
          : msTeam
          ? "msTeams"
          : null,
      });
    } else {
      setUrlValidation({
        status: false,
        type: null,
      });
    }
  };

  useMemo(validURL, [joinLink]);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showOnpromiseMeeting, setShowOnpromiseMeeting] = useState(false);
  const [instantMeetingLoader, setInstantMeetingLoader] = useState(false);

  const [isZoomAuthenticated, setIsZoomAuthenticated] = useState(false);
  const [isMicrosoftAuthenticated, setIsMicrosoftAuthenticated] =
    useState(false);
  const [instantMeetingDilogStatus, setInstantMeetingDilogStatus] =
    useState(false);
  const [instantMeetingURL, setInstantMeetingURL] = useState("");
  const [startMeetingURL, setStartMeetingURL] = useState("");
  const [instantMeetingType, setInstantMeetingType] = useState("");
  const [weatherData, setWeatherData] = useState(false);
  const [preferredUnit, setPreferredUnit] = useState("c");

  const [algoliaSearchDrawerStatus, setAlgoliaSearchDrawerStatus] =
    useState(false);

  const [algoliaSearchDrawerWidth, setAlgoliaSearchDrawerWidth] = useState(
    window.innerWidth - 1000
  );

  const applyFullWidth = () => {
    if (algoliaSearchDrawerWidth != window.innerWidth - 100) {
      requestAnimationFrame(() => {
        setAlgoliaSearchDrawerWidth(window.innerWidth - 100);
      });
    } else {
      setAlgoliaSearchDrawerWidth(window.innerWidth - 1000);
    }
  };

  var weatherOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function permissionsDeclined(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const fetchWeatherData = async (pos) => {
    if (localStorage.getItem("weatherdata") !== null) {
      let localDataString = localStorage.getItem("weatherdata");
      let localData = JSON.parse(localDataString);

      if (localData.time > Date.now() - 1000 * 60 * 60) {
        setWeatherData(localData);
        if (localData.preferredUnit) setPreferredUnit(localData.preferredUnit);

        return;
      }
    }
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        await storeUserLocation({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
        let res = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/user/weather?lon=${pos.coords.longitude}&lat=${pos.coords.latitude}`,
          jwtToken
        );
        if (res.status == "success") {
          res.data.time = Date.now();
          if (localStorage.getItem("weatherdata") !== null) {
            let localDataString = localStorage.getItem("weatherdata");
            let localData = JSON.parse(localDataString);
            res.data.preferredUnit = localData.preferredUnit;
          } else {
            res.data.preferredUnit = "c";
          }
          localStorage.setItem("weatherdata", JSON.stringify(res.data));
          setWeatherData(res.data);
        }
      });
  };

  const joinMeetingWithUrl = async () => {
    // Just ignore validation: By Ronak
    if (urlValidation?.status || true) {
      let meetingURL = joinLink;
      if (
        !(meetingURL.includes("https://") || meetingURL.includes("http://"))
      ) {
        meetingURL = "https://" + meetingURL;
      }
      let data = { joinUrl: meetingURL };
      setShowLoader(true);
      let apiRes = await Post(AddInstantMeeting, data);
      setShowLoader(false);
      if (apiRes.status === 200 && apiRes.data.status === "success") {
        setShowSuccess(true);
        setJoinLink("");
        props.showSnackbar({
          show: true,
          severity: "success",
          message: "bluecap™ will join the meeting shortly.",
        });
      } else {
        // setShowError(true);
        props.showSnackbar({
          show: true,
          severity: "error",
          message: "Please enter valid meeting URL.",
        });
      }
    } else {
      // setShowError(true);
      props.showSnackbar({
        show: true,
        severity: "error",
        message: "Please enter valid meeting URL.",
      });
    }
  };

  const openOnpromiseMeetingPopup = () => {
    setShowOnpromiseMeeting(true);
  };

  const openCreateEventPopup = () => {
    setShowCreateEvent(true);
  };

  const closeCreateEventPopup = () => {
    setShowCreateEvent(false);
    setShowSuccess(true);
  };

  const createInstantMeeting = async () => {
    setInstantMeetingLoader(true);
    setInstantMeetingType("googlemeet");
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let res = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/calendar/createEventAndJoin`,
          jwtToken
        );

        if (res.status == "success") {
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Meeting created successfully.",
          });

          setInstantMeetingURL(res.data.hangoutLink);
          setInstantMeetingDilogStatus(true);
        } else {
          props.showSnackbar({
            show: true,
            severity: "error",
            message: "Something went wrong.",
          });
        }

        setInstantMeetingLoader(false);
      });
  };

  const createInstantZoomMeeting = async () => {
    setInstantMeetingLoader(true);
    setInstantMeetingType("zoom");

    if (!isZoomAuthenticated) {
      alert("please authorize with Zoom first");
      window.location = `https://zoom.us/oauth/authorize?client_id=${
        process.env.REACT_APP_ZOOM_CLIENT_ID
      }&response_type=code&redirect_uri=${
        process.env.REACT_APP_ZOOM_REDIRECT_URL +
        "&state=" +
        firebase.auth().currentUser.uid
      }`;
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let res = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/calendar/createEventAndJoin?type=zoom`,
          jwtToken
        );

        if (res.status == "success") {
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Meeting created successfully.",
          });

          setInstantMeetingURL(res.data.join_url);
          setStartMeetingURL(res.data.start_url);
          setInstantMeetingDilogStatus(true);
        } else {
          props.showSnackbar({
            show: true,
            severity: "error",
            message: "Something went wrong.",
          });
        }

        setInstantMeetingLoader(false);
      });
  };

  const createInstantTeamsMeeting = async () => {
    setInstantMeetingLoader(true);
    setInstantMeetingType("teams");

    if (!isMicrosoftAuthenticated) {
      alert("please authorize with Microsoft first");
      const authUrl =
        "https://login.microsoftonline.com/common" +
        "/oauth2/v2.0/authorize?client_id=" +
        process.env.REACT_APP_MICROSOFT_CLIENT_ID +
        "&response_type=code&redirect_uri=" +
        process.env.REACT_APP_MICROSOFT_REDIRECT_URL +
        "&response_mode=query&scope=offline_access user.read calendars.readwrite OnlineMeetings.ReadWrite&state=" +
        firebase.auth().currentUser.uid;
      window.location = authUrl;
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (jwtToken) {
        let res = await getData(
          `${process.env.REACT_APP_BASE_API_URL}/calendar/createEventAndJoin?type=teams`,
          jwtToken
        );

        if (res.status == "success") {
          props.showSnackbar({
            show: true,
            severity: "success",
            message: "Meeting created successfully.",
          });

          setInstantMeetingURL(res.data.join_url);
          setInstantMeetingDilogStatus(true);
        } else {
          props.showSnackbar({
            show: true,
            severity: "error",
            message: "Something went wrong.",
          });
        }

        setInstantMeetingLoader(false);
      });
  };

  const changePreferredUnit = (val) => {
    // let currentWeatherData = weatherData;
    // currentWeatherData.preferredUnit = val;
    // setWeatherData(currentWeatherData);
    setPreferredUnit(val);
    if (localStorage.getItem("weatherdata") !== null) {
      let localDataString = localStorage.getItem("weatherdata");
      let localData = JSON.parse(localDataString);
      localData.preferredUnit = val;
      localStorage.setItem("weatherdata", JSON.stringify(localData));
    }
  };

  const handleInstantMeetingDilogClose = () => {
    setInstantMeetingDilogStatus(!instantMeetingDilogStatus);
    setInstantMeetingURL("");
    setStartMeetingURL("");
  };

  // function getStyles(name, filterTypes, theme) {
  //   return {
  //     fontWeight:
  //       filterTypes.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium,
  //   };
  // }

  // export default function MultipleSelect() {

  return (
    <>
      {showCreateEvent && (
        <CreateEvent onClose={closeCreateEventPopup} open={showCreateEvent} />
      )}
      {showOnpromiseMeeting && (
        <OnpromiseMeetingModal
          open={showOnpromiseMeeting}
          onClose={() => setShowOnpromiseMeeting(false)}
        />
      )}
      <Drawer
        anchor="right"
        open={algoliaSearchDrawerStatus}
        onClose={() => setAlgoliaSearchDrawerStatus(!algoliaSearchDrawerStatus)}
      >
        <Box
          width={algoliaSearchDrawerWidth}
          style={{ transition: "all 0.5s" }}
        >
          <Box padding={2}>
            <a style={{ cursor: "pointer" }} onClick={applyFullWidth}>
              Toggle width
            </a>
          </Box>
          <Box padding={20}>
            <h2>Search Content</h2>
          </Box>
        </Box>
      </Drawer>

      <Dialog
        onClose={handleInstantMeetingDilogClose}
        aria-labelledby="simple-dialog-title"
        open={instantMeetingDilogStatus}
      >
        {(instantMeetingType == "googlemeet" ||
          instantMeetingType == "teams") && (
          <Box
            p={5}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            flexDirection={{ xs: "column", md: "row" }}
            width={{ xs: "400px", md: "500px" }}
          >
            <a
              target="_blank"
              style={{
                textDecoration: "none",
                maxWidth: "339px",
                wordWrap: "break-word",
              }}
              href={instantMeetingURL}
              rel="noreferrer"
            >
              {instantMeetingURL}
            </a>
            <img
              onClick={() => {
                navigator.clipboard.writeText(instantMeetingURL);
                props.showSnackbar({
                  show: true,
                  severity: "success",
                  message: "Copied meeting URL successfully!",
                });
              }}
              title="Copy to clipboard"
              src="/icons/copy_icon.svg"
              alt="copy text"
              style={{ cursor: "pointer", height: "30px", width: "30px" }}
            />
          </Box>
        )}{" "}
        {instantMeetingType == "zoom" && (
          <>
            <Box
              p={5}
              pb={0}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              flexDirection={{ xs: "column", md: "row" }}
              width={{ xs: "400px", md: "500px" }}
            >
              Start Meeting:{" "}
              <a
                target="_blank"
                style={{
                  textDecoration: "none",
                  maxWidth: "339px",
                  wordWrap: "break-word",
                }}
                href={startMeetingURL}
                rel="noreferrer"
              >
                {startMeetingURL}
              </a>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(startMeetingURL);
                  props.showSnackbar({
                    show: true,
                    severity: "success",
                    message: "Copied meeting URL successfully!",
                  });
                }}
                title="Copy to clipboard"
                src="/icons/copy_icon.svg"
                alt="copy text"
                style={{ cursor: "pointer", height: "30px", width: "30px" }}
              />
            </Box>
            <Box
              p={5}
              pt={1}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              flexDirection={{ xs: "column", md: "row" }}
              width={{ xs: "400px", md: "500px" }}
            >
              Join Meeting:{" "}
              <a
                target="_blank"
                style={{
                  textDecoration: "none",
                  maxWidth: "339px",
                  wordWrap: "break-word",
                }}
                href={instantMeetingURL}
                rel="noreferrer"
              >
                {instantMeetingURL}
              </a>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(instantMeetingURL);
                  props.showSnackbar({
                    show: true,
                    severity: "success",
                    message: "Copied meeting URL successfully!",
                  });
                }}
                title="Copy to clipboard"
                src="/icons/copy_icon.svg"
                alt="copy text"
                style={{ cursor: "pointer", height: "30px", width: "30px" }}
              />
            </Box>
          </>
        )}
      </Dialog>
      <RootStyle
        id="navbar-dashboard"
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100%)` },
          }),
        }}
      >
        {/* <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}> */}
        <Icon
          icon={menu2Fill}
          className="hamburger-full-width"
          onClick={onToggleCollapse}
        />
        {/* </IconButton> */}
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Box marginTop="16px">
                <Icon icon={menu2Fill} />
              </Box>
            </IconButton>
          </MHidden>

          <Box display={{ xs: "none", lg: "block" }}>
            <TypographyStyle
              className="logo-header"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <img
                src={logo}
                alt=""
                className="img-fluid"
                style={{ width: 100 }}
              />
            </TypographyStyle>
          </Box>
          <Box
            width="100%"
            display={{ xs: "flex", lg: "none" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={logo}
              alt=""
              className="img-fluid"
              style={{ width: 100, height: "35px" }}
            />
            <Box flexGrow={1} justifyContent="center" display="flex">
              {/* <div className="username meeting-in-progress">
            <MeetingButton>
              <img
                src="/icons/meeting-in-process.svg"
                style={{ marginRight: "5px" }}
              />
              Meeting in Progress
            </MeetingButton>
          </div> */}
              <div className="username">
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => {
                    const { isOpen } = popupState;
                    if (showSuccess) {
                      // close and reset showSuccess to false
                      popupState.close();
                      setShowSuccess(false);
                    }
                    return (
                      <div>
                        {/* <Button variant="contained" color="primary" >
                    Open Popover
                  </Button> */}
                        <ScheduleMeeting
                          {...bindTrigger(popupState)}
                          className="schedule-meeting-btn-1"
                        >
                          Schedule meeting
                          {isOpen ? (
                            <ExpandLessIcon className="expand-arrow-icon" />
                          ) : (
                            <ExpandMoreIcon className="expand-arrow-icon" />
                          )}
                        </ScheduleMeeting>

                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box
                            id="schedule-meeting-btn-popover"
                            p={2}
                            height="170px"
                            width="190px"
                            display="flex"
                            justifyContent="space-around"
                            flexDirection="column"
                            style={{ boxShadow: "0px 0px 4px 0px #E5E6FF" }}
                          >
                            <Typography className="option">
                              Instant Meeting via:
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <img
                                src="/images/zoom-logo.png"
                                width="30"
                                height="30"
                                alt=""
                                aria-hidden
                                style={{
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={createInstantZoomMeeting}
                              />
                              <img
                                src="/images/google-meet-logo.png"
                                width="25"
                                height="22"
                                alt=""
                                style={{
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={createInstantMeeting}
                              />
                              <img
                                src="/images/ms-teams-logo.png"
                                width="30"
                                height="30"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={createInstantTeamsMeeting}
                              />
                              {instantMeetingLoader && (
                                <CircularProgress
                                  style={{ color: "blue" }}
                                  size="14px"
                                />
                              )}
                            </Box>
                            <hr color="F0F0F0" />
                            {/* <Box display="flex" justifyContent="space-between">
                              <Typography
                                style={{ cursor: "pointer" }}
                                className="option"
                                onClick={createInstantMeeting}
                              >
                                Instant meeting
                              </Typography>

                              {createInstantMeetingLoader && (
                                <CircularProgress
                                  style={{ color: "blue" }}
                                  size="12px"
                                />
                              )}
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                style={{ cursor: "pointer" }}
                                className="option"
                                onClick={createInstantZoomMeeting}
                              >
                                Instant Zoom
                              </Typography>

                              {createInstantZoomMeetingLoader && (
                                <CircularProgress
                                  style={{ color: "blue" }}
                                  size="12px"
                                />
                              )}
                            </Box> */}

                            <Box
                              display={"flex"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                popupState.close();
                                openOnpromiseMeetingPopup();
                              }}
                              alignItems="center"
                            >
                              <img
                                src={"/images/recording_meeting.png"}
                                width="16"
                                height="16"
                                style={{ paddingRight: "5px" }}
                                alt=""
                                title={"Record New Meeting"}
                              />
                              <Typography
                                style={{
                                  cursor: "pointer",
                                  padding: "20px 0 10px 0",
                                }}
                                className="option"
                              >
                                Record New Meeting
                              </Typography>
                              <hr color="#F0F0F0" />
                            </Box>

                            <Box display={"flex"} alignItems="center">
                              <ScheduleIcon
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  paddingRight: "5px",
                                }}
                              />
                              <Typography
                                style={{
                                  cursor: "pointer",
                                  paddingTop: "10px",
                                }}
                                className="option"
                                onClick={openCreateEventPopup}
                              >
                                Schedule meeting
                              </Typography>
                            </Box>

                            <hr color="F0F0F0" />
                            <Box display={"flex"} alignItems="center">
                              <AddLinkIcon
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  paddingRight: "5px",
                                }}
                              />
                              <Typography
                                className="option"
                                style={{ paddingTop: "10px" }}
                              >
                                Join via URL:
                              </Typography>
                            </Box>

                            <TextField
                              required
                              id="meeting-url"
                              name="meeting-url"
                              // variant="outlined"
                              placeholder="Enter meeting URL"
                              value={joinLink}
                              onChange={(e) => setJoinLink(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  joinMeetingWithUrl();
                                }
                              }}
                              style={{ marginTop: "5px", marginBottom: "10px" }}
                            />

                            <button
                              style={{ marginBottom: 8 }}
                              className="modal-button white d-flex-center"
                              onClick={joinMeetingWithUrl}
                              disabled={!urlValidation?.status}
                            >
                              {showLoader ? (
                                <CircularProgress
                                  style={{ color: "#2c73ff" }}
                                  size="14px"
                                />
                              ) : (
                                <>
                                  {urlValidation?.type && (
                                    <img
                                      src={
                                        urlValidation?.type === "googleMeet"
                                          ? googleMeetAvatar
                                          : urlValidation?.type === "msTeams"
                                          ? msTeamAvatar
                                          : zoomAvatar
                                      }
                                      style={{
                                        marginRight: 8,
                                        height: 16,
                                        width: 16,
                                      }}
                                      alt={urlValidation?.type}
                                    />
                                  )}
                                  Join
                                </>
                              )}
                            </button>

                            {/* <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={showSuccess}
                          autoHideDuration={4000}
                          onClose={() => setShowSuccess(false)}
                        >
                          <Alert severity="success">
                            Bluecap will join the meeting shortly.
                          </Alert>
                        </Snackbar>
                        */}

                            {/* <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={showError}
                          autoHideDuration={4000}
                          onClose={() => setShowError(false)}
                        >
                          <Alert severity="error">
                            Please enter valid meeting URL.
                          </Alert>
                        </Snackbar> */}
                          </Box>
                        </Popover>
                      </div>
                    );
                  }}
                </PopupState>
              </div>
              <div className="username header-searchbar">
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBaseSearch
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>
            </Box>
          </Box>
          <UserName className="header-user-details">
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
              style={{ marginLeft: 10 }}
            >
              <div className="username">
                Hello,{" "}
                <span style={{ marginLeft: "5px", color: "#053a99" }}>
                  {displayName}!
                </span>
              </div>
              {weatherData && (
                <div
                  style={{
                    boxShadow: "0px 0px 10px 0px rgba(229, 230, 255, 1)",
                    borderRadius: "10px",
                  }}
                  className="forcast"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      height="45px"
                      style={{ width: "50px" }}
                      width="50px"
                      src={`${weatherData.iconUrl}`}
                      alt=""
                    />

                    <Box
                      display="flex"
                      style={{ paddingRight: "10px" }}
                      flexDirection="row"
                    >
                      {preferredUnit == "c" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                          >
                            {weatherData.celsius}
                          </Typography>
                          <Typography
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                            variant="subtitle1"
                          >
                            &nbsp;°C
                          </Typography>
                          <Typography
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                            variant="caption"
                          >
                            &nbsp;|&nbsp;
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ cursor: "pointer" }}
                            variant="caption"
                            onClick={() => changePreferredUnit("f")}
                          >
                            {" "}
                            <p>°F</p>
                          </Typography>
                        </>
                      )}

                      {preferredUnit == "f" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                          >
                            {weatherData.fahrenheit}
                          </Typography>
                          <Typography
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                            variant="subtitle1"
                          >
                            &nbsp;°F
                          </Typography>
                          <Typography
                            style={{ color: "rgba(64, 64, 64, 1)" }}
                            variant="caption"
                          >
                            &nbsp;|&nbsp;
                          </Typography>
                          <Typography
                            color="primary"
                            style={{ cursor: "pointer" }}
                            variant="caption"
                            onClick={() => changePreferredUnit("c")}
                          >
                            {" "}
                            <p>°C</p>
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </div>
              )}
            </Stack>
          </UserName>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            {/* <div className="username meeting-in-progress">
            <MeetingButton>
              <img
                src="/icons/meeting-in-process.svg"
                style={{ marginRight: "5px" }}
              />
              Meeting in Progress
            </MeetingButton>
          </div> */}

            <div className="username header-searchbar" style={{ width: "80%" }}>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>

              </Search> */}

              {/* Elasticsearch start */}

              <Box className="global-search">
                <GlobalElasticSearch />
              </Box>
              {/* Elasticsearch end */}
            </div>
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <NotificationCriticalPopover user={user} logout={logout} />

            <NotificationsPopover />

            <div className="username">
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => {
                  const { isOpen } = popupState;
                  if (showSuccess) {
                    // close and reset showSuccess to false
                    popupState.close();
                    setShowSuccess(false);
                  }
                  return (
                    <div>
                      {/* <Button variant="contained" color="primary" >
                    Open Popover
                  </Button> */}
                      <ScheduleMeeting
                        {...bindTrigger(popupState)}
                        className="schedule-meeting-btn"
                      >
                        Schedule Meeting
                        {isOpen ? (
                          <ExpandLessIcon className="expand-arrow-icon" />
                        ) : (
                          <ExpandMoreIcon className="expand-arrow-icon" />
                        )}
                      </ScheduleMeeting>

                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Box
                          id="schedule-meeting-btn-popover"
                          p={2}
                          height="auto !important"
                          width="187px"
                          display="flex"
                          justifyContent="flex-start"
                          flexDirection="column"
                          style={{ boxShadow: "0px 0px 4px 0px #E5E6FF" }}
                        >
                          <Typography
                            className="option"
                            onClick={createInstantMeeting}
                          >
                            Instant Meeting via:
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            marginBottom="10px"
                          >
                            <img
                              src="/images/zoom-logo.png"
                              width="30"
                              height="30"
                              alt=""
                              style={{ marginRight: "20px", cursor: "pointer" }}
                              onClick={createInstantZoomMeeting}
                            />
                            <img
                              src="/images/google-meet-logo.png"
                              width="25"
                              height="22"
                              alt=""
                              style={{ marginRight: "20px", cursor: "pointer" }}
                              onClick={createInstantMeeting}
                            />
                            <img
                              src="/images/ms-teams-logo.png"
                              width="30"
                              height="30"
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={createInstantTeamsMeeting}
                            />
                            {instantMeetingLoader && (
                              <CircularProgress
                                style={{ color: "blue" }}
                                size="12px"
                              />
                            )}
                          </Box>
                          <hr color="#F0F0F0" />
                          {/* <Box display="flex" justifyContent="space-between">
                            <Typography
                              style={{ cursor: "pointer" }}
                              className="option"
                              onClick={createInstantMeeting}
                            >
                              Instant meeting
                            </Typography>

                            {createInstantMeetingLoader && (
                              <CircularProgress
                                style={{ color: "blue" }}
                                size="12px"
                              />
                            )}
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography
                              style={{ cursor: "pointer" }}
                              className="option"
                              onClick={createInstantZoomMeeting}
                            >
                              Instant Zoom
                            </Typography>

                            {createInstantZoomMeetingLoader && (
                              <CircularProgress
                                style={{ color: "blue" }}
                                size="12px"
                              />
                            )}
                          </Box> */}

                          <>
                            <Box
                              display={"flex"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                popupState.close();
                                openOnpromiseMeetingPopup();
                              }}
                              alignItems="center"
                            >
                              <img
                                src={"/images/recording_meeting.png"}
                                width="20"
                                height="20"
                                style={{ paddingRight: "5px" }}
                                alt=""
                                title={"Record New Meeting"}
                              />
                              <Typography
                                style={{
                                  cursor: "pointer",
                                  padding: "20px 0 10px 0",
                                }}
                                className="option"
                              >
                                Record New Meeting
                              </Typography>
                            </Box>
                            <hr color="#F0F0F0" />
                          </>

                          <Box
                            display={"flex"}
                            style={{ cursor: "pointer" }}
                            alignItems="center"
                            onClick={openCreateEventPopup}
                          >
                            <ScheduleIcon
                              style={{
                                width: "20px",
                                height: "20px",
                                paddingRight: "5px",
                              }}
                            />
                            <Typography
                              style={{
                                cursor: "pointer",
                                padding: "20px 0 10px 0",
                              }}
                              className="option"
                            >
                              Schedule Meeting
                            </Typography>
                          </Box>

                          <hr color="#F0F0F0" />

                          <Box display={"flex"} alignItems="center">
                            <AddLinkIcon
                              style={{
                                width: "20px",
                                height: "20px",
                                paddingRight: "5px",
                              }}
                            />
                            <Typography
                              style={{
                                cursor: "pointer",
                                paddingTop: "10px",
                              }}
                              className="option"
                            >
                              Join via URL:
                            </Typography>
                          </Box>

                          <TextField
                            required
                            id="meeting-url"
                            name="meeting-url"
                            // variant="outlined"
                            placeholder="Enter meeting URL"
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                            value={joinLink}
                            onChange={(e) => setJoinLink(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                joinMeetingWithUrl();
                              }
                            }}
                          />

                          <button
                            style={{ marginBottom: 8 }}
                            className="modal-button white d-flex-center"
                            onClick={joinMeetingWithUrl}
                            disabled={!urlValidation?.status}
                          >
                            {showLoader ? (
                              <CircularProgress
                                style={{ color: "#2c73ff" }}
                                size="12px"
                              />
                            ) : (
                              <>
                                {urlValidation?.type && (
                                  <img
                                    src={
                                      urlValidation?.type === "googleMeet"
                                        ? googleMeetAvatar
                                        : urlValidation?.type === "msTeams"
                                        ? msTeamAvatar
                                        : zoomAvatar
                                    }
                                    style={{
                                      marginRight: 8,
                                      height: 16,
                                      width: 16,
                                    }}
                                    alt={urlValidation?.type}
                                  />
                                )}
                                Join
                              </>
                            )}
                          </button>

                          {/* <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={showSuccess}
                          autoHideDuration={4000}
                          onClose={() => setShowSuccess(false)}
                        >
                          <Alert severity="success">
                            Bluecap will join the meeting shortly.
                          </Alert>
                        </Snackbar>
                        */}

                          {/* <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={showError}
                          autoHideDuration={4000}
                          onClose={() => setShowError(false)}
                        >
                          <Alert severity="error">
                            Please enter valid meeting URL.
                          </Alert>
                        </Snackbar> */}
                        </Box>
                      </Popover>
                    </div>
                  );
                }}
              </PopupState>
            </div>
            <Notifications />

            <AccountPopover user={user} logout={logout} />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(actions.showSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
