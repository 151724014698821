import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackbarNotification = (props) => {
  const { open, severity, message } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => props.handleSnackbar(false)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={() => props.handleSnackbar(false)}
        severity={severity ?? "info"}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
