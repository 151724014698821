let recording = null;
let desktopMediaStream = null;
let mediaStream = null;

const mergeAudioStreams = (desktopStream, voiceStream) => {
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();
  if (desktopStream && desktopStream.getAudioTracks().length > 0) {
    // If you don't want to share Audio from the desktop it should still work with just the voice.
    const source1 = context.createMediaStreamSource(desktopStream);
    const desktopGain = context.createGain();
    desktopGain.gain.value = 0.7;
    source1.connect(desktopGain).connect(destination);
  }

  if (voiceStream && voiceStream.getAudioTracks().length > 0) {
    const source2 = context.createMediaStreamSource(voiceStream);
    const voiceGain = context.createGain();
    voiceGain.gain.value = 0.7;
    source2.connect(voiceGain).connect(destination);
  }
  return destination.stream.getAudioTracks();
};

export const startMediaDeviceWithScreenShare = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getDisplayMedia({
        video: {
          mediaSource: "screen",
          cursor: "always",
          displaySurface: "window",
        },
        audio: true,
      })
      .then(
        (desktopStream) => {
          desktopMediaStream = desktopStream;
          if (desktopStream && desktopStream.getAudioTracks().length > 0) {
            navigator.mediaDevices
              .getUserMedia({ video: false, audio: true })
              .then((voiceStream) => {
                const tracks = mergeAudioStreams(desktopStream, voiceStream);
                let stream = new MediaStream(tracks);
                mediaStream = stream;
                resolve(stream);
              });
          } else {
            desktopStream.getTracks().forEach((track) => track.stop());
            reject("Share audio is required.");
          }
        },
        (error) => {
          console.log("error ==>", error);
          reject("Permission denied");
        }
      );
  });
};

export const startMediaDevice = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((voiceStream) => {
        mediaStream = voiceStream;
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const getMediaStream = () => {
  return new Promise((resolve, reject) => {
    resolve(mediaStream);
  });
};

export const stopMediaStream = () => {
  if (desktopMediaStream) {
    desktopMediaStream.getTracks().forEach((track) => track.stop());
    desktopMediaStream = null;
    mediaStream = null;
  }
};
