import React, {useState, useEffect} from 'react';
import DateRangeDropdown from "../dropdowns/DateRangeDropdown";
import {DateRangeOptions} from "../dropdowns/DateRangeOptions";
import Chart from 'react-apexcharts';
import {Box, CircularProgress} from "@material-ui/core";


const hours = [
    {
        label: '12am',
        start: 12,
        end: 14
    },
    {
        label: '2am',
        start: 2,
        end: 4
    },
    {
        label: '4am',
        start: 4,
        end: 6
    },
    {
        label: '6am',
        start: 6,
        end: 8
    },
    {
        label: '8am',
        start: 8,
        end: 10
    },
    {
        label: '10am',
        start: 10,
        end: 12
    },
    {
        label: '12pm',
        start: 0,
        end: 2
    },
    {
        label: '2pm',
        start: 14,
        end: 16
    },
    {
        label: '4pm',
        start: 16,
        end: 18
    },
    {
        label: '6pm',
        start: 18,
        end: 20
    },
    {
        label: '8pm',
        start: 20,
        end: 22
    },
    {
        label: '10pm',
        start: 22,
        end: 0
    },
    {
        label: '12pm',
        start: 22,
        end: 0
    }
];

const days = [
    {
        label: 'Sun',
        value: 0
    },
    {
        label: 'Mon',
        value: 1
    },
    {
        label: 'Tue',
        value: 2
    },
    {
        label: 'Wed',
        value: 3
    },
    {
        label: 'Thu',
        value: 4
    },
    {
        label: 'Fri',
        value: 5
    },
    {
        label: 'Sat',
        value: 6
    }
];

function Heatmap({meetingsData, fetchLoading, dateRangeController}) {
    const [dateRange, setDateRange] = useState(DateRangeOptions[0]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isParentDataShouldChange,setIsParentDataShouldChange] = useState(false);

    const options = {
        chart: {
            height: 1200,
            type: 'heatmap',
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        title: {
            display: false
        },
        plotOptions: {
            heatmap: {
                radius: 0,
                shadeIntensity: 0.3,
                colorScale: {
                    ranges: [
                        {
                            from: -1,
                            to: 1,
                            color: '#707070',
                        },
                        {
                            from: 1,
                            to: 2,
                            color: '#F8FAFF',
                        },
                        {
                            from: 3,
                            to: 5,
                            color: '#EDF3FF',
                        },
                        {
                            from: 6,
                            to: 10,
                            color: '#7DA8FB',
                        },
                        {
                            from: 10,
                            to: 999,
                            color: '#2C73FF',
                        }
                    ]
                }
            }
        }
    };

    const transformData = () => {
        setLoading(true);
        const allMeetingsDates = [];
        meetingsData?.map(meeting => allMeetingsDates.push(new Date(meeting.startTime * 1000)));
        const meetingsInRange = allMeetingsDates.filter(item => item >= new Date().getTime() - dateRange.value * 24 * 60 * 60 * 1000);
        let chartSeries = [];
        hours.map(hour => {
            let serie = {
                name: hour.label,
                data: []
            }
            days.map(day => {
                const meetingsByDay = meetingsInRange.filter(item => {
                    const meetingDate = new Date(item);
                    return meetingDate.getDay() === day.value && meetingDate.getHours() >= hour.start && meetingDate.getHours() < hour.end
                });
                serie.data.push({
                    x: day.label,
                    y: meetingsByDay.length //meeting count here
                })
            })
            chartSeries.push(serie);
        });
        setSeries(chartSeries);
        setLoading(false);
        setIsParentDataShouldChange(false);
    }

    useEffect(() => {
        transformData()
    }, [
        isParentDataShouldChange,
        meetingsData
    ]);

    return (
        <div className="chart">
            <div className="chart-header">
                <h6>Meetings by time of day</h6>
                <DateRangeDropdown
                    selected={dateRange}
                    setDateRange={(newDateRange) => {
                        setDateRange(newDateRange);
                        if(dateRangeController(newDateRange)) {
                            setLoading(true);
                        } else {
                            setIsParentDataShouldChange(true);
                        }
                    }}
                />
            </div>
            {
                loading || fetchLoading ?
                    <Box justifyContent="center" alignItems="center" display="flex" height={"100%"}>
                        <CircularProgress style={{color: "#2c73ff"}} size="30px"/>
                    </Box>
                    :
                    <>
                        <div className="heatmap-chart-container">
                            <Chart options={options} type="heatmap" series={series} height={"100%"}/>
                        </div>
                        <div className="heatmap-chart-info">
                            <div>No activity</div>
                            <div>1-2</div>
                            <div>3-5</div>
                            <div>6-10</div>
                            <div>10+</div>
                        </div>
                    </>
            }
        </div>
    );
}

export default Heatmap;
