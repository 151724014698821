import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import { sentimentIcons } from "../../utils/sentimentIcons";
import { useHistory } from "react-router-dom";

function BubbleChartModal({ data, label, open, onClose }) {
  const history = useHistory();
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data, "DATA");
  const transformData = () => {
    setLoading(true);
    if (!data || data?.length === 0) {
      setLoading(false);
      return;
    }
    console.log(data, "DATA");

    let columns = [
      {
        field: "meetingTitle",
        headerName: "Meeting title",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "keyCount",
        headerName: "Count",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 120,
        flex: 1,
      },
      {
        field: "sentimentScore",
        headerName: "Average sentiment",
        headerClassName: "data-table-header",
        cellClassName: "data-table-cell",
        minWidth: 64,
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return sentimentIcons[params.row.sentimentScore];
        },
      },
    ];

    const rows = [];
    columns.find(
      (item) => item.field === "keyCount"
    ).headerName = `Count of ${label}`;
    data.map((meeting, index) => {
      rows.push({
        id: index,
        meetingTitle: meeting?.title,
        sentimentScore: meeting?.emotion,
        meetingId: meeting.id,
        keyCount: meeting?.count,
      });
    });
    setTableColumns(columns);
    setTableRows(rows);
    setLoading(false);
  };

  useEffect(transformData, [data]);
  const handleRowClick = (meetingData) => {
    history.push(`/meeting/${meetingData.meetingId}`);
  };
  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={open}>
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={"100%"}
          minHeight={500}
          sx={{ background: "#fff", borderRadius: ".5rem" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <div style={{ height: "80vh" }}>
          <DataGrid
            rows={tableRows}
            columns={tableColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowCount={data?.length}
            checkboxSelection={false}
            disableSelectionOnClick
            enableCellSelect={false}
            getRowClassName={() => `data-table-row`}
            onRowClick={(meeting) => handleRowClick(meeting.row)}
            className={`data-table`}
          />
        </div>
      )}
    </Dialog>
  );
}

export default BubbleChartModal;
