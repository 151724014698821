import React, { useState, useEffect, useRef } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box, CircularProgress } from "@mui/material";
import { getData, postData } from "../../utils";
import { notifications as notificationsCollection } from "./../../firebase/firestore";
import firebase from "./../../firebase/firebase";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";

export default function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAllLoader, setDeleteAllLoader] = useState(false);
  const [markAllAsReadLoader, setMarkAllAsReadLoader] = useState(false);
  const pageRef = useRef(1);

  const [deleting, setDeleting] = useState(null);
  const [changingReadStatus, setChangingReadStatus] = useState(null);

  const refreshNotificationsData = async (nextPage) => {
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    let genNotifications = await getData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications?page=${nextPage}`,
      jwtToken
    );

    if (genNotifications.status === "success") {
      setUnreadCount(genNotifications.data.unreadCount);
      setTotal(genNotifications.data.total);
      setNotifications(genNotifications.data.notificationsArray);
    }
  };

  const addDummyData = async () => {
    return true;
    let jwtToken = await firebase.auth().currentUser.getIdToken();
    let cnt = 1;
    setInterval(async () => {
      await postData(
        `${process.env.REACT_APP_BASE_API_URL}/notifications/test-add-notification`,
        jwtToken,
        {
          title: `title ${cnt}`,
          url: `https://dev-app.bluecap.ai/?title=title_${cnt}`,
        }
      );
      cnt++;
    }, 10000);
  };
  useEffect(() => {
    let userId = firebase.auth().currentUser.uid;
    let unsubscribeNotificationsRealtimeUpdatesObserver =
      notificationsCollection.doc(userId).onSnapshot((doc) => {
        refreshNotificationsData(pageRef.current);
      });

    // addDummyData();
    return () => unsubscribeNotificationsRealtimeUpdatesObserver();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id) => {
    setDeleting(id);
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    await postData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications/delete`,
      jwtToken,
      {
        id,
      }
    );
    setDeleting(null);
  };
  const handleMarkAsRead = async (id) => {
    setChangingReadStatus(id);
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    await postData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications/mark-as-read`,
      jwtToken,
      {
        id,
      }
    );
    setChangingReadStatus(null);
  };
  const handleMarkAsUnread = async (id) => {
    setChangingReadStatus(id);
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    await postData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications/mark-as-unread`,
      jwtToken,
      {
        id,
      }
    );
    setChangingReadStatus(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const handleScroll = async (e) => {
    if (isLoading) {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (total > notifications.length && notifications.length < 500) {
        setIsLoading(true);
        let nextPage = pageRef.current + 1;
        pageRef.current = nextPage;

        await refreshNotificationsData(nextPage);
        setIsLoading(false);
      }
    }
  };

  const markAllAsRead = async () => {
    setMarkAllAsReadLoader(true);
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    await getData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications/mark-all-as-read`,
      jwtToken
    );
    setMarkAllAsReadLoader(false);
  };
  const deleteAll = async () => {
    setDeleteAllLoader(true);
    let jwtToken = await firebase.auth().currentUser.getIdToken();

    await getData(
      `${process.env.REACT_APP_BASE_API_URL}/notifications/delete-all`,
      jwtToken
    );
    setDeleteAllLoader(false);
  };

  return (
    <div>
      <Badge
        style={{ cursor: "pointer" }}
        badgeContent={unreadCount}
        color="error"
        onClick={handleClick}
      >
        <NotificationsIcon fontSize="large" color="primary" />
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <>
          <Box display={"flex"} justifyContent="flex-end">
            <Box
              paddingRight={1}
              style={{ cursor: "pointer" }}
              onClick={() => setAnchorEl(null)}
              paddingTop={1}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box display={"flex"} alignItems="center" flexDirection="column">
            <Typography variant="h6" paddingBottom={1}>
              Notifications
            </Typography>
            <Box
              display={"flex"}
              width={250}
              p={1}
              justifyContent="space-between"
            >
              <Box display={"flex"} alignItems="center">
                {markAllAsReadLoader && <CircularProgress size={"14px"} />}
                <p
                  style={{
                    color: "#2C73FF",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => markAllAsRead()}
                >
                  Mark all as read
                </p>
              </Box>
              <Box display={"flex"} alignItems="center">
                {deleteAllLoader && <CircularProgress size={"14px"} />}
                <p
                  style={{
                    color: "#2C73FF",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteAll()}
                >
                  Delete all
                </p>
              </Box>
            </Box>
            <Box
              p={2}
              paddingBottom={3}
              width={400}
              maxHeight={600}
              onScroll={handleScroll}
              style={{ overflowY: "scroll" }}
            >
              {notifications.map((notification, key) => {
                return (
                  <Box
                    key={key}
                    display={"flex"}
                    flexDirection="column"
                    style={
                      notification.isRead
                        ? { backgroundColor: "#F5F5F5" }
                        : { backgroundColor: "#DCDCDC" }
                    }
                    marginY={1}
                    padding={2}
                    borderRadius={2}
                  >
                    {notification.url ? (
                      <a
                        href={notification.url}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <p>{notification.title}</p>
                      </a>
                    ) : (
                      <>
                        <p>{notification.title}</p>
                      </>
                    )}

                    <Box
                      marginTop={"10px"}
                      display={"flex"}
                      justifyContent="space-between"
                    >
                      <p
                        onClick={() => console.log("Nothing")}
                        style={{ fontSize: "14px" }}
                        title={moment(
                          new Date(notification.createdAt._seconds * 1000)
                        ).format("lll")}
                      >
                        {moment(
                          new Date(notification.createdAt._seconds * 1000)
                        ).fromNow()}
                      </p>
                      {!notification.isRead && (
                        <Box display={"flex"} alignItems="center">
                          {changingReadStatus &&
                            changingReadStatus === notification.id && (
                              <CircularProgress size={"14px"} />
                            )}
                          <p
                            style={{
                              color: "#2C73FF",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Mark as read
                          </p>
                        </Box>
                      )}

                      {notification.isRead && (
                        <Box display={"flex"} alignItems="center">
                          {changingReadStatus &&
                            changingReadStatus === notification.id && (
                              <CircularProgress size={"14px"} />
                            )}
                          <p
                            style={{
                              color: "#2C73FF",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMarkAsUnread(notification.id)}
                          >
                            Mark as unread
                          </p>
                        </Box>
                      )}
                      <Box display={"flex"} alignItems="center">
                        {deleting && deleting === notification.id && (
                          <CircularProgress size={"14px"} />
                        )}
                        <p
                          style={{
                            color: "#2C73FF",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(notification.id)}
                        >
                          Delete
                        </p>
                      </Box>
                    </Box>
                    {/* <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr> */}
                  </Box>
                );
              })}
              {notifications.length === 0 && (
                <Box display={"flex"} justifyContent="center">
                  <p>No recent notifications are there!</p>
                </Box>
              )}

              {isLoading && (
                <Box display={"flex"} justifyContent={"center"}>
                  <CircularProgress size={"20px"} />
                </Box>
              )}
            </Box>
          </Box>
        </>
      </Popover>
    </div>
  );
}
