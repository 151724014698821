import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import Entity from "./Entity";

const useStyles = makeStyles((theme) => ({
  chipBox: {
    marginLeft: "-10px",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    marginLeft: "10px",
    marginTop: "10px",
  },
}));

const sentimentToColor = (sentiment, isSentimentAvailable) => {
  if (!isSentimentAvailable) {
    return "#000";
  }
  if (sentiment === "POSITIVE_2") {
    return "#00C800";
  } else if (sentiment === "POSITIVE_1") {
    return "#b3cb00";
  } else if (sentiment === "NEUTRAL" || sentiment === "mixed") {
    return "#FFB043";
  } else if (sentiment === "NEGATIVE_1") {
    return "#EA5435";
  } else if (sentiment === "NEGATIVE_2") {
    return "#FF0000";
  } else {
    return null;
  }
};

function Topics(props) {
  const classes = useStyles();

  const [topics, setTopics] = React.useState([]);
  useEffect(async () => {
    let meetingTopics = "";
    let topicSentiments = [];
    let sentimentColor = "";
    let sentimentKey = "";
    props.meetingTopics?.participants
      ? (meetingTopics = props.meetingTopics.participants)
      : (meetingTopics = []);

    if (meetingTopics.length > 0) {
      meetingTopics.map((topic) => {
        if (topic.topics_sentiment && topic.topics_sentiment.length > 0) {
          topic.topics_sentiment.map((tSentiment) => {
            if (tSentiment.emotion && tSentiment.labelName) {
              sentimentColor = sentimentToColor(
                tSentiment.emotion,
                props.isSentimentAvailable
              );
              sentimentKey = tSentiment.emotion;
              topicSentiments.push({
                emotion: tSentiment.emotion,
                labelName: tSentiment.labelName,
                sentimentColor,
                timestamp: tSentiment?.timestamp?.start
                  ? {
                      _seconds: tSentiment.timestamp.start / 1000,
                      start: tSentiment.timestamp.start / 1000,
                      end: tSentiment?.timestamp?.end / 1000,
                    }
                  : null,
              });
            }
          });
        }
      });
      // console.log("topicSentiments", topicSentiments);
      setTopics(topicSentiments);
    }
  }, []);

  return (
    <Box className={classes.chipBox}>
      {topics.length > 0 ? (
        topics.map((topic, index) => {
          return (
            <Entity
              key={index}
              entityName={topic.labelName}
              emotionKey={topic?.emotion}
              entityCount={0}
              sentimentColor={topic?.sentimentColor}
              entityToMarks={props.entityToMarks}
              isSentimentAvailable={props.isSentimentAvailable}
              setActiveEntity={props.setActiveEntity}
              timestamp={topic?.timestamp ? [topic?.timestamp] : []}
              type={"topics"}
            />
          );
        })
      ) : (
        <p style={{ fontSize: "14px", marginLeft: "20px" }}>
          Topics not available!
        </p>
      )}
    </Box>
  );
}

export default Topics;

/*


            <Chip
              label={topic.labelName}
              variant="outlined"
              key={index}
              className={classes.chip}
              style={{
                border: topic.tBorder,
              }}
            />

 */
