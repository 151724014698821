import React, { useState, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import Select from "@mui/material/Select";
import { TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Icon } from "@iconify/react";
import ContactAlgoliaSelect from "../ContactAlgoliaSelect";
import {
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  InputAdornment,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

const CrmItem = ({ elem, index, crmListData, setCrmListData }) => {
  const [contactAccount, setContactAccount] = useState({});
  const [contactsOrAnd, setContactsOrAnd] = useState("");
  const [crmSelectedOption, setCrmSelectedOption] = useState("Very positive");
  const [crmDimensionsItems, setCrmDimensionsItems] = useState([
    "Contains",
    "Does not contain",
  ]);
  const [crmFilterDimensionsContains, setCrmFilterDimensionsContains] =
    useState("Lead Name");
  const [crmSentimentOptions, setCrmSentimentOptions] = useState([
    "Very positive",
    "Positive",
    "Neutral",
    "Negative",
    "Very negative",
  ]);
  const minValue = 0;
  const maxValue = 100;
  const [engagmentsValue, setEngagmentsValue] = useState(minValue);
  const crmOutcomeOptions = ["positive", "neutral", "negative"];

  const handleContactsOrAndChange = (event, newAlignment) => {
    setContactsOrAnd(newAlignment);
  };

  const crmHandlefilterDimensionsChange = (event, idx) => {
    let newCrmListData = [...crmListData];
    newCrmListData[idx].firstSelectResult = event.target.value;
    if (event.target.value === "Lead Name") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Leads owned by") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    } else if (event.target.value === "Lead Stage") {
      newCrmListData[idx].secondSelectResult = "Any";
      newCrmListData[idx].secondSelectList = ["Any", "Include", "Exclude"];
    } else if (event.target.value === "Lead sentiment") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Lead Engagement") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Contact Name") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Contacts owned be") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    } else if (event.target.value === "Contact sentiment") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Contact Engagement") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Contact Address") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Topics interested") {
      newCrmListData[idx].secondSelectResult = "Include";
      newCrmListData[idx].secondSelectList = ["Include", "Exclude"];
    } else if (event.target.value === "Topics sentiment") {
      newCrmListData[idx].secondSelectResult = "Include";
      newCrmListData[idx].secondSelectList = ["Include", "Exclude"];
    } else if (event.target.value === "Product Mentions") {
      newCrmListData[idx].secondSelectResult = "Include";
      newCrmListData[idx].secondSelectList = ["Include", "Exclude"];
    } else if (event.target.value === "Product sentiment") {
      newCrmListData[idx].secondSelectResult = "Include";
      newCrmListData[idx].secondSelectList = ["Include", "Exclude"];
    } else if (event.target.value === "Account Name") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Accounts owned by") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    } else if (event.target.value === "Account address") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Deal Name") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Deals owned by") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    } else if (event.target.value === "Deal Value") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Deal stage") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Task Title") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Task Status") {
      newCrmListData[idx].secondSelectResult = "Equals to";
      newCrmListData[idx].secondSelectList = [
        "Equals to",
        "More than",
        "Less than",
      ];
    } else if (event.target.value === "Tasks assigned to") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    } else if (event.target.value === "Notes") {
      newCrmListData[idx].secondSelectResult = "Contains";
      newCrmListData[idx].secondSelectList = ["Contains", "Does not contain"];
    } else if (event.target.value === "Notes created by") {
      newCrmListData[idx].secondSelectResult = "Me";
      newCrmListData[idx].secondSelectList = [
        "Me",
        "Not me",
        "Specific people",
      ];
    }
    setCrmListData(newCrmListData);
    // setFilterDimensionsContains(event.target.value);
  };

  const crmHandlefilterDimensionsItemsChange = (event, idx) => {
    let newCrmListData = [...crmListData];
    newCrmListData[idx].secondSelectResult = event.target.value;
    setCrmListData(newCrmListData);
  };

  const crmSentimentHandleChange = (event, idx) => {
    let newCrmListData = [...crmListData];
    newCrmListData[idx].crmSentimentResult = event.target.value;
    setCrmListData(newCrmListData);
  };

  const engagmentsHandleChange = (e) => {
    const newValue = Math.min(Math.max(e.target.value, minValue), maxValue);
    setEngagmentsValue((previousValue) => newValue);
  };

  const contactDeleteHandleClick = (idx, peopleId) => {
    let newCrmListData = [...crmListData];
    newCrmListData[idx].selectedSpecificPeoples.splice(peopleId, 1);
    setCrmListData(newCrmListData);
  };

  const crmOrHandleClick = () => {
    setCrmListData([
      ...crmListData,
      {
        id: uuidv4(),
        firstSelectResult: "Lead Name",
        secondSelectResult: "Contains",
        secondSelectList: ["Contains", "Does not contain"],
        crmSentimentResult: "Very positive",
        selectedSpecificPeoples: [],
        selects: [
          {
            id: 1,
            dimension: "Lead Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 2,
            dimension: "Leads owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 3,
            dimension: "Lead Stage",
            options: ["Any", "Include", "Exclude"],
          },
          {
            id: 4,
            dimension: "Lead sentiment",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 5,
            dimension: "Lead Engagement",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 6,
            dimension: "Contact Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 7,
            dimension: "Contacts owned be",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 8,
            dimension: "Contact sentiment",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 9,
            dimension: "Contact Engagement",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 10,
            dimension: "Contact Address",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 11,
            dimension: "Topics interested",
            options: ["Include", "Exclude"],
          },
          {
            id: 12,
            dimension: "Topics sentiment",
            options: ["Include", "Exclude"],
          },
          {
            id: 13,
            dimension: "Product Mentions",
            options: ["Include", "Exclude"],
          },
          {
            id: 14,
            dimension: "Product sentiment",
            options: ["Include", "Exclude"],
          },
          {
            id: 15,
            dimension: "Account Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 16,
            dimension: "Accounts owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 17,
            dimension: "Account address",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 18,
            dimension: "Deal Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 19,
            dimension: "Deals owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 20,
            dimension: "Deal Value",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 21,
            dimension: "Deal stage",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 22,
            dimension: "Task Title",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 23,
            dimension: "Task Status",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 24,
            dimension: "Tasks assigned to",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 25,
            dimension: "Notes",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 26,
            dimension: "Notes created by",
            options: ["Me", "Not me", "Specific people"],
          },
        ],
      },
    ]);
  };

  const crmAndHandleClick = () => {
    setCrmListData([
      ...crmListData,
      {
        id: uuidv4(),
        firstSelectResult: "Lead Name",
        secondSelectResult: "Contains",
        crmSentimentResult: "Very positive",
        secondSelectList: ["Contains", "Does not contain"],
        selectedSpecificPeoples: [],
        selects: [
          {
            id: 1,
            dimension: "Lead Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 2,
            dimension: "Leads owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 3,
            dimension: "Lead Stage",
            options: ["Any", "Include", "Exclude"],
          },
          {
            id: 4,
            dimension: "Lead sentiment",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 5,
            dimension: "Lead Engagement",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 6,
            dimension: "Contact Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 7,
            dimension: "Contacts owned be",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 8,
            dimension: "Contact sentiment",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 9,
            dimension: "Contact Engagement",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 10,
            dimension: "Contact Address",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 11,
            dimension: "Topics interested",
            options: ["Include", "Exclude"],
          },
          {
            id: 12,
            dimension: "Topics sentiment",
            options: ["Include", "Exclude"],
          },
          {
            id: 13,
            dimension: "Product Mentions",
            options: ["Include", "Exclude"],
          },
          {
            id: 14,
            dimension: "Product sentiment",
            options: ["Include", "Exclude"],
          },
          {
            id: 15,
            dimension: "Account Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 16,
            dimension: "Accounts owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 17,
            dimension: "Account address",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 18,
            dimension: "Deal Name",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 19,
            dimension: "Deals owned by",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 20,
            dimension: "Deal Value",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 21,
            dimension: "Deal stage",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 22,
            dimension: "Task Title",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 23,
            dimension: "Task Status",
            options: ["Equals to", "More than", "Less than"],
          },
          {
            id: 24,
            dimension: "Tasks assigned to",
            options: ["Me", "Not me", "Specific people"],
          },
          {
            id: 25,
            dimension: "Notes",
            options: ["Contains", "Does not contain"],
          },
          {
            id: 26,
            dimension: "Notes created by",
            options: ["Me", "Not me", "Specific people"],
          },
        ],
      },
    ]);
  };

  const crmCancelHandleClick = (idx) => {
    if (crmListData.length > 1) {
      let newCrmListData = [...crmListData];
      newCrmListData.pop();
      setCrmListData(newCrmListData);
    } else {
      setCrmListData([
        {
          id: uuidv4(),
          firstSelectResult: "Lead Name",
          secondSelectResult: "Contains",
          crmSentimentResult: "Very positive",
          secondSelectList: ["Contains", "Does not contain"],
          selectedSpecificPeoples: [],
          selects: [
            {
              id: 1,
              dimension: "Lead Name",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 2,
              dimension: "Leads owned by",
              options: ["Me", "Not me", "Specific people"],
            },
            {
              id: 3,
              dimension: "Lead Stage",
              options: ["Any", "Include", "Exclude"],
            },
            {
              id: 4,
              dimension: "Lead sentiment",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 5,
              dimension: "Lead Engagement",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 6,
              dimension: "Contact Name",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 7,
              dimension: "Contacts owned be",
              options: ["Me", "Not me", "Specific people"],
            },
            {
              id: 8,
              dimension: "Contact sentiment",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 9,
              dimension: "Contact Engagement",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 10,
              dimension: "Contact Address",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 11,
              dimension: "Topics interested",
              options: ["Include", "Exclude"],
            },
            {
              id: 12,
              dimension: "Topics sentiment",
              options: ["Include", "Exclude"],
            },
            {
              id: 13,
              dimension: "Product Mentions",
              options: ["Include", "Exclude"],
            },
            {
              id: 14,
              dimension: "Product sentiment",
              options: ["Include", "Exclude"],
            },
            {
              id: 15,
              dimension: "Account Name",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 16,
              dimension: "Accounts owned by",
              options: ["Me", "Not me", "Specific people"],
            },
            {
              id: 17,
              dimension: "Account address",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 18,
              dimension: "Deal Name",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 19,
              dimension: "Deals owned by",
              options: ["Me", "Not me", "Specific people"],
            },
            {
              id: 20,
              dimension: "Deal Value",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 21,
              dimension: "Deal stage",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 22,
              dimension: "Task Title",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 23,
              dimension: "Task Status",
              options: ["Equals to", "More than", "Less than"],
            },
            {
              id: 24,
              dimension: "Tasks assigned to",
              options: ["Me", "Not me", "Specific people"],
            },
            {
              id: 25,
              dimension: "Notes",
              options: ["Contains", "Does not contain"],
            },
            {
              id: 26,
              dimension: "Notes created by",
              options: ["Me", "Not me", "Specific people"],
            },
          ],
        },
      ]);
    }
  };

  useEffect(() => {
    let newCrmListData = [...crmListData];

    if (Object.keys(contactAccount).length) {
      newCrmListData.map((elem) => {
        if (elem.id === contactAccount.rowId) {
          elem.selectedSpecificPeoples.push(contactAccount);
        }
      });
      setCrmListData(newCrmListData);
    }
  }, [contactAccount]);

  useEffect(() => {
    crmListData.map((elem) => {
      elem.selects.map((item) => {
        if (crmFilterDimensionsContains === item.dimension) {
          setCrmDimensionsItems(item.options);
          elem.firstSelectResult = item.dimension;
          elem.secondSelectList = item.options;
          elem.secondSelectResult = item.options[0];
        }
      });
    });
  }, [crmFilterDimensionsContains]);

  return (
    <Grid
      position="relative"
      display="flex"
      padding="15px 0"
      justifyContent="space-between"
      style={{
        width: "100%",
        position: "relative",
        minHeight: "100px",
        borderRadius: "10px",
      }}
    >
      <Grid xs={10} sm={10} md={10}>
        <Select
          value={elem.firstSelectResult}
          onChange={(e) => crmHandlefilterDimensionsChange(e, index)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          style={{ marginRight: "10px", maxWidth: "120px" }}
        >
          {elem.selects.map((elem) => {
            return (
              <MenuItem key={elem.id} value={elem.dimension}>
                {elem.dimension}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          value={elem.secondSelectResult}
          onChange={(e) => crmHandlefilterDimensionsItemsChange(e, index)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          style={{ marginRight: "10px", maxWidth: "120px" }}
        >
          {elem.secondSelectList.length &&
            elem.secondSelectList.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
        </Select>
        {elem.secondSelectResult === "Contains" ||
        elem.secondSelectResult === "Does not contain" ? (
          <TextField type="text" size="small" style={{ width: "150px" }} />
        ) : !elem.firstSelectResult.includes("sentiment") &&
          !elem.firstSelectResult.includes("Engagement") &&
          (elem.secondSelectResult === "Less than" ||
            elem.secondSelectResult === "More than" ||
            elem.secondSelectResult === "Equals to") ? (
          <TextField type="number" size="small" style={{ width: "150px" }} />
        ) : elem.secondSelectResult === "Specific people" ? (
          <Box display="flex" justifyContent="space-between" marginTop={"10px"}>
            {elem.selectedSpecificPeoples?.length ? (
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="center"
                gap="3px"
                flexWrap="wrap"
              >
                {elem.selectedSpecificPeoples.map((people, i) => {
                  return (
                    <div
                      style={{ position: "relative" }}
                      className="contact-icon-section"
                    >
                      <img
                        src={people.avatar || people.avatarURL}
                        alt="img"
                        width={30}
                        height={30}
                        style={{ borderRadius: "50%" }}
                      />
                      <Icon
                        icon="ci:close-big"
                        color="white"
                        className="contact-icon"
                        onClick={() => contactDeleteHandleClick(index, i)}
                      />
                    </div>
                  );
                })}
              </Box>
            ) : (
              <Box></Box>
            )}
            <ContactAlgoliaSelect
              contactAccount={contactAccount}
              setContactAccount={setContactAccount}
              elementIdx={index}
              rowId={elem.id}
              sourceId={"contacts"}
              indexName={`${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}_contacts`}
              organizationId={""}
            />
          </Box>
        ) : elem.firstSelectResult.includes("Engagement") &&
          (elem.secondSelectResult === "Less than" ||
            elem.secondSelectResult === "More than" ||
            elem.secondSelectResult === "Equals to") ? (
          <TextField
            type="number"
            size="small"
            style={{ width: "150px" }}
            onChange={(e) => engagmentsHandleChange(e)}
            value={engagmentsValue}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        ) : elem.firstSelectResult === "Outcome" &&
          (elem.secondSelectResult === "Includes" ||
            elem.secondSelectResult === "Does not include") ? (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={crmOutcomeOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            style={{ width: "140px", display: "inline-flex" }}
            size="small"
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Outcome types" />
            )}
          />
        ) : elem.firstSelectResult.includes("sentiment") ? (
          <Select
            value={elem.crmSentimentResult}
            onChange={(e) => crmSentimentHandleChange(e, index)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            size="small"
            style={{ marginRight: "10px", maxWidth: "120px" }}
          >
            {crmSentimentOptions.map((elem) => {
              return <MenuItem value={elem}>{elem}</MenuItem>;
            })}
          </Select>
        ) : (
          <></>
        )}
      </Grid>
      <Grid xs={2} sm={2} md={2} textAlign="right">
        <ToggleButtonGroup
          color="primary"
          value={contactsOrAnd}
          exclusive
          onChange={handleContactsOrAndChange}
          size="small"
        >
          <ToggleButton value="-" onClick={() => crmCancelHandleClick(index)}>
            -
          </ToggleButton>
          <ToggleButton value="or" onClick={crmOrHandleClick}>
            or
          </ToggleButton>
          <ToggleButton value="and" onClick={crmAndHandleClick}>
            and
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default CrmItem;
