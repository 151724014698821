import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import {
  getOrganization,
  updateContact,
  updateLead,
} from "../../firebase/firestore";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    boxShadow: "none",
    border: "1px solid #707070",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    minHeight: "30px",
    height: "30px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#707070", // Custom colour
    padding: ".1em",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 6px",
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    border: "none",
    boxShadow: "none",
    zIndex: 3,
    width: "max-content",
    maxWidth: "180px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#f3f3f3",
    color: state.isFocused && "#000",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      background: "transparent",
      height: "20px",
      width: "20px",
      marginRight: -7,
    };
  },
};

function ProfileNameRole({
  profileDetails,
  leadId,
  contactId,
  handleInputChange,
  inputs,
  setInputs,
}) {
  const [roleOptions, setRoleOptions] = useState([]);
  const [editMode, setEditMode] = useState({ status: false, type: "" });
  const [loading, setLoading] = useState(false);

  const setSelectOptionsFn = async () => {
    let organization = await getOrganization();
    const fieldType = leadId ? "leadFieldsSetting" : "contactFieldsSetting";
    const RoleOptions = organization[fieldType].find(
      (item) => item.title === "Role"
    )?.options;
    const roleOptionsArray = [];
    RoleOptions?.map((option) =>
      roleOptionsArray.push({ label: option.title, value: option.title })
    );
    setRoleOptions(roleOptionsArray);
  };
  useEffect(setSelectOptionsFn, []);

  const SelectRole = () => (
    <Select
      value={roleOptions.find((item) => item.value === inputs.role)}
      options={roleOptions}
      onChange={(item) =>
        handleInputChange({ target: { value: item.value, name: "role" } })
      }
      styles={selectStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuPortalTarget={document.body}
    />
  );

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    let splittedName = name?.split(" ");
    if (splittedName?.length > 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name?.split(" ")[0][0]}`,
      };
    }
  };

  let fullName = (lead) => {
    if (lead.firstName) return `${lead.firstName} ${lead.lastName}`;
    else return lead.lastName;
  };

  const handleSave = async () => {
    setLoading(true);
    let newDetails = {};
    newDetails.role = inputs.role;
    if (leadId) {
      await updateLead(leadId, newDetails);
    } else if (contactId) {
      await updateContact(contactId, newDetails);
    }
    let tmpLeadData = { ...profileDetails };
    tmpLeadData.role = inputs.role;
    setEditMode({ status: false, type: "" });
    setLoading(false);
  };

  const EditIcons = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ml: 1,
      }}
    >
      {loading ? (
        <CircularProgress size={20} sx={{ color: "#707070" }} />
      ) : (
        <>
          <CheckIcon
            fontSize={"18px"}
            sx={{ color: "#707070", cursor: "pointer" }}
            onClick={() => {
              handleSave().catch((err) => {
                // Error handling
                handleInputChange({
                  target: { value: profileDetails?.role, name: "role" },
                });
                setEditMode({ status: false, type: "" });
              });
            }}
          />
          <ClearIcon
            fontSize={"18px"}
            sx={{ color: "#707070", cursor: "pointer", marginLeft: ".5em" }}
            onClick={() => {
              // Change input as begin
              handleInputChange({
                target: { value: profileDetails?.role, name: "role" },
              });
              setEditMode({ status: false, type: "" });
            }}
          />
        </>
      )}
    </Box>
  );

  const EditSelectField = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <SelectRole />
      <EditIcons />
    </Box>
  );

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {!profileDetails ? (
        <Stack spacing={1} animation="wave">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={55}
          >
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ bgcolor: "#cCc" }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height={55}
              sx={{ ml: 1 }}
            >
              <Skeleton
                variant="rectangular"
                width={210}
                height={25}
                sx={{ bgcolor: "#cCc" }}
              />
              <Skeleton
                variant="rectangular"
                width={210}
                height={18}
                sx={{ bgcolor: "#cCc" }}
              />
            </Box>
          </Box>
        </Stack>
      ) : (
        <>
          <Box>
            {profileDetails?.avatar ? (
              <Avatar
                style={{
                  color: "white",
                  marginRight: ".5em",
                  border: "1px solid #7DA8FB",
                }}
                src={profileDetails.avatar}
              />
            ) : (
              <Avatar
                style={{
                  color: "white",
                  marginRight: ".5em",
                  border: "1px solid #7DA8FB",
                }}
                {...stringAvatar(fullName(profileDetails))}
              />
            )}
          </Box>
          <Box>
            <h3 className="team-member-name">{`${profileDetails?.firstName ? profileDetails.firstName : ""} ${
              profileDetails?.lastName ? profileDetails.lastName : ""
            }`}</h3>
            <div className="profile-contact" style={{ marginBottom: "0" }}>
              <div>
                {editMode.status && editMode.type === "role" ? (
                  <EditSelectField />
                ) : (
                  <span className="team-member-role">
                    {!inputs?.role || inputs?.role === ""
                      ? "Role"
                      : inputs.role}
                  </span>
                )}
              </div>
              {!editMode.status && editMode.type !== "role" && (
                <EditIcon
                  fontSize={"18px"}
                  className={"icon"}
                  onClick={() => setEditMode({ status: true, type: "role" })}
                />
              )}
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ProfileNameRole;
