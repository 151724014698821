import Dialog from "@mui/material/Dialog";
import { deleteLeads } from "./../../../firebase/firestore.js";
import React, { useState } from "react";
import { Box, Button, CircularProgress, Container } from "@material-ui/core";
import Page from "../../Page";
import ListTabular from "../ListTabular";
import MergeLeadContactDialog from "../MergeLeadContactDialog";

export default function LeadsList() {
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [deleteLeadDialogStatus, setDeleteLeadDialogStatus] = useState(false);
  const [mergeDialogStatus, setMergeDialogStatus] = useState(false);
  const [leadDeleting, setLeadDeleting] = useState(false);

  const removeLeadsFromList = (removeLeads) => {
    setLeads(leads.filter((item) => !removeLeads.includes(item.id)));
  };

  return (
    <Page>
      <Dialog
        fullWidth
        open={deleteLeadDialogStatus}
        onClose={() => setDeleteLeadDialogStatus(false)}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={3}
        >
          <p>
            Are you sure you want to delete <b>{selectedLeads.length}</b> lead
            {selectedLeads.length > 1 ? "s" : ""}?
          </p>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setDeleteLeadDialogStatus(false);
              }}
            >
              Cancel{" "}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "20px", width: "100px" }}
              onClick={async () => {
                setLeadDeleting(true);
                let res = await deleteLeads(selectedLeads);

                setLeadDeleting(false);

                if (res.success == true) {
                  removeLeadsFromList(selectedLeads);
                  setDeleteLeadDialogStatus(false);
                  setSelectedLeads([]);
                }
              }}
            >
              Delete{" "}
            </Button>
            {leadDeleting && (
              <Box>
                <CircularProgress style={{ color: "blue" }} size="15px" />
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
      {mergeDialogStatus && (
        <MergeLeadContactDialog
          onClose={() => setMergeDialogStatus(false)}
          onSuccessClose={() => {
            setSelectedLeads([]);
            removeLeadsFromList(selectedLeads.slice(1));
            setMergeDialogStatus(false);
          }}
          selecteds={selectedLeads}
          type={"leads"}
        />
      )}
      <Container maxWidth={"xl"}>
        <ListTabular
          stateData={leads}
          setStateData={setLeads}
          selectedRows={selectedLeads}
          setSelectedRows={setSelectedLeads}
          setDeleteLeadDialogStatus={setDeleteLeadDialogStatus}
          setMergeDialogStatus={setMergeDialogStatus}
          type={"leads"}
        />
      </Container>
    </Page>
  );
}
