import React, { useState, useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { Box, CircularProgress } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function PerformanceTrends({ data, fetchLoading, type, isSentimentAvailable }) {
  const [loading, setLoading] = useState(true);
  const [chartValues, setChartValues] = useState(null);
  const [maxMeetingCount, setMaxMeetingCount] = useState(0);

  const dateFormatter = (date) => {
    //This function return ISO dates to mm/dd/yyyy format.
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  };

  const numberToSentiment = (value) => {
    if (!Number(value)) {
      return value;
    }
    if (value < 24) {
      return "Very Negative";
    } else if (value < 49) {
      return "Negative";
    } else if (value < 74) {
      return "Neutral";
    } else if (value < 100) {
      return "Positive";
    } else if (value === 100) {
      return "Very Positive";
    }
    return value;
  };

  const calculateAvarageSentiment = (sentiments) => {
    if (!sentiments || sentiments?.length === 0) {
      return 0;
    }
    const sentimentMeter = (sentiment) => {
      switch (sentiment) {
        case "NEGATIVE_2":
          return 0;
        case "NEGATIVE_1":
          return 25;
        case "NEUTRAL":
          return 50;
        case "POSITIVE_1":
          return 75;
        case "POSITIVE_2":
          return 100;
        default:
          return 0;
      }
    };
    let totalValue = 0;
    sentiments?.map((item) => (totalValue += sentimentMeter(item)));
    return Math.round(totalValue / sentiments?.length);
  };

  const calculateAvarageEngagement = (engagements) => {
    if (!engagements || engagements?.length === 0) {
      return 0;
    }
    let totalEngagement = 0;
    engagements.map((item) => (totalEngagement += item));
    return Math.round(totalEngagement / engagements?.length);
  };

  const calculateSpecifies = (specifications) => {
    if (!specifications || specifications?.length === 0) {
      return {
        positive: {
          value: 0,
          label: 0,
        },
        neutral: {
          value: 0,
          label: 0,
        },
        negative: {
          value: 0,
          label: 0,
        },
        notSpecified: {
          value: 0,
          label: 0,
        },
      };
    }
    let totalPositive = 0;
    let totalNegative = 0;
    let totalNeutral = 0;
    let totalNotSpecified = 0;
    specifications?.map((specification) => {
      if (specification === "POSITIVE") {
        totalPositive += 1;
      } else if (specification === "NEGATIVE") {
        totalNegative += 1;
      } else if (specification === "UNCERTAIN") {
        totalNeutral += 1;
      } else {
        totalNotSpecified += 1;
      }
    });
    let rate = 100 / maxMeetingCount;

    let percentages = {
      positive: Math.round(totalPositive * rate),
      neutral: Math.round(totalNeutral * rate),
      negative: Math.round(totalNegative * rate),
      notSpecified: Math.round(totalNotSpecified * rate),
    };

    console.log(percentages, specifications, "inside func");

    return {
      positive: {
        value: percentages.positive,
        label: `${totalPositive} meeting${totalPositive > 1 ? "s" : ""}`,
      },
      neutral: {
        value:
          percentages.positive > 0 && percentages.neutral > 0
            ? percentages.positive + percentages.neutral
            : percentages.neutral,
        label: `${totalNeutral} meeting${totalNeutral > 1 ? "s" : ""}`,
      },
      negative: {
        value:
          (percentages.positive > 0 && percentages.neutral > 0) ||
          percentages.negative > 0
            ? percentages.positive + percentages.neutral + percentages.negative
            : percentages.negative,
        label: `${totalNegative} meeting${totalNegative > 1 ? "s" : ""}`,
      },
      notSpecified: {
        value:
          percentages.positive > 0 ||
          percentages.neutral > 0 ||
          percentages.negative > 0
            ? percentages.positive +
              percentages.neutral +
              percentages.negative +
              percentages.notSpecified
            : percentages.notSpecified,
        label: `${totalNotSpecified} meeting${
          totalNotSpecified > 1 ? "s" : ""
        }`,
      },
    };
  };

  const transformData = () => {
    if (!data) {
      return;
    }
    if (data?.length) {
      setLoading(false);
    }
    setLoading(true);
    let currentDataStart = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
    let values = [];

    for (let i = 0; i < 7; i++) {
      const today = new Date();
      let newDate = dateFormatter(new Date(today.setDate(today.getDate() - i)));
      values.unshift({
        date: newDate,
        sentiments: [],
        engagements: [],
        specifications: [],
      });
    }

    data?.map((meeting) => {
      let meetingDate = new Date(meeting.startTime * 1000);
      let meetingDateByTime = meetingDate.getTime();
      if (meetingDateByTime > currentDataStart) {
        // Meeting in range
        let meetingDateFormatted = dateFormatter(meetingDate);
        let meetingDay = values.find(
          (item) => item.date === meetingDateFormatted
        );
        if (meetingDay) {
          if (type === "member") {
            if (meeting?.sentiment) {
              meetingDay.sentiments.push(
                meeting?.sentiment?.emotion === "MIXED"
                  ? "NEUTRAL"
                  : meeting.sentiment.emotion
              );
            }
            if (meeting?.participation) {
              if (meeting.participation?.noShow) {
                meetingDay.engagements.push(0);
              } else {
                meetingDay.engagements.push(
                  Number(meeting?.participation?.participation)
                );
              }
            }
          } else {
            if (
              meeting?.sentiments?.length > 0 &&
              meeting?.sentiments[0]?.emotion
            ) {
              meetingDay.sentiments.push(
                meeting.sentiments[0].emotion === "MIXED"
                  ? "NEUTRAL"
                  : meeting.sentiments[0].emotion
              );
            }
            if (
              meeting?.participations?.length > 0 &&
              meeting?.participations[0]?.participation
            ) {
              if (meeting?.participations[0]?.noShow) {
                meetingDay.engagements.push(0);
              } else {
                meetingDay.engagements.push(
                  Number(meeting?.participations[0]?.participation)
                );
              }
            }
          }
          meetingDay.specifications.push(
            meeting?.meetingOutcome || "Not Specified"
          );
        }
      }
    });
    let newMaxMeetingCount = 0;
    values.map((item) => {
      if (item?.specifications?.length > newMaxMeetingCount) {
        newMaxMeetingCount = item.specifications.length;
      }
    });
    setMaxMeetingCount(newMaxMeetingCount);
    setChartValues(values);
    setLoading(false);
  };

  useEffect(transformData, [data]);

  const chartJSData = {
    labels: chartValues?.map((item) => item.date.slice(0, 5)),
    datasets: [
      isSentimentAvailable
        ? {
            id: 1,
            type: "line",
            label: "Average Sentiment",
            data: chartValues?.map((item) =>
              calculateAvarageSentiment(item.sentiments)
            ),
            tension: 0.4,
            borderColor: "#E4A400",
            borderWith: 1,
          }
        : {},
      {
        id: 2,
        type: "line",
        label: "Average Engagement",
        data: chartValues?.map((item) =>
          calculateAvarageEngagement(item.engagements)
        ),
        tension: 0.4,
        borderColor: "#ef5da8",
        borderWith: 1,
      },
      {
        id: 3,
        type: "bar",
        label: "Positive",
        key: "positive",
        data: chartValues?.map(
          (item) => calculateSpecifies(item.specifications)?.positive.value
        ),
        backgroundColor: "#2C73FF",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 16,
      },
      {
        id: 4,
        type: "bar",
        label: "Negative",
        key: "negative",
        data: chartValues?.map(
          (item) => calculateSpecifies(item.specifications)?.negative.value
        ),
        backgroundColor: "#ff6347",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 16,
      },
      {
        id: 5,
        type: "bar",
        label: "Neutral",
        key: "neutral",
        data: chartValues?.map(
          (item) => calculateSpecifies(item.specifications)?.neutral.value
        ),
        backgroundColor: "#EDF3FF",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 16,
      },
      {
        id: 6,
        type: "bar",
        label: "Not Specified",
        key: "notSpecified",
        data: chartValues?.map(
          (item) => calculateSpecifies(item.specifications)?.notSpecified.value
        ),
        backgroundColor: "#cCc",
        borderWidth: 0,
        borderRadius: 3,
        barThickness: 16,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 1,
    interaction: {
      mode: "index",
    },
    plugins: {
      tooltip: {
        intersect: false,
        callbacks: {
          title: function (context) {
            return chartValues?.map((item) => item.date)[context[0]?.dataIndex];
          },
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (Number(context.dataset.id) === 1 && context.parsed.y !== null) {
              label += numberToSentiment(context.parsed.y);
            } else if (context.dataset.id > 2) {
              label += chartValues?.map(
                (item) =>
                  calculateSpecifies(item.specifications)[context?.dataset?.key]
                    .label
              )[context.dataIndex];
            } else if (context.parsed.y !== null) {
              label += context.parsed.y + "%";
            }
            return label;
          },
        },
        backgroundColor: "#edf3ff",
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 5,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        max: 110,
        ticks: {
          display: true,
          stepSize: 1,
          autoSkip: false,
          callback: (v, i, d) => {
            if (v === 0 || v > 100 || maxMeetingCount > 20) {
              return "";
            } else if (v % Math.round(100 / maxMeetingCount) === 0) {
              return v / Math.round(100 / maxMeetingCount);
            }
            return "";
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
        stacked: true,
      },
    },
  };

  return (
    <div className="chart">
      <div className="chart-header">
        <h6>Overall performance trends</h6>
      </div>
      {loading || fetchLoading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          sx={{ height: "100%" }}
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Chart type={"bar"} data={chartJSData} options={options} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isSentimentAvailable ? "space-between" : "center",
              flexWrap: "wrap",
            }}
          >
            {isSentimentAvailable && (
              <div className="compare-chart-info">
                <div className="dot orange" />
                Average Sentiment
              </div>
            )}

            <div className="compare-chart-info">
              <div className="dot pink" />
              Average Engagement
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              mt: 1,
            }}
          >
            <div className="compare-chart-info">
              <div className="dot blue" />
              Positive
            </div>

            <div className="compare-chart-info">
              <div className="dot tomato-red" />
              Negative
            </div>

            <div className="compare-chart-info">
              <div className="dot ice-blue" />
              Neutral
            </div>

            <div className="compare-chart-info">
              <div className="dot gray" />
              Not specified
            </div>
          </Box>
        </>
      )}
    </div>
  );
}

export default PerformanceTrends;
