import { put, call } from "redux-saga/effects";
import { createAction } from "redux-actions";
import constants from "../constants";
import {
  getOrganizationLeads,
  getOrganizationLeadsCount,
  getOrganizationContacts,
  getOrganizationContactsCount,
  getOrganizationAccounts,
  getOrganizationAccountsCount,
} from "../../firebase/firestore.js";

export const startCrmLeadFetching = createAction(
  constants.CRM_LEAD_START_FETCHING_LEAD
);
export const stopCrmLeadFetching = createAction(
  constants.CRM_LEAD_STOP_FETCHING_LEAD
);

export const fetchLeads = createAction(constants.CRM_LEAD_FETCH_LEAD);
export const removeLeadsAction = createAction(constants.CRM_LEAD_REMOVE_LEADS);
export const updateLeadAction = createAction(constants.CRM_LEAD_UPDATE_LEAD);

export const startCrmAccountFetching = createAction(
  constants.CRM_ACCOUNT_START_FETCHING_ACCOUNT
);
export const stopCrmAccountFetching = createAction(
  constants.CRM_ACCOUNT_STOP_FETCHING_ACCOUNT
);

export const fetchAccounts = createAction(constants.CRM_ACCOUNT_FETCH_ACCOUNT);
export const removeAccountsAction = createAction(
  constants.CRM_ACCOUNT_REMOVE_ACCOUNTS
);
export const updateAccountAction = createAction(
  constants.CRM_ACCOUNT_UPDATE_ACCOUNT
);

export const startCrmContactFetching = createAction(
  constants.CRM_CONTACT_START_FETCHING_CONTACT
);
export const stopCrmContactFetching = createAction(
  constants.CRM_CONTACT_STOP_FETCHING_CONTACT
);

export const fetchContacts = createAction(constants.CRM_CONTACT_FETCH_CONTACT);
export const removeContactsAction = createAction(
  constants.CRM_CONTACT_REMOVE_CONTACTS
);
export const updateContactAction = createAction(
  constants.CRM_CONTACT_UPDATE_CONTACT
);

export function* fetchLead(action) {
  yield put(startCrmLeadFetching());
  let res = {};
  res.leads = yield call(getOrganizationLeads, action.payload);
  if (action.payload.page == 1) {
    res.totalLeads = yield call(getOrganizationLeadsCount);
  }
  yield put({
    type: constants.CRM_LEAD_SET_LEADS,
    payload: res,
  });
  yield put(stopCrmLeadFetching());
}

export function* fetchContact(action) {
  yield put(startCrmContactFetching());
  let res = {};
  res.contacts = yield call(getOrganizationContacts, action.payload.page);
  if (action.payload.page == 1) {
    res.totalContacts = yield call(getOrganizationContactsCount);
  }

  yield put({
    type: constants.CRM_CONTACT_SET_CONTACTS,
    payload: res,
  });
  yield put(stopCrmContactFetching());
}

export function* fetchAccount(action) {
  yield put(startCrmAccountFetching());
  let res = {};
  res.accounts = yield call(getOrganizationAccounts, action.payload);
  if (action.payload.page == 1) {
    res.totalAccounts = yield call(getOrganizationAccountsCount);
  }
  yield put({
    type: constants.CRM_ACCOUNT_SET_ACCOUNTS,
    payload: res,
  });
  yield put(stopCrmAccountFetching());
}
