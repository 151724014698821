import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Scatter } from "react-chartjs-2";
import veryPositiveIcon from "../../assets/images/veryPositiveIcon.png";
import positiveIcon from "../../assets/images/positiveIcon.png";
import neutralIcon from "../../assets/images/neutralIcon.png";
import unhappyIcon from "../../assets/images/unhappyIcon.png";
import veryUnhappyIcon from "../../assets/images/veryUnhappyIcon.png";
import zoomIcon from "../../assets/images/zoom.png";
import googleMeetIcon from "../../assets/images/google-meet-logo.png";
import msTeamIcon from "../../assets/images/ms-team-logo.png";
import moment from "moment";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const zoomLogo = new Image(16, 16);
zoomLogo.src = zoomIcon;

const googleMeetLogo = new Image(16, 16);
googleMeetLogo.src = googleMeetIcon;

const msTeamLogo = new Image(16, 16);
msTeamLogo.src = msTeamIcon;

const veryPositiveIconImage = new Image(16, 16);
veryPositiveIconImage.src = veryPositiveIcon;

const positiveIconImage = new Image(16, 16);
positiveIconImage.src = positiveIcon;

const neutralIconImage = new Image(16, 16);
neutralIconImage.src = neutralIcon;

const unhappyIconImage = new Image(16, 16);
unhappyIconImage.src = unhappyIcon;

const veryUnhappyIconImage = new Image(16, 16);
veryUnhappyIconImage.src = veryUnhappyIcon;

function SentimentScore({ data }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [days, setDays] = useState([]);
  const [yMax, setYMax] = useState(3);
  const [xPositions, setXPositions] = useState({ min: 0, max: 3 });

  const transformData = () => {
    if (!data) {
      return;
    }

    let meetingDays = [];

    let datasets = [
      {
        label: "POSITIVE_1",
        data: [],
        pointStyle: positiveIconImage,
        pointHoverRadius: 10,
      },
      {
        label: "POSITIVE_2",
        data: [],
        pointStyle: veryPositiveIconImage,
        pointHoverRadius: 10,
      },
      {
        label: "NEUTRAL",
        data: [],
        pointStyle: neutralIconImage,
        pointHoverRadius: 10,
      },
      {
        label: "NEGATIVE_1",
        data: [],
        pointStyle: unhappyIconImage,
        pointHoverRadius: 10,
      },
      {
        label: "NEGATIVE_2",
        data: [],
        pointStyle: veryUnhappyIconImage,
        pointHoverRadius: 10,
      },
      {
        label: "ZOOM",
        data: [],
        pointStyle: zoomLogo,
        pointHoverRadius: 10,
      },
      {
        label: "GOOGLE_MEET",
        data: [],
        pointStyle: googleMeetLogo,
        pointHoverRadius: 10,
      },
      {
        label: "MS_TEAMS",
        data: [],
        pointStyle: msTeamLogo,
        pointHoverRadius: 10,
      },
    ];

    if (data && data.length === 0) {
      setChartData({ datasets: [] });
      setLoading(false);
      return;
    }
    let filterAvailableData = data
      ?.filter((meeting) => meeting?.segmentedSentiment?.length)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    filterAvailableData?.map((meeting, index) => {
      const sentiments = meeting?.segmentedSentiment?.sort(
        (a, b) => a.sort - b.sort
      );
      sentiments?.map((sentiment) => {
        let findDataSet = datasets.find(
          (item) =>
            item.label ===
            (sentiment?.emotion === "MIXED" ? "NEUTRAL" : sentiment?.emotion)
        );
        if (findDataSet) {
          findDataSet.data.push({
            x: index + 0.5,
            y: sentiment?.sort - 0.5,
            startTime: sentiment?.startTime,
            endTime: sentiment?.endTime,
          });
        }
      });
      meetingDays.push(
        new Date(meeting?.createdAt).toLocaleDateString("en-US", {
          day: "numeric",
          month: "numeric",
        })
      );
    });

    let hightestY = 0;
    let highestX = 0;
    datasets.map((item) => {
      item.data.map((meetingCoords) => {
        if (meetingCoords.y > hightestY) {
          hightestY = meetingCoords.y;
        }
        if (meetingCoords.x > highestX) {
          highestX = meetingCoords.x;
        }
      });
    });

    //Pleace meeting logo
    filterAvailableData?.map((meeting, index) => {
      let findChannel = datasets.find(
        (item) => item.label === meeting.channelType
      );
      if (findChannel) {
        findChannel.data.push({
          x: index + 0.5,
          y: hightestY + 1,
          duration: meeting?.duration ? meeting.duration : 0,
          title: meeting?.title ? meeting.title : "",
        });
      }
    });
    setDays(meetingDays);
    setChartData({ datasets });
    setXPositions({
      min: meetingDays.length - 7,
      max: meetingDays.length,
    });
    if (hightestY > 2) {
      setYMax(hightestY + 1.5);
    }
    setLoading(false);
  };

  useEffect(transformData, [data]);

  const getGradient = (ctx, axis) => {
    let gradient;
    if (axis === "x") {
      gradient = ctx.createLinearGradient(0, 0, 0, 360);
    } else {
      gradient = ctx.createLinearGradient(0, 0, 360, 0);
    }
    gradient.addColorStop(0, "rgba(230, 230, 255, 0)");
    gradient.addColorStop(0.5, "rgba(230, 230, 255, 1)");
    gradient.addColorStop(1, "rgba(230, 230, 255, 0)");
    return gradient;
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        //intersect: false,
        callbacks: {
          label: function (context) {
            if (context.parsed.y !== null) {
              if (context?.raw?.duration || context?.raw?.title) {
                let hours = Math.floor(context?.raw?.duration / 60 / 60 / 1000);
                let mins =
                  Math.floor(context?.raw?.duration / 60 / 1000) - hours * 60;
                let tooltipTexts = [];
                if (context?.raw?.title) {
                  tooltipTexts.push(`Title: ${context.raw.title}`);
                }
                if (hours > 0 || mins > 0) {
                  tooltipTexts.push(
                    `Total Duration : ${hours > 0 ? hours + "h" : ""} ${
                      mins > 0 ? mins + "m" : ""
                    }`
                  );
                }
                return tooltipTexts;
              }
              if (context?.raw?.startTime && context?.raw?.endTime) {
                return `${moment(context.raw.startTime).format(
                  "hh:mm"
                )} to ${moment(context.raw.endTime).format("hh:mm")}`;
              }
              return "";
            } else {
              return "";
            }
          },
        },
        position: "average",
        backgroundColor: "#edf3ff",
        borderColor: "grey",
        boxHeight: 10,
        borderWidth: 0.2,
        titleFont: {
          size: 12,
        },
        titleColor: "#2c73ff",
        bodyColor: "#2c73ff",
        bodyFont: {
          size: 12,
        },
        displayColors: false,
        caretSize: 2,
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
    scales: {
      y: {
        max: yMax,
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: false,
          beginAtZero: true,
          stepSize: 1,
          callback: function (val, index) {
            return index === 0 ? "End" : val === yMax - 1 ? "Beginning" : "";
          },
          color: "#7DA8FB",
        },
        grid: {
          display: true,
          color: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getGradient(ctx, "y");
          },
        },
      },
      x: {
        max: xPositions.max,
        min: xPositions.min,
        beginAtZero: true,
        ticks: {
          display: true,
          autoSkip: false,
          beginAtZero: true,
          stepSize: 0.5,
          callback: function (val, index) {
            if (val % 1 === 0.5) {
              return days[Math.floor(val)];
            }
            if (days?.length === 0) {
              return "";
            }
          },
        },
        grid: {
          display: true,
          color: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            return getGradient(ctx, "x");
          },
        },
      },
    },
  };

  const handleScrollChart = (direction) => {
    if (direction === "toLeft") {
      if (xPositions.min - 1 < 0) {
        return;
      }
      setXPositions({
        min: xPositions.min - 7 < 0 ? 0 : xPositions.min - 7,
        max: xPositions.min,
      });
    }
    if (direction === "toRight") {
      if (xPositions.max + 1 > days?.length) {
        return;
      }
      setXPositions({
        min: xPositions.max,
        max:
          xPositions.max + 7 > days?.length ? days?.length : xPositions.max + 7,
      });
    }
  };

  return (
    <div className="analytics-chart lead chart">
      <div className="chart-header">
        <h6>Sentiment Score</h6>
      </div>
      {loading ? (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="100%"
        >
          <CircularProgress style={{ color: "#2c73ff" }} size="30px" />
        </Box>
      ) : (
        <div className="chart-canvas">
          {xPositions.min - 1 > 0 && (
            <div
              className="chart-arrow-icon"
              onClick={() => handleScrollChart("toLeft")}
            >
              <ChevronLeftIcon sx={{ color: "#2c73ff", fontSize: 16 }} />
            </div>
          )}
          <Scatter data={chartData} options={options} />
          {days?.length > xPositions.max + 1 && (
            <div
              className="chart-arrow-icon"
              style={{ right: 0 }}
              onClick={() => handleScrollChart("toRight")}
            >
              <ChevronRightIcon sx={{ color: "#2c73ff", fontSize: 16 }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SentimentScore;
